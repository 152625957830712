import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, FormGroup, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle, colourStyles } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import Selects from "react-select";
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { useReactToPrint } from "react-to-print";

function Areawisecustomerlist() {

    const [customers, setCustomers] = useState([]);
    const [customerarea, setCustomerarea] = useState();
    const [newdata, setNewdata] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const [checked, setChecked] = useState(true);
    const [code, setCode] = useState([]);
    const { auth } = useContext(AuthContext);

    //Areafiled
    const [daysFilter, setDaysFilter] = useState({
        area: " ",
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //Select All function 
    const fetchAreaWiseSelect = async (e) => {

        setChecked(s => !s)
        try {
            let response = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            if (checked === true) {
                setCustomers(response.data.customers);

            } else if (checked === false) {
                setCustomers([])

            }
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Fetch customer area 
    const fetchArea = async () => {
        try {
            let res_group = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.customers?.map((d) => {
                return d.area
            })
            setNewdata(masters_data);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    let newarray = [...new Set(newdata)];

    // Customer area in dropdown
    const fetchcustomerarea = async () => {
        try {
            setCustomerarea(
                newarray.map((d) => ({
                    ...d,
                    label: d,
                    value: d,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchcustomerarea();
    }, [customerarea])

    const getCateName = (newValue) => {
        if (newValue) {
            setCustomers([]) || setChecked(true)
        }
        setCode(newValue.category)
    }

    //jquery
    $.DataTable = require('datatables.net')
    const tableRefArea = useRef()

    // CustomersAreaWise Filter 
    const fetchAreaWiseCustomer = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            let getDatawithFilter = req.data.customers?.filter((data) => {
                if (daysFilter.area == "") {
                    return data
                }
                else if (data.area == daysFilter.area) {
                    return data
                }
            })
            setCustomers(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefArea.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchAreaWiseCustomer();
            fetchArea();
        }, []
    )

    // Excel
    const fileName = 'AreaWiseCustomer'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = customers?.map(t => ({
            "Category": t.category,
            "Customer ID": t.cusid,
            "Name": t.name,
            "Address": t.address,
            "Area": t.area,
            "Mobile No": t.mobileno,
            "All Contact No": t.whatsapp,
            "GSTN No": t.gstn,
            "Credit Limit": t.creditlimit,
        }));
        setExceldata(data);
    }
    useEffect(
        () => {
            getexcelDatas();
        }, [customers]
    )

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | AREA WISE CUSTOMER REPORT',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Areawisecustomerlist' })
        doc.save('Areawisecustomerlist.pdf')
    }

    return (
        <Box >
            <Headtitle title={'Area Wise Customer List'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Area Wise Customer List</Typography>
            <Box sx={userStyle.filtercontent}>
                <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                    <Grid item lg={4} md={4}>
                        <Grid container>
                            <Grid item lg={2} md={2}>
                                <Typography sx={{ marginTop: 1 }}> Area</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <Selects
                                        onChange={(e)=>{
                                            setDaysFilter({ ...daysFilter, area: e.value })
                                            getCateName(e.value);
                                            setChecked(true)
                                        }}
                                        styles={colourStyles}
                                        options={customerarea}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onClick={(e) => { fetchAreaWiseSelect(e) }} checked={!checked} />} label="Select All" />
                        </FormGroup>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <Button onClick={fetchAreaWiseCustomer} variant='contained' color='secondary'>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvareawisecustomer && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelareawisecustomer && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printareawisecustomer && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfareawisecustomer && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="Areawisecustomerlist" ref={tableRefArea}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Customer Id</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Address</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Mobile No</StyledTableCell>
                                    <StyledTableCell>All Contact Nos</StyledTableCell>
                                    <StyledTableCell>GST In No</StyledTableCell>
                                    <StyledTableCell>Credit Limit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customers.length > 0 && (
                                    customers.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.category}</StyledTableCell>
                                            <StyledTableCell align="left">{row.cusid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell >
                                            <StyledTableCell align="left">{row.address}</StyledTableCell>
                                            <StyledTableCell align="left">{row.area}</StyledTableCell>
                                            <StyledTableCell align="left">{row.mobileno}</StyledTableCell >
                                            <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                                            <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditlimit}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Table End ****** */}

            { /* ****** Print ****** */}

            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Area Wise Customer List Print</Typography>
                </Box>
                <Box  >
                    <TableContainer component={Paper} sx={userStyle.printcls}>
                        <Table aria-label="simple table" id="Areawisecustomerlist">
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Customer Id</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Address</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Mobile No</StyledTableCell>
                                    <StyledTableCell>All Contact Nos</StyledTableCell>
                                    <StyledTableCell>GST In No</StyledTableCell>
                                    <StyledTableCell>Credit Limit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customers.length > 0 && (
                                    customers.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.category}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.address}</StyledTableCell >
                                            <StyledTableCell align="left">{row.area}</StyledTableCell>
                                            <StyledTableCell align="left">{row.city}</StyledTableCell>
                                            <StyledTableCell align="left">{row.mobileno}</StyledTableCell >
                                            <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                                            <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditlimit}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
}

function AreawisecustomerlistList() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Areawisecustomerlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default AreawisecustomerlistList;