import React, { useState, useEffect, useRef, useContext } from "react";
import { userStyle } from "../PageStyle";
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Paper, Typography, Button, Table, TableContainer, TableHead, TableBody, DialogActions, DialogContent, Dialog, } from "@mui/material";
import axios from "axios";
import $ from "jquery";
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import Headtitle from '../../components/header/Headtitle';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../Export';
import { useReactToPrint } from "react-to-print";
import autoTable from 'jspdf-autotable';
import jsPDF from "jspdf";

const Schedule = () => {

    //alert delete popup
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const { auth } = useContext(AuthContext);

    // /DataTable
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    const handleClose = () => {
        setIsDeleteOpen(false);
    };

    //alert delete popup
    const [deleteOpen, setDeleteOpen] = useState(false);

    const handleClickOpendel = () => {
        setDeleteOpen(true);
    };

    const handleClosedel = () => {
        setDeleteOpen(false);
    };

    //popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpenalert = () => {
        setIsErrorOpen(true);
    };
    const handleClosealert = () => {
        setIsErrorOpen(false);
    };

    const [scheduleList, setScheduleList] = useState();
    //text field
    const [schedule, setSchedule] = useState({ name: "", remark: "" });
    const [scheduleEdit, setScheduleEdit] = useState({ name: "", updatedname: "" });
    const [getrowid, setRowGetid] = useState();
    const [schedit, setSchedit] = useState({ name: "", remark: "" });
    const [delId, setDelId] = useState("");
    const [show, setShow] = useState(false);
    // get all products
    const fetchSchedule = async () => {
        try {
            let res_product = await axios.get(SERVICE.SCHEDULE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setScheduleList(res_product.data.schedule);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // store product data
    const sendRequest = async () => {
        try {
            let Schedule_Req = await axios.post(SERVICE.SCHEDULE_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                name: String(schedule.name),
                remark: String(schedule.remark),
            });
            setSchedule(Schedule_Req.data);
            setSchedule({ name: '', remark: "" })
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: 'orange' }} />
                    <p style={{ fontSize: '20px', fontWeight: 900 }}>{messages}</p>
                </>
            );
            handleClickOpenalert();
        }
    };

    useEffect(
        () => {
            fetchSchedule();
        }, [scheduleList]
    );
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {

                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [tableRef.current])

    const handleSubmit = (e) => {
        //
        if (schedule.name == "") {
            e.preventDefault();
            setShowAlert(
                <>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: 'orange' }} />
                    <p style={{ fontSize: '20px', fontWeight: 900 }}>{"Please enter schedule name"}</p>
                </>
            );
            handleClickOpenalert();
        } else {
            sendRequest();
        }

    };
    const getdelId = (e) => {
        setDelId(e)
    }

    //alert delete popup
    const deleteSchedule = async () => {
        try {
            let res = await axios.delete(`${SERVICE.SCHEDULE}/${delId}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            handleClosedel();
        } catch (err) {
            const messages = err.Schedule_data.data.message;
            toast.error(messages)
        }
    };

    // schedule edit
    const sendEditRequest = async () => {
        try {
            let Schedule_data = await axios.put(`${SERVICE.SCHEDULE}/${getrowid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                name: String(scheduleEdit.updatedname),
            });

            setScheduleEdit(Schedule_data.data);
            toast.success(Schedule_data.data.message);

            handleClose();
            setScheduleEdit({ name: '', updatedname: "" })
        } catch (err) {
            const messages = err.Schedule_data.data.message;
            toast.error(messages);
        }
    };

    const editSubmit = (e) => {
        // e.preventDefault();
        sendEditRequest();
    }
    const crearvalue = (e) => {
        e.preventDefault();
        setSchedule({ name: '', remark: "" });
    }
    const getCode = async (e) => {
        setRowGetid(e);
        let res = await axios.get(`${SERVICE.SCHEDULE}/${e}`, {
            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            }
        })
        setSchedit(res.data.sschedule);
    }

    return (
        <Box>
            <Headtitle title={'Schedule'} />
            <Typography sx={userStyle.HeaderText}>Schedule List</Typography>
            <form>
                <Grid container sx={userStyle.container} >
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ paddingRight: '3px', padding: '61px', textAling: "center" }} >
                        {!show ?
                            <>
                                <Grid container spacing={3}  >
                                    <Grid item lg={12} md={12} sm={112} xs={12}>
                                        <InputLabel htmlFor="component-outlined" >Schedule<b style={{ color: "red" }}> *</b></InputLabel>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                id="component-outlined"
                                                value={schedule.name}
                                                onChange={(e) => { setSchedule({ ...schedule, name: e.target.value }) }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <InputLabel htmlFor="component-outlined" >Remark</InputLabel>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                id="component-outlined"
                                                value={schedule.remark}
                                                onChange={(e) => { setSchedule({ ...schedule, remark: e.target.value }) }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container sx={userStyle.gridcontainer}>
                                    <Grid >
                                        <Button sx={userStyle.buttoncancel} onClick={crearvalue}>CANCEL</Button>
                                        <Button sx={userStyle.buttonadd} type="submit" onClick={handleSubmit}>Save</Button>
                                    </Grid>
                                </Grid> </> : <>
                                <Grid container spacing={3} sx={userStyle.textInput}>
                                    <Grid item lg={12} md={12} sm={6} xs={12}>
                                        <InputLabel id="demo-select-small">Name</InputLabel>
                                        <FormControl size="small" fullWidth>

                                            <OutlinedInput
                                                id="component-outlined"
                                                value={schedit.name}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={6} xs={12}>
                                        <InputLabel htmlFor="component-outlined" >New Schedule</InputLabel>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                id="component-outlined"
                                                required
                                                value={scheduleEdit.updatedname}
                                                onChange={(e) => { setScheduleEdit({ ...scheduleEdit, updatedname: e.target.value }) }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container sx={userStyle.gridcontainer}>
                                    <Grid >
                                        <Button sx={userStyle.buttoncancel} onClick={(e) => { e.preventDefault(); setShow(false) }} type='button'>CANCEL</Button>
                                        <Button sx={userStyle.buttonadd} type="submit" onClick={editSubmit}>UPDATE</Button>
                                    </Grid>
                                </Grid> </>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} sx={{ borderLeft: ' 1px solid #91868652', padding: '41px' }}>
                        <Grid container  >
                            <br />
                            {/* Table Grid Container */}

                            { /* Table Start */}
                            <TableContainer component={Paper} >
                                <Table sx={{ margin: '10px', paddingTop: "20px", paddingBottom: "20px" }} aria-label="simple table" id="producttable" ref={tableRef}>
                                    <TableHead sx={{ fontWeight: "600" }} >
                                        <StyledTableRow >
                                            <StyledTableCell sx={{ border: '1px solid #dddddd7d !important', fontSize: "16px" }}>Schedule</StyledTableCell>
                                            <StyledTableCell sx={{ border: '1px solid #dddddd7d !important', fontSize: "16px" }}>Remark</StyledTableCell>
                                            <StyledTableCell sx={{ border: '1px solid #dddddd7d !important', fontSize: "16px" }}>Actions</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {scheduleList && (
                                            scheduleList.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={row.index}>
                                                        <StyledTableCell align="left">{row.name}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.remark}</StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">
                                                            <Grid sx={{ display: 'flex' }}>
                                                                <Button sx={userStyle.buttonedit} onClick={() => {
                                                                    setShow(true); getCode(row._id)
                                                                }}><EditOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                                                                <Button sx={userStyle.buttondelete} onClick={() => { handleClickOpendel(); getdelId(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                                                            </Grid>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            { /* Table End */}
                        </Grid>
                    </Grid>
                </Grid>

                <Dialog
                    open={isDeleteOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <Box>
                            <Typography sx={userStyle.HeaderText}>Schedule Update</Typography>
                            <form >
                                <Box sx={userStyle.container}>
                                    <Grid container spacing={3} sx={userStyle.textInput}>
                                        <Grid item lg={12} md={12} sm={6} xs={12}>

                                            <FormControl size="small" fullWidth>
                                                <InputLabel id="demo-select-small">Name</InputLabel>

                                                <OutlinedInput
                                                    id="component-outlined"
                                                    label="New Schedule"
                                                    value={schedit.name}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={6} xs={12}>
                                            <FormControl size="small" fullWidth >
                                                <InputLabel htmlFor="component-outlined" >New Schedule</InputLabel>
                                                <OutlinedInput
                                                    id="component-outlined"
                                                    label="New Schedule"
                                                    required
                                                    value={scheduleEdit.updatedname}
                                                    onChange={(e) => { setScheduleEdit({ ...scheduleEdit, updatedname: e.target.value }) }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button sx={userStyle.buttoncancel} onClick={handleClose} type='button'>CANCEL</Button>
                        <Button sx={userStyle.buttonadd} type="submit" onClick={editSubmit}>UPDATE</Button>
                    </DialogActions>
                </Dialog>
            </form>
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClosealert}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <Typography>{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClosealert}>ok</Button>
                </DialogActions>
            </Dialog>

            {/* ALERT DIALOG */}
            <Dialog
                open={deleteOpen}
                onClose={handleClosedel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"

            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosedel} variant="outlined">Cancel</Button>
                    <Button onClick={(e) => deleteSchedule(delId)} autoFocus variant="contained" color='error'> OK </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

function Schedulecreate() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Schedule /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Schedulecreate;