import {
    BASE_URL
} from './Authservice';

export const SERVICE = {

    FEATURE: `${BASE_URL}/api`,
    //user
    USER: `${BASE_URL}/api/users`,
    USER_CREATE: `${BASE_URL}/api/user/new`,
    USER_SINGLE: `${BASE_URL}/api/user`,
    //role
    ROLE: `${BASE_URL}/api/roles`,
    ROLE_CREATE: `${BASE_URL}/api/role/new`,
    ROLE_SINGLE: `${BASE_URL}/api/role`,
    //departments
    DEPARTMENT: `${BASE_URL}/api/departments`,
    DEPARTMENT_CREATE: `${BASE_URL}/api/department/new`,
    DEPARTMENT_SINGLE: `${BASE_URL}/api/department`,
     //accounts
    ACCOUNT_CREATION: `${BASE_URL}/api/accountcreations`,
    ACCOUNT_CREATION_CREATE: `${BASE_URL}/api/accountcreation/new`,
    ACCOUNT_CREATION_SINGLE: `${BASE_URL}/api/accountcreation`,
    //group
    GROUP_CREATION: `${BASE_URL}/api/groupcreations`,
    GROUP_CREATION_CREATE: `${BASE_URL}/api/groupcreation/new`,
    GROUUP_CREATION_SINGLE: `${BASE_URL}/api/groupcreation`,
    //cash book
    CASH_BOOK: `${BASE_URL}/api/cashbooks`,
    CASH_BOOK_CREATE: `${BASE_URL}/api/cashbook/new`,
    CASH_BOOK_SINGLE: `${BASE_URL}/api/cashbook`,
    //voucher
    VOUCHER: `${BASE_URL}/api/vouchers`,
    VOUCHER_CREATE: `${BASE_URL}/api/voucher/new`,
    VOUCHER_SINGLE: `${BASE_URL}/api/voucher`,
    //book
    BANKBOOK: `${BASE_URL}/api/bankbooks`,
    BANKBOOK_CREATE: `${BASE_URL}/api/bankbook/new`,
    BANKBOOK_SINGLE: `${BASE_URL}/api/bankbook`,
    //bank
    BANKMASTER: `${BASE_URL}/api/bankmasters`,
    BANKMASTER_CREATE: `${BASE_URL}/api/bankmaster/new`,
    BANKMASTER_SINGLE:  `${BASE_URL}/api/bankmaster`,
    //customer
    CUSTOMER: `${BASE_URL}/api/customers`,
    CUSTOMER_CREATE: `${BASE_URL}/api/customer/new`,
    CUSTOMER_SINGLE:`${BASE_URL}/api/customer`,
    //customer name
    CUSTOMER_NAME: `${BASE_URL}/api/customernames`,
    CUSTOMER_NAME_CREATE: `${BASE_URL}/api/customername/new`,
    CUSTOMER_NAME_SINGLE:`${BASE_URL}/api/customername`,
    //customer bills
    CUSTOMER_BILL: `${BASE_URL}/api/customerbills`,
    CUSTOMER_BILL_CREATE: `${BASE_URL}/api/customerbill/new`,
    CUSTOMER_BILL_SINGLE:`${BASE_URL}/api/customerbill`,
    //customer payment
    CUSTOMER_PAYMENT: `${BASE_URL}/api/customerpayments`,
    CUSTOMER_PAYMENT_CREATE:  `${BASE_URL}/api/customerpayment/new`,
    CUSTOMER_PAYMENT_SINGLE: `${BASE_URL}/api/customerpayment`,
    //customer drug
    CUSTOMER_DRUG: `${BASE_URL}/api/customerdrugs`,
    CUSTOMER_DRUG_CREATE: `${BASE_URL}/api/customerdrug/new`,
    CUSTOMER_DRUG_SINGLE: `${BASE_URL}/api/customerdrug`,
    //customer group
    CUSTOMER_GROUP: `${BASE_URL}/api/cgroups`,
    CUSTOMER_GROUP_CREATE: `${BASE_URL}/api/cgroup/new`,
    CUSTOMER_GROUP_SINGLE: `${BASE_URL}/api/cgroup`,
    //supplier
    SUPPLIER: `${BASE_URL}/api/suppliers`,
    SUPPLIER_SINGLE: `${BASE_URL}/api/supplier`,
    SUPPLIER_CREATE: `${BASE_URL}/api/supplier/new`,
    //supplier name
    SUPPLIER_NAME: `${BASE_URL}/api/suppliernames`,
    SUPPLIER_NAME_CREATE: `${BASE_URL}/api/suppliername/new`,
    SUPPLIER_NAME_SINGLE:`${BASE_URL}/api/suppliername`,
     //expense
     EXPENSE: `${BASE_URL}/api/expenses`,
     EXPENSE_CREATE: `${BASE_URL}/api/expense/new`,
     EXPENSE_SINGLE: `${BASE_URL}/api/expense`,
     EXPENSE_LASTINDEX: `${BASE_URL}/api/expenselastindex`,
     //expense category
     EXPENSE_CATEGORY: `${BASE_URL}/api/expcategories`,
     EXPENSE_CATEGORY_CREATE: `${BASE_URL}/api/expcategory/new`,
     EXPENSE_CATEGORY_SINGLE: `${BASE_URL}/api/expcategory`,
     EXPENSE_CATEGORY_LASTINDEX: `${BASE_URL}/api/expcategorylastindex`,
    //product
    PRODUCT: `${BASE_URL}/api/products`,
    PRODUCT_CREATE: `${BASE_URL}/api/product/new`,
    PRODUCT_SINGLE: `${BASE_URL}/api/product`,
    //brand
    BRAND: `${BASE_URL}/api/brands`,
    BRAND_CREATE: `${BASE_URL}/api/brand/new`,
    //unit
    UNIT: `${BASE_URL}/api/units`,
    UNIT_CREATE: `${BASE_URL}/api/unit/new`,
    UNIT_SINGLE: `${BASE_URL}/api/unit`,
    //category
    CATEGORIES: `${BASE_URL}/api/categories`,
    CATEGORIES_CREATE: `${BASE_URL}/api/category/new`,
    CATEGORY:`${BASE_URL}/api/category`,
    //discount
    DISCOUNT: `${BASE_URL}/api/discounts`,
    DISCOUNT_SINGLE:`${BASE_URL}/api/discount`,
    DISCOUNT_CREATE: `${BASE_URL}/api/discount/new`,
    //moleclue
    MOLECULES:`${BASE_URL}/api/molecule`,
    MOLECULE_CREATE:`${BASE_URL}/api/molecule/new`,
    MOLECULE:`${BASE_URL}/api/molecule`,
    //schedule
    SCHEDULES:`${BASE_URL}/api/schedule`,
    SCHEDULE_CREATE:`${BASE_URL}/api/schedule/new`,
    SCHEDULE:`${BASE_URL}/api/schedule`,
    //purchase
    PURCHASE: `${BASE_URL}/api/purchases`,
    PURCHASE_SINGLE: `${BASE_URL}/api/purchase`,
    PURCHASE_CREATE: `${BASE_URL}/api/purchase/new`,
    PURCHASEPRODUCTS:`${BASE_URL}/api/productpurchases`,
    PURCHASEPRODUCTS_SINGLE:`${BASE_URL}/api/productpurchase`,
    //purchase return
    PURCHASE_RETURN: `${BASE_URL}/api/purchasereturns`,
    PURCHASE_RETURN_SINGLE:`${BASE_URL}/api/purchasereturn`,
    PURCHASE_RETURN_CREATE: `${BASE_URL}/api/purchasereturn/new`,
    //supplier product link
    SUPPLIER_PRODUCT_LINK: `${BASE_URL}/api/supplierproducstlink`,
    SUPPLIER_PRODUCT_LINK_SINGLE:`${BASE_URL}/api/supplierproductlink`,
    SUPPLIER_PRODUCT_LINK_CREATE: `${BASE_URL}/api/supplierproductlink/new`,
    //sell
    SALES: `${BASE_URL}/api/sales`,
    SALES_CREATE: `${BASE_URL}/api/sale/new`,
    //draft
    DRAFT: `${BASE_URL}/api/drafts`,
    DRAFT_SINGLE:`${BASE_URL}/api/draft`,
    DRAFT_CREATE: `${BASE_URL}/api/draft/new`,
    //quotation
    QUOTATION: `${BASE_URL}/api/quotaions`,
    QUOTATION_SINGLE: `${BASE_URL}/api/quotaion`,
    QUOTATION_CREATE: `${BASE_URL}/api/quotaion/new`,
    //pos
    POS: `${BASE_URL}/api/pos`,
    POS_CREATE:`${BASE_URL}/api/pos/new`,
    POS_SINGLE:`${BASE_URL}/api/pos`, 
    //sell return
    SELLRETURN: `${BASE_URL}/api/sellreturns`,
    SELLRETURN_CREATE:`${BASE_URL}/api/sellreturn/new`,
    SELLRETURN_SINGLE:`${BASE_URL}/api/sellreturn`,
    //settings
    UPDATE_SETTINGS: `${BASE_URL}/api/settings`,
    //business location
    BUSINESS_LOCATION: `${BASE_URL}/api/businesslocations`,
    BUSINESS_LOCATION_CREATE: `${BASE_URL}/api/businesslocation/new`,
    BUSINESS_LOCATION_SINGLE: `${BASE_URL}/api/businesslocation`,
    BUSINESS_LOCATION_LASTINDEX: `${BASE_URL}/api/busilocationlastindex`,
    //taxrate
    TAXRATE: `${BASE_URL}/api/taxrates`,
    TAXRATE_CREATE: `${BASE_URL}/api/taxrate/new`,
    TAXRATE_SINGLE: `${BASE_URL}/api/taxrate`,
    //alpharate
    ALPHARATE: `${BASE_URL}/api/alpharates`,
    ALPHARATE_CREATE: `${BASE_URL}/api/alpharate/new`,
    ALPHARATE_SINGLE: `${BASE_URL}/api/alpharate`,
    //payment integratin
    PAYMENTINTEGRATION: `${BASE_URL}/api/payments`,
    PAYMENTINTEGRATION_CREATE: `${BASE_URL}/api/payment/new`,
    PAYMENTINTEGRATION_SINGLE: `${BASE_URL}/api/payment`,
    //estimate
    ESTIMATE :`${BASE_URL}/api/estimates`,
    ESTIMATE_CREATE :`${BASE_URL}/api/estimate/new`,
    ESTIMATE_SINGLE :`${BASE_URL}/api/estimate`,
    //estimate draft
    ESTIMATE_DRAFTS:`${BASE_URL}/api/estimatedrafts`,
    ESTIMATE_DRAFT_CREATE:`${BASE_URL}/api/estimatedraft/new`,
    ESTIMATE_DRAFT_SINGLE:`${BASE_URL}/api/estimatedraft`,
    //estimate quotation
    ESTIMATE_QUOTATIONS:`${BASE_URL}/api/estimatequoations`,
    ESTIMATE_QUOTATION_CREATE:`${BASE_URL}/api/estimatequoation/new`,
    ESTIMATE_QUOTATION_SINGLE:`${BASE_URL}/api/estimatequoation`,
    //stock
    STOCK :`${BASE_URL}/api/stocks`,
    STOCK_CREATE :`${BASE_URL}/api/stock/new`,
    STOCK_SINGLE :`${BASE_URL}/api/stock`,
    //user new
    USERNEW: `${BASE_URL}/api/usersnew`,
    USERNEW_CREATE: `${BASE_URL}/api/usernew/new`,
    USERNEW_SINGLE: `${BASE_URL}/api/usernew`, 

}