import React, { useState, useEffect, useContext } from 'react';
import { Button, OutlinedInput, TextField, Select, MenuItem, TextareaAutosize, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Grid, FormControl, Box, } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { userStyle,colourStyles } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import Selects from "react-select";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function Vouchereditlist() {

    const { auth } = useContext(AuthContext);
    const [setngs, setSetngs] = useState();

    const exceptThisSymbols = ["e", "E", "+", "-", "."];


    const [newdata, setNewdata] = useState([]);
    const [options, setOptions] = useState([])

    //  Add Text Field
    const [voucher, setVoucher] = useState({
        voucherno: "", date: "", fromto: "", forwhat: "", amount: "", paymode: "", accountgroup: "", entry: "", remarks: "",
    });

    const [groupCreate, setGroupCreate] = useState({
        groupautoid: "", groupname: "",
    });

    
    let newvals = setngs ? setngs.groupsku + "0001" : "GR0001";
    const [grpcreation, setGroupCreation] = useState([])

    const [warn, setWarn] = useState(false)

    //  Modal
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
        setWarn(false)
        setGroupCreate({ ...groupCreate, groupname: "" })
    };

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => {
        setIsErrorOpen(false);
        setWarn(false)
    };

    
    //voucher auto id from settings
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // GROUP_CREATION
    const fetchGroupCreation = async () => {
        try {
            let req = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setGroupCreation(req.data.groupmasters);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

 
    const id = useParams().id;

    // Fetch Voucher
    const fetchVoucher = async () => {
        try {
            let req = await axios.get(`${SERVICE.VOUCHER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setVoucher(req.data.svoucher);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    useEffect(() => {
        fetchVoucher();
        
    }, [id])

    // Fetch customer accountgroup 
    const fetchgroup = async () => {
        try {
            let res_group = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.groupmasters?.map((d) => {
                return d.groupname
            })
            setNewdata(masters_data);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    let newarray = [...new Set(newdata)];

    // fetch Options
    const fetchOptions = async () => {
        try {
            setOptions(
                newarray.map((d) => (
                    {
                        ...d,
                        label: d,
                        value: d,
                    }
                ))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    useEffect(() => {
        fetchOptions();
    }, [options])

    // AccountGroup handlechange
    const handleAccountGroup = (newValue, actionMeta) => {
        setVoucher({ ...voucher, accountgroup: newValue.value })
    };

    const backPage = useNavigate();

    //   New group creation
    const addGroup = async () => {
        try {
            let req = await axios.post(SERVICE.GROUP_CREATION_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                groupautoid: String(newvals),
                groupname: String(groupCreate.groupname)
            },
            );
            setGroupCreation(req.data);
            handleModalClose();
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
        }
    };

    //New voucher 
    const updatavoucher = async () => {
        try {
            let req = await axios.put(`${SERVICE.VOUCHER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                voucherno: String(voucher.voucherno),
                date: String(voucher.date),
                fromto: String(voucher.fromto),
                forwhat: String(voucher.forwhat),
                amount: Number(voucher.amount),
                paymode: String(voucher.paymode),
                accountgroup: String(voucher.accountgroup),
                entry: String(voucher.entry),
                remarks: String(voucher.remarks),
            });
            setVoucher(req.data);
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/account/voucher/list')

        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };

    //submit button 
    const handleSubmit = (e) => {
        e.preventDefault();
        if (voucher.fromto == "") {
            setShowAlert("Please enter From / To!")
            handleClickOpen();
        }
        else if (voucher.forwhat == "") {
            setShowAlert("Please enter For what!")
            handleClickOpen();
        }
        else if (voucher.amount == "") {
            setShowAlert("Please enter Amount!")
            handleClickOpen();
        }
        else if (voucher.accountgroup == "") {
            setShowAlert("Please enter Account group!")
            handleClickOpen();
        }
        else {
            updatavoucher();
        }
    }

    //submit button 
    const handleSubmitModal = (e) => {
        e.preventDefault();
        if (groupCreate.groupname == "") {
            setShowAlert("Please enter Account group!")
            setWarn(true)
        } else {
            addGroup();
        }
    }

    const handleValidationfrom = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setVoucher({ ...voucher, fromto: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setVoucher({ ...voucher, fromto: value })
        }
    }

    const handleValidationwhat = (e) => {
        let val = e.target.value;
        let regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter and number only! (a-z & 0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setVoucher({ ...voucher, forwhat: value })
        }
    }
    const handleValidationRemark = (e) => {
        let val = e.target.value;
        let regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter and number only! (a-z & 0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setVoucher({ ...voucher, forwhat: value })
        }
    }

    useEffect(
        () => {
            fetchGroupCreation();
            fetchgroup();
            fetchSettings();
        }, [grpcreation]
    )

    return (

        <Box>
            <Headtitle title={'Edit Voucher Entry'} />
            <Typography sx={userStyle.HeaderText}>Edit Voucher Entry</Typography>
            <Box sx={userStyle.filtercontent}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Voucher No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={voucher.voucherno}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} lg={4} xs={12} sx={{ display: 'flex' }}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Date</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={voucher.date}
                                        onChange={(newValue) => {
                                            setVoucher({ ...voucher, date: newValue })
                                        }}
                                        renderInput={(props) => <TextField {...props}  size="small" />}
                                        size="small"
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>From / To <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={voucher.fromto}
                                    onChange={(e) => { setVoucher({ ...voucher, fromto: e.target.value }); handleValidationfrom(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>For what ? <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={voucher.forwhat}
                                    onChange={(e) => { setVoucher({ ...voucher, forwhat: e.target.value }); handleValidationwhat(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Amount <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={voucher.amount}
                                        onChange={(e) => { setVoucher({ ...voucher, amount: e.target.value }) }}
                                        type="number"
                                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Pay mode</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Select
                                  
                                    id="demo-simple-select"
                                    value={voucher.paymode}
                                    onChange={(e) => { setVoucher({ ...voucher, paymode: e.target.value }) }}
                                >
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="UPI">UPI</MenuItem>
                                    <MenuItem value="Account Master">Account Master</MenuItem>
                                    <MenuItem value="Card">Card</MenuItem>
                                    <MenuItem value="Net Banking">Net Banking</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Account Group <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <Selects
                                        value={{ value: voucher.accountgroup, label: voucher.accountgroup }}
                                        onChange={(e) => { handleAccountGroup(e); }}
                                        styles={colourStyles}
                                        options={options}
                                    />
                                </FormControl>
                                <Grid sx={userStyle.spanText} onClick={handleModalOpen} ><b>+</b></Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                        </Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Entry</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Select
                                 
                                    id="demo-simple-select"
                                    value={voucher.entry}
                                    onChange={(e) => { setVoucher({ ...voucher, entry: e.target.value }) }}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Credit">Credit</MenuItem>
                                    <MenuItem value="Debit">Debit</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Remarks</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                    value={voucher.remarks}
                                    onChange={(e) => { setVoucher({ ...voucher, remarks: e.target.value });handleValidationRemark(e) }}
                                    label="remarks"
                                    name='remarks'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <br />
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Link to="/account/voucher/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                                <Button sx={userStyle.buttonadd} type='submit' >Update</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            {/* Popup */}
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">Group Creation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {warn ? <p style={{ color: "red" }}>Please enter group name!</p> : null}
                        <Box sx={userStyle.container}>
                            <Grid container spacing={1}>
                                <Grid item lg={3} md={3}>
                                    {grpcreation.length > 0 && (
                                        grpcreation.map(
                                            () => {
                                                let strings = "GR";
                                                let refNo = grpcreation[grpcreation.length - 1].groupautoid;
                                                let digits = (grpcreation.length + 1).toString();
                                                const stringLength = refNo.length;
                                                let lastChar = refNo.charAt(stringLength - 1);
                                                let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                                let getlastThreeChar = refNo.charAt(stringLength - 3);
                                                let lastBeforeChar = refNo.slice(-2);
                                                let lastThreeChar = refNo.slice(-3);
                                                let lastDigit = refNo.slice(-4);
                                                let refNOINC = parseInt(lastChar) + 1
                                                let refLstTwo = parseInt(lastBeforeChar) + 1;
                                                let refLstThree = parseInt(lastThreeChar) + 1;
                                                let refLstDigit = parseInt(lastDigit) + 1;
                                                if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                                    refNOINC = ("000" + refNOINC);
                                                    newvals = strings + refNOINC;
                                                } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                                    refNOINC = ("00" + refLstTwo);
                                                    newvals = strings + refNOINC;
                                                } else if (digits.length < 4 && getlastThreeChar > 0) {
                                                    refNOINC = ("0" + refLstThree);
                                                    newvals = strings + refNOINC;
                                                } else {
                                                    refNOINC = (refLstDigit);
                                                    newvals = strings + refNOINC;
                                                }
                                            }))}
                                    <Typography>Reference No</Typography>
                                </Grid>
                                <Grid item lg={9} md={9}>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={newvals}
                                        />
                                    </FormControl>
                                </Grid><br />
                                <Grid item lg={3} md={3}>
                                    <Typography>Account Group <b style={{ color: 'red' }}>*</b></Typography>
                                </Grid>
                                <Grid item lg={9} md={9}>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={groupCreate.groupname}
                                            onChange={(e) => { setGroupCreate({ ...groupCreate, groupname: e.target.value, groupautoid: newvals }); }}
                                        />
                                    </FormControl>
                                </Grid><br />
                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" disableRipple onClick={handleSubmitModal} type="submit">SAVE</Button>
                    <Button variant='contained' color='error' onClick={handleModalClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function Voucheredit() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Vouchereditlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Voucheredit;