import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function Customerbillsreport() {

    const [customerbills, setCustomerbills] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    //  Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;

    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today,
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require("datatables.net");
    const tableRefCusbill = useRef();

    // Customer bills
    const fetchCustomerbills = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_BILL, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.customerbills.filter((data) => {
                let dateTrim = moment(data.billdate).format('YYYY-MM-DD');
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                    return data
                }
            })
            setCustomerbills(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefCusbill.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchCustomerbills();
    }, [])

    // Excel
    const fileName = 'Customerbillsreport'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = customerbills.map(t => ({
            "Reference No": t.referenceno, "Name": t.name, "Bill No": t.billno, "Bill Date": moment(t.billdate).format("DD-MM-YYYY"), "Due Date": t.duedate == "" ? "" : moment(t.duedate).format("DD-MM-YYYY"),
            "Bill Amount": t.billamount.toFixed(2), "Narration": t.narration,
        }));
        setExceldata(data);
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [customerbills]
    )

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CUSTOMER BILLS REPORT',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Customerbillsreport' })
        doc.save('Customerbillsreport.pdf')
    }

    return (

        <Box >
            <Headtitle title={'Customer Bills Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Customer Bills Report List</Typography>
            <Box sx={userStyle.container}>
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={4} md={4}>
                        <Grid container >
                            <Grid item lg={2} md={2}>
                                <Typography sx={{ marginTop: 1 }}>From</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.startdate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="startdate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Grid container>
                            <Grid item lg={1} md={1}>
                                <Typography sx={{ marginTop: 1 }}>To</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.enddate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="enddate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <Button variant='contained' color='secondary' onClick={fetchCustomerbills}>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvcustomerbillsreport && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelcustomerbillsreport && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printcustomerbillsreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfcustomerbillsreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Customerbillsreport" ref={tableRefCusbill}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Bill No</StyledTableCell>
                                    <StyledTableCell>Bill Date</StyledTableCell>
                                    <StyledTableCell>Due Date</StyledTableCell>
                                    <StyledTableCell>Bill Amount</StyledTableCell>
                                    <StyledTableCell>Narration</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customerbills.length > 0 && (
                                    customerbills?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.billno}</StyledTableCell >
                                            <StyledTableCell align="left">{moment(row.billdate).format("DD-MM-YYYY")}</StyledTableCell>
                                            <StyledTableCell align="left">{row.duedate == "" ? "" : moment(row.duedate).format("DD-MM-YYYY")}</StyledTableCell>
                                            <StyledTableCell align="left">{row.billamount.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.narration}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>

            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Customer Bills Report Print</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="Customerbillsreport">
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Reference No</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Bill No</StyledTableCell>
                                        <StyledTableCell>Bill Date</StyledTableCell>
                                        <StyledTableCell>Due Date</StyledTableCell>
                                        <StyledTableCell>Bill Amount</StyledTableCell>
                                        <StyledTableCell>Narration</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {customerbills.length > 0 && (
                                        customerbills?.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                                <StyledTableCell align="left">{row.billno}</StyledTableCell >
                                                <StyledTableCell align="left">{moment(row.billdate).format("DD-MM-YYYY")}</StyledTableCell>
                                                <StyledTableCell align="left">{row.duedate == "" ? "" : moment(row.duedate).format("DD-MM-YYYY")}</StyledTableCell>
                                                <StyledTableCell align="left">{row.billamount.toFixed(2)}</StyledTableCell>
                                                <StyledTableCell align="left">{row.narration}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>

        </Box>
    );
}

function Customerbillsreportlist() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Customerbillsreport /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Customerbillsreportlist;