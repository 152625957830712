import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Grid, Paper, Typography, TableContainer, Table, TableHead, TableBody, FormControl, InputLabel, } from '@mui/material';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import $ from 'jquery';
import { userStyle,colourStyles } from '../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Selects from "react-select";
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import Headtitle from '../../components/header/Headtitle';
import moment from 'moment';

function Item() {

  const [productslist, setProductsList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [idproduct, setIdproduct] = useState({});
  const [purchases, setPurchases] = useState([]);
  const [values, setValues] = useState([]);
  const { auth } = useContext(AuthContext);

  // Products
  const fetchProducts = async () => {
    try {
      let productlist = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      setProductsList(
        productlist?.data?.products?.map((d) => ({
          ...d,
          label: d.prodname + "-" + d.prodcode,
          value: d.prodname + "-" + d.prodcode,
        }))
      );
      await fetchPurchase(productlist.data.products)
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const searchProd = async (id) => {
    try {
      let res = await axios.get(`${SERVICE.PRODUCT_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setIdproduct(res?.data?.sproduct);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  let arr = []

  const fetchPurchase = async (allproducts) => {
    try {
      let res = await axios.get(SERVICE.PURCHASEPRODUCTS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      res.data.purchases.filter((item) => {
        console.log(item);
        allproducts.forEach((valuedata) => {
          let data = item
          if (item.sku == valuedata.prodcode) {
            setPurchases(
              data = [{ ...item, currentstock: valuedata.currentstock }]
            )
            return data
          }
        })
      })
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  // console.log(purchases)
  let currentdate = new Date();
  let current = moment(currentdate).utc().format("YYYY-MM-DD")

  const getPurchases = (id) => {
    let result = purchases.map((item, index) => {
      if (id == item.sku) {
        return item
      }
    })
    setTableData(result)

  };

  // total
  function totalNetCostCalc() {
    let totalvalue = 0;
    tableData.forEach((t) => {
      console.log(t);
      if (current >= t.expirydate) {
        totalvalue += Number(t.quantity)
        return totalvalue
      }
    })
    return totalvalue.toFixed(0);
  }
  let totalvalue = 0;
  useEffect(() => {
    fetchProducts();
  }, [])

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  return (
    <Box >
      <Typography variant="body2" sx={userStyle.HeaderText}>Item Search</Typography>
      <Headtitle title={'Item Search'} />
      <Box sx={userStyle.container}>
        <Grid container spacing={3} sx={userStyle.textInput}>
          <Grid item md={6} sm={12} xs={12}>
            <InputLabel htmlFor="component-outlined">Keywords:</InputLabel>
            <FormControl size="small" fullWidth>
              <Selects
                options={productslist}
                styles={colourStyles}
                label="walk-in-customer"
                sx={{ height: "20px" }}
                onChange={(e) => {
                  searchProd((e._id));
                  getPurchases(e.prodcode)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} sm={12} xs={12}></Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Product Code:</b>   {idproduct.prodcode}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Product Name:</b>  {idproduct.prodname}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Category:</b>  {idproduct.category}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Schedule:</b>  {idproduct.schedule}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Manufacture:</b>  {idproduct.manufacture}
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Rack:</b>  {idproduct.rack}
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Row:</b>  {idproduct.row}
            </Typography>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Box No:</b> {idproduct.boxno}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              {idproduct.applicabletax != "" ? (<><b>Tax:</b> {idproduct.applicabletax}</>) : (<><b>HSN:</b> {idproduct.hsn}</>)}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="body2" sx={{ marginTop: "5px", marginLeft: '15px' }}>
              <b>Molecule:</b>  {idproduct.molecules}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <br />
      <Typography variant="body2" sx={userStyle.importheadtext}>Stock Details</Typography>
      <Box sx={userStyle.container} >
        { /* Table Start */}
        <TableContainer component={Paper} >
          <Table aria-label="simple table" id="producttable" ref={tableRef}>
            <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
              <StyledTableRow>
                <StyledTableCell >GRN</StyledTableCell>
                <StyledTableCell >Batch</StyledTableCell>
                <StyledTableCell >Expiring Stock (pack)</StyledTableCell>
                <StyledTableCell >Packing</StyledTableCell>
                <StyledTableCell >Stock (piece)</StyledTableCell>
                <StyledTableCell >Purchase_date</StyledTableCell>
                <StyledTableCell >MRP</StyledTableCell>
                <StyledTableCell >Supplier Name</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                tableData.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{row.invoicenumber}</StyledTableCell>
                    <StyledTableCell align="left">{row.batch}</StyledTableCell>
                    <StyledTableCell align="left">{row.expirydate}</StyledTableCell>
                    <StyledTableCell align="left">{row.packingcharge}</StyledTableCell>
                    <StyledTableCell align="left">{row.currentstock}</StyledTableCell>
                    <StyledTableCell align="left">{moment(row.date).format("DD-MM-YYYY")}</StyledTableCell>
                    <StyledTableCell align="left">{row.purchasenetcost}</StyledTableCell>
                    <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        { /* Table End */}
      </Box>
    </Box>
  )
}

function ItemsList() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Item /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default ItemsList;