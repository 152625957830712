import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { userStyle } from '../../PageStyle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Link } from 'react-router-dom';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import autoTable from 'jspdf-autotable';

function Listbankmasterreport() {

    const [bankMaster, setBankMaster] = useState([]);
    const [bankList, setBankList] = useState({});
    const [exceldata, setExceldata] = useState([])
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Delete popup
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => { setIsDeleteOpen(true); };
    const handleClose = () => { setIsDeleteOpen(false); };

    //List Bank Master
    const fetchbankMasters = async () => {
        try {
            let req = await axios.get(SERVICE.BANKMASTER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBankMaster(req.data.bankmasters);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.BANKMASTER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBankList(res.data.sbankmaster);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //alert delete popup
    let listid = bankList._id;

    const deleteBankmas = async () => {
        try {
            let res = await axios.delete(`${SERVICE.BANKMASTER_SINGLE}/${listid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchbankMasters();
        }, [deleteBankmas]
    )

    // Excel
    const fileName = 'List Bank Master'
    //  get particular columns for export excel
    const getexcelDatas = async () => {
        let data = bankMaster.map(t => ({
            'Account Number': t.accno, 'Name': t.name, 'Bank': t.bank, 'Branch': t.branch, 'IFSC Code': t.ifsccode, 'Remarks': t.remarks
        }));
        setExceldata(data);
    }

    useEffect(() => {
        getexcelDatas()
    }, [exceldata])

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'List Bank Master',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#usertablepdf' })
        doc.save('Bank Master List.pdf')
    }

    //jquery
    $.DataTable = require("datatables.net");
    const tableRef = useRef();
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box >
            <Headtitle title={'List Bank Master'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>List Bank Master</Typography>
            <br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].excelbankmaster && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].csvbankmaster && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printbankmaster && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfbankmaster && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <>
                        <Link to="/bank/bank/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd} style={{ bottom: "3em" }} >ADD</Button></Link>
                    </><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="listbankmaster" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Action</StyledTableCell>
                                    <StyledTableCell>A/C NO</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Bank</StyledTableCell>
                                    <StyledTableCell>Branch</StyledTableCell>
                                    <StyledTableCell>IFSC code</StyledTableCell>
                                    <StyledTableCell>Remarks</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {bankMaster.length > 0 && (
                                    bankMaster.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row" colSpan={1}>
                                                <Grid sx={{ display: 'flex' }}>
                                                    {isUserRoleCompare[0].viewbankmaster && (<Link to={`/bank/bank/view/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                    {isUserRoleCompare[0].editbankmaster && (<Link to={`/bank/bank/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                    {isUserRoleCompare[0].deletebankmaster && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>)}
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.accno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.bank}</StyledTableCell >
                                            <StyledTableCell align="left">{row.branch}</StyledTableCell>
                                            <StyledTableCell align="left">{row.ifsccode}</StyledTableCell>
                                            <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}

                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined">Cancel</Button>
                            <Button onClick={(e) => deleteBankmas(listid)} autoFocus variant="contained" color='error'> OK </Button>
                        </DialogActions>
                    </Dialog>

                    { /* ****** Print ****** */}
                    <Box>
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table sx={{ minWidth: 600, }} aria-label="customized table" id="usertablepdf" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <Typography>List Bank Master</Typography>
                                    <StyledTableRow >
                                        <StyledTableCell>A/C NO</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Bank</StyledTableCell>
                                        <StyledTableCell>Branch</StyledTableCell>
                                        <StyledTableCell>IFSC code</StyledTableCell>
                                        <StyledTableCell>Remarks</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {bankMaster.length > 0 && (
                                        bankMaster.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.accno}</StyledTableCell>
                                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                                <StyledTableCell align="left">{row.bank}</StyledTableCell >
                                                <StyledTableCell align="left">{row.branch}</StyledTableCell>
                                                <StyledTableCell align="left">{row.ifsccode}</StyledTableCell>
                                                <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </>
        </Box>
    );
}

function Banklist() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Listbankmasterreport /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Banklist;