import React, { useState, useEffect, useContext } from 'react';
import { Grid,Box, Button, MenuItem, Menu } from '@mui/material';
import { navbarStyle } from './Style';
import { BiCalculator } from 'react-icons/bi';
import { CgMicrosoft } from 'react-icons/cg';
import { MoneyOutlined, Logout } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import Calculator from './Calculator';
import axios from 'axios';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { AUTH } from '../../services/Authservice';

const Header = () => {

  //***** Action button *****//
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => { setAnchorEl(event.currentTarget); };
  const handleClose = () => { setAnchorEl(null); };

  const [anchorElad, setAnchorElad] = useState(null);
  const openad = Boolean(anchorElad);
  const handleClickad = (event) => { setAnchorElad(event.currentTarget); };
  const handleClosead = () => { setAnchorElad(null); };

  const { auth, setAuth } = useContext(AuthContext);
  const [setngs, setSetngs] = useState();
    // get settings data
    const fetchSettings = async () => {
      try {
        var result = await axios.get(SERVICE.UPDATE_SETTINGS,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
              }

        });
        setSetngs(result.data.settingid);
      } catch (err) {
          const messages = err.response.data.message;
          toast.error(messages);
      }
      }
      useEffect(
          () => {
          fetchSettings();
          }, []
      )

  const backLPage = useNavigate();

  const logOut = async () => {
    try {
      let res = await axios.get(AUTH.LOGOUT,{
        headers: {
          'Authorization':`Bearer ${auth.APIToken}`
        }
      })
      //change login state
      setAuth({ ...auth, loginState: false });
      toast.success(res.data.message);
      localStorage.clear();
      backLPage('/signin');
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  return (
    <>
    <Box sx={{display:'flex'}}>
        <Grid container sx={{ justifyContent: 'flex-start', color:'white', marginTop:'3px', fontSize:'18px', fontWeight:600 }}>
          <img src={setngs && setngs.businesslogo} width="50px" height="50px" alt="HilifeLogo" />&ensp;
          <p>{setngs && setngs.businessname}</p>
        </Grid>
        <Grid container sx={{ justifyContent: 'flex-end' }}>
        <Button sx={navbarStyle.navbarrightbtn}>+</Button>
        <>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disableElevation
            onClick={handleClick}
            sx={navbarStyle.navbarrightbtn}
          >
            <BiCalculator></BiCalculator>
          </Button>
          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem>
              <Calculator />
            </MenuItem>
          </Menu>
        </>
        <Link to="/sell/pos/create"><Button sx={navbarStyle.navbarrightbtn}><CgMicrosoft />&ensp;POS</Button></Link>
        <Button sx={navbarStyle.navbarrightbtn}><MoneyOutlined /></Button>
        <>
          <Button
            // onClick={handleClickad}
            onClick={logOut}
            sx={navbarStyle.navbarrightbtn}
          >
            <Logout />&ensp;Log out
          </Button>
            <Menu
            id="demo-customized-menu"
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorElad}
            open={openad}
            onClose={handleClosead}
            >
            <MenuItem  sx={{
                '& .MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper' :{
                    borderRadius: '0px', 
                    boxShadow: 'none',
                    transition: 'none',
                    boxShadow: 'none',
            }
            }}>
                <Grid container spacing={2}  sx={{
                '& .MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper' :{
                    borderRadius: '0px', 
                    boxShadow: 'none',
                    transition: 'none',
                    boxShadow: 'none',
            }
            }}>
                <Grid item md={12} fullWidth sx={{height:'100px', justifyContent: 'center', alignItems:'center', textAlign:'center'}}>
                <img src="" style={{width:'100px', height:'100px'}}></img>
                </Grid>
                <Grid item md={12} sx={{justifyContent: 'center !important',alignItems:'center', textAlign:'center'}}>
                </Grid>
                
                <Grid item md={12}>
                    <Grid container>
                    <Grid item md={4}>
                        {/* <Link to={`/profile/edit/${id_quote}`} key={index} style={{ textDecoration: 'none', color: 'white' }}><Button sx={navbarStyle.navbarrightbtn}>Profile</Button></Link> */}
                    </Grid>
                    <Grid item md={4}>   </Grid>
                    <Grid item md={4}>
                        <Button sx={navbarStyle.navbarrightbtn} onClick={logOut}><Logout />&ensp;Log out</Button>
                    </Grid>
                    </Grid>
                </Grid>
                </Grid>
            </MenuItem>
            </Menu>
        </>
      </Grid>
    </Box>
    </>
  )
}

export default Header;