import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../../../PageStyle';
import Navbar from '../../../../components/header/Navbar';
import Footer from '../../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../../context/Appcontext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { SERVICE } from '../../../../services/Baseservice';
import { AuthContext } from '../../../../context/Appcontext';


function HSNwisereportretailsalesreturnlist() {
    const [HSNwisereportretailsalesreturn, setHSNwisereportretailsalesreturn] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const [dateFilter, setDateFilter] = useState({
        startdate: "", enddate: "",
    });
    const { auth, setAuth } = useContext(AuthContext);

    //  HSN Wise Report Retail Sales Return...........
    const fetchCustomerPayment = async () => {
        try {
            let customer = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = customer.data.customerpays.filter((data) => {
                let startdatefromsupplier = dateFilter.startdate;
                let enddatefromsupplier = dateFilter.enddate;
                return startdatefromsupplier <= data.createdAt && new Date(enddatefromsupplier) + 1 >= data.createdAt
            })
            setAuth({ ...auth, loginState: true });
            setHSNwisereportretailsalesreturn(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    console.log(tableRef.current)
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 25, 50, 100, 200, 500, -1],
                            [10, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    useEffect(
        () => {

        }, [dateFilter]
    )

    // Excel...........
    const fileName = " HSN Wise Report Retail Sales Return";
    const customerexcel = async () => {
        let res_customerpayment = await axios.get('http://localhost:8003/api/HSNwisereportretailsalesreturn');
        var data = res_customerpayment.data.customerpayment.map(t => ({
            contactid: t.contactid, firstname: t.firstname,
            lastname: t.lastname, email: t.email, creditlimit: t.creditlimit, payterm: t.payterm, paytermassign: t.paytermassign,
            openbalance: t.openbalance, customergroup: t.customergroup, contactperson: t.contactperson,
            addressone: t.addressone, addresstwo: t.addresstwo, phonenumber: t.phonenumber, whatsappno: t.whatsappno
        }));
        setExceldata(data);
    }

    //  PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#HSNwisereportretailsalesreturn' })
        doc.save('HSNwisereportretailsalesreturn.pdf')
    }
    useEffect(
        () => {
            // fetchCustomerPayment();
            customerexcel();
        }, []
    )
    let total = 0;

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                console.log(tableRef.current)
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 25, 50, 100, 200, 500, -1],
                        [10, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });

    }, [])

    return (
        <Box >
            <Headtitle title={'HSN Wise Report Retail Sales Return'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>HSN Wise Report Retail Sales Return<Typography sx={userStyle.SubHeaderText}></Typography></Typography>
            <Box sx={userStyle.container} >
                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={4} md={4}>
                        <Grid container lg={12} md={12} >
                            <Grid item lg={3} md={3}>
                                <Typography sx={{ marginTop: 1 }}>Date From</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><CalendarMonthOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.startdate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="startdate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Grid container lg={12} md={12} >
                            <Grid item lg={1} md={1}>
                                <Typography sx={{ marginTop: 1 }}>To</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><CalendarMonthOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.enddate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="enddate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={1} md={1}>
                        <Button variant='contained' color='secondary'
                            onClick={(e) => { fetchCustomerPayment() }}
                        >Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid>
                            <ExportCSV csvData={exceldata} fileName={fileName} />
                            <ExportXL csvData={exceldata} fileName={fileName} />
                            <Button sx={userStyle.buttongrp} onClick={(e) => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            <Link to="/contact/customer/HSNwisereportretailsalesreturnprint"><Button sx={userStyle.buttongrp} style={{ textDecoration: 'none', color: 'white', '&:hover': { color: 'red', backgroundColor: 'yellow' } }}><FaPrint />&ensp;Export to Print</Button></Link>
                        </Grid>
                        {/* ****** Table Grid Container ****** */}
                        <Grid container>
                            <Grid md={4} sm={2} xs={1}></Grid>
                            <Grid md={8} sm={10} xs={10} sx={{ align: "center" }}></Grid>
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="HSNwisereportretailsalesreturn" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Seriale No</StyledTableCell>
                                    <StyledTableCell>Description</StyledTableCell>
                                    <StyledTableCell>HSN/SAC</StyledTableCell>
                                    <StyledTableCell>Quantity</StyledTableCell>
                                    <StyledTableCell>UOM</StyledTableCell>
                                    <StyledTableCell>Rate Of Tax</StyledTableCell>
                                    <StyledTableCell>Taxable Value</StyledTableCell>
                                    <StyledTableCell>IGST Amount</StyledTableCell>
                                    <StyledTableCell>CGST Amount</StyledTableCell>
                                    <StyledTableCell>SGST/UTGST Amount</StyledTableCell>
                                    <StyledTableCell>CESS</StyledTableCell>
                                    <StyledTableCell>Total Tax</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {HSNwisereportretailsalesreturn.length > 0 && (
                                    HSNwisereportretailsalesreturn.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell >
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                            <StyledTableCell align="left">{row.reference}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
        </Box>
    );
}
function HSNwisereportretailsalesreturn() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <HSNwisereportretailsalesreturnlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default HSNwisereportretailsalesreturn;