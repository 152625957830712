import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function BankBookReportList() {

  const [bankDetails, setBankDetails] = useState([]);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  const fetchBankDetails = async () => {
    try {
      let req = await axios.get(SERVICE.BANKBOOK, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let getDatawithFilter = req.data.bankbooks.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else
          if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
          }
          else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
          }
          else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
          }
      })
      setBankDetails(getDatawithFilter);
      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
  }, [tableRef.current])

  // Export Excel
  const fileName = 'Bank Book Report';

  const [exceldata, setExceldata] = useState([])

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = bankDetails.map(t => ({
      'Reference No': t.referenceno, 'Date': moment(t.date).format("DD-MM-YYYY"), 'Account No': t.accountno, 'Account Type': t.accounttype, 'Amount': t.amount, 'Payment Method': t.paymentmethod
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()

  }, [exceldata])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | BANK BOOK REPORT',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#bankbookreport' })
    doc.save('Bank Book Report.pdf')
  }

  useEffect(() => {
    fetchBankDetails();
  }, [])

  return (
    <Box >
      <Headtitle title={'Bank Book Report'} />
      { /* ************************************************** Header Content ****************************************************** */}
      <Typography variant='body2' sx={userStyle.HeaderText}> Bank Book Report <Typography sx={userStyle.SubHeaderText}></Typography></Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container  >
              <Grid item lg={2} md={2}>
                <Typography variant='body2' sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography variant='body2' sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchBankDetails}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />

      { /* ******************************************************EXPORT Buttons****************************************************** */}
      <>
        <Box sx={userStyle.container} >
          <Grid container sx={{ justifyContent: "center" }} >
            <Grid >
              {isUserRoleCompare[0].excelbankbookreport && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].csvbankbookreport && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printbankbookreport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfbankbookreport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid>
          <br />
          { /*  *******************************************Table Start  *******************************************p*/}
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700, }} aria-label="customized table" id="bankbookreport" ref={tableRef}>
                <TableHead>
                  <StyledTableRow >
                    <StyledTableCell>Reference No</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Bank A/C No</StyledTableCell>
                    <StyledTableCell>Account Group</StyledTableCell>
                    <StyledTableCell>Account Type</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Payment Method</StyledTableCell>
                    <StyledTableCell>Narration</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {bankDetails.length > 0 && (
                    bankDetails.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                        <StyledTableCell align="left">{row.date == "" ? "" : moment(row.date).format("DD-MM-YYYY")}</StyledTableCell>
                        <StyledTableCell align="left">{row.accountno}</StyledTableCell >
                        <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                        <StyledTableCell align="left">{row.accounttype}</StyledTableCell>
                        <StyledTableCell align="left">{row.amount}</StyledTableCell>
                        <StyledTableCell align="left">{row.paymentmethod}</StyledTableCell>
                        <StyledTableCell align="left">{row.narration}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box >
          { /* *********************************************print layout **********************************************/}
          <TableContainer component={Paper} sx={userStyle.printcls} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" ref={componentRef}>
              <TableHead>
                <StyledTableRow >
                  <StyledTableCell>Reference No</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>Bank A/C No</StyledTableCell>
                  <StyledTableCell>Account Group</StyledTableCell>
                  <StyledTableCell>Account Type</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Payment Method</StyledTableCell>
                  <StyledTableCell>Narration</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {bankDetails.length > 0 && (
                  bankDetails.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                      <StyledTableCell align="left">{moment(row.date).format("DD-MM-YYYY")}</StyledTableCell>
                      <StyledTableCell align="left">{row.accountno}</StyledTableCell >
                      <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                      <StyledTableCell align="left">{row.accounttype}</StyledTableCell>
                      <StyledTableCell align="left">{row.amount}</StyledTableCell>
                      <StyledTableCell align="left">{row.paymentmethod}</StyledTableCell>
                      <StyledTableCell align="left">{row.narration}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Box>
  );
}

function BankBookReport() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <BankBookReportList /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default BankBookReport;