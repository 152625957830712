import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, InputLabel, OutlinedInput, Select, MenuItem, FormGroup, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button, } from "@mui/material";
import { userStyle } from "../../PageStyle";
import Navbar from "../../../components/header/Navbar";
import Footer from "../../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../../components/Table";
import axios from "axios";
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { ExportXL, ExportCSV } from "../../Export";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import Headtitle from "../../../components/header/Headtitle";
import $, { data } from "jquery";
import { UserRoleAccessContext } from "../../../context/Appcontext";
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from "../../../context/Appcontext";
import { useReactToPrint } from "react-to-print";

function Customerduesreport() {

  const [pos, setPos] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);
  const [checked, setChecked] = useState(true);
  const [code, setCode] = useState([]);

  // Daysfield
  const [daysFilter, setDaysFilter] = useState({
    dayoption: "", dayvalue: "",
  });

  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  // Days Difference
  let totalDays;

  //jquery
  $.DataTable = require("datatables.net");
  const tableRef = useRef();

  // Select all function 
  const fetchCustomerDues = async (e) => {
    setChecked(!checked)
    try {
      let response = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let req = response.data.pos1.filter((item) => {
        totalDays = (Number(item.creditdays)) - ((new Date().getDate() - new Date(item.date).getDate()) + 1)
        if (item.dueamount > 0 && totalDays > 0) {
          if (checked === true) {
            return item
          }
          else if (checked === false) {
            setPos([])
          }
        }
      })
      setPos(req)
      setDaysFilter({
        dayoption: "", dayvalue: "",
      })
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  const getCateName = (e) => {
    if (e) {
      setPos([]) || setChecked(true)
    }
    setCode(e.dayoption)
  }

  // fetch customer details from pos
  const fetchCustomerDuesReport = async () => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          Authorization: `Bearer ${auth.APIToken}`,
        },
      });

      let filterdata = req.data.pos1.filter((item) => {
        if (item.dueamount > 0) {
          totalDays = (Number(item.creditdays)) - ((new Date().getDate() - new Date(item.date).getDate()) + 1)
          if (daysFilter.dayoption == "" && totalDays > 0 && item.dueamount > 0) {
            return item
          }
          else if (daysFilter.dayoption == "greaterthan" && totalDays > 0 && item.dueamount > 0) {
            return totalDays > daysFilter.dayvalue
          }
          else if (daysFilter.dayoption == "lesserthan" && totalDays > 0 && item.dueamount > 0) {
            return totalDays < daysFilter.dayvalue
          }
          else if (daysFilter.dayoption == code) {
            return item
          }
        }
      })
      setPos(filterdata);

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = "none";
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: "", searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchCustomerDuesReport();
  }, []);

  // Excel
  const fileName = "CustomerDuesReport";
  // get particular columns for export excel
  const getexcelDatas = async () => {
    var data = pos.map((t) => ({
      "Name": t.customer,
      "Bill No": t.referenceno,
      "Due Date": t.duedate,
      "Days Difference": (Number(t.creditdays)) - ((new Date().getDate() - new Date(t.date).getDate()) + 1),
      "Due Amount": t.dueamount,
    }));
    setExceldata(data);
  };

  useEffect(() => {
    getexcelDatas();
  }, [pos]);

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CUSTOMER DUES REPORT',
    pageStyle: 'print'
  });

  //  PDF
  const downloadPdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#Customerduesreport" });
    doc.save("Customerduesreport.pdf");
  };

  return (
    <Box>
      <Headtitle title={"Customer Dues Report"} />
      {/* ****** Header Content ****** */}
      <Typography sx={userStyle.HeaderText}>
        Customer Dues Report List
      </Typography>
      <Box sx={userStyle.container}>
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={2} md={2}>
            <Typography sx={{ marginTop: "7px", float: "right" }}>
              Due Days
            </Typography>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-helper-label" >Please Select</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Please Select"
                value={daysFilter.dayoption}
                onChange={(e) => {
                  setDaysFilter({ ...daysFilter, dayoption: e.target.value });
                  getCateName(e);
                  setChecked(true)
                }}
              >
                <MenuItem value="greaterthan"> &gt; </MenuItem>
                <MenuItem value="lesserthan"> &lt; </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3}>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                placeholder="Enter Days"
                id="component-outlined"
                value={daysFilter.dayvalue}
                onChange={(e) => {
                  setDaysFilter({ ...daysFilter, dayvalue: e.target.value });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormGroup>
              <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onChange={(e) => { fetchCustomerDues(e) }} checked={!checked} />} label="Select All" />
            </FormGroup>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={fetchCustomerDuesReport}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      {/* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container}>
          {/* ****** Header Buttons ****** */}
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid >
              {isUserRoleCompare[0].csvcustduesreport && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelcustduesreport && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printcustduesreport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfcustduesreport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid>
          <br />
          {/* ****** Table start ****** */}
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              id="Customerduesreport"
              ref={tableRef}
            >
              <TableHead sx={{ fontWeight: "600" }}>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Bill No</StyledTableCell>
                  <StyledTableCell>Due Date</StyledTableCell>
                  <StyledTableCell>Day Difference</StyledTableCell>
                  <StyledTableCell>Due Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {pos.length > 0 &&
                  pos.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.customer}</StyledTableCell>
                      <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                      <StyledTableCell align="left">{row.duedate}</StyledTableCell>
                      <StyledTableCell align="left">
                        {(Number(row.creditdays)) - ((new Date().getDate() - new Date(row.date).getDate()) + 1)}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.dueamount}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ****** Table End ****** */}
        </Box>
      </>
      {/* ****** Table End ****** */}

      { /* ****** Print ****** */}
      <Box sx={userStyle.printcls} ref={componentRef}>
        <Box>
          <Typography variant='h5' >Customer Dues Report</Typography>
        </Box>
        <>
          <Box  >
            <TableContainer component={Paper} sx={userStyle.printcls}>
              <Table
                aria-label="simple table"
                id="Customerduesreport"
              >
                <TableHead sx={{ fontWeight: "600" }}>
                  <StyledTableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Bill No</StyledTableCell>
                    <StyledTableCell>Due Date</StyledTableCell>
                    <StyledTableCell>Day Difference</StyledTableCell>
                    <StyledTableCell>Due Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {pos.length > 0 &&
                    pos.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.customer}</StyledTableCell>
                        <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                        <StyledTableCell align="left">{row.duedate}</StyledTableCell>
                        <StyledTableCell align="left">
                          {(Number(row.creditdays)) - ((new Date().getDate() - new Date(row.date).getDate()) + 1)}
                        </StyledTableCell>
                        <StyledTableCell align="left">{row.dueamount}</StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      </Box>
    </Box>
  );
}

function Customerduesreportlist() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Customerduesreport />
          <br /><br /> <br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Customerduesreportlist;