import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { toast } from 'react-toastify';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../../src/context/Appcontext';

function Categorywiseestmatelist() {

  const [categorywiseestimate, setCategoryWiseEstimate] = useState([])
  const [exceldata, setExceldata] = useState([]);
  const [count, setCount] = useState([]);

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  let categoryArr = []
  let totArr = []
  let quantityArr = []
  let arrMixed = []
  let counts = []
  let res = []

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  //  Fetch Estimate Data
  const fetchEstimate = async () => {
    try {
      let request = await axios.get(SERVICE.ESTIMATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let estimatefetch = request.data.estimate.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
        } 
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
      })

      estimatefetch != "" ? estimatefetch.map(item => {
        item.goods.map(value => {
          categoryArr.push(value.category)
          totArr.push(+value.subtotal)
          quantityArr.push(+value.quantity)
        })
        categoryArr?.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

        const splitKeyValue = obj => {
          const keys = Object.keys(obj);
          for (let i = 0; i < keys.length; i++) {
            res.push({
              'billcount': obj[keys[i]]
            });
          };
          return res;
        };
        setCount(splitKeyValue(counts))

        arrMixed = categoryArr?.map(function (data, i) {
          return { category: data, bills: res[i], totlquantity: quantityArr[i] };
        });

        const finalresult = [...arrMixed.reduce((r, o) => {
          const key = o.category;
          const items = r.get(key) || Object.assign({}, o, {
            totlquantity: 0
          });
          items.totlquantity += o.totlquantity

          return r.set(key, items);
        }, new Map).values()];

        setCategoryWiseEstimate(finalresult);
      }) : setCategoryWiseEstimate([])

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }
  // Export Excel
  const fileName = 'Category Wise Estimate'

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = categorywiseestimate.map(t => ({
      Category: t.category, "Total Bills": t?.bills?.billcount, "Total Quantity": t.totlquantity
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  }, [categorywiseestimate])

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#CategorywiseEstimate' })
    doc.save('Category Wise Estimate.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CATEGORY WISE ESTIMATE',
    pageStyle: 'print'
  });

  return (
    <Box>
      <Headtitle title={'Category Wise Estimate'} />
      <Typography sx={userStyle.HeaderText}>Category Wise Estimate</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={3} md={3}>
                <Typography sx={{ marginTop: 1 }}> From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchEstimate} >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
        </Grid>
        { /* ******************************************************EXPORT Buttons****************************************************** */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvcategorywiseestimates && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelcategorywiseestimates && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printcategorywiseestimates && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfcategorywiseestimates && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="CategorywiseEstimate" ref={tableRef} >
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell >Total Bills</StyledTableCell>
                  <StyledTableCell >Total quantity</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {categorywiseestimate &&
                  (categorywiseestimate.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">{row.category}</StyledTableCell>
                      <StyledTableCell >{row?.bills?.billcount}</StyledTableCell>
                      <StyledTableCell >{row.totlquantity}</StyledTableCell>
                    </StyledTableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls} >
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="CategorywiseEstimate" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell >Total Bills</StyledTableCell>
              <StyledTableCell >Total quantity</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {categorywiseestimate &&
              (categorywiseestimate.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">{row.category}</StyledTableCell>
                  <StyledTableCell >{row?.bills?.billcount}</StyledTableCell>
                  <StyledTableCell >{row.totlquantity}</StyledTableCell>
                </StyledTableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Categorywiseestimate() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Categorywiseestmatelist /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Categorywiseestimate;