import React, { useState, useEffect, createRef, useRef, } from 'react';
import { Typography, Box, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { userStyle } from '../../../PageStyle';
import { StyledTableRow, StyledTableCell } from '../../../../components/Table';
import {useReactToPrint} from 'react-to-print';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';

const TaxratePrint = () => {

    const [taxRate, setTaxRate] = useState([]);

    // Get Datas
    const taxrateRequest = async () => {
        try {
            let response = await axios.get(`http://localhost:8003/api/taxrates`);
            let taxRateData = response.data.taxrates.filter((data) => {
                return data.taxtype == "taxrate"
            })
            setTaxRate(taxRateData)
            setTimeout(() => {
                handleprint();
            }, 3000);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS| TAXRATE',
        pageStyle: 'print'
    });
    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };
    useEffect(() => {
        taxrateRequest()
    }, []);

    return (
        <Box>
            <Headtitle title={'Taxrate Print'} />
            <Box>
                <Typography variant ='h5'>Taxrates</Typography>
            </Box><br />
            <Box>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth:700 }} aria-label="customized table" id="taxrate" ref={componentRef}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell align="left">Tax Rate %</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {taxRate && (
                                taxRate.map((item, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">{item.taxname}</StyledTableCell>
                                        <StyledTableCell align="left">{item.taxrate}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}
export default TaxratePrint;