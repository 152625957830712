import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { Box, Typography, FormGroup, FormControlLabel, FormControl, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from "../../../services/Baseservice";
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import { useReactToPrint } from "react-to-print";
import { userStyle, colourStyles } from '../../PageStyle';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Selects from 'react-select';
import axios from 'axios';
import jsPDF from "jspdf";
import $ from 'jquery';

function Suppliersreportlist() {

    const [purchases, setPurchases] = useState([]);
    const [newdata, setNewdata] = useState([]);
    const [supplierCity, setSupplierCity] = useState();
    const [exceldata, setExceldata] = useState();
    const [checked, setChecked] = useState(true);
    const [code, setCode] = useState([]);
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Cityfilter
    const [citySearch, setCitySearch] = useState({
        suppliercity: "",
    });

    //jquery
    $.DataTable = require('datatables.net')
    const tableRefSupreport = useRef()

    // Select all function 
    const fetchPurchaseselect = async (e) => {
        setChecked(!checked)
        try {
            let response = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            if (checked === true) {
                setPurchases(response.data.purchases);
                setCitySearch({ suppliercity: "" })
            }
            else if (checked === false) {
                setPurchases([])
            }
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

     // Fetch customer accountgroup 
     const fetchSuppliers = async () => {
        try {
            let res_group = await axios.get(SERVICE.SUPPLIER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.suppliers?.map((d) => {
                return d.city
            })
            setNewdata(masters_data);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    

    let newarray = [...new Set(newdata)];

    // Fetch city from supplier
    const fetchSuppliernames = async () => {
        try {
            let req = await axios.get(SERVICE.SUPPLIER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSupplierCity(
                newarray.map((d) => ({
                    ...d,
                    label: d,
                    value: d,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchSuppliernames();
    }, [supplierCity])

    // Suppliername handlechange
    const handleChangeManufacture = (newValue, actionMeta) => {
        setCitySearch({ ...citySearch, suppliercity: newValue.value });
        getCateName(newValue);
        setChecked(true)
    };

    const getCateName = (newValue) => {
        if (newValue) {
            setPurchases([]) || setChecked(true)
        }
        setCode(newValue.category)
    }

    // Purchases
    const fetchPurchases = async () => {
        try {
            let req = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getCitywithFilter = req.data.purchases.filter((data) => {
                if (citySearch.suppliercity == "") {
                    return data
                } else if (data.city == citySearch.suppliercity) {
                    return data
                }
            })
            setPurchases(getCitywithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefSupreport.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchSuppliers();
            fetchPurchases();
        }, []
    )

    // Excel
    const fileName = 'Supplier Report'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = purchases.map(t => ({
            "Name": t.supplier, "Area": t.city, "Address": t.addressone, "Phone No": t.phoneone, "GSTIN No": t.gstn, "Discounts": t.totaldiscount, "Credit Days": t.creditdays
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | SUPPLIERS REPORT',
        pageStyle: 'print'
    });

    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    // Pdf
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#suppliersreport' })
        doc.save('suppliersreport.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [purchases]
    )

    return (
        <Box >
            <Headtitle title={'Supplier Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Supplier Report</Typography>
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.filtercontent} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={4} md={4}>
                            <Grid container>
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{ marginTop: 1 }}>City</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <Selects
                                            onChange={handleChangeManufacture}
                                            styles={colourStyles}
                                            options={supplierCity}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Grid container>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox onClick={(e) => { fetchPurchaseselect(e) }} checked={!checked} />} label="Select All" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={fetchPurchases}>Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvsuppliersreport && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelsuppliersreport && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printsuppliersreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfsuppliersreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="suppliersreport" ref={tableRefSupreport}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Address</StyledTableCell>
                                    <StyledTableCell>Phone no</StyledTableCell>
                                    <StyledTableCell>GSTIN NO</StyledTableCell>
                                    <StyledTableCell>Discounts</StyledTableCell>
                                    <StyledTableCell>Bill No</StyledTableCell>
                                    <StyledTableCell>Bill Credit Days</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {purchases.length > 0 && (
                                    purchases.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                            <StyledTableCell align="left">{row.city}</StyledTableCell>
                                            <StyledTableCell align="left">{row.addressone}</StyledTableCell >
                                            <StyledTableCell align="left">{row.phoneone}</StyledTableCell>
                                            <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totaldiscount}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Table End ****** */}
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} >
                <Box>
                    <Typography variant='h5' >Supplier Report</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table sx={{}} aria-label="simple table" id="suppliersreport" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Area</StyledTableCell>
                                        <StyledTableCell>Address</StyledTableCell>
                                        <StyledTableCell>Phone no</StyledTableCell>
                                        <StyledTableCell>GSTIN NO</StyledTableCell>
                                        <StyledTableCell>Discounts</StyledTableCell>
                                        <StyledTableCell>Bill No</StyledTableCell>
                                        <StyledTableCell>Bill Credit Days</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {purchases.length > 0 && (
                                        purchases.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                                <StyledTableCell align="left">{row.area}</StyledTableCell>
                                                <StyledTableCell align="left">{row.addressone}</StyledTableCell >
                                                <StyledTableCell align="left">{row.phoneone}</StyledTableCell>
                                                <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                                <StyledTableCell align="left">{row.totaldiscount}</StyledTableCell>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Suppliersreport() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Suppliersreportlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Suppliersreport;