import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, FormControlLabel, InputLabel, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import { userStyle, colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import Selects from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { AuthContext } from '../../context/Appcontext';
import { UserRoleAccessContext } from '../../context/Appcontext';

function Itemsales() {

  const [itemwisesales, setItemwisesales] = useState([]);
  const [product, setProduct] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const [checked, setChecked] = useState(true);
  const [code, setCode] = useState([]);
  const { auth } = useContext(AuthContext);

  // Acces
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  let quantityArr = [];
  let arr = [];
  let numArr = [];
  let arrMixed = [];
  let arrSku = [];
  let finalArr = [];

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // Product
  const fetchProduct = async () => {
    try {
      let productname = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setProduct(productname?.data?.products?.map((data) =>
      ({
        ...data,
        label: data.prodname,
        value: data.prodname
      })))
    } catch (err) {
      const messages = err.response.data.message
      toast.error(messages)
    }
  };

  // Pos
  const fetchitemwisesalesSelect = async (e) => {
    try {
      let res = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = res.data.pos1.filter((data) => {
        return data
      })
      getDatawithFilter.map(item => {
        item.goods.map(iterator => {
          quantityArr.push(+iterator.quantity)
          arr.push(iterator.productname)
          numArr.push(+iterator.subtotal)
          arrSku.push(iterator.productid)
        })
      })
      arrMixed = arr.map(function (data, i) {
        return { itemname: data, itemcode: arrSku[i], totalbillamt: numArr[i], totlquantity: quantityArr[i] };
      });
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.itemcode
        const items = r.get(key) || Object.assign({}, o, {
          totalbillamt: 0,
          totlquantity: 0
        });
        items.totalbillamt += o.totalbillamt
        items.totlquantity += o.totlquantity
        return r.set(key, items);
      }, new Map).values()];
      
      finalArr = result?.filter(d => {
        if (d.itemname === e.prodname) {
          return d
        }
      })
      setItemwisesales(finalArr)
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Purchase
  const fetchitemwisesales = async () => {
    try {
      let res = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = res.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })
      getDatawithFilter.map(item => {
        item.goods.map(iterator => {
          quantityArr.push(+iterator.quantity)
          arr.push(iterator.productname)
          numArr.push(+iterator.subtotal)
          arrSku.push(iterator.productid)
        })
      })
      arrMixed = arr.map(function (data, i) {
        return { itemname: data, itemcode: arrSku[i], totalbillamt: numArr[i], totlquantity: quantityArr[i] };
      });
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.itemcode
        const items = r.get(key) || Object.assign({}, o, {
          totalbillamt: 0,
          totlquantity: 0
        });
        items.totalbillamt += o.totalbillamt
        items.totlquantity += o.totlquantity
        return r.set(key, items);
      }, new Map).values()];
      setItemwisesales(result)

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Select all function 
  const fetchPurchaseselect = async (e) => {
    setChecked(!checked)
    try {
      let res = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = res.data.pos1.filter((data) => {
        return data
      })
      if (checked === true) {
        getDatawithFilter.map(item => {
          item.goods.map(iterator => {
            quantityArr.push(+iterator.quantity)
            arr.push(iterator.productname)
            numArr.push(+iterator.subtotal)
            arrSku.push(iterator.productid)
          })
        })
        arrMixed = arr.map(function (data, i) {
          return { itemname: data, itemcode: arrSku[i], totalbillamt: numArr[i], totlquantity: quantityArr[i] };
        });
        const result = [...arrMixed.reduce((r, o) => {
          const key = o.itemcode
          const items = r.get(key) || Object.assign({}, o, {
            totalbillamt: 0,
            totlquantity: 0
          });
          items.totalbillamt += o.totalbillamt
          items.totlquantity += o.totlquantity
          return r.set(key, items);
        }, new Map).values()];
        setItemwisesales(result)
      }
      else if (checked === false) {
        setItemwisesales([])
      }
      setDateFilter({
        startdate: today, enddate: today,
      })
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  const clearSelectAll = (e) => {
    if (e) {
      setItemwisesales([]) || setChecked(true)
    }
    setCode(e.startdate)
}

  useEffect(() => {
    fetchProduct();
  }, [])

  // Export Excel
  const fileName = 'Item Wise Sales'
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = itemwisesales.map(t => ({
      "Item Code": t.itemcode,
      "Item Name": t.itemname,
      "Total Quantity": t.totlquantity,
      "Total Amount": t.totalbillamt,
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  }, [itemwisesales])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | ITEM WISE SALES',
    pageStyle: 'print'
  });

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#itemwisesales' })
    doc.save('Item Wise Sales.pdf')
  }

  return (
    <Box>
      <Headtitle title={'Item Wise Sales'} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography sx={userStyle.importheadtext}>Item Wise Sales (Retail)</Typography>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid><br />
      <Box sx={userStyle.filtercontent} >
        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
          <Grid item lg={3} md={3}>
            <Grid sx={{ display: "flex" }}>
              <Grid><Typography sx={{ marginTop: 1 }}>From&ensp;</Typography>  </Grid>
              <FormControl size="small" fullWidth>
                <TextField
                  id="component-outlined"
                  value={dateFilter.startdate}
                  onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }); clearSelectAll(e) }}
                  type="date"
                  size="small"
                  name="startdate"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3}>
            <Grid sx={{ display: "flex" }}>
              <Grid ><Typography sx={{ marginTop: 1 }}>To&ensp;</Typography>  </Grid>
              <FormControl size="small" fullWidth>
                <TextField
                  id="component-outlined"
                  value={dateFilter.enddate}
                  onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                  type="date"
                  size="small"
                  name="enddate"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchitemwisesales}>Generate</Button>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormControlLabel control={<Checkbox checked={!checked} onChange={(e) => fetchPurchaseselect(e)} />} label="Select All" />
          </Grid>
          <Grid item lg={2} md={2}  >
            <FormControl size="small" fullWidth >
              <InputLabel htmlFor="component-outlined" ></InputLabel>
              <Selects
                placeholder="Product Name"
                styles={colourStyles}
                id="component-outlined"
                options={product}
                onChange={fetchitemwisesalesSelect}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box><br />
      <Headtitle title={'item wise sales'} />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvitemwisesales && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].excelitemwisesales && (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printitemwisesales && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfitemwisesales && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table id="itemwisesales" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Item Code</StyledTableCell>
                  <StyledTableCell>Item Name</StyledTableCell>
                  <StyledTableCell>Total Quantity</StyledTableCell>
                  <StyledTableCell>Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {itemwisesales &&
                  itemwisesales?.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell >{row.itemcode}</StyledTableCell>
                        <StyledTableCell >{row.itemname}</StyledTableCell>
                        <StyledTableCell >{row.totlquantity}</StyledTableCell>
                        <StyledTableCell >{row.totalbillamt}</StyledTableCell>
                      </StyledTableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table id="itemwisesales" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Item Code</StyledTableCell>
              <StyledTableCell>Item Name</StyledTableCell>
              <StyledTableCell >Total Quantity</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {itemwisesales &&
              itemwisesales?.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell >{row.itemcode}</StyledTableCell>
                    <StyledTableCell >{row.itemname}</StyledTableCell>
                    <StyledTableCell >{row.totlquantity}</StyledTableCell>
                    <StyledTableCell >{row.totalbillamt}</StyledTableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function Itemsaleslist() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Itemsales /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Itemsaleslist;