import React, { useState, useEffect, useRef, useContext } from "react";
import { Box,Typography,InputLabel,TextField,FormControl,FormControlLabel,Grid, Checkbox,Paper,Table,TableBody,TableHead,TableContainer,Button,Dialog,DialogActions,DialogContent, DialogContentText,DialogTitle,} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { userStyle } from "../../../PageStyle";
import Navbar from "../../../../components/header/Navbar";
import Footer from "../../../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../../../components/Table";
import axios from "axios";
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ExportXL, ExportCSV } from "../../../Export";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import Headtitle from "../../../../components/header/Headtitle"
import $ from "jquery";
import { UserRoleAccessContext } from "../../../../context/Appcontext";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";


function GstrpurchaseReturnList() {
  const [suppliers, setSuppliers] = useState([]);
  const [deletesup, setDeletesup] = useState({});
  const [exceldata, setExceldata] = useState([]);

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  // Accordion Expand

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Text field

  const [supFilter, setSupFilter] = useState({
    supFilterStatus: "",
  });

  // Suppliers

  const fetchSuppliers = async () => {
    try {
      let req = await axios.get("http://localhost:8003/api/suppliers");

      setSuppliers(req.data.suppliers);
    } catch (err) {
      const messages = err.response.data.message;

      toast.error(messages);
    }
  };

  // Delete model

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleClickOpen = () => {
    setIsDeleteOpen(true);
  };

  const handleClose = () => {
    setIsDeleteOpen(false);
  };

  //set function to get particular row

  const rowData = async (id) => {
    try {
      let res = await axios.get(`http://localhost:8003/api/supplier/${id}`);

      setDeletesup(res.data.ssupplier);
    } catch (err) {}
  };

  // Alert delete popup

  let supid = deletesup._id;

  const deleteSupplier = async () => {
    try {
      let res = await axios.delete(
        `http://localhost:8003/api/supplier/${supid}`
      );

      handleClose();
    } catch (err) {}
  };

  // Excel

  const fileName = "Suppliers";

  // get particular columns for export excel

  const getexcelDatas = async () => {
    let res = await axios.get("http://localhost:8003/api/suppliers");

    var data = res.data.suppliers.map((t) => ({
         autogenerate: t.autogenerate,suppliername: t.suppliername,addressone: t.addressone,addresstwo: t.addresstwo,country: t.country,
state: t.state,city: t.city,pincode: t.pincode,email: t.email,gstn: t.gstn,phoneone: t.phoneone,whatsapp: t.whatsapp,contactperson: t.contactperson,
    }));

    setExceldata(data);
  };
  const downloadpdf = () => {
    const doc = new jsPDF();

    autoTable(doc, { html: "#Supplierpaymentsreport" });

    doc.save("Supplierpaymentsreport.pdf");
  };

  useEffect(() => {
    fetchSuppliers();

    getexcelDatas();
  }, [deleteSupplier]);

  let total = 0;

  //jquery

  $.DataTable = require("datatables.net");

  const tableRef = useRef();

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = "none";
      setTimeout(function () {
        console.log(tableRef.current);
        $(tableRef.current).DataTable({
          language: { search: "", searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 25, 50, 100, 200, 500, -1],
            [10, 25, 50, 100, 200, 500, "All"],
          ],
        });
      }, 1000);
    });
  }, [tableRef.current]);

  return (
    <Box>
      <Headtitle title={"Gstr Purchase Return"} />

      {/* ****** Header Content ****** */}

      <Typography sx={userStyle.HeaderText}>Gstr Purchase Return</Typography>

      {/* ****** Table Start ****** */}

      <>
        <Box sx={userStyle.container}>
          <Grid
            containerlg={12} md={12}sm={12}xs={12} sx={{ justifyContent: "center" }}spacing={1}>
            <Grid item lg={5} md={5}>
              <Grid container lg={12} md={12}>
                <Grid item lg={3} md={3}>
                  <Typography sx={{ marginTop: 1 }}>Date From</Typography>
                </Grid>

                <Grid item lg={9} md={9} sx={{ display: "flex" }}>
                  <Grid sx={userStyle.spanIcons}>
                    <CalendarMonthOutlinedIcon />
                  </Grid>

                  <FormControl size="small" fullWidth>
                    <TextField
                      id="component-outlined"
                      // value={supplierDate.startDate}

                      type="date"
                      size="small"
                      name="autogenerate"

                      // onChange={(e) =>setSupplierDate({...supplierDate, startDate: e.target.value})}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={5} md={5}>
              <Grid container lg={12} md={12}>
                <Grid item lg={1} md={1}>
                  <Typography sx={{ marginTop: 1 }}>To</Typography>
                </Grid>

                <Grid item lg={9} md={9} sx={{ display: "flex" }}>
                  <Grid sx={userStyle.spanIcons}>
                    <CalendarMonthOutlinedIcon />
                  </Grid>

                  <FormControl size="small" fullWidth>
                    <TextField
                      id="component-outlined"
                      // value={supplierDate.startDate}

                      type="date"
                      size="small"
                      name="autogenerate"

                      // onChange={(e) =>setSupplierDate({...supplierDate, startDate: e.target.value})}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={2} md={2}>
              <Button variant="contained" color="secondary">
                Generate
              </Button>
            </Grid>
          </Grid>
        </Box>
        <br />

        <Box sx={userStyle.container}>
          {/* ****** Header Buttons ****** */}

          <Grid container sx={{ justifyContent: "center" }}>
            <Grid>
              <ExportCSV csvData={exceldata} fileName={fileName} />

              <ExportXL csvData={exceldata} fileName={fileName} />

              <Button sx={userStyle.buttongrp} onClick={(e) => downloadpdf()}>
                <FaFilePdf />
                &ensp;Export to PDF&ensp;
              </Button>
            </Grid>

            <Link to="/contact/supplier/Supplierpaymentsreportprint">
              <Button sx={userStyle.buttongrp}>
                <FaPrint />
                &ensp;Export to Print
              </Button>
            </Link>

            {/* ****** Table Grid Container ****** */}

            <Grid container>
              <Grid md={4} sm={2} xs={1}></Grid>

              <Grid md={8} sm={10} xs={10} sx={{ align: "center" }}></Grid>
            </Grid>
          </Grid>
          <br />

          {/* ****** Table start ****** */}

          <TableContainer component={Paper}>
            <Table
              sx={{}}
              aria-label="simple table"
              id="Supplierpaymentsreport"
              ref={tableRef}
            >
              <TableHead sx={{ fontWeight: "600" }}>
                <StyledTableRow>
                  <StyledTableCell>Serial No</StyledTableCell>
                  <StyledTableCell>Name of Seller</StyledTableCell>
                  <StyledTableCell>Seller GSTIN/UIN</StyledTableCell>
                <StyledTableCell>Invoice No</StyledTableCell>
                  <StyledTableCell>Invoice Date</StyledTableCell>
                  <StyledTableCell>Rate Of Tax(%)</StyledTableCell>
                <StyledTableCell>Taxable value</StyledTableCell>
                 <StyledTableCell>IGST Amount</StyledTableCell>
                  <StyledTableCell>CGST Amount</StyledTableCell>
                  <StyledTableCell>SGST/UTGST Amount</StyledTableCell>
                  <StyledTableCell>CESS</StyledTableCell>
                  <StyledTableCell>Total Tax</StyledTableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {suppliers.length > 0 &&
                  suppliers.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" colSpan={1}>
                        <Grid sx={{ display: "flex" }}>
                          {isUserRoleCompare[0].auser && (
                            <Link
                              to={`/contact/supplier/edit/${row._id}`}
                              style={{
                                textDecoration: "none",
                                color: "#fff",
                                minWidth: "0px",
                              }}
                            >
                              <Button
                                sx={userStyle.buttonedit}
                                style={{ minWidth: "0px" }}
                              >
                                <EditOutlinedIcon
                                  style={{ fontSize: "large" }}
                                />
                              </Button>
                            </Link>
                          )}

                          {isUserRoleCompare[0].auser && (
                            <Button
                              sx={userStyle.buttondelete}
                              onClick={(e) => {
                                handleClickOpen();
                                rowData(row._id);
                              }}
                            >
                              <DeleteOutlineOutlinedIcon
                                style={{ fontsize: "large" }}
                              />
                            </Button>
                          )}

                          {isUserRoleCompare[0].auser && (
                            <Link
                              to={`/contact/supplier/view/${row._id}`}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <Button sx={userStyle.buttonview}>
                                <VisibilityOutlinedIcon
                                  style={{ fontSize: "large" }}
                                />
                              </Button>
                            </Link>
                          )}
                        </Grid>
                      </StyledTableCell>

                      <StyledTableCell align="left">{row.suppliername}</StyledTableCell>
                      <StyledTableCell align="left">{row.addressone}</StyledTableCell>
                        <StyledTableCell align="left">{row.addresstwo}</StyledTableCell>
                      <StyledTableCell align="left">{row.addressthree}</StyledTableCell>
                      <StyledTableCell align="left">{row.city}</StyledTableCell>
                       <StyledTableCell align="left">{row.addressthree}</StyledTableCell>

                      <StyledTableCell align="left">{row.city}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* ****** Table End ****** */}
        </Box>
      </>

      {/* ****** Table End ****** */}

      {/* Delete Modal */}

      <Box>
        {/* ALERT DIALOG */}

        <Dialog
          open={isDeleteOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={{ width: "350px", textAlign: "center", alignItems: "center" }}
          >
            <ErrorOutlineOutlinedIcon
              sx={{ fontSize: "80px", color: "orange" }}
            />

            <Typography variant="h5" sx={{ color: "red", textAlign: "center" }}>
              Are you sure?
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>

            <Button
              autoFocus
              variant="contained"
              color="error"
              onClick={(e) => deleteSupplier(supid)}
            >
              {" "}
              OK{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

function GstrpurchaseReturn() {
  return (
    <Box >
      <Navbar />

      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>

          <GstrpurchaseReturnList />
          <br />
          <br />
          <br />

          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default GstrpurchaseReturn;
