import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import { toast } from 'react-toastify';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import moment from "moment";
import { useReactToPrint } from "react-to-print";

function Diagnosislisttable() {

  const [diagnosiswisesales, setDiagnosiswisesales] = useState([]);
  const [count, setCount] = useState([]);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);
  const [exceldata, setExceldata] = useState([]);

  let docArr = []
  let totArr = []
  let allArray = []
  let counts = []
  const res = [];
  
  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  
  const fetchdiagnosiswisesales = async () => {
    try {
      let request = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let diagnosiswisesalesfetch = request.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
        } 
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
      })

      diagnosiswisesalesfetch?.map(item => {
        docArr?.push(item.diagnosis)
        totArr?.push(+item.aftergranddisctotal)
      })
      docArr?.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

      const splitKeyValue = obj => {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          res.push({
            'billcount': obj[keys[i]]
          });
        };
        return res;
      };
      setCount(splitKeyValue(counts))
      allArray = docArr.map(function (data, i) {
        return { diagno: data, totalAmount: totArr[i], bills: res[i] }
      })

      const result = [...allArray.reduce((r, o) => {
        const key = o.diagno;
        const items = r.get(key) || Object.assign({}, o, {
          totalAmount: 0,
        });
        items.totalAmount += o.totalAmount
        return r.set(key, items);
      }, new Map).values()];

      setDiagnosiswisesales(result)

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });

    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
  }
  
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = diagnosiswisesales.map(t => ({
      Diagnosis: t.diagno, "Total Bills": t?.bills?.billcount, "Total Amount": t.totalAmount
    }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | DIAGNOSIS WISE SALES',
    pageStyle: 'print'
  });

  // Export Excel
  const fileName = 'Diagnosis Wise Sales'

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#diagnosistable' })
    doc.save('Diagnosis Wise Sales.pdf')
  }

  useEffect(() => {
    getexcelDatas();
  }, [diagnosiswisesales])

  return (
    <Box>
      <Headtitle title={'Diagnosis Wise Sales'} />
      <Typography sx={userStyle.HeaderText}>Diagnosis Wise Sales</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchdiagnosiswisesales}  >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvdiagnosiswisesales && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].exceldiagnosiswisesales && (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printdiagnosiswisesales && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfdiagnosiswisesales && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="diagnosistable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Diagnosis</StyledTableCell>
                  <StyledTableCell >Total Bills</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {diagnosiswisesales &&
                  (diagnosiswisesales?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">{row.diagno}</StyledTableCell>
                      <StyledTableCell >{row?.bills?.billcount}</StyledTableCell>
                      <StyledTableCell >{row.totalAmount}</StyledTableCell>
                    </StyledTableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* Print Layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="diagnosistable" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Diagnosis</StyledTableCell>
              <StyledTableCell >Total Bills</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {diagnosiswisesales &&
              (diagnosiswisesales?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">{row.diagno}</StyledTableCell>
                  <StyledTableCell >{row?.bills?.billcount}</StyledTableCell>
                  <StyledTableCell >{row.totalAmount}</StyledTableCell>
                </StyledTableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Diagnosislist() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Diagnosislisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Diagnosislist;