import React, { useState, useEffect, useContext, useRef, createRef } from "react";
import { Box, Typography, Grid, FormGroup, FormControlLabel, Checkbox, DialogTitle, DialogContentText, DialogActions, DialogContent, Dialog, Paper, Table, TableBody, TableHead, TableContainer, Button, } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { userStyle } from "../../PageStyle";
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AuthContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import $ from 'jquery';
import { SERVICE } from '../../../services/Baseservice';
import { useReactToPrint } from "react-to-print";

function Customerlisttable() {

  const [custom, setCustom] = useState([]);
  const [iscustomer, setIsCustomer] = useState({});
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);

  // Pdffield
  const [isPdfData, setIsPdfData] = useState({
    isCusid: false, isCustomerGroup: false, isName: false, isAddress: false,
    isArea: false, isMobileno: false, isWhatsapp: false, isGstn: false, isUin: false, isAadhaar: false,
    isPanno: false, isCountry: false, isState: false, isCity: false, isCreditlimit: false, isCreditdays: false,
    isDlnos: false, isLedgerbalance: false, isCategory: false,
  })

  // Delete popup
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleClickOpen = () => { setIsDeleteOpen(true); };
  const handleClose = () => { setIsDeleteOpen(false); };

  // Pdf
  const [openPdf, setOpenPdf] = useState(false);
  const handleOpenPdf = () => { setOpenPdf(true) };
  const handleClosePdf = () => { setOpenPdf(false) };

  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  // Customers
  const Customerlists = async () => {
    try {
      let req = await axios.get(SERVICE.CUSTOMER, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      setCustom(req.data.customers);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  const rowData = async (id) => {
    try {
      let res = await axios.get(`${SERVICE.CUSTOMER_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      setIsCustomer(res.data.scustomer);//set function to get particular row
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //alert delete popup
  let cusid = iscustomer._id;

  const deleteSize = async (cusid) => {
    try {
      let res = await axios.delete(`${SERVICE.CUSTOMER_SINGLE}/${cusid}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Excel
  const fileName = "Customers";
  const getexcelDatas = async () => {
    var data = custom.map(t => ({
      "Customer ID": t.cusid, "Customer Group": t.customergroup, "Name": t.name, "Address": t.address, "Area": t.area, "Mobile No": t.mobileno,
      "Whatsapp": t.whatsapp, "GSTN": t.gstn, "UIN": t.uin, "Aadhaar": t.aadhaar, "PAN No": t.panno, "Country": t.country, "State": t.state,
      "City": t.city, "Credit Limit": t.creditlimit, "Credit Days": t.creditdays, "DL Nos": t.dlnos, "Category": t.category
    }));
    setExceldata(data);
  }

  useEffect(
    () => {
      Customerlists()
    }, [deleteSize]
  )

  useEffect(
    () => {
      getexcelDatas()
    }, [custom]
  )

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CUSTOMER LIST',
    pageStyle: 'print'
  });

  const ref = createRef();
  const options = {
    orientation: 'portrait',
    unit: 'in'
  };

  // PDF
  var nestedArray = [];

  {
    custom && (custom.map((item, index) => {
      var cateData = [
        item.cusid,
        item.customergroup,
        item.name,
        item.address,
        item.area,
        item.mobileno,
        item.whatsapp,
        item.gstn,
        item.uin,
        item.aadhaar,
        item.panno,
        item.country,
        item.state,
        item.city,
        item.creditlimit,
        item.creditdays,
        item.dlnos,
        item.ledgerbalance,
        item.category,
      ]
      nestedArray.push(cateData);
    })
    )
  }

  const downloadPdf = () => {
    const newData = custom.map(row => {
      delete row._id;
      delete row.phonecheck;
      delete row.activate;
      delete row.createdAt;
      delete row.__v;
      { !isPdfData.isCusid && delete row.cusid };
      { !isPdfData.isCustomerGroup && delete row.customergroup };
      { !isPdfData.isName && delete row.name };
      { !isPdfData.isAddress && delete row.address };
      { !isPdfData.isArea && delete row.area };
      { !isPdfData.isMobileno && delete row.mobileno };
      { !isPdfData.isWhatsapp && delete row.whatsapp };
      { !isPdfData.isGstn && delete row.gstn };
      { !isPdfData.isUin && delete row.uin };
      { !isPdfData.isAadhaar && delete row.aadhaar };
      { !isPdfData.isPanno && delete row.panno };
      { !isPdfData.isCountry && delete row.country };
      { !isPdfData.isState && delete row.state };
      { !isPdfData.isCity && delete row.city };
      { !isPdfData.isCreditlimit && delete row.creditlimit };
      { !isPdfData.isCreditdays && delete row.creditdays };
      { !isPdfData.isDlnos && delete row.dlnos };
      { !isPdfData.isLedgerbalance && delete row.ledgerbalance };
      { !isPdfData.isCategory && delete row.category };
      setIsPdfData(row)
    })

    const doc = new jsPDF()
    doc.autoTable({
      theme: "grid",
      body: custom,
    })
    doc.save('Customers.pdf')
  }

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  return (
    <Box>
      <Headtitle title={'Customers'} />
      { /* ****** Header Content ****** */}
      <Typography sx={userStyle.HeaderText}>Customers <Typography sx={userStyle.SubHeaderText}>Manage your Customers</Typography></Typography>
      { /* ****** Filter Start ****** */}

      { /* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container} >
          { /* Header Content */}
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography sx={userStyle.importheadtext}>All your Customers</Typography>
            </Grid>
            <Grid item xs={4}> </Grid>
          </Grid><br />
          { /* Header Buttons */}
          <Grid container sx={{ justifyContent: "center", }} >
            <Grid >
              {isUserRoleCompare[0].csvcustomer && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelcustomer && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printcustomer && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfcustomer && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => handleOpenPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          { /* Table Start */}
          <TableContainer component={Paper} >
            <Table aria-label="simple table" id="customertable" ref={tableRef}>
              <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
                <StyledTableRow >
                  <StyledTableCell>Actions</StyledTableCell>
                  <StyledTableCell>Customer ID</StyledTableCell>
                  <StyledTableCell>Customer Group</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Area</StyledTableCell>
                  <StyledTableCell>Mobile No</StyledTableCell>
                  <StyledTableCell>WhatsApp No</StyledTableCell>
                  <StyledTableCell>GSTIN No</StyledTableCell>
                  <StyledTableCell>UIN No</StyledTableCell>
                  <StyledTableCell>Aadhaar No</StyledTableCell>
                  <StyledTableCell>PAN No</StyledTableCell>
                  <StyledTableCell>Country</StyledTableCell>
                  <StyledTableCell>State</StyledTableCell>
                  <StyledTableCell>City</StyledTableCell>
                  <StyledTableCell>Credit Limit</StyledTableCell>
                  <StyledTableCell>Credit Days</StyledTableCell>
                  <StyledTableCell>DL Nos</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {custom.length > 0 ? (
                  custom.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" colSpan={1}>
                        <Grid sx={{ display: 'flex' }}>
                          {isUserRoleCompare[0].ecustomer && (
                            <Link to={`/contact/customer/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                          )}
                          {isUserRoleCompare[0].dcustomer && (
                            <Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                          )}
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.cusid}</StyledTableCell>
                      <StyledTableCell align="left">{row.customergroup}</StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell >
                      <StyledTableCell align="left">{row.address}</StyledTableCell>
                      <StyledTableCell align="left">{row.area}</StyledTableCell>
                      <StyledTableCell align="left">{row.mobileno}</StyledTableCell>
                      <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                      <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                      <StyledTableCell align="left">{row.uin}</StyledTableCell>
                      <StyledTableCell align="left">{row.aadhaar}</StyledTableCell>
                      <StyledTableCell align="left">{row.panno}</StyledTableCell>
                      <StyledTableCell align="left">{row.country}</StyledTableCell>
                      <StyledTableCell align="left">{row.state}</StyledTableCell>
                      <StyledTableCell align="left">{row.city}</StyledTableCell>
                      <StyledTableCell align="left">{row.creditlimit}</StyledTableCell>
                      <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                      <StyledTableCell align="left">{row.dlnos}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (<StyledTableCell colSpan={8}><Typography>No data available in table</Typography></StyledTableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* ALERT DIALOG */}
          <Dialog
            open={isDeleteOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
              <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
              <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined">Cancel</Button>
              <Button onClick={(e) => deleteSize(cusid)} autoFocus variant="contained" color='error'> OK </Button>
            </DialogActions>
          </Dialog>
          { /* Table End */}
        </Box>
      </>
      { /* ****** Print ****** */}

      <Box sx={userStyle.printcls} >
        <Box>
          <Typography variant='h5' >Customer List Print</Typography>
        </Box>
        <Box  >
          <TableContainer component={Paper} sx={userStyle.printcls}>
            <Table aria-label="simple table" id="customerlisttable" ref={componentRef}>
              <TableHead sx={{ fontWeight: "600" }} >
                <StyledTableRow >
                  <StyledTableCell>Customer ID</StyledTableCell>
                  <StyledTableCell>Customer Group</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Area</StyledTableCell>
                  <StyledTableCell>Mobile No</StyledTableCell>
                  <StyledTableCell>WhatsApp No</StyledTableCell>
                  <StyledTableCell>GSTIN No</StyledTableCell>
                  <StyledTableCell>UIN No</StyledTableCell>
                  <StyledTableCell>Aadhaar No</StyledTableCell>
                  <StyledTableCell>PAN No</StyledTableCell>
                  <StyledTableCell>Country</StyledTableCell>
                  <StyledTableCell>State</StyledTableCell>
                  <StyledTableCell>City</StyledTableCell>
                  <StyledTableCell>Credit Limit</StyledTableCell>
                  <StyledTableCell>Credit Days</StyledTableCell>
                  <StyledTableCell>DL Nos</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {custom.length > 0 && (
                  custom.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.cusid}</StyledTableCell>
                      <StyledTableCell align="left">{row.customergroup}</StyledTableCell>
                      <StyledTableCell align="left">{row.name}</StyledTableCell >
                      <StyledTableCell align="left">{row.address}</StyledTableCell>
                      <StyledTableCell align="left">{row.area}</StyledTableCell>
                      <StyledTableCell align="left">{row.mobileno}</StyledTableCell>
                      <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                      <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                      <StyledTableCell align="left">{row.uin}</StyledTableCell>
                      <StyledTableCell align="left">{row.aadhaar}</StyledTableCell>
                      <StyledTableCell align="left">{row.panno}</StyledTableCell>
                      <StyledTableCell align="left">{row.country}</StyledTableCell>
                      <StyledTableCell align="left">{row.state}</StyledTableCell>
                      <StyledTableCell align="left">{row.city}</StyledTableCell>
                      <StyledTableCell align="left">{row.creditlimit}</StyledTableCell>
                      <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                      <StyledTableCell align="left">{row.dlnos}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      {/* PDF Model */}
      <Box>
        <Dialog
          open={openPdf}
          onClose={handleClosePdf}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <DialogTitle id="alert-dialog-title">
            Select Option to Print PDF
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="subtitle1">Choose any 6</Typography>
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isCusid} onChange={(e) => { setIsPdfData({ ...isPdfData, isCusid: !isPdfData.isCusid }) }} />} label="Customer ID" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isCustomerGroup} onChange={(e) => setIsPdfData({ ...isPdfData, isCustomerGroup: !isPdfData.isCustomerGroup })} />} label="Customer Group" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isName} onChange={(e) => setIsPdfData({ ...isPdfData, isName: !isPdfData.isName })} />} label="Name" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isAddress} onChange={(e) => setIsPdfData({ ...isPdfData, isAddress: !isPdfData.isAddress })} />} label="Address" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isArea} onChange={(e) => setIsPdfData({ ...isPdfData, isArea: !isPdfData.isArea })} />} label="Area" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isMobileno} onChange={(e) => setIsPdfData({ ...isPdfData, isMobileno: !isPdfData.isMobileno })} />} label="Mobile No" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isWhatsapp} onChange={(e) => setIsPdfData({ ...isPdfData, isWhatsapp: !isPdfData.isWhatsapp })} />} label="WhatsApp No" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isGstn} onChange={(e) => setIsPdfData({ ...isPdfData, isGstn: !isPdfData.isGstn })} />} label="GSTIN No" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isUin} onChange={(e) => setIsPdfData({ ...isPdfData, isUin: !isPdfData.isUin })} />} label="UIN No" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isAadhaar} onChange={(e) => setIsPdfData({ ...isPdfData, isAadhaar: !isPdfData.isAadhaar })} />} label="GSTAadhaar No" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isPanno} onChange={(e) => setIsPdfData({ ...isPdfData, isPanno: !isPdfData.isPanno })} />} label="PAN No" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isCountry} onChange={(e) => setIsPdfData({ ...isPdfData, isCountry: !isPdfData.isCountry })} />} label="Country" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isState} onChange={(e) => setIsPdfData({ ...isPdfData, isState: !isPdfData.isState })} />} label="State" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isCity} onChange={(e) => setIsPdfData({ ...isPdfData, isCity: !isPdfData.isCity })} />} label="City" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isCreditlimit} onChange={(e) => setIsPdfData({ ...isPdfData, isCreditlimit: !isPdfData.isCreditlimit })} />} label="Credit Limit" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isCreditdays} onChange={(e) => setIsPdfData({ ...isPdfData, isCreditdays: !isPdfData.isCreditdays })} />} label="Credit Days" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isDlnos} onChange={(e) => setIsPdfData({ ...isPdfData, isDlnos: !isPdfData.isDlnos })} />} label="DL Nos" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isLedgerbalance} onChange={(e) => setIsPdfData({ ...isPdfData, isLedgerbalance: !isPdfData.isLedgerbalance })} />} label="Ledger Balance" />
                  </FormGroup>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isPdfData.isCategory} onChange={(e) => setIsPdfData({ ...isPdfData, isCategory: !isPdfData.isCategory })} />} label="Category" />
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={() => downloadPdf()} autoFocus>PDF</Button>
            <Button variant='contained' color='error' onClick={handleClosePdf}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
function Customerlist() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Customerlisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Customerlist;