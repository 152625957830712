import React, { useState, useEffect, useRef, useContext } from 'react';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import $ from 'jquery';
import { Box, Grid, FormControl, Typography, TextField, Table, TableBody, TableContainer, TableHead, Paper, Button } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment"
import { AuthContext } from '../../context/Appcontext';

function Manufacturewisepurchaseall() {

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  const [manufacturepurchases, setmanufacturePurchases] = useState([]);

  // Excel data
  const [exceldata, setExceldata] = useState([]);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  // Fetch Purchases
  const fetchPurchases = async () => {
    try {
      let res_purchase = await axios.get(SERVICE.PURCHASE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let getDatawithFilter = res_purchase.data.purchases.filter((data) => {
        let splittedMonth = data.purchasedate
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })

      let purchase = [];

      getDatawithFilter != "" ? getDatawithFilter.map(item => {
        item.products.map(iterator => {
          purchase.push(iterator)

          const final = [...purchase.reduce((r, o) => {
            const key = o.manufacture;
            const items = r.get(key) || Object.assign({}, o, {
              ratebydismod: 0,
              quantity: 0
            });
            items.ratebydismod += +o.ratebydismod
            items.quantity += +o.quantity
            return r.set(key, items);

          }, new Map).values()
          ];
          setmanufacturePurchases(final)

          $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
              $(tableRef.current).DataTable({
                language: { search: '', searchPlaceholder: "Search..." },
                lengthMenu: [
                  [10, 1, 25, 50, 100, 200, 500, -1],
                  [10, 1, 25, 50, 100, 200, 500, 'All'],
                ],
              });
            }, 1000);
          });
        })
      }) : setmanufacturePurchases([])
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Export Excel
  const fileName = 'Manufacture Wise Purchase';

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = manufacturepurchases.map(t => ({
      'Manufacture Name': t.manufacture, 'Total Quantity': t.quantity, 'Total Amount': t.ratebydismod
    }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | MANUFACTURE WISE PURCHASE',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#manufacturewisepurchase' });
    doc.save('Manufacture Wise Purchase.pdf')
  }

  useEffect(() => {
    getexcelDatas()
  }, [manufacturepurchases])

  useEffect(() => {
    fetchPurchases()
  }, [])

  return (
    <Box >
      <Headtitle title={'Manufacture Wise Purchase Report'} />
      { /* ************************************************** Header Content ****************************************************** */}
      <Typography sx={userStyle.HeaderText}>Manufacture Wise Purchase</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchPurchases} >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />

      { /* ******************************************************EXPORT Buttons****************************************************** */}

      <>
        <Box sx={userStyle.container} >
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvmanufacturewisepurchase && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelmanufacturwisepurchase && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printmanufacturewisepurchase && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfmanufacturewisepurchase && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />

          { /*  *******************************************Table Start  *******************************************p*/}
          <Box>
            <TableContainer component={Paper} >
              <Table id="manufacturewisepurchase" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Manufacture</StyledTableCell>
                    <StyledTableCell >Total Quantity</StyledTableCell>
                    <StyledTableCell >Total Amount</StyledTableCell>

                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {manufacturepurchases.length > 0 && (
                    manufacturepurchases.map((row, index) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell >{row.manufacture}</StyledTableCell>
                          <StyledTableCell >{row.quantity}</StyledTableCell>
                          <StyledTableCell >{row.ratebydismod}</StyledTableCell>
                        </StyledTableRow>
                      )
                    }))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </>
      {/* *******************************************print layout **********************************************/}

      <>
        <Box sx={userStyle.printcls} ref={componentRef}>
        <Typography variant='h5'>Manufacture Wise Purchase</Typography><br />
          <Box>
            <TableContainer component={Paper} >
              <Table id="manufacturewisepurchase" >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Manufacture</StyledTableCell>
                    <StyledTableCell >Total Quantity</StyledTableCell>
                    <StyledTableCell >Total Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {manufacturepurchases.length > 0 &&
                    (manufacturepurchases.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell >{row.manufacture}</StyledTableCell>
                        <StyledTableCell >{row.quantity}</StyledTableCell>
                        <StyledTableCell >{row.ratebydismod}</StyledTableCell>
                      </StyledTableRow>
                    ))
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </>
    </Box>
  );

}
function Manufacturewisepurchase() {
  return (
    <>
      <Box>
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Manufacturewisepurchaseall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Manufacturewisepurchase;