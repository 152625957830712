import React, { useState, useEffect, useContext} from "react";
import { userStyle } from "../../PageStyle";
import {
    Box, Grid, FormControl, InputLabel, OutlinedInput, Select, MenuItem, TextField, TableCell, Typography, Button, Table, Tooltip, IconButton, TableContainer, TableHead, TableRow, TableBody, DialogActions, DialogContent, Dialog
} from "@mui/material";
import {FaRegWindowClose } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import { FcInfo } from "react-icons/fc";
import { toast } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Mainlogo from "../../../assets/images/mainlogo.png";
import { Link } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import axios from 'axios';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { useParams } from 'react-router-dom';
import Headtitle from '../../../components/header/Headtitle';

const Sellview = () => {

    const { isUserRoleAccess } = useContext(UserRoleAccessContext);

    // sellreturn inside products array data
    const productInputs = {
        productid: "", productname: "", single: "", quantity: "", sellingpricetax: "", returnquantity: 0, taxtareval: "", subtotal: "", sellingexcludevalue: "", sellingincludevalue: "", applicabletax: "", discountamt: "0", purchaseexcludetax: ""
    }

    // sellreturn db store data 
    const [posAdd, setPosAdd] = useState({
        referenceno: "", customer: "", date: "", counter: "", location: "", contactnumber: "", contactid: "", creditlimit: "",
        ledgerbalance: "", totalitems: "", totalproducts: 0, grandtotal: 0, shippingtax: "", granddistype: "None", gdiscountvalue: 0, gdiscountamtvalue: 0, aftergranddisctotal: 0,
        totalbillamt: "", ordertax: "", packcharge: "", roundof: "", amountgain: 0, balance: 0, userbyadd: "", doctorname: "", patientname: "", mobile: "", address: "", diagnosis: "",
        paymentmethod: "", billno: ""
    });

    const [customers, setCustomers] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [products, setProducts] = useState([]);
    const [taxrates, setTaxrates] = useState();
    const [posdata, setPosdata] = useState({
        referenceno: "", customer: "", date: "", counter: "", location: "", contactnumber: "", contactid: "", creditlimit: "",
        ledgerbalance: "", totalitems: "", totalproducts: 0, grandtotal: 0, shippingtax: "", granddistype: "None", gdiscountvalue: 0, gdiscountamtvalue: 0, aftergranddisctotal: 0,
        totalbillamt: "", ordertax: "", packcharge: "", roundof: "", amountgain: 0, balance: 0, userbyadd: "", doctorname: "", patientname: "", mobile: "", address: "", diagnosis: "",
        paymentmethod: ""
    });
    const [pos, setPos] = useState([]);
    const [discounts, setDiscounts] = useState("");
    const [gotDiscount, setgotDiscount] = useState("");
    const [customer, setCustomer] = useState({});
    const [sellreturns, setSellReturns] = useState([]);
    const [sellrtns, setSellrtns] = useState([]);

    // sellreturn date
    const [purchaseDateTime, setPurchaseDateTime] = useState(dayjs());

    const [setngs, setSetngs] = useState();

    const { auth } = useContext(AuthContext)

    const id = useParams().id;

    let prodArr = [];

    // Error Popup model
    const [isErrorOpenalert, setIsErrorOpenalert] = useState(false);
    const [isshowAlert, setIsShowAlert] = useState()
    const handleClickOpenalert = () => {
        setIsErrorOpenalert(true);
    };
    const handleClose = () => {
        setIsErrorOpenalert(false);
    };



    // paynow button popup
    const [isPay, setIsPay] = useState(false);
    const handleClickOpenpay = () => {
        setIsPay(true);

    };
    const handleClosepay = () => {
        setIsPay(false);
    };

    // Show Ledger Alert
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const alertOpen = () => {
        setIsErrorOpen(true);
    };
    const alertClose = () => {
        setIsErrorOpen(false);
    };


    let discount;

    // get all sellreturn
    const fetchSellreturns = async () => {
        try {
            let res_sell = await axios.get(SERVICE.SELLRETURN, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setSellrtns(res_sell.data.sell1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const returnCheck = (value) => {
        tableData.map((data) => {
            if (Number(value) > Number(data.quantity)) {
                setIsShowAlert("Please Enter Value Less Than Quantity")
                handleClickOpenalert();
            }
        })
    }

    // get taxvrate data for products
    const taxrateRequest = async () => {
        try {
            let response = await axios.get(SERVICE.TAXRATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let taxRateData = response.data.taxrates.filter((data) => {
                return data.taxtype == "taxrate" || data.taxtype == "hsn"
            })
            setTaxrates(taxRateData);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }


    // fetch all products for get particular product in product select give products
    const fetchProductsall = async () => {
        try {
            let product = await axios.get(SERVICE.PRODUCT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setProducts(product.data.products);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };


    // get all stock data
    const fetchHandleStock = async () => {
        try {
            var response = await axios.get(SERVICE.PRODUCT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setProductsList(
                response.data.products?.map((d) => ({
                    ...d,
                    label: d.prodname + "-" + d.prodcode,
                    value: d.prodname,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages)
            alertOpen();
        }
    }

    // get all customer data from select option for customer
    const fetchCustomers = async () => {
        try {
            let customers = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setCustomers(
                customers?.data?.customers?.map((d) => ({
                    ...d,
                    label: d.name + "-" + d.cusid,
                    value: d.name,

                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //fetch sellreturn
    const fetchSellreturn = async () => {
        try {
            let sell = await axios.get(SERVICE.SELLRETURN, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setSellReturns(
                sell?.data?.sell1?.map((d) => ({
                    ...d,
                    label: d.referenceno,
                    value: d.referenceno,

                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // get all discount 
    const fetchDiscounts = async () => {
        try {
            let res = await axios.get(SERVICE.DISCOUNT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setDiscounts(res.data.discounts);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // get settings data
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.BUSINESS_SETTINGS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // get particular discount for particular product
    const getDiscount = async (e) => {
        discounts.map((item) => {
            if (e.sku == item.prodid) {
                discount = +item.discountamt;
                setgotDiscount(discount);
            }
        })
    }

    //bill no
    const getValue = (posid) => {
        let result = pos.filter((data) => {

            if (posid == data.referenceno) {
                setPosdata({
                    ...posdata, customer: data.customer, counter: data.counter, cusid: data._id, ledgerbalance: data.ledgerbalance, creditlimit: data.creditlimit,
                    doctorname: data.doctorname, patientname: data.patientname, mobile: data.mobile, address: data.address,
                    diagnosis: data.diagnosis, date: data.date
                })
                setProductsList(
                    data.goods?.map((d) => ({
                        ...d,
                        label: d.productname + "-" + d.productid,
                        value: d.productname,
                    }))
                );
            }
        })
    }

    // Search 
    const searchAdd = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.SELLRETURN_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSellReturns(res.data.sreturn);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    let quantity;
    let tot;

    const fetchDataProdquantity = (index, proid, quantity, vasubtotal, targetvalue) => {
        let first = (Number(vasubtotal) / Number(quantity));
        let second = (Number(targetvalue) * Number(first));
        const overtotal = tableData[index].single;
        const single = (overtotal / (Number(quantity)));
        let addQuantity = tableData.map((item, i) => {
            if (targetvalue == 0 || targetvalue > tableData[index].quantity) {
                setTableData([{
                    ...item, returnquantity: tableData[index].quantity, subtotal: overtotal
                }])
            }
            else {
                setTableData([{ ...item, returnquantity: targetvalue, subtotal: (single * Number(targetvalue)) }])
            }
        })
    }

    const fetchDataProdSearch = (e) => {
        setTableData((tableData) => {
            return [...tableData, {
                ...productInputs, productname: e.productname, productid: e.productid, quantity: e.quantity, sellingpricetax: e.sellingpricetax, single: e.subtotal,
                taxtareval: e.taxtareval, subtotal: e.subtotal, sellingexcludevalue: e.sellingexcludevalue, sellingincludevalue: e.sellingincludevalue
            }]
        });
    }
    useEffect(
        () => {
            grandtotalCalc();
        }, [tableData, posAdd.gdiscountvalue, posAdd.granddistype]
    )
    console.log(tableData, 'TBL')
    // grand total calculation
    const grandtotalCalc = () => {

        let totalgrandValue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                totalgrandValue += Number(value.subtotal)
            })
            if (posAdd.granddistype == "Fixed" || posAdd.granddistype == "Amount") {
                setPosAdd(
                    {
                        ...posAdd, gdiscountamtvalue: posAdd.gdiscountvalue, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue) - Number(posAdd.gdiscountvalue))
                    })
            }
            else if (posAdd.granddistype == "Percentage") {
                let percentCalc = Number(totalgrandValue) * (Number(posAdd.gdiscountvalue) / 100);
                setPosAdd(
                    {
                        ...posAdd, gdiscountamtvalue: percentCalc, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue) - percentCalc)
                    });
            }
            else {
                setPosAdd(
                    {
                        ...posAdd, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue))
                    }
                )
            }
        }
    }

    // total qunatity calculation
    const totalQuantityCalc = () => {
        let totalquanvalue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                totalquanvalue += Number(value.quantity)
            })
            return totalquanvalue.toFixed(0);
        }
    }

    let tempTotal = 0;
    // delete table data after data fetchparticular row
    const deleteRow = (i, e) => {
        setTableData(tableData.filter((e, item) => item !== i));
        tableData.splice(i, 1);
        if (tableData.length > 0) {
            tempTotal = 0
        }
    }


    // exit screen and re exit screen
    const fulscren = document.getElementById("fullScreen")

    function openFullscreen() {
        if (fulscren.requestFullscreen) {
            fulscren.requestFullscreen();
        } else if (fulscren.webkitRequestFullscreen) {
            fulscren.webkitRequestFullscreen();
        } else if (fulscren.msRequestFullscreen) {
            fulscren.msRequestFullscreen();
        }
    }

    // sellreturn autoid
    let newval = "SEL0001";

    // fetch pos
    const fetchpos = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPos(req.data.pos1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const fetchHandler = async () => {
        try {
            let response = await axios.get(`${SERVICE.SELLRETURN_SINGLE}/${id}`)
            setPosAdd(response.data.sreturn)
            setTableData(response.data.sreturn.goods);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    useEffect(() => {
        fetchHandler();
    }, [id])

    // cash open compare customer creditlimit to ledger balance
    const cashCustomerLedgerbalance = () => {
        if ((!posAdd.customer == "") || (!posAdd.customer == "Walk-in-customer")) {
            if ((customer.creditlimit <= customer.ledgerbalance)) {
                setShowAlert(
                    <>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: 'orange' }} />
                        <p style={{ fontSize: '20px', fontWeight: 900 }}>Check ledger balance</p>
                    </>
                );
                alertOpen();
            } else {
                handleClickOpenpay();
            }
        } else {
            handleClickOpenpay();
        }
        handleClickOpenpay();
    }


    // save sellreturn data to db cash
    const sendRequest = async () => {

        // reduce Current Stock in product
        tableData.map((item, index) => {
            productsList.forEach((data, i) => {
                if (item.sku == data.prodcode) {
                    axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                        currentstock: Number(data.currentstock) - Number(item.quantity),
                    });
                }
            })
        })

        //update ledger balance in customer
        axios.put(`${SERVICE.CUSTOMER_SINGLE}/${customer._id}`, {
            ledgerbalance: Number(customer.ledgerbalance) + Number(posAdd.aftergranddisctotal)
        })

        // Auto id for sellereturn
        {
            sellreturns && (
                sellreturns.map(
                    () => {
                        let strings = 'SEL';
                        let refNo = sellreturns[sellreturns.length - 1].referenceno;
                        let digits = (sellreturns.length + 1).toString();
                        const stringLength = refNo.length;
                        let lastChar = refNo.charAt(stringLength - 1);
                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                        let lastBeforeChar = refNo.slice(-2);
                        let lastThreeChar = refNo.slice(-3);
                        let lastDigit = refNo.slice(-4);
                        let refNOINC = parseInt(lastChar) + 1
                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                        let refLstThree = parseInt(lastThreeChar) + 1;
                        let refLstDigit = parseInt(lastDigit) + 1;
                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                            refNOINC = ("000" + refNOINC).substr(-4);
                            newval = strings + refNOINC;
                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                            refNOINC = ("00" + refLstTwo).substr(-4);
                            newval = strings + refNOINC;
                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                            refNOINC = ("0" + refLstThree).substr(-4);
                            newval = strings + refNOINC;
                        } else {
                            refNOINC = (refLstDigit).substr(-4);
                            newval = strings + refNOINC;
                        }
                    }))
        }
        try {
            let PRODUCT_REQ = await axios.put(`${SERVICE.SELLRETURN_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                customer: String(posdata.customer),
                billno: String(posdata.billno),
                location: String(posdata.city),
                contactnumber: String(posdata.contactnumber),
                date: String(purchaseDateTime),
                counter: String(posdata.counter),
                dueamount: String(posAdd.balance),
                creditlimit: String(posdata.creditlimit),
                ledgerbalance: String(customer.ledgerbalance),
                area: String(customer.area),
                whatsapp: String(posdata.contactnumber),
                gstn: String(customer.gstn),
                uin: String(customer.uin),
                aadhaar: String(customer.aadhaar),
                panno: String(customer.panno),
                country: String(customer.country),
                state: String(customer.state),
                city: String(customer.city),
                customergroup: String(customer.customergroup),
                creditdays: String(customer.creditdays),
                dlnos: String(customer.dlnos),
                activate: Boolean(customer.activate),
                goods: [...tableData],
                totalitems: String(tableData.length),
                grandtotal: String(posAdd.grandtotal),
                ordertax: String(posAdd.ordertax),
                shippingtax: String(posAdd.shippingtax),
                packcharge: String(posAdd.packcharge),
                granddistype: String(posAdd.granddistype),
                gdiscountvalue: Number(posAdd.gdiscountvalue),
                gdiscountamtvalue: Number(posAdd.gdiscountamtvalue),
                aftergranddisctotal: Number(posAdd.aftergranddisctotal),
                referenceno: String(newval),
                roundof: Number(posAdd.aftergranddisctotal),
                amountgain: Number(posAdd.amountgain),
                balance: Number(posAdd.balance),
                userbyadd: String(isUserRoleAccess.first),
                contactid: String(customer.cusid),
                doctorname: String(posAdd.posdata),
                patientname: String(posAdd.posdata),
                mobile: String(posAdd.posdata),
                address: String(posAdd.posdata),
                diagnosis: String(posAdd.posdata),
                paymentmethod: String("cash"),
            });
            setPosAdd(PRODUCT_REQ.data);
            handleClosepay();
            setPosAdd('');
        } catch (err) {
            const messages = err.response.data.message;
        }
    };

    const cardCustomerLedgerbalance = () => {
        if ((!posAdd.customer == "") || (!posAdd.customer == "Walk-in-customer")) {
            if ((customer.creditlimit <= customer.ledgerbalance)) {
                setShowAlert(
                    <>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "100px", color: 'orange' }} />
                        <p style={{ fontSize: '20px', fontWeight: 900 }}>Check ledger balance</p>
                    </>
                );
                alertOpen();
            } else {
            }
        } else {
        }
    }
    // save pos card to db for ledger balance calculation
    const sendRequestCredit = async () => {

        // reduce Current Stock in product
        tableData.map((item, index) => {
            productsList.forEach((data, i) => {
                if (item.sku == data.prodcode) {
                    axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                        currentstock: Number(data.currentstock) - Number(item.quantity),
                    });
                }
            })
        })

        //update ledger balance in customer
        axios.put(`${SERVICE.CUSTOMER_SINGLE}/${customer._id}`, {
            ledgerbalance: Number(customer.ledgerbalance) + Number(posAdd.aftergranddisctotal)
        })
        // Auto id for pos card
        {
            sellreturns && (
                sellreturns.map(
                    () => {
                        let strings = 'SEL';
                        let refNo = sellreturns[sellreturns.length - 1].referenceno;
                        let digits = (sellreturns.length + 1).toString();
                        const stringLength = refNo.length;
                        let lastChar = refNo.charAt(stringLength - 1);
                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                        let lastBeforeChar = refNo.slice(-2);
                        let lastThreeChar = refNo.slice(-3);
                        let lastDigit = refNo.slice(-4);
                        let refNOINC = parseInt(lastChar) + 1
                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                        let refLstThree = parseInt(lastThreeChar) + 1;
                        let refLstDigit = parseInt(lastDigit) + 1;
                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                            refNOINC = ("000" + refNOINC).substr(-4);
                            newval = strings + refNOINC;
                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                            refNOINC = ("00" + refLstTwo).substr(-4);
                            newval = strings + refNOINC;
                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                            refNOINC = ("0" + refLstThree).substr(-4);
                            newval = strings + refNOINC;
                        } else {
                            refNOINC = (refLstDigit).substr(-4);
                            newval = strings + refNOINC;
                        }
                    }))
        }
        try {
            let PRODUCT_REQ = await axios.put(`${SERVICE.SELLRETURN_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                customer: String(posdata.customer),
                billno: String(posdata.billno),
                location: String(posdata.city),
                contactnumber: String(posdata.contactnumber),
                date: String(purchaseDateTime),
                counter: String(posdata.counter),
                dueamount: String(posAdd.balance),
                creditlimit: String(posdata.creditlimit),
                ledgerbalance: String(customer.ledgerbalance),
                area: String(customer.area),
                whatsapp: String(posdata.contactnumber),
                gstn: String(customer.gstn),
                uin: String(customer.uin),
                aadhaar: String(customer.aadhaar),
                panno: String(customer.panno),
                country: String(customer.country),
                state: String(customer.state),
                city: String(customer.city),
                customergroup: String(customer.customergroup),
                creditdays: String(customer.creditdays),
                dlnos: String(customer.dlnos),
                activate: Boolean(customer.activate),
                goods: [...tableData],
                totalitems: String(tableData.length),
                grandtotal: String(posAdd.grandtotal),
                ordertax: String(posAdd.ordertax),
                shippingtax: String(posAdd.shippingtax),
                packcharge: String(posAdd.packcharge),
                granddistype: String(posAdd.granddistype),
                gdiscountvalue: Number(posAdd.gdiscountvalue),
                gdiscountamtvalue: Number(posAdd.gdiscountamtvalue),
                aftergranddisctotal: Number(posAdd.aftergranddisctotal),
                referenceno: String(newval),
                roundof: Number(posAdd.aftergranddisctotal),
                amountgain: Number(posAdd.amountgain),
                balance: Number(posAdd.balance),
                userbyadd: String(isUserRoleAccess.first),
                contactid: String(customer.cusid),
                doctorname: String(posAdd.posdata),
                patientname: String(posAdd.posdata),
                mobile: String(posAdd.posdata),
                address: String(posAdd.posdata),
                diagnosis: String(posAdd.posdata),
                paymentmethod: String("cash"),
            });
            setPosAdd(PRODUCT_REQ.data);
            toast.success(PRODUCT_REQ.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setPosAdd("");
            setTableData(clearvalall);
            setCustomers(' ');
            setCustomer(' ');
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            alertOpen();
        }
    };

    const handlereturnSubmit = (e) => {
        setPosAdd({ ...posAdd, referenceno: newval })
        // e.preventdefault();
        sendRequest();
    }

    let clearvalall = [];

    const handleSubmitclear = (e) => {
        setPosAdd(' ');
        setTableData(clearvalall);
        setCustomers([]);
        setCustomer(' ');
    };

    useEffect(() => {
        fetchpos();
        fetchCustomers();
        fetchSettings();
        fetchProductsall();
        fetchDiscounts();
        taxrateRequest();
        fetchHandleStock();
        fetchSellreturn();
    }, []);

    return (
        <Box id="fullScreen"
            sx={{
                backgroundColor: 'white',
                estimateition: "relative",
                overflow: "hidden",
            }}
        >
            <form >
                {/* Navbar Start */}
                <Headtitle title={'Sellreturn View'} />
                <Box sx={{ padding: "5px", backgroundColor: "#f0f2ff" }}>
                    <Grid container spacing={1} sx={{}} >
                        <Grid item lg={1} md={1} sm={2} xs={12}>
                            <Box sx={{ float: "left" }}>
                                <Link to="/">
                                    <img src={Mainlogo} alt="logo" style={{ width: '50px', height: '50px' }}></img>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={2} sm={8} xs={11} sx={{ marginTop: "17px" }}>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: "38px" }}>
                                    <SearchOutlinedIcon />
                                </Grid>
                                <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                    <InputLabel id="demo-select-small">Bill No </InputLabel>
                                    <OutlinedInput
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        label="Bill No"
                                        value={posAdd.billno}
                                        fullWidth
                                    >
                                        {pos && (
                                            pos.map((row, index) => (
                                                <MenuItem value={row.referenceno} onClick={(e) => { searchAdd(row._id); }} key={index}>{row.referenceno}</MenuItem>
                                            )))}
                                    </OutlinedInput>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={1} xs={1} >
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Customer Name </InputLabel>
                            <FormControl
                                size="small"
                                fullWidth
                                sx={{ display: "flex" }}
                            >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={posAdd.customer}
                                    sx={userStyle.estimateNavbarInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{ padding: "5px", margin: '0px' }}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Date </InputLabel>
                            <FormControl size="small" fullWidth sx={userStyle.estimateDateTime}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DateTimePicker
                                        renderInput={(props) => <TextField {...props} />}
                                        size='small'
                                        sx={userStyle.estimateNavbarInput}
                                        value={purchaseDateTime}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={1} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Counter </InputLabel>

                            <FormControl
                                size="small"
                                fullWidth
                                sx={{ display: "flex" }}
                            >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={posAdd.counter}
                                    type="text"
                                    sx={userStyle.estimateNavbarInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Credit Limit </InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: '34px' }}>
                                    <MoneyOutlinedIcon sx={{ marginTop: '-1px' }} />
                                </Grid>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{ display: "flex" }}
                                >
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={posAdd.creditlimit}
                                        type="text"
                                        sx={userStyle.estimateNavbarInput}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{}}>

                            <input value={customer.whatsappno} hidden onChange={(e) => { setPosAdd({ ...posAdd, contactnumber: e.target.value }) }} />
                            <input value={customer.city} hidden onChange={(e) => { setPosAdd({ ...posAdd, location: e.target.value }) }} />
                            <input value={customer.cusid} hidden onChange={(e) => { setPosAdd({ ...posAdd, contactid: e.target.value }) }} />
                        </Grid>
                    </Grid>
                </Box>
                {/* Navbar Ends */}
                <Grid container xs={12} sm={12} md={12} lg={12} sx={{ backgroundColor: "#f0f2ff", }} >
                    <Grid item xs={12} sm={12} md={8} lg={8} sx={{ paddingRight: '3px', backgroundColor: '#fff' }} >
                        <br />
                        <Grid container spacing={3}>
                            <Grid item md={1} sm={6} xs={12} sx={{ marginLeft: '25px' }}></Grid>
                            <Grid item md={9} sm={6} xs={12} sx={{ marginLeft: '25px' }}>
                                <Grid sx={{ display: "flex" }}>
                                    <Grid sx={userStyle.spanIcons} style={{ height: "38px" }}>
                                        {/* <SearchOutlinedIcon /> */}
                                    </Grid>
                                    <FormControl size="small" fullWidth>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item md={1} sm={6} xs={12} sx={{ marginLeft: '25px' }}></Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                {/* Table start */}
                                <TableContainer
                                    sx={{ paddingLeft: 1, height: '478px' }}
                                >
                                    <Table
                                        aria-label="customized table" padding='none'>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell sx={userStyle.tableHead1} style={{ marginLeft: '5px' }}>
                                                    {" "}
                                                    Product Name
                                                    <Tooltip arrow sx={{ zIndex: '1' }}
                                                        title="Click product name to edit price, discount & tax. Click Comment Icon to enter serial number / IMEI
                                                        or addional note. Click Modifier Icon(if enabled) for modifiers.">
                                                        <IconButton size="small">
                                                            <FcInfo />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Return Qty</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Qty</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Unit Cost ( Ex. Tax )</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Tax</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Unit Cost ( Inc. Tax )</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Discount value</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Subtotal </TableCell>
                                                <TableCell sx={userStyle.tableHead1}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableData.length > 0 &&
                                                tableData.map((data, i) => {
                                                    return (
                                                        <>
                                                            <TableRow sx={{ height: '16px' }}>
                                                                <TableCell key={i}>{data?.productname}</TableCell>
                                                                <TableCell>
                                                                    <OutlinedInput
                                                                        id="component-outlined"
                                                                        defaultValue={data?.returnquantity}
                                                                        type="Number"
                                                                        size="small"
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography
                                                                    >
                                                                        {data?.quantity}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {Math.abs(data?.sellingexcludevalue)}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {data?.taxtareval}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {data?.sellingincludevalue}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Grid container lg={12} md={12} sm={12} xs={12}>
                                                                        <Grid item lg={8} md={9} sm={9} xs={9}>
                                                                            <Typography>
                                                                                {data?.discountamt}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant='subtitle1'>{data?.subtotal}</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ color: 'red', fontWeight: '900', cursor: 'pointer', fontSize: '15px !important' }}><AiOutlineClose onClick={(e) => { deleteRow(i, e); }} /></TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* Table Ends */}
                                {/* Table Ends */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '-24px' }}>
                                <Grid container xs={12} sm={12} md={12} lg={12} spacing={1}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <Typography sx={{ marginLeft: '15px' }}>
                                            <b> Total Items :</b>{tableData.length}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={4} xs={12}
                                        sx={{ display: 'flex' }}
                                    >
                                        <FormControl size="small" fullWidth>
                                            <InputLabel> Discount Type </InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                fullWidth
                                                label="Discount Type"
                                                value={posAdd.granddistype}
                                                onChange={(e) => {
                                                    setPosAdd({ ...posAdd, granddistype: e.target.value })
                                                }}
                                            >
                                                <MenuItem value="None">None</MenuItem>
                                                <MenuItem value="Fixed">Fixed</MenuItem>
                                                <MenuItem value="Amount">Amount</MenuItem>
                                                <MenuItem value="Percentage">Percentage</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} sm={4} xs={12}>
                                        <TextField
                                            size="small"
                                            value={posAdd.gdiscountvalue}
                                            onChange={(e) => {
                                                setPosAdd({ ...posAdd, gdiscountvalue: e.target.value });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={5} sm={4} xs={12} sx={{ paddingLeft: '4px', paddingRight: '1px', marginTop: '-4px' }}>
                                        <Button fullWidth variant="contained" sx={{ marginTop: "5px", }}>
                                            <b>GRAND TOTAL :</b>&ensp;{posAdd.grandtotal}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={12} md={12} lg={12}>
                                    <Grid item md={2} sm={6} xs={12}>
                                        <Typography sx={{ marginTop: "5px", marginLeft: '15px' }}>
                                            <b>CGST:</b>{posAdd.gdiscountamtvalue}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={12}>
                                        <Typography sx={{ marginTop: "5px", marginLeft: '15px' }}>
                                            <b>SGST:</b>{posAdd.gdiscountamtvalue}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={12}>
                                        <Typography sx={{ marginTop: "5px", marginLeft: '15px' }}>
                                            <b>IGST:</b>{posAdd.gdiscountamtvalue}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{ p: 1, backgroundColor: '#fff', }}>
                        <Grid container  >
                            <Typography sx={userStyle.HeaderText}>Enter Details</Typography>
                            <Grid container xs={12} sm={12} md={12} lg={12} sx={{ padding: '10px' }} spacing={3}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={3}  >
                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                            <Grid container xs={12} sm={12} md={12} lg={12}>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <InputLabel id="demo-select-small"> Dr Name </InputLabel>
                                                    <Grid sx={{ display: "flex" }}>
                                                        <Grid sx={userStyle.spanIcons}>
                                                            <MoneyOutlinedIcon />
                                                        </Grid>
                                                        <FormControl
                                                            size="small"
                                                            fullWidth
                                                            sx={{ display: "flex" }}
                                                        >
                                                            <OutlinedInput
                                                                id="component-outlined"
                                                                type="text"
                                                                value={posAdd.doctorname}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <br />
                                                </Grid>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <InputLabel id="demo-select-small">
                                                        Patient Name /Customer
                                                    </InputLabel>
                                                    <Grid sx={{ display: "flex" }}>
                                                        <Grid sx={userStyle.spanIcons}>
                                                            <MoneyOutlinedIcon />
                                                        </Grid>
                                                        <FormControl
                                                            size="small"
                                                            fullWidth
                                                            sx={{ display: "flex" }}
                                                        >
                                                            <OutlinedInput
                                                                id="component-outlined"
                                                                type="text"
                                                                value={posAdd.patientname}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <br />
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Mobile
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ display: "flex" }}
                                                    >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="text"
                                                            value={posAdd.mobile}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Address
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ display: "flex" }}
                                                    >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="text"
                                                            value={posAdd.address}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Diagnosis
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ display: "flex" }}
                                                    >
                                                      <OutlinedInput
                                                            id="component-outlined"
                                                            type="text"
                                                            value={posAdd.diagnosis}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container sx={userStyle.btnGrid}>
                    <Grid item md={8} sm={8} xs={12} sx={{ display: "flex", color: 'black' }}>
                        <Link to="/sell/sellreturn/list"><Button disableRipple sx={userStyle.btnCancel}
                        >
                            <FaRegWindowClose />
                            &ensp;GO BACK
                        </Button></Link>
                        <Typography value={posAdd.totalbillamt}
                            sx={{ marginLeft: '15px', color: 'grey', fontSize: "20px" }}>
                            <b>Total:</b> <span style={{ color: 'green' }}>{posAdd.aftergranddisctotal}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpenalert}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{isshowAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            {/* Cash Dialog Ends */}
            <Dialog
                open={isErrorOpen}
                onClose={alertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <Typography>{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={alertClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
export default Sellview;