export const SidebarItems = [
    {
        id: 1,
        label: 'Home',
        dbname:'home',
        route: '/',
    },
    {
        id: 2,
        label: 'User Management',
        dbname:'usermanagement',
        name : 'user',
        children: [
            {
                id: 1,
                label: 'User',
                dbname:'alluser',
                route: '/user/user/list',
            },
            {
                id: 2,
                label: 'Role',
                dbname: 'allrole',
                route: '/user/role/list',
            },
            {
                id: 3,
                label: 'Department',
                dbname: 'alldepartment',
                route: '/user/department/list',
            }
        ]
    },
    {
        id: 3,
        label: 'Suppliers',
        dbname:'suppliermanagement',
        name: 'contact',
        children: [
            {
                id: 1,
                label: 'Add Suppliers',
                dbname:'asupplier',
                route: '/contact/supplier/create',
            },
            {
                id: 2,
                label: 'List Suppliers',
                dbname:'allsupplier',
                route: '/contact/supplier/list',
            },
            {
                id: 3,
                label: 'Import Suppliers',
                dbname:'isupplier',
                route: '/contact/supplier/import',
            },
            {
                id: 4,
                label: 'Import Supplier List',
                dbname:'importsupplier',
                route: '/contact/supplier/importsupplier',
            },
            {
                id: 5,
                label: 'Supplier Dues Report',
                dbname:'supplierduereport',
                route: '/contact/supplier/supplierduereport',
            },
            {
                id: 6,
                label: 'Balance Wise Supplier Dues Report',
                dbname:'balancewisesupduereport',
                route: '/contact/supplier/balancewisesupduereport',
            },
            {
                id: 7,
                label: 'Supplier Overdues Report',
                dbname:'supplieroverduereport',
                route: '/contact/supplier/supplieroverduereport',
            },
            {
                id: 8,
                label: 'Supplier Balance Abstract',
                dbname:'supplierbalanceabstract',
                route: '/contact/supplier/supplierbalanceabstract',
            },
            {
                id: 9,
                label: 'Supplier Payments Report',
                dbname:'supplierpaymentsreport',
                route: '/contact/supplier/supplierpaymentsreport',
            },
            {
                id: 10,
                label: 'Supplier Wise Payments Report',
                dbname:'supplierwisepaymentsreport',
                route: '/contact/supplier/supplierwisepaymentsreport',
            },
            {
                id: 11,
                label: 'Supplier Bills Report',
                dbname:'supplierbillsreport',
                route: '/contact/supplier/supplierbillsreport',
            },
            {
                id: 12,
                label: 'Supplier Report',
                dbname:'suppliersreport',
                route: '/contact/supplier/suppliersreport',
            }
        ]
    },

    {
        id: 4,
        label: 'Customers',
        dbname: 'customermanagement',
        name: 'contact',
        children: [
            {
                id: 1,
                label: 'ADD - Retail Customer',
                dbname: 'customermasterretail',
                route: '/contact/customer/retail/create',
            },
            {
                id: 2,
                label: 'ADD - Wholesale Customer ',
                dbname: 'customermasterwholesale',
                route: '/contact/customer/wholesale/create',
            },
            {
                id: 3,
                label: 'Import Customers',
                dbname: 'icustomer',
                route: '/contact/customer/import',
            },
            {
                id: 4,
                label: 'List Customers',
                dbname: 'allcustomer',
                route: '/contact/customer/list',
            },
            {
                id: 5,
                label: 'List - Retail Customers',
                dbname: 'importcustomerlistretail',
                route: '/contact/customer/retail/list',
            },
            {
                id: 6,
                label: 'List - Wholesale Customers',
                dbname: 'importcustomerlistwsale',
                route: '/contact/customer/wholesale/list',
            },
            {
                id: 7,
                label: 'List Customer Group',
                dbname:'allcustomergrp',
                route: '/contact/customergroup/list',
            },
            {
                id: 8,
                label: 'Customer Payment Register',
                dbname: 'customerpaymentregister',
                route: '/contact/customer/customerpaymentregister',
            },
            {
                id: 9,
                label: 'Customer Bills Register',
                dbname: 'customerbillsregister',
                route: '/contact/customer/customerbillsregister',
            },
            {
                id: 10,
                label: 'Customer Drug Entry',
                dbname: 'customerdrugentry',
                route: '/contact/customer/customerdrugentry',
            },
            {
                id: 11,
                label: 'Retail Follow Customer',
                dbname: 'retailfollowcustomer',
                route: '/contact/customer/retailfollowcustomer',
            },
            {
                id: 12,
                label: 'Wholesale Followup Customer',
                dbname: 'wholesalefollowcustomer',
                route: '/contact/customer/wholesalefollowupcustomer',
            },
            {
                id: 13,
                label: 'Customer Balance Register',
                dbname: 'customerbalanceregister',
                route: '/contact/customer/customerbalanceregister',
            },
            {
                id: 14,
                label: 'Customer Dues Report',
                dbname: 'customerduesreport',
                route: '/contact/customer/customerduesreport',
            },
            {
                id: 15,
                label: 'Customer Over Dues Report',
                dbname: 'Customeroverduesreport',
                route: '/contact/customer/Customeroverduesreport',
            },
            {
                id: 16,
                label: 'Customer Over Dues Abstract',
                dbname: 'Customeroverdueabstract',
                route: '/contact/customer/Customeroverdueabstract',
            },
            {
                id: 17,
                label: 'Balance Wise Customer Dues Report',
                dbname: 'Balanacewisecustomerduesreport',
                route: '/contact/customer/Balanacewisecustomerduesreport',
            },
            {
                id: 18,
                label: 'Customer Account Statement',
                dbname: 'customeraccountstatement',
                route: '/contact/customer/customeraccountstatement',
            },
            {
                id: 19,
                label: 'Customer Account Summary',
                dbname: 'customeraccountsummary',
                route: '/contact/customer/customeraccountsummary',
            },
            {
                id: 20,
                label: 'Reference Wise Dues Report (Sales)',
                dbname: 'Referencewiseduesales',
                route: '/contact/customer/Referencewiseduesales',
            },
            {
                id: 21,
                label: 'Reference Wise Dues Report (Estimates)',
                dbname: 'Referencewiseduesreport',
                route: '/contact/customer/Referencewiseduesreport',
            },
            {
                id: 22,
                label: 'Customer Payment Report',
                dbname: 'customerpaymentreport',
                route: '/contact/customer/customerpaymentreport',
            },
            {
                id: 23,
                label: 'Customer Wise Payment Report',
                dbname: 'customerwisepaymentreport',
                route: '/contact/customer/customerwisepaymentreport',
            },
            {
                id: 24,
                label: 'Reference Wise Payments Report',
                dbname: 'Referencewisepaymentreport',
                route: '/contact/customer/Referencewisepaymentreport',
            },
            {
                id: 25,
                label: 'Reference Wise Payments Summary',
                dbname: 'Referencewisepaymentsummary',
                route: '/contact/customer/Referencewisepaymentsummary',
            },
            {
                id: 26,
                label: 'Remarks Wise Report',
                dbname: 'Remarkswisereport',
                route: '/contact/customer/Remarkswisereport',
            },
            {
                id: 27,
                label: 'Customer Bills Report',
                dbname: 'customerbillsreport',
                route: '/contact/customer/customerbillsreport',
            },
            {
                id: 28,
                label: 'Category Wise Customers List',
                dbname: 'customerslist',
                route: '/contact/customer/categorywisecustomerlist',
            },
            {
                id: 29,
                label: 'Area Wise Customer List',
                dbname: 'creawisecustomerlist',
                route: '/contact/customer/areawisecustomerlist',
            },
        ]
    },
    {
        id:5,
       label: 'Products',
        dbname:'productmanagement',
        name: 'product',
        children:[
            {
                id: 1,
                label: 'Category',
                dbname:'allcategory',
                route: '/product/category/list',
            },
            {
                id: 2,
                label: 'Schedule',
                dbname:'allschedule',
                route: '/product/schedule',
            },
            {
                id: 3,
                label: 'Unit',
                dbname:'allunit',
                route: '/product/unit/list',
            },
            {
                id:4,
                label: 'Add Products',
                dbname:'aproduct',
                route: '/product/product/create',
            },
            {
                id: 5,
                label: 'Import Products',
                dbname:'iproduct',
                route: '/product/importproducts',
            },
            {
                id: 6,
                label: 'List products',
                dbname:'allproduct',
                route: '/product/product/list',
            },
            {
                id: 7,
                label: 'Discount',
                dbname:'alldiscount',
                route: '/product/discount/list',
               
            },
            {
                id: 8,
                label: 'Category Item List',
                dbname:'categorywiselist',
                route: '/product/categorywiselist',
            },
            {
                id: 9,
                label: 'Molecule Item list',
                dbname:'moleculewiselist',
                route: '/product/moleculewiselist',
            },
            {
                id: 10,
                label: 'Manufacture Item list',
                dbname:'manufacturewiselist',
                route: '/product/manufacturewiselist',
            },
            {
                id: 11,
                label: 'Schedule Item list',
                dbname:'schedulewiselist',
                route: '/product/schedulewiselist',
            },
            {
                id: 12,
                label: 'Category Item Summary',
                dbname:'categoryitemsummary',
                route: '/product/categorysummary',
            },
            {
                id: 13,
                label: 'Molecule Item Summary',
                dbname:'moleculesummary',
                route: '/product/moleculesummary',
            },
            {
                id: 14,
                label: 'Manufacture Item Summary',
                dbname:'manufacturesummary',
                route: '/product/manufacturesummary',
            },
            {
                id: 15,
                label: 'Item search',
                dbname:'itemsearch',
                route: '/product/itemsearch',
            },
        ]
    },
    {
        id: 6,
        label: 'Purchases',
        dbname:'purchasemanagement',
        name: 'purchase',
        children:[
            {
                id: 1,
                label: 'Purchase Register',
                dbname:'addpurchase',
                route: '/purchase/Purchase/create',
            },
            {
                id: 2,
                label: 'Purchase Return Register',
                dbname:'addpurchasereturn',
                route: '/purchase/purchasereturn/create',
            },
            {
                id: 3,
                label: 'Purchase List',
                dbname:'allpurchase',
                route: '/purchase/purchase/list',
            },
            {
                id: 4,
                label: 'Purchase Return List',
                dbname:'allpurchasereturn',
                route: '/purchase/purchasereturn/list',
            },
            {
                id: 5,
                label: 'Supplier Product Link',
                dbname:'itemwisepurchasereturn',
                route: '/purchase/SupplierProductlink',
            },
            {
                id: 6,
                label: 'Purchase Report',
                dbname:'purchasereport',
                route: '/purchase/purchasereport',
            },
            {
                id: 7,
                label: 'Purchase Detailed Report',
                dbname:'purchasedetailreport',
                route: '/purchase/purchasedetailedreport',
            },
            {
                id: 8,
                label: 'Supplier Wise Purchase Report',
                dbname:'supplierwisepursreport',
                route: '/purchase/supplierwisereport',
            },
            {
                id: 9,
                label: 'Item Wise Purchase',
                dbname:'itemwisepurchase',
                route: '/purchase/itemwisepurchase',
            },

            {
                id: 10,
                label: 'Category Wise Purchase',
                dbname:'categorywisepurchase',
                route: '/purchase/categorywisepurchase',
            },
            {
                id: 11,
                label: 'Supplier Wise purchase',
                dbname:'supplierwisepurchase',
                route: '/purchase/supplierwisepurchase',
            },
            {
                id: 12,
                label: 'Manufacture Wise Purchase',
                dbname:'manufacturewisereport',
                route: '/purchase/manufacturwisepurchase',
            },
            {
                id: 13,
                label: 'Purchase Return Report',
                dbname:'purchasereturnreport',
                route: '/purchase/purchasereturnreport',
            },
            {
                id: 14,
                label: 'Item Wise Purchase Return',
                dbname:'itemwisepurchasereturn',
                route: '/purchase/itemwisepurchasereturn',
            }
        ]
    },

    {
        id: 7,
        label: 'Sell',
        dbname:'sellmanagement',
        name: 'sell',
        children: [
            {
                id: 1,
                label: 'POS',
                dbname:'apos',
                route: '/sell/pos/create',
            },
            {
                id: 2,
                label: 'List POS',
                dbname:'allpos',
                route: '/sell/pos/list',
            },
            {
                id: 3,
                label: 'Create Sellreturn',
                dbname:'allpos',
                route: '/sell/sellreturn/create',
            },
            {
                id: 4,
                label: 'Diagnosis Wise Sales',
                dbname:'aquotation',
                route: '/sell/diagnosiswisesales',
            },
            {
                id: 5,
                label: 'Diagnosis Wise Sales Summary',
                dbname:'aquotation',
                route: '/sell/diagnosiswisesummary',
            },
            {
                id: 6,
                label: ' Sales Summary',
                dbname:'aquotation',
                route: '/sell/salessummary',
            },
            {
                id: 7,
                label: ' Day Wise Sales ',
                dbname:'aquotation',
                route: '/sell/daywisesales',
            },
            {
                id: 8,
                label: ' Molecule Wise Sales',
                dbname:'aquotation',
                route: '/sell/moleculewisesales',
            },
            {
                id: 9,
                label: 'Manufacture Wise Sales ',
                dbname:'aquotation',
                route: '/sell/manufacturewisesales',
            },
            {
                id: 10,
                label: ' sellreturn list',
                dbname:'aquotation',
                route: 'sell/sellreturn/list',
            },
            {
                id: 11,
                label: 'Supplier Wise Sales',
                dbname:'allquotation',
                route: '/sell/supplierwisesales',
            },
            {
                id: 12,
                label: 'Dr. Wise Sales',
                dbname:'allquotation',
                route: '/sell/drwisesales',
            },
            {
                id: 13,
                label: 'Category Wise Sales',
                dbname:'allquotation',
                route: '/sell/categorywisesales',
            },

            {
                id: 14,
                label: 'Area Wise Sales',
                dbname:'allquotation',
                route: '/sell/areawisesales',
            },
            {
                id: 15,
                label: 'Patient Wise Sales(Individual)',
                dbname:'allquotation',
                route: '/sell/patientwisesales',
            },
            {
                id: 16,
                label: 'Item Wise Sales',
                dbname:'allquotation',
                route: '/sell/itemwisesales',
            },
            {
                id: 17,
                label: ' Sales  Report',
                dbname:'allquotation',
                route: '/sell/salesreport',
            },
            {
                id: 18,
                label: ' Patient Wise Sales Report',
                dbname:'allquotation',
                route: '/sell/patientwisesalesreport',
            },
            {
                id: 19,
                label: ' Dr. Wise Sales Report',
                dbname:'allquotation',
                route: '/sell/drwisesalereport',
            },
            {
                id: 20,
                label: ' Sale Return Report(Retail)',
                dbname:'allquotation',
                route: '/sell/salereturnreaport',
            },
            {
                id: 21,
                label: 'Sale Return Summary',
                dbname:'allquotation',
                route: '/sell/Salereturnsummary',
            },
            {
                id: 22,
                label: 'Item Wise Sale Return Detail',
                dbname:'allquotation',
                route: '/sell/itemwisesalereturn',
            },
            {
                id: 23,
                label: ' Supplier Wise Sales Return',
                dbname:'allquotation',
                route: '/sell/supplierwisesalereturn',
            },
            {
                id: 24,
                label: 'Manufacture Wise Sales Return',
                dbname:'allquotation',
                route: '/sell/Manufacturewisereturnstatus',
            },
            {
                id: 25,
                label: 'Drug Register',
                dbname:'allquotation',
                route: '/sell/Drugregister',
            },
            {
                id: 26,
                label: 'Schedule Wise Sales',
                dbname:'allquotation',
                route: '/sell/schedulewisesalelist',
            },
            {
                id: 27,
                label: 'Draft',
                dbname:'allquotation',
                route: '/sell/draft/list',
            },
            {
                id: 28,
                label: 'Quotation',
                dbname:'allquotation',
                route: '/sell/quotation/list',
            },
        ]
    },
    //estimate module
    {
        id: 8,
        label: 'Estimate',
        dbname:'allestimates',
        name: 'estimate',
        children: [
            {
                id: 1,
                label: 'Estimate Preparation',
                dbname:'addestimate',
                route: '/estimate/estimate/create',
            },
            {
                id: 2,
                label: 'Estimate Return Register',
                dbname:'addestimatereturn',
                route: '/estimate/estimatereturnregister',
            },
            {
                id: 3,
                label: ' Estimate Report',
                dbname:'estimatereport',
                route: '/estimate/estimatereport',
            },
            {
                id: 3,
                label: ' Estimate List',
                dbname:'estimatelist',
                route: '/estimate/estimate/list',
            },
            {
                id: 4,
                label: ' Customer Wise Estimate Report ',
                dbname:'customerwiseestimatereport',
                route: '/estimate/customerwiseestimatereport',
            },
            {
                id: 5,
                label: ' Day Wise Estimate Report',
                dbname:'daywiseestimatereport',
                route: '/estimate/daywiseestimatereport',
            },
            {
                id: 6,
                label: 'Item Wise Estimate ',
                dbname:'itemwiseestimate',
                route: '/estimate/itemwiseestimate',
            },
            {
                id: 7,
                label: ' Category Wise Estimate ',
                dbname:'categorywiseestimate',
                route: '/estimate/categorywiseestimate',
            },
            {
                id: 8,
                label: 'Manufacture Wise Estimate',
                dbname:'manufacturewiseestimate',
                route: '/estimate/manufacturewiseestimate',
            },
            {
                id: 9,
                label: 'Supplier Wise Estimate',
                dbname:'supplierwiseestimate',
                route: '/estimate/supplierwiseestimate',
            },
            {
                id: 10,
                label: 'Area Wise Estimate',
                dbname:'areawiseestimate',
                route: '/estimate/areawiseestimate',
            },
            {
                id: 11,
                label: 'Reference Wise Estimate',
                dbname:'referencewiseestimate',
                route: '/estimate/referencewiseestimate',
            },
            {
                id: 12,
                label: 'Estimate Return Report',
                dbname:'estimatereturnreport',
                route: '/estimate/estimatereturnreport',
            },
            {
                id: 13,
                label: ' Item Wise Estimate Return',
                dbname:'itemwiseestimatereturn',
                route: '/estimate/itemwiseestimatereturn',
            },
            {
                id: 14,
                label: ' Supplier Wise Estimate Return ',
                dbname:'supplierwiseestimatereturn',
                route: '/estimate/supplierwiseestimatereturn',
            },
            {
                id: 15,
                label: ' Manufacture Wise Estimate Return',
                dbname:'manufacturewiseestimatereturn',
                route: '/estimate/manufacturewiseestimatereturn',
            },
            {
                id: 16,
                label: 'Draft',
                dbname:'alldraft',
                route: '/estimate/draft/list',
            },
            {
                id: 17,
                label: 'Quotation',
                dbname:'allquotation',
                route: '/estimate/quotation/list',
            },
        ]
    },
    //stock module
    {
        id: 9,
        label: 'Stock',
        dbname:'settingsmanagement',
        name: 'setting',
        children: [
            {
                id: 1,
                label: 'Manual Stock Entry',
                dbname:'manualstock',
                route: '/stock/stock/stockentry',
            },
            {
                id: 2,
                label: 'Stock Import [Bulk Upload]',
                dbname:'stockimport',
                route: '/stock/stock/import',
            },
            {
                id: 3,
                label: 'Stock Update',
                dbname:'stockupdate',
                route: '/stock/stock/update',
            },
            {
                id: 4,
                label: 'Pack Qunatity Update',
                dbname:'packupdate',
                route: '/stock/packqty/update',
            },
            {
                id: 5,
                label: 'Manufacture Update',
                dbname:'manufactureupdate',
                route: '/stock/manufacture/update',
            },
            {
                id: 6,
                label: 'Item Code Update',
                dbname:'itemcodeupdate',
                route: '/stock/itemcode/update',
            },
            {
                id: 7,
                label: 'Stock Overview Report',
                dbname:'stockoverviewreport',
                route: '/stock/stock/overviewreport',
            },
            {
                id: 8,
                label: 'Stock Expiry Report',
                dbname:'stockexpiryreport',
                route: '/stock/stock/expiryreport',
            },
            {
                id: 9,
                label: 'Item wise stock report',
                dbname:'nillstockreport',
                route: '/stock/itemwise/report',
            },
            {
                id: 10,
                label: 'Nill Stock Report',
                dbname:'nillstockreport',
                route: '/stock/stock/nillreport',
            },
            {
                id: 11,
                label: 'Minimum Stock Report',
                dbname:'minimumstockreport',
                route: '/stock/stock/minimumreport',
            },
            {
                id: 12,
                label: 'Non Moving Stock Report',
                dbname:'nonmovingstockreport',
                route: '/stock/stock/nonmovingreport',
            },
            {
                id: 13,
                label: 'Batch Wise Stock Report ',
                dbname:'batchstockreport',
                route: '/stock/stock/batchwisereport',
            },
            {
                id: 14,
                label: 'Batch Wise Stock Details',
                dbname:'batchstockdetail',
                route: '/stock/stock/batchwisedetails',
            },
        ]
    },
    {
        id: 10,
        label: 'Expenses',
        dbname:'expensemanagement',
        name: 'expenses',
        children: [
            {
                id: 1,
                label: 'Add Expenses',
                dbname:'aexpense',
                route: '/expense/expense/create',
            },
            {
                id: 2,
                label: 'List Expenses',
                dbname:'allexpense',
                route: '/expense/expense/list',
            },
            {
                id: 3,
                label: 'Expenses Categories',
                dbname:'allexpensecategory',
                route: '/expense/expensecategory/list',
            }
        ]
    },
    {
        id: 11,      
        label: 'Account',
        dbname: 'CheckBank',
        name: 'Account',
        children: [
            {
                id: 1,
                label: 'Cash Book Register',
                dbname: 'businesssettings',
                route: '/account/cashbookregister',
            },
            {
                id: 2,
                label: 'Profit & Loss Account',
                dbname: 'businesssettings',
                route: '/account/profitlossaccount',
            },
            {
                id: 3,
                label: 'Trail Balance',
                dbname: 'businesssettings',
                route: '/account/trailbalance',
            },
            {
                id: 4,
                label: 'Group Summary',
                dbname: 'businesssettings',
                route: '/account/groupsummarys',
            },
            {
                id: 5,
                label: 'Bank Statement',
                dbname: 'businesssettings',
                route: '/account/bankstatement',
            },
            {
                id: 6,
                label: 'Cash Book Report',
                dbname: 'CashBookReport',
                route: '/account/CashBookReport',
            },
            {
                id: 7,
                label: 'Cash Book Register Report',
                dbname: 'CashbookRegreport',
                route: '/account/CashbookRegisterreport',
            },
            {
                id: 8,
                label: "Day Book Printing",
                dbname: "allexpense",
                route: "/account/Daybookprint",
            },
            {
                id: 9,
                label: "Account and Group creation",
                dbname: 'allcustomergrp',
                route: '/account/accountandgroupcreation',
            },
            {
                id: 10,
                label: "Voucher",
                dbname: 'allvoucher',
                route: '/account/voucher/list',
            },
        ]
    },
    {
        id: 12,
        label: "Bank",
        dbname: "bankmanagement",
        name: "bank",
        children: [
            {
                id: 1,
                label: "Add Bank Master",
                dbname: "bankmaster",
                route: "/bank/bank/create",
            },
            {
                id: 2,
                label: "List Bank Master",
                dbname: "listbankmaster",
                route: "/bank/bank/list",
            },
            {
                id: 3,
                label: "Bank Master Report",
                dbname: "banklist",
                route: "/bank/bank/report",
            },
            {
                id: 4,
                label: "Bank Book Register",
                dbname: "bankbookregister",
                route: "/bank/book/create",
            },
            {
                id: 5,
                label: "Bank Book Report",
                dbname: "bankbookreport",
                route: "/bank/book/report",
            },
        ],
    },
    {
        id: 13,
        label: 'Reports',
        dbname: 'expensemanagement',
        name: 'report',
        children: [
            {
                id: 1,
                label: 'Bill Wise Profit Wholesale',
                dbname: 'aexpense',
                route: '/report/billwiseprofitwholesale',
            },
            {
                id: 2,
                label: 'Bill Wise Profit Retail',
                dbname: 'aexpense',
                route: '/report/billwiseprofitretail',
            },
            {
                id: 3,
                label: 'Bill Wise Profit Estimate',
                dbname: 'aexpense',
                route: '/report/billwiseprofitestimate',
            },
            {
                id: 4,
                label: 'Day Wise Profit Wholesale',
                dbname: 'aexpense',
                route: '/report/daywiseprofitwholesale',
            },
            {
                id: 5,
                label: 'Day Wise Profit Retail',
                dbname: 'aexpense',
                route: '/report/daywiseprofitretail',
            },
            {
                id: 6,
                label: 'Day Wise Profit Estimate',
                dbname: 'aexpense',
                route: '/report/daywiseprofitestimate',
            },
            {
                id: 7,
                label: 'Item Wise Profit Wholesale',
                dbname: 'aexpense',
                route: '/report/itemwiseprofitwholesale',
            },
            {
                id: 8,
                label: 'Item Wise Profit Retail',
                dbname: 'aexpense',
                route: '/report/itemwiseprofitretail',
            },
            {
                id: 9,
                label: 'Item Wise Profit Estimate',
                dbname: 'aexpense',
                route: '/report/itemwiseprofitestimate',
            },
            {
                id: 10,
                label: 'Customer Wise Profit Wholesale',
                dbname: 'aexpense',
                route: '/report/customerwiseprofitwholesale',
            },
            {
                id: 11,
                label: 'Customer Wise Profit Estimate',
                dbname: 'aexpense',
                route: '/report/customerwiseprofitestimate',
            },
            {
                id: 12,
                label: 'Reference Summary',
                dbname: 'aexpense',
                route: '/report/referencesummary',
            },
            {
                id: 13,
                label: 'Reference Comparison',
                dbname: 'aexpense',
                route: '/report/Referencecomparison',
            },
            {
                id: 14,
                label: 'Customer Comparison',
                dbname: 'aexpense',
                route: '/report/customercomparison',
            }
          ],
        },  
    {
        id: 14,
        label: 'Settings',
        dbname:'settingsmanagement',
        name: 'setting',
        children: [
            {
                id: 1,
                label: 'Business Settings',
                dbname:'businesssettings',
                route: '/settings/business/list',
            },
            {
                id: 2,
                label: 'Business Location',
                dbname:'allbusinesslocation',
                route: '/settings/location/list',
            },
            {
                id: 3,
                label: 'Alpharate',
                dbname:'allalpharate',
                route: '/settings/alpharate/list',
            },
            {
                id: 4,
                label: 'Tax Rates',
                dbname:'alltaxrate',
                route: '/settings/taxrate/list',
            },
            {
                id: 5,
                label: 'Payment Integration',
                dbname:'allpaymentintegration',
                route: '/settings/paymentintegration/list',
            },
            {
                id: 6,
                label: "GSTR Purchase",
                dbname: "Gstrpurchase",
                route: "/settings/taxrate/Gstrpurchase",
              },
              {
                id: 7,
                label: "GSTR Retail Sales Return",
                dbname: "Gstrretailsales",
                route: "/settings/taxrate/Gstrretailsalesreturn",
              },
              {
                id: 8,
                label: "HSN Wise Summary-Purchase",
                dbname: "Hsnpurchasesum",
                route: "/settings/taxrate/Hsnwisesummarypurchase",
              },
              {
                id: 9,
                label: "HSN Wise Report-Wholesale",
                dbname: "Hsnrepwholesales",
                route: "/settings/taxrate/Hsnwisereportwholesales",
              },
              {
                id: 10,
                label: "GSTR Sales Retail",
                dbname: "GstrsalesRetail",
                route: "/settings/taxrate/GstrsalesRetail",
              },
              {
                id: 11,
                label: "GSTR Purchase Return",
                dbname: "GstrpurchaseRet",
                route: "/settings/taxrate/GstrpurchaseReturn",
              },
              {
                id: 12,
                label: "GSTR Whole Sale",
                dbname: "Gstrwholesale",
                route: "/settings/taxrate/Gstrwholesale",
              },
              {
                id: 13,
                label: "GSTR Whole Sale Return",
                dbname: "Gstrwholesaleret",
                route: "/settings/taxrate/GstrwholesaleReturn",
              },
              {
                id: 14,
                label: 'HSN Wise Report Retails',
                dbname: 'HSNreportretails',
                route: '/settings/taxrate/HSNwisereportretails',
                },
                {
                id: 15,
                label: 'HSN Wise Report Whole Sale Return',
                dbname: 'Hsnrepwholereturn',
                route: '/settings/taxrate/Hsnwisereportwholesalereturn',
                },
                {
                id: 16,
                label: 'HSN Wise Report Retail Sales Return',
                dbname: 'Hsnretailsalesret',
                route: '/settings/taxrate/Hsnwisereportretailsalesreturn',
                },
        ]
    },
]

