import React, {  useContext} from 'react';
import { Box, Button, Grid, Dialog, DialogContent, DialogActions, Paper, Typography, TableContainer, Table, TableHead, TableBody } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { UserRoleAccessContext } from "../../context/Appcontext";
import { userStyle } from '../PageStyle';
import { ExportXL, ExportCSV } from '../Export';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../components/header/Headtitle';

function ItemCodeUpdateDetails() {

    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    return (

        <Box>
            <Headtitle title={'Item Code Update'} />
            <Typography sx={userStyle.HeaderText}>Item Code Update</Typography>
            <br />
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography sx={userStyle.HeaderText}></Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].uitemcodeupdate && (
                            <>
                                <Button sx={userStyle.buttonadd} >UPDATE</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                <Grid container sx={userStyle.gridcontainer}>
                      <Grid >
                        {isUserRoleCompare[0].excelitemcodeupdate && (
                            <>
                                <ExportCSV />
                            </>
                        )}
                        {isUserRoleCompare[0].csvitemcodeupdate && (
                            <>
                                <ExportXL />
                            </>
                        )}
                        {isUserRoleCompare[0].printitemcodeupdate && (
                            <>
                                <Button sx={userStyle.buttongrp} >&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfitemcodeupdate && (
                            <>
                                <Button sx={userStyle.buttongrp} ><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                </Grid>
              </Grid><br />
                <Box>
                    {/* ****** Table Start ****** */}
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="expensetable">
                            <TableHead>
                                <StyledTableRow >
                                    <StyledTableCell >Item Code</StyledTableCell>
                                    <StyledTableCell >Item Name</StyledTableCell>
                                    <StyledTableCell >Item Group</StyledTableCell>
                                    <StyledTableCell >Schedule</StyledTableCell>
                                    <StyledTableCell >Manufacture</StyledTableCell>
                                    <StyledTableCell >Molecule</StyledTableCell>
                                    <StyledTableCell >Pack</StyledTableCell>
                                    <StyledTableCell >HSN</StyledTableCell>
                                    <StyledTableCell >Minimum</StyledTableCell>
                                    <StyledTableCell >Maximum</StyledTableCell>
                                    <StyledTableCell >Codes</StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                    <StyledTableCell ></StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer> 
                    {/* ****** Table End ****** */}
                </Box>
                {/* print layout */}
                <TableContainer component={Paper} sx={userStyle.printcls}>
                    <Table sx={{ minWidth: 700, }} aria-label="customized table" id="expensetable" >
                        <TableHead>
                            <StyledTableRow >
                                <StyledTableCell >Item Code</StyledTableCell>
                                <StyledTableCell >Item Name</StyledTableCell>
                                <StyledTableCell >Item Group</StyledTableCell>
                                <StyledTableCell >Schedule</StyledTableCell>
                                <StyledTableCell >Manufacture</StyledTableCell>
                                <StyledTableCell >Molecule</StyledTableCell>
                                <StyledTableCell >Pack</StyledTableCell>
                                <StyledTableCell >HSN</StyledTableCell>
                                <StyledTableCell >Minimum</StyledTableCell>
                                <StyledTableCell >Maximum</StyledTableCell>
                                <StyledTableCell >Codes</StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody align="left">
                            <StyledTableRow >
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                                <StyledTableCell ></StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* ALERT DIALOG */}
            <Dialog
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent  sx={{ width: '350px',  textAlign:'center', alignItems:'center'}}>
                    <ErrorOutlineOutlinedIcon sx={{fontSize:"80px", color:'orange'}} />
                    <Typography variant="h5" sx={{color:'red', textAlign:'center'}}>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined">Cancel</Button>
                    <Button autoFocus  variant="contained" color='error'> OK  </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
function ItemCodeUpdate() {
    return (
        <>
            <Box  >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        < ItemCodeUpdateDetails/><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default ItemCodeUpdate ;