import React, { useState, useEffect, useRef, useContext } from 'react';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { Box, Grid, FormControl, Typography, TextField, Table, TableBody, TableContainer, TableHead, Paper, Button } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import $ from 'jquery';
import autoTable from 'jspdf-autotable';
import { userStyle } from '../PageStyle';
import axios from 'axios';
import jsPDF from "jspdf";
import { toast } from 'react-toastify';
import { ExportXL, ExportCSV } from '../Export';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { AuthContext } from '../../context/Appcontext';

function Itemwisepurchasereportlist() {

  const [itemwisepurchases, setItemwisepurchases] = useState([]);

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  // Export Excel
  const [exceldata, setExceldata] = useState([]);

  //purchase fetching
  const fetchPurchases = async () => {
    try {
      let res_purchase = await axios.get(SERVICE.PURCHASE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let getDatawithFilter = res_purchase.data.purchases.filter((data) => {
        let splittedMonth = data.purchasedate
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })
      let purchase = [];
      getDatawithFilter != "" ? getDatawithFilter.map(item => {
        item.products.map(iterator => {
          purchase.push(iterator);
          const final = [...purchase.reduce((r, o) => {
            const key = o.sku;
            const items = r.get(key) || Object.assign({}, o, {
              ratebydismod: 0,
              quantity: 0
            });
            items.ratebydismod += +o.ratebydismod
            items.quantity += +o.quantity
            return r.set(key, items);

          }, new Map).values()
          ];
          setItemwisepurchases(final)

          $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
              $(tableRef.current).DataTable({
                language: { search: '', searchPlaceholder: "Search..." },
                lengthMenu: [
                  [10, 1, 25, 50, 100, 200, 500, -1],
                  [10, 1, 25, 50, 100, 200, 500, 'All'],
                ],
              });
            }, 1000);
          })
        })
      }) : setItemwisepurchases([])
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.err(messages);
    }
  }

  const fileName = 'Item Wise Purchase Report';
  const getexcelDatas = async () => {
    let data = itemwisepurchases.map(t => ({
      'Item Code': t.sku, 'Item Name': t.prodname, 'Total Quantity': t.quantity, 'Total Amount': t.ratebydismod
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()
  }, [itemwisepurchases])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | ITEM WISE PURCHASE REPORT',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#purchasetable' });
    doc.save('Item Wise Purchase Report.pdf')
  }

  useEffect(() => {
    fetchPurchases();
  }, [])
  
  return (
    <Box >
      <Headtitle title={'Item Wise Purchase Report'} />
      { /* ************************************************** Header Content ****************************************************** */}
      <Typography sx={userStyle.HeaderText}>Item Wise Purchase</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchPurchases}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />

      { /* ******************************************************EXPORT Buttons****************************************************** */}

      <>
        <Box sx={userStyle.container} >
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvitemwisepurchasereport && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelitemwisepurchasereport && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printitemwisepurchasereport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfitemwisepurchasereport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid>
          <br />

          { /*  *******************************************Table Start  *******************************************p*/}
          <Box>
            <TableContainer component={Paper} >
              <Table id="purchasetable" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell >Item Name</StyledTableCell>
                    <StyledTableCell >Total Quantity</StyledTableCell>
                    <StyledTableCell>Total Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {itemwisepurchases.length > 0 &&
                    (itemwisepurchases.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell >{row.sku}</StyledTableCell>
                        <StyledTableCell >{row.prodname}</StyledTableCell>
                        <StyledTableCell >{row.quantity}</StyledTableCell>
                        <StyledTableCell >{row.ratebydismod}</StyledTableCell>
                      </StyledTableRow>
                    ))
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </>
      {/* *******************************************print layout **********************************************/}

      <>
        <Box sx={userStyle.printcls} >

          <TableContainer component={Paper} >
            <Table id="purchasetable" ref={componentRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Item Code</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Total Quantity</StyledTableCell>
                  <StyledTableCell>Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {itemwisepurchases.length > 0 &&
                  (itemwisepurchases.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.sku}</StyledTableCell>
                      <StyledTableCell >{row.prodname}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                      <StyledTableCell >{row.ratebydismod}</StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Box>
  );
}

function Itemwisepurchasereport() {
  return (
    <>
      <Box >
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Itemwisepurchasereportlist /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Itemwisepurchasereport;