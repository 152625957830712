import React, { useState, useContext } from 'react';
import { Box, Button, Grid, FormControl, OutlinedInput, InputLabel, Typography, Dialog, DialogContent, DialogActions, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AuthContext } from '../../../context/Appcontext';

function Unitcreate() {

  const [unitAdd, setUnitAdd] = useState({ unit: "", shortname: "" });
  const { auth } = useContext(AuthContext);

  //popup model
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState()
  const handleOpen = () => {
    setIsErrorOpen(true);
  };
  const handleClose = () => {
    setIsErrorOpen(false);
  };

  const backLPage = useNavigate();

  // store category data to db
  const sendRequest = async () => {

    try {
      let res = await axios.post(SERVICE.UNIT_CREATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        unit: String(unitAdd.unit),
        shortname: String(unitAdd.shortname)
      });
      setUnitAdd(res.data);
      backLPage('/product/unit/list');
    } catch (err) {
      const messages = err.response.data.message;
      setShowAlert(messages);
      handleOpen();
    }
  };

  const addCateSubmit = (e) => {
    e.preventDefault();
    if (unitAdd.unit == "") {
      setShowAlert("Please enter unit name");
      handleOpen();
    } else {
      sendRequest();
    }

  }

  return (
    <Box>
      <Headtitle title={'Add Units'} />
      <Typography sx={userStyle.HeaderText} variant='body2'>Add Unit</Typography>
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={3} sx={userStyle.textInput}>
          <Grid item md={8} sm={12} xs={12}>
            <InputLabel htmlFor="component-outlined">Unit<b style={{ color: "red" }}> *</b></InputLabel>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                id="component-outlined"
                value={unitAdd.unit}
                onChange={(e) => { setUnitAdd({ ...unitAdd, unit: e.target.value }) }}
                type="text"
                name="categoryname"
              />
            </FormControl>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <InputLabel htmlFor="component-outlined">Short Name</InputLabel>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                id="component-outlined"
                value={unitAdd.shortname}
                onChange={(e) => { setUnitAdd({ ...unitAdd, shortname: e.target.value }) }}
                type="text"
                name="categorycode"
              />
            </FormControl>
          </Grid>
        </Grid><br />
        <br />
        <Grid container sx={userStyle.gridcontainer}>
          <Grid sx={{ display: 'flex' }}>
            <Button sx={userStyle.buttonadd} type="submit" onClick={addCateSubmit}>SAVE</Button>
            <Link to="/product/unit/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
          </Grid>
        </Grid>
      </Box>
      {/* ALERT DIALOG */}
      <Dialog
        open={isErrorOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h6" >{showAlert}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
function Unitscreate() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Unitcreate /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Unitscreate;