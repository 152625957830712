import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Grid, InputLabel, FormControl, FormGroup, FormControlLabel, Checkbox, Box, OutlinedInput, Dialog, DialogContent, DialogActions } from '@mui/material';
import Navbar from '../../../../components/header/Navbar';
import Footer from '../../../../components/footer/Footer';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocationOnIconOutlined from '@mui/icons-material/LocationOnOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { useNavigate } from 'react-router-dom';
import Selects from "react-select";
import { userStyle, colourStyles } from '../../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Country, State, City } from "country-state-city";
import Select from 'react-select';
import Headtitle from '../../../../components/header/Headtitle';
import { SERVICE } from '../../../../services/Baseservice';
import { AuthContext } from '../../../../context/Appcontext';
import { SettingsPhone } from '@mui/icons-material';

function Custmasterretail() {

    const [customers, setCustomers] = useState();
    const [customerGroup, setCustomerGroup] = useState([]);
    const [setngs, setSetngs] = useState();
    const { auth } = useContext(AuthContext);

    //  Add Text Field
    const [customer, setCustomer] = useState({
        cusid: "", customergroup: "", name: "", address: "", area: "", mobileno: "", whatsapp: "", gstn: "", uin: "", aadhaar: "", phonecheck: false,
        panno: "", country: "", state: "", city: "", creditlimit: "", creditdays: 0, dlnos: "", ledgerbalance: 0, category: "Retail", activate: true,
    });

    // Country city state datas
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => {
        setIsErrorOpen(true);
    };
    const handleClose = () => {
        setIsErrorOpen(false);
    };

    // Customers
    const fetchCustomers = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomers(req.data.customers);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Category
    const fetchCustomerGroup = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_GROUP, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }

            });
            setCustomerGroup(
                req?.data?.cgroups?.map((d) => ({
                    ...d,
                    label: d.cusgroupname,
                    value: d.cusgroupname,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Autogenerate
    let newval = setngs ? setngs.customersku + "0001" : "CU0001";

    // Aadhar field
    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    // Phone Number length
    const handlePhoneone = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setCustomer({ ...customer, mobileno: num })
        }
    }
    const handleWhatsapp = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setCustomer({ ...customer, whatsapp: num })
        }
    }
    const handleAadhaar = (e) => {
        if (e.length > 12) {
            setShowAlert("AADHAAR number can't have more than 12 characters!")
            handleClickOpen();
            let num = e.slice(0, 12);
            setCustomer({ ...customer, aadhaar: num })
        }
    }
    const handlePan = (e) => {
        if (e.length > 10) {
            setShowAlert("Pan number can't have more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setCustomer({ ...customer, panno: num })
        }
    }
    const handleUin = (e) => {
        if (e.length > 15) {
            setShowAlert("UIN number can't have more than 15 characters!")
            handleClickOpen();
            let num = e.slice(0, 15);
            setCustomer({ ...customer, uin: num })
        }
    }
    const handleGstin = (e) => {
        if (e.length > 15) {
            setShowAlert("GSTIN number can't have more than 15 characters!")
            handleClickOpen();
            let num = e.slice(0, 15);
            setCustomer({ ...customer, gstn: num })
        }
    }

    const getPhoneNumber = () => {
        if (customer.phonecheck) {
            setCustomer({ ...customer, whatsapp: customer.mobileno })
        } else {
            setCustomer({ ...customer, whatsapp: "" })
        }
    }

    useEffect(
        () => {
            getPhoneNumber();
        }, [customer.phonecheck]
    )

    useEffect(() => {
        fetchCustomerGroup();
    }, [])

    useEffect(() => {
        fetchCustomers();
    }, [])

    const backPage = useNavigate();

    const addCustomer = async () => {
        try {
            let req = await axios.post(SERVICE.CUSTOMER_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                cusid: String(customer.cusid),
                customergroup: String(customer.customergroup),
                name: String(customer.name),
                address: String(customer.address),
                area: String(customer.area),
                mobileno: Number(customer.mobileno),
                whatsapp: Number(customer.whatsapp),
                gstn: String(customer.gstn),
                uin: String(customer.uin),
                aadhaar: Number(customer.aadhaar),
                panno: String(customer.panno),
                country: String(selectedCountry.name ? selectedCountry.name : ""),
                state: String(selectedState.name ? selectedState.name : ""),
                city: String(selectedCity.name ? selectedCity.name : ""),
                creditlimit: Number(customer.creditlimit),
                creditdays: Number(customer.creditdays),
                dlnos: String(customer.dlnos),
                ledgerbalance: Number(0),
                category: String(customer.category),
                phonecheck: Boolean(customer.phonecheck),
                activate: Boolean(customer.activate),
            });
            setCustomer(req.data)
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/contact/customer/retail/list');
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const anotherCustomer = async () => {
        try {
            let req = await axios.post(SERVICE.CUSTOMER_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                cusid: String(customer.cusid),
                customergroup: String(customer.customergroup),
                name: String(customer.name),
                address: String(customer.address),
                area: String(customer.area),
                mobileno: Number(customer.mobileno),
                whatsapp: Number(customer.whatsapp),
                gstn: String(customer.gstn),
                uin: String(customer.uin),
                aadhaar: Number(customer.aadhaar),
                panno: String(customer.panno),
                country: String(selectedCountry.name ? selectedCountry.name : ""),
                state: String(selectedState.name ? selectedCountry.name : ""),
                city: String(selectedCity.name ? selectedCountry.name : ""),
                creditlimit: Number(customer.creditlimit),
                creditdays: Number(customer.creditdays),
                dlnos: String(customer.dlnos),
                category: String(customer.category),
                phonecheck: Boolean(customer.phonecheck),
                activate: Boolean(customer.activate),
            });
            await fetchCustomers()
            setCustomer({
                cusid: "", customergroup: "", name: "", address: "", area: "", mobileno: "", whatsapp: "", gstn: "", uin: "", aadhaar: "",
                panno: "", country: "", state: "", city: "", creditlimit: "", creditdays: "", dlnos: "", phonecheck: false
            });
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (customer.name == "") {
            setShowAlert("Please Enter Customer Name!")
            handleClickOpen();
        }
        else if (customer.mobileno == "") {
            setShowAlert("Please Enter Mobile Number!")
            handleClickOpen();
        }
        else if (customer.whatsapp == "") {
            setShowAlert("Please Enter Whatsapp Number!")
            handleClickOpen();
        }
        else {
            addCustomer();
        }
    }

    const handleAnotherSubmit = () => {
        if (customer.name == "") {
            setShowAlert("Please Enter Customer Name!")
            handleClickOpen();
        }
        else if (customer.mobileno == "") {
            setShowAlert("Please Enter Mobile Number!")
            handleClickOpen();
        }
        else if (customer.whatsapp == "") {
            setShowAlert("Please Enter Whatsapp Number!")
            handleClickOpen();
        }
        else {
            anotherCustomer();
        }
    }

    const handleBack = () => {
        backPage('/contact/customer/retail/list')
    }

    const handleValidationName = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, name: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, name: value })
        }
    }

    return (
        <Box>
            <Headtitle title={'Customer Master - Retail'} />
            <Typography sx={userStyle.HeaderText}>Add New Customer - Retail</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ justifyContent: 'center' }}>Customer Details</Typography>
                        </Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Fields</Typography>
                        </Grid>
                        <Grid item md={8} sm={6} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>1.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Customer ID</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Auto Generate</InputLabel>
                            {customers && (
                                customers.map(
                                    () => {
                                        let strings = setngs ? setngs.customersku : 'CU';
                                        let refNo = customers[customers.length - 1].cusid;
                                        let digits = (customers.length + 1).toString();
                                        const stringLength = refNo.length;
                                        let lastChar = refNo.charAt(stringLength - 1);
                                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                                        let lastBeforeChar = refNo.slice(-2);
                                        let lastThreeChar = refNo.slice(-3);
                                        let lastDigit = refNo.slice(-4);
                                        let refNOINC = parseInt(lastChar) + 1
                                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                                        let refLstThree = parseInt(lastThreeChar) + 1;
                                        let refLstDigit = parseInt(lastDigit) + 1;
                                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                            refNOINC = ("000" + refNOINC).substr(-4);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                            refNOINC = ("00" + refLstTwo).substr(-4);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                                            refNOINC = ("0" + refLstThree).substr(-4);
                                            newval = strings + refNOINC;
                                        } else {
                                            refNOINC = (refLstDigit).substr(-4);
                                            newval = strings + refNOINC;
                                        }
                                    }))}
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><ContactPageOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={newval}
                                        type="text"
                                        name="autogenerate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>2.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Customer Group</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    styles={colourStyles}
                                    options={customerGroup}
                                    onChange={(e) => { setCustomer({ ...customer, customergroup: e.cusgroupname }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>3.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: "flex" }}>Name <Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.name}
                                        onChange={(e) => { setCustomer({ ...customer, name: e.target.value, cusid: newval, country: "", state: "", city: "" }); handleValidationName(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>4.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Address</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.address}
                                    onChange={(e) => { setCustomer({ ...customer, address: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>5.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Area</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.area}
                                    onChange={(e) => { setCustomer({ ...customer, area: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>6.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: "flex" }}>Mobile No<Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LocalPhoneOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.mobileno}
                                        onChange={(e) => { setCustomer({ ...customer, mobileno: e.target.value }); handlePhoneone(e.target.value) }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={customer.phonecheck} onChange={(e) => setCustomer({ ...customer, phonecheck: !customer.phonecheck })} />} label="Same as whatsapp number" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>7.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: "flex" }}>WhatsApp No<Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><PhoneAndroidOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.whatsapp}
                                        onChange={(e) => { setCustomer({ ...customer, whatsapp: e.target.value }); handleWhatsapp(e.target.value); }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>8.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>GSTIN No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.gstn}
                                    onChange={(e) => { setCustomer({ ...customer, gstn: e.target.value }); handleGstin(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>9.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>UIN No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.uin}
                                    onChange={(e) => { setCustomer({ ...customer, uin: e.target.value }); handleUin(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>10.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>AADHAR No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={customer.aadhaar}
                                    onChange={(e) => { setCustomer({ ...customer, aadhaar: e.target.value }); handleAadhaar(e.target.value); }}
                                    type="number"
                                    sx={userStyle.input}
                                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>11.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>PAN No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={customer.panno}
                                    onChange={(e) => { setCustomer({ ...customer, panno: e.target.value }); handlePan(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>12.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Country</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LanguageOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        options={Country.getAllCountries()}
                                        getOptionLabel={(options) => {
                                            return options["name"];
                                        }}
                                        getOptionValue={(options) => {
                                            return options["name"];
                                        }}
                                        value={selectedCountry}
                                        styles={colourStyles}
                                        onChange={(item) => {
                                            setSelectedCountry(item);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>13.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>State</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                        getOptionLabel={(options) => {
                                            return options["name"];
                                        }}
                                        getOptionValue={(options) => {
                                            return options["name"];
                                        }}
                                        value={selectedState}
                                        styles={colourStyles}
                                        onChange={(item) => {
                                            setSelectedState(item);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>14.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>City</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        options={City.getCitiesOfState(
                                            selectedState?.countryCode,
                                            selectedState?.isoCode
                                        )}
                                        getOptionLabel={(options) => {
                                            return options["name"];
                                        }}
                                        getOptionValue={(options) => {
                                            return options["name"];
                                        }}
                                        value={selectedCity}
                                        styles={colourStyles}
                                        onChange={(item) => {
                                            setSelectedCity(item);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>15.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Credit Limit</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.creditlimit}
                                    onChange={(e) => { setCustomer({ ...customer, creditlimit: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>16.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Credit Days</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.creditdays}
                                    onChange={(e) => { setCustomer({ ...customer, creditdays: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>17.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>DL NOS</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={customer.dlnos}
                                    onChange={(e) => { setCustomer({ ...customer, dlnos: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Button sx={userStyle.buttoncancel} onClick={handleBack}>CANCEL</Button>
                                <Button sx={userStyle.buttonadd} onClick={() => handleAnotherSubmit()} >SAVE AND ADD ANOTHER</Button>
                                <Button sx={userStyle.buttonadd} type="submit" >SAVE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Custmasterretaillist() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Custmasterretail /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Custmasterretaillist;