import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle,colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import Selects from 'react-select';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from '../../../src/services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import moment from "moment";

function Customerwise() {

  //  Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  const [customers, setCustomers] = useState([]);
  const [customerreportestimate, setCustomerReportEstimate] = useState([]);
  const [exceldata, setExceldata] = useState([]);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  //  Fetch Estimate Data
  const fetchEstimate = async () => {
    try {
      let request = await axios.get(SERVICE.ESTIMATE,{
        
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let estimatefetch = request.data.estimate.filter((data) => {
        let splittedMonth = data.date;
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
        } 
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
      });
      setCustomerReportEstimate(estimatefetch)

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // get all customer data from select option for customer
  const fetchCustomers = async () => {
    try {
      let customers = await axios.get(SERVICE.CUSTOMER,{
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setCustomers(
        customers?.data?.customers?.map((d) => ({
          ...d,
          label: d.name,
          value: d.name
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const fetchSelect = async (e) => {
    try {

      let request = await axios.get(SERVICE.ESTIMATE,{
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let selectestimate = request?.data?.estimate?.filter((data) => {
        if (data.customer == e.name) {
          return data
        }
      })
      setCustomerReportEstimate(selectestimate);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Export Excel
  const fileName = 'Customer Wise Estimate Report'

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = customerreportestimate.map(t => ({
      "Bill No ": t.referenceno, "Bill Date": t.date, "Customer Name": t.customer, "Total Items": t.totalitems, "Total Qty": t.totalproducts, "Sub Total": t.goods.map((value) => value.subtotal).reduce((val , dat)=> val + dat),
      Discount: t.gdiscountvalue, Total: t.aftergranddisctotal 
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  }, [customerreportestimate])

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#usertable' })
    doc.save('Customer Wise Estimate Report.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CUSTOMER WISE ESTIMATE REPORT',
    pageStyle: 'print'
  });

  useEffect(() => {
    fetchCustomers();
  }, [])

  return (
    <Box>
      <Headtitle title={'Customer Wise Estimate Report'} />
      <Typography sx={userStyle.HeaderText}>Customer Wise Estimate Report</Typography>
      <Box sx={userStyle.filtercontent} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={3} md={3}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1, marginLeft: "0.5em" }}> From</Typography>
              </Grid>
              <Grid item lg={9} md={9}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' sx={{ marginLeft: "1em" }} onClick={fetchEstimate} >Generate</Button>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormControl size="small" fullWidth>
              <Selects
                styles={colourStyles}
                  options={customers}
                  onChange={fetchSelect}
                  placeholder="Customer Name"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        { /* ******************************************************EXPORT Buttons****************************************************** */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvcustomerwiseestimatereport && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelcustomerwiseestimatereport && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printcustomerwiseestimatereport && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfcustomerwiseestimatereport && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill NO</StyledTableCell>
                  <StyledTableCell >Bill Date</StyledTableCell>
                  <StyledTableCell >Customer Name</StyledTableCell>
                  <StyledTableCell >Total Items</StyledTableCell>
                  <StyledTableCell >Total Qty</StyledTableCell>
                  <StyledTableCell >Sub Total</StyledTableCell>
                  <StyledTableCell >Discount</StyledTableCell>
                  <StyledTableCell >Total</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {customerreportestimate &&
                  (customerreportestimate?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row?.referenceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.customer}</StyledTableCell>
                      <StyledTableCell >{row.totalitems}</StyledTableCell>
                      <StyledTableCell >{row.totalproducts}</StyledTableCell>
                      <StyledTableCell >{row.goods.map((value) => value.subtotal).reduce((val , dat)=> val + dat)}</StyledTableCell>
                      <StyledTableCell >{row.gdiscountvalue}</StyledTableCell>
                      <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Bill NO</StyledTableCell>
              <StyledTableCell >Bill Date</StyledTableCell>
              <StyledTableCell >Customer Name</StyledTableCell>
              <StyledTableCell >Total Items</StyledTableCell>
              <StyledTableCell >Total Qty</StyledTableCell>
              <StyledTableCell >Sub Total</StyledTableCell>
              <StyledTableCell >Discount</StyledTableCell>
              <StyledTableCell >Total</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {customerreportestimate &&
              (customerreportestimate.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell >{row.referenceno}</StyledTableCell>
                  <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                  <StyledTableCell >{row.customer}</StyledTableCell>
                  <StyledTableCell >{row.totalitems}</StyledTableCell>
                  <StyledTableCell >{row.totalproducts}</StyledTableCell>
                  <StyledTableCell >{row.goods.map((value) => value.subtotal).reduce((val , dat)=> val + dat)}</StyledTableCell>
                  <StyledTableCell >{row.gdiscountvalue}</StyledTableCell>
                  <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Customerwiseestimatereport() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Customerwise /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Customerwiseestimatereport;