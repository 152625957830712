import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, FormControl, Grid, Paper, Table, OutlinedInput, TableBody, TableHead, TableContainer, Button, DialogActions, DialogContent, Dialog, DialogContentText, DialogTitle } from '@mui/material';
import { userStyle, colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import Selects from "react-select";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function Accountandgroupcreation() {

    const [exceldata, setExceldata] = useState([]);
    const [setngs, setSetngs] = useState();
    const [accdelete, setAccDelete] = useState({});
    const [popmodalOpen, setPopModalOpen] = useState(false);
    const [acEditModal, setAcEditModal] = useState(false);
    const [warn, setWarn] = useState(false);
    const [groupDeleteopen, setGroupDelete] = useState(false);
    const [grpEditModal, setgrpEditModal] = useState(false);
    const [acExcel, setAcExcel] = useState([]);
    const [grpcreation, setGroupCreation] = useState([]);
    const [showAlert, setShowAlert] = useState();
    const [grpdelete, setGrpDelete] = useState({});
    const [groups, setGroups] = useState([]);
    const [accCreation, setAccCreation] = useState([]);
    const { auth } = useContext(AuthContext);
    const [editAccreation, setEditAcCreation] = useState({
        autoid: "", accountname: "", accountgroup: ""
    });
    const [groupCreate, setGroupCreate] = useState({
        groupautoid: "", groupname: "",
    });
    const [accountCreation, setAccountCreation] = useState({
        autoid: "", accountname: "", accountgroup: ""
    });
    const [editGrpreation, setGrpCreation] = useState({
        groupname: ""
    });

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    let newval = setngs ? setngs.groupsku + "0001" : "GR0001";
    let newref = setngs ? setngs.accountsku + "0001" : "AC0001";

    // Delete popup
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => { setIsDeleteOpen(true); };
    const handleClose = () => { setIsDeleteOpen(false); };

    //account creation delete
    const [accountDeleteopen, setAccountDelete] = useState(false)
    const handleAcopen = () => { setAccountDelete(true) };
    const handleAClose = () => { setAccountDelete(false) };

    //pop to Account creation Edit
    const handleacEditOpen = () => { setAcEditModal(true); };
    const handleacEditClose = () => { setAcEditModal(false); };
    //pop to Group creation Edit
    const handleacGrpOpen = () => { setgrpEditModal(true); };
    const handleacGrpClose = () => { setgrpEditModal(false); setWarn(false) };

    //  Modal
    const handleSetModalOpen = () => {
        setPopModalOpen(true);
        setAccountCreation({ ...accCreation, accountname: "", accountgroup: "" })
    };
    const handleSetModalClose = () => {
        setPopModalOpen(false);
        setWarn(false)
        setAccountCreation({ ...accCreation, accountname: "", accountgroup: "" })
    };

    const handlegropen = () => { setGroupDelete(true) };
    const handlegrclose = () => { setGroupDelete(false) };

    //   New group creation
    const addGroup = async () => {
        try {
            let req = await axios.post(SERVICE.GROUP_CREATION_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                groupautoid: String(newval),
                groupname: String(groupCreate.groupname)
            });
            setGroupCreate(req.data);
            if (toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            })) {
                setGroupCreate({ groupname: "" })
                handleModalClose();
            }
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
        }
    };

    //  Modal
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalOpen = () => {
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setWarn(false)
        setGroupCreate({ groupname: "" })
    };

    // SUBMIT FOR ADD GROUP
    const handleSubmit = () => {
        if (groupCreate.groupname == "") {
            setWarn(true)
        } else {
            addGroup();
        };
    };

    // new account creation
    const fetchAccCreation = async () => {
        try {
            let req = await axios.get(SERVICE.ACCOUNT_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setAccCreation(req.data.accountmasters)
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        };
    };

    // get all customer data from select option for customer
    const fetchGroups = async () => {
        try {
            let request = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setGroups(
                request?.data?.groupmasters?.map((d) => ({
                    ...d,
                    label: d.groupname,
                    value: d.groupname,

                }))
            );

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //get settings
    const fetchSettings = async () => {
        try {
            var response = await axios.get(`${SERVICE.UPDATE_SETTINGS}`,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.APIToken}`
                    },
                });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages)
        }
    }

    // GROUP_CREATION
    const fetchbankMasters = async () => {
        try {
            let req = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setGroupCreation(req.data.groupmasters);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // GROUP_CREATION
    const fetchAccount = async () => {
        try {
            let req = await axios.get(SERVICE.ACCOUNT_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAccCreation(req.data.accountmasters);

        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //   New group creation
    const addGAccCreation = async () => {
        try {
            let req = await axios.post(SERVICE.ACCOUNT_CREATION_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                autoid: String(newref),
                accountname: String(accountCreation.accountname),
                accountgroup: String(accountCreation.accountgroup),
            });
            setAccountCreation(req.data);
            if (toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            })) {
                setAccountCreation({ accountname: "", accountgroup: "" })
            }
            handleSetModalClose();
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
        }
    };

    // GET SINGLE ACCOUNT
    const AccSingle = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.ACCOUNT_CREATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setEditAcCreation(res.data.saccountmaster);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //   New group creation
    const addGAccCreationUpdate = async () => {
        try {
            let req = await axios.put(`${SERVICE.ACCOUNT_CREATION_SINGLE}/${editAccreation._id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                autoid: String(editAccreation.autoid),
                accountname: String(editAccreation.accountname),
                accountgroup: String(editAccreation.accountgroup),
            });
            setEditAcCreation(req.data);
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            handleacEditClose();
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);

        }
    };

    // GET SINGLE ID FOR GROUP
    const EditSingle = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.GROUUP_CREATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setGrpCreation(res.data.sgroupmaster);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //   New group creation
    const addGroupEdit = async () => {
        try {
            let req = await axios.put(`${SERVICE.GROUUP_CREATION_SINGLE}/${editGrpreation._id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                groupname: String(editGrpreation.groupname),
            });
            setGrpCreation(req.data);
            if (toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            })) {
            }
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);

        }
    };

    // GROUP EDIT SUBMIT
    const handleEditGrpSubmit = (e) => {
        if (editGrpreation.groupname == !e.target.value) {
            setWarn(true)
        } else {
            addGroupEdit();
        }
    }

    // SUBMIT FOR ACCOUNT CREATION
    const handleAccEditSubmit = () => {
        if (editAccreation.accountname == "") {
            setWarn(true)
        } else if (editAccreation.accountgroup == "") {

            setWarn(true)
        } else {
            addGAccCreationUpdate()
        }
    }

    const handleValidationNameGroupEdit = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setGrpCreation({ ...editGrpreation, groupname: value })

        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setGrpCreation({ ...editGrpreation, groupname: value })
        }
    }

    const handleValidationNameGroup = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setGroupCreate({ ...groupCreate, groupname: value })

        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setGroupCreate({ ...groupCreate, groupname: value })
        }
    }

    const handleValidationNames = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAccCreation({ ...accCreation, accountname: value })

        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAccCreation({ ...accCreation, accountname: value })
        }
    }


    const handleValidationName = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setEditAcCreation({ ...editAccreation, accountname: value })

        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setEditAcCreation({ ...editAccreation, accountname: value })
        }
    }
    //handle submit for account creation
    const handleSubmitAccount = () => {
        if (accountCreation.accountname == "") {
            setWarn(true)
        } else if (accountCreation.accountgroup == "") {
            setWarn(true)

        } else {
            addGAccCreation();
        }
    }

    // Delete Model Pop up 
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.ACCOUNT_CREATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAccDelete(res.data.saccountmaster);

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    let listid = accdelete._id;
    //  DELETE FOR GROUP CREATION
    const deleteAccount = async () => {
        try {
            await axios.delete(`${SERVICE.ACCOUNT_CREATION_SINGLE}/${listid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //    GET SINGLE GROUP CREATION 
    const rowDataGrp = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.GROUUP_CREATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setGrpDelete(res.data.sgroupmaster);

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //  SINGLE GROUP ID 
    let grpid = grpdelete._id;
    const groupAccount = async () => {
        try {
            await axios.delete(`${SERVICE.GROUUP_CREATION_SINGLE}/${grpid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchbankMasters()
    }, [groupAccount])

    // Excel
    const fileName1 = 'Account Creation'
    //  get particular columns for export excel
    const getexcelDatas = async () => {
        let data = accCreation.map(t => ({
            "Referenceno": t.autoid, "Account Name": t.accountname, "Account Group": t.accountgroup
        }));
        setExceldata(data);
    }

    useEffect(() => {
        getexcelDatas();
    }, [exceldata])

    const fileName2 = 'Group Creation '
    //  get particular columns for export excel
    const getexcel = async () => {
        let data = grpcreation.map(t => ({
            "Referenceno": t.groupautoid, "Account Group": t.groupname
        }));
        setAcExcel(data);
    }

    useEffect(() => {
        getexcel()
    }, [acExcel])

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | ACCOUNT CREATION',
        pageStyle: 'print'
    });

    // Print
    const componentRefone = useRef();
    const handleprintOne = useReactToPrint({
        content: () => componentRefone.current,
        documentTitle: 'HIPOS | GROUP CREATION',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#accouncreatepdf' })
        doc.save('Account Creation.pdf')
    }

    //  PDF
    const downloadPdfgrp = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#groupcreatepdf' })
        doc.save('Group Creation.pdf')
    }

    useEffect(
        () => {
            fetchAccCreation();
            fetchbankMasters();
            fetchAccount();
            fetchGroups();
            fetchSettings();
        }, [accCreation]
    )

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef1 = useRef(null);
    const tableRefone2 = useRef(null);

    useEffect(
        () => {
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefone2.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });

                }, 1000);
            });
        }, [(tableRefone2.current)]

    )

    useEffect(
        () => {
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef1.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });

                }, 1000);
            });
        }, [(tableRef1.current)]
    )

    return (
        <Box sx={userStyle.filtercontent} >
            <Headtitle title={'Account and Group Creation'} />
            <Grid container sx={{ display: 'flex' }} spacing={1}>
                <Grid item lg={6} md={6}>

                    { /* --------------------------- Group Creation --------------------------- Start */}
                    <Typography component={'span'} variant='body2' sx={userStyle.HeaderText}>Account Creation</Typography>
                    <>
                        <Box sx={userStyle.container} >
                            { /* ****** Header Buttons ****** */}
                            <Grid container sx={{ justifyContent: "center" }} >
                                <Grid>
                                    {isUserRoleCompare[0].excelaccountcreation && (
                                        <>
                                            <ExportCSV csvData={exceldata} fileName={fileName1} />
                                        </>
                                    )}
                                    {isUserRoleCompare[0].csvaccountcreation && (
                                        <>
                                            <ExportXL csvData={exceldata} fileName={fileName1} />
                                        </>
                                    )}
                                    {isUserRoleCompare[0].printaccountcreation && (
                                        <>
                                            <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                        </>
                                    )}
                                    {isUserRoleCompare[0].pdfaccountcreation && (
                                        <>
                                            <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                        </>
                                    )}
                                </Grid>
                            </Grid><br />
                            <Grid container justifyContent={"flex-end"} >
                                <Grid item md={8}></Grid>
                                <Grid item md={2}>
                                    {isUserRoleCompare[0].aaccountcreation && (
                                        <>
                                            <Button sx={userStyle.buttonadd} onClick={handleSetModalOpen}>Add</Button>
                                        </>
                                    )}
                                </Grid>
                            </Grid><br />
                            { /* ****** Table start ****** */}
                            <TableContainer component={Paper} >
                                <Table aria-label="simple table" id="tableRef1" ref={tableRef1}>
                                    <TableHead sx={{ fontWeight: "600" }} >
                                        <StyledTableRow >
                                            <StyledTableCell>Action</StyledTableCell>
                                            <StyledTableCell>Reference No</StyledTableCell>
                                            <StyledTableCell>Account Name</StyledTableCell>
                                            <StyledTableCell>Account Group</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {accCreation &&
                                            accCreation.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="center">
                                                            <Grid sx={{ display: 'flex' }}>
                                                                {isUserRoleCompare[0].eaccountcreation && (<Button sx={userStyle.buttonedit} onClick={(e) => { rowData(row._id); handleacEditOpen(); AccSingle(row._id) }}><EditOutlinedIcon style={{ fontSize: 'small' }} /></Button>)}
                                                                {isUserRoleCompare[0].daccountcreation && (<Button onClick={(e) => { handleAcopen(); rowData(row._id) }} sx={userStyle.buttondelete}><DeleteOutlineOutlinedIcon style={{ fontSize: 'small' }} /></Button>)}
                                                            </Grid>

                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.autoid}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.accountname}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            { /* ****** Table End ****** */}
                        </Box>
                    </>
                    { /* ------------------------- Print ------------------------------------- */}
                    <Box sx={userStyle.printcls} >
                        <>
                            <Box  >
                                <TableContainer component={Paper}  >
                                    <Table aria-label="simple table" ref={componentRef} id="accouncreatepdf">
                                        <TableHead sx={{ fontWeight: "600" }} >
                                            <StyledTableRow>
                                                <StyledTableCell>Reference No</StyledTableCell>
                                                <StyledTableCell>Account Name</StyledTableCell>
                                                <StyledTableCell>Account Group</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {accCreation &&
                                                accCreation.map((row, index) => {
                                                    return (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell align="left">{row.autoid}</StyledTableCell>
                                                            <StyledTableCell align="left">{row.accountname}</StyledTableCell>
                                                            <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </>
                    </Box>
                </Grid>
                <Grid item lg={6} md={6}>
                    { /* --------------------------- Group Creation --------------------------- Start */}
                    <Typography component={'span'} variant='body2' sx={userStyle.HeaderText}>Group Creation</Typography>
                    <>
                        <Box sx={userStyle.container} >
                            <Grid container sx={{ justifyContent: "center" }} >
                                <Grid>
                                    {isUserRoleCompare[0].excelgroupcreation && (
                                        <>
                                            <ExportCSV csvData={acExcel} fileName={fileName2} />
                                        </>
                                    )}
                                    {isUserRoleCompare[0].csvgroupcreation && (
                                        <>
                                            <ExportXL csvData={acExcel} fileName={fileName2} />
                                        </>
                                    )}
                                    {isUserRoleCompare[0].printgroupcreation && (
                                        <>
                                            <Button sx={userStyle.buttongrp} onClick={handleprintOne}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                        </>
                                    )}
                                    {isUserRoleCompare[0].pdfgroupcreation && (
                                        <>
                                            <Button sx={userStyle.buttongrp} onClick={() => downloadPdfgrp()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                        </>
                                    )}
                                </Grid>
                            </Grid><br />
                            <Grid container justifyContent={"flex-end"} >
                                <Grid item md={8}></Grid>
                                <Grid item md={2}>
                                    {isUserRoleCompare[0].agroupcreation && (
                                        <>
                                            <Button sx={userStyle.buttonadd} onClick={(e) => { setModalOpen(true) }}>Add</Button>
                                        </>
                                    )}
                                </Grid>
                            </Grid><br />
                            { /* ****** Table start ****** */}
                            <TableContainer component={Paper} >
                                <Table aria-label="simple table" id="tableRefone2" ref={tableRefone2}>
                                    <TableHead sx={{ fontWeight: "600" }} >
                                        <StyledTableRow >
                                            <StyledTableCell>Action</StyledTableCell>
                                            <StyledTableCell>Reference No</StyledTableCell>
                                            <StyledTableCell>Account Group</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {grpcreation &&
                                            grpcreation.map((row, index) => {
                                                return (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="center">
                                                            <Grid sx={{ display: 'flex' }}>
                                                                {isUserRoleCompare[0].egroupcreation && (<Button onClick={(e) => { handleacGrpOpen(); EditSingle(row._id) }} sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: 'small' }} /></Button>)}
                                                                {isUserRoleCompare[0].dgroupcreation && (<Button onClick={(e) => { handlegropen(); rowDataGrp(row._id) }} sx={userStyle.buttondelete}><DeleteOutlineOutlinedIcon style={{ fontSize: 'small' }} /></Button>)}
                                                            </Grid>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.groupautoid}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.groupname}</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            { /* ****** Table End ****** */}
                        </Box>
                    </>
                    { /* ------------------------- Print ------------------------------------- */}
                    <Box sx={userStyle.printcls}>
                        <>
                            <Box  >
                                <TableContainer component={Paper} >
                                    <Table sx={{ fontWeight: "600" }} aria-label="simple table" ref={componentRefone} id="groupcreatepdf">
                                        <TableHead sx={{ fontWeight: "600" }} >
                                            <Typography component={'span'} variant='body2' >Group Creation Print</Typography>
                                            <StyledTableRow >
                                                <StyledTableCell>Reference No</StyledTableCell>
                                                <StyledTableCell>Account Group</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {grpcreation &&
                                                grpcreation.map((row, index) => {
                                                    return (
                                                        <StyledTableRow key={index}>
                                                            <StyledTableCell align="left">{row.groupautoid}</StyledTableCell>
                                                            <StyledTableCell align="left">{row.groupname}</StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </>
                    </Box>
                </Grid>
            </Grid>

            {/*.............add pop up for new group creation................... */}
            <Box>
                <Dialog
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title"> Add Group Creation</DialogTitle>
                    <DialogContent  >
                        <DialogContentText id="alert-dialog-description">
                            {warn ? <p style={{ color: "red", fontWeight: "bolder" }}>please enter group name !</p> : null}
                            <p style={{ color: "red" }}>{showAlert}</p>
                            <Box sx={userStyle.popupcontainer} >
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3}>
                                        {grpcreation && (
                                            grpcreation?.map(
                                                () => {
                                                    let strings = setngs.groupsku ? setngs.groupsku : "GR";
                                                    let refNo = grpcreation[grpcreation.length - 1].groupautoid;
                                                    let digits = (grpcreation.length + 1).toString();
                                                    const stringLength = refNo.length;
                                                    let lastChar = refNo.charAt(stringLength - 1);
                                                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                                                    let lastBeforeChar = refNo.slice(-2);
                                                    let lastThreeChar = refNo.slice(-3);
                                                    let lastDigit = refNo.slice(-4);
                                                    let refNOINC = parseInt(lastChar) + 1
                                                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                                                    let refLstThree = parseInt(lastThreeChar) + 1;
                                                    let refLstDigit = parseInt(lastDigit) + 1;
                                                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                                        refNOINC = ("000" + refNOINC);
                                                        newval = strings + refNOINC;
                                                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                                        refNOINC = ("00" + refLstTwo);
                                                        newval = strings + refNOINC;
                                                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                                                        refNOINC = ("0" + refLstThree);
                                                        newval = strings + refNOINC;
                                                    } else {
                                                        refNOINC = (refLstDigit);
                                                        newval = strings + refNOINC;
                                                    }
                                                }))}
                                        <Typography component={'span'} variant='body2'>Reference No </Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                sx={userStyle.input}
                                                value={newval}
                                            />
                                        </FormControl>
                                    </Grid><br />
                                    <Grid item lg={3} md={3}>
                                        <Typography component={'span'} variant='body2'>Account Group <b style={{ color: "red" }}> *</b></Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                sx={userStyle.input}
                                                value={groupCreate.groupname}
                                                onChange={(e) => { setGroupCreate({ ...groupCreate, groupname: e.target.value, }); handleValidationNameGroup(e) }}

                                            />
                                        </FormControl>
                                    </Grid><br />
                                </Grid>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" disableRipple onClick={(e) => { handleSubmit() }}>SAVE</Button>
                        <Button variant='contained' color='error' onClick={handleModalClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/*.............edit pop up for group creation................... */}

            <Box >
                <Dialog
                    open={grpEditModal}
                    onClose={handleacGrpClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title"> Edit Group Creation</DialogTitle>
                    <DialogContent sx={userStyle.filtercontainer}>
                        <DialogContentText id="alert-dialog-description">
                            {warn ? <p style={{ color: "red", fontWeight: "bolder" }}>please enter group name !</p> : null}

                            {/* <form onSubmit={handleSubmit}> */}
                            <Box sx={userStyle.popupcontainer}>
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3}>

                                        <Typography component={'span'} variant='body2'>Reference No</Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>

                                        <FormControl size="small" fullWidth >

                                            <OutlinedInput
                                                sx={userStyle.input}
                                                value={editGrpreation.groupautoid}
                                            />
                                        </FormControl>
                                    </Grid><br />

                                    <Grid item lg={3} md={3}>
                                        <Typography component={'span'} variant='body2'>Account Group  <b style={{ color: "red" }}> *</b></Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                sx={userStyle.input}
                                                value={editGrpreation.groupname}
                                                onChange={(e) => { setGrpCreation({ ...editGrpreation, groupname: e.target.value }); handleValidationNameGroupEdit(editAccreation) }}

                                            />
                                        </FormControl>
                                    </Grid><br />
                                    <Grid item lg={3} md={3}> </Grid>
                                    <Grid item lg={9} md={9}> </Grid>
                                </Grid>
                            </Box>
                            {/* </form> */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" disableRipple onClick={(e) => { handleEditGrpSubmit(e); handleacGrpClose() }}>UPDATE</Button>
                        <Button variant='contained' color='error' onClick={handleacGrpClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/*.............add pop up for new account creation................... */}

            <Box>
                <Dialog PaperProps={{ style: { overflowY: 'visible' } }}
                    open={popmodalOpen}
                    onClose={handleSetModalClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title"> Add Account Creation</DialogTitle>
                    <DialogContent style={{ overflowY: 'visible' }}>
                        <DialogContentText id="alert-dialog-description">
                            {warn ? <p style={{ color: "red", fontWeight: "bolder" }}>please fill all fields</p> : null}
                            <Box sx={userStyle.popupcontainer} >
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3}>
                                        {accCreation && (
                                            accCreation?.map(
                                                () => {
                                                    let strings = setngs.accountsku ? setngs.accountsku : "AC";
                                                    let refNo = accCreation[accCreation.length - 1].autoid;
                                                    let digits = (accCreation.length + 1).toString();
                                                    const stringLength = refNo.length;
                                                    let lastChar = refNo.charAt(stringLength - 1);
                                                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                                                    let lastBeforeChar = refNo.slice(-2);
                                                    let lastThreeChar = refNo.slice(-3);
                                                    let lastDigit = refNo.slice(-4);
                                                    let refNOINC = parseInt(lastChar) + 1
                                                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                                                    let refLstThree = parseInt(lastThreeChar) + 1;
                                                    let refLstDigit = parseInt(lastDigit) + 1;
                                                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                                        refNOINC = ("000" + refNOINC);
                                                        newref = strings + refNOINC;
                                                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                                        refNOINC = ("00" + refLstTwo);
                                                        newref = strings + refNOINC;
                                                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                                                        refNOINC = ("0" + refLstThree);
                                                        newref = strings + refNOINC;
                                                    } else {
                                                        refNOINC = (refLstDigit);
                                                        newref = strings + refNOINC;
                                                    }
                                                }))}
                                        <Typography component={'span'} variant='body2'>Reference No</Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                sx={userStyle.input}
                                                value={newref}
                                            />
                                        </FormControl>
                                    </Grid><br />
                                    <Grid item lg={3} md={3}>
                                        <Typography component={'span'} variant='body2'>Account Name <b style={{ color: "red" }}> *</b></Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                style={{ colourStyles }}
                                                value={accountCreation.accountname}
                                                onChange={(e) => { setAccountCreation({ ...accountCreation, accountname: e.target.value }); handleValidationNames(e) }} />
                                        </FormControl>
                                    </Grid><br />
                                    <Grid item lg={3} md={3}>
                                        <Typography component={'span'} variant='body2'>Group <b style={{ color: "red" }}> *</b></Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth>
                                            <Selects
                                                options={groups}
                                                onChange={(e) => { setAccountCreation({ ...accountCreation, accountgroup: e.value }) }}
                                                styles={colourStyles}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* </form> */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" disableRipple onClick={(e) => { handleSubmitAccount(e) }}>SAVE</Button>
                        <Button variant='contained' color='error' onClick={handleSetModalClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/*.............edit pop up for  account creation................... */}
            <Box>
                <Dialog PaperProps={{ style: { overflowY: 'visible' } }}
                    open={acEditModal}
                    onClose={handleacEditClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title"> Edit Account Creation</DialogTitle>
                    <DialogContent style={{ overflowY: 'visible' }}>
                        <DialogContentText id="alert-dialog-description">
                            {warn ? <p style={{ color: "red", fontWeight: "bolder" }}>please fill all fields !</p> : null}
                            <Box sx={userStyle.popupcontainer}>
                                <Grid container spacing={1}>
                                    <Grid item lg={3} md={3}>
                                        <Typography component={'span'} variant='body2'>Reference No</Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                sx={userStyle.input}
                                                value={editAccreation.autoid}
                                            />
                                        </FormControl>
                                    </Grid><br />
                                    <Grid item lg={3} md={3}>
                                        <Typography component={'span'} variant='body2'>Account Name <b style={{ color: "red" }}> *</b></Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                sx={userStyle.input}
                                                value={editAccreation.accountname}
                                                onChange={(e) => { setEditAcCreation({ ...editAccreation, accountname: e.target.value }); handleValidationName(e) }}
                                            />
                                        </FormControl>
                                    </Grid><br />
                                    <Grid item lg={3} md={3}>
                                        <Typography component={'span'} variant='body2'>Group <b style={{ color: "red" }}> *</b></Typography>
                                    </Grid>
                                    <Grid item lg={9} md={9}>
                                        <FormControl size="small" fullWidth>
                                            <Selects
                                                styles={colourStyles}
                                                value={{ value: editAccreation.accountgroup, label: editAccreation.accountgroup }}
                                                options={groups}
                                                onChange={(e) => { setEditAcCreation({ ...editAccreation, accountgroup: e.value }) }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            {/* </form> */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" disableRipple onClick={handleAccEditSubmit}  >Update</Button>
                        <Button variant='contained' color='error' onClick={handleacEditClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* ALERT DIALOG */}
            <Dialog
                open={accountDeleteopen}
                onClose={handleAClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAClose} variant="outlined">Cancel</Button>
                    <Button onClick={(e) => { deleteAccount(listid); handleAClose() }} autoFocus variant="contained" color='error'>OK</Button>
                </DialogActions>
            </Dialog>

            {/* poup for delete */}
            <Dialog
                open={groupDeleteopen}
                onClose={handlegrclose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlegrclose} variant="outlined">Cancel</Button>
                    <Button onClick={(e) => { groupAccount(grpid); handlegrclose() }} autoFocus variant="contained" color='error'>OK</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function Accountandgroupcreationlist() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Accountandgroupcreation /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Accountandgroupcreationlist;