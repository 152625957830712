import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
import { Button, Grid, Typography, Box, Table, TableBody, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, DialogActions } from '@mui/material';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { FaPrint, FaFilePdf } from "react-icons/fa";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import $ from 'jquery';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { userStyle } from '../../PageStyle';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { useReactToPrint } from "react-to-print";
import axios from 'axios';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';

function CustomerGroupsListtable() {

  const { auth } = useContext(AuthContext);
  const [exceldata, setExceldata] = useState([]);
  const [cgroups, setCgroups] = useState();
  const [cusgrp, setCusgrp] = useState({});

  // User Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //Delete modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleClickOpen = () => { setIsDeleteOpen(true); };
  const handleClose = () => { setIsDeleteOpen(false); };

  const fetchHandler = async () => {
    try {
      let response = await axios.get(SERVICE.CUSTOMER_GROUP, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setCgroups(response.data.cgroups)
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //set function to get particular row
  const rowData = async (id) => {
    try {
      let response = await axios.get(`${SERVICE.CUSTOMER_GROUP_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setCusgrp(response.data.scgroup);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //alert delete popup
  let cgrpid = cusgrp._id;
  const deleteCgrp = async (cgrpid) => {
    try {
      let response = await axios.delete(`${SERVICE.CUSTOMER_GROUP_SINGLE}/${cgrpid}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const fileName = 'Customer Groups'
  const getexcelDatas= async () => {
    var data = cgroups.map(t=>({"Customer Group ID":t.cusgroupid, "Customer Group Name":t.cusgroupname}));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CUSTOMER GROUP LIST',
    pageStyle: 'print'
  });


  //  PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#cutomergrouptablePDF' })
    doc.save('Customer Group.pdf')
  }

  //DATA TABLE
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  useEffect(() => {

    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });

  }, [(tableRef.current)])

  useEffect(() => {
    fetchHandler();
   

  }, [deleteCgrp]);

  useEffect(() => {
    getexcelDatas();

  }, [cgroups]);


  return (
    <Box>
      <Headtitle title={'Customer groups'} />
      <Typography sx={userStyle.HeaderText}>Customer Groups <Typography sx={userStyle.SubHeaderText}></Typography></Typography>
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography sx={userStyle.importheadtext}>All Customer Groups </Typography>
          </Grid>
          {isUserRoleCompare[0].acustomergrp && (
            <>
              <Grid item xs={4}>
                <Link to="/contact/customergroup/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd} >ADD</Button></Link>
              </Grid>
            </>
          )} <br />
        </Grid> <br />
        <Grid container spacing={2}>
          <Grid item xs={3}> </Grid>
          <Grid container sx={{ justifyContent: "center" }} >
            <Grid >
              {isUserRoleCompare[0].csvcustomergrp && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelcustomergrp && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printcustomergrp && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfcustomergrp && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          <Grid item xs={2}></Grid>
        </Grid>
        <br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="cutomergrouptable" ref={tableRef}>
              <TableHead>
                <TableRow>
                <StyledTableCell align="left">Action</StyledTableCell>
                  <StyledTableCell>Customer Group Code</StyledTableCell>
                  <StyledTableCell>Customer Group Name</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cgroups && (
                  cgroups.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        <Grid sx={{ display: 'flex' }}>
                          {isUserRoleCompare[0].ecustomergrp && (<Link to={`/contact/customergroup/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                          {isUserRoleCompare[0].dcustomergrp && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>)}
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row"> {row.cusgroupid} </StyledTableCell>
                      <StyledTableCell component="th" scope="row"> {row.cusgroupname} </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      { /* ****** Print ****** */}
      <Box sx={userStyle.printcls} >
        <Box>
          <Typography variant='h5' >Customer Groups </Typography>
        </Box>
        <>
          <Box>
            <TableContainer component={Paper} sx={userStyle.printcls}>
              <Table aria-label="simple table" id="cutomergrouptablePDF" ref={componentRef}>
                <TableHead sx={{ fontWeight: "600" }} >
                  <StyledTableRow >
                    <StyledTableCell>Customer Group Code</StyledTableCell>
                    <StyledTableCell>Customer Group Name</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                {cgroups && (
                  cgroups.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row"> {row.cusgroupid} </StyledTableCell>
                      <StyledTableCell component="th" scope="row"> {row.cusgroupname} </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      </Box>

      {/* ALERT DIALOG */}
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={(e) => deleteCgrp(cgrpid)} autoFocus variant="contained" color='error'> OK </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function CustomerGroupsList() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <CustomerGroupsListtable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default CustomerGroupsList;