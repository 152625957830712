import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableRow, TableCell, TableBody, TableHead, TableContainer, Button, TableFooter } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

function Trailbalancelist() {
    const [trailbalance, setTrailbalance] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const [dateFilter, setDateFilter] = useState({
        startdate: "", enddate: "",
    });
    const { auth, setAuth } = useContext(AuthContext);
    //  Trail Balance...........
    const fetchTrailbalance = async () => {
        try {
            let customer = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = customer.data.customerpays.filter((data) => {
                let startdatefromsupplier = dateFilter.startdate;
                let enddatefromsupplier = dateFilter.enddate;
                return startdatefromsupplier <= data.createdAt && new Date(enddatefromsupplier) + 1 >= data.createdAt
            })
            setAuth({ ...auth, loginState: true });
            setTrailbalance(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 25, 50, 100, 200, 500, -1],
                            [10, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    useEffect(
        () => {
        }, [dateFilter]
    )

    // Excel...........
    const fileName = " Trail Balance ";
    const customerexcel = async () => {
        let res_customerpayment = await axios.get('http://localhost:8003/api/trailbalance');
        var data = res_customerpayment.data.customerpayment.map(t => ({
            contactid: t.contactid, firstname: t.firstname,
            lastname: t.lastname, email: t.email, creditlimit: t.creditlimit, payterm: t.payterm, paytermassign: t.paytermassign,
            openbalance: t.openbalance, customergroup: t.customergroup, contactperson: t.contactperson,
            addressone: t.addressone, addresstwo: t.addresstwo, phonenumber: t.phonenumber, whatsappno: t.whatsappno
        }));
        setExceldata(data);
    }

     // Print
     const componentRef = useRef();
     const handleprint = useReactToPrint({
         content: () => componentRef.current,
         documentTitle: 'HIPOS | TRIAL BALANCE',
         pageStyle: 'print'
     });

    //  PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Trailbalance' })
        doc.save('Trailbalance.pdf')
    }
    useEffect(
        () => {
            // fetchTrailbalance();
            customerexcel();
        }, []
    )
    let total = 0;

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 25, 50, 100, 200, 500, -1],
                        [10, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [])

    return (
        <Box >
            <Headtitle title={'Trail Balance'} />
            { /* ****** Header Content ****** */}
            <Typography component={'span'} variant={'body2'} sx={userStyle.HeaderText}>Trail Balance <Typography sx={userStyle.SubHeaderText}></Typography></Typography>
            <Box sx={userStyle.container} >
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={4} md={4}>
                        <Grid container >
                            <Grid item lg={2} md={2}>
                                <Typography component={'span'} variant={'body2'} sx={{ marginTop: 1 }}> From</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.startdate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="startdate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Grid container >
                            <Grid item lg={1} md={1}>
                                <Typography component={'span'} variant={'body2'} sx={{ marginTop: 1 }}>To</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.enddate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="enddate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={1} md={1}>
                        <Button variant='contained' color='secondary'
                            onClick={(e) => { fetchTrailbalance() }}
                        >Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                    <Grid >
                            {isUserRoleCompare[0].csvtrailbalance && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].exceltrailbalance && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printtrailbalance && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdftrailbalance && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Trailbalance" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Particulaus</StyledTableCell>
                                    <StyledTableCell>Debit</StyledTableCell>
                                    <StyledTableCell>Credit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {trailbalance.length > 0 && (
                                    trailbalance.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.Particulaus}</StyledTableCell>
                                            <StyledTableCell align="left">{row.Particulaus}</StyledTableCell>
                                            <StyledTableCell align="left">{row.Particulaus}</StyledTableCell >
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow >
                                    <TableCell>Difference on Credit</TableCell>
                                    <TableCell>4646123</TableCell>
                                    <TableCell>541254</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell></TableCell>
                                    <TableCell>4646123</TableCell>
                                    <TableCell>541254</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
        </Box>
    );
}
function Trailbalance() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Trailbalancelist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Trailbalance;