import React, { useState, useEffect, createRef, useRef, } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Typography } from '@mui/material';
import { userStyle } from '../../../PageStyle';
import { StyledTableRow, StyledTableCell } from '../../../../components/Table';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';


const TaxgrpPrint = () => {

  const [hsnGrp, sethsnGrp] = useState([]);

  // Get Datas
  const taxrateRequest = async () => {
    try {
      let response = await axios.get(`http://localhost:8003/api/taxrates`);
      let hsnGrpData = response.data.taxrates.filter((data) => {
        return data.taxtype == "hsn"
      })
      sethsnGrp(hsnGrpData)
      setTimeout(() => {
        handleprint()
      }, 3000);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | HSN',
    pageStyle: 'print'
  });
  const ref = createRef();
  const options = {
    orientation: 'portrait',
    unit: 'in'
  };
  useEffect(() => {
    taxrateRequest()
  }, []);

  return (
    <Box>
      <Headtitle title={'HSN Print'} />
      <Box>
        <Typography variant='h5'>HSN</Typography>
      </Box>
      <br />
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" id="hsn" ref={componentRef}>
            <TableHead>
              <StyledTableRow>
              <StyledTableCell >HSN</StyledTableCell>
                  <StyledTableCell align="left" >Tax rate %</StyledTableCell>
                  <StyledTableCell align="left" >Sub taxes</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {hsnGrp.length > 0 && (
                hsnGrp.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">{item.hsn}</StyledTableCell>
                    <StyledTableCell align="left">{item.taxrate}</StyledTableCell>
                      <StyledTableCell align="left">{item.hsnsubtax + "," + " "}</StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
export default TaxgrpPrint;