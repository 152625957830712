import React, { useEffect, useState, useContext, useRef } from 'react';
import { Button, Box, Typography, FormControl,Grid, InputLabel, Select, MenuItem, Table, TableHead, TableContainer, TableBody, Dialog, DialogContent, DialogActions, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import $ from 'jquery';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { userStyle } from '../../PageStyle';
import axios from 'axios';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext,UserRoleAccessContext } from '../../../context/Appcontext';

function AlpharateListtable() {

  const [isAlpha, setIsAlpha] = useState();
  const { auth } = useContext(AuthContext);

  const [Alpha, setAlpha] = useState("");

  // User Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //popup model
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState()
  const handleClickOpen = () => { setIsErrorOpen(true);  };
  const handleClose = () => { setIsErrorOpen(false); };

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  // Alpha Rates
  const fetchAlpha = async () => {
    try {
      let res = await axios.get(SERVICE.ALPHARATE, {
        headers: {
            'Authorization': `Bearer ${auth.APIToken}`
        }
    });
      setIsAlpha(res.data.alpharates);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const messages = "Another one is Activated";

  //Activate and Deactivate
  const handleActivatetrue = async (id) => {
    try {
      let getrow = await axios.get(`${SERVICE.ALPHARATE_SINGLE}/${id}`, {
        headers: {
            'Authorization': `Bearer ${auth.APIToken}`
        }
    });
      let getActivate = getrow.data.salpharate.activate;
      isAlpha.map((item) => {
        if (item.activate != true) {
          axios.put(`${SERVICE.ALPHARATE_SINGLE}/${id}`, {
            headers: {
              'Authorization': `Bearer ${auth.APIToken}`
          },
            activate: true,
          }).then(res => res.data);
        } else {
          axios.put(`${SERVICE.ALPHARATE_SINGLE}/${id}`, {
            headers: {
              'Authorization': `Bearer ${auth.APIToken}`
          },
            activate: false,
          }).then(res => res.data);
          setShowAlert(messages);
          handleClickOpen();
        }
      })
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  const handleActivatefalse = async (id) => {
    try {
      let getrow = await axios.get(`${SERVICE.ALPHARATE_SINGLE}/${id}`, {
        headers: {
            'Authorization': `Bearer ${auth.APIToken}`
        }
    });
      let getActivate = getrow.data.salpharate.activate;
      await axios.put(`${SERVICE.ALPHARATE_SINGLE}/${id}`, {
        activate: false,
      }).then(res => res.data);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const deleteAlpha = async (id) => {
    try {
      let delalpha = await axios.delete(`${SERVICE.ALPHARATE_SINGLE}/${id}`, {
        headers: {
            'Authorization': `Bearer ${auth.APIToken}`
        }
    });
      toast.success(delalpha.data.message);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(
    () => {
      fetchAlpha();
    }, [deleteAlpha]
  )

  useEffect(
    ()=>{
        $(document).ready(function() {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
            $(tableRef.current).DataTable({
                language: { search: '', searchPlaceholder: "Search..." },
                lengthMenu: [
                      [10, 1, 25, 50, 100, 200, 500, -1],
                      [10, 1, 25, 50, 100, 200, 500, 'All'],
                  ],
                      });
          }, 1000);
          } );
    },[tableRef.current]
)
  return (
    <Box>
      <Headtitle title={'Alpharates'} />
      <Grid sx={{display: 'flex'}}>
        <Typography sx={userStyle.HeaderText}>Alpharate </Typography> &nbsp;
        <Typography sx={userStyle.SubHeaderText} style={{marginTop:"22px"}}>Manage your alpharate settings</Typography>
      </Grid>
      <Box sx={userStyle.container}>
        <Box>
          { /* Table header  */}
          <Grid container>
              <Grid item xs={8}>
                  <Typography sx={userStyle.importheadtext}>All your Alpharate settings</Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'right !important' }}>
                {isUserRoleCompare[0].aalpharate && ( <Link to="/settings/alpharate/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link> )}
              </Grid>
          </Grid>
          {/* Table start */}
          <TableContainer component={Paper} sx={{ boxShadow: 'none',marginTop:"30px" }}>
            <Table aria-label="customized table" id="alphatable" sx={{ margin: '25px' }} ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left">Sno</StyledTableCell>
                  <StyledTableCell align="left">Alpha ID</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left">Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {isAlpha && (isAlpha.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">{item.alphaid}</StyledTableCell>
                    <StyledTableCell align="left">
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          size="small"
                          value={Alpha.status}
                          defaultValue={item.activate == true ? "true" : "false"}
                        >
                          <MenuItem value={'true'} onClick={(e) => {
                            handleActivatetrue(item._id)
                          }
                          }>Activate</MenuItem>
                          <MenuItem value={'false'} onClick={(e) => handleActivatefalse(item._id)}>Deactivate</MenuItem>
                        </Select>
                      </FormControl>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {isUserRoleCompare[0].valpharate && (
                          <>
                            <Link to={`/settings/alpharate/view/${item._id}`} style={{ textDecoration: 'none', color: 'white' }}><Button variant="contained" size='small' sx={userStyle.buttonview}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                          </>
                      )}
                  </StyledTableCell>
                  </StyledTableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Table end */}
        </Box>
      </Box>
      {/* ALERT DIALOG */}
      <Dialog
        open={isErrorOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography>{showAlert}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function Alpharatelist() {
  return (
    <Box sx={{ display: 'flex', }} >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <AlpharateListtable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Alpharatelist;