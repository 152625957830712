import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, TextField, Select, MenuItem, Button, DialogActions, DialogContent, DialogTitle, Dialog, Grid, Box, FormControl, OutlinedInput, InputLabel, Typography, } from '@mui/material';
import Chip from '@mui/material/Chip';
import { userStyle } from '../../../PageStyle';
import Navbar from '../../../../components/header/Navbar';
import Footer from '../../../../components/footer/Footer';
import { useNavigate,Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function HsnCreatelist() {

  const [taxRate, setTaxRate] = useState([]);
  const [subtaxrate, setSubtaxrate] = useState([])
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => { setOpen(true);};
  const handleClose = () => { setOpen(false);};

  const [taxrateGroup, setTaxrateGroup] = useState({
    hsn: "",  hsntaxrate: "", taxtype: "hsn", fortaxgonly:false
  });

    //popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert,setShowAlert] = useState()
    const handleClickOpenalert = () => {
        setIsErrorOpen(true);
    };
    const handleClosealert = () => {
        setIsErrorOpen(false);
    };



  const backLPage = useNavigate();
  let taxratedata;

  // Taxrates
  const taxrateRequest = async () => {
    try {
      let response = await axios.get(`http://localhost:8003/api/taxrates`);
      let taxrateData = response.data.taxrates.filter((data) => {
        return data.taxtype == "taxrate" 
      })
      setSubtaxrate(taxrateData);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }
    // Get GST FROM TAX
    const searchAdd = async (id) => {
        try {
            let res = await axios.get(`http://localhost:8003/api/taxrate/${id}`)
            setTaxRate(res.data.staxrate);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
      }
      let total=0;
      // Get GST FROM TAX
      const getTaxRate = async (i,e) => {
          setTaxRate((taxRate) => [...taxRate, e]);
        }
  
        function removeDuplicates(taxRate) {
          var unique = [];
          for(let i=0; i < taxRate.length; i++){ 
              if(unique.indexOf(taxRate[i]) === -1) { 
                  unique.push(taxRate[i]); 
              }else{
                unique.splice(unique.indexOf(taxRate[i]), 1); 
              }
          }
          return unique;
        }
        removeDuplicates(taxRate).map((item) => {
          total +=  +item
        })

   // Add Datas
   const sendRequest = async () => {
    try {
      let res = await axios.post("http://localhost:8003/api/taxrate/new", {
        hsn: String(taxrateGroup.hsn),     
        hsntaxrate: String(taxrateGroup.hsntaxrate),
        hsnsubtax:[...personName],
        taxrate: Number(total),
        taxtype: String(taxrateGroup.taxtype),
        fortaxgonly: String(taxrateGroup.fortaxgonly),
      });
      setTaxrateGroup(res.data);
      backLPage('/settings/taxrate/list');
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    
    } catch (err) {
      const messages = err.response.data.message;
      setShowAlert(messages);
      handleClickOpenalert();
  
    }
  };
  useEffect(
    () => {
      taxrateRequest();
    }, []
  )

  const handleSubmit = (e) => {
    e.preventDefault();
    if(taxrateGroup.hsn.length == 0 && taxrateGroup.hsntaxrate.length == 0){
      setShowAlert("Please Fill out all fields");
      handleClickOpenalert();
    }else{
      sendRequest();
    }
  }

  // ****** Multi Select ****** //
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  return (
    <>
   <Headtitle title={'HSN Create'} />
        <form onSubmit={handleSubmit}>
        <Typography sx={userStyle.HeaderText}>Add HSN Group</Typography>
          <Box sx={userStyle.container}>
            <Box component="form" sx={{ '& .MuiTextField-root': { maxWidth: '100%', minWidth: '100%', width: '400px' }, '& .MuiOutlinedInput-notchedOutline': { border: '1px solid #B97DF0', }, }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item sm={5} md={5}>
                  <FormControl size="small" fullWidth >
                    <InputLabel htmlFor="component-outlined">HSN</InputLabel>
                    <OutlinedInput
                      id="component-outlined"
                      type='text'
                      value={taxrateGroup.hsn}
                      onChange={(e) => { setTaxrateGroup({ ...taxrateGroup, hsn: e.target.value }) }}
                      label="Name"
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <OutlinedInput sx={{visibility:'hidden'}}
                      id="component-outlined"
                      type='text'
                      value={total}
                      label="Name"
                      name="taxrateone"
                    />
                <InputLabel id="demo-multiple-chip-label">Tax Group</InputLabel>
                <Grid item sm={5} md={5}>
                    <FormControl sx={{ width:400}}>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        size="small"
                        multiple
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {subtaxrate.map((item, index) => (
                          <MenuItem
                            key={item.taxname}
                            value={item.taxname + " @ " + item.taxrate}
                            style={getStyles(item.taxname, personName, theme)}
                            onClick={(e) => getTaxRate(index,item.taxrate)}

                          >
                            {item.taxname + " @ " + item.taxrate}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
              </Grid>
              <br />
            </Box>
            </Box>
            <Grid container sx={userStyle.gridcontainer}>
              <Grid >
                <Link to ="/settings/taxrate/list" ><Button sx={userStyle.buttoncancel} >Cancel</Button></Link>
                  <Button sx={userStyle.buttonadd} type="submit">Save</Button>
              </Grid>
          </Grid>
        </form>
           {/* ALERT DIALOG */}
      <Dialog
        open={isErrorOpen}
        onClose={handleClosealert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
            <Typography>{showAlert}</Typography>
        </DialogContent>
        <DialogActions>
        <Button variant="contained" color="error" onClick={handleClosealert}>ok</Button>
        </DialogActions>
        </Dialog>
    </>
  );

}
const HsnCreate = () => {
  return (
      <>
          <Box >
              <Navbar />
              <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                  <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                      <HsnCreatelist /><br /><br /><br />
                      <Footer />
                  </Box>
              </Box>
          </Box>
      </>
  );
}

export default HsnCreate;