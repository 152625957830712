import React, { useState, useEffect } from 'react';
import CanvasJSReact from './canvasjs.react';
import axios from 'axios';

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function toggleDataSeries(e) {
	if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
		e.dataSeries.visible = false;
	}
	else {
		e.dataSeries.visible = true;
	}
	chart.render();
}
const getcmonth = new Date();
const monthNames = ["January", "February", "March", "April", "May", "June",
	"July", "August", "September", "October", "November", "December"
];
const valnmonth = monthNames[getcmonth.getMonth()];
var chart;
let purchasetotal = 0;
let saletotal = 0;
const Dashbrdchart = () => {
	//sales
	const [sales, setSales] = useState();
	const [purchases, setPurchases] = useState();
	const fetchHandler = async () => {
		try {
			let sale = await axios.get("http://localhost:8003/api/sales");
			setSales(sale.data.sales);
		} catch (err) {
			console.log(err);
		}
	};
	const URL = "http://localhost:8003/api/purchases";
	const purchase = async () => {
		try {
			let purchase = await axios.get(URL);
			setPurchases(purchase.data.purchases);
		} catch (err) {
			console.log(err);
		}
	};
	useEffect(
		() => {
			fetchHandler()
			purchase()
		}, []);
	{
		purchases && (
			purchases.forEach(
				(item => {
					purchasetotal += item.paydue;
				})
			))
	}
	{
		sales && (
			sales.forEach(
				(item => {
					saletotal += +item.amount;
					console.log(saletotal);
				})
			))
	}
	const options = {
		animationEnabled: true,
		exportEnabled: true,
		theme: "light1", // "light1", "dark1", "dark2"
		title: {
			text: "This Week Sales and Purchases",
			fontSize: 20,
			font: "bold",
		},
		axisY: {
			title: "in Eur",
			includeZero: true,
			prefix: "€",
			suffix: "k"
		},
		axisX: {
			title: "",
			prefix: "day",
			interval: 0
		},

		toolTip: {
			shared: true,
			reversed: true
		},
		legend: {
			verticalAlign: "center",
			horizontalAlign: "right",
			reversed: true,
			cursor: "pointer",
			itemclick: toggleDataSeries
		},
		data: [
			{
				type: "column",
				color: "rgb(64 154 233)",
				name: "sales",
				showInLegend: true,
				yValueFormatString: "#,###k",
				dataPoints: [
					{ x: 1, y: saletotal },
					{ x: 2, y: saletotal },
					{ x: 3, y: saletotal },
					{ x: 4, y: saletotal },
					{ x: 5, y: saletotal },
					{ x: 6, y: saletotal },
					{ x: 7, y: saletotal },
				],
			},
			{
				type: "column",
				color: "rgba(103, 58, 183, 0.85)",
				name: "purchase",
				showInLegend: true,
				yValueFormatString: "#,###k",
				dataPoints: [
					{ x: 1, y: purchasetotal },
					{ x: 2, y: purchasetotal },
					{ x: 3, y: purchasetotal },
					{ x: 4, y: purchasetotal },
					{ x: 5, y: purchasetotal },
					{ x: 6, y: purchasetotal },
					{ x: 7, y: purchasetotal },
				]
			},
			// {
			//  type: "column",
			//  color: "rgb(161 210 250)",
			//  name: "stock",
			//  showInLegend: true,
			//  yValueFormatString: "#,###k",
			//  dataPoints: [
			//      { x: 1, y: 64 },
			//      { x: 2, y: 30 },
			//      { x: 3, y: 20 },
			//      { x: 4, y: 85 },
			//      { x: 5, y: 30 },
			//      { x: 6, y: 54 },
			//  ]
			// }
		]
	};
	return (
		<div>
			<CanvasJSChart options={options}
				onRef={ref => chart = ref}
			/>
		</div>
	);
}
export default Dashbrdchart;