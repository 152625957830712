import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Button, Tabs, Tab, Typography } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Businesscreate from './Businesscreate';
import Productcreate from './Productcreate';
import Contactcreate from './Contactcreate';
import Prefixescreate from './Prefixescreate';
import Countercreate from './Countercreate';
import Invoicecreate from './Invoicecreate';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext} from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import Headtitle from '../../../components/header/Headtitle';

function TabPanel(props) {

    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Businesssettingstable() {
    const [value, setValue] = useState(0);
    const [isSettingUpdate, setIsSettingUpdate] = useState(false);
    const [setngs, setSetngs] = useState({businesslocation:"",currency:"",currencysymbol:"",timezone:"",fyearsstartmonth:"",stockaccountmethod:"",
        dateformat:"",timeformat:"",defaulunit:"None",
    });
    const [counters, setCounters] = useState([]);
    const { auth } = useContext(AuthContext);

    const backLPage = useNavigate();

      // get settings data
    const fetchSettings = async () => {
    try {
      var req = await axios.get(SERVICE.UPDATE_SETTINGS,{
        headers: {
            'Authorization':`Bearer ${auth.APIToken}`
            }
      });
      setSetngs(req.data.settingid);
      setCounters(req.data.settingid.counter);
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
    }
    useEffect(
        () => {
        fetchSettings();
        }, [isSettingUpdate]
    )

    // update settings data
    const updateRequest = async (idRemovedcounter) => {
        
        try {
            let req = await axios.put(SERVICE.UPDATE_SETTINGS, {
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    },
                businessname: String(setngs.businessname),
                buniessaddress:String(setngs.buniessaddress),
                businesslocation:String(setngs.businesslocation),
                startdate: String(setngs.startdate),
                dprofitpercent: Number(setngs.dprofitpercent),
                currency: String(setngs.currency),
                currencysymbol: String(setngs.currencysymbol),
                timezone: String(setngs.timezone),
                businesslogo: String(setngs.businesslogo),
                fyearsstartmonth: String(setngs.fyearsstartmonth),
                stockaccountmethod: String(setngs.stockaccountmethod),
                dateformat: String(setngs.dateformat),
                timeformat: String(setngs.timeformat),
                skuprefix: String(setngs.skuprefix),
                esize:Boolean(setngs.esize),
                eunit:Boolean(setngs.eunit),
                ecategory: Boolean(setngs.ecategory),
                ebrand: Boolean(setngs.ebrand),
                defaulunit: String(setngs.defaulunit),
                credeitlimit: Number(setngs.credeitlimit),
                purchasesku: String(setngs.purchasesku),
                purchasereturnsku: String(setngs.purchasereturnsku),
                purchaseordersku: String(setngs.purchaseordersku),
                expensesku: String(setngs.expensesku),
                customersku: String(setngs.customersku),
                suppliersku: String(setngs.suppliersku),
                cusgroupsku: String(setngs.cusgroupsku),
                discountsku:String(setngs.discountsku),
                businesslocationsku: String(setngs.businesslocationsku),
                usersku: String(setngs.usersku),
                salesku: String(setngs.salesku),
                draftsku: String(setngs.draftsku),
                quotationsku:String(setngs.quotationsku),
                ciono :String(setngs.ciono),
                gstno :String(setngs.gstno),
                counter:[...idRemovedcounter],
                applicabletax:String(setngs.applicabletax),
                multivalue:String(setngs.multivalue),
                sellingpricetax:String(setngs.sellingpricetax),
                minquantity:String(setngs.minquantity),
                accountsku:String(setngs.accountsku),
                groupsku:String(setngs.groupsku),
                cashsku:String(setngs.cashsku),
                banksku:String(setngs.banksku),
                vouchersku:String(setngs.vouchersku),
                estimatesku:String(setngs.estimatesku),
                edraftsku:String(setngs.edraftsku),
                equotationsku:String(setngs.equotationsku),
                customerdrugentrysku:String(setngs.customerdrugentrysku),
                departmentsku:String(setngs.departmentsku)
            });
            setSetngs(req.data);
            setIsSettingUpdate(true)
            backLPage('/settings/business/list');
            toast.success(req.data.message,{
                position: toast.POSITION.TOP_CENTER
            });
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const idRemovedNew = ()=> {
        const idRemovedcounter = counters.map((value)=> {
          if (value.newAdded) {
            return {countername : value.countername,counterid:value.counterid}
          }
          else {
            return value;
          }
        });
        updateRequest(idRemovedcounter);
      }
    const sendRequest = (e) => {
        e.preventDefault();
        idRemovedNew();
    }
    return (
        <>
            <Headtitle title={'Business Settings'}/>
            <Typography sx={userStyle.HeaderText}>Business Settings</Typography>
            <form onSubmit={sendRequest}>
                <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        sx={{ borderRight: 1, borderColor: 'divider', minWidth: '15%', maxWidth: '15%' }}
                    >
                        <Tab label="Business" {...a11yProps(0)} />
                        <Tab label="Product" {...a11yProps(1)} />
                        <Tab label="Contact" {...a11yProps(2)} />
                        <Tab label="Prefixes" {...a11yProps(3)} />
                        <Tab label="Counter" {...a11yProps(4)} />
                        <Tab label="Invoice" {...a11yProps(5)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Businesscreate setngs={setngs} setSetngs={setSetngs} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Productcreate setngs={setngs} setSetngs={setSetngs} />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Contactcreate setngs={setngs} setSetngs={setSetngs} />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <Prefixescreate setngs={setngs} setSetngs={setSetngs} />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                        <Countercreate setngs={setngs} setSetngs={setSetngs} counters={counters} setCounters={setCounters}  />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                        <Invoicecreate setngs={setngs} setSetngs={setSetngs} />
                    </TabPanel>
                </Box>
                <br />
                <Grid container sx={{ justifyContent: 'right !important', bottom: '0', }}>
                    <Box>
                        <Button type="submit" name="submit" sx={userStyle.buttonadd}>UPDATE SETTINGS</Button>
                    </Box>
                </Grid>
            </form>
        </>
    );
}

function Businesssettings() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Businesssettingstable /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Businesssettings;