import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from "../../services/Baseservice";
import { AuthContext, UserRoleAccessContext } from '../../context/Appcontext';

function Stockoverviewreportlist() {

  const { auth } = useContext(AuthContext);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const [products, setProducts] = useState([]);
  
  // Export Excel
  const fileName = 'Stock Overview Report'
  //Excel data
  const [exceldata, setExceldata] = useState([]);

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  //fetch all purchase
const fetchProducts = async () => {
  try {
      let res_product = await axios.get(SERVICE.PRODUCT,{
        headers: {
            'Authorization': `Bearer ${auth.APIToken}`
          },
      });
      setProducts(res_product.data.products);
      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
  } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
  }
};

  // get particular columns for export excel
  const getexcelDatas = async () => {
    var data = products.map((t) => ({ 
        Category: t.category, "Stock in Hand(pieces)": t.currentstock, "Purchase Rate":t.selltaxtype == "Inclusive" ? t.pruchaseincludetax : t.pruchaseexcludetax, "Sales Rate": t.sellingpriceunitcost,"MRP Rate": t.mrp == "" || t.mrp == 0 ? t.sellingpriceunitcost : t.mrp
    }));
    setExceldata(data);
  };

  useEffect(() => {
      getexcelDatas();
  }, [products])

  // PDF
  const downloadpdf = () => {
  const doc = new jsPDF()
  autoTable(doc, { html: '#stockoverview' })
  doc.save('Stock Overview Report.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
  content: () => componentRef.current,
  documentTitle: 'HIPOS | STOCK OVERVIEW REPORT',
  pageStyle: 'print'
  });

  useEffect(
    ()=>{
      fetchProducts();
    },[]
  )

  return (

      <Box>
        <Headtitle title={'Stock Overview'} />
        <Typography sx={userStyle.HeaderText}>Stock Overview</Typography>
        {/* content start */}
        <Box sx={userStyle.container}>
          { /* ******************************************************EXPORT Buttons****************************************************** */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvstockoverviewreport && (
                  <>
                    <ExportCSV csvData={exceldata} fileName={fileName} />
                  </>
              )}
              {isUserRoleCompare[0].excelstockoverviewreport && (
                  <>
                    <ExportXL csvData={exceldata} fileName={fileName} />
                  </>
              )}
              {isUserRoleCompare[0].printstockoverviewreport && (
                  <>
                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                  </>
              )}
              {isUserRoleCompare[0].pdfstockoverviewreport && (
                  <>
                    <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                  </>
              )}
            </Grid>
          </Grid><br />
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700 }} aria-label="customized table" id="stockoverview" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell >Stock in Hand(pieces)</StyledTableCell>
                    <StyledTableCell >Purchase Rate</StyledTableCell>
                    <StyledTableCell >Sales Rate</StyledTableCell>
                    <StyledTableCell >MRP Rate</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {products.length > 0 ? (
                    products.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.category}</StyledTableCell>
                          <StyledTableCell>{row.currentstock}</StyledTableCell>
                          <StyledTableCell>{row.selltaxtype == "Inclusive" ? row.pruchaseincludetax : row.pruchaseexcludetax}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                          <StyledTableCell>{row.mrp == "" || row.mrp == 0 ? row.sellingpriceunitcost : row.mrp}</StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={6}>No data record found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {/* Print Layout */}
        <TableContainer component={Paper} sx={userStyle.printcls} >
          <Table sx={{ minWidth: 700 }} aria-label="customized table" id="stockoverview" ref={componentRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell >Stock in Hand(pieces)</StyledTableCell>
                    <StyledTableCell >Purchase Rate</StyledTableCell>
                    <StyledTableCell >Sales Rate</StyledTableCell>
                    <StyledTableCell >MRP Rate</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {products.length > 0 ? (
                    products.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.category}</StyledTableCell>
                          <StyledTableCell>{row.currentstock}</StyledTableCell>
                          <StyledTableCell>{row.row.selltaxtype == "Inclusive" ? row.pruchaseincludetax : row.pruchaseexcludetax}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                          <StyledTableCell>{row.mrp == "" || row.mrp == 0 ? row.sellingpriceunitcost : row.mrp}</StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={6}>No data record found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
        </TableContainer>
      </Box>
  );
}


function Stockoverviewreport() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Stockoverviewreportlist /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Stockoverviewreport;