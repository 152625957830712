import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, FormGroup, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle, colourStyles } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import Selects from 'react-select';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print"
import moment from 'moment';

function Customeroverduesreportlist() {

    const [pos, setPos] = useState({});
    const [customernames, setCustomernames] = useState({});
    const [exceldata, setExceldata] = useState([]);
    const [newdata, setNewdata] = useState([]);
    const [checked, setChecked] = useState(true);
    const [code, setCode] = useState([]);
    const { auth } = useContext(AuthContext);

    // Areafilter ...................Area
    const [customerArea, setCustomerArea] = useState({
        area: ""
    });

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

      // Daysdiff
      let totalDays = 0;
      let datefiltervalue;

    //function 
    const fetchCustomerOverDues = async (e) => {
        setChecked(s => !s)
        try {
            let response = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let req = response.data.pos1.filter((item) => {
                var futuredate = new Date(new Date().setDate(new Date(item.date).getDate() + item.creditdays));
                datefiltervalue = moment(futuredate).utc().format('DD-MM-YYYY')
                totalDays = (Number(item.creditdays)) - ((new Date().getDate() - 1 - new Date(item.date).getDate()) - 1)
                const d = new Date(futuredate);
                let x = d.getDay()

                if (totalDays >= x) {
                    totalDays = totalDays + x
                }

                if (checked === true) {
                    if (item.dueamount > 0 && totalDays < 0) {
                        return item
                    }
                } else if (checked === false) {
                    setPos([])
                }
            })
            setPos(req)
            setCustomerArea({ area: "" })
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Fetch customer adress 
    const fetchCustomerAddress = async () => {
        try {
            let res_group = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.customers?.map((d) => {
                return d.city
            })
            setNewdata(masters_data);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    let newarray = [...new Set(newdata)];

    // Fetch customer Area for dropdown......Area
    const fetchcustomername = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomernames(
                newarray.map((d) => ({
                    ...d,
                    label: d,
                    value: d,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchcustomername();
    }, [customernames])

    const getCateName = (e) => {
        if (e) {
            setPos([]) || setChecked(true)
        }
        setCode(e.area)
    }

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    //Customers
    const fetchPos = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.pos1.filter((data) => {
                var futuredate = new Date(new Date().setDate(new Date(data.date).getDate() + (data.creditdays * 24 * 60 * 60 * 1000)));
                datefiltervalue = moment(futuredate).utc().format('DD-MM-YYYY')
                totalDays = (Number(data.creditdays)) - ((new Date().getDate() - 1 - new Date(data.date).getDate()) + 1)
                const d = new Date(futuredate);
                let x = d.getDay()

                if (totalDays >= x) {
                    totalDays = totalDays + x
                    return totalDays
                }
            
                if (data.dueamount > 0 && totalDays < 0) {
                    if (customerArea.area == "" && totalDays < 0) {
                        return data
                    }
                    else if (data.location == customerArea.area && totalDays < 0) {
                        return data
                    }
                    else if (customerArea.area === code ) {
                        return data
                    }
                }
            })
            setPos(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchCustomerAddress();
        fetchPos();
    }, []);

    const fileName = 'Customer Over Dues Report'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = pos.map(t => ({
            "Area": t.address,
            "Bill No": t.referenceno,
            "Due Date": t.duedate,
            "Days Difference": ((new Date().getDate() - new Date(t.date).getDate()) - 1) - (Number(t.creditdays)),
            "Due Amount": t.dueamount
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CUSTOMER OVER DUES REPORT',
        pageStyle: 'print'
    });

    //  PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Customeroverduesreportlist' })
        doc.save('Customeroverduesreportlist.pdf')
    }

    useEffect(() => {
        getexcelDatas();
    }, [pos]);

    return (
        <Box >
            <Headtitle title={'Customer Over Dues Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Customer Over Dues Report</Typography>
            <Box sx={userStyle.filtercontent}>
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={2} md={2}>
                        <Typography sx={{ marginTop: '7px', float: 'right' }}>Area</Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sx={{ display: 'flex' }}>
                        <FormControl size="small" fullWidth>
                            <Selects
                                onChange={(e) => {
                                    setCustomerArea({ ...customerArea, area: e.value });
                                    getCateName(e);
                                    setChecked(true)
                                }}
                                styles={colourStyles} 
                                options={customernames}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onClick={(e) => { fetchCustomerOverDues(e) }} checked={!checked} />} label="Select All" />
                        </FormGroup>
                    </Grid>
                    <Grid item lg={3} md={3}>
                        <Button
                            onClick={fetchPos}
                            variant='contained' color='secondary'>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br /><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvcusoverduesrep && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelcusoverduesrep && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printcusoverduesrep && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfcusoverduesrep && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Customeroverduesreportlist" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Bill No</StyledTableCell>
                                    <StyledTableCell>Due Date</StyledTableCell>
                                    <StyledTableCell>Days Diff </StyledTableCell>
                                    <StyledTableCell>Due Amount</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {pos.length > 0 && (
                                    pos?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.location}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell >
                                            <StyledTableCell align="left">{row.duedate}</StyledTableCell>
                                            <StyledTableCell align="left"> {((new Date().getDate() - new Date(row.date).getDate()) - 1) - (Number(row.creditdays))}</StyledTableCell>
                                            <StyledTableCell align="left">{row.dueamount}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                    { /* ****** Print ****** */}
                    <Box sx={userStyle.printcls} ref={componentRef}>
                        <Box>
                            <Typography variant='h5' >Customer Over Dues Report</Typography>
                        </Box>
                        <>
                            <Box  >
                                <TableContainer component={Paper} sx={userStyle.printcls}>
                                    <Table aria-label="simple table" id="Customeroverduesreportlist">
                                        <TableHead sx={{ fontWeight: "600" }} >
                                            <StyledTableRow >
                                                <StyledTableCell>Area</StyledTableCell>
                                                <StyledTableCell>Total Bill Due</StyledTableCell>
                                                <StyledTableCell>Total Due Amount</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pos.length > 0 && (
                                                pos.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">{row.location}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.referenceno}</StyledTableCell >
                                                        <StyledTableCell align="left">{row.duedate}</StyledTableCell>
                                                        <StyledTableCell align="left"> {((new Date().getDate() - new Date(row.date).getDate()) - 1) - (Number(row.creditdays))}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.dueamount}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </>
                    </Box>
                </Box>
            </>
        </Box>
    );
}

function Customeroverduesreport() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Customeroverduesreportlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Customeroverduesreport;