import React, { useState, useEffect, useContext } from 'react';
import { userStyle, colourStyles } from '../../PageStyle';
import { Box, Grid, FormControl, Tooltip, InputLabel, Dialog, DialogContent, DialogActions, OutlinedInput, Select, MenuItem, Typography, FormGroup, FormControlLabel, Checkbox, TextField, Button, IconButton } from '@mui/material';
import { FcInfo } from "react-icons/fc";
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import Selects from "react-select";
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function Productcreatelist() {

    const [categories, setCategories] = useState();
    const [units, setUnits] = useState([]);
    const [busilocations, setBusilocations] = useState();
    const [schedule, setSchedule] = useState();
    const [products, setProducts] = useState([]);
    const [taxrates, setTaxrates] = useState([]);
    const [hsnGrp, sethsnGrp] = useState([]);
    const [setngs, setSetngs] = useState();
    const [taxtypes, setTaxtypes] = useState([]);
    const { auth } = useContext(AuthContext);
    const backLPage = useNavigate();
    let newval = setngs ? setngs.skuprefix + "0001" : "PR0001";

    //popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleOpen = () => {
        setIsErrorOpen(true);
    };
    const handleClose = () => {
        setIsErrorOpen(false);
    };

    const selltaxtype = [{ type: "Exclusive" }, { type: "Inclusive" }];
    let alltype = [];
    alltype = selltaxtype.flat();


    // Text field
    const [product, setProduct] = useState({
        prodcode: "", prodname: "", category: "DEFAULT", schedule: "DEFAULT", molecules: "", manufacture: "", businesslocation: "",
        managestock: "", hsn: "", hsncode: "", unit: "", rack: "", row: "", minquantity: "", maxquantity: "", applicabletax: "",
        selltaxtype: "", boxno: "",reorderlevel:"",
    });

    // Categorys
    const fetchCategory = async () => {
        try {
            let response = await axios.get(SERVICE.CATEGORIES, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setCategories(
                response.data.categories?.map((d) => ({

                    ...d,
                    label: d.categoryname,
                    value: d.categoryname,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const taxtype = () => {
        try {
            setTaxtypes(
                alltype?.map((d) => ({
                    ...d,
                    label: d.type,
                    value: d.type,
                }))
            );

        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const fetchSettings = async () => {
        try {
            var response = await axios.get(`${SERVICE.UPDATE_SETTINGS}`,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.APIToken}`
                    },
                });
            setSetngs(response.data.settingid);

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages)
        }
    }

    // schedules
    const fetchSchedule = async () => {
        try {
            let response = await axios.get(SERVICE.SCHEDULE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setSchedule(
                response.data.schedule?.map((d) => ({

                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // units
    const fetchUnits = async () => {
        try {
            let response = await axios.get(SERVICE.UNIT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setUnits(response.data.unit?.map((d) => ({

                ...d,
                label: d.unit,
                value: d.unit,
            }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Business Location
    const fetchLocation = async () => {
        try {
            let response = await axios.get(SERVICE.BUSINESS_LOCATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setBusilocations(response.data.busilocations.map((d) => ({
                ...d,
                label: d.name,
                value: d.name,
            })));
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Get tax
    const taxrateRequest = async () => {
        try {
            let response = await axios.get(SERVICE.TAXRATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            let hsnGrpData = response.data.taxrates.filter((data) => {
                if (data.taxtype == "hsn") {
                    return data
                }
            })
            setTaxrates(
                response?.data?.taxrates?.map((d) => ({
                    ...d,
                    label: d.taxname,
                    value: d.taxname,
                })))
            sethsnGrp(hsnGrpData)
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const fetchProducts = async () => {
        try {
            let response = await axios.get(SERVICE.PRODUCT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });

            setProducts(response.data.products);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }


    // store product data
    const sendRequest = async () => {
        try {
            let PRODUCT_REQ = await axios.post(SERVICE.PRODUCT_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                prodcode: String(newval),
                prodname: String(product.prodname),
                category: String(product.category),
                schedule: String(product.schedule),
                molecules: String(product.molecules),
                manufacture: String(product.manufacture),
                businesslocation: String(product.businesslocation),
                managestock: Boolean(product.managestock),
                hsn: String(product.hsn),
                hsncodecheck: Boolean(product.hsncodeCheck),
                hsncode: String(product.hsncode),
                unit: String(product.unit),
                rack: String(product.rack),
                row: String(product.row),
                boxno: String(product.boxno),
                minquantity: Number(product.minquantity),
                maxquantity: Number(product.maxquantity),
                reorderlevel:Number(product.reorderlevel),
                applicabletax: String(product.applicabletax),
                selltaxtype: String(product.selltaxtype),
                batch:String(""),
                currentstock: Number(0),
                purchaseexcludetax: Number(0),
                pruchaseincludetax: Number(0),
                sellingpriceunitcost: Number(0),
                ptr:Number(0),
                pts:Number(0),
                mrp:Number(0),
                expirydate:String(""),
            });

            backLPage('/product/product/list');
            toast.success(PRODUCT_REQ.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // store product data for next page
    const sendOtherRequest = async () => {
        try {
               let res = await axios.post(SERVICE.PRODUCT_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                prodcode: String(newval),
                prodname: String(product.prodname),
                category: String(product.category),
                schedule: String(product.schedule),
                molecules: String(product.molecules),
                manufacture: String(product.manufacture),
                businesslocation: String(product.businesslocation),
                managestock: Boolean(product.managestock),
                hsn: String(product.hsn),
                hsncodecheck: Boolean(product.hsncodeCheck),
                hsncode: String(product.hsncode),
                unit: String(product.unit),
                rack: String(product.rack),
                row: String(product.row),
                boxno: String(product.boxno),
                minquantity: Number(product.minquantity),
                maxquantity: Number(product.maxquantity),
                reorderlevel:Number(product.reorderlevel),
                applicabletax: String(product.applicabletax),
                selltaxtype: String(product.selltaxtype),
                batch:String(""),
                currentstock: Number(0),
                purchaseexcludetax: Number(0),
                pruchaseincludetax: Number(0),
                sellingpriceunitcost: Number(0),
                ptr:Number(0),
                pts:Number(0),
                mrp:Number(0),
                expirydate:String(""),
            });
            await fetchProducts();
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (product.prodname == "") {
            setShowAlert("Please enter product name");
            handleOpen();
        } else {
            sendRequest();
        }
    };

    const handleotherSubmit = (e) => {
        e.preventDefault();
        if (product.prodname == "") {
            setShowAlert("Please enter product name");
            handleOpen();
        } else {
            sendOtherRequest();
        }
    };

    // fetch all data from page load
    useEffect(
        () => {
            fetchSettings();
            fetchProducts();
            fetchCategory();
            fetchSchedule();
            fetchLocation();
            taxrateRequest();
            fetchUnits();
            taxtype();
        }, []);

    return (
        <Box>
            <Headtitle title={'Add Product'} />
            <form>
                {/* header text */}
                <Typography sx={userStyle.HeaderText}>Add new product</Typography>
                {/* content start */}
                <Box sx={userStyle.filtercontent}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            {products && (
                                products?.map(
                                    () => {
                                        let strings = setngs ? setngs.skuprefix : "PR";
                                        let refNo = products[products.length - 1].prodcode;
                                        let digits = (products.length + 1).toString();
                                        const stringLength = refNo.length;
                                        let lastChar = refNo.charAt(stringLength - 1);
                                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                                        let lastBeforeChar = refNo.slice(-2);
                                        let lastThreeChar = refNo.slice(-3);
                                        let lastDigit = refNo.slice(-4);
                                        let refNOINC = parseInt(lastChar) + 1
                                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                                        let refLstThree = parseInt(lastThreeChar) + 1;
                                        let refLstDigit = parseInt(lastDigit) + 1;
                                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                            refNOINC = ("000" + refNOINC);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                            refNOINC = ("00" + refLstTwo);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                                            refNOINC = ("0" + refLstThree);
                                            newval = strings + refNOINC;
                                        } else {
                                            refNOINC = (refLstDigit);
                                            newval = strings + refNOINC;
                                        }
                                    }))}
                            <InputLabel htmlFor="component-outlined" >Product Code</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={newval}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Product Name <b style={{ color: "red" }}> *</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.prodname}
                                    onChange={(e) => {
                                        setProduct({
                                            ...product,
                                            prodname: e.target.value,
                                            prodcode: newval,
                                            unit: setngs ? setngs.defaulunit : "",
                                            applicabletax: setngs ? setngs.applicabletax : "",
                                            molecules: "DEFAULT",
                                            manufacture: "DEFAULT",
                                            schedule: "DEFAULT",
                                            category: "DEFAULT",
                                            rack: 0,
                                            row: 0,
                                            boxno: 0,
                                            hsn: "",
                                            hsncode: "",
                                            selltaxtype: setngs ? setngs.sellingpricetax : "",
                                            minquantity: setngs ? setngs.minquantity : 0,
                                            maxquantity: setngs ? setngs.maxquantity : 0,
                                            businesslocation: setngs ? setngs.businesslocation : ""
                                        })
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Category <b style={{ color: "red" }}> *</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    styles={colourStyles}
                                    options={categories}
                                    placeholder="DEFAULT"
                                    onChange={(e) => {
                                        setProduct({ ...product, category: e.categoryname });
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Schedule <b style={{ color: "red" }}> *</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    styles={colourStyles}
                                    placeholder="DEFAULT"
                                    options={schedule}
                                    onChange={(e) => { setProduct({ ...product, schedule: e.name }) }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel >Molecule <b style={{ color: "red" }}> *</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    placeholder="DEFAULT"
                                    value={product.molecules}
                                    onChange={(e) => { setProduct({ ...product, molecules: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel>Manufacture <b style={{ color: "red" }}> *</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder="DEFAULT"
                                    value={product.manufacture}
                                    onChange={(e) => { setProduct({ ...product, manufacture: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel >Business Location</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    styles={colourStyles}
                                    placeholder={setngs ? setngs.businesslocation : ""}
                                    options={busilocations}
                                    onChange={(e) => { setProduct({ ...product, businesslocation: e.name }); }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel >HSN CODE</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                   
                                 
                                    value={product.hsn}
                                    onChange={(e) => { setProduct({ ...product, hsn: e.target.value, hsncode: e.target.value }) }}
                                >
                                    {hsnGrp && (
                                        hsnGrp.map((row, index) => (
                                            <MenuItem value={row.hsn} key={index}>{row.hsn}</MenuItem>
                                        )))}
                                </Select>
                            </FormControl>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox  onChange={(e) => { setProduct({ ...product, hsncodeCheck: !product.hsncodeCheck }) }}  checked={Boolean(product.hsncodeCheck)} />} label="Enable tax with HSN code" sx={{ fontSize: '10px' }} />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel >Unit Desc</InputLabel>
                            <FormControl size="small" fullWidth >

                                <Selects
                                    styles={colourStyles}
                                    options={units}
                                    placeholder={setngs ? setngs.defaulunit : ""}
                                    onChange={(e) => { setProduct({ ...product, unit: e.unit }); }}
                                ></Selects>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel >Rack</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.rack}
                                    onChange={(e) => { setProduct({ ...product, rack: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel >Row</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                  
                                    value={product.row}
                                    onChange={(e) => { setProduct({ ...product, row: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Box No</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.boxno}
                                    onChange={(e) => { setProduct({ ...product, boxno: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <FormGroup>
                                <span><FormControlLabel control={<Checkbox   onChange={(e) => { setProduct({ ...product, managestock: !product.managestock }) }}  checked={Boolean(product.managestock)}  />} label="Manage Stock" />
                                    <Tooltip title="Enable or disable stock management for a product. you can set the min max quantity alert for the product and default minimum maximum value added in settings." placement="top" arrow>
                                        <IconButton size="small">
                                            <FcInfo />
                                        </IconButton>
                                    </Tooltip>
                                </span>
                            </FormGroup>
                        </Grid>
                        {product.managestock && (
                            <>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <InputLabel >Minimum Quantity</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <OutlinedInput
                                            value={product.minquantity}
                                            onChange={(e) => { setProduct({ ...product, minquantity: e.target.value }) }}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <InputLabel >Maximum Quantity</InputLabel>
                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <OutlinedInput
                                            value={product.maxquantity}
                                            onChange={(e) => { setProduct({ ...product, maxquantity: e.target.value }) }}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Re-Order Level</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    type="number"
                                    sx={userStyle.input}
                                    value={product.reorderlevel}
                                    onChange={(e) => { setProduct({ ...product, reorderlevel: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            {product.hsncodeCheck ?
                                <Grid item md={4} sm={4} xs={12} sx={{ minWidth: "100%" }}>
                                    <InputLabel >HSN code</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="date"
                                            type="text"
                                            size='small'
                                            placeholder='HSN code'
                                            value={product.hsncode}
                                        />
                                    </FormControl>
                                </Grid>
                                :
                                <Grid item md={4} sm={4} xs={12} sx={{ minWidth: "100%" }}>
                                    <InputLabel >Applicable tax</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <Selects
                                            styles={colourStyles}
                                            options={taxrates}
                                            placeholder={setngs ? setngs.applicabletax : ""}
                                            onChange={(e) => { setProduct({ ...product, applicabletax: e.taxname }); }}
                                        >
                                        </Selects>
                                    </FormControl>
                                </Grid>
                            }
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel >Selling price tax type</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    options={taxtypes}
                                    placeholder={setngs ? setngs.sellingpricetax : ""}
                                    styles={colourStyles}
                                    onChange={(e) => { setProduct({ ...product, selltaxtype: e.type }); }}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid container sx={userStyle.gridcontainer}>
                        <Grid >
                            <Link to="/product/product/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                            <Button sx={userStyle.buttonadd} type="submit" onClick={handleotherSubmit}>Save And Add Another</Button>
                            <Button sx={userStyle.buttonadd} type="submit" onClick={handleSubmit}>Save</Button>
                        </Grid>
                    </Grid>
                </Box>
                {/* content end */}
            </form>
            <br /> <br />
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h6" >{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function Productcreate() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Productcreatelist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Productcreate;