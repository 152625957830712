import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormGroup, FormControl, FormControlLabel, Paper, Button, Grid, Typography, } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import Selects from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import { SERVICE } from "../../services/Baseservice";
import { AuthContext, UserRoleAccessContext } from '../../context/Appcontext';

function Nonmovingstockreportlist() {

  const { auth } = useContext(AuthContext);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const [isSelect, setIsSelect] = useState(false)
  const [categories, setCategories] = useState();
  const [isCategory, setIsCategory] = useState({categoryvalue:""});
  const [products, setProducts] = useState([]);
  const [isProducts, setIsProducts] = useState([]);

   // Categorys
   const fetchCategory = async () => {
    try {
        let response = await axios.get(SERVICE.CATEGORIES,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
              },
        });
        setCategories(
            response.data.categories?.map((d) => ({
                
                ...d,
                label: d.categoryname,
                value: d.categoryname,
            }))
        );
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
};

//fetch all products
const fetchProducts = async () => {
  try {
      let res_products = await axios.get(SERVICE.PRODUCT,{
        headers: {
            'Authorization': `Bearer ${auth.APIToken}`
          },
      });
      setProducts(res_products.data.products);
  } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
  }
};


const handleFetchProduct = () =>{

    if(isSelect){
      setIsProducts(products);
    }else{
      let get_all_purchase = products.filter((item, i)=>{
        if(isCategory.categoryvalue == item.category){
          return item
        }
      })
      setIsProducts(get_all_purchase);
    }
}

const handleSelectChange = (value) =>{
  console.log(value)

  // if(true)
}

useEffect(
  ()=>{
    fetchCategory();
    fetchProducts();
  },[]
)

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])


  return (

      <Box>
        <Headtitle title={'Stock update'} />
        <Typography sx={userStyle.HeaderText}>Item Wise Stock Report</Typography>
        <Box sx={userStyle.container} >
          <Grid container spacing={1}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Grid sx={{display:'flex'}}>
                <Typography sx={{ marginTop: 1 }}>Category:</Typography>
                <FormControl size="small" fullWidth >
                  <Selects
                    id="component-outlined"
                    sx={userStyle.textInput}
                    options={categories}
                    onChange={(e) => {setIsCategory({...isCategory, categoryvalue:e.categoryname}); setIsSelect(false)}}
                  />
                </FormControl>
                </Grid>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <FormGroup>
                <FormControlLabel  control={<Checkbox checked={isSelect} onClick={(e)=> {setIsSelect(!isSelect); handleSelectChange(e);} }  />} label="Select All" />
              </FormGroup>
            </Grid>
            <Grid item lg={1} md={1} sm={1} xs={1}></Grid>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <Button variant='contained' color='secondary' onClick={handleFetchProduct}>Generate</Button>
            </Grid>
          </Grid>
        </Box><br />
        {/* header text */}
        {/* content start */}
        <Box sx={userStyle.container}>
          { /* ******************************************************EXPORT Buttons****************************************************** */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].excelnonmovingstockreport && (
                <>
                  <ExportCSV />
                </>
              )} 
              {isUserRoleCompare[0].csvnonmovingstockreport && (
                <>
                  <ExportXL />
                </>
               )}
              {isUserRoleCompare[0].printnonmovingstockreport && (
                <>
                  <Button sx={userStyle.buttongrp} >&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfnonmovingstockreport && ( 
                <>
                  <Button sx={userStyle.buttongrp} ><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
               )} 
            </Grid>
          </Grid><br />
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700 }} aria-label="customized table" id="usertable" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell>Item Name</StyledTableCell>
                    <StyledTableCell >Stock in Hand(pices)</StyledTableCell>
                    <StyledTableCell >Purchase Rate</StyledTableCell>
                    <StyledTableCell >Sales Rate</StyledTableCell>
                    <StyledTableCell >MRP Rate</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {isProducts.length > 0 ? (
                    isProducts.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.prodcode}</StyledTableCell>
                          <StyledTableCell>{row.prodname}</StyledTableCell>
                          <StyledTableCell>{row.currentstock}</StyledTableCell>
                          <StyledTableCell>{row.pruchaseincludetax ? row.pruchaseincludetax : row.purchaseexcludetax}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={8}>No data recored found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
  );
}


function Nonmovingstockreport() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Nonmovingstockreportlist /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Nonmovingstockreport;
