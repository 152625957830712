import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, FormControlLabel, InputLabel, TextField, Paper, Button, Grid, Typography, } from "@mui/material";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import autoTable from "jspdf-autotable";
import { userStyle, colourStyles } from "../PageStyle";
import Navbar from "../../components/header/Navbar";
import Footer from "../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../components/Table";
import { ExportXL, ExportCSV } from "../Export";
import Headtitle from "../../components/header/Headtitle";
import jsPDF from "jspdf";
import axios from "axios";
import { toast } from "react-toastify";
import $ from "jquery";
import Selects from "react-select";
import Checkbox from "@mui/material/Checkbox";
import { SERVICE } from "../../services/Baseservice";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { UserRoleAccessContext } from "../../context/Appcontext";
import { AuthContext } from '../../../src/context/Appcontext'


function ItemWiseEstimate() {

  const [itemwsieestimate, setItemwsieEstimate] = useState([]);
  const [product, setProduct] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const [checked, setChecked] = useState(true);
  const [code, setCode] = useState([]);

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  let quantityArr = [];
  let arr = [];
  let numArr = [];
  let arrMixed = [];
  let arrSku = [];

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  const fetchProductName = async () => {
    try {
      let req = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setProduct(
        req?.data?.products?.map((data) => ({
          ...data,
          label: data.prodname,
          value: data.prodname,
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const fetchEstimateSelect = async (e) => {
    setChecked(!checked)
    try {
      let res_purchase = await axios.get(SERVICE.ESTIMATE,{
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      if(checked === true){
        let getDatawithFilter = res_purchase.data.estimate.filter((data) => {
          let splittedMonth = data.date;
          let startdatefromsupplier = dateFilter.startdate;
          let enddatefromsupplier = dateFilter.enddate;
          let dateTrim = moment(splittedMonth).utc().format("YYYY-MM-DD");
          return (
            startdatefromsupplier <= dateTrim &&
            enddatefromsupplier + 1 >= dateTrim
          );
        });
        getDatawithFilter.map((item) => {
          item.goods.map((iterator) => {
            quantityArr.push(+iterator.quantity);
            arr.push(iterator.productname);
            numArr.push(+iterator.subtotal);
            arrSku.push(iterator.productid);
          });
        });
        arrMixed = arr.map(function (data, i) {
          return {
            itemname: data,
            itemcode: arrSku[i],
            totalbillamt: numArr[i],
            totlquantity: quantityArr[i],
          };
        });
        const result = [
          ...arrMixed
            .reduce((r, o) => {
              const key = o.itemcode;
              const items =
                r.get(key) ||
                Object.assign({}, o, {
                  totalbillamt: 0,
                  totlquantity: 0,
                });
              items.totalbillamt += o.totalbillamt;
              items.totlquantity += o.totlquantity;
              return r.set(key, items);
            }, new Map())
            .values(),
        ];
        setItemwsieEstimate(result);
      }
      else {
        setItemwsieEstimate([]);
      }
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //purchase fetching
  const fetchEstimate = async () => {
    try {
      let res_purchase = await axios.get(SERVICE.ESTIMATE,{
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = res_purchase.data.estimate.filter((data) => {
        let splittedMonth = data.date;
        let startdatefromsupplier = dateFilter.startdate;
        let enddatefromsupplier = dateFilter.enddate;
        let dateTrim = moment(splittedMonth).utc().format("YYYY-MM-DD");
        return (
          startdatefromsupplier <= dateTrim &&
          enddatefromsupplier + 1 >= dateTrim
        );
      });
      getDatawithFilter.map((item) => {
        item.goods.map((iterator) => {
          quantityArr.push(+iterator.quantity);
          arr.push(iterator.productname);
          numArr.push(+iterator.subtotal);
          arrSku.push(iterator.productid);
        });
      });
      arrMixed = arr.map(function (data, i) {
        return {
          itemname: data,
          itemcode: arrSku[i],
          totalbillamt: numArr[i],
          totlquantity: quantityArr[i],
        };
      });
      const result = [
        ...arrMixed
          .reduce((r, o) => {
            const key = o.itemcode;
            const items =
              r.get(key) ||
              Object.assign({}, o, {
                totalbillamt: 0,
                totlquantity: 0,
              });
            items.totalbillamt += o.totalbillamt;
            items.totlquantity += o.totlquantity;
            return r.set(key, items);
          }, new Map())
          .values(),
      ];
      setItemwsieEstimate(result);
      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = "none";
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: "", searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, "All"],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const fetchEstimateByProdName = async (e) => {
    console.log("E: ",e.value);
    try {
      let res_purchase = await axios.get(SERVICE.ESTIMATE,{
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = res_purchase.data.estimate.filter((data) => {
        let splittedMonth = data.date;
        let startdatefromsupplier = dateFilter.startdate;
        let enddatefromsupplier = dateFilter.enddate;
        let dateTrim = moment(splittedMonth).utc().format("YYYY-MM-DD");
        return (
          startdatefromsupplier <= dateTrim &&
          enddatefromsupplier + 1 >= dateTrim
        );
      });
      getDatawithFilter.map((item) => {
        item.goods.map((iterator) => {
          quantityArr.push(+iterator.quantity);
          arr.push(iterator.productname);
          numArr.push(+iterator.subtotal);
          arrSku.push(iterator.productid);
        });
      });
      arrMixed = arr.map(function (data, i) {
        return {
          itemname: data,
          itemcode: arrSku[i],
          totalbillamt: numArr[i],
          totlquantity: quantityArr[i],
        };
      });
      const result = [
        ...arrMixed
          .reduce((r, o) => {
            const key = o.itemcode;
            const items =
              r.get(key) ||
              Object.assign({}, o, {
                totalbillamt: 0,
                totlquantity: 0,
              });
            items.totalbillamt += o.totalbillamt;
            items.totlquantity += o.totlquantity;
            return r.set(key, items);
          }, new Map())
          .values(),
      ];
      let finalArr = result?.filter(d => {
        if (d.itemname === e.prodname) {
          return d
        }
      })
      setItemwsieEstimate(finalArr)
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }
  const clearSelectAll = (e) => {
    if (e) {
      setItemwsieEstimate([]) || setChecked(true)
    }
    setCode(e.startdate)
}

  // Export Excel
  const fileName = "Item Wise Estimate";

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = itemwsieestimate.map(t => ({
      "Item Code": t.itemcode, "Item Name": t.itemname, "Total Quantity": t.totlquantity, "Total Amount": t.totalbillamt 
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()
  }, [itemwsieestimate])

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#itemwiseestimate" });
    doc.save("Item Wise Estimate.pdf");
  };
  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "HIPOS| ITEM WISE ESTIMATE",
    pageStyle: "print",
  });

  useEffect(() => {
    fetchProductName();
  },[]);

  return (
    <Box>
      <Headtitle title={'Item Wise Estimate'} />
      <Typography sx={userStyle.HeaderText}>Item Wise Estimate</Typography>
      <Box sx={userStyle.filtercontent}>
        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
          <Grid item lg={3} md={3}>
            <Grid sx={{ display: "flex" }}>
              <Grid><Typography sx={{ marginTop: 1 }}>From&ensp;</Typography>  </Grid>
              <FormControl size="small" fullWidth>
                <TextField
                  id="component-outlined"
                  value={dateFilter.startdate}
                  onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value });
                   clearSelectAll(e) 
                  }}
                  type="date"
                  size="small"
                  name="startdate"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3}>
            <Grid sx={{ display: "flex" }}>
              <Grid ><Typography sx={{ marginTop: 1 }}>To&ensp;</Typography>  </Grid>
              <FormControl size="small" fullWidth>
                <TextField
                  id="component-outlined"
                  value={dateFilter.enddate}
                  onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }); }}
                  type="date"
                  size="small"
                  name="enddate"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchEstimate}>Generate</Button>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormControlLabel
              sx={{ marginLeft: "1em" }}
              control={<Checkbox checked={!checked} onChange={(e) => fetchEstimateSelect(e)} />}
              label="Select All"
            />         
           </Grid>
          <Grid item lg={2} md={2}  >
            <FormControl size="small" fullWidth>
              <InputLabel htmlFor="component-outlined"></InputLabel>
              <Selects
                styles={colourStyles}
                id="component-outlined"
                options={product}
                onChange={(e) => {fetchEstimateByProdName(e);  setChecked(false);}}
                placeholder="Product Name"
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <br />
      {/* content start */}
      <Box sx={userStyle.container}>
        {/* ******************************************************EXPORT Buttons****************************************************** */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid>
            {isUserRoleCompare[0].csvitemwiseestimate && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelitemwiseestimate && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printitemwiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}> &ensp; <FaPrint />  &ensp;Print&ensp;    </Button>
              </>
            )}
            {isUserRoleCompare[0].pdfitemwiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}>
                  <FaFilePdf />
                  &ensp;Export to PDF&ensp;
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <br />
        <Box>
          <TableContainer component={Paper}>
            <Table id="itemwiseestimate" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Item Code</StyledTableCell>
                  <StyledTableCell>Item Name</StyledTableCell>
                  <StyledTableCell>Total Quantity</StyledTableCell>
                  <StyledTableCell>Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {itemwsieestimate &&
                  (itemwsieestimate?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.itemcode}</StyledTableCell>
                      <StyledTableCell>{row.itemname}</StyledTableCell>
                      <StyledTableCell>{row.totlquantity}</StyledTableCell>
                      <StyledTableCell>{row.totalbillamt}</StyledTableCell>
                    </StyledTableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* Print Layout */}
        <TableContainer component={Paper} sx={userStyle.printcls}>
          <Table id="itemwiseestimate" ref={componentRef}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Item Code</StyledTableCell>
                <StyledTableCell>Item Name</StyledTableCell>
                <StyledTableCell>Total Quantity</StyledTableCell>
                <StyledTableCell>Total Amount</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody align="left">
              {itemwsieestimate &&
                (itemwsieestimate?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.itemcode}</StyledTableCell>
                    <StyledTableCell>{row.itemname}</StyledTableCell>
                    <StyledTableCell>{row.totlquantity}</StyledTableCell>
                    <StyledTableCell>{row.totalbillamt}</StyledTableCell>
                  </StyledTableRow>
                )))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* content end */}
    </Box>
  );
}

function Itemsalesestimate() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <ItemWiseEstimate /> <br />  <br /> <br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Itemsalesestimate;