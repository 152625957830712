import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import { toast } from 'react-toastify';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import moment from "moment";
import { useReactToPrint } from "react-to-print";

function Manufacturewise() {

  const [manufacturewise, setManufacturewise] = useState([]);
  const [exceldata,setExceldata] = useState([]);

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  let manArr = []
  let totArr = []
  let quantityArr = []
  let arrMixed = []

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  //  Fetch Manufacture Wise Sales Data
  const fetchmanufacturewise = async () => {
    try {
      let request = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let manufacturewisefetch = request.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
      })
      //date filter datas    
      manufacturewisefetch != "" ? 
      manufacturewisefetch.map(item => {
        item.goods.map(value => {
          manArr.push(value.manufacture)
          totArr.push(value.subtotal)
          quantityArr.push(value.quantity)
        })
        //all arrays
        arrMixed = manArr.map(function (data, i) {
          return { manufacture: data, totalbillamt: totArr[i], totlquantity: quantityArr[i], };
        });
        //calculation for total / quantity
        const result = [...arrMixed.reduce((r, o) => {
          const key = o.manufacture;
          const items = r.get(key) || Object.assign({}, o, {
            totalbillamt: 0,
            totlquantity: 0
          });
          items.totalbillamt += o.totalbillamt
          items.totlquantity += o.totlquantity
          return r.set(key, items);
        }, new Map).values()];
        setManufacturewise(result);

      }) : setManufacturewise([])
      
      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Export Excel
  const fileName = 'Manufacture Wise Sales'

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = manufacturewise.map(t => ({
      Manufacture: t.manufacture, "Total Quantity": t?.totlquantity, "Total Amount": t.totalbillamt
    }));
    setExceldata(data);
  }
  
  useEffect(() => {
    getexcelDatas();
  },[manufacturewise])

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#manufacturewisesales' })
    doc.save('Manufacture Wise Sales.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | MANUFACTURE WISE SALES ',
    pageStyle: 'print'
  });

  return (
    <Box>
      <Headtitle title={'Manufacture Wise Sales'} />
      <Typography sx={userStyle.HeaderText}>Manufacture Wise Sales</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchmanufacturewise}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvmanufacturewisesales && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].excelmanufacturewisesales && (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printmanufacturewisesales && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfmanufacturewisesales && (<Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="manufacturewisesales" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Manufacture</StyledTableCell>
                  <StyledTableCell >Total Quantity</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {manufacturewise &&
                 (manufacturewise.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.manufacture}</StyledTableCell>
                      <StyledTableCell >{row?.totlquantity}</StyledTableCell>
                      <StyledTableCell >{row?.totalbillamt}</StyledTableCell>
                    </StyledTableRow>
                )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="manufacturewisesalesreturn" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Manufacture</StyledTableCell>
              <StyledTableCell >Total Quantity</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {manufacturewise &&
             (manufacturewise.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell >{row.manufacture}</StyledTableCell>
                  <StyledTableCell >{row?.totlquantity}</StyledTableCell>
                  <StyledTableCell >{row?.totalbillamt}</StyledTableCell>
                </StyledTableRow>
            )))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Manufacturewisesales() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Manufacturewise /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Manufacturewisesales;