import React, { useState, useEffect, useRef, useContext } from "react";
import { userStyle } from "../../PageStyle";
import { Box, Grid, Tabs, Tab, FormControl, InputLabel, OutlinedInput, Paper, Select, MenuItem, TextField, TableCell, Typography, Button, Table, TableContainer, TableHead, TableRow, TableBody, DialogActions, DialogContent, Dialog, DialogTitle, Card, } from "@mui/material";
import { FaClock, FaMoneyBillAlt, FaRegWindowClose } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import Selects from "react-select";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { toast } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Mainlogo from "../../../assets/images/mainlogo.png";
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Headtitle from '../../../components/header/Headtitle';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import moment from 'moment';


// recent transction popup tab
// Tabpanel
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Estimatecreate = () => {

    const [customers, setCustomers] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [products, setProducts] = useState([]);
    const [businesslocation, setBusinesslocation] = useState([]);
    const [estimate, setEstimate] = useState([]);
    const [discounts, setDiscounts] = useState("");
    const [drafts, setDrafts] = useState([]);
    const [customer, setCustomer] = useState({});
    const [quotations, setQuotations] = useState();
    const [billDateTime, setBillDateTime] = useState(dayjs());
    const [setngs, setSetngs] = useState();
    const { auth } = useContext(AuthContext)

    // Access
    const { isUserRoleAccess } = useContext(UserRoleAccessContext);

    // estimate inside products array data
    const productInputs = {
        productid: "", productname: "", quantity: "",purchasevalue:0, sellingpriceunitcost:0, sellingpricetax: "", taxtareval: "", subtotal: "", sellingexcludevalue: "", sellingincludevalue: "", applicabletax: "", discountamt: 0, purchaseexcludetax: "", productcgst: 0, productigst: 0, productsgst: 0, batch:"",
        
    }
    // estimate db store data 
    const [estimateAdd, setEstimateAdd] = useState({
        referenceno: "", customer: "Walk-in-customer", contactnumber: "", date: "", counter: "", location: "", contactid: "", creditlimit: "",
        ledgerbalance: 0, totalitems: 0, totalproducts: 0, grandtotal: 0, shippingtax: 0, granddistype: "None", gdiscountvalue: 0, gdiscountamtvalue: 0, aftergranddisctotal: 0,
        creditdays: 0, totalbillamt: 0, ordertax: 0, packcharge: 0, roundof: 0, amountgain: 0, balance: 0, userbyadd: "", doctorname: "", patientname: "", mobile: "", address: "", diagnosis: "",
        paymentmethod: "", dueamount: 0, customerpaidamount: 0, duedate: "", customerledgerid: ""
    });

    //card popup
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => { setIsDeleteOpen(true); };
    const handleClickClose = () => { setIsDeleteOpen(false); };

    // Show Ledger Alert
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const alertOpen = () => { setIsErrorOpen(true); };
    const alertClose = () => { setIsErrorOpen(false); };

    // Amount gain alter Alert
    const [isAmountgain, setisAmountgain] = useState("")

    //  Recent Transactions Modal
    const [recentTranMod, setRecentTranMod] = useState(false);
    const recentTranModOpen = () => { setRecentTranMod(true) }
    const recentTranModClose = () => { setRecentTranMod(false) }

    // TABS
    const [valueMod, setValueMod] = useState(0);
    const handleChangeMod = (event, newValue) => {
        setValueMod(newValue);
    };

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | INVOICE',
        pageStyle: 'print'
    });

    //fetch all locations
    const fetchLocation = async (settingslocation) => {
        try {
            var response = await axios.get(SERVICE.BUSINESS_LOCATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });

            var setngresult = response.data.busilocations.filter((item) => {
                if (item.name == settingslocation.businessname) {
                    return item
                }
            })
            setBusinesslocation(setngresult);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages)
        }
    }

    // get all product data
    const fetchHandleProduct = async () => {
        try {
            var response = await axios.get(SERVICE.PRODUCT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setProductsList(
                response.data.products?.map((d) => ({
                    ...d,
                    label: d.prodname + "-" + d.prodcode,
                    value: d.prodname,
                }))
            );
            setProducts(response.data.products);
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages)
            alertOpen();
        }
    }

    // get all customer data from select option for customer
    const fetchCustomers = async () => {
        try {
            let customers = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomers(
                customers?.data?.customers?.map((d) => ({
                    ...d,

                    label: d.name + "-" + d.cusid,
                    value: d.name,

                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // get all discount 
    const fetchDiscounts = async () => {
        try {
            let res = await axios.get(SERVICE.DISCOUNT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDiscounts(res.data.discounts);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // get settings data
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSetngs(response.data.settingid);
            await fetchLocation(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Select particular customer id to get credit limit 
    const searchCus = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.CUSTOMER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomer(res.data.scustomer);
            setEstimateAdd({ ...estimateAdd, customer: res.data.scustomer.name, contactnumber: res.data.scustomer.whatsapp == undefined ? 0 : res.data.scustomer.whatsapp, location: res.data.scustomer.city == undefined ? "" : res.data.scustomer.city, mobile: res.data.scustomer.whatsapp == undefined ? 0 : res.data.scustomer.whatsapp, address: res.data.scustomer.address == undefined ? "" : res.data.scustomer.address, creditlimit: res.data.scustomer.creditlimit == undefined ? 0 : res.data.scustomer.creditlimit, ledgerbalance: res.data.scustomer.ledgerbalance == undefined ? 0 : customer.ledgerbalance, contactid: res.data.scustomer.cusid == undefined ? "" : res.data.scustomer.cusid, customerledgerid: res.data.scustomer._id == undefined ? "" : res.data.scustomer._id, creditdays: res.data.scustomer.creditdays == undefined ? 0 : res.data.scustomer.creditdays });
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // fetch estimate for recent transction
    const fetchestimate = async () => {
        try {
            let req = await axios.get(SERVICE.ESTIMATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setEstimate(req.data.estimate);

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // fetch quotation for recent quotation
    const fetchQot = async () => {
        try {
            let req = await axios.get(SERVICE.ESTIMATE_QUOTATIONS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setQuotations(req.data.quotations);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // product onchange table data calculation
    const fetchDataProd = (e) => {
        //get particular discount for prticular product
        let discAmtData = discounts?.filter((item) => {
            if (e.prodcode == item.productid) {
                return item
            }
        })
        //is product already added include quantity +1
        let isAlreadyAdded = false;
        let addQuantity = tableData.map((item) => {
            if (e.prodcode == item.productid) {
                isAlreadyAdded = true
                return { ...item, quantity: item.quantity + 1, subtotal: ((Number(item.sellingincludevalue) - Number(item.discountamt)) * Number(item.quantity + 1)) }
            } else {
                return item
            }
        })
        if (isAlreadyAdded) {
            setTableData(addQuantity)
        } else {
            setTableData((tableData) => {
                return [...tableData, {
                    ...productInputs,
                    productid: e.prodcode,
                    productname:
                        e.prodname,
                    quantity: 1,
                    batch:e.batch,
                    sellingpriceunitcost:e.sellingpriceunitcost,
                    purchasevalue:e.selltaxtype == 'Inclusive' ? e.pruchaseincludetax : e.purchaseexcludetax,
                    category: e.category,
                    molecules: e.molecules,
                    manufacture: e.manufacture,
                    schedule: e.schedule,
                    sellingpricetax: e.selltaxtype,
                    sellingexcludevalue: e.sellingpriceunitcost,
                    sellingincludevalue: e.sellingpriceunitcost,
                    applicabletax: e.hsncode || e.applicabletax,
                    discountamt: discAmtData[0] ? discAmtData[0].discountamt : 0,
                    subtotal: Number(e.selltaxtype == 'Inclusive' ? Number(e.sellingpriceunitcost) : ((Number(e.sellingpriceunitcost)) - Number(discAmtData[0] ? Number(discAmtData[0].discountamt) : Number(0)))),
                }]
            });

        }


    }
    useEffect(
        () => {
            grandtotalCalc();
        }, [tableData, estimateAdd.gdiscountvalue, estimateAdd.granddistype]
    )

    //phone number onchange didn't accept more than 10 characters
    const handleChangeNumber = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            alertOpen();
            let num = e.slice(0, 10);
            setEstimateAdd({ ...estimateAdd, contactnumber: num })
        }
    }

    // grand total calculation
    const grandtotalCalc = () => {

        let totalgrandValue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                totalgrandValue += Number(value.subtotal)
            })
            if (estimateAdd.granddistype == "Fixed" || estimateAdd.granddistype == "Amount") {
                setEstimateAdd(
                    {
                        ...estimateAdd, gdiscountamtvalue: estimateAdd.gdiscountvalue, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue) - Number(estimateAdd.gdiscountvalue))
                    })
            }
            else if (estimateAdd.granddistype == "Percentage") {
                let percentCalc = Number(totalgrandValue) * (Number(estimateAdd.gdiscountvalue) / 100);
                setEstimateAdd(
                    {
                        ...estimateAdd, gdiscountamtvalue: percentCalc, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue) - percentCalc)
                    });
            }
            else {
                setEstimateAdd(
                    {
                        ...estimateAdd, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue))
                    }
                )
            }
        }
    }
    // total qunatity calculation
    const totalQuantityCalc = () => {
        let totalquanvalue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                totalquanvalue += Number(value.quantity)
            })
            return totalquanvalue.toFixed(0);
        }
    }

    let tempTotal = 0;

    // delete table data after data fetchparticular row
    const deleteRow = (i, e) => {
        setTableData(tableData.filter((e, item) => item !== i));
        tableData.splice(i, 1);
        if (tableData.length > 0) {
            tempTotal = 0
        }
    }

    // exit screen and re exit screen
    const fulscren = document.getElementById("fullScreen")

    function openFullscreen() {
        if (fulscren.requestFullscreen) {
            fulscren.requestFullscreen();
        } else if (fulscren.webkitRequestFullscreen) {
            fulscren.webkitRequestFullscreen();
        } else if (fulscren.msRequestFullscreen) {
            fulscren.msRequestFullscreen();
        }
    }


    // paynow button popup
    const [isPay, setIsPay] = useState(false);
    const handleClickOpenpay = () => {
        {
            if (estimateAdd.customer == "" || estimateAdd.customer == undefined) {
                setEstimateAdd({ ...estimateAdd, customer: 'Walk-in-customer' });
            }
            if (tableData.length == 0) {
                setShowAlert("Please select any one of product details!");
                alertOpen();
            } else {
                setIsPay(true);
            }
        };
    }
    const handleClosepay = () => { setIsPay(false); };

    let clearvalall = [];

    const handleSubmitclear = (e) => {
        setEstimateAdd({
            referenceno: "", customer: "Walk-in-customer", contactnumber: "", date: "", counter: "", location: "", contactid: "", creditlimit: "",
            ledgerbalance: 0, totalitems: 0, totalproducts: 0, grandtotal: 0, shippingtax: 0, granddistype: "None", gdiscountvalue: 0, gdiscountamtvalue: 0, aftergranddisctotal: 0,
            creditdays: 0, totalbillamt: 0, ordertax: 0, packcharge: 0, roundof: 0, amountgain: 0, balance: 0, userbyadd: "", doctorname: "", patientname: "", mobile: "", address: "", diagnosis: "",
            paymentmethod: "", dueamount: 0, customerpaidamount: 0, duedate: "", customerledgerid: ""
        });
        setTableData(clearvalall);
        setCustomer({ name: "", mobileno: "", address: "" });
    };

    // fetch draft for recent draft
    const fetchDraft = async () => {
        try {
            let draft = await axios.get(SERVICE.ESTIMATE_DRAFTS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let res = draft.data.drafts;
            setDrafts(res);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchDraft();
        fetchestimate();
        fetchQot();

    }, []);

    useEffect(() => {
        fetchCustomers();
        fetchSettings();
        fetchDiscounts();
        fetchHandleProduct();
    }, []);

    // estimate/draft/quotation auto id
    let newvalestimate = setngs ? setngs.estimatesku + "0001" : "EST001";
    let newvaldraft = setngs ? setngs.edraftsku + "0001" : "DRA0001";
    let newvalquot = setngs ? setngs.equotationsku + "0001" : "QUO0001";


    // Auto id for estimate
    {
        {
            estimate && (
                estimate.map(
                    () => {
                        let strings = setngs ? setngs.estimatesku : "EST";
                        let refNo = estimate[estimate.length - 1].referenceno;
                        let digits = (estimate.length + 1).toString();
                        const stringLength = refNo.length;
                        let lastChar = refNo.charAt(stringLength - 1);
                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                        let lastBeforeChar = refNo.slice(-2);
                        let lastThreeChar = refNo.slice(-3);
                        let lastDigit = refNo.slice(-4);
                        let refNOINC = parseInt(lastChar) + 1
                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                        let refLstThree = parseInt(lastThreeChar) + 1;
                        let refLstDigit = parseInt(lastDigit) + 1;
                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                            refNOINC = ("000" + refNOINC);
                            newvalestimate = strings + refNOINC;
                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                            refNOINC = ("00" + refLstTwo);
                            newvalestimate = strings + refNOINC;
                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                            refNOINC = ("0" + refLstThree);
                            newvalestimate = strings + refNOINC;
                        } else {
                            refNOINC = (refLstDigit);
                            newvalestimate = strings + refNOINC;
                        }

                    }))
        }
    }

    // Auto id for draft
    {
        drafts && (
            drafts?.map(
                () => {
                    let strings = setngs ? setngs.edraftsku : "DRA";
                    let refNo = drafts[drafts.length - 1].referenceno;
                    let digits = (drafts.length + 1).toString();
                    const stringLength = refNo.length;
                    let lastChar = refNo.charAt(stringLength - 1);
                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                    let lastBeforeChar = refNo.slice(-2);
                    let lastThreeChar = refNo.slice(-3);
                    let lastDigit = refNo.slice(-4);
                    let refNOINC = parseInt(lastChar) + 1
                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                    let refLstThree = parseInt(lastThreeChar) + 1;
                    let refLstDigit = parseInt(lastDigit) + 1;
                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                        refNOINC = ("000" + refNOINC).substr(-4);
                        newvaldraft = strings + refNOINC;
                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                        refNOINC = ("00" + refLstTwo).substr(-4);
                        newvaldraft = strings + refNOINC;
                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                        refNOINC = ("0" + refLstThree).substr(-4);
                        newvaldraft = strings + refNOINC;
                    } else {
                        refNOINC = (refLstDigit).substr(-4);
                        newvaldraft = strings + refNOINC;
                    }
                }))
    }

    // Auto id for quotation
    {
        quotations && (
            quotations.map(
                () => {
                    let strings = setngs ? setngs.equotationsku : "QUO";
                    let refNo = quotations[quotations.length - 1].referenceno;
                    let digits = (quotations.length + 1).toString();
                    const stringLength = refNo.length;
                    let lastChar = refNo.charAt(stringLength - 1);
                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                    let lastBeforeChar = refNo.slice(-2);
                    let lastThreeChar = refNo.slice(-3);
                    let lastDigit = refNo.slice(-4);
                    let refNOINC = parseInt(lastChar) + 1
                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                    let refLstThree = parseInt(lastThreeChar) + 1;
                    let refLstDigit = parseInt(lastDigit) + 1;
                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                        refNOINC = ("000" + refNOINC).substr(-4);
                        newvalquot = strings + refNOINC;
                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                        refNOINC = ("00" + refLstTwo).substr(-4);
                        newvalquot = strings + refNOINC;
                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                        refNOINC = ("0" + refLstThree).substr(-4);
                        newvalquot = strings + refNOINC;
                    } else {
                        refNOINC = (refLstDigit).substr(-4);
                        newvalquot = strings + refNOINC;
                    }
                }))
    }

    // save estimate data to db cash
    const sendRequest = async () => {

        // reduce Current Stock in product
        tableData.map((item, index) => {
            products.forEach((data, i) => {
                if (item.productid == data.prodcode) {
                    axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                        currentstock: Number(data.currentstock) - Number(item.quantity),
                    });
                }
            })
        })

        var futureDate = new Date(new Date().setDate(new Date(billDateTime).getDate() + estimateAdd.creditdays));
        try {
            await axios.post(SERVICE.ESTIMATE_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: newvalestimate,
                customer: String(estimateAdd.customer),
                location: String(estimateAdd.location),
                contactnumber: Number(estimateAdd.contactnumber),
                mobileno: Number(estimateAdd.mobile),
                date: String(billDateTime),
                counter: String(isUserRoleAccess.counter),
                dueamount: Number((Number(estimateAdd.aftergranddisctotal) - Number(estimateAdd.amountgain)) > 0 ? (Number(estimateAdd.aftergranddisctotal) - Number(estimateAdd.amountgain)) : (Number(estimateAdd.aftergranddisctotal) == Number(estimateAdd.amountgain)) ? 0 : 0),
                customerpaidamount: Number(estimateAdd.balance == 0 ? Number(estimateAdd.amountgain) : (Number(estimateAdd.aftergranddisctotal) - Number(estimateAdd.balance))),
                duedate: String(estimateAdd.creditdays > 0 ? moment(new Date()).format("DD-MM-YYYY") : moment(futureDate).utc().format('DD-MM-YYYY')),
                creditlimit: Number(estimateAdd.creditlimit),
                ledgerbalance: Number(estimateAdd.ledgerbalance),
                creditdays: Number(estimateAdd.creditdays),
                goods: [...tableData],
                totalitems: Number(tableData.length),
                totalproducts: Number(totalQuantityCalc()),
                grandtotal: Number(estimateAdd.grandtotal),
                ordertax: Number(estimateAdd.ordertax),
                shippingtax: Number(estimateAdd.shippingtax),
                packcharge: Number(estimateAdd.packcharge),
                granddistype: String(estimateAdd.granddistype),
                gdiscountvalue: Number(estimateAdd.gdiscountvalue),
                gdiscountamtvalue: Number(estimateAdd.gdiscountamtvalue),
                aftergranddisctotal: Number(estimateAdd.aftergranddisctotal),
                roundof: Number(estimateAdd.aftergranddisctotal),
                amountgain: Number(estimateAdd.amountgain),
                balance: Number((Number(estimateAdd.aftergranddisctotal) < Number(estimateAdd.amountgain)) ? (Number(estimateAdd.amountgain) - Number(estimateAdd.aftergranddisctotal)) : (Number(estimateAdd.aftergranddisctotal) == Number(estimateAdd.amountgain)) ? 0 : (Number(estimateAdd.aftergranddisctotal) > Number(estimateAdd.amountgain)) ? (Number(estimateAdd.aftergranddisctotal) - Number(estimateAdd.amountgain)) : 0),
                userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),
                contactid: String(estimateAdd.contactid),
                doctorname: String(estimateAdd.doctorname),
                address: String(estimateAdd.address),
                diagnosis: String(estimateAdd.diagnosis),
                paymentmethod: String("Cash"),
                customerledgerid: String(estimateAdd.customerledgerid),
                activate: Boolean(customer.activate ? customer.activate : false)
            });
            handleprint();
            handleClosepay();
            handleSubmitclear()
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            alertOpen();
        }
    };

    const cardCustomerLedgerbalance = () => {
        if (estimateAdd.customer == "" || estimateAdd.customer == undefined || estimateAdd.customer == "Walk-in-customer") {
            setShowAlert("Please select customer name!");
            alertOpen();
        } else if (tableData.length == 0) {
            setShowAlert("Please select any one of product details!");
            alertOpen();
        } else {
            if (estimateAdd.customer == "" || estimateAdd.customer == undefined) {
                setEstimateAdd({ ...estimateAdd, customer: 'Walk-in-customer' })
            }

            if ((!estimateAdd.customer == "") || (!estimateAdd.customer == "Walk-in-customer")) {
                if ((customer.creditlimit <= customer.ledgerbalance)) {
                    setShowAlert("Check ledger balance!")
                    alertOpen();
                } else {
                    handleCreditSubmit();
                }
            } else {
                handleCreditSubmit();
            }
        }
    }

    // save estimate card to db for ledger balance calculation
    const sendRequestCredit = async () => {

        // reduce Current Stock in product
        tableData.map((item, index) => {
            products.forEach((data, i) => {
                if (item.productid == data.prodcode) {
                    axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                        currentstock: Number(data.currentstock) - Number(item.quantity),
                    });
                }
            })
        })

        //update ledger balance in customer
        axios.put(`${SERVICE.CUSTOMER_SINGLE}/${customer._id}`, {
            ledgerbalance: Number(customer.ledgerbalance) + Number(estimateAdd.aftergranddisctotal.toFixed(0))
        })


        try {
            let PRODUCT_REQ = await axios.post(SERVICE.ESTIMATE_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: newvalestimate,
                customer: String(estimateAdd.customer),
                location: String(estimateAdd.location),
                contactnumber: Number(estimateAdd.contactnumber),
                mobileno: Number(estimateAdd.mobile),
                date: String(billDateTime),
                counter: String(isUserRoleAccess.counter),
                dueamount: Number(0),
                customerpaidamount: Number(estimateAdd.aftergranddisctotal),
                duedate: String(0),
                creditlimit: Number(estimateAdd.creditlimit),
                ledgerbalance: Number(estimateAdd.ledgerbalance),
                creditdays: Number(estimateAdd.creditdays),
                goods: [...tableData],
                totalitems: Number(tableData.length),
                totalproducts: Number(totalQuantityCalc()),
                grandtotal: Number(estimateAdd.grandtotal),
                ordertax: Number(estimateAdd.ordertax),
                shippingtax: Number(estimateAdd.shippingtax),
                packcharge: Number(estimateAdd.packcharge),
                granddistype: String(estimateAdd.granddistype),
                gdiscountvalue: Number(estimateAdd.gdiscountvalue),
                gdiscountamtvalue: Number(estimateAdd.gdiscountamtvalue),
                aftergranddisctotal: Number(estimateAdd.aftergranddisctotal),
                roundof: Number(estimateAdd.aftergranddisctotal),
                amountgain: Number(estimateAdd.aftergranddisctotal),
                balance: Number(estimateAdd.balance),
                userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),
                contactid: String(estimateAdd.contactid),
                doctorname: String(estimateAdd.doctorname),
                address: String(estimateAdd.address),
                diagnosis: String(estimateAdd.diagnosis),
                paymentmethod: String("Credit"),
                customerledgerid: String(estimateAdd.customerledgerid),
                activate: Boolean(customer.activate ? customer.activate : false)
            });
            handleprint();
            handleSubmitclear()
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            alertOpen();
        }
    }


    // store draft data
    const sendDraft = async () => {

        try {
            let PRODUCT_REQ = await axios.post(SERVICE.ESTIMATE_DRAFT_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newvaldraft),
                customer: String(estimateAdd.customer),
                location: String(estimateAdd.location),
                contactnumber: Number(estimateAdd.contactnumber),
                mobileno: Number(estimateAdd.mobile),
                date: String(billDateTime),
                counter: String(isUserRoleAccess.counter),
                dueamount: Number(0),
                customerpaidamount: Number(0),
                duedate: String(0),
                creditlimit: Number(estimateAdd.creditlimit),
                ledgerbalance: Number(estimateAdd.ledgerbalance),
                creditdays: Number(estimateAdd.creditdays),
                goods: [...tableData],
                totalitems: Number(tableData.length),
                totalproducts: Number(totalQuantityCalc()),
                grandtotal: Number(estimateAdd.grandtotal),
                ordertax: Number(estimateAdd.ordertax),
                shippingtax: Number(estimateAdd.shippingtax),
                packcharge: Number(estimateAdd.packcharge),
                granddistype: String(estimateAdd.granddistype),
                gdiscountvalue: Number(estimateAdd.gdiscountvalue),
                gdiscountamtvalue: Number(estimateAdd.gdiscountamtvalue),
                aftergranddisctotal: Number(estimateAdd.aftergranddisctotal),
                roundof: Number(estimateAdd.aftergranddisctotal),
                amountgain: Number(estimateAdd.amountgain),
                balance: Number(estimateAdd.balance),
                userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),
                contactid: String(estimateAdd.contactid),
                doctorname: String(estimateAdd.doctorname),
                address: String(estimateAdd.address),
                diagnosis: String(estimateAdd.diagnosis),
                paymentmethod: String("None"),
                customerledgerid: String(estimateAdd.customerledgerid),
                activate: Boolean(customer.activate ? customer.activate : false)
            });
            toast.success("Successfullty added!");
            handleSubmitclear();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };


    // store quotation data
    const sendQuotation = async () => {

        try {
            let PRODUCT_REQ = await axios.post(SERVICE.ESTIMATE_QUOTATION_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newvalquot),
                customer: String(estimateAdd.customer),
                location: String(estimateAdd.location),
                contactnumber: Number(estimateAdd.contactnumber),
                mobileno: Number(estimateAdd.mobile),
                date: String(billDateTime),
                counter: String(isUserRoleAccess.counter),
                dueamount: Number(0),
                customerpaidamount: Number(0),
                duedate: String(0),
                creditlimit: Number(estimateAdd.creditlimit),
                ledgerbalance: Number(estimateAdd.ledgerbalance),
                creditdays: Number(estimateAdd.creditdays),
                goods: [...tableData],
                totalitems: Number(tableData.length),
                totalproducts: Number(totalQuantityCalc()),
                grandtotal: Number(estimateAdd.grandtotal),
                ordertax: Number(estimateAdd.ordertax),
                shippingtax: Number(estimateAdd.shippingtax),
                packcharge: Number(estimateAdd.packcharge),
                granddistype: String(estimateAdd.granddistype),
                gdiscountvalue: Number(estimateAdd.gdiscountvalue),
                gdiscountamtvalue: Number(estimateAdd.gdiscountamtvalue),
                aftergranddisctotal: Number(estimateAdd.aftergranddisctotal),
                roundof: Number(estimateAdd.aftergranddisctotal),
                amountgain: Number(estimateAdd.amountgain),
                balance: Number(estimateAdd.balance),
                userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),
                contactid: String(estimateAdd.contactid),
                doctorname: String(estimateAdd.doctorname),
                address: String(estimateAdd.address),
                diagnosis: String(estimateAdd.diagnosis),
                paymentmethod: String("None"),
                customerledgerid: String(estimateAdd.customerledgerid),
                activate: Boolean(customer.activate ? customer.activate : false)
            });
            toast.success("Successfullty added!");
            handleSubmitclear();

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };


    const handleEstimateSubmit = (e) => {
        e.preventDefault();
        setEstimateAdd({ ...estimateAdd, referenceno: newvalestimate });

        if (estimateAdd.amountgain == "" || estimateAdd.amountgain == 0) {
            setisAmountgain("please enter amount!");
        } else {
            setisAmountgain("");
            sendRequest();

        }
    }
    const handleCreditSubmit = () => {
        setEstimateAdd({ ...estimateAdd, referenceno: newvalestimate });

        sendRequestCredit();
    }

    const handleSubmitDraft = (e) => {
        setEstimateAdd({ ...estimateAdd, referenceno: newvalquot })
        e.preventDefault();
        if (tableData.length == 0) {
            setShowAlert("Please select any one of product details!");
            alertOpen();
        } else {
            if (estimateAdd.customer == "" || estimateAdd.customer == undefined) {
                setEstimateAdd({ ...estimateAdd, customer: 'Walk-in-customer' });
                sendDraft();
            } else {
                sendDraft();
            }
        }
    };


    const handleSubmitquotation = (e) => {
        setEstimateAdd({ ...estimateAdd, referenceno: newvalquot })
        e.preventDefault();
        if (tableData.length == 0) {
            setShowAlert("Please select any one of product details!");
            alertOpen();
        } else {
            if (estimateAdd.customer == "" || estimateAdd.customer == undefined) {
                setEstimateAdd({ ...estimateAdd, customer: 'Walk-in-customer' });
                sendQuotation();
            } else {
                sendQuotation();
            }
        }
    };

    return (
        <Box id="fullScreen"
            sx={{
                backgroundColor: 'white',
                // color: "red",
                estimateition: "relative",
                overflow: "hidden",
            }}
        >
            <Headtitle title={'Estimate Add'} />
            <form >
                {/* Navbar Start */}
                <Box sx={{ padding: "5px", backgroundColor: "#f0f2ff" }}>
                    <Grid container spacing={1}>
                        <Grid item lg={1} md={1} sm={2} xs={12}>
                            <Box sx={{ float: "left" }}>
                                <Link to="/">
                                    <img src={Mainlogo} alt="logo" style={{ width: '50px', height: '50px' }}></img>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={2} sm={8} xs={11} sx={{ marginTop: "17px" }}>
                            <InputLabel sx={{ marginTop: '-21px' }}>Customer</InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: "38px" }}>
                                    <SearchOutlinedIcon />
                                </Grid>
                                <FormControl size="small" fullWidth>
                                    <Selects
                                        options={customers}
                                        onChange={(e) => {
                                            searchCus(e._id);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={1} xs={1} sx={{ marginTop: "17px" }}>
                            <Button onClick={(e) => { openFullscreen() }}><FullscreenOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{ padding: "5px", margin: '0px' }}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Date </InputLabel>
                            <FormControl size="small" fullWidth sx={userStyle.estimateDateTime}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DateTimePicker
                                        renderInput={(props) => <TextField size="small" {...props} />}
                                        size='small'
                                        sx={userStyle.estimateNavbarInput}
                                        value={billDateTime}
                                        onChange={(newValue) => {
                                            setBillDateTime(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={1} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Counter</InputLabel>
                            <FormControl
                                size="small"
                                fullWidth
                                sx={{ display: "flex" }}
                            >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={isUserRoleAccess.counter}
                                    type="text"
                                    sx={userStyle.estimateNavbarInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Credit Limit </InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: '34px' }}>
                                    <MoneyOutlinedIcon sx={{ marginTop: '-1px' }} />
                                </Grid>
                                <FormControl
                                    size="small"
                                    fullWidth >
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={customer.creditlimit}
                                        onChange={(e) => {
                                            setEstimateAdd({ ...estimateAdd, creditlimit: e.target.value });
                                        }}
                                        type="text"
                                        sx={userStyle.estimateNavbarInput}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Ledger Balance </InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: '34px' }}>
                                    <PersonOutlineOutlinedIcon sx={{ marginTop: '-1px' }} />
                                </Grid>
                                <FormControl
                                    size="small"
                                    fullWidth
                                >
                                    <OutlinedInput
                                        type="text"
                                        value={customer.ledgerbalance}
                                        sx={userStyle.estimateNavbarInput}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {/* Navbar Ends */}
                <Grid container sx={{ backgroundColor: "#f0f2ff", }} >
                    <Grid item xs={12} sm={12} md={8} lg={8} sx={{ paddingRight: '3px', backgroundColor: '#fff' }} >
                        <br />
                        <Grid container spacing={3}>
                            <Grid item md={1} sm={6} xs={12} sx={{ marginLeft: '25px' }}></Grid>
                            <Grid item md={9} sm={6} xs={12} sx={{ marginLeft: '25px' }}>
                                <Grid sx={{ display: "flex" }}>
                                    <Grid sx={userStyle.spanIcons} style={{ height: "38px" }}>
                                        <SearchOutlinedIcon />
                                    </Grid>
                                    <FormControl size="small" fullWidth>
                                        <Selects
                                            options={productsList}
                                            onChange={(e) => {
                                                fetchDataProd(e);
                                                totalQuantityCalc();
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item md={1} sm={6} xs={12} sx={{ marginLeft: '25px' }}></Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                {/* Table start */}
                                <TableContainer
                                    sx={{ paddingLeft: 1, height: '478px' }}
                                >
                                    <Table
                                        aria-label="customized table" padding='none'>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell sx={userStyle.tableHead1} style={{ marginLeft: '5px' }}>  {" "}  Product Name  </TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Qty</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Unit Cost</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Discount value</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Subtotal </TableCell>
                                                <TableCell sx={userStyle.tableHead1}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableData.length > 0 &&
                                                tableData.map((data, i) => {
                                                    return (
                                                        <>
                                                            <TableRow sx={{ height: '16px' }}>
                                                                <TableCell key={i}>{data?.productname}</TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {data?.quantity}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {Math.abs(data?.sellingexcludevalue)}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Grid container>
                                                                        <Grid item lg={8} md={9} sm={9} xs={9}>
                                                                            <Typography>
                                                                                {data?.discountamt}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant='subtitle1'>{data?.subtotal}</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ color: 'red', fontWeight: '900', cursor: 'pointer', fontSize: '15px !important' }}><AiOutlineClose onClick={(e) => { deleteRow(i, e); }} /></TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* Table Ends */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '-24px' }}>
                                <Grid container spacing={1}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <Typography sx={{ marginLeft: '15px' }}>
                                            <b> Total Items :</b>{tableData.length}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={4} xs={12}
                                        sx={{ display: 'flex' }}
                                    >
                                        <FormControl size="small" fullWidth>
                                            <InputLabel> Discount Type </InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                fullWidth
                                                label="Discount Type"
                                                value={estimateAdd.granddistype}
                                                onChange={(e) => {
                                                    setEstimateAdd({ ...estimateAdd, granddistype: e.target.value })
                                                }}
                                            >
                                                <MenuItem value="None">None</MenuItem>
                                                <MenuItem value="Fixed">Fixed</MenuItem>
                                                <MenuItem value="Amount">Amount</MenuItem>
                                                <MenuItem value="Percentage">Percentage</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} sm={4} xs={12}>
                                        <TextField
                                            size="small"
                                            label="Discount Amount"
                                            value={estimateAdd.gdiscountvalue}
                                            onChange={(e) => {
                                                setEstimateAdd({ ...estimateAdd, gdiscountvalue: e.target.value });
                                            }}
                                            type="number"
                                            sx={userStyle.input}
                                        />
                                    </Grid>
                                    <Grid item md={5} sm={4} xs={12} sx={{ paddingLeft: '4px', paddingRight: '1px', marginTop: '-4px' }}>
                                        <Button fullWidth variant="contained" sx={{ marginTop: "5px", }}>
                                            <b>GRAND TOTAL :</b>&ensp;{estimateAdd.grandtotal}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{ p: 1, backgroundColor: '#fff', }}>
                        <Grid container  >
                            <Typography sx={userStyle.HeaderText}>Enter Details</Typography>
                            <Grid container sx={{ padding: '10px' }} spacing={3}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={3}  >
                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                            <Grid container>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <InputLabel id="demo-select-small"> Dr Name </InputLabel>
                                                    <Grid sx={{ display: "flex" }}>
                                                        <Grid sx={userStyle.spanIcons}>
                                                            <MoneyOutlinedIcon />
                                                        </Grid>
                                                        <FormControl
                                                            size="small"
                                                            fullWidth  >
                                                            <OutlinedInput
                                                                id="component-outlined"
                                                                type="text"
                                                                value={estimateAdd.doctorname}
                                                                onChange={(e) => { setEstimateAdd({ ...estimateAdd, doctorname: e.target.value, }) }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <br />
                                                </Grid>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <InputLabel id="demo-select-small">
                                                        Patient Name /Customer
                                                    </InputLabel>
                                                    <Grid sx={{ display: "flex" }}>
                                                        <Grid sx={userStyle.spanIcons}>
                                                            <MoneyOutlinedIcon />
                                                        </Grid>
                                                        <FormControl
                                                            size="small"
                                                            fullWidth
                                                        >
                                                            <OutlinedInput
                                                                id="component-outlined"
                                                                type="text"
                                                                value={estimateAdd.customer}
                                                                onChange={(e) => { setEstimateAdd({ ...estimateAdd, customer: e.target.value }) }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <br />
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Mobile
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="number"
                                                            value={estimateAdd.contactnumber}
                                                            onChange={(e) => { setEstimateAdd({ ...estimateAdd, contactnumber: e.target.value }); handleChangeNumber(e.target.value); }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Address
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="text"
                                                            value={estimateAdd.address}
                                                            onChange={(e) => { setEstimateAdd({ ...estimateAdd, address: e.target.value }) }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Diagnosis
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ display: "flex" }}
                                                    >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="text"
                                                            value={estimateAdd.diagnosis}
                                                            onChange={(e) => { setEstimateAdd({ ...estimateAdd, diagnosis: e.target.value }) }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container sx={userStyle.btnGrid}>
                    <Grid item md={8} sm={8} xs={12} sx={{ display: "flex", color: 'black' }}>
                        <Button disableRipple sx={userStyle.btnBack} type="submit" onClick={handleSubmitDraft}>
                            <EditOutlinedIcon style={{ fontSize: "large" }} />
                            &ensp;Draft
                        </Button>
                        <Button disableRipple sx={userStyle.btnPause} type="submit" onClick={handleSubmitquotation}>
                            <EditOutlinedIcon style={{ fontSize: "large" }} />
                            &ensp;Quotation
                        </Button>
                        <Button disableRipple sx={userStyle.btnCash}
                            onClick={handleClickOpenpay}>
                            <FaMoneyBillAlt />
                            &ensp;Cash
                        </Button>
                        <Button disableRipple sx={userStyle.btnCancel} onClick={handleSubmitclear}>
                            <FaRegWindowClose />
                            &ensp;Cancel
                        </Button>
                        <Button disableRipple sx={userStyle.btnCard} onClick={cardCustomerLedgerbalance}>
                            <FaRegWindowClose />
                            &ensp;Credit
                        </Button>
                        <Typography value={estimateAdd.totalbillamt}
                            sx={{ marginLeft: '15px', color: 'grey', fontSize: "20px" }}>
                            <b>Total:</b> <span style={{ color: 'green' }}>{(estimateAdd.aftergranddisctotal).toFixed(0)}</span>
                        </Typography>
                    </Grid>
                    <Grid item md={4} sm={4} xs={12}>
                        <Box sx={{ float: "right" }}>
                            <Button disableRipple sx={userStyle.btnRec} onClick={recentTranModOpen}>
                                <FaClock />
                                &ensp;Recent Transactions
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            {/*  card details*/}
            <Dialog
                open={isDeleteOpen}
                onClose={handleClickClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '610px', height: '300px' }}>
                    <Typography sx={userStyle.HeaderText}>Enter Card  Details</Typography>
                    <Grid container spacing={1} sx={{ paddingTop: '2em' }}>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <InputLabel htmlFor="component-outlined">Card Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12} >
                            <InputLabel htmlFor="component-outlined">Card Holder Name</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Card Transaction Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} >
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Card Type</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                >
                                    <MenuItem value="Visa" > Visa</MenuItem>
                                    <MenuItem value="Master Card" > Master Card</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Month</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Year</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Security Code</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickClose} variant="outlined">Cancel</Button>
                    <Button autoFocus variant="contained" color='error'> OK </Button>
                </DialogActions>
            </Dialog>
            {/* Cash dialog box */}
            <Dialog
                open={isPay}
                onClose={handleClosepay}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle>
                    <Typography sx={userStyle.HeaderText}>Enter Cash Details</Typography>
                </DialogTitle>
                <DialogContent sx={{ padding: '0px' }}>
                    <Grid container sx={{ padding: '10px' }} spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container spacing={3}  >
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <Grid container>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <InputLabel id="demo-select-small"> Amount </InputLabel>
                                            <Grid sx={{ display: "flex" }}>
                                                <Grid sx={userStyle.spanIcons}>
                                                    <MoneyOutlinedIcon />
                                                </Grid>
                                                <FormControl
                                                    size="small"
                                                    fullWidth
                                                    sx={{ display: "flex" }}
                                                >
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        type="text"
                                                        value={(estimateAdd.aftergranddisctotal).toFixed(0)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <br />
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <p style={{ color: 'red' }}>{isAmountgain}</p>
                                            <InputLabel id="demo-select-small">
                                                Amount Gain
                                            </InputLabel>
                                            <Grid sx={{ display: "flex" }}>
                                                <Grid sx={userStyle.spanIcons}>
                                                    <MoneyOutlinedIcon />
                                                </Grid>
                                                <FormControl
                                                    size="small"
                                                    fullWidth
                                                    sx={{ display: "flex" }}
                                                >
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        type="text"
                                                        value={estimateAdd.amountgain}
                                                        onChange={(e) => { setEstimateAdd({ ...estimateAdd, amountgain: e.target.value, balance: (Number(estimateAdd.aftergranddisctotal) - Number(e.target.value)) }) }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <br />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <InputLabel id="demo-select-small">
                                            Balance
                                        </InputLabel>
                                        <Grid sx={{ display: "flex" }}>
                                            <Grid sx={userStyle.spanIcons}>
                                                <MoneyOutlinedIcon />
                                            </Grid>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                                sx={{ display: "flex" }}
                                            >
                                                <OutlinedInput
                                                    id="component-outlined"
                                                    type="text"
                                                    value={Math.abs(estimateAdd.balance.toFixed(0))}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ paddingLeft: '20px' }}>
                            <Card sx={{ padding: '30px' }}>
                                <TableContainer sx={{ boxShadow: '0 0 15px -2px #444444' }}>
                                    <Table>
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell><b>Total Products :</b></StyledTableCell>
                                                <StyledTableCell>{totalQuantityCalc()}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell> <b>Shipping :</b></StyledTableCell>
                                                <StyledTableCell>Rs.0.00</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell><b>Discount :</b></StyledTableCell>
                                                <StyledTableCell>{estimateAdd.gdiscountamtvalue}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell> <b>Packing Charge :</b></StyledTableCell>
                                                <StyledTableCell>Rs.0.00</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell> <b>Round off :</b></StyledTableCell>
                                                <StyledTableCell>{(estimateAdd.aftergranddisctotal).toFixed(0)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell><b>Grand Total :</b></StyledTableCell>
                                                <StyledTableCell>{(estimateAdd.aftergranddisctotal).toFixed(0)}</StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosepay} variant="outlined">Cancel</Button>
                    <Button autoFocus variant="contained" color='primary' type="submit" onClick={(e) => { handleEstimateSubmit(e) }}> Print </Button>
                </DialogActions>
            </Dialog>
            {/* Cash Dialog Ends */}
            {/* Recent Transactions Modal Start */}
            <Dialog
                open={recentTranMod}
                onClose={recentTranModClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ '& .MuiDialogContent-root css-ypiqx9-MuiDialogContent-root': { width: "775px !important" } }}
            >
                <DialogContent >
                    <Typography sx={userStyle.HeaderText}>Recent Transactions</Typography>
                    <br /><br />
                    <Grid container sx={{ maxWidth: "800px !important" }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', textTransform: 'CAPITALIZE', }}>
                                <Tabs value={valueMod} onChange={handleChangeMod} TabIndicatorProps={{
                                    style: {
                                        background: 'none',
                                        borderTop: '5px solid #7009ab', borderLeft: '2px solid black', top: '0', borderRadius: '5px !important'
                                    }
                                }}
                                    aria-label="basic tabs example" scrollButtons variant="scrollable"  >
                                    <Tab iconestimateition="start" label={<><div><CheckOutlinedIcon />&ensp;Final</div></>} {...a11yProps(0)}></Tab>
                                    <Tab iconestimateition="start" label={<><div><ChevronRightOutlinedIcon />&ensp;Quotation</div></>} {...a11yProps(1)}></Tab>
                                    <Tab iconestimateition="start" label={<><div><ChevronRightOutlinedIcon />&ensp;Draft</div></>} {...a11yProps(2)}></Tab>
                                </Tabs>
                            </Box>

                            {/* Estimate Panel */}
                            <TabPanel value={valueMod} index={0}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {estimate &&
                                                        (estimate.slice(-4)?.map((item, i) => (
                                                            <StyledTableRow key={i}>
                                                                <StyledTableCell>{item.referenceno}</StyledTableCell>
                                                                <StyledTableCell>{item.customer}</StyledTableCell>
                                                                <StyledTableCell>{item.aftergranddisctotal}</StyledTableCell>
                                                            </StyledTableRow>
                                                        )))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            {/* Quotation Panel */}
                            <TabPanel value={valueMod} index={1}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {quotations &&
                                                        (quotations?.slice(-4)?.map((item, i) => (
                                                            <StyledTableRow key={i}>
                                                                <StyledTableCell>{item.referenceno}</StyledTableCell>
                                                                <StyledTableCell>{item.customer}</StyledTableCell>
                                                                <StyledTableCell>{item.aftergranddisctotal}</StyledTableCell>
                                                            </StyledTableRow>
                                                        )))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            {/* Draft Panel */}
                            <TabPanel value={valueMod} index={2}>
                                <Grid container  >
                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                        <TableContainer>
                                            <Table>
                                                <TableBody>
                                                    {drafts &&
                                                        (drafts.slice(-4)?.map((item, i) => (
                                                            <StyledTableRow key={i}>
                                                                <StyledTableCell>{item.referenceno}</StyledTableCell>
                                                                <StyledTableCell>{item.customer}</StyledTableCell>
                                                                <StyledTableCell>{item.aftergranddisctotal}</StyledTableCell>
                                                            </StyledTableRow>
                                                        )))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Box>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disableRipple onClick={recentTranModClose} variant="outlined">Close</Button>
                </DialogActions>
            </Dialog>
            {/* Recent Transactions Modal Ends */}
            <Dialog
                open={isErrorOpen}
                onClose={alertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <Typography>{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={alertClose}>ok</Button>
                </DialogActions>
            </Dialog>

            <>
                <Box sx={userStyle.printcls} ref={componentRef}>
                    <Box sx={{ textAlign: 'center', width: '400px' }}>
                        {/* <img> </img> */}
                        <Typography>{setngs?.businessname}</Typography>
                        <Typography>{setngs?.buniessaddress}</Typography>
                        <Typography>{businesslocation[0]?.zipcde}</Typography>
                        <Typography>CIN : {setngs ? setngs.ciono : ""}</Typography>
                        <Typography>GST NO : {setngs ? setngs.gstno : ""}</Typography>
                        <Typography variant="h6">INVOICE</Typography>
                        <Box sx={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography name="print" align='left'><b>Invoice No :</b>{newvalestimate}</Typography>
                                <Typography name="print" align='left'><b>Counter :</b> {isUserRoleAccess.counter}</Typography>
                                <Typography name="print" align='left'><b>Customer ID :</b> {customer.cusid == undefined ? "" : customer.cusid}</Typography>
                                <Typography name="print" align='left'><b>Mail ID :</b> {businesslocation[0]?.email}</Typography>
                            </Grid>
                            <Grid item xs={6} align="right" >
                                <Typography name="print" ><b>Cashier :</b>{isUserRoleAccess.username}</Typography>
                                <Typography name="print" ><b>Name : </b>{isUserRoleAccess.first + ' ' + isUserRoleAccess.last}</Typography>
                                <Typography name="print" ><b>Phone : </b>{businesslocation[0]?.whatsappno}</Typography>
                            </Grid>
                        </Grid>
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box>
                        <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
                            <Table aria-label="simple table" >
                                <TableHead >
                                    <TableRow sx={{ borderBottom: 'none' }}>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>ITEM</TableCell>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>QUANTITY</TableCell>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>PRICE</TableCell>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>AMOUNT</TableCell>
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {tableData.length > 0 &&
                                        tableData.map((data, i) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ fontSize: '14px', fontWeight: "1000", padding: '5px', borderBottom: "none" }} key={i}>{data?.productid} <br />

                                                        </TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', fontWeight: "1000", padding: '5px', borderBottom: "none" }}>
                                                            <Typography>
                                                                {data?.quantity}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', fontWeight: "1000", padding: '5px', borderBottom: "none" }}>
                                                            <Typography>
                                                                {Math.abs(data?.sellingexcludevalue)}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ fontSize: '12px', fontWeight: "1000", padding: '5px', borderBottom: "none" }}>
                                                            <Typography variant='subtitle1'>{Number(data?.subtotal).toFixed(2)}</Typography>
                                                        </TableCell>

                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontSize: '14px', fontWeight: "1000", padding: '0px', borderBottom: "none" }} colSpan={4}>{data?.productname}</TableCell>
                                                    </TableRow>
                                                </>
                                            );
                                        })}

                                </TableBody>

                            </Table>
                        </TableContainer>
                        <br />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography name="print" ><b>GRAND TOTAL :</b></Typography>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Typography name="print" ><b>{Number(estimateAdd.aftergranddisctotal).toFixed(2)}</b> </Typography>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography name="print" ><b>NET TOTAL :</b></Typography>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Typography name="print" ><b>{Number(estimateAdd.aftergranddisctotal).toFixed(2)}</b> </Typography>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography name="print" ><b>TOTAL :</b></Typography>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}><b>{Number(estimateAdd.aftergranddisctotal).toFixed(2)}</b>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />

                        <Grid container>
                            <Grid item xs={7}>
                                <Typography name="print" ><b></b></Typography>
                                <Typography name="print" ><b>TOTAL RECEIVED AMOUNT</b></Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Typography name="print" ><b></b> </Typography>
                                <Typography name="print" ><b>{estimateAdd.amountgain}</b> </Typography>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography name="print" ><b>NO. OF ITEMS:  </b>{totalQuantityCalc()}</Typography>
                                <Typography name="print" ><b>TOTAL ITEMS: </b>{totalQuantityCalc()}</Typography>
                            </Grid>
                        </Grid><br />
                        <Grid container>
                            <Grid>
                                <Typography variatnt="subtitle2" align="left">* All Offers are subject to applicable T&C.</Typography>
                                <Typography variatnt="subtitle2" align="left">* No return / Exchange / Refund on Innerwear, Dresses, Cloths and Chudidhar.</Typography>
                                <Typography variatnt="subtitle2" align="left">* This is computer generated invoice and hence does not require any signature.</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <br />   <br />   <br />   <br />

                </Box>
                <br /><br />
                {/* Error model */}
                <Dialog
                    open={isErrorOpen}
                    onClose={alertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={alertClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </>
        </Box>
    );
};
export default Estimatecreate;