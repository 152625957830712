import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { UserRoleAccessContext } from '../../context/Appcontext';

function Supplierwise() {


  const [supplierwisesales, setsupplierwisesales] = useState([]);
  const [purchases, setPurchases] = useState([]);
  //  const[tableDatas , setTableDatas]= useState([])
  const { auth, setAuth } = useContext(AuthContext);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  let tableDatas = []

  const fetchTableproducts = async (e) => {
    try {
      let response = await axios.get(SERVICE.PURCHASEPRODUCTS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setPurchases(response.data.purchases)

    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //supplierwisesales fetching

  const fetchsupplierwisesales = async () => {
    try {
      let request = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let supplierwisesalesfetch = request.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }

      })

      supplierwisesalesfetch !== "" ? supplierwisesalesfetch.map((pos) => {
        pos.goods.map((value) => {
          purchases.forEach((data) => {
            if (value.productid == data.sku && value.batch == data.batch && value.sellingpriceunitcost == data.sellingpriceunitcost) {
              tableDatas.push({ ...value, supplier: data.supplier })
            }
          })
        })
      }) : setsupplierwisesales([])

      const result = [...tableDatas.reduce((r, o) => {
        const key = o.supplier;
        const items = r.get(key) || Object.assign({}, o, {
          quantity: 0,
          ratebydismod: 0

        });
        items.quantity += o.quantity
        items.subtotal += o.subtotal

        return r.set(key, items);
      }, new Map).values()];

      setsupplierwisesales(result)

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 25, 50, 100, 200, 500, -1],
              [10, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      })




    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);

    }
  }


  useEffect(() => {
    fetchTableproducts()
  }, [tableDatas])
  // Export Excel
  const fileName = 'Supplier Wise Sales'

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#supplierwisesales' })
    doc.save('supplierwisesales.pdf')
  }


  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIsupplierwisesales | Salesummary',
    pageStyle: 'print'
  });
  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  useEffect(() => {

  }, [(tableRef.current)])

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={userStyle.importheadtext}>Supplier Wise Sales</Typography>
        </Grid>
      </Grid><br />
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container >
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container >
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchsupplierwisesales} >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      <Headtitle title={'Supplier wise sales'} />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>

        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].excelsupplierwisesales && (<ExportCSV csvData={supplierwisesales} fileName={fileName} />)}
            {isUserRoleCompare[0].csvsupplierwisesales && (<ExportXL csvData={supplierwisesales} fileName={fileName} />)}
            {isUserRoleCompare[0].printsupplierwisesales && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfsupplierwisesales && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="supplierwisesales" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Supplier</StyledTableCell>
                  <StyledTableCell >Total Qunatity</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">

                {supplierwisesales.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.supplier}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                      <StyledTableCell >{row.subtotal}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="supplierwisesales" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Supplier</StyledTableCell>
              <StyledTableCell >Total Qunatity</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {supplierwisesales.map((row, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell >{row.supplier}</StyledTableCell>
                  <StyledTableCell >{row.quantity}</StyledTableCell>
                  <StyledTableCell >{row.subtotal}</StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}


function Supplierwisesales() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Supplierwise /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Supplierwisesales;