import React, { useState, useEffect, useRef, createRef, useContext } from "react";
import { useReactToPrint } from 'react-to-print';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Button, Typography, DialogContent, Paper, TableHead, Table, TableContainer, TableBody, Dialog, TableFooter, DialogActions, Grid } from '@mui/material';
import { FaPrint, FaFilePdf } from "react-icons/fa";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import "rsuite/dist/rsuite.css";
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import jsPDF from "jspdf";
import { ExportXL, ExportCSV } from '../../Export';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import moment from 'moment';

const Purchasereturntablelist = () => {

    const [purchasesrtn, setpurchasesrtn] = useState();
    const [deletepur, setDeletepur] = useState({});

    const { auth } = useContext(AuthContext);
      // Access
     const { isUserRoleCompare } = useContext(UserRoleAccessContext);
 

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [])

    // Purchase 

    const fetchHandler = async () => {
        try {
            let purchase = await axios.get(SERVICE.PURCHASE_RETURN, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setpurchasesrtn(purchase.data.purchasesrtn);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    let total = 0;
    let totals = 0;
    let sum = 0;
    let sums = 0;
    let grandtotal = 0;
    let gtotal = 0;
    let btnval;

     //  PDF
     const downloadPdf = () => {
        const doc = new jsPDF()
        doc.autoTable({
            theme: "grid",
            columns: columns.map(col => ({ ...col, dataKey: col.field })),
            body: purchasesrtn
          })
        doc.save('purchaseReturn.pdf')
    }
    const columns = [
        { title: "Date", field: "purchasedate" },
        { title: "Reference No", field: "referenceno" },
        { title: "Location", field: "businesslocation"  },
        { title: "Supplier", field: 'supplier'},
        { title: "Invoice No", field: 'invoiceno'},
        { title: " Purchase Return Status", field: 'purchasestatus',},
        { title: "Grand Total", field: 'nettotal'},
    ]

    //set function to get particular row
    const rowDataDel = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.PURCHASE_RETURN_SINGLE}/${id}`,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDeletepur(res.data.spurchsertn);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Alert delete popup
    let supid = deletepur._id;
    const deletePurchase = async () => {
        try {
            let res = await axios.delete(`${SERVICE.PURCHASE_RETURN_SINGLE}/${supid}`,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleClickOpen = () => {
        setIsDeleteOpen(true);
    };

    const handleClose = () => {
        setIsDeleteOpen(false);
    };

    // Excel
    const fileName = "Purchase Return";

    const [purchaseData, setPurchaseData] = useState([]);

    // get particular columns for export excel
    const getexcelDatas = async () => {

        let purchase = await axios.get(SERVICE.PURCHASE_RETURN,{
            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            }
        })

        var data = purchase?.data?.purchasesrtn.map(t => ({
            "Date": moment(t.purchasedate).format('DD/MM/YYYY'), 
            "Reference No": t.referenceno,
            "Location": t.businesslocation, "Supplier": t.supplier,
            "Invoice No" : t.invoiceno,
             "Purchase Return Status": t.purchasestatus,
            "Grand Total": t.grandtotal, "Return Total": t.nettotal,
            "Product Name":t.prodname,
        }));
        setPurchaseData(data);
    }

    useEffect(
        () => {
            fetchHandler();
        }, []);

    useEffect(
        () => {
            getexcelDatas();
        }, [purchasesrtn]);

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | PURCHASE RETURN',
        pageStyle: 'print'
    });

    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    return (
        <>
            <Box>
                <Headtitle title={'Purchase Return List'} />
                <Typography sx={userStyle.HeaderText}>Purchase Return List</Typography>
            </Box>
            <br />
            <>
                <Box sx={userStyle.container}>
                    { /* Table header  */}
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography sx={userStyle.importheadtext}>All your purchases return</Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right !important' }}>
                            <Link to="/purchase/purchasereturn/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                        </Grid>
                    </Grid>
                    { /* Table export btn */}
                    <Grid container sx={userStyle.gridcontainer}>
                        <Grid >
                            {isUserRoleCompare[0].csvpurchasereturnlist && (
                                <>
                                    <ExportCSV csvData={purchasesrtn} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelpurchasereturnlist && (
                                <>
                                    <ExportXL csvData={purchasesrtn} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printpurchasereturnlist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfpurchasereturnlist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() =>{downloadPdf()}}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* Table start */}
                    <Box sx={userStyle.container}>
                        <TableContainer component={Paper} >
                            <Table aria-label="customized table" id="purchasetable" ref={tableRef}>
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell> Action </StyledTableCell>
                                        <StyledTableCell> Date</StyledTableCell>
                                        <StyledTableCell> Reference No</StyledTableCell>
                                        <StyledTableCell> Location </StyledTableCell>
                                        <StyledTableCell> Supplier </StyledTableCell>
                                        <StyledTableCell> Invoice No</StyledTableCell>
                                        <StyledTableCell> Purchase Return Status</StyledTableCell>
                                        <StyledTableCell> Grand Total</StyledTableCell>
                                        <StyledTableCell> Return Total</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {purchasesrtn && (
                                        purchasesrtn.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Grid sx={{ display: 'flex' }}>
                                                        {isUserRoleCompare[0].epurchasereturnlist && (<Link to={`/purchase/purchasereturn/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                        {isUserRoleCompare[0].vpurchasereturnlist && (<Link to={`/purchase/purchasereturn/view/${row._id}`}  style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                        {isUserRoleCompare[0].dpurchasereturnlist && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowDataDel(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>)}
                                                    </Grid>
                                                </StyledTableCell>
                                                <StyledTableCell>{moment(row.purchasedate).format('DD/MM/YYYY')}</StyledTableCell>
                                                <StyledTableCell>{row.referenceno}</StyledTableCell>
                                                <StyledTableCell>{row.businesslocation}</StyledTableCell>
                                                <StyledTableCell>{row.supplier}</StyledTableCell>
                                                <StyledTableCell>{row.invoiceno}</StyledTableCell>
                                                <StyledTableCell>{row.purchasestatus}</StyledTableCell>
                                                <StyledTableCell>₹ {(row.grandtotal)? row.grandtotal : 0}</StyledTableCell>
                                                <StyledTableCell>₹ {(row.nettotal)? row.nettotal : 0}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    { /* Table end */}
                </Box>
            </>
            <>
                <Box>
                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined">Cancel</Button>
                            <Button autoFocus variant="contained" color='error' onClick={(e) => deletePurchase(supid)}> OK </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <TableContainer component={Paper} sx={userStyle.printcls} >
                    <Table aria-label="customized table" id="purchasetable" ref={componentRef}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell> Date</StyledTableCell>
                                <StyledTableCell> Reference No</StyledTableCell>
                                <StyledTableCell> Location </StyledTableCell>
                                <StyledTableCell> Supplier </StyledTableCell>
                                <StyledTableCell> Invoice No</StyledTableCell>
                                <StyledTableCell> Purchase Return Status</StyledTableCell>
                                <StyledTableCell> Grand Total</StyledTableCell>
                                <StyledTableCell> Return Total</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {purchasesrtn && (
                                purchasesrtn.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{row.purchasedate}</StyledTableCell>
                                            <StyledTableCell>{row.referenceno}</StyledTableCell>
                                            <StyledTableCell>{row.businesslocation}</StyledTableCell>
                                            <StyledTableCell>{row.supplier}</StyledTableCell>
                                            <StyledTableCell>{row.invoiceno}</StyledTableCell>
                                            <StyledTableCell>{moment(row.purchasestatus).format('DD/MM/YYYY')}</StyledTableCell>
                                            <StyledTableCell>₹ {(row.grandtotal)? row.grandtotal : 0}</StyledTableCell>
                                            <StyledTableCell>₹ {(row.nettotal)? row.nettotal : 0}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        </>
    )
}

function Purchasereturnlists() {
    return (
        <>
            <Box >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Purchasereturntablelist /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Purchasereturnlists;