import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Typography, TextField, OutlinedInput, FormControlLabel, FormControl, Checkbox, Grid, InputLabel, Paper, Table, Select, MenuItem, TableHead, TableBody, TableContainer, Button, Dialog, DialogContent, DialogActions, } from "@mui/material";
import { userStyle, colourStyles } from "../PageStyle";
import Navbar from "../../components/header/Navbar";
import Footer from "../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../components/Table";
import axios from "axios";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { ExportXL, ExportCSV } from "../Export";
import dayjs from 'dayjs';
import { UserRoleAccessContext, AuthContext } from '../../context/Appcontext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from "react-toastify";
import Headtitle from "../../components/header/Headtitle";
import $ from "jquery";
import { SERVICE } from "../../services/Baseservice";
import Selects from "react-select";
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { useReactToPrint } from "react-to-print";

function ManualStockEntryDetails() {

  const { auth } = useContext(AuthContext);
  const [stockentry, setStockEntry] = useState({
    prodname: "", sku: "", batch: "", manufacture: "DEFAULT", molecule: "DEFAULT", schedule: "DEFAULT", category: "DEFAULT", enteramt: 0,
    sellingpriceunitcost: 0, retailrate: 0, ptrrate: 0, ptsrate: 0, quantity: 0, packingcharge: 0, distypemod: "None", disvaluemod: Number(0), ratebydismod: 0, applicabletax: "", hsntax: "", expirydate: "",
  });
  const [isCategory, setIsCategory] = useState([]);
  const [purchases, setPurchases] = useState();
  const [isMolecule, setIsMolecule] = useState([]);
  const [isSchedule, setIsSchedule] = useState([]);
  const [isManufacture, setIsManufacture] = useState([]);
  const [isProducts, setisProducts] = useState([]);
  const [setngs, setSetngs] = useState();
  const [hsnGrp, sethsnGrp] = useState([]);
  const [hsnCodes, sethsnCodes] = useState(true);
  const [taxrates, setTaxrates] = useState([]);
  const [tableData, setTableData] = useState([]);

  //role access
  const { isUserRoleAccess } = useContext(UserRoleAccessContext);
  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  // purchase date
  const [purchaseDateTime, setPurchaseDateTime] = useState(dayjs());

  //popup model
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState()
  const handleClickOpenc = () => {
    setIsErrorOpen(true);
  };
  const handleClose = () => {
    setIsErrorOpen(false);
  };

  let defaulttax = setngs?.applicabletax;
  //products
  const fetchProducts = async () => {
    try {
      let response = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });

      setisProducts(response.data.products);

      await fetchPurchases(response.data.products);

    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //fetch settings data from 
  const fetchSettings = async () => {
    try {
      var response = await axios.get(`${SERVICE.UPDATE_SETTINGS}`,
        {
          headers: {
            'Authorization': `Bearer ${auth.APIToken}`
          },
        });
      setSetngs(response.data.settingid);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages)
    }
  }

  let newval = setngs ? setngs.skuprefix + "0001" : "PR0001";
  // auto id for purchase number
  let newvalpurchase = setngs ? setngs.purchasesku + "0001" : "PU0001";
  // Categorys
  const fetchCategory = async () => {
    try {
      let categorylist = await axios.get(SERVICE.CATEGORIES, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setIsCategory(
        categorylist.data.categories?.map((d) => ({

          ...d,
          label: d.categoryname,
          value: d.categoryname,
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Molecule
  const fetchMolecule = async () => {
    try {
      let moleculelist = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setIsMolecule(
        moleculelist.data.products?.map((d) => ({

          ...d,
          label: d.molecules,
          value: d.molecules,
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //fetch all purchase
  const fetchPurchases = async (allproducts) => {
    try {
      let purchase = await axios.get(SERVICE.PURCHASE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let purchaseproducts = await axios.get(SERVICE.PURCHASEPRODUCTS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      setPurchases(purchase.data.purchases)
      await fetchProductPurchase(purchaseproducts.data.purchases, allproducts);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //add product data to purchase
  const fetchProductPurchase = (allPurchaselist, allproducts) => {

    let result = allPurchaselist.map((item, index) => {
      let data = item;
      allproducts.forEach((value, i) => {
        if (item.sku == value.prodcode && item.batch == value.batch && item.sellingpricetax == value.sellingpriceunitcost) {
          data = { ...item, minquantity: value.minquantity, maxquantity: value.maxquantity, selltaxtype: value.selltaxtype, unit: value.unit, molecule: value.molecules, category: value.category, schedule: value.schedule, businesslocation: value.businesslocation, ptr: value.ptr, pts: value.pts, mrp: value.mrp == "" || value.mrp == 0 ? value.sellingpriceunitcost : value.mrp, currentstock: value.currentstock, hsn: value.hsncode, reorderlevel: value.reorderlevel }

        } else if (item.sku == value.prodcode && item.sellingpricetax == value.sellingpriceunitcost) {
          data = { ...item, minquantity: value.minquantity, maxquantity: value.maxquantity, selltaxtype: value.selltaxtype, unit: value.unit, molecule: value.molecules, category: value.category, schedule: value.schedule, businesslocation: value.businesslocation, ptr: value.ptr, pts: value.pts, mrp: value.mrp == "" || value.mrp == 0 ? value.sellingpriceunitcost : value.mrp, currentstock: value.currentstock, hsn: value.hsncode, reorderlevel: value.reorderlevel }

        } else if (item.sku == value.prodcode && item.batch == value.batch) {
          data = { ...item, minquantity: value.minquantity, maxquantity: value.maxquantity, selltaxtype: value.selltaxtype, unit: value.unit, molecule: value.molecules, category: value.category, schedule: value.schedule, businesslocation: value.businesslocation, ptr: value.ptr, pts: value.pts, mrp: value.mrp == "" || value.mrp == 0 ? value.sellingpriceunitcost : value.mrp, currentstock: value.currentstock, hsn: value.hsncode, reorderlevel: value.reorderlevel }
        } else if (item.sku == value.prodcode) {
          data = { ...item, minquantity: value.minquantity, maxquantity: value.maxquantity, selltaxtype: value.selltaxtype, unit: value.unit, molecule: value.molecules, category: value.category, schedule: value.schedule, businesslocation: value.businesslocation, ptr: value.ptr, pts: value.pts, mrp: value.mrp == "" || value.mrp == 0 ? value.sellingpriceunitcost : value.mrp, currentstock: value.currentstock, hsn: value.hsncode, reorderlevel: value.reorderlevel }
        }
      })
      return data;
    })

    setTableData(result);

  };

  // Manufactures
  const fetchManufactures = async () => {
    try {
      let manufactureslist = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setIsManufacture(
        manufactureslist.data.products?.map((d) => ({

          ...d,
          label: d.manufacture,
          value: d.manufacture,
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Schedule
  const fetchSchedule = async () => {
    try {
      let schedulelist = await axios.get(SERVICE.SCHEDULE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setIsSchedule(
        schedulelist.data.schedule?.map((d) => ({

          ...d,
          label: d.name,
          value: d.name,
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Get Datas
  const taxrateRequest = async () => {
    try {
      let response = await axios.get(SERVICE.TAXRATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });

      let hsnGrpData = response.data.taxrates.filter((data) => {
        if (data.taxtype == "hsn") {
          return data
        }
      })
      sethsnGrp(hsnGrpData)
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //purchase auto id
  {
    purchases && (
      purchases.map(
        () => {
          let strings = setngs ? setngs.purchasesku : "PU";
          let refNo = purchases[purchases.length - 1].referenceno;
          let digits = (purchases.length + 1).toString();
          const stringLength = refNo.length;
          let lastChar = refNo.charAt(stringLength - 1);
          let getlastBeforeChar = refNo.charAt(stringLength - 2);
          let getlastThreeChar = refNo.charAt(stringLength - 3);
          let lastBeforeChar = refNo.slice(-2);
          let lastThreeChar = refNo.slice(-3);
          let lastDigit = refNo.slice(-4);
          let refNOINC = parseInt(lastChar) + 1
          let refLstTwo = parseInt(lastBeforeChar) + 1;
          let refLstThree = parseInt(lastThreeChar) + 1;
          let refLstDigit = parseInt(lastDigit) + 1;
          if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
            refNOINC = ("000" + refNOINC).substr(-4);
            newvalpurchase = strings + refNOINC;
          } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
            refNOINC = ("00" + refLstTwo).substr(-4);
            newvalpurchase = strings + refNOINC;
          } else if (digits.length < 4 && getlastThreeChar > 0) {
            refNOINC = ("0" + refLstThree).substr(-4);
            newvalpurchase = strings + refNOINC;
          } else {
            refNOINC = (refLstDigit).substr(-4);
            newvalpurchase = strings + refNOINC;
          }
        }))
  }

  // Taxrates
  const fetchRates = async () => {
    try {
      let response = await axios.get(SERVICE.TAXRATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });

      setTaxrates(
        response.data.taxrates.map((d) => ({
          ...d,
          label: d.taxname,
          value: d.taxname,
        })))
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const discountCalculation = (inputValue, reference) => {
    if (reference == "purchase") {
      if (stockentry.quantity > 0) {
        if (stockentry.distypemod == "None") {
          let purchaseqty = Number(inputValue) * Number(stockentry.quantity);
          setStockEntry({ ...stockentry, ratebydismod: purchaseqty, enteramt: inputValue })

        } else if (stockentry.distypemod == "Amount" || stockentry.distypemod == "Fixed") {
          let purchaseqty = Number(inputValue) * Number(stockentry.quantity);
          setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(stockentry.disvaluemod), enteramt: inputValue })

        } else if (stockentry.distypemod == "Percentage") {
          let purchaseqty = Number(inputValue) * Number(stockentry.quantity);
          let discvalue = stockentry.disvaluemod == undefined ? 0 : (Number(stockentry.disvaluemod) * Number(inputValue))
          let percetagevalue = discvalue > 0 ? (Number(discvalue) / Number(100)) : 0;

          setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(percetagevalue), enteramt: inputValue })
        }
      } else {
        setStockEntry({ ...stockentry, enteramt: inputValue })
      }

    } else if (reference == "quantity") {
      if (stockentry.distypemod == "None") {
        let purchaseqty = Number(stockentry.enteramt) * Number(inputValue);
        setStockEntry({ ...stockentry, ratebydismod: purchaseqty, quantity: inputValue })

      } else if (stockentry.distypemod == "Amount" || stockentry.distypemod == "Fixed") {
        let purchaseqty = Number(stockentry.enteramt) * Number(inputValue);
        setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(stockentry.disvaluemod), quantity: inputValue })

      } else if (stockentry.distypemod == "Percentage") {
        let purchaseqty = Number(stockentry.enteramt) * Number(inputValue);
        let discvalue = stockentry.disvaluemod == undefined ? 0 : (Number(stockentry.enteramt) * Number(stockentry.disvaluemod))
        let percetagevalue = discvalue > 0 ? (Number(discvalue) / Number(100)) : 0

        setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(percetagevalue), quantity: inputValue })
      }
    } else if (reference == "discounttype") {
      if (stockentry.enteramt > 0 && stockentry.quantity > 0) {
        if (inputValue == "None") {
          let purchaseqty = Number(stockentry.enteramt) * Number(stockentry.quantity);

          setStockEntry({ ...stockentry, ratebydismod: purchaseqty, distypemod: inputValue })
        } else if (inputValue == "Fixed" || inputValue == "Amount") {
          let purchaseqty = Number(stockentry.enteramt) * Number(stockentry.quantity);

          setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(stockentry.disvaluemod), distypemod: inputValue })
        } else if (inputValue == "Percentage") {
          let purchaseqty = Number(stockentry.enteramt) * Number(stockentry.quantity);
          let discvalue = stockentry.disvaluemod == undefined ? 0 : (Number(stockentry.enteramt) * Number(stockentry.disvaluemod))
          let percetagevalue = discvalue > 0 ? (Number(discvalue) / Number(100)) : 0;
          setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(percetagevalue), distypemod: inputValue })
        }
      } else {
        setStockEntry({ ...stockentry, distypemod: inputValue })

      }

    } else if (reference == "enterdiscountamout") {
      if (stockentry.distypemod == "None") {
        let purchaseqty = Number(stockentry.enteramt) * Number(stockentry.quantity);
        setStockEntry({ ...stockentry, ratebydismod: purchaseqty, disvaluemod: inputValue })

      } else if (stockentry.distypemod == "Amount" || stockentry.distypemod == "Fixed") {
        let purchaseqty = Number(stockentry.enteramt) * Number(stockentry.quantity);
        setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(inputValue), disvaluemod: inputValue })

      } else if (stockentry.distypemod == "Percentage") {
        let purchaseqty = Number(stockentry.enteramt) * Number(stockentry.quantity);
        let discvalue = (Number(stockentry.enteramt) * Number(inputValue))
        let percetagevalue = (Number(discvalue) / Number(100));
        setStockEntry({ ...stockentry, ratebydismod: Number(purchaseqty) - Number(percetagevalue), disvaluemod: inputValue })
      }
    }
  }

  const sendRequest = async () => {
    try {
      //store as stock
      let res_stock = await axios.post(SERVICE.STOCK_CREATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        itemname: String(stockentry.prodname),
        itemcode: String(newval),
        batchno: Number(stockentry.batch),
        category: String(stockentry.category),
        schedule: String(stockentry.schedule),
        molecule: String(stockentry.molecule),
        manufacture: String(stockentry.manufacture),
        purchaserate: String(stockentry.enteramt),
        mrprate: Number(stockentry.sellingpriceunitcost),
        retailrate: Number(stockentry.retailrate),
        ptrrate: Number(stockentry.ptrrate),
        ptsrate: Number(stockentry.ptsrate),
        quantity: Number(stockentry.quantity),
        packing: Number(stockentry.packingcharge),
        discounttype: String(stockentry.distypemod),
        discountvalue: Number(stockentry.disvaluemod),
        taxvalue: String(hsnCodes ? stockentry.hsntax : stockentry.applicabletax),
        expiry: String(stockentry.expirydate),
        afterdiscount: String(stockentry.ratebydismod)
      });

      //store as product
      let res_product = await axios.post(SERVICE.PRODUCT_CREATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        prodname: String(stockentry.prodname),
        prodcode: String(newval),
        category: String(stockentry.category),
        schedule: String(stockentry.schedule),
        molecules: String(stockentry.molecule),
        manufacture: String(stockentry.manufacture),
        hsncode: String(stockentry.hsntax),
        hsncodeCheck: Boolean(hsnCodes),
        applicabletax: String(stockentry.applicabletax),
        businesslocation: String(""),
        managestock: Boolean(true),
        unit: String(""),
        rack: String(""),
        boxno: String(""),
        currentstock: Number(stockentry.quantity),
        purchaseexcludetax: Number(stockentry.applicabletax == "None" || stockentry.hsntax == "" || stockentry.hsntax == undefined ? 0 : stockentry.enteramt),
        pruchaseincludetax: Number(stockentry.applicabletax || stockentry.hsntax ? stockentry.enteramt : 0),
        sellingpriceunitcost: Number(stockentry.sellingpriceunitcost),
        selltaxtype: String(setngs?.sellingpricetax),
        producttype: String("Single"),
        minquantity: Number(setngs ? setngs.minquantity : 0),
        maxquantity: Number(setngs ? setngs.maxquantity : 0),
        ptr: Number(stockentry.ptrrate),
        pts: Number(stockentry.ptsrate),
        mrp: Number(stockentry.sellingpriceunitcost),
        expirydate: String(stockentry.expirydate),
      });

      //store to purchase
      let res_purchase = await axios.post(SERVICE.PURCHASE_CREATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        supplier: String("None"),
        referenceno: String(newvalpurchase),
        purchasedate: String(purchaseDateTime),
        purchasestatus: String("Received"),
        addressone: String(""),
        supplierid: String(""),
        supaddressone: String(""),
        addresstwo: String(""),
        city: String(""),
        gstn: String(""),
        whatsapp: Number(0),
        contactperson: String(""),
        creditdays: Number(0),
        dlnos: Number(0),
        duedate: Number(0),
        businesslocation: String(""),
        billamount: Number(stockentry.ratebydismod),
        invoiceno: String(""),
        attachdoc: String(""),
        products: [stockentry],
        additionalnotes: String(""),
        totalcnt: Number(0),
        totalitem: Number(stockentry.quantity),
        nettotal: Number(stockentry.ratebydismod),
        advancebalance: Number(0),
        payamount: Number(stockentry.ratebydismod),
        paidon: String(purchaseDateTime),
        paymentmethod: String("None"),
        paydue: Number(0),
        balance: Number(0),
        beforediscounttotal: Number(0),
        subcost: Number(0),
        totaldiscount: Number(stockentry.ratebydismod),
        granddistype: String(stockentry.distypemod),
        gdiscountvalue: String(stockentry.disvaluemod),
        discountamt: String(stockentry.distypemod == "Percentage" ? ((Number(stockentry.disvaluemod) * Number(100)) / (Number(stockentry.disvaluemod))) : stockentry.disvaluemod),
        cgst: Number(0),
        igst: Number(0),
        gst: Number(0),
        activate: Boolean(false),
        userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),

      });

      // update as product 
      setStockEntry({
        ...stockentry, prodname: "", sku: "", batch: "", manufacture: "DEFAULT", molecule: "DEFAULT", schedule: "DEFAULT", category: "DEFAULT", enteramt: 0,
        sellingpriceunitcost: 0, retailrate: 0, ptrrate: 0, ptsrate: 0, quantity: 0, packingcharge: 0, distypemod: "None", disvaluemod: Number(0), ratebydismod: 0, applicabletax: "", hsntax: "", expirydate: "",
      });
    } catch (err) {
      const messages = err.response.data.message;
      setShowAlert(messages);
      handleClickOpenc();
    }
  }

  useEffect(
    () => {
      fetchCategory();
      fetchRates();
      fetchSchedule();
      fetchManufactures();
      fetchMolecule();
      fetchProducts();
      fetchSettings();
      taxrateRequest();
    }, []
  )

  const handleSubmit = (e) => {
    e.preventDefault();
    if (stockentry.prodname == "") {
      setShowAlert("Please enter item name!");
      handleClickOpenc();
    } else if (stockentry.enteramt == "" || stockentry.enteramt == 0 || stockentry.enteramt == undefined) {
      setShowAlert("Please enter purchase rate!");
      handleClickOpenc();
    } else if (stockentry.retailrate == "" || stockentry.retailrate == 0 || stockentry.retailrate == undefined) {
      setShowAlert("Please enter retail rate!");
      handleClickOpenc();
    } else if (stockentry.quantity == "" || stockentry.quantity == 0 || stockentry.quantity == undefined) {
      setShowAlert("Please enter purchase rate!");
      handleClickOpenc();
    } else if (stockentry.expirydate == "") {
      setShowAlert("Please enter expiry date!");
      handleClickOpenc();
    } else {
      sendRequest();
    }
  }

  // Excel
  const fileName = "Purchase Return";

  const [exceldata, setExceldata] = useState([]);

  // get particular columns for export excel
  const getexcelDatas = async () => {
    var data = tableData?.data?.purchasesrtn.map(t => ({
      "Code": t.sku,
      "Name": t.prodname,
      "Batch": t.batch,
      "Expiry": t.expirydate,
      "Quality": t.quantity,
      "Pack Quality(pieces)": t.currentstock,
      "Packup": t.reorderlevel,
      "Purchase Rate": t.enteramt,
      "MRP": t.mrp,
      "Sales Rate": t.sellingpriceunitcost,
      "Discount": t.disvaluemod,
      "GST": t.applicabletax,
      "Tax Type": t.selltaxtype,
      "Category": t.category,
      "Schedule": t.schedule,
      "Manufacture": t.manufacture,
      "Location": t.businesslocation,
      "PTR": t.ptr,
      "PTS": t.pts,
      "Molecule": t.molecule,
      "HSN": t.hsncode,
      "Unit Description": t.unit,
      "Minimum Stock": t.minquantity,
      "Maximum Stock": t.maxquantity,
    }));
    setExceldata(data);
  }
  useEffect(
    () => {
      getexcelDatas();
    }, [tableData]);

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#manualstock' })
    doc.save('Manualstock.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | MANUAL STOCK ENTRY',
    pageStyle: 'print'
  });

  return (
    <Box>
      <Headtitle title={"Manual Stock Entry"} />
      {/* ****** Header Content ****** */}
      <Typography sx={userStyle.HeaderText}>Manual Stock Entry</Typography>
      <Box sx={userStyle.container}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Item Name<b style={{ color: 'red' }}>*</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={stockentry.prodname}
                  onChange={(e) => { setStockEntry({ ...stockentry, prodname: e.target.value, sku: newval }) }}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Item Code</InputLabel>
              {/* //product auto id */}
              {isProducts && (
                isProducts?.map(
                  () => {
                    let strings = setngs ? setngs.skuprefix : "PR";
                    let refNo = isProducts[isProducts.length - 1].prodcode;
                    let digits = (isProducts.length + 1).toString();
                    const stringLength = refNo.length;
                    let lastChar = refNo.charAt(stringLength - 1);
                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                    let lastBeforeChar = refNo.slice(-2);
                    let lastThreeChar = refNo.slice(-3);
                    let lastDigit = refNo.slice(-4);
                    let refNOINC = parseInt(lastChar) + 1
                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                    let refLstThree = parseInt(lastThreeChar) + 1;
                    let refLstDigit = parseInt(lastDigit) + 1;
                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                      refNOINC = ("000" + refNOINC);
                      newval = strings + refNOINC;
                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                      refNOINC = ("00" + refLstTwo);
                      newval = strings + refNOINC;
                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                      refNOINC = ("0" + refLstThree);
                      newval = strings + refNOINC;
                    } else {
                      refNOINC = (refLstDigit);
                      newval = strings + refNOINC;
                    }
                  }))}
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={newval}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Batch No</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.batch}
                  onChange={(e) => { setStockEntry({ ...stockentry, batch: e.target.value }) }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Manufacture</InputLabel>
              <FormControl size="small" fullWidth>
                <Selects
                  sx={userStyle.textInput}
                  styles={colourStyles}
                  placeholder="DEFAULT"
                  options={isManufacture}
                  onChange={(e) => { setStockEntry({ ...stockentry, manufacture: e.manufacture }) }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Molecule</InputLabel>
              <FormControl size="small" fullWidth>
                <Selects
                  sx={userStyle.textInput}
                  styles={colourStyles}
                  placeholder="DEFAULT"
                  options={isMolecule}
                  onChange={(e) => { setStockEntry({ ...stockentry, molecule: e.molecules }) }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Schedule</InputLabel>
              <FormControl size="small" fullWidth>
                <Selects
                  sx={userStyle.textInput}
                  styles={colourStyles}
                  placeholder="DEFAULT"
                  options={isSchedule}
                  onChange={(e) => { setStockEntry({ ...stockentry, schedule: e.name }) }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Category</InputLabel>
              <FormControl size="small" fullWidth>
                <Selects
                  sx={userStyle.textInput}
                  styles={colourStyles}
                  placeholder="DEFAULT"
                  options={isCategory}
                  onChange={(e) => { setStockEntry({ ...stockentry, category: e.categoryname }) }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Purchase rate<b style={{ color: 'red' }}>*</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.enteramt}
                  onChange={(e) => { discountCalculation(e.target.value, 'purchase') }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">MRP</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.sellingpriceunitcost}
                  onChange={(e) => { setStockEntry({ ...stockentry, sellingpriceunitcost: e.target.value }) }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Retail rate<b style={{ color: 'red' }}>*</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.retailrate}
                  onChange={(e) => { setStockEntry({ ...stockentry, retailrate: e.target.value }) }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">PTR</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.ptrrate}
                  onChange={(e) => { setStockEntry({ ...stockentry, ptrrate: e.target.value }) }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">PTS</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.ptsrate}
                  onChange={(e) => { setStockEntry({ ...stockentry, ptsrate: e.target.value }) }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Quantity<b style={{ color: 'red' }}>*</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.quantity}
                  onChange={(e) => { discountCalculation(e.target.value, 'quantity') }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Packing</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.packingcharge}
                  onChange={(e) => { setStockEntry({ ...stockentry, packingcharge: e.target.value }) }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Discount Type</InputLabel>
              <FormControl size="small" fullWidth>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={stockentry.distypemod}
                  onChange={(e) => { discountCalculation(e.target.value, 'discounttype') }}
                >
                  <MenuItem value="None">None</MenuItem>
                  <MenuItem value="Fixed">Fixed</MenuItem>
                  <MenuItem value="Amount" >Amount</MenuItem>
                  <MenuItem value="Percentage">Percentage</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Discount Value</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.disvaluemod}
                  onChange={(e) => { discountCalculation(e.target.value, 'enterdiscountamout') }}
                  type="number"
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">After Discount</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  sx={userStyle.input}
                  value={stockentry.ratebydismod}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">GST%</InputLabel>
              <FormControl size="small" fullWidth>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={stockentry.hsntax}
                  onChange={(e) => { setStockEntry({ ...stockentry, hsntax: e.target.value, }) }}
                >
                  {hsnGrp && (
                    hsnGrp.map((row, index) => (
                      <MenuItem value={row.hsn} key={index}>{row.hsn}</MenuItem>
                    )))}
                </Select>
              </FormControl>
              <Grid sx={{ fontSize: '10px' }}>
                <FormControlLabel control={<Checkbox checked={hsnCodes} onClick={(e) => { sethsnCodes(s => !s); }} />} label="Enable tax with HSN code" />
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              {hsnCodes ?
                <>
                  <InputLabel id="demo-select-small">HSN code</InputLabel>
                  <FormControl size="small" fullWidth>
                    <TextField
                      id="date"
                      type="text"
                      size='small'
                      placeholder='HSN code'
                      value={stockentry.hsntax}
                    />
                  </FormControl>
                </>
                :
                <>
                  <InputLabel id="demo-select-small">Applicable tax</InputLabel>
                  <FormControl size="small" fullWidth>
                    <Selects
                      options={taxrates}
                      labelId="demo-select-small"
                      styles={colourStyles}
                      id="demo-select-small"
                      value={taxrates?.find(op => {
                        return op.value == defaulttax
                      })}
                      onChange={(e) => { setStockEntry({ ...stockentry, applicabletax: e.taxname }); }}
                    >

                    </Selects>
                  </FormControl>
                </>
              }
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel id="demo-select-small">Expiry date<b style={{ color: 'red' }}>*</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={stockentry.expirydate}
                  onChange={(e) => { setStockEntry({ ...stockentry, expirydate: e.target.value }) }}
                  type="date"
                />
              </FormControl>
            </Grid>
            <Grid item md={4} lg={4} sm={6} xs={12} sx={{ display: 'none' }}>
              <FormControl size="small" fullWidth >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(props) => <TextField size="small" {...props} />}
                    label="Purchase Date"
                    value={purchaseDateTime}
                    onChange={(newValue) => {
                      setPurchaseDateTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid><br />
          <Grid container sx={userStyle.gridcontainer}>
            <Grid sx={{ display: 'flex' }}>
              <Button sx={userStyle.buttonadd} type="submit" >SAVE</Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <br />
      {/* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container}>
          {/* ****** Header Buttons ****** */}
          <Grid container sx={{ justifyContent: "center" }}>
            {/* <Grid>
              <ExportCSV />
              <ExportXL />
              <Button sx={userStyle.buttongrp}>
                <FaFilePdf />
                &ensp;Export to PDF&ensp;
              </Button>{" "}
            </Grid>
            <Button sx={userStyle.buttongrp} style={{
              textDecoration: "none", color: "white", "&:hover": { color: "red", backgroundColor: "yellow" },
            }}
            >
              <FaPrint />
              &ensp;Export to Print
            </Button> */}
            {/* ****** Table Grid Container ****** */}
            <Grid container>
              <Grid md={4} sm={2} xs={1}></Grid>
              <Grid md={8} sm={10} xs={10} sx={{ align: "center" }}></Grid>
            </Grid>
          </Grid>
          <br />
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvmanualstockentry && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelmanualstockentry && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printmanualstockentry && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfmanualstockentry && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => { downloadpdf() }}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          {/* ****** Table start ****** */}
          <TableContainer component={Paper}>
            <Table
              sx={{}}
              aria-label="simple table"
              id="Customerduesreport"
            >
              <TableHead sx={{ fontWeight: "600" }}>
                <StyledTableRow>
                  <StyledTableCell>Code</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Batch </StyledTableCell>
                  <StyledTableCell>Expiry </StyledTableCell>
                  <StyledTableCell>Quality</StyledTableCell>
                  <StyledTableCell>Pack Quality(pieces)</StyledTableCell>
                  <StyledTableCell>Packup</StyledTableCell>
                  <StyledTableCell>Purchase Rate</StyledTableCell>
                  <StyledTableCell>MRP</StyledTableCell>
                  <StyledTableCell>Sales Rate</StyledTableCell>
                  <StyledTableCell>Discount%</StyledTableCell>
                  <StyledTableCell>GST%</StyledTableCell>
                  <StyledTableCell>Tax Type</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell>Schedule</StyledTableCell>
                  <StyledTableCell>Manufacture</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                  <StyledTableCell>PTR</StyledTableCell>
                  <StyledTableCell>PTS</StyledTableCell>
                  <StyledTableCell>Molecule</StyledTableCell>
                  <StyledTableCell>HSN</StyledTableCell>
                  <StyledTableCell>Unit Description</StyledTableCell>
                  <StyledTableCell>Minimum Stock</StyledTableCell>
                  <StyledTableCell>Maximum Stock</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  (tableData.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.sku} </StyledTableCell>
                      <StyledTableCell>{row.prodname}</StyledTableCell>
                      <StyledTableCell>{row.batch} </StyledTableCell>
                      <StyledTableCell>{row.expirydate}</StyledTableCell>
                      <StyledTableCell>{row.quantity}</StyledTableCell>
                      <StyledTableCell>{row.currentstock}</StyledTableCell>
                      <StyledTableCell>{row.reorderlevel}</StyledTableCell>
                      <StyledTableCell>{row.enteramt}</StyledTableCell>
                      <StyledTableCell>{row.mrp}</StyledTableCell>
                      <StyledTableCell>{row.sellingpriceunitcost} </StyledTableCell>
                      <StyledTableCell>{row.disvaluemod}</StyledTableCell>
                      <StyledTableCell>{row.applicabletax}</StyledTableCell>
                      <StyledTableCell>{row.selltaxtype}</StyledTableCell>
                      <StyledTableCell>{row.category}</StyledTableCell>
                      <StyledTableCell>{row.schedule}</StyledTableCell>
                      <StyledTableCell>{row.manufacture}</StyledTableCell>
                      <StyledTableCell>{row.businesslocation}</StyledTableCell>
                      <StyledTableCell>{row.ptr}</StyledTableCell>
                      <StyledTableCell>{row.pts}</StyledTableCell>
                      <StyledTableCell>{row.molecule}</StyledTableCell>
                      <StyledTableCell>{row.hsncode}</StyledTableCell>
                      <StyledTableCell>{row.unit} </StyledTableCell>
                      <StyledTableCell>{row.minquantity}</StyledTableCell>
                      <StyledTableCell>{row.maxquantity} </StyledTableCell>
                    </StyledTableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ****** Table End ****** */}

          {/* Print Start */}
          <TableContainer component={Paper}>
            <Table
              sx={userStyle.printcls}
              aria-label="simple table"
              id="manualstock"
              ref={componentRef}
            >
              <TableHead sx={{ fontWeight: "600" }}>
                <StyledTableRow>
                  <StyledTableCell>Code</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Batch </StyledTableCell>
                  <StyledTableCell>Expiry </StyledTableCell>
                  <StyledTableCell>Quality</StyledTableCell>
                  <StyledTableCell>Pack Quality(pieces)</StyledTableCell>
                  <StyledTableCell>Packup</StyledTableCell>
                  <StyledTableCell>Purchase Rate</StyledTableCell>
                  <StyledTableCell>MRP</StyledTableCell>
                  <StyledTableCell>Sales Rate</StyledTableCell>
                  <StyledTableCell>Discount%</StyledTableCell>
                  <StyledTableCell>GST%</StyledTableCell>
                  <StyledTableCell>Tax Type</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell>Schedule</StyledTableCell>
                  <StyledTableCell>Manufacture</StyledTableCell>
                  <StyledTableCell>Location</StyledTableCell>
                  <StyledTableCell>PTR</StyledTableCell>
                  <StyledTableCell>PTS</StyledTableCell>
                  <StyledTableCell>Molecule</StyledTableCell>
                  <StyledTableCell>HSN</StyledTableCell>
                  <StyledTableCell>Unit Description</StyledTableCell>
                  <StyledTableCell>Minimum Stock</StyledTableCell>
                  <StyledTableCell>Maximum Stock</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {tableData &&
                  (tableData.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.sku} </StyledTableCell>
                      <StyledTableCell>{row.prodname}</StyledTableCell>
                      <StyledTableCell>{row.batch} </StyledTableCell>
                      <StyledTableCell>{row.expirydate}</StyledTableCell>
                      <StyledTableCell>{row.quantity}</StyledTableCell>
                      <StyledTableCell>{row.currentstock}</StyledTableCell>
                      <StyledTableCell>{row.reorderlevel}</StyledTableCell>
                      <StyledTableCell>{row.enteramt}</StyledTableCell>
                      <StyledTableCell>{row.mrp}</StyledTableCell>
                      <StyledTableCell>{row.sellingpriceunitcost} </StyledTableCell>
                      <StyledTableCell>{row.disvaluemod}</StyledTableCell>
                      <StyledTableCell>{row.applicabletax}</StyledTableCell>
                      <StyledTableCell>{row.selltaxtype}</StyledTableCell>
                      <StyledTableCell>{row.category}</StyledTableCell>
                      <StyledTableCell>{row.schedule}</StyledTableCell>
                      <StyledTableCell>{row.manufacture}</StyledTableCell>
                      <StyledTableCell>{row.businesslocation}</StyledTableCell>
                      <StyledTableCell>{row.ptr}</StyledTableCell>
                      <StyledTableCell>{row.pts}</StyledTableCell>
                      <StyledTableCell>{row.molecule}</StyledTableCell>
                      <StyledTableCell>{row.hsncode}</StyledTableCell>
                      <StyledTableCell>{row.unit} </StyledTableCell>
                      <StyledTableCell> {row.minquantity}</StyledTableCell>
                      <StyledTableCell>{row.maxquantity} </StyledTableCell>
                    </StyledTableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Print End */}
        </Box>
      </>
      {/* Delete Modal */}
      {/* ALERT DIALOG */}
      <Box>
        <Dialog
          open={isErrorOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
            <Typography variant="h6" >{showAlert}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

function ManualStockEntry() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <ManualStockEntryDetails /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default ManualStockEntry;