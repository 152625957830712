import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import moment from "moment";
import { useReactToPrint } from "react-to-print";

function Profitlossaccountlist() {

    const [purchases, setPurchases] = useState(0);
    const [pos, setPos] = useState(0);
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Datefield 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today,
    })

    let debitTotal = 0;

    // Fetches values from both POS and PURCHASES
    const fetchDatas = async () => {
        try {
            //  Fetch Purchases Data
            let request = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })

            let getDatawithFilters = request.data.purchases.filter((data) => {
                let splittedMonth = data.purchasedate
                let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    setPos(0); setPurchases(0)
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                    return data
                }
                else if (dateFilter.startdate < dateTrim && dateFilter.enddate + 1 < dateTrim) {
                    setPurchases(0)
                }
                else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
                    return data
                }
                else if (dateFilter.startdate == "" && dateFilter.enddate + 1 < dateTrim) {
                    setPos(0); setPurchases(0)
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate > dateTrim && dateFilter.enddate == "") {
                    setPos(0); setPurchases(0)
                }
                else if (dateFilter.startdate > dateTrim && dateFilter.enddate >= dateTrim) {
                    setPos(0); setPurchases(0)
                }

            })
            let results = getDatawithFilters.map((item) => {
                let res = item.products.map((data) => {
                    return +data.packingcharge
                }).reduce((val, dat) => val + dat)
                debitTotal += res
                return debitTotal
            })
            let ans = results.pop()
            setPurchases(ans.toFixed(2))

            //  Fetch POS Data
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            let getDatawithFilter = req.data.pos1.filter((data) => {
                let splittedMonth = data.date
                let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    setPos([]); setPurchases([])
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                    return data
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 < dateTrim) {
                    setPos(0)
                }
                else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
                    return data
                }
                else if (dateFilter.startdate == "" && dateFilter.enddate + 1 < dateTrim) {
                    setPos(0); setPurchases(0)
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate > dateTrim && dateFilter.enddate == "") {
                    setPos(0); setPurchases(0)
                }
                else if (dateFilter.startdate > dateTrim && dateFilter.enddate >= dateTrim) {
                    setPos(0); setPurchases(0)
                }
            })
            let result = getDatawithFilter.map((values) => {
                return +values.aftergranddisctotal
            }).reduce((val, dat) => val + dat)
            setPos(result.toFixed(2))

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Profit Loss Account',
        pageStyle: 'print'
    });

    // PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Profitlossaccount' })
        doc.save('Profit Loss Account.pdf')
    }

    useEffect(() => {
        fetchDatas();
    }, [purchases])

    return (
        <Box >
            <Headtitle title={'Profit Loss Account'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Profit Loss Account</Typography>

            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={4} md={4}>
                            <Grid container>
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{ marginTop: 1 }}>From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, startdate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Grid container>
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, enddate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={() => fetchDatas()}>Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid>
                            {isUserRoleCompare[0].printprofitlossaccount && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfprofitlossaccount && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />

                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Profitlossaccount">
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Particulars</StyledTableCell>
                                    <StyledTableCell align="center">Debit</StyledTableCell>
                                    <StyledTableCell align="center">Credit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow >
                                    <StyledTableCell>Total</StyledTableCell>
                                    <StyledTableCell align="center">{purchases > 0 ? purchases : 0}</StyledTableCell>
                                    <StyledTableCell align="center">{pos > 0 ? pos : 0}</StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell>Gross Profit</StyledTableCell>
                                    {pos > 0 && purchases > 0 ?
                                        (
                                            <><StyledTableCell colSpan={2} align="center">{(Number(pos) - Number(purchases)).toFixed(0)}</StyledTableCell></>
                                        ) :
                                        pos > 0 ?
                                            (
                                                <><StyledTableCell colSpan={2} align="center">{Number(pos).toFixed(0)}</StyledTableCell></>
                                            ) :
                                            purchases > 0 ?
                                                (
                                                    <><StyledTableCell colSpan={2} align="center">{-Number(purchases).toFixed(0)}</StyledTableCell></>
                                                ) :
                                                (
                                                    <><StyledTableCell colSpan={2} align="center">{0}</StyledTableCell></>
                                                )
                                    }
                                </StyledTableRow>
                                <StyledTableRow >
                                    <StyledTableCell>Net Total</StyledTableCell>
                                    <StyledTableCell colSpan={2} align="center">{purchases && purchases ? purchases : 0}</StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Profit Loss Account</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table sx={{}} aria-label="simple table" id="Profitlossaccount">
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Particulars</StyledTableCell>
                                        <StyledTableCell align="center">Debit</StyledTableCell>
                                        <StyledTableCell align="center">Credit</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow >
                                        <StyledTableCell>Total</StyledTableCell>
                                        <StyledTableCell align="center">{purchases && purchases ? purchases : 0}</StyledTableCell>
                                        <StyledTableCell align="center">{pos && pos ? pos : 0}</StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow >
                                        <StyledTableCell>Gross Profit</StyledTableCell>
                                        {pos > 0 && purchases > 0 ?
                                            (
                                                <><StyledTableCell colSpan={2} align="center">{(Number(pos) - Number(purchases)).toFixed(0)}</StyledTableCell></>
                                            ) :
                                            pos > 0 ?
                                                (
                                                    <><StyledTableCell colSpan={2} align="center">{Number(pos).toFixed(0)}</StyledTableCell></>
                                                ) :
                                                purchases > 0 ?
                                                    (
                                                        <><StyledTableCell colSpan={2} align="center">{Number(purchases).toFixed(0)}</StyledTableCell></>
                                                    ) :
                                                    (
                                                        <><StyledTableCell colSpan={2} align="center">{0}</StyledTableCell></>
                                                    )
                                        }
                                    </StyledTableRow>
                                    <StyledTableRow >
                                        <StyledTableCell>Net Total</StyledTableCell>
                                        <StyledTableCell colSpan={2} align="center">{purchases && purchases ? purchases : 0}</StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}
function Profitlossaccount() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Profitlossaccountlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Profitlossaccount;