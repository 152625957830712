import React, { useState, useEffect } from "react";
import { Box, Paper, Typography, Table, TableBody, TableContainer, TableHead, } from "@mui/material";
import { StyledTableRow, StyledTableCell } from "../components/Table";
import { dashboardstyle } from './Dashboardstyle';
import axios from 'axios';
import { toast } from 'react-toastify';

function Dashstockalert() {

  const [quantity, setQuantity] = useState();

  // Products
  const fetchProducts = async () => {
    try {
      let product = await axios.get('http://localhost:8003/api/products');
      let prodData = product.data.products.filter((data) => {
        return data.currentstock <= data.alertquantity || data.minquantity || data.mixquantity
      })
      setQuantity(prodData);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  useEffect(() => {
    fetchProducts();

  }, []);

  //  JQUERY
  // $(document).ready(function () {
  //     setTimeout(function () {
  //         $('#expcattable').DataTable({
  //             language: { search: '', searchPlaceholder: "Search..." },
  //             lengthMenu: [25, 50, 100, 200, 500, 1000],
  //         });
  //     }, 1000);
  // });

  return (
    <Box>
      <Box sx={{ margin: '10px' }}>
        <TableContainer component={Paper} >
          <Table aria-label="customized table" id="draftstable">
            <TableHead>
              <Box sx={dashboardstyle}>
                <Typography variant='h6'>Stock Alert</Typography>
              </Box>
              <StyledTableRow>
                <StyledTableCell align="left">Location</StyledTableCell>
                <StyledTableCell align="left">Code</StyledTableCell>
                <StyledTableCell align="left">Product</StyledTableCell>
                <StyledTableCell align="left">Current Stock</StyledTableCell>
                <StyledTableCell align="left">Alert Quantity</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {quantity && (
                quantity.map((row, index) => (
                  <StyledTableRow key={index} >
                    <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                    {/* <StyledTableCell align="left">{row.businesslocation}{toast.warning(`Current Stock of ${row.productname} is ${row.currentstock}`)}</StyledTableCell> */}
                    <StyledTableCell align="left">{row.sku}</StyledTableCell>
                    <StyledTableCell align="left">{row.productname}</StyledTableCell>
                    <StyledTableCell align="left">{row.currentstock}</StyledTableCell>
                    <StyledTableCell align="left">{row.alertquantity}</StyledTableCell>
                  </StyledTableRow>
                )))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default Dashstockalert;