import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Dashboard
import Dashboard from '../dashboard/Dashboard';
import Profiles from '../dashboard/Profile';

// user module import 
import Usercreate from '../pages/user/users/Create';
import Useredit from '../pages/user/users/Edit';
import Userview from '../pages/user/users/View';
import Userslist from '../pages/user/users/List';
import Rolecreate from '../pages/user/roles/Create';
import Roleedit from '../pages/user/roles/Edit';
import Roleslist from '../pages/user/roles/List';
import Rolesprint from '../pages/user/roles/Print';
import Departmentscreate from '../pages/user/department/Create';
import Departmentsedit from '../pages/user/department/Edit';
import Departmentlist from '../pages/user/department/List';
import Salescreate from '../pages/user/salesagent/Create';
import Salesedit from '../pages/user/salesagent/Edit';
import Saleslist from '../pages/user/salesagent/List';
import Salesprint from '../pages/user/salesagent/Print';

// customer module
import Customerlist from '../pages/contacts/customer/List';
import Customeredit from '../pages/contacts/customer/Edit';
import ImportCustomerList from '../pages/contacts/customer/import';
import Listretail from '../pages/contacts/customer/retail/List';
import Listwholesale from '../pages/contacts/customer/wholesale/List';
import Customerpaymentreport from '../pages/contacts/customer/Customerpaymentreport';
import Custwisepaymentreport from '../pages/contacts/customer/Custwisepaymentreport';
import Custacctstatementlist from '../pages/contacts/customer/Customeraccountstatement';
import Custacctsummary from '../pages/contacts/customer/Customeracctsummary';
import Custmasterretaillist from '../pages/contacts/customer/retail/Create';
import Custmasterwsalelist from '../pages/contacts/customer/wholesale/Create';
import Custpayregisterlist from '../pages/contacts/customer/Customerpaymentregister';
import Retailfollowcustomerlist from '../pages/contacts/customer/Followup_retail';
import Wholesalefollowupcustomerlist from '../pages/contacts/customer/Followup_wholesale';
import Customerbalanceregisterlist from '../pages/contacts/customer/Customerbalanceregister';
import Customerduesreportlist from '../pages/contacts/customer/Customerduesreport';
import Customerbillsreportlist from '../pages/contacts/customer/Customerbillsreport';
import Categorywisecustomerlist from '../pages/contacts/customer/Categorywise_list';
import AreawisecustomerlistList from '../pages/contacts/customer/Areawisecustomerlist';
import Custbillregisterlist from '../pages/contacts/customer/Customerbillsregister';
import Customerdrugentry from '../pages/contacts/customer/Customerdrugentry';
import Referencewisedues from '../pages/contacts/customer/Referencewiseduesreport';
import Referencewiseduesreportsales from '../pages/contacts/customer/Referencewiseduessales';
import Balancewisecustomerdues from '../pages/contacts/customer/Balanacewisecustomerduesreport';
import Customeroverduesreport from '../pages/contacts/customer/Customeroverduesreport';
import Customeroverdueabstract from '../pages/contacts/customer/Customeroverdueabstract';
import Referencewisepaymentreport from '../pages/contacts/customer/Referencewisepaymentreport';
import Remarkswisereport from '../pages/contacts/customer/Remarkswisereport';
import Referencewisepaymentsummary from '../pages/contacts/customer/Referencewisepaymentsummary';
import CustomerGroupsList from '../pages/contacts/customergroup/List';
import Customorgroupcreate from '../pages/contacts/customergroup/Create';
import Customergrpedit from '../pages/contacts/customergroup/Edit';

//supplier module
import Supplierlist from '../pages/contacts/suppliers/supplier/List';
import Suppliercreate from '../pages/contacts/suppliers//supplier/Create';
import Supplieredit from '../pages/contacts/suppliers/supplier/Edit';
import Supplierview from '../pages/contacts/suppliers/supplier/View';
import Supplierimport from '../pages/contacts/suppliers/supplier/Import';
import Importsupplierlist from '../pages/contacts/suppliers/Importsupplierlist';
import Supplierduesreport from '../pages/contacts/suppliers/Supplierduesreport';
import Balancewisesupduesreport from '../pages/contacts/suppliers/Balancewisesupduesreport';
import Supplieroverduesreport from '../pages/contacts/suppliers/Supplieroverduesreport';
import Supplierbalanceabstract from '../pages/contacts/suppliers/Supplierbalanceabstract';
import Supplierpaymentsreport from '../pages/contacts/suppliers/Supplierpaymentsreport';
import Supplierwisepaymentsreport from '../pages/contacts/suppliers/Supplierwisepaymentsreport';
import Supplierbillsreport from '../pages/contacts/suppliers/Supplierbillsreport';
import Suppliersreport from '../pages/contacts/suppliers/Suppliersreport';

// /* product module import */
import Productlist from '../pages/products/product/List';
import Productcreate from '../pages/products/product/Create';
import Productedit from '../pages/products/product/Edit';
import Productview from '../pages/products/product/View';
import Categorieslist from '../pages/products/category/List';
import Categorycreate from '../pages/products/category/Create';
import Categoriesedit from '../pages/products/category/Edit';
import ImportProducts from '../pages/products/ImportProducts';
import Schedulecreate from '../pages/products/Schedule';
import Discountlist from '../pages/products/discount/List';
import Discountcreate from '../pages/products/discount/Create';
import Discountedit from '../pages/products/discount/Edit';
import Categorywiselist from '../pages/products/Categorywiselist';
import Moleculewiselist from '../pages/products/Moleculeswiselist';
import Manufacturewiselist from '../pages/products/Manufacturewiselist';
import Schedulewiselist from '../pages/products/Schedulewiselist';
import Categorywisesummary from '../pages/products/Categorywisesummary';
import Moleculewisesummary from '../pages/products/Moleculeswisesummary';
import Manufacturewisesummary from '../pages/products/Manufacturewisesummary';
import Unitlist from '../pages/products/unit/List';
import Unitscreate from '../pages/products/unit/Create';
import Unitedit from '../pages/products/unit/Edit';
import Itemsearch from '../pages/products/Itemsearch';

// import Stock module
import Stockupdate from '../pages/stock/Stockupdate';
import Manualstockentry from '../pages/stock/Manualstockentry';
import Stockimport from '../pages/stock/Stockimport';
import Packqtyupdate from '../pages/stock/Packqualityupdate';
import Manufactureupdate from '../pages/stock/Manufactureupdate';
import Itemcodeupdate from '../pages/stock/Itemcodeupdate';
import Stockoverviewreport from '../pages/stock/Stockoverviewreport';
import Stockexpiryreport from '../pages/stock/Stockexpiryreport';
import Expiryreport from '../pages/stock/Expiryreport';
import Nillstock from '../pages/stock/Nillstockreport';
import MinimumStock from '../pages/stock/Minimumstockreport';
import Itemwisestockreport from '../pages/stock/Itemwisestockreport';
import Nonmovingstockreport from '../pages/stock/Nonmovingstockreport';
import Batchwisestockreport from '../pages/stock/Batchwisestockreport';
import Batchwisestockdetail from '../pages/stock/Batchwisestockdetails';

// purchase module
import Purchaseregister from '../pages/purchase/purchase/Create';
import Purchasereport from '../pages/purchase/Purchasereport';
import Purchaseediregister from '../pages/purchase/purchase/Edit';
import Purchaseview from '../pages/purchase/purchase/View';
import Purchaselist from '../pages/purchase/purchase/List';
import Purchasereturncreate from '../pages/purchase/purchasereturn/Create';
import Purchasereutrnedit from '../pages/purchase/purchasereturn/Edit';
import Purchasereturnlist from '../pages/purchase/purchasereturn/List';
import Purchasereutrnview from '../pages/purchase/purchasereturn/view';
import SupplierProduct from '../pages/purchase/supplierproduct/Create'
import SupplierProductList from '../pages/purchase/supplierproduct/List'
import SupplierProductEdit from '../pages/purchase/supplierproduct/Edit';
import SupplierProductView from '../pages/purchase/supplierproduct/View'
import Purchasedetailreport from '../pages/purchase/Purchasedetailedreport';
import Supplierwisepurchasereport from '../pages/purchase/Supplierwisepurchasereport';
import Itemwisepurchase from '../pages/purchase/Itemwisepurchasereport';
import Categorywisepurchase from '../pages/purchase/Categorywisepurchase';
import Supplierwisepurchase from '../pages/purchase/Supplierwisepurchase';
import Manufacturewisepurchase from '../pages/purchase/Manufacturewisepurchase';
import Purchasereturnreport from '../pages/purchase/Purchasereturnreport';
import Purchasereturnlists from '../pages/purchase/purchasereturn/List';
import Purchaseareturncreate from '../pages/purchase/purchasereturn/Create';
import Purchasereturnedit from '../pages/purchase/purchasereturn/Edit';
import Itemwisepurchasereutrn from '../pages/purchase/Itemwisepurchasereturn';

// sell module
import Poslist from '../pages/sell/pos/List';
import Poscreate from '../pages/sell/pos/Create';
import Posview from '../pages/sell/pos/View';
import Draftlist from '../pages/sell/draft/List';
import Draftedit from '../pages/sell/draft/Edit';
import Draftview from '../pages/sell/draft/View';
import Quotationlist from '../pages/sell/quotation/List';
import Quotationview from '../pages/sell/quotation/View';
import Quotationedit from '../pages/sell/quotation/Edit';
import Sellreturncreate from '../pages/sell/Sellreturn/Create';
import Sellreturnlist from '../pages/sell/Sellreturn/List';
import Selledit from '../pages/sell/Sellreturn/Edit';
import Sellview from '../pages/sell/Sellreturn/View';
import Daywisesales from '../pages/sell/Daywisesales';
import Diagnosislist from '../pages/sell/Diagnosiswisesales';
import Diagnosissummarypage from '../pages/sell/Diagnosiswisesummary';
import Manufacturewisesales from '../pages/sell/Manufacturewisesales';
import Moleculewisesales from '../pages/sell/Moleculewisesales';
import Salessummarylist from '../pages/sell/Salessummary';
import Supplierwisesales from '../pages/sell/Supplierwisesales';
import Drwisesales from '../pages/sell/Drwisesales';
import Categorywisesales from '../pages/sell/Categorywisesales';
import Areawisesales from '../pages/sell/Areawisesales';
import Patientwisesales from '../pages/sell/Patientwisesales';
import Itemsaleslist from '../pages/sell/Itemwisesales';
import Salesreportpage from '../pages/sell/Salesreport';
import Drwisereportpage from '../pages/sell/Drwisesalereport';
import Patientwisesalesreport from '../pages/sell/Patientwisesalesreport';
import Salereturnreport from '../pages/sell/Salereturnreaport';
import Salereturnsummary from '../pages/sell/Salereturnsummary';
import Itemwisesalereturnlist from '../pages/sell/Itemwisesalereturn';
import Supplierwisereturnlist from '../pages/sell/Supplierwisesalereturn';
import Manufacturewisesalesreturnlist from '../pages/sell/Manufacturewisereturnstatus';
import Drugregisterlist from '../pages/sell/Drugregister';
import Schedulewisesalelist from '../pages/sell/Schedulewisesales';

// estimate module
import Estimatepreparation from '../pages/estimate/estimate/Create';
import Estimatereturnregister from '../pages/estimate/Estimatereturnregister';
import Estimatelist from '../pages/estimate/estimate/List';
import Estimatereport from '../pages/estimate/Estimatereport';
import Customerwiseestimatereport from '../pages/estimate/Customerwiseestimatereport';
import Daywiseestimatereport from '../pages/estimate/Daywiseestimatereport';
import Itemwiseestimate from '../pages/estimate/Itemwiseestimate';
import Categorywiseestimate from '../pages/estimate/Categorywiseestimate';
import Manufacturewiseestimate from '../pages/estimate/Manufacturewiseestimate';
import Supplierwiseestimate from '../pages/estimate/Supplierwiseestimate';
import Areawiseestimate from '../pages/estimate/Areawiseestimate';
import Referencewiseestimate from '../pages/estimate/Referencewiseestimate';
import Estimatereturnreport from '../pages/estimate/Estimatereturnreport';
import Itemwiseestimatereturn from '../pages/estimate/Itemwiseestimatereturn';
import Supplierwiseestimatereturn from '../pages/estimate/Supplierwiseestimatereturn';
import Manufacturewiseestimatereturn from '../pages/estimate/Manufacturewiseestimatereturn';
import Estimatequotationlist from '../pages/estimate/quotation/List';
import Estimatedraftlist from '../pages/estimate/draft/List'
import EstimateView from '../pages/estimate/estimate/View';
import DraftEstimateedit from '../pages/estimate/draft/Edit';
import QuotationEstimateedit from '../pages/estimate/quotation/Edit'
import QuotationEstimatView from '../pages/estimate/quotation/View';
import DraftEstimateView from '../pages/estimate/draft/View';

// Expenses
import Expenselist from '../pages/expenses/expense/List';
import Expensecreate from '../pages/expenses/expense/Create';
import Expenseedit from '../pages/expenses/expense/Edit';
import Expensecategorylist from '../pages/expenses/expensecategory/List';
import Expensecategoryedit from '../pages/expenses/expensecategory/Edit';

// Account Modules
import Accountandgroupcreationlist from '../pages/account/Accountandgroupcreation';
import Cashbookregister from '../pages/account/Cashbookregister';
import Profitlossaccount from '../pages/account/Profitlossaccount';
import Trailbalance from '../pages/account/Trailbalance';
import Groupsummary from '../pages/account/Groupsummarys';
import Bankstatement from '../pages/account/Bankstatement';
import CashBookReport from '../pages/account/CashBookReport'
import CashbookRegisterreport from '../pages/account/CashbookRegisterreport';
import Daybookprint from '../pages/account/Daybookprint';
import Voucherlist from '../pages/account/voucher/List';
import Vouchercreate from '../pages/account/voucher/Create';
import Voucheredit from '../pages/account/voucher/Edit';
import Voucherview from '../pages/account/voucher/View';

//bank 
import Bankcreate from '../pages/bank/bank/Create';
import Bankview from '../pages/bank/bank/View';
import Banklist from '../pages/bank/bank/List';
import Bankedit from '../pages/bank/bank/Edit';
import Bankreport from '../pages/bank/bank/Report';  
import Bookcreate from '../pages/bank/book/Create';
import Bookreport from '../pages/bank/book/Report'; 

// Report
import Billwiseprofitwholesale from '../pages/report/Billwiseprofitwholesale';
import Billwiseprofitretail from '../pages/report/Billwiseprofitretail';
import Billwiseprofitestimate from '../pages/report/Billwiseprofitestimate';
import Daywiseprofitwholesale from '../pages/report/Daywiseprofitwholesale';
import Daywiseprofitretail from '../pages/report/Daywiseprofitretail';
import Daywiseprofitestimate from '../pages/report/Daywiseprofitestimate';
import Itemwiseprofitwholesale from '../pages/report/Itemwiseprofitwholesale';
import Itemwiseprofitretail from '../pages/report/Itemwiseprofitretail';
import Itemwiseprofitestimate from '../pages/report/Itemwiseprofitestimate';
import Customerwiseprofitwholesale from '../pages/report/Customerwiseprofitwholesale';
import Customerwiseprofitestimate from '../pages/report/Customerwiseprofitestimate';
import Referencesummary from '../pages/report/Referencesummary';
import Referencecomparison from '../pages/report/Referencecomparison';
import Customercomparison from '../pages/report/Customercomparison';

// Settings
import Businesssettings from '../pages/settings/business/List';
import Locationlist from '../pages/settings/location/List';
import Locationcreate from '../pages/settings/location/Create';
import Businesslocationedit from '../pages/settings/location/Edit';
import Taxratelist from '../pages/settings/taxrate/List';
import TaxGrpCreate from '../pages/settings/taxrate/taxgroup/Create';
import TaxrateCreate from '../pages/settings/taxrate/taxrate/Create';
import Hsncreate from '../pages/settings/taxrate/hsn/Create';
import Taxrateedit from '../pages/settings/taxrate/taxrate/Edit';
import Taxrategprint from '../pages/settings/taxrate/taxgroup/Print';
import Taxrateprint from '../pages/settings/taxrate/taxrate/Print';
import Hsnprint from '../pages/settings/taxrate/hsn/Print';
import Alpharatelist from '../pages/settings/alpharate/List';
import Alphacreate from '../pages/settings/alpharate/Create';
import Alphaedit from '../pages/settings/alpharate/Edit';
import Alpharateprint from '../pages/settings/alpharate/Print';
import Paymentintegrationlist from '../pages/settings/paymentintegration/List';
import Paymentintegrationcreate from '../pages/settings/paymentintegration/Create';
import Payintegrationedit from '../pages/settings/paymentintegration/Edit';
import Payintegrationview from '../pages/settings/paymentintegration/View';
import Gstrpurchase from '../pages/settings/taxrate/taxrate/Gstrpurchase';
import Gstrretailsalesreturn from '../pages/settings/taxrate/taxrate/Gstrretailsalesreturn';
import Hsnwisesummarypurchase from '../pages/settings/taxrate/taxrate/Hsnwisesummarypurchase';
import Hsnwisereportwholesales from '../pages/settings/taxrate/taxrate/Hsnwisereportwholesales';
import GstrpurchaseReturn from '../pages/settings/taxrate/taxrate/GstrpurchaseReturn';
import Gstrwholesale from '../pages/settings/taxrate/taxrate/Gstrwholesale';
import GstrwholesaleReturn from '../pages/settings/taxrate/taxrate/GstrwholesaleReturn';
import GstrsalesRetail from '../pages/settings/taxrate/taxrate/GstrsalesRetail';
import HSNwisereportretails from '../pages/settings/taxrate/taxrate/HSNwisereportretails';
import HSNwisereportwholesale from '../pages/settings/taxrate/taxrate/HSNwisereportwholesale';
import HSNwisereportwholesalereturn from '../pages/settings/taxrate/taxrate/HSNwisereportwholesalereturn';
import HSNwisereportretailsalesreturn from '../pages/settings/taxrate/taxrate/HSNwisereportretailsalesreturn';


function Applicationstack() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Dashboard routes */}
          <Route path="/" element={<Dashboard />} />
          <Route path="profile/edit/:id" element={<Profiles />} />

          {/* user module */}
          <Route path="user/user/create" element={<Usercreate />} />
          <Route path="user/user/edit/:id" element={<Useredit />} />
          <Route path="user/user/view/:id" element={<Userview />} />
          <Route path="user/user/list" element={<Userslist />} />
          <Route path="user/role/create" element={<Rolecreate />} />
          <Route path="user/role/edit/:id" element={<Roleedit />} />
          <Route path="user/role/list" element={<Roleslist />} />
          <Route path="user/role/print" element={<Rolesprint />} />
          <Route path="user/sales/create" element={<Salescreate />} />
          <Route path="user/sales/edit/:id" element={<Salesedit />} />
          <Route path="user/sales/list" element={<Saleslist />} />
          <Route path="user/sales/print" element={<Salesprint />} />
          <Route path="user/department/create" element={<Departmentscreate />} />
          <Route path="user/department/edit/:id" element={<Departmentsedit />} />
          <Route path="user/department/list" element={<Departmentlist />} />

          {/* contacts module */}
          <Route path="contact/customer/list" element={<Customerlist />} />
          <Route path="contact/customer/edit/:id" element={<Customeredit />} />
          <Route path="contact/customer/import" element={<ImportCustomerList />} />
          <Route path="contact/customer/retail/list" element={<Listretail />} />
          <Route path="contact/customer/wholesale/list" element={<Listwholesale />} />
          <Route path="contact/customer/customerpaymentreport" element={<Customerpaymentreport />} />
          <Route path="contact/customer/customerwisepaymentreport" element={<Custwisepaymentreport />} />
          <Route path="contact/customer/customeraccountstatement" element={<Custacctstatementlist />} />
          <Route path="contact/customer/customeraccountsummary" element={<Custacctsummary />} />
          <Route path="contact/customer/retail/create" element={<Custmasterretaillist />} />
          <Route path="contact/customer/wholesale/create" element={<Custmasterwsalelist />} />
          <Route path="contact/customer/customerpaymentregister" element={<Custpayregisterlist />} />
          <Route path="contact/customer/retailfollowcustomer" element={<Retailfollowcustomerlist />} />
          <Route path="contact/customer/wholesalefollowupcustomer" element={<Wholesalefollowupcustomerlist />} />
          <Route path="contact/customer/customerbalanceregister" element={<Customerbalanceregisterlist />} />
          <Route path="contact/customer/customerduesreport" element={<Customerduesreportlist />} />
          <Route path="contact/customer/customerbillsreport" element={<Customerbillsreportlist />} />
          <Route path="contact/customer/categorywisecustomerlist" element={<Categorywisecustomerlist />} />
          <Route path="contact/customer/areawisecustomerlist" element={<AreawisecustomerlistList />} />
          <Route path="contact/customer/customerbillsregister" element={<Custbillregisterlist />} />
          <Route path="contact/customer/customerdrugentry" element={<Customerdrugentry />} />
          <Route path="contact/customer/Referencewiseduesreport" element={<Referencewisedues />} />
          <Route path="contact/customer/Referencewiseduesales" element={<Referencewiseduesreportsales />} />
          <Route path="contact/customer/Balanacewisecustomerduesreport" element={<Balancewisecustomerdues />} />
          <Route path="contact/customer/Customeroverduesreport" element={<Customeroverduesreport />} />
          <Route path="contact/customer/Customeroverdueabstract" element={<Customeroverdueabstract />} />
          <Route path="contact/customer/Referencewisepaymentreport" element={<Referencewisepaymentreport />} />
          <Route path="contact/customer/Remarkswisereport" element={<Remarkswisereport />} />
          <Route path="contact/customer/Referencewisepaymentsummary" element={<Referencewisepaymentsummary />} />
          <Route path="contact/customergroup/list" element={<CustomerGroupsList />} />
          <Route path="contact/customergroup/create" element={<Customorgroupcreate />} />
          <Route path="contact/customergroup/edit/:id" element={<Customergrpedit />} />

          {/* supplier module */}
          <Route path="contact/supplier/list" element={<Supplierlist />} />
          <Route path="contact/supplier/create" element={<Suppliercreate />} />
          <Route path="contact/supplier/edit/:id" element={<Supplieredit />} />
          <Route path="contact/supplier/view/:id" element={<Supplierview />} />
          <Route path="contact/supplier/import" element={<Supplierimport />} />
          <Route path="contact/supplier/importsupplier" element={<Importsupplierlist />} />
          <Route path="contact/supplier/supplierduereport" element={<Supplierduesreport />} />
          <Route path="contact/supplier/balancewisesupduereport" element={<Balancewisesupduesreport />} />
          <Route path="contact/supplier/supplieroverduereport" element={<Supplieroverduesreport />} />
          <Route path="contact/supplier/supplierbalanceabstract" element={<Supplierbalanceabstract />} />
          <Route path="contact/supplier/supplierpaymentsreport" element={<Supplierpaymentsreport />} />
          <Route path="contact/supplier/supplierwisepaymentsreport" element={<Supplierwisepaymentsreport />} />
          <Route path="contact/supplier/supplierbillsreport" element={<Supplierbillsreport />} />
          <Route path="contact/supplier/suppliersreport" element={<Suppliersreport />} />

          {/* products module */}
          <Route path="product/product/list" element={<Productlist />} />
          <Route path="product/product/create" element={<Productcreate />} />
          <Route path="product/product/edit/:id" element={<Productedit />} />
          <Route path="product/product/view/:id" element={<Productview />} />
          <Route path="product/importproducts" element={<ImportProducts />} />
          <Route path="product/category/list" element={<Categorieslist />} />
          <Route path="product/category/create" element={<Categorycreate />} />
          <Route path="product/category/edit/:id" element={<Categoriesedit />} />
          <Route path="product/discount/list" element={<Discountlist />} />
          <Route path="product/discount/create" element={<Discountcreate />} />
          <Route path="product/discount/edit/:id" element={<Discountedit />} />
          <Route path="product/categorywiselist" element={<Categorywiselist />} />
          <Route path="product/moleculewiselist" element={<Moleculewiselist />} />
          <Route path="product/manufacturewiselist" element={<Manufacturewiselist />} />
          <Route path="product/schedulewiselist" element={<Schedulewiselist />} />
          <Route path="product/categorysummary" element={<Categorywisesummary />} />
          <Route path="product/moleculesummary" element={<Moleculewisesummary />} />
          <Route path="product/manufacturesummary" element={<Manufacturewisesummary />} />
          <Route path="product/schedule" element={<Schedulecreate />} />
          <Route path="product/unit/create" element={<Unitscreate />} />
          <Route path="product/unit/list" element={<Unitlist />} />
          <Route path="product/unit/edit/:id" element={<Unitedit />} />
          <Route path="product/itemsearch" element={<Itemsearch />} />

          { /* stock module */ }
            <Route path="stock/stock/update" element={<Stockupdate />} />
            <Route path="stock/stock/stockentry" element={<Manualstockentry />} />
            <Route path="stock/stock/import" element={<Stockimport />} />
            <Route path="stock/packqty/update" element={<Packqtyupdate />} />
            <Route path="stock/manufacture/update" element={<Manufactureupdate />} />
            <Route path="stock/itemcode/update" element={<Itemcodeupdate />} />
            <Route path="stock/itemwise/report" element={<Itemwisestockreport />} />
            <Route path="stock/stock/overviewreport" element={<Stockoverviewreport />} />
            <Route path="stock/stock/expiryreport" element={<Stockexpiryreport />} />
            <Route path="stock/expiryreport" element={<Expiryreport />} />
            <Route path="stock/stock/nillreport" element={<Nillstock />} />
            <Route path="stock/stock/minimumreport" element={<MinimumStock />} />
            <Route path="stock/stock/nonmovingreport" element={<Nonmovingstockreport />} />
            <Route path="stock/stock/batchwisereport" element={<Batchwisestockreport />} />
            <Route path="stock/stock/batchwisedetails" element={<Batchwisestockdetail />} />

          { /* purchase module */}
          <Route path="purchase/Purchase/create" element={<Purchaseregister />} />
          <Route path="purchase/Purchase/edit/:id" element={<Purchaseediregister />} />
          <Route path="purchase/purchase/view/:id" element={<Purchaseview />} />
          <Route path="purchase/Purchase/list" element={<Purchaselist />} />
          <Route path="purchase/purchasereturn/create" element={<Purchasereturncreate />} />
          <Route path="purchase/purchasereturn/edit/:id" element={<Purchasereutrnedit />} />
          <Route path="purchase/purchasereturn/list" element={<Purchasereturnlist />} />
          <Route path="purchase/purchasereturn/view/:id" element={<Purchasereutrnview />} />
          <Route path="purchase/SupplierProductlink" element={<SupplierProduct />} />
          <Route path="purchase/SupplierProduct/ListSupplierProduct" element={<SupplierProductList />}/>
          <Route path="purchase/SupplierProduct/SupplierProductEdit/:id" element={<SupplierProductEdit />}/>
          <Route path="purchase/SupplierProduct/SupplierProductView/:id" element={<SupplierProductView />}/>
          <Route path="purchase/purchasereport" element={<Purchasereport />} />
          <Route path="purchase/purchasedetailedreport" element={<Purchasedetailreport />} />
          <Route path="purchase/supplierwisereport" element={<Supplierwisepurchasereport />} />
          <Route path="purchase/itemwisepurchase" element={<Itemwisepurchase />} />
          <Route path="purchase/categorywisepurchase" element={<Categorywisepurchase />} />
          <Route path="purchase/supplierwisepurchase" element={<Supplierwisepurchase />} />
          <Route path="purchase/manufacturwisepurchase" element={<Manufacturewisepurchase />} />
          <Route path="purchase/purchasereturnreport" element={<Purchasereturnreport />} />
          <Route path="purchase/itemwisepurchasereturn" element={<Itemwisepurchasereutrn />} />
          <Route path="purchase/purchasereturnlist" element={<Purchasereturnlists />} />
          <Route path="purchase/purchaseareturncreate" element={<Purchaseareturncreate />} />
          <Route path="purchase/Purchasereturnedit" element={<Purchasereturnedit />} />

          { /* sell module */}
          <Route path="sell/pos/list" element={<Poslist />} />
          <Route path="sell/pos/create" element={<Poscreate />} />
          <Route path="sell/pos/view/:id" element={<Posview />} />
          <Route path="sell/draft/list" element={<Draftlist />} />
          <Route path="sell/draft/view/:id" element={<Draftview />} />
          <Route path="sell/draft/edit/:id" element={<Draftedit />} />
          <Route path="sell/quotation/list" element={<Quotationlist />} />
          <Route path="sell/quotation/edit/:id" element={<Quotationedit/>} />
          <Route path="sell/quotation/view/:id" element={<Quotationview />} />
          <Route path="sell/sellreturn/create" element={<Sellreturncreate />} />
          <Route path="sell/sellreturn/list" element={<Sellreturnlist />} />
          <Route path="sell/sellreturn/create/:id" element={<Selledit />} />
          <Route path="sell/sellreturn/view/:id" element={<Sellview />} />
          <Route path="sell/daywisesales" element={<Daywisesales />} />
          <Route path="sell/diagnosiswisesales" element={<Diagnosislist />} />
          <Route path="sell/diagnosiswisesummary" element={<Diagnosissummarypage />} />
          <Route path="sell/manufacturewisesales" element={<Manufacturewisesales />} />
          <Route path="sell/moleculewisesales" element={<Moleculewisesales />} />
          <Route path="sell/salessummary" element={<Salessummarylist />} />
          <Route path="sell/supplierwisesales" element={<Supplierwisesales />} />
          <Route path="sell/drwisesales" element={<Drwisesales />} />
          <Route path="sell/categorywisesales" element={<Categorywisesales />} />
          <Route path="sell/areawisesales" element={<Areawisesales />} />
          <Route path="sell/patientwisesales" element={<Patientwisesales />} />
          <Route path="sell/itemwisesales" element={<Itemsaleslist />} />
          <Route path="sell/salesreport" element={<Salesreportpage />} />
          <Route path="sell/drwisesalereport" element={<Drwisereportpage />} />
          <Route path="sell/patientwisesalesreport" element={<Patientwisesalesreport />} />
          <Route path="sell/salereturnreaport" element={<Salereturnreport />} />
          <Route path="sell/Salereturnsummary" element={<Salereturnsummary />} />
          <Route path="sell/itemwisesalereturn" element={<Itemwisesalereturnlist />} />
          <Route path="sell/supplierwisesalereturn" element={<Supplierwisereturnlist />} />
          <Route path="sell/Manufacturewisereturnstatus" element={<Manufacturewisesalesreturnlist />} />
          <Route path="sell/Drugregister" element={<Drugregisterlist />} />
          <Route path="sell/schedulewisesalelist" element={<Schedulewisesalelist />} />


          { /* Estimate module */}
          <Route path="estimate/estimate/create" element={<Estimatepreparation />} />
          <Route path="estimate/estimate/create/:id" element={<Estimatepreparation />} />   
          <Route path="estimate/estimate/view/:id" element={<EstimateView />} />
          <Route path="estimate/estimatereturnregister" element={<Estimatereturnregister />} />
          <Route path="estimate/estimatereport" element={<Estimatereport />} />
          <Route path="estimate/estimate/list" element={<Estimatelist />} />
          <Route path="estimate/customerwiseestimatereport" element={<Customerwiseestimatereport />} />
          <Route path="estimate/daywiseestimatereport" element={<Daywiseestimatereport />} />
          <Route path="estimate/itemwiseestimate" element={<Itemwiseestimate />} />
          <Route path="estimate/categorywiseestimate" element={<Categorywiseestimate />} />
          <Route path="estimate/manufacturewiseestimate" element={<Manufacturewiseestimate />} />
          <Route path="estimate/supplierwiseestimate" element={<Supplierwiseestimate />} />
          <Route path="estimate/areawiseestimate" element={<Areawiseestimate />} />
          <Route path="estimate/referencewiseestimate" element={<Referencewiseestimate />} />
          <Route path="estimate/estimatereturnreport" element={<Estimatereturnreport />} />
          <Route path="estimate/itemwiseestimatereturn" element={<Itemwiseestimatereturn />} />
          <Route path="estimate/supplierwiseestimatereturn" element={<Supplierwiseestimatereturn />} />
          <Route path="estimate/manufacturewiseestimatereturn" element={<Manufacturewiseestimatereturn />} />
          <Route path="estimate/draft/list" element={<Estimatedraftlist />} />
          <Route path="estimate/quotation/list" element={<Estimatequotationlist />} />
          <Route path="estimate/draft/list" element={<Estimatedraftlist />} />
          <Route path="estimate/draft/edit/:id" element={<DraftEstimateedit />} />
          <Route path="estimate/quotation/list" element={<Estimatequotationlist />} />
          <Route path="estimate/quotation/edit/:id" element={<QuotationEstimateedit />} />
          <Route path="estimate/quotation/view/:id" element={<QuotationEstimatView />} />
          <Route path="estimate/drafts/view/:id" element={<DraftEstimateView />} />

          { /* Expenses module */}
          <Route path="expense/expense/list" element={<Expenselist />} />
          <Route path="expense/expense/create" element={<Expensecreate />} />
          <Route path="expense/espense/edit/:id" element={<Expenseedit />} />
          <Route path="expense/expensecategory/list" element={<Expensecategorylist />} />
          <Route path="expense/expensecategory/edit/:id" element={<Expensecategoryedit />} />

          {/* Account Modules*/}
          <Route path="account/accountandgroupcreation" element={<Accountandgroupcreationlist />} />
          <Route path="account/cashbookregister" element={<Cashbookregister />} />
          <Route path="account/profitlossaccount" element={<Profitlossaccount />} />
          <Route path="account/trailbalance" element={<Trailbalance />} />
          <Route path="account/groupsummarys" element={<Groupsummary />} />
          <Route path="account/bankstatement" element={<Bankstatement />} />
          <Route path="account/CashbookRegisterreport" element={<CashbookRegisterreport />} />
          <Route path="account/CashBookReport" element={<CashBookReport />} />
          <Route path="account/Daybookprint" element={<Daybookprint />} />
          <Route path="account/voucher/list" element={<Voucherlist />} />
          <Route path="account/voucher/create" element={<Vouchercreate />} />
          <Route path="account/voucher/edit/:id" element={<Voucheredit />} />
          <Route path="account/voucher/view/:id" element={<Voucherview />} />

          {/* bank module */}
          <Route path="bank/bank/create" element={<Bankcreate />} />
          <Route path="bank/bank/list" element={<Banklist />} />
          <Route path="bank/bank/edit/:id" element={<Bankedit />} />
          <Route path="bank/bank/view/:id" element={<Bankview />} />
			    <Route path="bank/bank/report" element={<Bankreport />} />
          <Route path="bank/book/create" element={<Bookcreate />} />
			    <Route path="bank/book/report" element={<Bookreport />} />

          { /* Report module */}
          <Route path="report/billwiseprofitwholesale" element={<Billwiseprofitwholesale />} />
          <Route path="report/billwiseprofitretail" element={<Billwiseprofitretail />} />
          <Route path="report/billwiseprofitestimate" element={<Billwiseprofitestimate />} />
          <Route path="report/daywiseprofitwholesale" element={<Daywiseprofitwholesale />} />
          <Route path="report/Daywiseprofitretail" element={<Daywiseprofitretail />} />
          <Route path="report/daywiseprofitestimate" element={<Daywiseprofitestimate />} />
          <Route path="report/itemwiseprofitwholesale" element={<Itemwiseprofitwholesale />} />
          <Route path="report/itemwiseprofitretail" element={<Itemwiseprofitretail />} />
          <Route path="report/itemwiseprofitestimate" element={<Itemwiseprofitestimate />} />
          <Route path="report/customerwiseprofitwholesale" element={<Customerwiseprofitwholesale />} />
          <Route path="report/customerwiseprofitestimate" element={<Customerwiseprofitestimate />} />
          <Route path="report/referencesummary" element={<Referencesummary />} />
          <Route path="report/referencecomparison" element={<Referencecomparison />} />
          <Route path="report/customercomparison" element={<Customercomparison />} />

          { /* Settings module */}
          <Route path="settings/business/list" element={<Businesssettings />} />
          <Route path="settings/location/list" element={<Locationlist />} />
          <Route path="settings/location/create" element={<Locationcreate />} />
          <Route path="settings/location/edit/:id" element={<Businesslocationedit />} />
          <Route path="settings/alpharate/list" element={<Alpharatelist />} />
          <Route path="settings/alpharate/create" element={<Alphacreate />} />
          <Route path="settings/alpharate/view/:id" element={<Alphaedit />} />
          <Route path="settings/alpharate/print/:id" element={<Alpharateprint />} />
          <Route path="settings/taxrate/list" element={<Taxratelist />} />
          <Route path="settings/taxrate/create" element={<TaxrateCreate />} />
          <Route path="settings/hsn/create" element={<Hsncreate />} />
          <Route path="settings/taxrateg/create" element={<TaxGrpCreate />} />
          <Route path="settings/taxrate/edit/:id" element={<Taxrateedit />} />
          <Route path="settings/taxrateg/print" element={<Taxrategprint />} />
          <Route path="settings/taxrate/print" element={<Taxrateprint />} />
          <Route path="settings/taxrate/print" element={<Taxrateprint />} />
          <Route path="settings/hsn/print" element={<Hsnprint />} />
          <Route path="settings/paymentintegration/list" element={<Paymentintegrationlist />} />
          <Route path="settings/paymentintegration/create" element={<Paymentintegrationcreate />} />
          <Route path="settings/paymentintegration/edit/:id" element={<Payintegrationedit />} />
          <Route path="settings/paymentintegration/view/:id" element={<Payintegrationview />} />
          <Route path="settings/taxrate/Gstrpurchase" element={<Gstrpurchase />} />
          <Route path="settings/taxrate/Gstrretailsalesreturn" element={<Gstrretailsalesreturn />} />
          <Route path="settings/taxrate/Hsnwisesummarypurchase" element={<Hsnwisesummarypurchase />} />
          <Route path="settings/taxrate/Hsnwisereportwholesales" element={<Hsnwisereportwholesales />} />
          <Route path="settings/taxrate/GstrpurchaseReturn" element={<GstrpurchaseReturn />} />
          <Route path="settings/taxrate/gstrwholesale" element={<Gstrwholesale />} />
          <Route path="settings/taxrate/GstrwholesaleReturn" element={<GstrwholesaleReturn />} />
          <Route path="settings/taxrate/GstrsalesRetail" element={<GstrsalesRetail />} />
          <Route path="settings/taxrate/HSNwisereportretails" element={<HSNwisereportretails />} />
          <Route path="settings/taxrate/HSNwisereportwholesale" element={<HSNwisereportwholesale />} />
          <Route path="settings/taxrate/hsnwisereportwholesalereturn" element={<HSNwisereportwholesalereturn />} />
          <Route path="settings/taxrate/hsnwisereportretailsalesreturn" element={<HSNwisereportretailsalesreturn />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default Applicationstack; 