import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Grid,  FormControl, Box, OutlinedInput,TextareaAutosize} from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { userStyle } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from "../../../services/Baseservice";

function Bankmasterview() {

    const { auth } = useContext(AuthContext);

    //  Edit Text Field
    const [bankDetail, setBankDetail] = useState({
        accno: "", name: "", bank: "", branch: "", ifsccode: "", remarks: "",
    });

    const id = useParams().id

     // Bankmasters
     const fetchHandler = async () => {
        try {
            let response = await axios.get(`${SERVICE.BANKMASTER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setBankDetail(response.data.sbankmaster);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(() => {
        fetchHandler();
    }, [id])

    const backLPage = useNavigate();

     // cancel button
    const handleBack = (e) =>{
        backLPage('/bank/bank/list')
    }

    return (
        <Box>
            <Headtitle title={'Bank View'} />
            <Typography sx={userStyle.HeaderText}>View Bank</Typography>
            <Box sx={userStyle.container}>
                <form>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>A/C No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        value={bankDetail.accno}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Name</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={bankDetail.name}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Bank</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={bankDetail.bank}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Branch</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={bankDetail.branch}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>IFSC Code</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >         
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={bankDetail.ifsccode}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Remarks</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #B97DF0' }}
                                      value={bankDetail.remarks}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <br />
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Button sx={userStyle.buttoncancel} onClick={handleBack} >Back</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
}

function Bankview() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Bankmasterview /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Bankview;