import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Button, Typography, Dialog, DialogContent, DialogActions, Select, MenuItem, } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { useNavigate, useParams } from 'react-router-dom';
import { userStyle } from '../../PageStyle';
import { toast } from 'react-toastify';
import axios from 'axios';

const Discounteditlist = () => {

    const { auth } = useContext(AuthContext);

    const [disAddForm, setDisAddForm] = useState({selectdiscountprice :"", discounttype:""});

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    const id = useParams().id

    const fetchHandler = async () => {
        try {
            let response = await axios.get(`${SERVICE.DISCOUNT_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDisAddForm(response.data.sdiscount);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(() => {
        fetchHandler()
    }, [id]);

    const getDiscount = () => {

        if (disAddForm.discounttype == "None") {
            if (disAddForm.selectdiscountprice == "Purchase Excluding Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.purchaseexcludetax)
                })
            }
            else if (disAddForm.selectdiscountprice == "Purchase Including Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.pruchaseincludetax)
                })
            }
            else if (disAddForm.selectdiscountprice == "Selling Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.sellingvalue)
                })
            }
        }
        if (disAddForm.discounttype == "Fixed" || disAddForm.discounttype == "Amount") {
            if (disAddForm.selectdiscountprice == "Purchase Excluding Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.purchaseexcludetax) - Number(disAddForm.discountamt)
                })
            }
            else if (disAddForm.selectdiscountprice == "Purchase Including Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.pruchaseincludetax) - Number(disAddForm.discountamt)
                })
            }
            else if (disAddForm.selectdiscountprice == "Selling Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.sellingvalue) - Number(disAddForm.discountamt)
                })
            }
        }
        if (disAddForm.discounttype == "Percentage") {
            if (disAddForm.selectdiscountprice == "Purchase Excluding Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.purchaseexcludetax) - (Number(disAddForm.purchaseexcludetax) * (Number(disAddForm.discountamt) / 100))
                })
            }
            else if (disAddForm.selectdiscountprice == "Purchase Including Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.pruchaseincludetax) - (Number(disAddForm.purchaseexcludetax) * (Number(disAddForm.discountamt) / 100))
                })
            }
            else if (disAddForm.selectdiscountprice == "Selling Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(disAddForm.sellingvalue) - (Number(disAddForm.purchaseexcludetax) * (Number(disAddForm.discountamt) / 100))
                })
            }
        }
    }

    useEffect(() => {
        getDiscount();
    }, [disAddForm.discounttype, disAddForm.discountamt, disAddForm.selectdiscountprice])

    const bactToPage = useNavigate();

    const sendRequest = async () => {
        try {
            let req = await axios.put(`${SERVICE.DISCOUNT_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                location: String(disAddForm.location),
                discountid: String(disAddForm.discountid),
                name: String(disAddForm.name),
                productid:String(disAddForm.productid),
                products: String(disAddForm.products),
                category: String(disAddForm.category),
                schedule: String(disAddForm.schedule),
                molecule: String(disAddForm.molecule),
                manufacture: String(disAddForm.manufacture),
                purchaseexcludetax: Number(disAddForm.purchaseexcludetax),
                purchaseincludetx: Number(disAddForm.pruchaseincludetax),
                discounttype: String(disAddForm.discounttype),
                selectdiscountprice: String(disAddForm.selectdiscountprice),
                sellingvalue: Number(disAddForm.sellingvalue),
                discountamt: Number(disAddForm.discountamt),
                discountvalue: Number(disAddForm.discountvalue),
                startsat: String(disAddForm.startsat),
                endsat: String(disAddForm.endsat),
                productvalue: String(disAddForm.productvalue),
            });
            setDisAddForm(req.data)
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            bactToPage('/product/discount/list');
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const handleEditSubmit = (e) => {
        e.preventDefault();
        if (disAddForm.location == '') {
            setShowAlert("Please Select Location!")
            handleClickOpen();
        }
        else if (disAddForm.name == '') {
            setShowAlert("Please Enter Name!")
            handleClickOpen();
        }
        else if (disAddForm.products == '') {
            setShowAlert("Please Select Product Name!")
            handleClickOpen();
        }
        else if (disAddForm.discounttype == '') {
            setShowAlert("Please Select Discount Type!")
            handleClickOpen();
        }
        else if (disAddForm.discountamt == '') {
            setShowAlert("Please Enter Discount Amount!")
            handleClickOpen();
        }
        else {
            sendRequest();
        }
    }

    const handleBack = () => {
        bactToPage('/product/discount/list')
    }

    const handleValidationAmount = (e) => {
        let val = e.target.value;
        let alphabets = new RegExp('[a-zA-Z]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(alphabets)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setDisAddForm({ ...disAddForm, discountamt: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setDisAddForm({ ...disAddForm, discountamt: value })
        }
    }

    return (
        <Box>
            <Headtitle title={'Discount Edit'} />
            <Typography sx={userStyle.HeaderText}>Edit Discount</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleEditSubmit}>
                    <Grid container spacing={3}>
                        {/* Grid one */}
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  sx={{ display: "flex" }}>Location <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.location}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Discount Id</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.discountid}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  sx={{ display: "flex" }}>Name <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.name}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, name: e.target.value, }); }}

                                />
                            </FormControl>
                        </Grid>

                        {/* Grid two */}
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel sx={{ display: "flex" }}>Product Name <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        size='small'
                                        value={disAddForm.products}
                                    />
                                </FormControl>

                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Category</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.category}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                        <InputLabel>Schedule </InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.schedule}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                        <InputLabel >Molecule</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.molecule}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Manufacture</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.manufacture}
                                />
                            </FormControl>
                        </Grid>
                        {/* Grid three */}
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Purchase Excluding Tax</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.purchaseexcludetax}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Purchase Including Tax</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.purchaseincludetx}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Selling Tax</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.sellingvalue}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  sx={{ display: "flex" }}>Discount Type <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={disAddForm.discounttype}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, discounttype: e.target.value }) }}
                                >
                                    <MenuItem value="selectdiscounttype" onClick={(e) => { getDiscount() }}>None</MenuItem>
                                    <MenuItem value="Fixed" onClick={(e) => { getDiscount() }}>Fixed</MenuItem>
                                    <MenuItem value="Amount" onClick={(e) => { getDiscount() }}>Amount</MenuItem>
                                    <MenuItem value="Percentage" onClick={(e) => { getDiscount() }}>Percentage</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Select Discount Price</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={disAddForm.selectdiscountprice}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, selectdiscountprice: e.target.value }); }}
                                > 
                                    <MenuItem value="Purchase Including Tax" onClick={(e) => { getDiscount() }}>Purchase Including Tax</MenuItem>
                                    <MenuItem value="Purchase Excluding Tax" onClick={(e) => { getDiscount() }}>Purchase Excluding Tax</MenuItem>
                                    <MenuItem value="Selling Tax" onClick={(e) => { getDiscount() }}>Selling Tax</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel sx={{ display: "flex" }}>Discount Amount <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.discountamt}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, discountamt: e.target.value, }); handleValidationAmount(e) }}
                                />
                            </FormControl>
                        </Grid>
                        {/* Grid four */}
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel>Discount Value</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.discountvalue}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Start At</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.startsat}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, startsat: e.target.value }) }}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel >Ends At</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.endsat}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, endsat: e.target.value }) }}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Button sx={userStyle.buttoncancel} onClick={handleBack}>CANCEL</Button>
                                <Button autoFocus sx={userStyle.buttonadd} type="submit" disableRipple>UPDATE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>

            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}
function Discountedit() {
    return (

        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Discounteditlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Discountedit;