import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Grid, FormGroup, FormControlLabel, Checkbox, Paper, Typography, TableContainer, Table, TableHead, TableBody, FormControl, } from '@mui/material';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import $ from 'jquery';
import { userStyle, colourStyles } from '../PageStyle';
import axios from 'axios';
import Selects from "react-select";
import jsPDF from "jspdf";
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { useReactToPrint } from "react-to-print";
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';

function Categorywiselistall() {

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checked, setChecked] = useState(true);
  const [selectFilter, setSelectFilter] = useState({ category: "" })
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  // get all products
  const fetchCategory = async () => {
    try {
      let req = await axios.get(SERVICE.CATEGORIES, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      setCategories(
        req.data.categories?.map((d) => ({
                    
          ...d,
          label: d.categoryname,
          value: d.categoryname,
      }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  const getCateName = (e) => {
    if (e) {
      setProducts([]) || setChecked(true)
    }
  }

  const fetchproductSelect = async (e) => {

    setChecked(s => !s)
    try {
      let response = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      if (checked === true) {
        setProducts(response.data.products);

      } else if (checked === false) {
        setProducts([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  const searchLoc = async () => {
    try {
      let product1 = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let prodDataLocation = product1.data.products.filter((data) => {

        if (data.category == selectFilter.category) {
          return data
        }
      });
      setProducts(prodDataLocation);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, [categories])

  // Export Excel
  const fileName = 'Category Wise Item List';
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    var data = products.map(t => ({
      "Item Code": t.prodcode, "Item Name": t.prodname, "Molecule": t.molecules, "Category": t.category, "Schedule": t.schedule,
      "Manufacture": t.manufacture, "Location": t.businesslocation, "HSN Code": t.hsncode ? t.hsncode : t.applicabletax, "Unit Desc": t.unit, "Minimum Quanitity": t.minquantity,
      "Maximum Quantity": t.maxquantity
    }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CATEGORY WISE ITEM LIST',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    doc.autoTable({
      html: '#producttable',
      margin: { top: 10 },
    })
    doc.save('Category Wise Item List.pdf')
  }

  useEffect(() => {
    getexcelDatas();
  },[products])

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  return (
    <Box >
      <Headtitle title={'Category Wise Item List'} />
      { /* ****** Header Content ****** */}
      <Typography variant='body2' sx={userStyle.HeaderText}>Category<Typography variant='body2' sx={userStyle.SubHeaderText}>Categorywise item list</Typography></Typography>
      <Box sx={userStyle.filtercontent} >
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl size="small" fullWidth>
              <Selects 
                onChange={(e)=>{  
                  setSelectFilter({ ...selectFilter, category: e.categoryname })
                  getCateName(e.categoryname);
                  setChecked(true);
                }}
                placeholder={"Categories"}
                styles={colourStyles}
                options={categories}
              />
            </FormControl>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <FormGroup>
              <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onClick={(e) => { fetchproductSelect(e) }} checked={!checked} />} label="Select All" />
            </FormGroup>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Button variant='contained' color='secondary' onClick={(e) => { searchLoc() }} >Generate&ensp;</Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      { /* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container} >
          { /* Header Content */}

          { /* Header Buttons */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvcatelist && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelcatelist && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printcatelist && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfcatelist && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          { /* Table Start */}
          <TableContainer component={Paper} >
            <Table id="producttable" ref={tableRef}>
              <TableHead  >
                <StyledTableRow >
                  <StyledTableCell >Item Code</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Molecule</StyledTableCell>
                  <StyledTableCell >Category</StyledTableCell>
                  <StyledTableCell >Schedule</StyledTableCell>
                  <StyledTableCell >Manufacture</StyledTableCell>
                  <StyledTableCell >Location</StyledTableCell>
                  <StyledTableCell >HSN Code</StyledTableCell>
                  <StyledTableCell >Unit_Desc</StyledTableCell>
                  <StyledTableCell >Min_Stock Qty </StyledTableCell>
                  <StyledTableCell >Max_Stock Qty</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {products &&
                  products?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.prodcode}  </StyledTableCell>
                      <StyledTableCell >{row.prodname}</StyledTableCell>
                      <StyledTableCell >{row.molecules}</StyledTableCell>
                      <StyledTableCell >{row.category}</StyledTableCell>
                      <StyledTableCell >{row.schedule}</StyledTableCell>
                      <StyledTableCell >{row.manufacture}</StyledTableCell>
                      <StyledTableCell >{row.businesslocation}</StyledTableCell>
                      <StyledTableCell>{row.hsncode ? row.hsncode : row.applicabletax}</StyledTableCell>
                      <StyledTableCell>{row.unit}</StyledTableCell>
                      <StyledTableCell >{row.minquantity}</StyledTableCell>
                      <StyledTableCell>{row.maxquantity}</StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          { /* Table End */}
        </Box>
      </>

      {/* print layout     */}
      <>
        <Box >
          <Box sx={userStyle.printcls}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table" id="categorytable" ref={componentRef}>
                <TableHead>
                  <Typography variant='h5'>Category</Typography>
                  <StyledTableRow >
                    <StyledTableCell >Item Code</StyledTableCell>
                    <StyledTableCell >Item Name</StyledTableCell>
                    <StyledTableCell >Molecule</StyledTableCell>
                    <StyledTableCell >Category</StyledTableCell>
                    <StyledTableCell >Schedule</StyledTableCell>
                    <StyledTableCell >Manufacture</StyledTableCell>
                    <StyledTableCell >Location</StyledTableCell>
                    <StyledTableCell >HSN Code</StyledTableCell>
                    <StyledTableCell >Unit_Desc</StyledTableCell>
                    <StyledTableCell >Min_Stock Qty </StyledTableCell>
                    <StyledTableCell >Max_Stock Qty</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {products &&
                    products?.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">{row.prodcode}  </StyledTableCell>
                        <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                        <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                        <StyledTableCell align="left">{row.category}</StyledTableCell>
                        <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                        <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                        <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                        <StyledTableCell align="left">{row.hsncode ? row.hsncode : row.applicabletax}</StyledTableCell>
                        <StyledTableCell align="left">{row.unit}</StyledTableCell>
                        <StyledTableCell align="left">{row.minquantity}</StyledTableCell>
                        <StyledTableCell align="left">{row.maxquantity}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

      </>
    </Box>
  );

}
function Categorywiselist() {
  return (
    <>
      <Box>
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Categorywiselistall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Categorywiselist;