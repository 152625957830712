import React, { useState, useEffect, useContext} from 'react';
import Navbar from '../components/header/Navbar';
import { Grid, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { dashboardstyle } from './Dashboardstyle';
import { SmartScreenSharp, DoDisturbOnSharp, PriorityHighSharp, PrivacyTipSharp, ShoppingCart, ChildFriendly, Crib } from '@mui/icons-material';
import Daterangepicker from '../components/Daterangepicker';
import Dashbrdchart from './Dashbrdchart';
import Dashpiechart from './Dashpiechart';
import Dashstockalert from './Dashstockalert';
import Dashtopselling from './Dashtopselling';
import Dashrecentsale from './Dashrecentsale';
import Dashcuspie from './Dashcuspie';
import axios from 'axios';
import Headtitle from '../components/header/Headtitle';
import { UserRoleAccessContext } from '../context/Appcontext';

const Dashboardlayout = () => {
  const [location, setLocation] = useState("");
  const [purchases, setPurchases] = useState();
  const [sales, setSales] = useState();
  const [expenses, setExpenses] = useState();
  const [products, setProducts] = useState({});
  const [busilocations, setBusilocations] = useState();

  const {isUserRoleAccess, setIsUserRoleAccess} = useContext(UserRoleAccessContext);

  const fetchPurchase = async () => {
    try {
      let res = await axios.get(`http://localhost:8003/api/purchases`);
      setPurchases(res.data.purchases)
    } catch (err) {
      console.log(err);
    }
  };

  // sales
  const fetchSale = async () => {
    try {

      let res = await axios.get(`http://localhost:8003/api/sales`);
      setSales(res.data.sales)
    } catch (err) {
      console.log(err);
    }
  };

  //expense
  const fetchExpense = async () => {
    try {
      let res = await axios.get(`http://localhost:8003/api/expenses`);
      setExpenses(res.data.expenses)
    } catch (err) {
      console.log(err);
    }
  };

  const stockAlert = async () => {
    try {
      let res = await axios.get(`http://localhost:8003/api/products`);
      let productdata = res.data.products.filter((data) => {
        return data.alertquantity < data.alertquantity
      })
      setProducts(productdata);
    }
    catch (err) {
      console.log(err.message);
    }
  };

  // Business Locations
  const fetchLocation = async () => {
      try {
          let res = await axios.get(`http://localhost:8003/api/businesslocations`);
          setBusilocations(res.data.busilocations);
      } catch (err) {
          console.log(err.message)
      }
  };

  let purchasetotal = 0;
  let saletotal = 0;
  let expensetotal = 0;

  useEffect(
    () => {
      fetchPurchase()
      fetchSale()
      fetchExpense()
      stockAlert()
      fetchLocation()
    }, []);


  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Headtitle title={'Home'} />
      <Typography variant="h5" sx={{ color: 'rgb(94, 53, 177)' }}>Welcome {isUserRoleAccess.role}</Typography><br />
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <Grid item xs={12} sm={6} md={4} sx={{ '& .MuiOutlinedInput-notchedOutline': { border: '1px solid #B97DF0', borderRadius: '10px', } }}>
          <Grid sx={dashboardstyle.headlocation}>
            <FormControl size="small" fullWidth>
              <InputLabel id="demo-select-small">Select Location</InputLabel>
              <Select 
                labelId="demo-select-small" 
                id="demo-select-small" 
                label="Select Location"
                value={busilocations}
                onChange={(e) => { setBusilocations(busilocations) }}
              >
                <MenuItem value="All">All</MenuItem>
                {busilocations && (
                    busilocations.map((row, index) => (
                        <MenuItem value={row.name} key={index}>{row.name}</MenuItem>
                    ))
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ '& .MuiOutlinedInput-notchedOutline': { border: '1px solid #B97DF0', borderRadius: '10px', } }}>
          <FormControl size="small" fullWidth>
            <Daterangepicker />
          </FormControl>
        </Grid>
      </Grid><br />
      {/* Grid Layout for TOTAL  start*/}
      <Grid lg={12} item container spacing={{ xs: 3, sm: 3, md: 4, lg: 3 }}>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Grid sx={dashboardstyle.container}>
            <Grid sx={dashboardstyle.conetntbox}>
              <Grid sx={dashboardstyle.contentboxicon}><SmartScreenSharp style={{ fontSize: '52px', padding: '5px', backgroundColor: 'rgb(146 4 196)!important', marginTop: '2px', }} /></Grid>
              {purchases && (
                purchases.forEach(
                  (item => {

                    purchasetotal += item.paydue;
                  })

                ))}
              <span>TOTAL PURCHASE<br /><span style={{ fontSize: '35px' }}>₹ {purchasetotal}</span></span>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Grid sx={dashboardstyle.containerOne}>
            <Grid sx={dashboardstyle.conetntbox}>
              <Grid sx={dashboardstyle.contentboxicon}><ShoppingCart style={{ fontSize: '52px', padding: '5px', }} /></Grid>
              {sales && (
                sales.forEach(
                  (item => {

                    saletotal += +item.amount;
                  })

                ))}

              <span>TOTAL SALES<br /><span style={{ fontSize: '35px' }}>₹ {saletotal}</span></span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Grid sx={dashboardstyle.containerTwo}>
            <Grid sx={dashboardstyle.conetntbox}>
              <Grid sx={dashboardstyle.contentboxicon}><PriorityHighSharp style={{ fontSize: '42px', padding: '5px', }} /></Grid>
              <span>PURCHASE DUE<br /><span style={{ fontSize: '35px' }}>₹ {purchasetotal}</span></span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Grid sx={dashboardstyle.containerThree}>
            <Grid sx={dashboardstyle.conetntbox}>
              <Grid sx={dashboardstyle.contentboxicon}><PrivacyTipSharp style={{ fontSize: '42px', padding: '5px', }} /></Grid>
              <span>SALES DUE<br /><span style={{ fontSize: '35px' }}>₹ {saletotal}</span></span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Grid sx={dashboardstyle.containerFour}>
            <Grid sx={dashboardstyle.conetntbox}>
              <Grid sx={dashboardstyle.contentboxicon}><Crib style={{ fontSize: '42px', padding: '5px', }} /></Grid>
              <span>TOTAL SALES <br />RETURN<br /><span style={{ fontSize: '35px' }}>₹ 0.00</span></span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Grid sx={dashboardstyle.containerFour}>
            <Grid sx={dashboardstyle.conetntbox}>
              <Grid sx={dashboardstyle.contentboxicon}><ChildFriendly style={{ fontSize: '42px', padding: '5px', }} /></Grid>
              <span>TOTAL PURCHASE <br />RETURN<br /><span style={{ fontSize: '35px' }}>₹ 0.00</span></span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Grid sx={dashboardstyle.containerFour}>
            <Grid sx={dashboardstyle.conetntbox}>
              <Grid sx={dashboardstyle.contentboxicon}><DoDisturbOnSharp style={{ fontSize: '42px', padding: '5px', }} /></Grid>
              {expenses && (
                expenses.forEach(
                  (item => {
                    expensetotal += +item.expamount;
                  })

                ))}
              <span>EXPENSES<br /><span style={{ fontSize: '35px' }}>₹ {expensetotal}</span></span>
            </Grid>
          </Grid>
        </Grid>
      </Grid><br /><br />
      {/* Grid Layout for TOTAL end */}
      {/* Table start */}
      <Box>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12} fullWidth>
            <Dashbrdchart />
          </Grid>
          <Grid item md={6} sm={12} xs={12} fullWidth>
            <Dashpiechart />
          </Grid>
        </Grid><br />
        <Grid container spacing={2}>
          <Grid item md={8} sm={12} xs={12} fullWidth>
            <Dashstockalert />
            <Dashrecentsale />
            <Dashtopselling />
          </Grid>
          <Grid item md={4} sm={12} xs={12} fullWidth>
            <Typography variant='h5' sx={{ marginBottom: '10px' }}></Typography>
            <Dashcuspie />
          </Grid>
        </Grid>
      </Box>
      {/* Table end */}
    </Box>
  );
}

const Dashboard = () => {
  return (
    <>
      <Box>
        <Navbar />
        {/* Main Dashboard start */}
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Dashboardlayout />
          </Box>
        </Box>
        {/* Main Dashboard end */}
      </Box>
    </>
  )
}

export default Dashboard;