import React, { useState, useEffect, useContext } from 'react';
import { Button, TextField, Select, MenuItem, TextareaAutosize, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Grid, InputLabel, FormControl, Box, OutlinedInput, } from '@mui/material';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { useNavigate, Link } from 'react-router-dom';
import { userStyle, colourStyles} from '../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import Selects from "react-select";
import CreatableSelect from 'react-select/creatable';

function CashBookregisterlist() {

    const { auth } = useContext(AuthContext);

    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    
    // account (Name)
    const [accountAllName, setAccountAllName] = useState([])
    const [cashbooks, setCashbooks] = useState([]);
    const [newdata, setNewdata] = useState([]);
    const [options, setOptions] = useState([])
    const [optionData, setOptionData] = useState([])
    const [setngs, setSetngs] = useState();

    //  Add Text Field
    const [cashBook, setCashBook] = useState({
        referenceno: "", date: "", accountname: "", account: "", accountgroup: "", amount: "", paymethod: "Cash", entry: "None", narration: "",
    });

    const [groupCreate, setGroupCreate] = useState({
        groupautoid: "", groupname: "",
    });
    let newval = setngs ? setngs.cashsku + "0001" : "CA0001"; 
    let newvals = setngs ? setngs.groupsku + "0001" : "GR0001";
    const [grpcreation, setGroupCreation] = useState([])

    const[warn , setWarn ]= useState(false)

    //  Modal
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
        setWarn(false)
        setGroupCreate({ ...groupCreate, groupname: "" })
    };

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    // GROUP_CREATION
    const fetchbankMasters = async () => {
        try {
            let req = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setGroupCreation(req.data.groupmasters);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //supplier auto id from settings
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

useEffect(()=>{
    fetchSettings()
},[])
    // CashBook
    const fetchCashBook = async () => {
        try {
            let req = await axios.get(SERVICE.CASH_BOOK, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setCashbooks(req.data.cashbooks);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    useEffect(() => {
        fetchCashBook();
    },[])

    const fetchAllAccountName = async (id) => {
        try {
            let res = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let resname = res.data.customers.map((item) => {
                return item.name
            })
            let res2 = await axios.get(SERVICE.SUPPLIER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let resname2 = res2.data.suppliers.map((item) => {
                return item.suppliername
            })

            let res3 = await axios.get(SERVICE.USER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let resname3 = res3.data.users.map((item) => {
                return item.first
            })
            let resname4 = res3.data.users.map((item) => {
                return item.last
            })
            let concatArr =  resname3.map((data,item)=> data + " " + resname4[item] )
            let finalResult = resname.concat(resname2, concatArr)
            let newArr = finalResult.map(function (value) {
                return { name: value };
            });
            setAccountAllName(newArr.map((d) => ({
                ...d,
                label: d.name,
                value: d.name,
            })))
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Fetch customer accountgroup 
    const fetchgroup = async () => {
        try {
            let res_group = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.groupmasters?.map((d) => {
                return d.groupname
            })
            setNewdata(masters_data);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    let newarray = [...new Set(newdata)];

    // fetch Options
    const fetchOptions = async () => {
        try {
            setOptions(
                newarray.map((d) => (
                    {
                        ...d,
                        label: d,
                        value: d,
                    }
                ))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    useEffect(() => {
        fetchOptions();
    }, [options])

    // Fetch customer account name 
    const fetchAccount = async (e) => {
        try {
            let res_group = await axios.get(SERVICE.ACCOUNT_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.accountmasters?.filter((data) => {
                if (e.label == data.accountgroup) {
                    return data
                }
            })
            setOptionData(
                masters_data.map((d) => (
                    {
                        ...d,
                        label: d.accountname,
                        value: d.accountname
                    }
                ))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const backPage = useNavigate();

    //   New group creation
    const addGroup = async () => {
        try {
            let req = await axios.post(SERVICE.GROUP_CREATION_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                groupautoid: String(newvals),
                groupname: String(groupCreate.groupname)
            },
            );
            setGroupCreation(req.data);
            handleModalClose();
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
        }
    };

    //New cashbook 
    const addcashBookregister = async () => {
        try {
            let req = await axios.post(SERVICE.CASH_BOOK_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newval),
                date: String(cashBook.date),
                accountname: String(cashBook.accountname),
                account: String(cashBook.account),
                accountgroup: String(cashBook.accountgroup),
                amount: Number(cashBook.amount),
                paymethod: String(cashBook.paymethod),
                entry: String(cashBook.entry),
                narration: String(cashBook.narration),
            });
            setCashBook(req.data);
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/account/CashBookReport')

        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };

    //New cashbook 
    const addanothercashBookregister = async () => {
        try {
            let req = await axios.post(SERVICE.CASH_BOOK_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newval),
                date: String(cashBook.date),
                accountname: String(cashBook.accountname),
                account: String(cashBook.account),
                accountgroup: String(cashBook.accountgroup),
                amount: Number(cashBook.amount),
                paymethod: String(cashBook.paymethod),
                entry: String(cashBook.entry),
                narration: String(cashBook.narration),
            });
            await fetchCashBook();
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setCashBook({
                ...cashBook, referenceno: newval + 1, date: "", accountname: "", account: "", accountgroup: "", amount: "",
                paymethod: "Cash", entry: "None", narration: "",
            });
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };

    //submit button 
    const handleSubmit = (e) => {
        e.preventDefault();
        if (cashBook.accountname == "") {
            setShowAlert("Please enter Account name!")
            handleClickOpen();
        }
        else if (cashBook.amount == "") {
            setShowAlert("Please enter amount!")
            handleClickOpen();
        }
        else {
            addcashBookregister();
        }
    }



    const handleValidationName = (e) => {
        let val = e.target.value;
        let alphabets = new RegExp('[a-zA-Z]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
        if (e.target.value.match(alphabets)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCashBook({ ...cashBook, accountname: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCashBook({ ...cashBook, amount: value })
        }
    }
    //Save & add another button 
    const handleAnotherSubmit = (e) => {
        // e.preventDefault();
        if (cashBook.accountname == "") {
            setShowAlert("Please enter Account name!")
            handleClickOpen();
        }
        else if (cashBook.amount == "") {
            setShowAlert("Please enter amount!")
            handleClickOpen();
        }
        else {
            addanothercashBookregister();
        }
    }

    //submit button 
    const handleSubmitModal = (e) => {
        e.preventDefault();
        if (groupCreate.groupname == "") {
            setWarn(true)
        } else {
            addGroup();
        }
    }

    useEffect(
        () => {
            fetchbankMasters();
            fetchgroup();
            fetchAllAccountName();
        }, [grpcreation]
    )

    return (

        <Box>
            <Headtitle title={'Cash Book Register - Create'} />
            <Typography sx={userStyle.HeaderText}>Cash Book register</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Reference No</Typography>
                        </Grid>
                        {cashbooks && (
                            cashbooks.map(
                                () => {
                                    let strings = setngs ? setngs.cashsku : "CA";
                                    let refNo = cashbooks[cashbooks.length - 1].referenceno;
                                    let digits = (cashbooks.length + 1).toString();
                                    const stringLength = refNo.length;
                                    let lastChar = refNo.charAt(stringLength - 1);
                                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                                    let lastBeforeChar = refNo.slice(-2);
                                    let lastThreeChar = refNo.slice(-3);
                                    let lastDigit = refNo.slice(-4);
                                    let refNOINC = parseInt(lastChar) + 1
                                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                                    let refLstThree = parseInt(lastThreeChar) + 1;
                                    let refLstDigit = parseInt(lastDigit) + 1;
                                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                        refNOINC = ("000" + refNOINC);
                                        newval = strings + refNOINC;
                                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                        refNOINC = ("00" + refLstTwo);
                                        newval = strings + refNOINC;
                                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                                        refNOINC = ("0" + refLstThree);
                                        newval = strings + refNOINC;
                                    } else {
                                        refNOINC = (refLstDigit);
                                        newval = strings + refNOINC;
                                    }

                                }))}
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={newval}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Date</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth>
                                <TextField
                                    id="component-outlined"
                                    value={cashBook.date}
                                    onChange={(e) => { setCashBook({ ...cashBook, date: e.target.value }) }}
                                    type="date"
                                    size="small"
                                    name="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' >Account <Typography sx={{fontSize:"15px !important",display: "inline-block",}}>( Name )</Typography><b style={{ color: "red" }}> *</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <CreatableSelect
                                    onChange={(e)=>{setCashBook({ ...cashBook, accountname: e.value })}}
                                    styles={colourStyles}
                                    options={accountAllName}
                                    style={{borderColor:'rgb(112,9,171) '}}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Account Group</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{display:'flex'}}>
                                <FormControl size="small" fullWidth>
                                    <Selects
                                        onChange={(e) => { setCashBook({ ...cashBook, accountgroup: e.value }); fetchAccount(e) }}
                                        styles={colourStyles}
                                        options={options}
                                    />
                                </FormControl>
                                <Grid sx={userStyle.spanText} onClick={handleModalOpen}><b>+</b></Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                        </Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Account Type</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    onChange={(e) => { setCashBook({ ...cashBook, account: e.value }) }}
                                    styles={colourStyles}
                                    options={optionData}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Amount <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={cashBook.amount}
                                        onChange={(e) => { setCashBook({ ...cashBook, amount: e.target.value, referenceno: newval }) ; handleValidationName(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Paymethod</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Select
                                    
                                    id="demo-simple-select"
                                    value={cashBook.paymethod}
                                    onChange={(e) => { setCashBook({ ...cashBook, paymethod: e.target.value }) }}
                                >
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="UPI">UPI</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Entry</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={cashBook.entry}
                                    onChange={(e) => { setCashBook({ ...cashBook, entry: e.target.value }) }}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Credit">Credit</MenuItem>
                                    <MenuItem value="Debit">Debit</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Narration</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                    value={cashBook.narration}
                                    onChange={(e) => { setCashBook({ ...cashBook, narration: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <br />
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Link to="/account/CashBookReport"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                                <Button sx={userStyle.buttonadd} onClick={handleAnotherSubmit} >SAVE & ADD ANOTHER</Button>
                                <Button sx={userStyle.buttonadd} type='submit' >SAVE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
            {/* Popup */}
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">Group Creation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {warn ? <p style={{ color: "red" }}>Please enter group name!</p>: null }
                        <Box sx={userStyle.container}>
                            <Grid container spacing={1}>
                                <Grid item lg={3} md={3}>
                                    {grpcreation.length > 0 && (
                                        grpcreation.map(
                                            () => {
                                                let strings = setngs ? setngs.groupsku : "GR";
                                                let refNo = grpcreation[grpcreation.length - 1].groupautoid;
                                                let digits = (grpcreation.length + 1).toString();
                                                const stringLength = refNo.length;
                                                let lastChar = refNo.charAt(stringLength - 1);
                                                let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                                let getlastThreeChar = refNo.charAt(stringLength - 3);
                                                let lastBeforeChar = refNo.slice(-2);
                                                let lastThreeChar = refNo.slice(-3);
                                                let lastDigit = refNo.slice(-4);
                                                let refNOINC = parseInt(lastChar) + 1
                                                let refLstTwo = parseInt(lastBeforeChar) + 1;
                                                let refLstThree = parseInt(lastThreeChar) + 1;
                                                let refLstDigit = parseInt(lastDigit) + 1;
                                                if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                                    refNOINC = ("000" + refNOINC);
                                                    newvals = strings + refNOINC;
                                                } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                                    refNOINC = ("00" + refLstTwo);
                                                    newvals = strings + refNOINC;
                                                } else if (digits.length < 4 && getlastThreeChar > 0) {
                                                    refNOINC = ("0" + refLstThree);
                                                    newvals = strings + refNOINC;
                                                } else {
                                                    refNOINC = (refLstDigit);
                                                    newvals = strings + refNOINC;
                                                }
                                            }))}
                                    <Typography>Reference No</Typography>
                                </Grid>
                                <Grid item lg={9} md={9}>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={newvals}
                                        />
                                    </FormControl>
                                </Grid><br />
                                <Grid item lg={3} md={3}>
                                    <Typography>Account Group <b style={{color:'red'}}>*</b></Typography>
                                </Grid>
                                <Grid item lg={9} md={9}>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={groupCreate.groupname}
                                            onChange={(e) => { setGroupCreate({ ...groupCreate, groupname: e.target.value, groupautoid: newvals }); }}

                                        />
                                    </FormControl>
                                </Grid><br />
                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" disableRipple onClick={handleSubmitModal} type="submit">SAVE</Button>
                    <Button variant='contained' color='error' onClick={handleModalClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function CashBookregister() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <CashBookregisterlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default CashBookregister;