import React, { useState } from "react";
import { Box, Grid, Dialog, DialogContent, DialogActions, Typography, Button, FormControl, InputLabel, OutlinedInput} from '@mui/material';
import { userStyle } from "../../PageStyle";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function Prefixescreate({setngs, setSetngs}) {

    // Pop up error
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()

    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    const handleValidationPurchase = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,purchasesku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,purchasesku: value};
            })
        }
    }

    const handleValidationPurchaseReturn = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,purchasereturnsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,purchasereturnsku: value};
            })
        }
    }

    const handleValidationPurchaseOrder = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,purchaseordersku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,purchaseordersku: value};
            })
        }
    }

    const handleValidationCustomer = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,customersku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,customersku: value};
            })
        }
    }

    const handleValidationSupplier = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,suppliersku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,suppliersku: value};
            })
        }
    }

    const handleValidationCustomerGrp = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,cusgroupsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,cusgroupsku: value};
            })
        }
    }

    const handleValidationExpense = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,expensesku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,expensesku: value};
            })
        }
    }

    const handleValidationUser = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,usersku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,usersku: value};
            })
        }
    }

    const handleValidationBusinessLocation = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,businesslocationsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,businesslocationsku: value};
            })
        }
    }

    const handleValidationDiscount = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,discountsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,discountsku: value};
            })
        }
    }

    const handleValidationSales = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,salesku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,salesku: value};
            })
        }
    }

    const handleValidationDraft = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,draftsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,draftsku: value};
            })
        }
    }

    const handleValidationQuotation = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,quotationsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,quotationsku: value};
            })
        }
    }

    const handleValidationAccountCreation = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,accountsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,accountsku: value};
            })
        }
    }

    const handleValidationGroupCreation = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,groupsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,groupsku: value};
            })
        }
    }

    const handleValidationCashBook = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,cashsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,cashsku: value};
            })
        }
    }

    const handleValidationBankBook = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,banksku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,banksku: value};
            })
        }
    }

    const handleValidationVoucher = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,vouchersku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,vouchersku: value};
            })
        }
    }

    const handleValidationEstimate = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,estimatesku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,estimatesku: value};
            })
        }
    }

    const handleValidationEstimateDraft = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,customerdrugentrysku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,customerdrugentrysku: value};
            })
        }   
    }

    const handleValidationCustomerDrug = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,edraftsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,edraftsku: value};
            })
        }   
    }

    const handleValidationEstimateQuotation = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,equotationsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,equotationsku: value};
            })
        }   
    }

    const handleValidationDepartment = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,departmentsku: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,departmentsku: value};
            })
        }   
    }


    return (
        <Box sx={userStyle.container}>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Purchase</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.purchasesku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,purchasesku:e.target.value};
                            }); handleValidationPurchase(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Purchase Return</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.purchasereturnsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,purchasereturnsku:e.target.value};
                            }); handleValidationPurchaseReturn(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Purchase order</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.purchaseordersku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,purchaseordersku:e.target.value};
                            }); handleValidationPurchaseOrder(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Customer</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.customersku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,customersku:e.target.value};
                            }); handleValidationCustomer(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Supplier</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.suppliersku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,suppliersku:e.target.value};
                            }); handleValidationSupplier(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Customer Group</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.cusgroupsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,cusgroupsku:e.target.value};
                            }); handleValidationCustomerGrp(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Customer Drug Entry</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.customerdrugentrysku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,customerdrugentrysku:e.target.value};
                            }); handleValidationCustomerDrug(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Expense</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.expensesku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,expensesku:e.target.value};
                            }); handleValidationExpense(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">User</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.usersku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,usersku:e.target.value};
                            }); handleValidationUser(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Business Location</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.businesslocationsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,businesslocationsku:e.target.value};
                            }); handleValidationBusinessLocation(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Discount</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.discountsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,discountsku:e.target.value};
                            }); handleValidationDiscount(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Sales</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.salesku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,salesku:e.target.value};
                            }); handleValidationSales(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Draft</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.draftsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,draftsku:e.target.value};
                            }); handleValidationDraft(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Quotation</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.quotationsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,quotationsku:e.target.value};
                            }); handleValidationQuotation(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Department</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.departmentsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,departmentsku:e.target.value};
                            }); handleValidationDepartment(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Account creation</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.accountsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,accountsku:e.target.value};
                            }); handleValidationAccountCreation(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Group creation</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.groupsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,groupsku:e.target.value};
                            }); handleValidationGroupCreation(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Cash book</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.cashsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,cashsku:e.target.value};
                            }); handleValidationCashBook(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Bank book</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.banksku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,banksku:e.target.value};
                            }); handleValidationBankBook(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Voucher</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.vouchersku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,vouchersku:e.target.value};
                            }); handleValidationVoucher(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Estimate</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.estimatesku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,estimatesku:e.target.value};
                            }); handleValidationEstimate(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined">Estimate Draft</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.edraftsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,edraftsku:e.target.value};
                            }); handleValidationEstimateDraft(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    <InputLabel htmlFor="component-outlined"> Estimate Quotation</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            value={setngs.equotationsku}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,equotationsku:e.target.value};
                            }); handleValidationEstimateQuotation(e)}}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h6">{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}