import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import { userStyle, colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import Selects from 'react-select';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { AuthContext } from '../../context/Appcontext';


function Drwisereport() {

  const [pos, setPos] = useState();
  const [exceldata, setExceldata] = useState([]);
  const [cuspos, setcuspos] = useState([]);
  const { auth } = useContext(AuthContext);

  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })


  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  const fetchcuspos = async () => {
    try {
      let cuspos = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setcuspos(
        cuspos?.data?.pos1?.map((d) => ({
          ...d,
          label: d.doctorname,
          value: d.doctorname,

        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  let billNoArr = []
  let billdateArr = []
  let totalItems = []
  let totalQuantity = []
  let subTotal = []
  let discount = []
  let cgst = []
  let sgst = []
  let igst = []
  let otherCharges = []
  let roundoff = []
  let netTotal = []
  let paymentMethod = []
  let arrMixed = []

  // Pos to show all data
  const fetchPos = async () => {
    try {
      let res = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let posfetch = res.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (data.activate == true) {
          if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
          }
          else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
          }
          else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
          }
          else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
          }
        }
      })

      posfetch.map(item => {
        let dateTrim = moment(item.date).utc().format('YYYY-MM-DD')

        billNoArr.push(item.referenceno)
        billdateArr.push(dateTrim)
        totalItems.push(+item.totalitems)
        item.goods.map((value, i) => {
          totalQuantity.push(value.quantity)
          subTotal.push(+value.subtotal)
          discount.push(+value.discountamt)
          value.subtax.map(i => {
            cgst.push(+i.taxratecgst)
            sgst.push(+i.taxrategst)
            igst.push(+i.taxrateigst)
          })
        },
          otherCharges.push(+item.packcharge),
          roundoff.push(+item.roundof),
          netTotal.push(+item.aftergranddisctotal),
          paymentMethod.push(item.paymentmethod),
        )
      })
      arrMixed = billNoArr.map(function (data, i) {
        return {
          billno: data,
          referenceno: billNoArr[i],
          date: billdateArr[i],
          totalitems: totalItems[i],
          quantity: totalQuantity[i],
          subtotal: subTotal[i],
          discountamt: discount[i],
          taxratecgst: cgst[i],
          taxrategst: sgst[i],
          taxrateigst: igst[i],
          packcharge: otherCharges[i],
          roundof: roundoff[i],
          aftergranddisctotal: netTotal[i],
          paymentmethod: paymentMethod[i],
        }
      });

      // calculation
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.billno;
        const items = r.get(key) || Object.assign({}, o, {
          date: "",
          totalitems: 0,
          packcharge: 0,
          roundof: 0,
          aftergranddisctotal: 0,
          paymentmethod: "",
          quantity: 0,
          subtotal: 0,
          discountamt: 0,
          taxratecgst: 0,
          taxrategst: 0,
          taxrateigst: 0,
        });
        items.date = o.date
        items.totalitems = o.totalitems
        items.packcharge += o.packcharge
        items.roundof += o.roundof
        items.aftergranddisctotal += o.aftergranddisctotal
        items.paymentmethod = o.paymentmethod
        items.quantity += o.quantity
        items.subtotal += o.subtotal
        items.discountamt += o.discountamt
        items.taxratecgst += o.taxratecgst
        items.taxrategst += o.taxrategst
        items.taxrateigst += o.taxrateigst
        return r.set(key, items);
      }, new Map).values()];

      setPos(result);

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Filter
  const searchPos = async (e) => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let expDataLocation = req.data.pos1.filter((data) => {
        return data.doctorname == e.doctorname;
      })

      expDataLocation.map(item => {
        let dateTrim = moment(item.date).utc().format('YYYY-MM-DD')

        billNoArr.push(item.referenceno)
        billdateArr.push(dateTrim)
        totalItems.push(+item.totalitems)
        item.goods.map(value => {
          totalQuantity.push(+value.quantity)
          subTotal.push(+value.subtotal)
          discount.push(+value.discountamt)
          value.subtax.map(i => {
            cgst.push(+i.taxratecgst)
            sgst.push(+i.taxrategst)
            igst.push(+i.taxrateigst)
          })
        },
          otherCharges.push(+item.packcharge),
          roundoff.push(+item.roundof),
          netTotal.push(+item.aftergranddisctotal),
          paymentMethod.push(item.paymentmethod),
        )
      })
      arrMixed = billNoArr.map(function (data, i) {
        return {
          billno: data,
          referenceno: billNoArr[i],
          date: billdateArr[i],
          totalitems: totalItems[i],
          quantity: totalQuantity[i],
          subtotal: subTotal[i],
          discountamt: discount[i],
          taxratecgst: cgst[i],
          taxrategst: sgst[i],
          taxrateigst: igst[i],
          packcharge: otherCharges[i],
          roundof: roundoff[i],
          aftergranddisctotal: netTotal[i],
          paymentmethod: paymentMethod[i],
        }
      });

      // calculation
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.billno;
        const items = r.get(key) || Object.assign({}, o, {
          date: "",
          totalitems: 0,
          packcharge: 0,
          roundof: 0,
          aftergranddisctotal: 0,
          paymentmethod: "",
          quantity: 0,
          subtotal: 0,
          discountamt: 0,
          taxratecgst: 0,
          taxrategst: 0,
          taxrateigst: 0,
        });
        items.date = o.date
        items.totalitems = o.totalitems
        items.packcharge += o.packcharge
        items.roundof += o.roundof
        items.aftergranddisctotal += o.aftergranddisctotal
        items.paymentmethod = o.paymentmethod
        items.quantity = o.quantity
        items.subtotal = o.subtotal
        items.discountamt = o.discountamt
        items.taxratecgst = o.taxratecgst
        items.taxrategst = o.taxrategst
        items.taxrateigst = o.taxrateigst
        return r.set(key, items);
      }, new Map).values()];

      setPos(result);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(
    () => {
      fetchPos()
      fetchcuspos();
    }, []
  )

  // Export Excel
  const fileName = 'Dr Wise Sales Report'
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = pos.map(t => ({
      "Bill no": t.referenceno,
      "Bil Date": moment(t.date).utc().format('DD-MM-YYYY'),
      "Total Items": t.totalitems,
      "Total Quantity": t.quantity,
      "Sub Total": t.subtotal,
      "Discount": t.discountamt,
      "Cgst": t.taxratecgst,
      "Sgst": t.taxrategst,
      "Igst": t.taxrateigst,
      "Other Charges": t.packcharge,
      "Round off": t.roundof,
      "Net Total": t.aftergranddisctotal,
      "Payment Method": t.paymentmethod,
    }));
    setExceldata(data);
  }

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#usertable' })
    doc.save('Dr Wise Sales Report.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | DR WISE SALES REPORT',
    pageStyle: 'print'
  });

  useEffect(() => {
    getexcelDatas();
  }, [pos]);

  return (
    <Box>
      <Headtitle title={'Doctor Wise Sales Report'} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={userStyle.importheadtext}>Dr Wise Sales Report (Retail)</Typography>
        </Grid>

      </Grid><br />
      <Box sx={userStyle.filtercontent} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={1} md={1} sm={12} xs={12}>
            <Typography sx={{ marginTop: 1, marginLeft: "0.5em" }}> From</Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl size="small" fullWidth>
              <TextField
                id="component-outlined"
                value={dateFilter.startdate}
                onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                type="date"
                size="small"
                name="startdate"
              />
            </FormControl>
          </Grid>
          <Grid item lg={1} md={1} sm={12} xs={12}>
            <Typography sx={{ marginTop: 1 }}>To</Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl size="small" fullWidth>
              <TextField
                id="component-outlined"
                value={dateFilter.enddate}
                onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                type="date"
                size="small"
                name="enddate"
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl size="small" fullWidth >
              <Selects
                styles={colourStyles}
                defaultValue={"walk-in-customer"}
                options={cuspos}
                placeholder="Doctor Name"
                onChange={searchPos}
              />
            </FormControl>
          </Grid>
          <Grid item lg={1} md={1} sm={12} xs={12}>
            <Button variant='contained' color='secondary' sx={{ marginLeft: "1em" }} onClick={fetchPos}>Generate</Button>
          </Grid>
        </Grid>

      </Box><br />
      <Headtitle title={'DR wise sales Report'} />
      {/* header text */}
      <Box sx={userStyle.container}>
        { /* ******************************************************EXPORT Buttons****************************************************** */}
        {/*       
          <Box sx={userStyle.container} > */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].exceldrwisesalereport && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].csvdrwisesalereport && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printdrwisesalereport && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfdrwisesalereport && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table id="usertable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill no</StyledTableCell>
                  <StyledTableCell>Bill date</StyledTableCell>
                  <StyledTableCell>Total Items</StyledTableCell>
                  <StyledTableCell>Total Quantity</StyledTableCell>
                  <StyledTableCell>Sub Total</StyledTableCell>
                  <StyledTableCell>Discount</StyledTableCell>
                  <StyledTableCell>Cgst</StyledTableCell>
                  <StyledTableCell>Sgst</StyledTableCell>
                  <StyledTableCell>Igst</StyledTableCell>
                  <StyledTableCell>Other Charges</StyledTableCell>
                  <StyledTableCell>Round off</StyledTableCell>
                  <StyledTableCell>Net Total</StyledTableCell>
                  <StyledTableCell>Payment Method</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {pos &&
                  (pos.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.referenceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.totalitems}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                      <StyledTableCell >{row.subtotal}</StyledTableCell>
                      <StyledTableCell >{row.discountamt}</StyledTableCell>
                      <StyledTableCell >{row.taxratecgst}</StyledTableCell>
                      <StyledTableCell >{row.taxrategst}</StyledTableCell>
                      <StyledTableCell >{row.taxrateigst}</StyledTableCell>
                      <StyledTableCell >{row.packcharge}</StyledTableCell>
                      <StyledTableCell >{row.roundof}</StyledTableCell>
                      <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                      <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Bill no</StyledTableCell>
              <StyledTableCell>Bill date</StyledTableCell>
              <StyledTableCell>Total Items</StyledTableCell>
              <StyledTableCell>Total Quantity</StyledTableCell>
              <StyledTableCell>Sub Total</StyledTableCell>
              <StyledTableCell>Discount</StyledTableCell>
              <StyledTableCell>Cgst</StyledTableCell>
              <StyledTableCell>Sgst</StyledTableCell>
              <StyledTableCell>Igst</StyledTableCell>
              <StyledTableCell>Other Charges</StyledTableCell>
              <StyledTableCell>Round off</StyledTableCell>
              <StyledTableCell>Net Total</StyledTableCell>
              <StyledTableCell>Payment Method</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {pos &&
              (pos.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell >{row.referenceno}</StyledTableCell>
                  <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                  <StyledTableCell >{row.totalitems}</StyledTableCell>
                  <StyledTableCell >{row.quantity}</StyledTableCell>
                  <StyledTableCell >{row.subtotal}</StyledTableCell>
                  <StyledTableCell >{row.discountamt}</StyledTableCell>
                  <StyledTableCell >{row.taxratecgst}</StyledTableCell>
                  <StyledTableCell >{row.taxrategst}</StyledTableCell>
                  <StyledTableCell >{row.taxrateigst}</StyledTableCell>
                  <StyledTableCell >{row.packcharge}</StyledTableCell>
                  <StyledTableCell >{row.roundof}</StyledTableCell>
                  <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                  <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}


function Drwisereportpage() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Drwisereport /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Drwisereportpage;