import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Grid, Paper, Table, TableBody, TableHead, TableContainer, FormGroup, FormControl, Button, FormControlLabel, Checkbox, DialogTitle, DialogContentText, Dialog, DialogActions, DialogContent } from '@mui/material';
import { userStyle, colourStyles } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import Selects from "react-select";
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

function Categorywisecustomerlisttable() {

    const [customers, setCustomers] = useState([]);
    const [customername, setCustomername] = useState();
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);
    const [checked, setChecked] = useState(true);
    const categoryType = [{category:"Retail"},{category:"Wholesale"}]
    const [code, setCode] = useState([]);
    let allcategory = [];
    allcategory = categoryType.flat();

    // Categoryfield
    const [dateFilter, setDateFilter] = useState({
        category: " ",
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Pdffield
    const [isPdfData, setIsPdfData] = useState({
        isCusid: false, isCustomerGroup: false, isName: false, isAddress: false,
        isArea: false, isMobileno: false, isWhatsapp: false, isGstn: false, isUin: false, isAadhaar: false,
        isPanno: false, isCountry: false, isState: false, isCity: false, isCreditlimit: false, isCreditdays: false,
        isDlnos: false, isLedgerbalance: false, isCategory: false,
    })

    // Pdf
    const [openPdf, setOpenPdf] = useState(false);
    const handleOpenPdf = () => { setOpenPdf(true) };
    const handleClosePdf = () => { setOpenPdf(false) };

    //Select All function 
    const fetchCustomerSelectAll = async (e) => {

        setChecked(s => !s)
        try {
            let response = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            if (checked === true) {
                setCustomers(response.data.customers);

            } else if (checked === false) {
                setCustomers([])

            }
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Customer category
    const fetchcustomercategory = async () => {
        try {
           
            setCustomername(
                allcategory.map((d) => ({
                    ...d,
                    label: d.category,
                    value: d.category,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchcustomercategory();
    }, [customername])

    // customername handlechange
    const handleChangeManufacture = (newValue, actionMeta) => {
        setDateFilter({ ...dateFilter, category: newValue.value })
        getCateName(newValue);
        setChecked(true)
    };
    const getCateName = (newValue) => {
        if (newValue) {
            setCustomers([]) || setChecked(true)
        }
        setCode(newValue.category)
    }

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    const fetchCustomers = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            let getDatawithFilter = req.data.customers.filter((data) => {
                if (dateFilter.category == "") {
                    return data
                }
                else if (data.category == dateFilter.category) {
                    return data
                }
                else if (data.category === code) {
                    return data
                }
            })
            setCustomers(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Excel
    const fileName = 'Category Wise Customers'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = customers.map(t => ({
            "Customer ID": t.cusid, "Customer Group": t.customergroup, "Name": t.name, "Address": t.address, "Area": t.area, "Mobile No": t.mobileno,
            "Whatsapp": t.whatsapp, "GSTN": t.gstn, "UIN": t.uin, "Aadhaar": t.aadhaar, "PAN No": t.panno, "Country": t.country, "State": t.state,
            "City": t.city, "Credit Limit": t.creditlimit, "Credit Days": t.creditdays, "DL Nos": t.dlnos, "Category": t.category
        }));
        setExceldata(data);
    }

    useEffect(
        () => {
            fetchCustomers();
        }, []
    )

    useEffect(
        () => {
            getexcelDatas();
        }, [customers]
    )

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CATEGORY WISE CUSTOMER LIST',
        pageStyle: 'print'
    });

    // PDF
    var nestedArray = [];

    {
        customers && (customers.map((item, index) => {
            var cateData = [
                item.cusid,
                item.customergroup,
                item.name,
                item.address,
                item.area,
                item.mobileno,
                item.whatsapp,
                item.gstn,
                item.uin,
                item.aadhaar,
                item.panno,
                item.country,
                item.state,
                item.city,
                item.creditlimit,
                item.creditdays,
                item.dlnos,
                item.ledgerbalance,
                item.category,
            ]
            nestedArray.push(cateData);
        })
        )
    }
    const downloadPdf = () => {
        const newData = customers.map(row => {
            delete row._id;
            delete row.phonecheck;
            delete row.activate;
            delete row.createdAt;
            delete row.__v;
            { !isPdfData.isCusid && delete row.cusid };
            { !isPdfData.isCustomerGroup && delete row.customergroup };
            { !isPdfData.isName && delete row.name };
            { !isPdfData.isAddress && delete row.address };
            { !isPdfData.isArea && delete row.area };
            { !isPdfData.isMobileno && delete row.mobileno };
            { !isPdfData.isWhatsapp && delete row.whatsapp };
            { !isPdfData.isGstn && delete row.gstn };
            { !isPdfData.isUin && delete row.uin };
            { !isPdfData.isAadhaar && delete row.aadhaar };
            { !isPdfData.isPanno && delete row.panno };
            { !isPdfData.isCountry && delete row.country };
            { !isPdfData.isState && delete row.state };
            { !isPdfData.isCity && delete row.city };
            { !isPdfData.isCreditlimit && delete row.creditlimit };
            { !isPdfData.isCreditdays && delete row.creditdays };
            { !isPdfData.isDlnos && delete row.dlnos };
            { !isPdfData.isLedgerbalance && delete row.ledgerbalance };
            { !isPdfData.isCategory && delete row.category };
            setIsPdfData(row)
        })

        const doc = new jsPDF()
        doc.autoTable({
            theme: "grid",
            body: customers,
        })
        doc.save('CategoryWiseCustomerList.pdf')
    }

    return (
        <Box >
            <Headtitle title={'Category Wise Customers List'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Category Wise Customers List</Typography>
            <Box sx={userStyle.filtercontent}>
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={4} md={4} >
                        <Grid container >
                            <Grid item lg={3} md={3}>
                                <Typography sx={{ marginTop: 1 }}> Category</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <Selects
                                        onChange={handleChangeManufacture}
                                        styles={colourStyles}
                                        options={customername}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onClick={(e) => { fetchCustomerSelectAll(e) }} checked={!checked} />} label="Select All" />
                        </FormGroup>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <Button variant='contained' color='secondary' onClick={fetchCustomers}>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvcategorywisecustomerlist && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelcategorywisecustomerlist && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printcategorywisecustomerlist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfcategorywisecustomerlist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => handleOpenPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="categorywisecustomerslist" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Customer ID</StyledTableCell>
                                    <StyledTableCell>Customer Group</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Address</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Mobile No</StyledTableCell>
                                    <StyledTableCell>WhatsApp No</StyledTableCell>
                                    <StyledTableCell>GSTIN No</StyledTableCell>
                                    <StyledTableCell>UIN No</StyledTableCell>
                                    <StyledTableCell>Aadhaar No</StyledTableCell>
                                    <StyledTableCell>PAN No</StyledTableCell>
                                    <StyledTableCell>Country</StyledTableCell>
                                    <StyledTableCell>State</StyledTableCell>
                                    <StyledTableCell>City</StyledTableCell>
                                    <StyledTableCell>Credit Limit</StyledTableCell>
                                    <StyledTableCell>Credit Days</StyledTableCell>
                                    <StyledTableCell>DL Nos</StyledTableCell>
                                    <StyledTableCell>Category</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customers.length > 0 && (
                                    customers.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.cusid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.customergroup}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell >
                                            <StyledTableCell align="left">{row.address}</StyledTableCell>
                                            <StyledTableCell align="left">{row.area}</StyledTableCell>
                                            <StyledTableCell align="left">{row.mobileno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                                            <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                            <StyledTableCell align="left">{row.uin}</StyledTableCell>
                                            <StyledTableCell align="left">{row.aadhaar}</StyledTableCell>
                                            <StyledTableCell align="left">{row.panno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.country}</StyledTableCell>
                                            <StyledTableCell align="left">{row.state}</StyledTableCell>
                                            <StyledTableCell align="left">{row.city}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditlimit}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                                            <StyledTableCell align="left">{row.dlnos}</StyledTableCell>
                                            <StyledTableCell align="left">{row.category}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Table End ****** */}

            { /* ****** Print ****** */}

            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Category Wise Customer List Print</Typography>
                </Box>
                <Box  >
                    <TableContainer component={Paper} sx={userStyle.printcls}>
                        <Table aria-label="simple table" id="categorywisecustomerslistprint">
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Customer ID</StyledTableCell>
                                    <StyledTableCell>Customer Group</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Address</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Mobile No</StyledTableCell>
                                    <StyledTableCell>WhatsApp No</StyledTableCell>
                                    <StyledTableCell>GSTIN No</StyledTableCell>
                                    <StyledTableCell>UIN No</StyledTableCell>
                                    <StyledTableCell>Aadhaar No</StyledTableCell>
                                    <StyledTableCell>PAN No</StyledTableCell>
                                    <StyledTableCell>Country</StyledTableCell>
                                    <StyledTableCell>State</StyledTableCell>
                                    <StyledTableCell>City</StyledTableCell>
                                    <StyledTableCell>Credit Limit</StyledTableCell>
                                    <StyledTableCell>Credit Days</StyledTableCell>
                                    <StyledTableCell>DL Nos</StyledTableCell>
                                    <StyledTableCell>Category</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customers.length > 0 && (
                                    customers.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.cusid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.customergroup}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell >
                                            <StyledTableCell align="left">{row.address}</StyledTableCell>
                                            <StyledTableCell align="left">{row.area}</StyledTableCell>
                                            <StyledTableCell align="left">{row.mobileno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                                            <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                            <StyledTableCell align="left">{row.uin}</StyledTableCell>
                                            <StyledTableCell align="left">{row.aadhaar}</StyledTableCell>
                                            <StyledTableCell align="left">{row.panno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.country}</StyledTableCell>
                                            <StyledTableCell align="left">{row.state}</StyledTableCell>
                                            <StyledTableCell align="left">{row.city}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditlimit}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                                            <StyledTableCell align="left">{row.dlnos}</StyledTableCell>
                                            <StyledTableCell align="left">{row.category}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>

            {/* PDF Model */}
            <Box>
                <Dialog
                    open={openPdf}
                    onClose={handleClosePdf}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">
                        Select Option to Print PDF
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="subtitle1">Choose any 6</Typography>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCusid} onChange={(e) => { setIsPdfData({ ...isPdfData, isCusid: !isPdfData.isCusid }) }} />} label="Customer ID" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCustomerGroup} onChange={(e) => setIsPdfData({ ...isPdfData, isCustomerGroup: !isPdfData.isCustomerGroup })} />} label="Customer Group" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isName} onChange={(e) => setIsPdfData({ ...isPdfData, isName: !isPdfData.isName })} />} label="Name" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isAddress} onChange={(e) => setIsPdfData({ ...isPdfData, isAddress: !isPdfData.isAddress })} />} label="Address" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isArea} onChange={(e) => setIsPdfData({ ...isPdfData, isArea: !isPdfData.isArea })} />} label="Area" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isMobileno} onChange={(e) => setIsPdfData({ ...isPdfData, isMobileno: !isPdfData.isMobileno })} />} label="Mobile No" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isWhatsapp} onChange={(e) => setIsPdfData({ ...isPdfData, isWhatsapp: !isPdfData.isWhatsapp })} />} label="WhatsApp No" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isGstn} onChange={(e) => setIsPdfData({ ...isPdfData, isGstn: !isPdfData.isGstn })} />} label="GSTIN No" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isUin} onChange={(e) => setIsPdfData({ ...isPdfData, isUin: !isPdfData.isUin })} />} label="UIN No" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isAadhaar} onChange={(e) => setIsPdfData({ ...isPdfData, isAadhaar: !isPdfData.isAadhaar })} />} label="GSTAadhaar No" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isPanno} onChange={(e) => setIsPdfData({ ...isPdfData, isPanno: !isPdfData.isPanno })} />} label="PAN No" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCountry} onChange={(e) => setIsPdfData({ ...isPdfData, isCountry: !isPdfData.isCountry })} />} label="Country" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isState} onChange={(e) => setIsPdfData({ ...isPdfData, isState: !isPdfData.isState })} />} label="State" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCity} onChange={(e) => setIsPdfData({ ...isPdfData, isCity: !isPdfData.isCity })} />} label="City" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCreditlimit} onChange={(e) => setIsPdfData({ ...isPdfData, isCreditlimit: !isPdfData.isCreditlimit })} />} label="Credit Limit" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCreditdays} onChange={(e) => setIsPdfData({ ...isPdfData, isCreditdays: !isPdfData.isCreditdays })} />} label="Credit Days" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isDlnos} onChange={(e) => setIsPdfData({ ...isPdfData, isDlnos: !isPdfData.isDlnos })} />} label="DL Nos" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isLedgerbalance} onChange={(e) => setIsPdfData({ ...isPdfData, isLedgerbalance: !isPdfData.isLedgerbalance })} />} label="Ledger Balance" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCategory} onChange={(e) => setIsPdfData({ ...isPdfData, isCategory: !isPdfData.isCategory })} />} label="Category" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='primary' onClick={() => downloadPdf()} autoFocus>PDF</Button>
                        <Button variant='contained' color='error' onClick={handleClosePdf}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box >
    );
}

function Categorywisecustomerlist() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Categorywisecustomerlisttable /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Categorywisecustomerlist;