import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, FormControl, Grid, Paper, Table, TableRow, TableCell, TableBody, TableHead, TableContainer, Button, TableFooter } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import CreatableSelect from 'react-select/creatable';
import { useReactToPrint } from "react-to-print";

function Groupsummarylist() {
    const [groupsummary, setGroupsummary] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
   
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { auth, setAuth } = useContext(AuthContext);

    //  Group Summary...........
    const fetchGroupsummary = async () => {
        try {
            let customer = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = customer.data.customerpays.filter((data) => {
                return ''
             })
            setAuth({ ...auth, loginState: true });
            setGroupsummary(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 25, 50, 100, 200, 500, -1],
                            [10, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    useEffect(
        () => {
        }, []
    )

    // Excel...........
    const fileName = " Group Summary ";
    const customerexcel = async () => {
        let res_customerpayment = await axios.get('http://localhost:8003/api/groupsummary');
        var data = res_customerpayment.data.customerpayment.map(t => ({
            contactid: t.contactid, firstname: t.firstname,
            lastname: t.lastname, email: t.email, creditlimit: t.creditlimit, payterm: t.payterm, paytermassign: t.paytermassign,
            openbalance: t.openbalance, customergroup: t.customergroup, contactperson: t.contactperson,
            addressone: t.addressone, addresstwo: t.addresstwo, phonenumber: t.phonenumber, whatsappno: t.whatsappno
        }));
        setExceldata(data);
    }

     // Print
     const componentRef = useRef();
     const handleprint = useReactToPrint({
         content: () => componentRef.current,
         documentTitle: 'HIPOS | GROUP SUMMARY',
         pageStyle: 'print'
     });

    //  PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Groupsummary' })
        doc.save('Groupsummary.pdf')
    }
    useEffect(
        () => {
            fetchGroupsummary();
            customerexcel();
        }, []
    )
    let total = 0;

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        // $(document).ready(function () {
        //     $.fn.dataTable.ext.errMode = 'none';
        //     setTimeout(function () {
        //        
        //         $(tableRef.current).DataTable({
        //             language: { search: '', searchPlaceholder: "Search..." },
        //             lengthMenu: [
        //                 [10, 25, 50, 100, 200, 500, -1],
        //                 [10, 25, 50, 100, 200, 500, 'All'],
        //             ],
        //         });
        //     }, 1000);
        // });
    }, [])

    return (
        <Box >
            <Headtitle title={'Group Summary'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Group Summary <Typography sx={userStyle.SubHeaderText}></Typography></Typography>
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    <Grid item lg={1} md={1} style={{ display: 'flex', justifyContent: "end" }}>
                        <Button variant='contained' color='secondary'
                            onClick={(e) => { handleClickOpen() }}
                        >add</Button>
                    </Grid>
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                    <Grid >
                            {isUserRoleCompare[0].csvgroupsummary && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelgroupsummary && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printgroupsummary && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfgroupsummary && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                        {/* ****** Table Grid Container ****** */}
                        <Grid container>
                            <Grid md={4} sm={2} xs={1}></Grid>
                            <Grid md={8} sm={10} xs={10} sx={{ align: "center" }}></Grid>
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Groupsummary" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Particulaus</StyledTableCell>
                                    <StyledTableCell>Debit</StyledTableCell>
                                    <StyledTableCell>Credit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {groupsummary.length > 0 && (
                                    groupsummary.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.Particulaus}</StyledTableCell>
                                            <StyledTableCell align="left">{row.Particulaus}</StyledTableCell>
                                            <StyledTableCell align="left">{row.Particulaus}</StyledTableCell >
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow >
                                    <TableCell>Total</TableCell>
                                    <TableCell>4646123</TableCell>
                                    <TableCell>541254</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell>Gross Profit</TableCell>
                                    <TableCell>4646123</TableCell>
                                    <TableCell>541254</TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell>Net Total</TableCell>
                                    <TableCell>4646123</TableCell>
                                    <TableCell>541254</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>

                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle id="alert-dialog-title">
                    {" Account Group Selection "}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography sx={{ marginTop: 1 }}> Account Group : </Typography>
                        <FormControl fullWidth>
                            <CreatableSelect
                                isClearable
                            // onChange={handleActnofill}
                            // styles={colourStyles}
                            // onInputChange={handleInputActnofill}
                            // options={actno}
                            />
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Ok</Button>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
function Groupsummary() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Groupsummarylist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Groupsummary;