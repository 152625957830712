import React from "react";
import { Box, Paper, Typography, Table, TableBody, TableContainer, TableHead, } from "@mui/material";
import { StyledTableRow, StyledTableCell } from "../components/Table";

function Dashrecentsale() {

  //  JQUERY
  // $(document).ready(function () {
  //     setTimeout(function () {
  //         $('#expcattable').DataTable({
  //             language: { search: '', searchPlaceholder: "Search..." },
  //             lengthMenu: [25, 50, 100, 200, 500, 1000],
  //         });
  //     }, 1000);
  // });

  return (
    <Box>
      <Box sx={{margin:'10px'}}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            id="expcattable"
          >
            <TableHead>
            <Typography variant='h6'>Recent Sales</Typography>
              <StyledTableRow>
                <StyledTableCell align="left">Reference</StyledTableCell>
                <StyledTableCell align="left">Customer</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
                <StyledTableCell align="left">Grand Total</StyledTableCell>
                <StyledTableCell align="left">Paid</StyledTableCell>
                <StyledTableCell align="left">Due</StyledTableCell>
                <StyledTableCell align="left">Payment Status</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default Dashrecentsale;
