import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import moment from "moment";

function Salesreport() {

  const [salesreport, setSalesreport] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);

  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  let billNoArr = []
  let billdateArr = []
  let totalItems = []
  let totalQuantity = []
  let subTotal = []
  let discount = []
  let cgst = []
  let sgst = []
  let igst = []
  let otherCharges = []
  let roundoff = []
  let netTotal = []
  let paymentMethod = []
  let arrMixed = []

  const fetchpos = async () => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      
      let getDatawithFilter = req.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('DD-MM-YYYY')
        if (data.activate == true) {
          if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
          } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
          }
          else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
          }
          else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
          }
        }
      })
      
      getDatawithFilter.map(item => {
        let dateTrim = moment(item.date).utc().format('YYYY-MM-DD')

        billNoArr.push(item.referenceno)
        billdateArr.push(dateTrim)
        totalItems.push(+item.totalitems)
        item.goods.map(value => {
          totalQuantity.push(+value.quantity)
          subTotal.push(+value.subtotal)
          discount.push(+value.discountamt)
          value.subtax.map(i => {
            cgst.push(+i.taxratecgst)
            sgst.push(+i.taxrategst)
            igst.push(+i.taxrateigst)
          })
        },
          otherCharges.push(+item.packcharge),
          roundoff.push(+item.roundof),
          netTotal.push(+item.aftergranddisctotal),
          paymentMethod.push(item.paymentmethod),
        )
      })
      
      arrMixed = billNoArr.map(function (data, i) {
        return {
          billno: data,
          referenceno: billNoArr[i],
          date: billdateArr[i],
          totalitems: totalItems[i],
          quantity: totalQuantity[i],
          subtotal: subTotal[i],
          discountamt: discount[i],
          taxratecgst: cgst[i],
          taxrategst: sgst[i],
          taxrateigst: igst[i],
          packcharge: otherCharges[i],
          roundof: roundoff[i],
          aftergranddisctotal: netTotal[i],
          paymentmethod: paymentMethod[i],
        }
      });

      // calculation
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.billno;
        const items = r.get(key) || Object.assign({}, o, {
          date: "",
          totalitems: 0,
          packcharge: 0,
          roundof: 0,
          aftergranddisctotal: 0,
          paymentmethod: "",
          quantity: 0,
          subtotal: 0,
          discountamt: 0,
          taxratecgst: 0,
          taxrategst: 0,
          taxrateigst: 0,
        });
        items.date = o.date
        items.totalitems = o.totalitems
        items.packcharge += o.packcharge
        items.roundof += o.roundof
        items.aftergranddisctotal += o.aftergranddisctotal
        items.paymentmethod = o.paymentmethod
        items.quantity = o.quantity 
        items.subtotal = o.subtotal 
        items.discountamt = o.discountamt 
        items.taxratecgst = o.taxratecgst 
        items.taxrategst = o.taxrategst 
        items.taxrateigst = o.taxrateigst 
        return r.set(key, items);
      }, new Map).values()];

      setSalesreport(result);

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchpos();
  }, [])

  // Export Excel
  const fileName = 'Sales Report'
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let datas = salesreport.map(t => ({
      "Bill no": t.referenceno,
      "Bil Date": moment(t.date).format('DD-MM-YYYY'),
      "Total Items": t.totalitems,
      "Total Quantity": t.quantity,
      "Sub Total": t.subtotal,
      "Discount": t.discountamt,
      "Cgst": t.taxratecgst,
      "Sgst": t.taxrategst,
      "Igst": t.taxrateigst,
      "Other Charges": t.packcharge,
      "Round off": t.roundof,
      "Net Total": t.aftergranddisctotal,
      "Payment Method": t.paymentmethod,
    }));
    setExceldata(datas);
  }

  useEffect(() => {
    getexcelDatas();
  }, [salesreport])

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#salesreportpdf' })
    doc.save('Sales Report.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Sales Report',
    pageStyle: 'print'
  });

  return (
    <Box>
      <Headtitle title={'Sales Report'} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography sx={userStyle.importheadtext}>Sales Report (Retail)</Typography>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid><br />
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchpos} >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      <Headtitle title={'salesreport'} />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
        { /* ******************************************************EXPORT Buttons****************************************************** */}
        {/*       
          <Box sx={userStyle.container} > */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvsalesreport && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelsalesreport && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printsalesreport && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfsalesreport && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table id="usertable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill no</StyledTableCell>
                  <StyledTableCell>Bil Date</StyledTableCell>
                  <StyledTableCell>Total Items</StyledTableCell>
                  <StyledTableCell>Total Quantity</StyledTableCell>
                  <StyledTableCell>Sub Total</StyledTableCell>
                  <StyledTableCell>Discount</StyledTableCell>
                  <StyledTableCell>Cgst</StyledTableCell>
                  <StyledTableCell>Sgst</StyledTableCell>
                  <StyledTableCell>Igst</StyledTableCell>
                  <StyledTableCell>Other Charges</StyledTableCell>
                  <StyledTableCell>Round off</StyledTableCell>
                  <StyledTableCell>Net Total</StyledTableCell>
                  <StyledTableCell>Payment Method</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {salesreport &&
                  (salesreport.map((row, index) => (
                    <StyledTableRow key={index}>
                    <StyledTableCell >{row.referenceno}</StyledTableCell>
                    <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                    <StyledTableCell >{row.totalitems}</StyledTableCell>
                    <StyledTableCell >{row.quantity}</StyledTableCell>
                    <StyledTableCell >{row.subtotal}</StyledTableCell>
                    <StyledTableCell >{row.discountamt}</StyledTableCell>
                    <StyledTableCell >{row.taxratecgst}</StyledTableCell>
                    <StyledTableCell >{row.taxrategst}</StyledTableCell>
                    <StyledTableCell >{row.taxrateigst}</StyledTableCell>
                    <StyledTableCell >{row.packcharge}</StyledTableCell>
                    <StyledTableCell >{row.roundof}</StyledTableCell>
                    <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                    <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                  </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table id="salesreportpdf" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Bill no</StyledTableCell>
              <StyledTableCell>Bil Date</StyledTableCell>
              <StyledTableCell >Total Items</StyledTableCell>
              <StyledTableCell >Total Quantity</StyledTableCell>
              <StyledTableCell>Sub Total</StyledTableCell>
              <StyledTableCell>Discount</StyledTableCell>
              <StyledTableCell >Cgst</StyledTableCell>
              <StyledTableCell >Sgst</StyledTableCell>
              <StyledTableCell>Igst</StyledTableCell>
              <StyledTableCell>Other Charges</StyledTableCell>
              <StyledTableCell >Round off</StyledTableCell>
              <StyledTableCell >Net Total</StyledTableCell>
              <StyledTableCell >Payment Method</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {salesreport &&
              (salesreport.map((row, index) => (
                <StyledTableRow key={index}>
                      <StyledTableCell >{row.referenceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.totalitems}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                      <StyledTableCell >{row.subtotal}</StyledTableCell>
                      <StyledTableCell >{row.discountamt}</StyledTableCell>
                      <StyledTableCell >{row.taxratecgst}</StyledTableCell>
                      <StyledTableCell >{row.taxrategst}</StyledTableCell>
                      <StyledTableCell >{row.taxrateigst}</StyledTableCell>
                      <StyledTableCell >{row.packcharge}</StyledTableCell>
                      <StyledTableCell >{row.roundof}</StyledTableCell>
                      <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                      <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                    </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Salesreportpage() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Salesreport /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Salesreportpage;