import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import moment from 'moment';

function CashBookReportList() {

    const [cashbooks, setCashbooks] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // Datefield 
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today,
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // CashBook
    const fetchCashBook = async () => {
        try {
            let req = await axios.get(SERVICE.CASH_BOOK, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            let getDatawithFilter = req.data.cashbooks.filter((data) => {
                let splittedMonth = data.createdAt
                let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                  return data
                }
                else if (dateFilter.startdate == dateTrim && dateFilter.enddate == "") {
                  return data
                }
                else if (dateFilter.startdate == "" && dateFilter.enddate == dateTrim) {
                  return data
                }
                else if (dateFilter.startdate == dateTrim && dateFilter.enddate == dateTrim) {
                  return data
                }
                else if (dateTrim >= dateFilter.startdate && dateTrim <= dateFilter.enddate) {
                  return data
                }
        
              })         
            setCashbooks(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                  $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                      [10, 1,25, 50, 100, 200, 500, -1],
                      [10, 1,25, 50, 100, 200, 500, 'All'],
                    ],
                  });
                }, 1000);
              });
           
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Cash Book Report'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = cashbooks?.map(t => ({
            'Reference No': t.referenceno, 'Date': moment(t.date).format("DD-MM-YYYY"), 'Account': t.account, 'Account Group': t.accountgroup,
            'Amount': t.amount, 'Entry': t.entry, 'Narration': t.narration,
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CASH BOOK REPORT',
        pageStyle: 'print'
    });

   // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#cashbook' })
    doc.save('Cash Book Report.pdf')
  }
    useEffect(
        () => {
           
            getexcelDatas();
        }, [exceldata]
    )

    return (
        <Box >
            <Headtitle title={'Cash Book Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Cash Book Report</Typography>
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={4} md={4}>
                            <Grid container  >
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{ marginTop: 1 }}>From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, startdate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Grid container >
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, enddate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={fetchCashBook}>Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />

                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvcashbookreport && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelcashbookreport && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printcashbookreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfcashbookreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />

                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="cashbook" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Account</StyledTableCell>
                                    <StyledTableCell>Account Group</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Entry</StyledTableCell>
                                    <StyledTableCell>Narration</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {cashbooks.length > 0 && (
                                    cashbooks.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{ row.date == "" ? " ":  moment(row.date).format("DD-MM-YYYY") }</StyledTableCell>
                                            <StyledTableCell align="left">{row.account}</StyledTableCell >
                                            <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                                            <StyledTableCell align="left">{row.amount}</StyledTableCell>
                                            <StyledTableCell align="left">{row.paymethod}</StyledTableCell>
                                            <StyledTableCell align="left">{row.narration}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Cash Book Report Print</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="cashbook">
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Reference No</StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Account</StyledTableCell>
                                        <StyledTableCell>Account Group</StyledTableCell>
                                        <StyledTableCell>Amount</StyledTableCell>
                                        <StyledTableCell>Entry</StyledTableCell>
                                        <StyledTableCell>Narration</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {cashbooks.length > 0 && (
                                        cashbooks.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{moment(row.date).format("DD-MM-YYYY")}</StyledTableCell>
                                                <StyledTableCell align="left">{row.account}</StyledTableCell >
                                                <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                                                <StyledTableCell align="left">{row.amount}</StyledTableCell>
                                                <StyledTableCell align="left">{row.paymethod}</StyledTableCell>
                                                <StyledTableCell align="left">{row.narration}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function CashBookReport() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <CashBookReportList /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default CashBookReport;