import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import moment from "moment";
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import { AuthContext } from '../../context/Appcontext';

function Salessummary() {

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  const [salesummary, setSalesummary] = useState();
  // const [exceldata,setExceldata] = useState([]);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // Fetch Sales Summary data
  const fetchSaleSummary = async () => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = req.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } 
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate > dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate > dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
      })
      setSalesummary(getDatawithFilter);

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Export Excel
  const fileName = 'Sales Summary'

  // //  get particular columns for export excel
  // const getexcelDatas = async () => {
  //   let data = salesummary?.map(t => ({
  //     "Bill No": t.referenceno, "Bill Amount": t.aftergranddisctotal, "Pay Mode": t.paymentmethod, "Item Name": t?.goods?.map((value) => value.productname + ", "), Rate: t.grandtotal, Amount: t.grandtotal
  //   }));
  //   setExceldata(data);
  // }

  // useEffect(() => {
  //   getexcelDatas()
  // }, [salesummary ])
  
  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#salessummary' })
    doc.save('Sales Summary.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | SALES SUMMARY',
    pageStyle: 'print'
  });

  return (

    <Box>
      <Headtitle title={'Sales Summary'} />
      <Typography sx={userStyle.HeaderText}>Sales Summary</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' sx={{ marginLeft: "1em" }} onClick={fetchSaleSummary}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {/* {isUserRoleCompare[0].csvsalessummary && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].excelsalessummary && (<ExportXL csvData={exceldata} fileName={fileName} />)} */}
            {isUserRoleCompare[0].printsalessummary && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfsalessummary && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="salessummary" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill NO</StyledTableCell>
                  <StyledTableCell >Bill Amount</StyledTableCell>
                  <StyledTableCell >Pay Mode</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Rate</StyledTableCell>
                  <StyledTableCell >Amount</StyledTableCell>
                  {/* <StyledTableCell >Batch</StyledTableCell>
                  <StyledTableCell >Expiry</StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {salesummary &&
                  (salesummary.map((row, index) => (
                    <StyledTableRow >
                      <StyledTableCell >{row.referenceno}</StyledTableCell>
                      <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                      <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                      <StyledTableCell >{row?.goods?.map((value) => value.productname + ",")}</StyledTableCell>
                      <StyledTableCell >{row?.goods?.map((value) => value.subtotal + ",")}</StyledTableCell>
                      <StyledTableCell >{row.grandtotal}</StyledTableCell>
                      {/* <StyledTableCell ></StyledTableCell>
                      <StyledTableCell ></StyledTableCell> */}
                    </StyledTableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="salessummary" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Bill NO</StyledTableCell>
              <StyledTableCell >Bill Amount</StyledTableCell>
              <StyledTableCell >Pay Mode</StyledTableCell>
              <StyledTableCell >Item Name</StyledTableCell>
              <StyledTableCell >Rate</StyledTableCell>
              <StyledTableCell >Amount</StyledTableCell>
              {/* <StyledTableCell >Batch</StyledTableCell>
              <StyledTableCell >Expiry</StyledTableCell> */}
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {salesummary &&
              (salesummary.map((row, index) => (
                <StyledTableRow >
                  <StyledTableCell >{row.referenceno}</StyledTableCell>
                  <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                  <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                  <StyledTableCell >{row.goods.map((value) => value.productname + ",")}</StyledTableCell>
                  <StyledTableCell >{row?.goods?.map((value) => value.subtotal + ",")}</StyledTableCell>
                  <StyledTableCell >{row.grandtotal}</StyledTableCell>
                  {/* <StyledTableCell ></StyledTableCell>
                  <StyledTableCell ></StyledTableCell> */}
                </StyledTableRow>
              )))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Salessummarylist() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Salessummary /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Salessummarylist;