import React, { useState, useEffect, useContext } from 'react';
import { userStyle } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Dialog, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';

function Alphacreatelist() {

    const [alphas, setAlphas] = useState();

    const { auth } = useContext(AuthContext);
    const [setngs, setSetngs] = useState();

    //popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => {
        setIsErrorOpen(true);
    };
    const handleClose = () => {
        setIsErrorOpen(false);
    };

    // auto id 
    let newval = "AP0001";

    //alpharate auto id from settings
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Purchase 
    const fetchHandler = async () => {
        try {
            let alpha = await axios.get(SERVICE.ALPHARATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAlphas(alpha.data.alpharates);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const [alpha, setAlpha] = useState({
        1: "", alphatwo: "", alphathree: "", alphafour: "", alphafive: "", alphasix: "", alphaseven: "", alphaeight: "", alphanine: "",
        alphazero: "", one: 1, two: 2, three: 3, four: 4, five: 5, six: 6, seven: 7, eight: 8, nine: 9, zero: 0, alphaid: newval, activate: false
    });

    const backPage = useNavigate();

    const handleAlphaZero = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphazero: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphazero: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphazero: num })
        }

    }
    const handleAlphaOne = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphaone: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphaone: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphaone: num })
        }
    }
    const handleAlphaTwo = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphatwo: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphatwo: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphatwo: num })
        }
    }
    const handleAlphaThree = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphathree: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphathree: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphathree: num })
        }
    }
    const handleAlphaFour = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphafour: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphafour: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphafour: num })
        }
    }
    const handleAlphaFive = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphafive: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphafive: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphafive: num })
        }
    }
    const handleAlphaSix = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphasix: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphasix: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphasix: num })
        }
    }
    const handleAlphaSeven = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphaseven: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphaseven: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphaseven: num })
        }
    }
    const handleAlphaEight = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphaeight: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphaeight: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphaeight: num })
        }
    }
    const handleAlphaNine = (e) => {
        let val = e;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (e.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphanine: value,})
        }
        else if (regExSpecialChar.test(e)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setAlpha({ ...alpha, alphanine: value,})
        }
        else if(e.length > 1){
            setShowAlert("Alpha can't have more than 1 character!")
            handleClickOpen();
            let num = e.slice(0, 1);
            setAlpha({ ...alpha, alphanine: num })
        }
    }

    const addAlpharate = async () => {
        try {
            let res = await axios.post(SERVICE.ALPHARATE_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                1: String(alpha.alphaone),
                2: String(alpha.alphatwo),
                3: String(alpha.alphathree),
                4: String(alpha.alphafour),
                5: String(alpha.alphafive),
                6: String(alpha.alphasix),
                7: String(alpha.alphaseven),
                8: String(alpha.alphaeight),
                9: String(alpha.alphanine),
                0: String(alpha.alphazero),
                one: Number(alpha.one),
                two: Number(alpha.two),
                three: Number(alpha.three),
                four: Number(alpha.four),
                five: Number(alpha.five),
                six: Number(alpha.six),
                seven: Number(alpha.seven),
                eight: Number(alpha.eight),
                nine: Number(alpha.nine),
                zero: Number(alpha.zero),
                activate: Boolean(false),
                alphaid: String(newval),
            });
            setAlpha(res.data)
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/settings/alpharate/list');
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(alpha.alphazero == ""){
            setShowAlert("Please enter Alpha Zero!");
            handleClickOpen();
        }
        else if(alpha.alphaone == ""){
            setShowAlert("Please enter Alpha One!");
            handleClickOpen();
        }
        else if(alpha.alphatwo == ""){
            setShowAlert("Please enter Alpha Two!");
            handleClickOpen();
        }
        else if(alpha.alphathree == ""){
            setShowAlert("Please enter Alpha Three!");
            handleClickOpen();
        }
        else if(alpha.alphafour == ""){
            setShowAlert("Please enter Alpha Four!");
            handleClickOpen();
        }
        else if(alpha.alphafive == ""){
            setShowAlert("Please enter Alpha Five!");
            handleClickOpen();
        }
        else if(alpha.alphasix == ""){
            setShowAlert("Please enter Alpha Six!");
            handleClickOpen();
        }
        else if(alpha.alphaseven == ""){
            setShowAlert("Please enter Alpha Seven!");
            handleClickOpen();
        }
        else if(alpha.alphaeight == ""){
            setShowAlert("Please enter Alpha Eight!");
            handleClickOpen();
        }
        else if(alpha.alphanine == ""){
            setShowAlert("Please enter Alpha Nine!");
            handleClickOpen();
        }
        else{
            addAlpharate();
        }
    }

    useEffect(() => {
        fetchHandler();
        fetchSettings();
    }, [])

    return (
        <Box>
            <Headtitle title={'Alpharate Create'} />
            <form onSubmit={handleSubmit}>
                <Box>
                    <Grid display="flex">
                        <Typography sx={userStyle.HeaderText}>Add Alpharate</Typography>
                    </Grid>
                </Box>
                <Box sx={userStyle.container}>
                    <FormControl variant="outlined" size="small" fullWidth>
                        {alphas && (
                            alphas.map(
                                () => {
                                    let strings = 'AP';
                                    let refNo = alphas[alphas.length - 1].alphaid;
                                    let digits = (alphas.length + 1).toString();
                                        const stringLength = refNo.length;
                                        let lastChar = refNo.charAt(stringLength - 1);
                                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                                        let lastBeforeChar = refNo.slice(-2);
                                        let lastThreeChar = refNo.slice(-3);
                                        let lastDigit = refNo.slice(-4);
                                        let refNOINC = parseInt(lastChar) + 1
                                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                                        let refLstThree = parseInt(lastThreeChar) + 1;
                                        let refLstDigit = parseInt(lastDigit) + 1;
                                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                            refNOINC = ("000" + refNOINC).substr(-4);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                            refNOINC = ("00" + refLstTwo).substr(-4);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                                            refNOINC = ("0" + refLstThree).substr(-4);
                                            newval = strings + refNOINC;
                                        } else {
                                            refNOINC = (refLstDigit).substr(-4);
                                            newval = strings + refNOINC;
                                        }
                                }))}
                        <input
                            id="outlined-adornment-password"
                            value={newval}
                            hidden
                            onClick={(e) => setAlpha({ ...alpha, alphaid: newval })}
                            name="alphaid"
                        />
                    </FormControl>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel  >Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphazero}
                                    onChange={(e) => { setAlpha({ ...alpha, alphazero: e.target.value }); handleAlphaZero(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.zero}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphaone}
                                    onChange={(e) => { setAlpha({ ...alpha, alphaone: e.target.value }); handleAlphaOne(e.target.value)  }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.one}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphatwo}
                                    onChange={(e) => { setAlpha({ ...alpha, alphatwo: e.target.value }); handleAlphaTwo(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.two}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphathree}
                                    onChange={(e) => { setAlpha({ ...alpha, alphathree: e.target.value }); handleAlphaThree(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.three}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphafour}
                                    onChange={(e) => { setAlpha({ ...alpha, alphafour: e.target.value }); handleAlphaFour(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.four}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphafive}
                                    onChange={(e) => { setAlpha({ ...alpha, alphafive: e.target.value }); handleAlphaFive(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.five}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphasix}
                                    onChange={(e) => { setAlpha({ ...alpha, alphasix: e.target.value }); handleAlphaSix(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.six}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphaseven}
                                    onChange={(e) => { setAlpha({ ...alpha, alphaseven: e.target.value }); handleAlphaSeven(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.seven}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphaeight}
                                    onChange={(e) => { setAlpha({ ...alpha, alphaeight: e.target.value }); handleAlphaEight(e.target.value) }}
                                    type="text"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.eight}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={userStyle.formBorder}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel>Alpha <b style={{color:"red"}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder=""
                                    value={alpha.alphanine}
                                    onChange={(e) => { setAlpha({ ...alpha, alphanine: e.target.value }); handleAlphaNine(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel >Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={alpha.nine}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center' }}>
                        <Grid >
                                <Link to="/settings/alpharate/list"><Button sx={userStyle.buttoncancel} type="button">CANCEL</Button></Link>
                            <Button sx={userStyle.buttonadd} type="submit">SAVE</Button>
                        </Grid>
                    </Grid>
                </Box>
            </form>
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h6" >{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function Alphacreate() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Alphacreatelist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Alphacreate;