import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Button, Typography, Dialog, DialogContent, DialogActions, Select, MenuItem, TextField, } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { userStyle, colourStyles } from '../../PageStyle';
import { toast } from 'react-toastify';
import Selects from 'react-select';
import axios from 'axios';

const Discountcreate = () => {

    const [discounts, setDiscounts] = useState();
    const [productList, setProductList] = useState([]);
    const [busilocations, setBusilocations] = useState([]);
    const [allProducts, setAllProducts] = useState({});
    const [product, setProduct] = useState({});
    const [setngs, setSetngs] = useState();
    const { auth } = useContext(AuthContext);

    const [disAddForm, setDisAddForm] = useState({
        location: "", discountid: "", name: "", products: "", category: "", schedule: "", molecule: "", manufacture: "", purchaseexcludetax: "",
        purchaseincludetx: "", discounttype: "None", selectdiscountprice: "Purchase Excluding Tax",
        discountamt: 0, discountvalue: 0, startsat: "", endsat: "", isactive: "",
    });

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    // auto id for pur  chase number
    let newval = setngs ? setngs.discountsku + "0001" : "DS0001"; 

    //supplier auto id from settings
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Discounts
    const fetchDiscount = async () => {
        try {
            let discountList = await axios.get(SERVICE.DISCOUNT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDiscounts(discountList.data.discounts);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Business Location
    const fetchBusinessLocation = async () => {
        try {
            let req = await axios.get(SERVICE.BUSINESS_LOCATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBusilocations(
                req?.data?.busilocations?.map((d) => ({
                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const singleProd = async (id) => {

        try {
          let res = await axios.get(`${SERVICE.PRODUCT_SINGLE}/${id}`, {
            headers: {
              'Authorization': `Bearer ${auth.APIToken}`
            }
          });
          setProduct(res?.data?.sproduct);
       
        }
        catch (err) {
          const messages = err.response.data.message;
          toast.error(messages);
        }
      };

    // Products
    const fetchProducts = async () => {
        try {
            let request = await axios.get(SERVICE.PRODUCT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAllProducts(request.data.products);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // get all products based on location
    const getProducts = (e) => {
        let getcustomerallbill = allProducts?.filter((item) => {
            if (e.name == item.businesslocation) {
               return(item); 
            }
        })
        setProductList(getcustomerallbill.map((d)=>({
            ...d,
            label:d.prodname,
            value:d.prodname
        })))
    }

    useEffect(
        () => {
            fetchSettings();
            fetchDiscount()
            fetchBusinessLocation();
            fetchProducts();
        }, []
    );

    let purExc = product.purchaseexcludetax
    let purInc = product.pruchaseincludetax
    let selExc = product.sellingpriceunitcost

    const getDiscount = () => {

        if (disAddForm.discounttype == "None") {
            if (disAddForm.selectdiscountprice == "Purchase Excluding Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(purExc)
                })
            }
            else if (disAddForm.selectdiscountprice == "Purchase Including Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(purInc)
                })
            }
            else if (disAddForm.selectdiscountprice == "Selling Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(selExc)
                })
            }
        }
        if (disAddForm.discounttype == "Fixed" || disAddForm.discounttype == "Amount") {
            if (disAddForm.selectdiscountprice == "Purchase Excluding Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(purExc) - Number(disAddForm.discountamt)
                })
            }
            else if (disAddForm.selectdiscountprice == "Purchase Including Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(purInc) - Number(disAddForm.discountamt)
                })
            }
            else if (disAddForm.selectdiscountprice == "Selling Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(selExc) - Number(disAddForm.discountamt)
                })
            }
        }
        if (disAddForm.discounttype == "Percentage") {
            if (disAddForm.selectdiscountprice == "Purchase Excluding Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(purExc) - (Number(purExc) * (Number(disAddForm.discountamt) / 100))
                })
            }
            else if (disAddForm.selectdiscountprice == "Purchase Including Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(purInc) - (Number(purExc) * (Number(disAddForm.discountamt) / 100))
                })
            }
            else if (disAddForm.selectdiscountprice == "Selling Tax") {
                setDisAddForm({
                    ...disAddForm, discountvalue: Number(selExc) - (Number(purExc) * (Number(disAddForm.discountamt) / 100))
                })
            }
        }
    }

    useEffect(() => {
        getDiscount();
    }, [disAddForm.products, disAddForm.discountamt, disAddForm.selectdiscountprice])

    const bactToPage = useNavigate();

    const sendRequest = async () => {
        try {
            let req = await axios.post(SERVICE.DISCOUNT_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                location: String(disAddForm.location),
                discountid: String(disAddForm.discountid),
                name: String(disAddForm.name),
                productid:String(product.prodcode),
                products: String(disAddForm.products),
                category: String(product.category),
                schedule: String(product.schedule),
                molecule: String(product.molecules),
                manufacture: String(product.manufacture),
                purchaseexcludetax: Number(product.purchaseexcludetax),
                purchaseincludetx: Number(product.pruchaseincludetax),
                discounttype: String(disAddForm.discounttype),
                selectdiscountprice: String(disAddForm.selectdiscountprice),
                sellingvalue: Number(product.sellingpriceunitcost),
                discountamt: Number(disAddForm.discountamt),
                discountvalue: Number(disAddForm.discountvalue.toFixed(0)),
                startsat: String(disAddForm.startsat),
                endsat: String(disAddForm.endsat),
                productvalue: String(disAddForm.productvalue),
            });
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            bactToPage('/product/discount/list');
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }

    }

    const handleAddSubmit = (e) => {
        e.preventDefault();
        if (disAddForm.location == "") {
            setShowAlert("Please Select Location!")
            handleClickOpen();
        }
        else if (disAddForm.name == "") {
            setShowAlert("Please Enter Name!")
            handleClickOpen();
        }
        else if (disAddForm.products == "") {
            setShowAlert("Please Select Product Name!")
            handleClickOpen();
        }
        else if (disAddForm.discounttype == "") {
            setShowAlert("Please Select Discount Type!")
            handleClickOpen();
        }
        else if (disAddForm.discountamt == "") {
            setShowAlert("Please Enter Discount Amount!")
            handleClickOpen();
        }
        else {
            sendRequest();
        }
    }

    const handleBack = () => {
        bactToPage('/product/discount/list')
    }

    const handleValidationName = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setDisAddForm({ ...disAddForm, name: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setDisAddForm({ ...disAddForm, name: value })
        }
    }

    const handleValidationAmount = (e) => {
        let val = e.target.value;
        let alphabets = new RegExp('[a-zA-Z]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
        if (e.target.value.match(alphabets)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setDisAddForm({ ...disAddForm, discountamt: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setDisAddForm({ ...disAddForm, discountamt: value })
        }
    }

    return (
        <Box>
            <Headtitle title={'Discount Create'} />
            <Typography sx={userStyle.HeaderText}>Add Discount</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleAddSubmit}>
                    <Grid container spacing={3}>
                        {/* Grid one */}
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined" sx={{ display: "flex" }}>Location <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    placeholder="Select location"
                                    onChange={(e) => {
                                        // handleChangeManufacture(e);
                                        setDisAddForm({ ...disAddForm, location: e.value })
                                        getProducts(e);
                                    }}
                                    styles={colourStyles}
                                    options={busilocations}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Discount Id</InputLabel>
                            <FormControl size="small" fullWidth>
                                {discounts && (
                                    discounts.map(
                                        () => {
                                            let strings = setngs ? setngs.discountsku : "DS";
                                            let refNo = discounts[discounts.length - 1].discountid;
                                            let digits = (discounts.length + 1).toString();
                                            const stringLength = refNo.length;
                                            let lastChar = refNo.charAt(stringLength - 1);
                                            let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                            let getlastThreeChar = refNo.charAt(stringLength - 3);
                                            let lastBeforeChar = refNo.slice(-2);
                                            let lastThreeChar = refNo.slice(-3);
                                            let lastDigit = refNo.slice(-4);
                                            let refNOINC = parseInt(lastChar) + 1
                                            let refLstTwo = parseInt(lastBeforeChar) + 1;
                                            let refLstThree = parseInt(lastThreeChar) + 1;
                                            let refLstDigit = parseInt(lastDigit) + 1;
                                            if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                                refNOINC = ("000" + refNOINC).substr(-4);
                                                newval = strings + refNOINC;
                                            } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                                refNOINC = ("00" + refLstTwo).substr(-4);
                                                newval = strings + refNOINC;
                                            } else if (digits.length < 4 && getlastThreeChar > 0) {
                                                refNOINC = ("0" + refLstThree).substr(-4);
                                                newval = strings + refNOINC;
                                            } else {
                                                refNOINC = (refLstDigit).substr(-4);
                                                newval = strings + refNOINC;
                                            }
                                        }))}
                                <OutlinedInput
                                    value={newval}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined" sx={{ display: "flex" }}>Name <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                   
                                    value={disAddForm.name}
                                    type="text"
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, name: e.target.value, discountid: newval }); handleValidationName(e) }}
                                />
                            </FormControl>
                        </Grid>
                        {/* Grid two */}
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined" sx={{ display: "flex" }}>Product Name <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    placeholder="Select product"
                                    styles={colourStyles}
                                    onChange={(e)=>{singleProd(e._id);setDisAddForm({ ...disAddForm, products: e.value })}}
                                    options={productList}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Category</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={product.category}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Schedule </InputLabel>
                            <FormControl size="small" fullWidth>
                                <TextField
                                    value={product.schedule}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Molecule</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={product.molecules}
                                  
                                    
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Manufacture</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={product.manufacture}
                                />
                            </FormControl>
                        </Grid>
                        {/* Grid three */}

                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Purchase Excluding Tax</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={product.purchaseexcludetax}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Purchase Including Tax</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={product.pruchaseincludetax}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Selling Tax</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={product.sellingpriceunitcost}
                                    type="number"
                                    sx={userStyle.input}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined"  sx={{ display: "flex" }}>Discount Type <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={disAddForm.discounttype}
                                    onChange={(e) => {
                                        setDisAddForm({ ...disAddForm, discounttype: e.target.value });
                                    }}
                                >
                                    <MenuItem value="None" onClick={(e) => { getDiscount() }}>None</MenuItem>
                                    <MenuItem value="Fixed" onClick={(e) => { getDiscount() }}>Fixed</MenuItem>
                                    <MenuItem value="Amount" onClick={(e) => { getDiscount() }}>Amount</MenuItem>
                                    <MenuItem value="Percentage" onClick={(e) => { getDiscount() }}>Percentage</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Select Discount Price</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={disAddForm.selectdiscountprice}
                                    onChange={(e) => {
                                        setDisAddForm({ ...disAddForm, selectdiscountprice: e.target.value });

                                    }}
                                >
                                    <MenuItem value="Purchase Including Tax" onClick={(e) => { getDiscount() }}>Purchase Including Tax</MenuItem>
                                    <MenuItem value="Purchase Excluding Tax" onClick={(e) => { getDiscount() }}>Purchase Excluding Tax</MenuItem>
                                    <MenuItem value="Selling Tax" onClick={(e) => { getDiscount() }}>Selling Tax</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined" sx={{ display: "flex" }}>Discount Amount <b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.discountamt}
                                    type="number"
                                    sx={userStyle.input}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, discountamt: e.target.value }); handleValidationAmount(e) }}
                                />
                            </FormControl>
                        </Grid>
                        {/* Grid four */}
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel  htmlFor="component-outlined">Discount Value</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.discountvalue}
                                    type="number"
                                    sx={userStyle.input}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, discountvalue: e.target.value }); }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Start At</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.startsat}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, startsat: e.target.value }) }}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={4} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Ends At</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={disAddForm.endsat}
                                    onChange={(e) => { setDisAddForm({ ...disAddForm, endsat: e.target.value }) }}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Button sx={userStyle.buttoncancel} onClick={handleBack}>CANCEL</Button>
                                <Button autoFocus sx={userStyle.buttonadd} type="submit" disableRipple>SAVE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}
function Discountcreates() {
    return (

        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Discountcreate /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Discountcreates;