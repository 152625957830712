import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Select, Grid, InputLabel, FormControl, Box, MenuItem, TextField, OutlinedInput, TextareaAutosize, Dialog, DialogContent, DialogActions } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { userStyle } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';

function Usereditlist() {


    const { auth } = useContext(AuthContext);



    const [useradd, setUseradd] = useState({
        email: "", phonenum: "", role: "", entryno: "", date: "", department: "",
        designation: "", staffid: "", dateofjoin: "", staffname: "", fathername: "", gender: "", bloodgroup: "", dateobth: "", religion: "",
        address: "", city: "", pincode: "", otherconno: "", profileimage: "", maritalstatus: "", familydetails: "", educationdetails: "",
        experiencedetails: "", jobdetails: "", languageknown: "", aadharno: "", bankacno: "", remarks: "", area: ""
    });

    const [datevalue, setDateValue] = useState(dayjs());

    const handleChange = (newValue) => {
        setUseradd({ ...useradd, date: newValue });
    };
    //popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    const id = useParams().id
    const [autoid, setAutoid] = useState();

    const getUser = async () => {
        try {
            let res = await axios.get(`${SERVICE.USERNEW_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setUseradd(res.data.susernew)
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }


    const updateUser = async () => {
        try {
            let res = await axios.put(`${SERVICE.USERNEW_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                email: String(useradd.email),
                phonenum: Number(useradd.phonenum),
                role: String(useradd.role),
                entryno: Number(useradd.entryno),
                date: String(datevalue),
                department: String(useradd.department),
                staffid: String(useradd.staffid),
                dateofjoin: String(useradd.dateofjoin),
                staffname: String(useradd.staffname),
                fathername: String(useradd.fathername),
                gender: String(useradd.gender),
                bloodgroup: String(useradd.bloodgroup),
                dateobth: String(useradd.dateobth),
                religion: String(useradd.religion),
                address: String(useradd.address),
                city: String(useradd.city),
                pincode: Number(useradd.pincode),
                otherconno: Number(useradd.otherconno),
                profileimage: String(useradd.profileimage),
                maritalstatus: String(useradd.maritalstatus),
                familydetails: String(useradd.familydetails),
                educationdetails: String(useradd.educationdetails),
                experiencedetails: String(useradd.experiencedetails),
                jobdetails: String(useradd.jobdetails),
                languageknown: String(useradd.languageknown),
                aadharno: String(useradd.aadharno),
                bankacno: String(useradd.bankacno),
                remarks: String(useradd.remarks),
                area: String(useradd.area),

            });
            setUseradd(res.data)
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/user/user/list');
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const handleMobile = (e) => {
        if (e.length > 10) {
            setShowAlert("Mobile number can't have more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setUseradd({ ...useradd, phonenum: num })
        }
    }
    const handleAadhaar = (e) => {
        var a = e.target.value;
        if (a.length > 12) {
            setShowAlert("Aadhaar number can't have more than 12 characters!")
            handleClickOpen();
            let num = a.slice(0, 12);
            setUseradd({ ...useradd, aadharno: num })
        }
    }

    const handlePincode = (e) => {
        if (e.length > 6) {
            setShowAlert("Pincode can't have more than 6!")
            handleClickOpen();
            let num = e.slice(0, 6);
            setUseradd({ ...useradd, pincode: num })
        }
    }
    const handleAlter = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't have more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setUseradd({ ...useradd, otherconno: num })
        }
    }

    const backPage = useNavigate();

    useEffect(
        () => {
            getUser();
        }, [id]);

    const fetchHandler = async () => {
        try {
            let user = await axios.get(`${SERVICE.USERNEW}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAutoid(user.data.usersnew);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    useEffect(() => {
        fetchHandler();
    }, [])


    const handleSubmit = (e) => {
        e.preventDefault();
        if (useradd.role == "") {
            setShowAlert("Please enter role!")
            handleClickOpen();
        }
        else if (useradd.staffname == "") {
            setShowAlert("Please enter staff name!")
            handleClickOpen();
        }
        else if (useradd.fathername == "") {
            setShowAlert("Please enter father name!")
            handleClickOpen();
        }
        else if (useradd.phonenum == "") {
            setShowAlert("Please enter mobile!")
            handleClickOpen();
        }
        else if (useradd.email == "") {
            setShowAlert("Please enter email!")
            handleClickOpen();
        }
        else {
            updateUser();
        }
    }

    var regex = /[`+!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    const handleValidationStaff = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        if (e.target.value.match(numbers)) {
            setShowAlert("Please Enter Letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, staffname: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, staffname: value })
        }
    }

    const handleValidationfName = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')

        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, fathername: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, fathername: value })
        }
    }

    const handleValidationReligion = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, religion: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, religion: value })
        }
    }

    const handleValidationNationality = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        if (e.target.value.match(numbers)) {
            setShowAlert("Please Enter Letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, city: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, city: value })
        }
    }
    


    return (
        <Box>
            <Headtitle title={'Staff Profile Edit'} />
            <Typography sx={userStyle.HeaderText}>Staff Profile Edit</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={{ padding: '40px 20px' }}>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Entry Number <b style={{ color: "red" }}>*</b></InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={useradd.entryno}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                            <Typography variant="caption">Leave blank to auto generate</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Date</InputLabel>
                            <FormControl size="small" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        value={useradd.date}
                                        onChange={handleChange}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Department</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={useradd.department}
                                >
                                </OutlinedInput>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Designation<b style={{ color: "red" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={useradd.role}


                                >
                                </OutlinedInput>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Staff ID<b style={{ color: "red" }}>*</b></InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={useradd.staffid}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                            <Typography variant="caption">Leave blank to auto generate</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Date of Join</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.dateofjoin}
                                    onChange={(e) => { setUseradd({ ...useradd, dateofjoin: e.target.value }) }}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Staff Name <b style={{ color: "red" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.staffname}
                                    onChange={(e) => { setUseradd({ ...useradd, staffname: e.target.value }); handleValidationStaff(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Father's Name <b style={{ color: "red" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.fathername}
                                    onChange={(e) => { setUseradd({ ...useradd, fathername: e.target.value }); handleValidationfName(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Gender</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    value={useradd.gender}
                                    onChange={(e) => { setUseradd({ ...useradd, gender: e.target.value }); }}
                                    isClearable
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                >
                                    <MenuItem value={'Male'}>Male</MenuItem>
                                    <MenuItem value={'Female'}>Female</MenuItem>
                                    <MenuItem value={'Others'}>Others</MenuItem>
                                </Select>
                            </FormControl><br></br>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Blood Group</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.bloodgroup}
                                    onChange={(e) => { setUseradd({ ...useradd, bloodgroup: e.target.value }) }}
                                    type="text"

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Date of Birth</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.dateobth}
                                    onChange={(e) => { setUseradd({ ...useradd, dateobth: e.target.value }) }}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Religion</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.religion}
                                    onChange={(e) => { setUseradd({ ...useradd, religion: e.target.value }); handleValidationReligion(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Nationality</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.city}
                                    onChange={(e) => { setUseradd({ ...useradd, city: e.target.value }); handleValidationNationality(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Address</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput aria-label="minimum height"
                                    value={useradd.address}
                                    onChange={(e) => { setUseradd({ ...useradd, address: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Area/city</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.area}
                                    onChange={(e) => { setUseradd({ ...useradd, area: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Pincode</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.pincode}
                                    onChange={(e) => { setUseradd({ ...useradd, pincode: e.target.value }); handlePincode(e.target.value) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Mobile</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.phonenum}
                                    onChange={(e) => { setUseradd({ ...useradd, phonenum: e.target.value }); handleMobile(e.target.value) }}
                                    type="Number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Other Contact Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.otherconno}
                                    onChange={(e) => { setUseradd({ ...useradd, otherconno: e.target.value }); handleAlter(e.target.value) }}
                                    type="Number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Email <b style={{ color: "red" }}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.email}
                                    onChange={(e) => { setUseradd({ ...useradd, email: e.target.value }) }}
                                    type="text/number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Marital Status</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={useradd.maritalstatus}
                                    onChange={(e) => { setUseradd({ ...useradd, maritalstatus: e.target.value }) }}
                                >
                                    <MenuItem value={'Married'}>Married</MenuItem>
                                    <MenuItem value={'Unmarried'}>Unmarried</MenuItem>
                                    <MenuItem value={'Divorced'}>Divorced</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4} >
                            <InputLabel>Product Image</InputLabel>
                            <Grid sx={{ display: 'flex', justifyContent: 'center' }} >
                                <img src={useradd.profileimage} style={{ width: '30%' }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Family Details</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.familydetails}
                                    onChange={(e) => { setUseradd({ ...useradd, familydetails: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Education Details</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.educationdetails}
                                    onChange={(e) => { setUseradd({ ...useradd, educationdetails: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Experience Details</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    type="text"
                                    value={useradd.experiencedetails}
                                    onChange={(e) => { setUseradd({ ...useradd, experiencedetails: e.target.value }) }}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Job Details</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    type="text"
                                    value={useradd.jobdetails}
                                    onChange={(e) => { setUseradd({ ...useradd, jobdetails: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Language Known</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    type="text"
                                    value={useradd.languageknown}
                                    onChange={(e) => { setUseradd({ ...useradd, languageknown: e.target.value }) }}
                                />

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Aadhaar Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.aadharno}
                                    onChange={(e) => { setUseradd({ ...useradd, aadharno: e.target.value }); handleAadhaar(e) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <InputLabel htmlFor="component-outlined">Bank A/C Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={useradd.bankacno}
                                    onChange={(e) => { setUseradd({ ...useradd, bankacno: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <InputLabel htmlFor="component-outlined">Remarks</InputLabel>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={5} mincol={5} style={{ border: '1px solid #b97df0' }}
                                    value={useradd.remarks}
                                    onChange={(e) => { setUseradd({ ...useradd, remarks: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Link to="/user/user/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                                <Button sx={userStyle.buttonadd} type="submit" >Update</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>

            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose} >ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Useredit() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Usereditlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Useredit;