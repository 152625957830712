import React, { useState, useEffect, useContext } from "react";
import { Box, Grid,Dialog,DialogContent,DialogActions,Typography,Button, FormControl, InputLabel, OutlinedInput,Select, MenuItem, } from '@mui/material';
import { userStyle } from "../../PageStyle";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import axios from 'axios';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { toast } from 'react-toastify';

export default function Productcreate({setngs, setSetngs}) {

    const [units, setUnits] = useState();
    const [taxrates, setTaxrates] = useState();
    const { auth } = useContext(AuthContext);

    // Pop up error
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()

    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    const handleValidationSku = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,skuprefix: value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,skuprefix: value};
            })
        }
    }

    const handleValidationMinQty = (e) => {
        let val = e.target.value;
        let alphabets = new RegExp('[a-zA-Z]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(alphabets)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,minquantity:value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,minquantity:value};
            })        
        }
    }

    const handleValidationMaxQty = (e) => {
        let val = e.target.value;
        let alphabets = new RegExp('[a-zA-Z]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(alphabets)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,maxquantity:value};
            })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter numbers only! (0-9)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSetngs((prevState)=> {
                return {...prevState,maxquantity:value};
            })        
        }
    }

    // Units
    const fetchUnit = async () => {
        try {
            let unitlist = await axios.get(SERVICE.UNIT,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setUnits(unitlist.data.units);
        } catch (err) {
            const messages = err.response.data.message;
            toast.err(messages);
        }
    };

     // Taxrates
     const fetchRates = async () => {
        try {
            let response = await axios.get(SERVICE.TAXRATE,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            // let taxRateData = response.data.taxrates.filter((data) => {
            //   return (data.taxtype == "taxrate" || data.taxtype == "taxrategroup") && data.fortaxgonly == false
            // })
          setTaxrates(response.data.taxrates)
        } catch (err) {
            const messages = err.response.data.message;
            toast.err(messages);
        }
    };

    useEffect(
        () =>{
            fetchUnit();
            fetchRates();
        },[]
    )

    return (
        <Box sx={userStyle.container}>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel htmlFor="component-outlined">SKU Prefix</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            name="skuprefix"
                            value={setngs.skuprefix}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,skuprefix:e.target.value};
                            }); handleValidationSku(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel htmlFor="component-outlined">Default Unit</InputLabel>
                    <FormControl size="small" fullWidth>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={setngs.defaulunit}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,defaulunit:e.target.value};
                            })}
                            fullWidth
                        >
                            <MenuItem value={"None"}>{"None"}</MenuItem>
                            {units && (
                                units.map((item, index) => (
                                    <MenuItem value={item.unit} key={index}>{item.unit}</MenuItem>
                                )))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel id="demo-select-small">Applicable Tax</InputLabel>
                    <FormControl size="small" fullWidth>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={setngs.applicabletax}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,applicabletax:e.target.value};
                            })}
                        >
                            <MenuItem value="None">None</MenuItem>
                            {taxrates && (
                                taxrates.map((row, index) => (
                                    <MenuItem value={row.taxname} key={index}
                                    >{row.taxname}</MenuItem> 
                                ))
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel htmlFor="component-outlined">Multiselect</InputLabel>
                    <FormControl size="small" fullWidth>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={setngs.multivalue}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,multivalue:e.target.value};
                            })}
                            fullWidth
                        >
                           <MenuItem value="None">None</MenuItem>
                           <MenuItem value="Color">Color</MenuItem>
                           <MenuItem value="Size">Size</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel htmlFor="component-outlined">Selling price tax</InputLabel>
                    <FormControl size="small" fullWidth>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={setngs.sellingpricetax}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,sellingpricetax:e.target.value};
                            })}
                            fullWidth
                        >
                           <MenuItem value="Exclusive">Exclusive</MenuItem>
                           <MenuItem value="Inclusive">Inclusive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel htmlFor="component-outlined">Min quantity</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            name="skuprefix"
                            value={setngs.minquantity}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,minquantity:e.target.value};
                            }) ; handleValidationMinQty(e)}}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <InputLabel htmlFor="component-outlined">Max quantity</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput
                            id="component-outlined"
                            type="text"
                            name="skuprefix"
                            value={setngs.maxquantity}
                            onChange={(e) => {setSetngs((prevState)=> {
                                return {...prevState,maxquantity:e.target.value};
                            }); handleValidationMaxQty(e)}}
                        />
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Enable Size" value={setngs.esize} onChange={(e) => setSetngs({...setngs, esize:!setngs.esize})} />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Enable Unit" value={setngs.eunit} onChange={(e) => setSetngs({...setngs, eunit:!setngs.eunit})} />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Enable Category" value={setngs.ecategory} onChange={(e) => setSetngs({...setngs, ecategory:!setngs.ecategory})} />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox />} label="Enable Brand" value={setngs.ebrand} onChange={(e) => setSetngs({...setngs, ebrand:!setngs.ebrand})} />
                    </FormGroup>
                </Grid> */}
            </Grid>
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h6">{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}