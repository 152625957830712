import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import moment from "moment";
import { AuthContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from '../../services/Baseservice';

function Diagnosissummary() {

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  const [count, setCount] = useState([]);
  const [pos, setPos] = useState();
  const [exceldata,setExceldata] = useState([]);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  let billNoArr = [];
  let dateArr = [];
  let patientArr = [];
  let itemNameArr = [];
  let qtyArr = [];
  let allArray = []
  let counts = [];
  const res = [];

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // Pos
  const fetchPos = async () => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = req.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
        } 
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
      })

      getDatawithFilter?.map(item => {
        billNoArr?.push(item.referenceno)
        let splittedMonth = item.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        dateArr?.push(dateTrim)
        patientArr?.push(item.customer)

        item.goods.map((value)=>{
          itemNameArr?.push(value.productname)
          qtyArr?.push(+value.quantity)
        })
      })
      billNoArr?.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

      const splitKeyValue = obj => {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          res.push({
            'billcount': obj[keys[i]]
          });
        };
        return res;
      };
      setCount(splitKeyValue(counts))
      allArray = billNoArr.map(function (data, i) {
        return { billno: data, date: dateArr[i], patientname: patientArr[i], itemname: itemNameArr[i], quantity: qtyArr[i] }
      })

      const result = [...allArray.reduce((r, o) => {
        const key = o.billno;
        const items = r.get(key) || Object.assign({}, o, {
          date: "",
          patientname: "",
          itemname: "",
          quantity: 0
        });
        items.date = o.date
        items.patientname = o.patientname
        items.itemname += o.itemname + ", "
        items.quantity += o.quantity

        return r.set(key, items);
      }, new Map).values()];

      setPos(result)

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
  };

  // Export Excel
  const fileName = 'Diagnosis Wise Sales Summary'

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = pos?.map(t => ({
      "Bill No": t.billno, Date: t.date, "Patient Name": t.patientname, "Item Name": t.itemname, Quantity: t.quantity
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  }, [pos])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | DIAGNOSIS WISE SALES SUMMARY',
    pageStyle: 'print'
  });

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#Diagnosiswisesalessummary' })
    doc.save('Diagnosis Wise Sales Summary.pdf')
  }

  return (

    <Box>
      <Headtitle title={'Diagnosis Wise Sales Summary'} />
      <Typography sx={userStyle.HeaderText}>Diagnosis Wise Sales Summary</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' sx={{ marginLeft: "1em" }} onClick={fetchPos}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvdiagnosiswisesalessummary &&  (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].exceldiagnosiswisesalessummary &&  (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printdiagnosiswisesalessummary && ( <Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfdiagnosiswisesalessummary && (<Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid>
        <br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="Diagnosiswisesalessummary" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill NO</StyledTableCell>
                  <StyledTableCell >Date</StyledTableCell>
                  <StyledTableCell >Patient Name</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Quantity</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {pos &&
                  (pos.map((row, index) => (
                    <StyledTableRow >
                      <StyledTableCell >{row.billno}</StyledTableCell>
                      <StyledTableCell >{row.date}</StyledTableCell>
                      <StyledTableCell >{row.patientname}</StyledTableCell>
                      <StyledTableCell >{row.itemname}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                    </StyledTableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="Diagnosiswisesalessummary" ref={componentRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill NO</StyledTableCell>
                  <StyledTableCell >Date</StyledTableCell>
                  <StyledTableCell >Patient Name</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Quantity</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {pos &&
                  (pos.map((row, index) => (
                    <StyledTableRow >
                      <StyledTableCell >{row.billno}</StyledTableCell>
                      <StyledTableCell >{row.date}</StyledTableCell>
                      <StyledTableCell >{row.patientname}</StyledTableCell>
                      <StyledTableCell >{row.itemname}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                    </StyledTableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
    </Box>
  );
}

function Diagnosissummarypage() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Diagnosissummary /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Diagnosissummarypage;