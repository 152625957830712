import React, { useState, useEffect ,useContext} from 'react';
import { userStyle , colourStyles} from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel,Dialog, DialogContent,DialogActions,OutlinedInput, Select, MenuItem, Typography,  TextField, FormGroup, FormControlLabel, Checkbox,   Button, IconButton,  Tooltip } from '@mui/material';
import { FcInfo } from "react-icons/fc";
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { useNavigate, useParams,Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Selects from 'react-select';
import { AuthContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import {SERVICE} from '../../../services/Baseservice';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function Producteditlist() {

    const [categories, setCategories] = useState();
    const [units, setUnits] = useState([]);
    const [taxtypes, setTaxtypes] = useState([]);
    const [hsnGrp, sethsnGrp] = useState([]);
    const [schedule, setSchedule] = useState();
    const { auth } = useContext(AuthContext);
    const [taxrates, setTaxrates] = useState([]);
    const [busilocations, setBusilocations] = useState();

    const selltaxtype = [{type:"Exclusive"},{type:"Inclusive"}];
    let alltype = [];
    alltype = selltaxtype.flat();
    // Text field
    const [product, setProduct] = useState({});
     
    const id = useParams().id;

    // Units
    const fetchUnit = async () => {
        try {
            let unitlist = await axios.get(SERVICE.UNIT,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                  },
            });
            setUnits( unitlist.data.unit?.map((d) => ({
                        
                ...d,
                label: d.unit,
                value: d.unit,
            })) );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

  // Business Location
  const fetchLocation = async () => {
    try {
        let response = await axios.get(SERVICE.BUSINESS_LOCATION,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
              },
        });
  
        setBusilocations(response.data.busilocations.map((d)=>({
        
            ...d,
            label: d.name,
            value: d.name,
        })));
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
};

    // schedules
    const fetchSchedule = async () => {
        try {
            let response = await axios.get(SERVICE.SCHEDULE,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                  },
            });
            setSchedule(
                response.data.schedule?.map((d) => ({
                    
                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Taxrates
    const taxrateRequest = async () => {
    try {
        let taxrates = await axios.get(SERVICE.TAXRATE,{
        headers: {
            'Authorization':`Bearer ${auth.APIToken}`
            },
        });
        let hsnGrpData = taxrates.data.taxrates.filter((data) => {
            if(data.taxtype == "hsn"){
                return data
            }
        })
        sethsnGrp(hsnGrpData);

        setTaxrates(
        taxrates?.data?.taxrates?.map((d) => ({
            ...d,
            label: d.taxname,
            value: d.taxname,
        })))
    
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
    }

    //selling tax type
    const taxtype = () => {
        try{
         setTaxtypes(
             alltype?.map((d) => ({
                 ...d,
                 label: d.type,
                 value: d.type,
             }))
         );

        }  
       catch (err) {
         const messages = err.response.data.message;
         toast.error(messages);
     }
    }

    // ALERT POPUP
    //popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert,setShowAlert] = useState()
    const handleOpen = () => {
        setIsErrorOpen(true);
    };
    const handleClose = () => {
        setIsErrorOpen(false);
    };

    const backLPage = useNavigate();
 
    //fetch all products
    const getProducts = async () => {
        try {
            let res = await axios.get(`${SERVICE.PRODUCT_SINGLE}/${id}`,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                  },
            })
            setProduct(res.data.sproduct)
           
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Categorys
    const fetchCategory = async () => {
        try {
            let response = await axios.get(SERVICE.CATEGORIES,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                  },
            });
            setCategories(
                response.data.categories?.map((d) => ({
                    
                    ...d,
                    label: d.categoryname,
                    value: d.categoryname,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // update Datas
    const sendRequest = async () => {
        try{
            let productedit = await axios.put(`${SERVICE.PRODUCT_SINGLE}/${id}`, {
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                  },
                  prodcode: String(product.prodcode),
                  prodname: String(product.prodname),
                  category: String(product.category),
                  schedule: String(product.schedule),
                  molecules: String(product.molecules),
                  manufacture: String(product.manufacture),
                  businesslocation: String(product.businesslocation),
                  managestock: Boolean(product.managestock),
                  hsn: String(product.hsn),
                  hsncodecheck:Boolean(product.hsncodecheck),
                  hsncode: String(product.hsncode),
                  unit: String(product.unit),
                  rack: String(product.rack),
                  row: String(product.row),
                  boxno:String(product.boxno),
                  minquantity: Number(product.minquantity),
                  maxquantity: Number(product.maxquantity),
                  reorderlevel:Number(product.reorderlevel),
                  applicabletax:String(product.applicabletax),
                  selltaxtype:String(product.selltaxtype),
                  batch:String(product.batch),
                  currentstock:Number(product.currentstock),
                  purchaseexcludetax:Number(product.purchaseexcludetax),
                  pruchaseincludetax:Number(product.pruchaseincludetax),
                  sellingpriceunitcost:Number(product.sellingpriceunitcost),
                  ptr:Number(product.prt),
                pts:Number(product.pts),
                mrp:Number(product.mrp),
                expirydate:String(product.expirydate),
            });
            backLPage('/product/product/list');

            toast.success(productedit.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
        }catch(err){
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleOpen();
        }
    };

     // fetch all data from page load
    useEffect(
        () => {
        fetchUnit();
        getProducts();
        taxrateRequest();
        fetchSchedule();
        taxtype();
        fetchLocation();
        fetchCategory();
;    }, []);

    
    const handleSubmit = (e) => {
        e.preventDefault();
        if(product.prodname == ""){
            setShowAlert("Please enter product name!");
            handleOpen();
        }else if(product.category == ""){
            setShowAlert("Please enter category!");
            handleOpen();

        }else if(product.schedule == ""){
            setShowAlert("Please enter schedule!");
            handleOpen();

        }else if(product.molecules == ""){
            setShowAlert("Please enter molecule!");
            handleOpen();

        }else if(product.manufacture == ""){
            setShowAlert("Please enter manufacture!");
            handleOpen();

        }else{
            sendRequest();
        }
    }
    return (
        <Box>
            <Headtitle title={'Edit Product'} />
            <form onSubmit={handleSubmit}>
                {/* header text */}
                <Typography sx={userStyle.HeaderText}>Edit product</Typography>
                {/* content start */}
                <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="component-outlined" >Product Code</InputLabel>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput                                   
                                id="component-outlined"
                                value={product.prodcode}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="component-outlined" >Product Name<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput
                                id="component-outlined"
                                value={product.prodname}
                                onChange={(e) => { setProduct({ ...product, prodname: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Category<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <Selects                  
                                styles={colourStyles}
                                options={categories}
                                placeholder={product.category}
                                onChange={(e) => { 
                                    setProduct({ ...product, category: e.categoryname });                                       
                                }}
                            /> 
                         </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Schedule<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth>
                            <Selects                     
                                styles={colourStyles}
                                options={schedule}
                                placeholder={product.schedule}
                                onChange={(e) => { setProduct({ ...product, schedule: e.name}) }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Molecule<b style={{ color: "red" }}> *</b></InputLabel>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput
                                id="component-outlined"
                                value={product.molecules}
                                onChange={(e) => { setProduct({ ...product,molecules:e.target.value  }); }}
                            />
                        </FormControl>
                    </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <InputLabel id="demo-select-small">Manufacture<b style={{ color: "red" }}> *</b></InputLabel>
                    <FormControl size="small" fullWidth >
                        <OutlinedInput
                            id="component-outlined"
                            value={product.manufacture}
                            onChange={(e) => { setProduct({ ...product,manufacture:e.target.value  });console.log(e) }}
                            />
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <InputLabel id="demo-select-small">Business Location</InputLabel>
                      <FormControl size="small" fullWidth>
                            <Selects                     
                                styles={colourStyles}
                                options={busilocations}
                                placeholder={product.businesslocation}
                                onChange={(e) => { setProduct({ ...product, businesslocation: e.name}) }}
                            />
                      </FormControl>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <InputLabel htmlFor="component-outlined" >HSN Code</InputLabel>
                      <FormControl size="small" fullWidth >
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={product.hsn}
                            onChange={(e) => { setProduct({ ...product, hsn: e.target.value, hsncode:e.target.value }) }}
                        >
                        {hsnGrp && (
                            hsnGrp.map((row, index) => ( 
                                <MenuItem value={row.hsn} key={index}>{row.hsn}</MenuItem>
                            )))}
                        </Select> 
                      </FormControl>
                      <FormGroup>
                         <FormControlLabel control={<Checkbox  checked={Boolean(product.hsncodecheck)} onChange={(e) => { setProduct({ ...product,hsncodecheck: !product.hsncodecheck})}} />} label="Enable tax with HSN code" sx={{fontSize:'10px'}} />        
                      </FormGroup>
                  </Grid>
                </Grid>
                <br/>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="component-outlined" >Unit Desc</InputLabel>
                        <FormControl size="small" fullWidth >
                            <Selects
                                    options={units}
                                styles={colourStyles}
                                labelId="demo-select-small"
                                id="demo-select-small"
                                placeholder={product.unit}
                                onChange={(e) => { setProduct({ ...product, unit:e.unit});}}
                            ></Selects>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="component-outlined" >Rack</InputLabel>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput
                                id="component-outlined"
                                type="text"
                                value={product.rack}
                                onChange={(e) => { setProduct({ ...product, rack: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="component-outlined" >Row</InputLabel>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput
                                id="component-outlined"
                                type="text"
                                value={product.row}
                                onChange={(e) => { setProduct({ ...product, row: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="component-outlined" >Box No</InputLabel>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput
                                id="component-outlined"
                                type="text"
                                value={product.boxno}
                                onChange={(e) => { setProduct({ ...product, boxno: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <br />
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <FormGroup>
                            <span><FormControlLabel control={<Checkbox checked={Boolean(product.managestock)} onChange={(e) => { setProduct({ ...product, managestock: !product.managestock }) }} />} label="Manage Stock"/>
                                <Tooltip title="Enable or disable stock management for a product. you can set the min max quantity alert for the product and default minimum maximum value added in settings." placement="top" arrow>
                                    <IconButton size="small">
                                        <FcInfo />
                                    </IconButton>
                                </Tooltip>
                            </span>
                        </FormGroup>
                    </Grid>
                    {product.managestock && (
                        <>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="outlined-adornment-password">Minimum Quantity</InputLabel>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    value={product.minquantity}
                                    onChange={(e) => { setProduct({ ...product, minquantity: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="outlined-adornment-password">Maximum Quantity</InputLabel>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    value={product.maxquantity}
                                    onChange={(e) => { setProduct({ ...product, maxquantity: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                    </>
                    )}
                     <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel htmlFor="component-outlined" >Re-order level</InputLabel>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput
                                id="component-outlined"
                                type="number"
                                value={product.reorderlevel}
                                onChange={(e) => { setProduct({ ...product, reorderlevel: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                </Grid><br />
                <Grid container spacing={2}>
                    {product.hsncodecheck ? ( 
                        <>
                            <Grid item md={4} sm={4} xs={12}>
                                <InputLabel htmlFor="outlined-adornment-password">HSN code</InputLabel>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                            type="text"
                                            size='small'
                                            placeholder='HSN code'
                                            value={product.hsncode}
                                        />
                                </FormControl>
                            </Grid> 
                        </>
                    ) 
                    :
                       (
                        <>
                            <Grid item md={4} sm={4} xs={12}>
                                <InputLabel id="demo-select-small">Applicable Tax</InputLabel>
                                <FormControl size="small" fullWidth>
                                    <Selects
                                    options={taxrates}
                                    styles={ colourStyles}
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    placeholder={product.applicabletax}
                                    onChange={(e) => { setProduct({ ...product, applicabletax:e.taxname});}}
                                    >
                                    </Selects> 
                                </FormControl>
                            </Grid>
                        </>
                       ) }
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Selling price tax type</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects
                                options={taxtypes}
                                styles={colourStyles}
                                placeholder={product.selltaxtype}
                                    onChange={(e) => { setProduct({ ...product, selltaxtype: e.type }); }}
                                />  
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container sx={userStyle.gridcontainer}>
                        <Grid >
                            <Link to="/product/product/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                            <Button sx={userStyle.buttonadd} type="submit" >Update</Button>
                        </Grid>
                    </Grid>
                </Box>  
                {/* content end */} 
            </form>
            <br /> <br />
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h6" >{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
         </Box>
    );
}

function Productedit() {
    return (

        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Producteditlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Productedit;