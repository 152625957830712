import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Typography, Button, Table, TableBody, TableFooter, TableContainer, TableHead, Paper, Grid, } from '@mui/material';
import { userStyle } from '../PageStyle';
import { FaPrint, FaFilePdf, } from "react-icons/fa";
import $ from 'jquery';
import "rsuite/dist/rsuite.css";
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import axios from 'axios';
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';

function Categorywisesummaryall() {

  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const [values, setValues] = useState([]);
  const { auth } = useContext(AuthContext);

  let counts = {};
  let Array = [];
  let res = [];

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  const fetchCategory = async () => {
    try {
      let res = await axios.get(SERVICE.CATEGORIES, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let req = res.data.categories.filter((data) => {
        return data
      })
      setCategory(req);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  const fetchProduct = async () => {
    try {
      let res_product = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let reqdata = res_product.data.products.map((data) => {
        category.map((item) => {
          if (item.categoryname == data.category) {
            return Array.push(data.category)
          }
        })
      })
      setProducts(reqdata);

      Array?.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
      const splitKeyValue = obj => {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          res.push({
            'category': keys[i],
            'Totalitems': obj[keys[i]]
          });
        };
        return res;
      };

      setValues(splitKeyValue(counts));

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10,1, 25, 50, 100, 200, 500, -1],
              [10, 1,25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // total
  function totalNetCostCalc() {
    let totalvalue = 0;
    values?.forEach((value) => {
      totalvalue += Number(value.Totalitems)
    })
    return totalvalue.toFixed(0);
  }

  // Export Excel
  const fileName = 'Category Wise Item Summary';
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    var data = values.map(t => ({
      "Category": t.category, "Total Items": t.Totalitems,
    }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CATEGORY WISE ITEM SUMMARY',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    doc.autoTable({
      html: '#categorysum',
      margin: { top: 10 },
    })
    doc.save('Category Wise Item Summary.pdf')
  }

  useEffect(() => {
    fetchCategory();
    fetchProduct();
    getexcelDatas();
  }, [values])

  // Datatable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  useEffect(() => {
   
  }, [(tableRef.current)])

  return (
    <>
      <Box sx={userStyle.container}>
        <Headtitle title={'Category Wise Item Summary'} />
        <Typography variant='body2' sx={userStyle.HeaderText}>Category Wise Item Summary</Typography>
        {/* TABLE START    */}
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          {/* Export buttons start*/}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvcatesummary && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelcatesummary && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printsummary && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfsummary && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          {/* Export buttons end*/}
          <Table sx={{ minWidth: 700 }} aria-label="customized table" ref={tableRef} id="categorysum">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">Category</StyledTableCell>
                <StyledTableCell align="left">Total items</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {values &&
                values.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{row.category}</StyledTableCell>
                    <StyledTableCell align="left">{row.Totalitems}</StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
              <StyledTableCell align="center" colSpan={6} sx={{ color: 'black', fontSize: '20px', justifyContent: 'center' }}>Total item records: {Number(totalNetCostCalc())}</StyledTableCell>
            </TableFooter>
          </Table>
        </TableContainer>

        {/* print layout     */}
        <>
          <Box sx={userStyle.printcls} ref={componentRef}>
            <Box>
              <Typography variant='h5'>Category Wise Item Summary</Typography>
            </Box><br />
            <Box>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" id="categorytable" >
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>Category</StyledTableCell>
                      <StyledTableCell >Total items</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody align="left">
                    {values &&
                      values.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="left">{row.category}</StyledTableCell>
                          <StyledTableCell align="left">{row.Totalitems}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                  <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
                    <StyledTableCell align="center" colSpan={6} sx={{ color: 'black', fontSize: '20px', justifyContent: 'center' }}>Total item records: {Number(totalNetCostCalc())}</StyledTableCell>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      </Box>
    </>

  );
}

function Categorywisesummary() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Categorywisesummaryall /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Categorywisesummary;