import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function ReferencecomparisonList() {

    const [payments, setPayments] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today,
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    let refArr = [];
    let totSalesArr = [];
    let percentArr = [];
    let arrMixed = []

    // Payments
    const fetchPayments = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })

            let result = req.data.customerpays.filter((item, i) => {
                let dateTrim = moment(item.date).utc().format('YYYY-MM-DD')
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    return item
                } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                    return item
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                    return item
                }
            })

            result != "" ? result.map(item => {
                refArr.push(item.referenceno);
                totSalesArr.push(+item.totalamount);
                percentArr.push(((Number(item.netpaidamount) / Number(item.totalamount)) * 100).toFixed(2))

                arrMixed = refArr?.map(function (data, i) {
                    return { referenceno: data, totalsales: totSalesArr[i], percentage: percentArr[i] };
                });

                const finalresult = [...arrMixed.reduce((r, o) => {
                    const key = o.referenceno;
                    const items = r.get(key) || Object.assign({}, o, {
                        billno: "",
                        totalsales: 0,
                        percentage: ""
                    });
                    items.billno = o.billno
                    items.totalsales += o.totalsales
                    items.percentage += o.percentage + " %"

                    return r.set(key, items);
                }, new Map).values()];

                setPayments(finalresult);
            }) : setPayments([])

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Reference Comparison'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = payments.map((t, i) => ({
            'Reference No': t.referenceno, 'Sales Amount': t.totalsales, 'Percentage': t.percentage
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | REFERENCE COMPARISON',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Referencecomparison' })
        doc.save('Reference Comparison.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [payments]);

    return (
        <Box >
            <Headtitle title={'Reference Comparison'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Reference Comparison</Typography>
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={4} md={4}>
                            <Grid container>
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{ marginTop: 1 }}>From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, startdate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Grid container>
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, enddate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={fetchPayments} >Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvreferencecomparison && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelreferencecomparison && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printreferencecomparison && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfreferencecomparison && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="Referencecomparison" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Reference</StyledTableCell>
                                    <StyledTableCell>Sales Amount</StyledTableCell>
                                    <StyledTableCell>Percentage</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {payments.length > 0 && (
                                    payments?.map((row, index) => (
                                        <StyledTableRow>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalsales}</StyledTableCell>
                                            <StyledTableCell align="left">{row.percentage}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls}>
                <Box>
                    <Typography variant='h5' >Reference Comparison Print</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="Referencecomparison" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Reference</StyledTableCell>
                                        <StyledTableCell>Sales Amount</StyledTableCell>
                                        <StyledTableCell>Percentage</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {payments.length > 0 && (
                                        payments?.map((row, index) => (
                                            <StyledTableRow>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{row.totalsales}</StyledTableCell>
                                                <StyledTableCell align="left">{row.percentage}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Referencecomparison() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <ReferencecomparisonList /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Referencecomparison;