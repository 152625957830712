import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import $ from 'jquery';
import { Link, } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../Export';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import axios from 'axios';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Headtitle from '../../../components/header/Headtitle';

const Quotationlisttable = () => {

    const [quotations, setQuotations] = useState();
    const [deleteqot, setDeleteqot] = useState({});
    const [quotData, setQuotData] = useState([]);
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => {  setIsDeleteOpen(true);  };
    const handleClose = () => {  setIsDeleteOpen(false);  };

    const fetchQuotation = async () => {
        try {
            let quotelist = await axios.get(SERVICE.ESTIMATE_QUOTATIONS, {

                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setQuotations(quotelist.data.quotations);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //set function to get particular row
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.ESTIMATE_QUOTATION_SINGLE}/${id}`,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDeleteqot(res.data.squotation);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Alert delete popup
    let QUTID = deleteqot._id;
    const deleteQuot = async () => {
        try {
            let res = await axios.delete(`${SERVICE.ESTIMATE_QUOTATION_SINGLE}/${QUTID}`,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Export Excel
    const fileName = 'Quotations'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        let response = await axios.get(SERVICE.ESTIMATE_QUOTATIONS, {

            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            }
        });
        var data = response.data.quotations.map(t => {
            delete t._id
            delete t.__v
            delete t.createdAt
            return t
        });
        setQuotData(data);
    }

    useEffect(() => {
        getexcelDatas()
    }, [quotations])

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "  HIPOS | QUOTATION",
        pageStyle: "print",
    });

    // PDF
    const downloadpdf = () => {
        const doc = new jsPDF();
        autoTable(doc, { html: "#quotationstable" });
        doc.save("Quotations.pdf");
    };

    useEffect(
        () => {
            fetchQuotation();
        }, [deleteQuot]
    );

    //  JQUERY
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box>
            <Headtitle title={'Quotation Estimate List'} />
            <Typography sx={userStyle.HeaderText}>Quotations</Typography>
            {/* Table */}
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].aestimatequotation && (
                            <>
                                <Link to="/estimate/estimate/create" style={{ textDecoration: 'none', color: 'white' }}> <Button sx={userStyle.buttonadd} >ADD</Button></Link>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].csvestimatequotation && (
                            <>
                                <ExportCSV csvData={quotData} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelestimatequotation && (
                            <>
                                <ExportXL csvData={quotData} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printestimatequotation && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfestimatequotation && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Box>
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="QuotationTable" ref={tableRef}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="left">Action</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell align="left">Quotation No.</StyledTableCell>
                                    <StyledTableCell align="left">Customer Name</StyledTableCell>
                                    <StyledTableCell align="left">Contact Number</StyledTableCell>
                                    <StyledTableCell align="left">Location</StyledTableCell>
                                    <StyledTableCell align="left">Total Items</StyledTableCell>
                                    <StyledTableCell align="left">Added By</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {quotations &&
                                    (quotations.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell >
                                                <Grid sx={{ display: "flex" }}>
                                                    {isUserRoleCompare[0].eestimatequotation && (
                                                        <>
                                                            <Link to={`/estimate/quotation/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                        </>
                                                    )}
                                                    {isUserRoleCompare[0].destimatequotation && (
                                                        <>
                                                            <Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>
                                                        </>
                                                    )}
                                                    {isUserRoleCompare[0].vestimatequotation && (
                                                        <>
                                                            <Link to={`/estimate/quotation/view/${row._id}`}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }} ><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>                                                    </>
                                                    )}
                                                </Grid>

                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                            <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.address}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                            <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>

                                        </StyledTableRow>
                                    )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <>
                <Box>
                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"

                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined">Cancel</Button>
                            <Button autoFocus variant="contained" color='error' onClick={(e) => deleteQuot(QUTID)}> OK </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>
            {/* print layut */}
            <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" id="quotationstable" ref={componentRef}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell align="left">Draft No.</StyledTableCell>
                            <StyledTableCell align="left">Customer Name</StyledTableCell>
                            <StyledTableCell align="left">Contact Number</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Total Items</StyledTableCell>
                            <StyledTableCell align="left">Added By</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {quotations && (
                            quotations.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">{row.date == "" ? "" : moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                    <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                    <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                    <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.address}</StyledTableCell>
                                    <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                    <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>

    );
}
const Quotationlist = () => {
    return (
        <>
            <Box >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Quotationlisttable /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Quotationlist;