import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { userStyle, colourStyles } from '../../PageStyle';
import axios from 'axios';
import { Box, Typography, FormControl, Grid, Paper, InputLabel, Table, TableBody, TableHead, TableContainer, Button, DialogActions, DialogContent, Dialog, } from '@mui/material';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import Selects from 'react-select';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { AiOutlineClose } from "react-icons/ai";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AuthContext } from '../../../context/Appcontext';




function EditSupplierProductlinkall() {

  const { auth } = useContext(AuthContext)
  const [supplierProd, setSupplierProd] = useState([])
  const [tableData, setTableData] = useState([])
  const [supplist, setSuppList] = useState([])
  const [prodname, setProdName] = useState([])
  const [showAlert, setShowAlert] = useState();
  const id = useParams().id
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const handleClickOpen = () => { setIsErrorOpen(true); };
  const handleClose = () => { setIsErrorOpen(false); };
  const [addSuppPoduct, setAddSuppPoduct] = useState({
    supplier: "", itemname: "",
  })
  const backPage = useNavigate();

  const productInputs = {
    productid: "", productname: "", category: "", manufacture: "", taxtareval: "", quantity: ""
  }

  const fetchHandlerList = async () => {
    try {
      let response = await axios.get(`${SERVICE.SUPPLIER_PRODUCT_LINK_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      setSuppList(response.data.ssupplierproduct);
      setTableData(response.data.ssupplierproduct.supplierproductlinks)
      setAddSuppPoduct(response.data.ssupplierproduct);

    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  useEffect(() => {
    fetchHandlerList()
  }, [])


  // Supplier
  const fetchSupplier = async () => {
    try {
      let supplier = await axios.get(SERVICE.SUPPLIER, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setSupplierProd(
        supplier?.data?.suppliers?.map((d) => ({
          ...d,
          label: d.suppliername,
          value: d.suppliername
        }))
      )
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // product code fetching
  const fetchProductName = async () => {
    try {
      let prodcode = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setProdName(
        prodcode?.data?.products?.map((d) => ({
          ...d,
          label: d.prodname,
          value: d.prodname
        }))
      )
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //event for tabale data fetching
  const fetchEvent = (e) => {
    let isAlreadyAdded = false;
    let addQuantity = tableData.map((item) => {
      if (e.prodcode == item.productid) {
        isAlreadyAdded = true
        setShowAlert("This  product already added!")
        handleClickOpen();
        return { ...item }
      } else {
        return item
      }
    })
    if (isAlreadyAdded) {
      setTableData(addQuantity)
    } else {
      setTableData((tableData) => {
        return [...tableData, {
          ...productInputs,
          productid: e.prodcode,
          productname: e.prodname,
          category: e.category,
          manufacture: e.manufacture,
          taxtareval: e.applicabletax,
          quantity: e.minquantity,
        }]
      })
    }
  }

  //functio for delete columns
  const deleteRow = (i, e) => {
    setTableData(tableData.filter((e, item) => item !== i));
    tableData.splice(i, 1);

  }

  //send request for supplierproducts link
  const sendSupplierProducts = async () => {
    console.log(productInputs)
    try {
      let SUPPLIERPROD_REQ = await axios.put(`${SERVICE.SUPPLIER_PRODUCT_LINK_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        supplier: String(addSuppPoduct.supplier),
        itemname: String(addSuppPoduct.itemname),
        supplierproductlinks: [...tableData,],
      });
      setAddSuppPoduct(SUPPLIERPROD_REQ)
      toast.success(SUPPLIERPROD_REQ.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      backPage('/purchase/SupplierProduct/ListSupplierProduct')

    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages)
    }
  };

  useEffect(() => {
    fetchSupplier();
    fetchProductName();
  }, [])

  const handleSubmit = () => {
    if (addSuppPoduct.supplier == "") {
      setShowAlert("please select supplier name")
      handleClickOpen()
    } else if (addSuppPoduct.itemname == "") {
      setShowAlert("please select product name")
      handleClickOpen()
    } else {
      sendSupplierProducts()
    }
  }


  return (
    <Box>
              <Typography sx={userStyle.HeaderText}>Edit Supplier & Product Link <Typography sx={userStyle.SubHeaderText}></Typography></Typography>

      <Box sx={userStyle.container}>
        <Headtitle title={'Edit supplierproductslinks'} />
        <br /><br />
        <Grid container lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} spacing={1}>
          <Grid container lg={12} md={12} >
            <Grid item lg={2} md={2}>
              <Typography sx={{ marginTop: 1, marginLeft: "1em" }}><b>Supplier:</b><b style={{ color: "red" }}> *</b></Typography>
            </Grid>
            <Grid item lg={4} md={2} >
              <FormControl size="small" fullWidth style={{ marginLeft: "0.8em" }} >
                <InputLabel htmlFor="component-outlined" ></InputLabel>
                <Selects
                  id="component-outlined"
                  styles={colourStyles}
                  options={supplierProd}
                  value={{ value: addSuppPoduct.supplier, label: addSuppPoduct.supplier }}
                  onChange={(e)=>{setAddSuppPoduct({ ...addSuppPoduct, supplier: e.value })}}
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={8}>
            </Grid>
          </Grid>
        </Grid><br /><br />
        <Grid container spacing={0.2} >
          <Grid item lg={2} md={4}>
            <Typography sx={{ marginTop: "1em", marginLeft: "0.6em" }}><b>Item Name:<b style={{ color: "red" }}> *</b></b></Typography>
          </Grid>
          <Grid item lg={4} md={4}>
            <FormControl size="small" fullWidth >
              <InputLabel htmlFor="component-outlined" ></InputLabel>
              <Selects
                options={prodname}
                styles={colourStyles}
                value={{ value: addSuppPoduct.itemname, label: addSuppPoduct.itemname }}
                onChange={(e) => { fetchEvent(e); setAddSuppPoduct({ ...addSuppPoduct, itemname: e.value }) }}

              />
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4}>
          </Grid>
        </Grid><br /><br />
        <TableContainer component={Paper} >
          <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Item Code</StyledTableCell>
                <StyledTableCell >Item Name </StyledTableCell>
                <StyledTableCell >Category</StyledTableCell>
                <StyledTableCell >Manufacture</StyledTableCell>
                <StyledTableCell >Tax%</StyledTableCell>
                <StyledTableCell >Re-Order Level</StyledTableCell>
                <StyledTableCell ><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody align="left">
              {tableData.length > 0 &&
                tableData?.map((data, i) => {
                  return (
                    <>
                      <StyledTableRow key={i}>
                        <StyledTableCell >{data.productid}</StyledTableCell>
                        <StyledTableCell >{data.productname}</StyledTableCell>
                        <StyledTableCell >{data.category}</StyledTableCell>
                        <StyledTableCell >{data.manufacture}</StyledTableCell>
                        <StyledTableCell >{data.taxtareval}</StyledTableCell>
                        <StyledTableCell >{data.quantity}</StyledTableCell>
                        <StyledTableCell ><AiOutlineClose onClick={(e) => { deleteRow(i, e); }} style={{ color: "red",cursor:"pointer" }} /></StyledTableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer><br /><br />
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            <Link to="/purchase/SupplierProduct/ListSupplierProduct"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
            <Button sx={userStyle.buttonadd} onClick={handleSubmit} >SAVE</Button>
          </Grid>
        </Grid>
        {/* Error pop up */}
        <Box>
          <Dialog
            open={isErrorOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
              <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
              <Typography variant="h6" >{showAlert}</Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
}

function SupplierProductEdit() {
  return (
    <>
      <Box >
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <EditSupplierProductlinkall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SupplierProductEdit;