import React, { useState, useContext, useEffect } from 'react';
import { Button, TextField, Select, MenuItem, TextareaAutosize, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Grid, InputLabel, FormControl, Box, OutlinedInput, } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { useNavigate, Link } from 'react-router-dom';
import { userStyle, colourStyles } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from "../../../services/Baseservice";
import Selects from "react-select";

function BankbookRegisterlist() {

    //  Add Text Field
    const [bankBook, setBankBook] = useState({
        referenceno: "", date: "", accountno: "", accountgroup: "", amount: "", accounttype: "", paymentmethod: "Cash", narration: "",
    });
    const [setngs, setSetngs] = useState();

    const [groupCreate, setGroupCreate] = useState({
        groupautoid: "", groupname: "",
    });
    const [grpcreation, setGroupCreation] = useState([])
    // Access
    const { auth } = useContext(AuthContext);
    const [newdata, setNewdata] = useState([]);

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };
    const [bankdetail, setBankDetails] = useState([]);
    const [options, setOptions] = useState([])
    const [optionData, setOptionData] = useState([])
    const [accoption, setAccoptions] = useState([])

    //  Modal
    const [modalOpen, setModalOpen] = useState(false)
    const handleModalOpen = () => {
        setModalOpen(true);
    };
    const handleModalClose = () => {
        setModalOpen(false);
        setShowAlert("");
        setGroupCreate({ ...groupCreate, groupname: "" });
    };

    //et settings
    const fetchSettings = async () => {
        try {
            var response = await axios.get(`${SERVICE.UPDATE_SETTINGS}`,
                {
                    headers: {
                        'Authorization': `Bearer ${auth.APIToken}`
                    },
                });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages)
        }
    }

    // get all products
    const fetchgroup = async () => {
        try {
            let res_group = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.groupmasters?.map((d) => {
                return d.groupname
            })

            setNewdata(masters_data);


        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    let newarray = [...new Set(newdata)];

    // Categorys
    const fetchOptions = async () => {
        try {

            setOptions(
                newarray.map((d) => (
                    {
                        ...d,
                        label: d,
                        value: d,
                    }
                ))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchOptions()
    }, [fetchOptions])

    //Bank Details
    const fetchBankDetails = async () => {
        try {
            let req = await axios.get(SERVICE.BANKBOOK, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBankDetails(req.data.bankbooks);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchBankDetails()
        fetchgroup()
    }, [newdata])

    let newval = setngs ? setngs.banksku + "0001" : "BK0001";
    let newvals = setngs ? setngs.groupsku + "0001" : "GR0001";

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    // get all products
    const fetchbank = async () => {
        try {
            let res_bank = await axios.get(SERVICE.BANKMASTER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAccoptions(
                res_bank.data.bankmasters.map((d) => (
                    {
                        ...d,
                        label: d.accno,
                        value: d.accno
                    }
                ))
            )
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // GROUP_CREATION
    const fetchAccounts = async () => {
        try {
            let req = await axios.get(SERVICE.GROUP_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setGroupCreation(req.data.groupmasters);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };


    useEffect(() => {
        fetchAccounts()
    }, [grpcreation])

    const backLPage = useNavigate();

    //   New group creation
    const addGroup = async () => {
        try {
            let req = await axios.post(SERVICE.GROUP_CREATION_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                groupautoid: String(newvals),
                groupname: String(groupCreate.groupname),
            });
            setGroupCreation(req.data);
            if (toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            })) {
                setGroupCreate({ groupname: "" })
            }
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
        }
    };

    const fetchAccount = async (e) => {

        try {
            let res_group = await axios.get(SERVICE.ACCOUNT_CREATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let masters_data = res_group?.data?.accountmasters?.filter((data) => {
                if (e.label == data.accountgroup) {
                    return data
                }
            })
            setOptionData(
                masters_data.map((d) => (
                    {
                        ...d,
                        label: d.accountname,
                        value: d.accountname
                    }
                ))
            );

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(() => {
        fetchSettings();
        fetchbank();

    }, [])

    //New customer add page
    const addBankBook = async () => {
        try {
            let req = await axios.post(SERVICE.BANKBOOK_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newval),
                date: String(bankBook.date),
                accountno: String(bankBook.accountno),
                accountgroup: String(bankBook.accountgroup),
                amount: Number(bankBook.amount),
                paymentmethod: String(bankBook.paymentmethod),
                narration: String(bankBook.narration),
                accounttype: String(bankBook.accounttype),
            },
            );
            setBankBook(req.data);
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            })
            //redirecting list page 
            backLPage('/bank/book/report')
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };

    //New bank book add page
    const addBankBookAnother = async () => {
        try {
            let req = await axios.post(SERVICE.BANKBOOK_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newval),
                date: String(bankBook.date),
                accountno: String(bankBook.accountno),
                accountgroup: String(bankBook.accountgroup),
                amount: Number(bankBook.amount),
                paymentmethod: String(bankBook.paymentmethod),
                narration: String(bankBook.narration),
                accounttype: String(bankBook.accounttype),
            });
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setBankBook({ referenceno: "", date: "", accountno: "", accountgroup: "", amount: "", paymentmethod: "Cash", narration: "", accounttype: "" });
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };

    //submit button 
    const handleSubmitModal = (e) => {
        e.preventDefault();
        if (groupCreate.groupname == "") {
            setShowAlert("Please enter group name!");
        } else {
            addGroup();
            handleModalClose();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (bankBook.accountno == "" || bankBook.accountno == undefined) {
            setShowAlert("Please select account number!");
            handleClickOpen();
        } else if (bankBook.amount == "") {
            setShowAlert("Please enter Amount!");
            handleClickOpen();
        }
        else {
            addBankBook();
        }
    }

    const handleSubmitAnother = (e) => {
        e.preventDefault();
        if (bankBook.accountno == "") {
            setShowAlert("Please enter account number!");
            handleClickOpen();
        } else if (bankBook.amount == "") {
            setShowAlert("Please enter Amount!");
            handleClickOpen();
        }
        else {
            addBankBookAnother();
        }
    }

    return (
        <Box>
            <Headtitle title={'Bank Book Register'} />
            <Typography variant='body2' sx={userStyle.HeaderText}>Bank Book Register</Typography>
            <Box sx={userStyle.container}>
                <Grid container spacing={3}>
                    <Grid item md={2} sm={3} xs={12}>
                        {bankdetail.length > 0 && (
                            bankdetail?.map(
                                () => {
                                    let strings = setngs ? setngs.banksku : "BK";
                                    let refNo = bankdetail[bankdetail.length - 1].referenceno;
                                    let digits = (bankdetail.length + 1).toString();
                                    const stringLength = refNo.length;
                                    let lastChar = refNo.charAt(stringLength - 1);
                                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                                    let lastBeforeChar = refNo.slice(-2);
                                    let lastThreeChar = refNo.slice(-3);
                                    let lastDigit = refNo.slice(-4);
                                    let refNOINC = parseInt(lastChar) + 1
                                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                                    let refLstThree = parseInt(lastThreeChar) + 1;
                                    let refLstDigit = parseInt(lastDigit) + 1;
                                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                        refNOINC = ("000" + refNOINC);
                                        newval = strings + refNOINC;
                                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                        refNOINC = ("00" + refLstTwo);
                                        newval = strings + refNOINC;
                                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                                        refNOINC = ("0" + refLstThree);
                                        newval = strings + refNOINC;
                                    } else {
                                        refNOINC = (refLstDigit);
                                        newval = strings + refNOINC;
                                    }
                                }))}
                        <Typography variant='h6'>Reference No</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}  >
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={newval}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Date</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                        <FormControl size="small" fullWidth>
                            <TextField
                                id="component-outlined"
                                value={bankBook.date}
                                onChange={(e) => { setBankBook({ ...bankBook, date: e.target.value }) }}
                                type="date"
                                size="small"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Bank AC /No <b style={{ color: 'red' }}> *</b></Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <Selects
                                styles={colourStyles}
                                options={accoption}
                                onChange={(e) => { setBankBook({ ...bankBook, accountno: e.value }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Account Group</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    styles={colourStyles}
                                    options={options}
                                    onChange={(e) => { fetchAccount(e); setBankBook({ ...bankBook, accountgroup: e.value }) }}
                                />
                            </FormControl>
                            <Grid sx={userStyle.spanText} onClick={handleModalOpen}><b>+</b></Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                    </Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Account Type</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <Selects
                                styles={colourStyles}
                                options={optionData}
                                onChange={(e) => { setBankBook({ ...bankBook, accounttype: e.value }) }} />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Amount <b style={{ color: "red" }}> *</b></Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}  >
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={bankBook.amount}
                                    onChange={(e) => { setBankBook({ ...bankBook, amount: e.target.value }) }}
                                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Paymethod</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <Select

                                id="demo-simple-select"
                                value={bankBook.paymentmethod}
                                onChange={(e) => { setBankBook({ ...bankBook, paymentmethod: e.target.value }) }}
                            >
                                <MenuItem value="Cash">Cash</MenuItem>
                                <MenuItem value="Card">Card</MenuItem>
                                <MenuItem value="UPI">UPI</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Narration</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth >
                            <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                value={bankBook.narration}
                                onChange={(e) => { setBankBook({ ...bankBook, narration: e.target.value }) }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}></Grid>
                    <br />
                    <Grid container sx={userStyle.gridcontainer}>
                        <Grid >
                            <Link to="/bank/bank/report"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                            {/* <Button sx={userStyle.buttonadd} onClick={handleSubmitAnother}>SAVE AND ANOTHER</Button> */}
                            <Button sx={userStyle.buttonadd} onClick={handleSubmit}>SAVE</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>

            {/* Popup */}
            <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">Group Creation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p style={{ color: "red" }}>{showAlert}</p>
                        <Box sx={userStyle.container}>
                            <Grid container spacing={1}>
                                <Grid item lg={3} md={3}>
                                    {grpcreation.length > 0 && (
                                        grpcreation.map(
                                            () => {
                                                let strings = setngs ? setngs.groupsku : "GR";
                                                let refNo = grpcreation[grpcreation.length - 1].groupautoid;
                                                let digits = (grpcreation.length + 1).toString();
                                                const stringLength = refNo.length;
                                                let lastChar = refNo.charAt(stringLength - 1);
                                                let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                                let getlastThreeChar = refNo.charAt(stringLength - 3);
                                                let lastBeforeChar = refNo.slice(-2);
                                                let lastThreeChar = refNo.slice(-3);
                                                let lastDigit = refNo.slice(-4);
                                                let refNOINC = parseInt(lastChar) + 1
                                                let refLstTwo = parseInt(lastBeforeChar) + 1;
                                                let refLstThree = parseInt(lastThreeChar) + 1;
                                                let refLstDigit = parseInt(lastDigit) + 1;
                                                if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                                    refNOINC = ("000" + refNOINC);
                                                    newvals = strings + refNOINC;
                                                } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                                    refNOINC = ("00" + refLstTwo);
                                                    newvals = strings + refNOINC;
                                                } else if (digits.length < 4 && getlastThreeChar > 0) {
                                                    refNOINC = ("0" + refLstThree);
                                                    newvals = strings + refNOINC;
                                                } else {
                                                    refNOINC = (refLstDigit);
                                                    newvals = strings + refNOINC;
                                                }
                                            }))}
                                    <Typography>Reference No</Typography>
                                </Grid>
                                <Grid item lg={9} md={9}>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={newvals}
                                        />
                                    </FormControl>
                                </Grid><br />
                                <Grid item lg={3} md={3}>
                                    <Typography>Account Group <b style={{ color: 'red' }}>*</b></Typography>
                                </Grid>
                                <Grid item lg={9} md={9}>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={groupCreate.groupname}
                                            onChange={(e) => { setGroupCreate({ ...groupCreate, groupname: e.target.value, groupautoid: newvals }); }}
                                        />
                                    </FormControl>
                                </Grid><br />
                            </Grid>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" disableRipple onClick={handleSubmitModal} type="submit">SAVE</Button>
                    <Button variant='contained' color='error' onClick={handleModalClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function BankbookRegister() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <BankbookRegisterlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default BankbookRegister;