import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button, Grid, Typography, Box, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { FaPrint, FaFilePdf } from "react-icons/fa";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link } from 'react-router-dom';
// import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import Salescreate from './Create';
import Salesedit from './Edit';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { ExportXL, ExportCSV } from '../../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';

function Saleslisttable() {

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);


  const axiosRequest = async () => {
    try {
      let response = await axios.get('http://www.boredapi.com/api/activity');

    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  useEffect(() => {
    axiosRequest();
  }, []
  );

  // Export Excel
  const fileName = 'Roles';

  // PDF
  const downloadpdf = () => {
    // const newData = roles.map(row => {
    //   delete row._id
    //   return row
    // })
    const doc = new jsPDF()
    autoTable(doc, { html: '#salesagenttable' })

    // doc.text("Roles details", 20, 30)
    // doc.autoTable({
    //     theme: "grid",
    //     body: roles,
    // })
    doc.save('Sales.pdf')
  }

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()
  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        console.log(tableRef.current)
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 25, 50, 100, 200, 500, -1],
            [10, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  return (
    <Box>
      <Typography sx={userStyle.HeaderText}>Sales Commission Agents</Typography>
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography  ></Typography>
          </Grid>
          <Grid item xs={4}>
            <Salescreate />
          </Grid>
        </Grid>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid>
            <ExportCSV fileName={fileName} />
            <ExportXL fileName={fileName} />
            {isUserRoleCompare[0].auser && (<Link to={`/user/sales/print`} style={{ textDecoration: 'none', color: 'white', '&:hover': { color: 'red', backgroundColor: 'yellow' } }}>
              <Button sx={userStyle.buttongrp} ><FaPrint />&ensp;Print&ensp;</Button>
            </Link>)}
            {isUserRoleCompare[0].auser && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid>
        <Box>
          <TableContainer component={Paper} sx={userStyle.tablecontainer}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="salesagenttable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell >Email</StyledTableCell>
                  <StyledTableCell >Contact Number</StyledTableCell>
                  <StyledTableCell >Address</StyledTableCell>
                  <StyledTableCell >Sales Commission Percentage&ensp;(%)</StyledTableCell>
                  <StyledTableCell >Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row"></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell >
                    <Grid SX={{ display: 'flex' }}>
                      {isUserRoleCompare[0].auser && (<Salesedit />)}
                      {isUserRoleCompare[0].auser && (<Button sx={userStyle.buttondelete}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>)}
                    </Grid>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

function Saleslist() {
  return (
    <Box sx={{ display: 'flex', }} >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Saleslisttable /><br /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Saleslist;