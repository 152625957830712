import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormGroup, FormControl, FormControlLabel, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import { userStyle,colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import moment from "moment";
import { toast } from 'react-toastify';
import $ from 'jquery';
import Selects from 'react-select';
import Checkbox from '@mui/material/Checkbox';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from "../../services/Baseservice";
import { AuthContext, UserRoleAccessContext } from '../../context/Appcontext';

function Batchwisestockreportlist() {

  //  Access
  const { auth } = useContext(AuthContext);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  const [isSelect, setIsSelect] = useState(false)
  const [categories, setCategories] = useState();
  const [isCategory, setIsCategory] = useState({categoryvalue:""});
  const [products, setProducts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [isProducts, setIsProducts] = useState([]);
  const [exceldata, setExceldata] = useState([]);

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  // Categorys
  const fetchCategory = async () => {
    try {
        let response = await axios.get(SERVICE.CATEGORIES,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
              },
        });
        setCategories(
            response.data.categories?.map((d) => ({
                ...d,
                label: d.categoryname,
                value: d.categoryname,
            }))
        );
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
  };

  //fetch all products
  const fetchProducts = async () => {
    try {
        let res_products = await axios.get(SERVICE.PRODUCT,{
          headers: {
              'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        setProducts(res_products.data.products);
        await fetchPurchases(res_products.data.products);
        $(document).ready(function () {
          $.fn.dataTable.ext.errMode = 'none';
          setTimeout(function () {
            $(tableRef.current).DataTable({
              language: { search: '', searchPlaceholder: "Search..." },
              lengthMenu: [
                [10, 1, 25, 50, 100, 200, 500, -1],
                [10, 1, 25, 50, 100, 200, 500, 'All'],
              ],
            });
          }, 1000);
        });
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
  };

  //fetch all purchase
  const fetchPurchases = async (prodata) => {
    try {
        let res_purchase = await axios.get(SERVICE.PURCHASEPRODUCTS,{
          headers: {
              'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        let product_currentstock = res_purchase.data.purchases.map((value, index)=>{
          let data = value;
          prodata.forEach((item, i)=>{
                if(value.sku == item.prodcode){
                  data = {...value, currentstock:item.currentstock};
                }
            })
            return data;
        }) 
        setPurchases(product_currentstock)
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
  };

  useEffect(
  ()=>{
  },[products, purchases, isProducts]
  )

  const handleFetchProduct = () =>{

      if(isSelect){
        setIsProducts(purchases);
      }else{
        let get_all_purchase = purchases.filter((item, i)=>{
          if(isCategory.categoryvalue == item.category){
            return item
          }
        })
        setIsProducts(get_all_purchase);
      }
  }

  useEffect(
    ()=>{
      fetchCategory();
      fetchProducts();
    },[]
  )

  // Export Excel
  const fileName = "Batch Wise Stock Report";

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = isProducts.map(t => ({
      "Item Code": t.sku, "Item Name": t.prodname, "Batch No": t.batch, Expiry: t.pruchaseincludetax ? t.pruchaseincludetax : t.purchaseexcludetax,
      "Stock In Hand(Pieces)": t.currentstock, "Purchase Rate": t.enteramt, "Sales Rate": t.sellingpriceunitcost, "MRP Rate": t.sellingpriceunitcost, 
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()
  }, [isProducts])

  useEffect(
    ()=>{
      handleFetchProduct();
    },[isSelect]
  )

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#batchwisestock" });
    doc.save("Batch Wise Stock Report.pdf");
  };

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "HIPOS | BATCH WISE STOCK REPORT",
    pageStyle: "print",
  });

  return (

      <Box>
        <Headtitle title={'Batch Wise Stock Report'} />
        <Typography sx={userStyle.HeaderText}>Batch Wise Stock Report</Typography>
        <Box sx={userStyle.filtercontent} >
          <Grid container spacing={2}>
            <Grid item lg={1} md={1} sm={4} xs={6}>
              <Typography sx={{ marginTop: 1 }}>Category</Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <FormControl size="small" fullWidth >
                <Selects
                  id="component-outlined"
                  styles={colourStyles}
                  sx={userStyle.textInput}
                  options={categories}
                  onChange={(e) => {setIsCategory({...isCategory, categoryvalue:e.categoryname}); setIsSelect(false)}}
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <FormGroup>
                <FormControlLabel  control={<Checkbox checked={isSelect} onClick={(e)=> {setIsSelect(!isSelect)} }  />} label="Select All" />
              </FormGroup>
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <Button variant='contained' color='secondary' onClick={handleFetchProduct}>Generate</Button>
            </Grid>
          </Grid>
        </Box><br />
        {/* content start */}
        <Box sx={userStyle.container}>
          { /* ******************************************************EXPORT Buttons****************************************************** */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvbatchwisestockreport && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )} 
              {isUserRoleCompare[0].excelbatchwisestockreport && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
               )}
              {isUserRoleCompare[0].printbatchwisestockreport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}> &ensp; <FaPrint />  &ensp;Print&ensp;    </Button>
                </>
              )}
              {isUserRoleCompare[0].pdfbatchwisestockreport && ( 
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
               )} 
            </Grid>
          </Grid><br />
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700 }} aria-label="customized table" id="batchwisestock" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell>Item Name</StyledTableCell>
                    <StyledTableCell >Batch No</StyledTableCell>
                    <StyledTableCell >Expiry</StyledTableCell>
                    <StyledTableCell >Stock In Hand(Pieces)</StyledTableCell>
                    <StyledTableCell >Purchase Rate</StyledTableCell>
                    <StyledTableCell >Sales Rate</StyledTableCell>
                    <StyledTableCell >MRP Rate</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {isProducts.length > 0 ? (
                    isProducts.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.sku}</StyledTableCell>
                          <StyledTableCell>{row.prodname}</StyledTableCell>
                          <StyledTableCell>{row.batch}</StyledTableCell>
                          <StyledTableCell>{moment(row.expirydate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                          <StyledTableCell>{row.currentstock}</StyledTableCell>
                          <StyledTableCell>{row.enteramt}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={8}>No data recored found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {/* Print Layout */}
        <TableContainer component={Paper} sx={userStyle.printcls}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" id="batchwisestock" ref={componentRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell>Item Name</StyledTableCell>
                    <StyledTableCell >Batch No</StyledTableCell>
                    <StyledTableCell >Expiry</StyledTableCell>
                    <StyledTableCell >Stock In Hand(Pieces)</StyledTableCell>
                    <StyledTableCell >Purchase Rate</StyledTableCell>
                    <StyledTableCell >Sales Rate</StyledTableCell>
                    <StyledTableCell >MRP Rate</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {isProducts.length > 0 ? (
                    isProducts.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.sku}</StyledTableCell>
                          <StyledTableCell>{row.prodname}</StyledTableCell>
                          <StyledTableCell>{row.batch}</StyledTableCell>
                          <StyledTableCell>{moment(row.expirydate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                          <StyledTableCell>{row.currentstock}</StyledTableCell>
                          <StyledTableCell>{row.enteramt}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                          <StyledTableCell>{row.sellingpriceunitcost}</StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={8}>No data recored found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
        </TableContainer>
      </Box>
  );
}


function Batchwisestockreport() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Batchwisestockreportlist /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Batchwisestockreport;