import React, { useState, useContext } from 'react';
import { Box, Grid, FormControl, InputLabel, OutlinedInput,Button } from '@mui/material';
import { userStyle } from '../../PageStyle';
import { FaPlus } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

export default function Countercreate({setngs, setSetngs, counters, setCounters}) {
  

  function addCounter(){
    let uniqueid = Math.random().toFixed(5);
    setCounters([...counters, {counterid:"",countername:"",_id: uniqueid, newAdded:true}])
  }

  function multiCounterInputs(referenceId,reference,inputvalue) {
    if (reference == "counterName") {
     let counterNameInput = counters.map((value,index)=> {
      if (referenceId == value._id) {
        return {...value,countername:inputvalue}
      }
      else {
        return value;
      }
     });
     return setCounters(counterNameInput);
    }
    else if (reference == "counterCode") {
      let counterCodeInput = counters.map((value,index)=> {
        if (referenceId == value._id) {
          return {...value,counterid:inputvalue}
        }
        else {
          return value;
        }
       });
      return setCounters(counterCodeInput);
    }
  }

 

  const deleteCounter =(referenceId)=>{
    let counterdata = counters.filter((value,index)=> {
      if (referenceId != value._id) {
        return value;
      }
    });
    setCounters(counterdata);
  }

    return (
        <Box sx={userStyle.container}>
          {counters.length >= 0 && ( 
              <ul type="none" className="todoLlistUl" style={{paddingLeft : '0px',marginLeft: '0px'}}>
            {counters.map((item, index) => {
              return(
              <li key={index}>
                <br />
                  <Grid container columnSpacing={1}>
                    <Grid item sm={12} xs={12} md={5} lg={5}>
                    <InputLabel htmlFor="component-outlined">Counter No</InputLabel>
                      <FormControl size="small" fullWidth>
                        <OutlinedInput
                          id="component-outlined"
                          value={item.countername} 
                          onChange={(e) => multiCounterInputs(item._id,"counterName",e.target.value)}
                          type="text"
                          name="countername"
                          placeholder="Counter No"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} xs={12} md={5} lg={5}>
                    <InputLabel htmlFor="component-outlined">Counter ID</InputLabel>
                      <FormControl size="small" fullWidth>
                        <OutlinedInput
                          id="component-outlined"
                          value={item.counterid} 
                          onChange={(e) => multiCounterInputs(item._id,"counterCode",e.target.value)}
                          type="text"
                          name="counterid"
                          placeholder="Counter ID"
                        />
                      </FormControl>
                    </Grid>  
                    <Grid item sm={1} xs={1} md={2} lg={2} sx={{display:'flex'}}>
                      <Button variant="contained" color="success" type="button" onClick={() => addCounter()} sx={userStyle.categoryadd}><FaPlus /></Button>&nbsp;
                      <Button variant="contained" color="error" type="button" onClick={(e) => deleteCounter(item._id)} sx={userStyle.categoryadd}><AiOutlineClose /></Button>
                    </Grid>              
            </Grid>
            </li>
              ) 
            })}
          </ul>
            )
          } 
        </Box>
    );
}