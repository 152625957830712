import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import moment from "moment";
import $ from 'jquery';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

function Supplierpaymentsreportlist() {

    const [purchases, setPurchases] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const [dateValue, setDateValue] = useState();
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //  Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;

    // Datefilter
    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today
    })

    // Daysdiff
    let dateTrim;

    // jquery
    $.DataTable = require('datatables.net')
    const tableRefsupppay = useRef()

    // Purchases
    const fetchPurchases = async () => {
        try {
            let req = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.purchases.filter((data) => {
                dateTrim = moment(data.purchasedate).utc().format('YYYY-MM-DD');
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                    return data
                }
            })

            setPurchases(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefsupppay.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchPurchases();
        }, []
    )

    // Excel
    const fileName = 'SuppliersPaymentReport'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = purchases.map(t => ({
            "Reference No": t.referenceno, "Paid Date": moment(t.paidon).utc().format('DD-MM-YYYY'), "Name": t.supplier, "Total Amount": t.nettotal, "Discounts": t.discountamt, "Net Paid Amount": t.advancebalance, "Pay": t.subcost
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | SUPPLIER PAYMENTS REPORT',
        pageStyle: 'print'
    });

    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Supplierpaymentsreport' })
        doc.save('Supplierpaymentsreport.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [purchases]
    )

    return (
        <Box >
            <Headtitle title={'Supplier Payments Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Supplier Payments Report</Typography>

            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={1} md={1}></Grid>
                        <Grid item lg={4} md={4}>
                            <Grid container  >
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{ marginTop: 1 }}>From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                            type="date"
                                            size="small"
                                            name="startdate"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Grid container >
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                            type="date"
                                            size="small"
                                            name="enddate"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={fetchPurchases}>Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvsupplierpaymentsreport && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelsupplierpaymentsreport&& (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printsupplierpaymentsreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfsupplierpaymentsreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="Supplierpaymentsreport" ref={tableRefsupppay}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Paid Date</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Total Amount</StyledTableCell>
                                    <StyledTableCell>Returns & Discounts</StyledTableCell>
                                    <StyledTableCell>Net Paid Amount</StyledTableCell>
                                    <StyledTableCell>Pay</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {purchases.length > 0 && (
                                    purchases.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(row.paidon).utc().format('DD-MM-YYYY')}</StyledTableCell >
                                            <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                            <StyledTableCell align="left">{row.nettotal}</StyledTableCell>
                                            <StyledTableCell align="left">{row.discountamt}</StyledTableCell>
                                            <StyledTableCell align="left">{row.advancebalance}</StyledTableCell>
                                            <StyledTableCell align="left">{row.paymentmethod}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Table End ****** */}
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} >
                <Box>
                    <Typography variant='h5' >Supplier Payments Report</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls} >
                            <Table aria-label="simple table" id="Supplierpaymentsreport" ref={componentRef} >
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Reference No</StyledTableCell>
                                        <StyledTableCell>Paid Date</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Total Amount</StyledTableCell>
                                        <StyledTableCell>Returns & Discounts</StyledTableCell>
                                        <StyledTableCell>Net Paid Amount</StyledTableCell>
                                        <StyledTableCell>Pay</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {purchases.length > 0 && (
                                        purchases.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{moment(row.paidon).utc().format('DD-MM-YYYY')}</StyledTableCell >
                                                <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                                <StyledTableCell align="left">{row.nettotal}</StyledTableCell>
                                                <StyledTableCell align="left">{row.discountamt}</StyledTableCell>
                                                <StyledTableCell align="left">{row.advancebalance}</StyledTableCell>
                                                <StyledTableCell align="left">{row.paymentmethod}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Supplierpaymentsreport() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Supplierpaymentsreportlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Supplierpaymentsreport;