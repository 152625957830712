import React, { useEffect, useState, useContext } from 'react';
import { userStyle, colourStyles } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, TextField, Select, MenuItem, Paper, TableCell, Dialog, DialogContent, DialogActions, TextareaAutosize, Typography, Button, Table, TableContainer, TableHead, TableBody } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FaSearch } from "react-icons/fa";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { AiOutlineClose } from "react-icons/ai";
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Selects from "react-select";
import { SERVICE } from '../../../services/Baseservice';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { AuthContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import moment from 'moment';

const Purchaseeditlist = () => {

    const [supplier, setSupplier] = useState({});
    const [suppliers, setSuppliers] = useState([]);
    const [busilocations, setBusilocations] = useState([]);
    const [taxrates, setTaxrates] = useState();
    const [productsList, setProductsList] = useState([]);
    const [alpha, setAlpha] = useState("");
    const [setngs, setSetngs] = useState();
    const [allTotalItems, setAlltotalItems] = useState([]);
    const [arraydata, getarraydata] = useState([]);
    const [getTaxValue , setTaxvalue] = useState("");
    const totalItem = { quantity: 0, freeitem: 0 };
    const { auth } = useContext(AuthContext);
    //role access
    const {isUserRoleAccess} = useContext(UserRoleAccessContext);
    let purchaseStatusPending = "Pending";
    let purchaseStatusReceived = "Received";
    let balanceamt=0;
    // produts list for add purchase data into db
    const productInputs = {
        prodname: "", sku: "", hsn: "", hsntax: 0, applicabletax:"", applicabletaxrate:0,purchasetabletax: "Exclusive", purchasetax:0, sellingpricetax: "", enteramt:"", margin: 0, purchaseexcludetax: 0,
        pruchaseincludetax:0, excalpha: "", incalpha: "", quantity: "", freeitem: "", purchasenetcost:0, distypemod: "None", disvaluemod: "", ratebydismod: "",subtax:"",invoicenumber:"",
        differenceamt: "", sellingpriceunitcost: 0,batch:"", expirydate:"", packingcharge:"",
    }
    const [allProductsList, setAllProductsList] = useState([]);
    // purchase list og data only in add purchase
    const [purchase, setPurchase] = useState({
        supplier: "", purchasestatus: "", paymentmethod: "", businesslocation: "", nettotal: 0.0, gdiscountvalue: "", granddistype: "",discountamt:""
    });

    // Error Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => {
        setIsErrorOpen(true);
    };
    const handleClose = () => {
        setIsErrorOpen(false);
    };
    
    let getTaxrateData = [];

    const id = useParams().id;

    const fetchHandlers = async () => {
        try {
            let req = await axios.get(`${SERVICE.PURCHASE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPurchase(req.data.spurchse)
            setAllProductsList(req.data.spurchse.products);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchHandlers()
    }, [id]);

    const gethsncode = async (e) => {
      
        let taxlist =await axios.get(SERVICE.TAXRATE,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
        let taxRateData = taxlist.data.taxrates.filter((data) => {
            if(data.taxname == e.applicabletax || e.hsncode) {
                    return data
                }
            });
            setTaxvalue(taxRateData)
            setTaxrates(taxRateData);
            //
            let isAlreadyAdded = false;
            let addedproductId =  allProductsList.map((item)=>{
                if(e.prodcode == item.sku){
                    isAlreadyAdded = true
                    setShowAlert("This product is already added!")
                    handleClickOpen();
                    return { ...item }
                }else {
                    return item
                    
                  }
            })

            if(isAlreadyAdded){
                setAllProductsList(addedproductId)
            }else{
            setAllProductsList((productslistdata) => {
                return [{ ...productInputs, batch:"", expirydate:"", packingcharge:"", supplier: purchase.supplier, date: purchase.purchasedate, margin: setngs ? setngs.dprofitpercent : 0,purchasetax:e.applicabletax, subtax:getTaxrateData, invoicenumber: purchase.invoiceno,
                        applicabletax: e.applicabletax, applicabletaxrate: e.applicabletax == "" ? 0 : taxRateData ? taxRateData[0].taxtotal : 0,category:e.category,molecule:e.molecules,schedule:e.schedule, manufacture:e.manufacture,hsntax: e.hsncode == "" ? 0 : taxRateData ? taxRateData[0].taxtotal : 0,
                        prodname: e.prodname, sku: e.prodcode, hsn: e.hsncode, sellingpricetax:e.selltaxtype }, ...productslistdata]
                });
            }
    }

    // Get supplier address Addressone
    const searchAdd = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.SUPPLIER_SINGLE}/${id}`,{              
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            setSupplier(res.data.ssupplier);
            setPurchase({...purchase, addressone:res.data.ssupplier.addressone + ' ' + res.data.ssupplier.addresstwo + ' ' + res.data.ssupplier.whatsapp, supplierid:res.data.ssupplier.autogenerate, supaddressone:res.data.ssupplier.addressone, addresstwo:res.data.ssupplier.addresstwo, city:res.data.ssupplier.city, gstn:res.data.ssupplier.gstn, whatsapp:res.data.ssupplier.whatsapp, creditdays:res.data.ssupplier.creditdays, contactperson:res.data.ssupplier.contactperson,dlnos:res.data.ssupplier.dlnos, activate:res.data.ssupplier.activate })
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    
    let fiveDaysInTheFuture;
    let Duesdate;
    let Duedate;

    const getDueDate = (suppliercreditdays) => {
        console.log(suppliercreditdays,'cl')
        fiveDaysInTheFuture = new Date(new Date().setDate(new Date(purchase.purchasedate).getDate() + suppliercreditdays)) 
        Duesdate = moment(fiveDaysInTheFuture).utc().format('YYYY-MM-DD');
         Duedate = Duesdate.toString();
         if(suppliercreditdays == 0){
            setPurchase({...purchase, duedate:""});
         }else{
            setPurchase({...purchase, duedate:Duedate});
         }
    }
  
    // Suppliers
    const fetchSuppliers = async () => {
        try {
            let req = await axios.get(SERVICE.SUPPLIER,{              
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            setSuppliers(req?.data?.suppliers?.map((d) => ({
                ...d,
                label: d.suppliername,
                value: d.suppliername,
            })))
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Business Locations
    const fetchBusilocations = async () => {
   
        try {
            let productlist = await axios.get(SERVICE.BUSINESS_LOCATION,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            
            setBusilocations(
                productlist?.data?.busilocations?.map((d) => ({
                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Products
    const fetchProducts = async () => {
        try {
            let productlist = await axios.get(SERVICE.PRODUCT,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    }
                });
                setProductsList(
                    productlist?.data?.products?.map((d) => ({
                        ...d,
                        label: d.prodname,
                        value: d.prodname,
                    }))
                );
            } catch (err) {
                const messages = err.response.data.message;
                toast.error(messages);
            }
        };

    // get settings data
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    }
            });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Taxrates
    const fetchRates = async () => {
        try {
            let taxlist = await axios.get(SERVICE.TAXRATE,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            setTaxrates(taxlist.data.taxrates);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    let cgst = 0;
    let gst = 0;
    let igst = 0;
    let CGST = 0;
    let GST = 0;
    let IGST = 0;

    allProductsList?.map((item) => {
           item?.subtax?.filter((data) =>{
            cgst += Number(data?.taxratecgst);
            gst += Number(data?.taxrategst);
            igst += Number(data?.taxrateigst);
           })
    })
  
        CGST = Number(totalNetCostCalcinclusive())*(cgst ? (cgst/100 ): 0).toFixed(2);
        GST  = Number(totalNetCostCalcinclusive())*(gst ? (gst/100) : 0).toFixed(2);
        IGST = Number(totalNetCostCalcinclusive())*(igst ?( igst/100 ): 0).toFixed(2);
      

    // alpha
    const fetchAlpha = async () => {
        let alpharate = await axios.get(SERVICE.ALPHARATE,{
            headers: {
            'Authorization':`Bearer ${auth.APIToken}`
            }
        });
        let getactivate = alpharate.data.alpharates.filter((data) => {
            return data.activate == true
        })
        setAlpha(getactivate);
    }

    // alpharate for exclude tax
    let gotalpha = "";
    const getAlphaRate = (inputValue) => {
        let getalpha = alpha.slice(0).filter((data) => {
            var num = inputValue;
            var digits = num.toString().split('');
            var realDigits = digits.map((item) => {
                if (item == '.') {
                    gotalpha += '.'
                }
                else {
                    gotalpha += data[item]
                }
            });
        });
        return gotalpha
    }

    //alpharate include tax
    const getAlphaRateInc = (taxValue) => {
        let alphaValue = ""
        let getalpha = alpha.slice(0).filter((data) => {
            var num = taxValue;
            var digits = num.toString().split('');
            var realDigits = digits.map((item) => {
                if (item == '.') {
                    alphaValue += '.'
                }
                else {
                    alphaValue += data[item]
                }
            })
        });
        return alphaValue;
    }


    useEffect(() => {
        fetchRates();
        fetchProducts();
        fetchSuppliers();
        fetchBusilocations();
        fetchSettings();
        fetchAlpha();
        totalNetCostCalc();
        totalNetCostCalcDisc();
        totalNetCostCalcSub();
        totalNetCostCalcinclusive();
    }, []);

    const backPage = useNavigate();

    // compare bill amount net total then only purchase status changed and update currentstock
    const purchaseStatusInput = (value) =>{
        if(value == "Received"){
            if(purchase.billamount == "" || purchase.billamount == 0){
                setPurchase({
                    ...purchase, purchasestatus: "Pending"
                });
                purchaseStatusReceived = "Pending";
                setShowAlert("Please enter bill amount");
                handleClickOpen();
            }else if(allProductsList.length == 0){
                setPurchase({
                    ...purchase, purchasestatus: "Pending"
                });
                purchaseStatusReceived = "Pending";
                setShowAlert("Please select any one of prodcut!");
                handleClickOpen();
            }else if(purchase.billamount != purchase.nettotal){
                setPurchase({
                    ...purchase, purchasestatus: "Pending"
                });
                purchaseStatusReceived = "Pending";
                setShowAlert("Bill Amount and Net total didn't match");
                handleClickOpen();
            }
            else if(purchase.billamount == purchase.nettotal && allProductsList.length > 0){
                setPurchase({
                    ...purchase, purchasestatus: "Received"
                });
            }
            }
        }


    // purchase data add to database
    const updateRequest = async () => {
        // current stock when the user submit
        if(purchase.purchasestatus == "Received"){
            allProductsList.map((item, index) => {
                productsList.forEach((data, i) => {
                    if ((item.sku == data.prodcode)) {
                        axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                            currentstock: Number(data.currentstock) + Number(item.quantity) + Number(item.freeitem),
                            purchaseexcludetax: Number(item.purchaseexcludetax),
                            pruchaseincludetax: Number(item.pruchaseincludetax),
                            sellingpriceunitcost: Number(item.sellingpriceunitcost),
                            batch:String(item.batch),
                            expirydate:String(item.expirydate),
                        });
                    }
                })
            })
        }

        

        let allproductreverse = [...allProductsList.reverse()]

            // setShowAlert(messages);
            // handleClickOpen();
        try {
            let req = await axios.put(`${SERVICE.PURCHASE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                supplier: String(purchase.supplier),
                referenceno: String(purchase.referenceno),
                purchasedate: String(purchase.purchasedate),
                purchasestatus: String(purchase.purchasestatus),
                addressone: String(purchase.addressone),
                supplierid: String(purchase.supplierid),
                supaddressone: String(purchase.supaddressone),
                addresstwo: String(purchase.addresstwo),
                city: String(purchase.city),
                gstn: String(purchase.gstn), 
                whatsapp: Number(purchase.whatsapp), 
                contactperson: String(purchase.contactperson),
                creditdays: Number(purchase.creditdays),
                dlnos: String(purchase.dlnos),
                duedate: String(purchase.duedate),
                businesslocation: String(purchase.businesslocation),
                billamount:Number(purchase.billamount),
                invoiceno: String(purchase.invoiceno),
                attachdoc: String(purchase.attachdoc),
                products: [...allproductreverse],
                additionalnotes: String(purchase.additionalnotes),
                totalcnt:Number(allProductsList.length),
                totalitem: Number(totalitem),
                nettotal: Number(purchase.nettotal),
                advancebalance: Number(purchase.advancebalance),
                payamount:Number(purchase.payamount),
                paidon:String(purchase.paidon),
                paymentmethod:String(purchase.paymentmethod),
                paydue: Number(purchase.paydue),
                balance:Number(balanceClaculation()),
                beforediscounttotal:Number(totalNetCostCalc()),
                subcost: Number(totalNetCostCalcSub()),
                totaldiscount: Number(totalNetCostCalcDisc()),
                granddistype:String(purchase.granddistype),
                gdiscountvalue:Number(purchase.gdiscountvalue),
                discountamt:Number(purchase.discountamt),
                cgst:Number(CGST),
                igst:Number(IGST),
                gst:Number(GST),
                activate:Boolean(purchase.activate),
                userbyadd:String(isUserRoleAccess.first+' '+isUserRoleAccess.last),
            })
            setPurchase(req.data);
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/purchase/purchase/list');
        }
        catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(purchase.supplier == ""){
            setShowAlert("Please select supplier");
            handleClickOpen();
        } else if(purchase.invoiceno == ""){
            setShowAlert("Please enter invoice no");
            handleClickOpen();
         }else if(allProductsList.length == 0){
            setShowAlert("Please select any one of product details!");
            handleClickOpen();
         }else if(purchase.billamount == "" ){
            setShowAlert("Please enter bill amount");
            handleClickOpen();
        }else if((purchase.billamount < purchase.nettotal)){
            setShowAlert("Please enter a valid bill amount");
            handleClickOpen();
        }else{
            updateRequest();
        }
       
    }

    //pay due calculation
    function payDueCalc(){

        if(purchase.granddistype == "None"){
            setPurchase(
                {...purchase, paydue: totalNetCostCalc() == undefined ? 0 : Number(totalNetCostCalc()) - Number(purchase.payamount)}
            )  
        }else{
            setPurchase(
                {...purchase, paydue: purchase.nettotal == "" ||  purchase.nettotal == 0 ? 0 : Number(purchase.nettotal) - Number(purchase.payamount)}
            )  
        }
       
    }

    useEffect(
        ()=>{
            payDueCalc();
        },[purchase.payamount,purchase.nettotal,allProductsList]
    )

    // all tabel product tax calculation
    function productUserInput(indexInput, productInputName, inputValue, reference = "") {
        let userInputs = allProductsList.map((value, indexList) => {

            if (indexInput == indexList) {
                if (reference == "Exclusive") {
                       if (value.purchasetax) {
                        let tax = (Number(inputValue) * (getTaxValue[0]?.taxtotal) / 100);
                        let includeTaxValue = tax + Number(inputValue)
                        return { ...value, [productInputName]: inputValue, excalpha: getAlphaRate(inputValue), pruchaseincludetax: includeTaxValue.toFixed(0), incalpha: getAlphaRateInc(includeTaxValue) }
                    }
                    return { ...value, [productInputName]: inputValue, excalpha: getAlphaRate(inputValue)}
                }
                else if (reference == "Inclusive") {
                    let tax = value.purchaseexcludetax ? (Number(value.purchaseexcludetax) * (getTaxValue[0]?.taxtotal) / 100) : "";
                    let includeTaxValue = tax ? tax + Number(value.purchaseexcludetax) : "";
                    return { ...value, [productInputName]: inputValue, pruchaseincludetax: includeTaxValue, incalpha: getAlphaRateInc(includeTaxValue) }
                }
                else if(reference == "Batch"){
                    return {...value, [productInputName]: inputValue}
                }else if(reference == "Packcharge"){
                    return {...value, [productInputName]: inputValue}
                }else if(reference == "Expirydate"){
                    return {...value, [productInputName]: inputValue}
                }else if(reference == "Margin"){
                    return {...value, [productInputName]: inputValue}
                }
             
                else if (reference == "Enteramt") {
                    if (value.purchasetabletax == "Exclusive") {
                        if(value.sellingpricetax == "Exclusive"){
                            let purchaseExtAmt = Number(inputValue);
                            let purchaseextamtfixed= purchaseExtAmt.toFixed(0);
                            //selling price exclusive purchase price exclusive unit cost
                            let sellingvalue = (Number(purchaseextamtfixed) * (Number(value.margin) / 100));
                            let sellingExcludevalue = Number(purchaseextamtfixed) + sellingvalue;
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, purchaseexcludetax: purchaseextamtfixed, pruchaseincludetax: 0 , incalpha:"", excalpha: getAlphaRate(purchaseextamtfixed),sellingpriceunitcost: sellingExcludevalue.toFixed(0)};
                        }else if(value.sellingpricetax == "Inclusive"){
                            let purchaseExtAmt = Number(inputValue);
                            let purchaseextamtfixed= purchaseExtAmt.toFixed(0);
                            //selling price inclusive purchase price exclusive unit cost
                            let sellingvaluemargin= (Number(purchaseextamtfixed) * (Number(value.margin) / 100));
                            let sellingExmargin = Number(purchaseextamtfixed) + Number(sellingvaluemargin);
                            let sellingvaluetax = (Number(sellingExmargin) * ((value.applicabletaxrate ? Number(value.applicabletaxrate) : Number(value.hsntax)) / 100));
                            let sellingExcludevaluetax = Number(sellingExmargin) + Number(sellingvaluetax);
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, purchaseexcludetax: purchaseextamtfixed, pruchaseincludetax: 0 , incalpha:"", excalpha: getAlphaRate(purchaseextamtfixed),sellingpriceunitcost: sellingExcludevaluetax.toFixed(0)};
                        }
                    }
                    else if (value.purchasetabletax == "Inclusive" ) {
                        if(value.sellingpricetax == "Exclusive"){
                            let purchaseIncAmt = Number(inputValue);
                            let purchaseincamtfix=purchaseIncAmt.toFixed(0);
                            let purchaseExcamtBasedInc = purchaseIncAmt * (getTaxValue[0]?.taxtotal/100) - purchaseIncAmt;
                            let purchaseExcamtBasedIncfix = Math.abs(purchaseExcamtBasedInc.toFixed(0));
                            //selling price exclusive purchase price inclusive unit cost
                            let sellingvalue = (Number(purchaseincamtfix) * (Number(value.margin) / 100));
                            let sellingIncludevalue = Number(purchaseincamtfix) + sellingvalue;
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, pruchaseincludetax:purchaseincamtfix, purchaseexcludetax: purchaseExcamtBasedIncfix,excalpha: getAlphaRate(purchaseExcamtBasedIncfix),incalpha: getAlphaRateInc(purchaseincamtfix),sellingpriceunitcost: sellingIncludevalue.toFixed(0)};
                        }else if(value.sellingpricetax == "Inclusive"){
                            let purchaseIncAmt = Number(inputValue);
                            let purchaseincamtfix=purchaseIncAmt.toFixed(0);
                            let purchaseExcamtBasedInc = purchaseIncAmt * (getTaxValue[0]?.taxtotal/100) - purchaseIncAmt ;
                            let purchaseExcamtBasedIncfix = Math.abs(purchaseExcamtBasedInc.toFixed(0));
                            //selling price inclusive purchase price inclusive unit cost
                            let sellingvaluemargin= (Number(purchaseExcamtBasedIncfix) * (Number(value.margin) / 100));
                            let sellingExmargin = Number(purchaseExcamtBasedIncfix) + Number(sellingvaluemargin);
                            let sellingvaluetax = (Number(sellingExmargin) * ((value.applicabletaxrate ? Number(value.applicabletaxrate) : Number(value.hsntax)) / 100));
                            let sellingExcludevaluetax = Number(sellingExmargin) + Number(sellingvaluetax);
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, pruchaseincludetax:purchaseincamtfix, purchaseexcludetax: purchaseExcamtBasedIncfix,excalpha: getAlphaRate(purchaseExcamtBasedIncfix),incalpha: getAlphaRateInc(purchaseincamtfix),sellingpriceunitcost: sellingExcludevaluetax.toFixed(0)};
                        }
                    }
                }
                else if (reference == "Quantity") {
               
                    if (value.purchasetabletax == "Exclusive" && value.purchaseexcludetax) {
                        let netCost = Number(value.purchaseexcludetax) * Number(inputValue)
                        setAlltotalItems((alltotalitems) => {
                            let allItems = [...alltotalitems];
                            allItems[indexInput] = { ...totalItem, quantity: inputValue };
                            return allItems;
                        });
                        return { ...value, [productInputName]: inputValue, purchasenetcost: netCost.toFixed(0),ratebydismod:netCost.toFixed(0)};
                    }
                    
                    else if (value.purchasetabletax == "Inclusive") {       
                        getTaxrateData = taxrates?.filter((item) => {
                            if(item.taxname == value.applicabletax ){
                                        return item                              
                                }
                            })                              
                        let netCost = Number(value.pruchaseincludetax) * Number(inputValue);
                        setAlltotalItems((alltotalitems) => {
                            let allItems = [...alltotalitems];
                            allItems[indexInput] = { ...totalItem, quantity: inputValue };
                            return allItems;
                        })
                    return { ...value, [productInputName]: inputValue, purchasenetcost: netCost.toFixed(0),ratebydismod:netCost.toFixed(0), 
                                     subtax:getTaxrateData };
                            }
                     
                    
                }
                else if (reference == "Discount") {
                    if(value.distypemod == "None") {
                        return { ...value, [productInputName]: inputValue, differenceamt: value.purchasenetcost,
                             }
                    }
                    else if((value.distypemod == "Fixed" || value.distypemod == "Amount") && value.purchasenetcost) {
                        let discountValue = Number(value.purchasenetcost) - Number(inputValue);
                        let differenceValue = Number(value.purchasenetcost) - discountValue;
                        return { ...value, [productInputName]: inputValue, ratebydismod: discountValue, differenceamt: differenceValue }
                    }
                    else if (value.distypemod == "Percentage" && value.purchasenetcost) {
                        let differenceValue = Number(value.purchasenetcost) * (Number(inputValue) / 100);
                        let discountValue = Number(value.purchasenetcost) - differenceValue;
                        return { ...value, [productInputName]: inputValue, ratebydismod: discountValue, differenceamt: differenceValue }
                    }
                }
                else if (reference == "Free") {
                    setAlltotalItems((alltotalitems) => {
                        let allItems = [...alltotalitems];
                        allItems[indexInput] = { ...totalItem, freeitem: inputValue };
                        return allItems;
                    });
                }
                return { ...value, [productInputName]: inputValue }
            }
            else {
                return value;
            }
        });
        setAllProductsList(userInputs);
    }
    
    function totalNetCostCalc() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                totalvalue += Number(value.ratebydismod)
            })
            return Number(totalvalue.toFixed(0));
        }
    }
    function totalNetCostCalcinclusive() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                if(value.purchasetabletax == "Inclusive")
                totalvalue += Number(value.ratebydismod)
            })
            return Number(totalvalue.toFixed(0));
        }
    }

    const balanceClaculation = () =>{
       if(purchase.granddistype == "None"){
        let balanceamtval =Number(purchase.billamount) ? Number(purchase.billamount)  : 0;
        balanceamt = (balanceamtval ? balanceamtval   : 0)- ( Number(totalNetCostCalc()) ? Number(totalNetCostCalc())  : 0)

        return balanceamt
       }else{
        let balanceamtval =Number(purchase.billamount) ? Number(purchase.billamount)  : 0;
        balanceamt = (balanceamtval ? balanceamtval   : 0)- ( Number(purchase.nettotal) ? Number(purchase.nettotal)  : 0)

        return balanceamt
       }

    }

    useEffect(
        ()=>{
            balanceClaculation()
        },[purchase.nettotal,allProductsList]
    )

    // total quantity and free item calculation
    let totalitem = 0.00;
    {
        allProductsList && (
            allProductsList.forEach(
                (item => {
                    totalitem += +item.quantity + +item.freeitem
                })
            ))
    }

    // Discount Amount
    function totalNetCostCalcDisc() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                totalvalue += +Number(value.differenceamt)
            })
            return totalvalue.toFixed(0);
        }
    }

    //subtotal
    function totalNetCostCalcSub() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                totalvalue += +Number(value.purchasenetcost)
            })
            return totalvalue.toFixed(0);
        }
    }

    useEffect(()=>{
        setPurchase({...purchase,beforediscounttotal:Number(totalNetCostCalc()) })
    },[])


    // Delete Searched Product
    const deleteRow = (i, e) => {
        setAllProductsList(allProductsList.filter((v, item) => item !== i));
        getarraydata(arraydata.filter((v, item) => item !== i))
    }
  
    // grand total calculation
    const grandtotalCalc = (reference, inputvalue) => {
        if(reference == "discounttype"){
            if (inputvalue == "None" ) {                    
                setPurchase(
                    {
                        ...purchase, nettotal: totalNetCostCalc().toFixed(0), granddistype:inputvalue, gdiscountvalue:"", discountamt:""
                    });
            }else if (inputvalue == "Fixed" || inputvalue == "Amount" ) {                    
                setPurchase(
                    {
                        ...purchase, nettotal: ((Number(totalNetCostCalc()) - Number(purchase.gdiscountvalue)).toFixed(0)), discountamt:Number(purchase.gdiscountvalue).toFixed(0) , granddistype:inputvalue
                    });
            }
            else if (inputvalue == "Percentage") {                    
                let percentCalc = Number(totalNetCostCalc()) * (Number(purchase.gdiscountvalue) / 100);
            setPurchase(
                {
                    ...purchase,  nettotal: (Number(totalNetCostCalc()) - percentCalc).toFixed(0),discountamt:Math.abs((Number(totalNetCostCalc()) - percentCalc) -  Number(totalNetCostCalc())).toFixed(0), granddistype:inputvalue
                });
            }
        }else if(reference == "discountamt"){
            if (purchase.granddistype == "None" ) {                    
                setPurchase(
                    {
                        ...purchase, nettotal: totalNetCostCalc().toFixed(0), gdiscountvalue:Number(inputvalue), discountamt: ""
                    });
            }else if (purchase.granddistype == "Fixed" || purchase.granddistype == "Amount" ) {                    
                setPurchase(
                    {
                        ...purchase, nettotal: (Number(totalNetCostCalc()) - Number(inputvalue)), discountamt:Number(inputvalue) , gdiscountvalue:Number(inputvalue),
                    });
            }
            else if (purchase.granddistype == "Percentage") {                    
                let percentCalc = Number(totalNetCostCalc()) * (Number(inputvalue) / 100);
            setPurchase(
                {
                    ...purchase,  nettotal: (Number(totalNetCostCalc()) - percentCalc).toFixed(0),discountamt:Math.abs((Number(totalNetCostCalc()) - percentCalc) -  Number(totalNetCostCalc())).toFixed(0), gdiscountvalue:Number(inputvalue)
                });
            }
        }             
    }

    return (
        <Box>
            <Headtitle title={'Edit Purchase'} />
            <form onSubmit={handleSubmit}>
                <Typography sx={userStyle.HeaderText}>Edit Purchase</Typography>
                <Box sx={userStyle.container}>
                    <Grid container spacing={3} sx={userStyle.textInput}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Supplier<b style={{color:'red'}}>*</b></InputLabel>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                    <Selects
                                        placeholder={purchase.supplier}
                                        styles={colourStyles}
                                        options={suppliers}
                                        onChange={(e) => { setPurchase({ ...purchase, supplier:e.value}) ;searchAdd(e._id); getDueDate(e.creditdays)}}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Reference No</InputLabel>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    value={purchase.referenceno}
                                    name="referenceno"
                                />
                            </FormControl>
                            <Typography variant='body2' sx={{ opacity: '0.9', marginTop: '5px' }}>Leave empty to autogenerate</Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Purchase Date</InputLabel>
                            <FormControl size="small" fullWidth >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField size="small" {...props} />}
                                    value={purchase.purchasedate}
                                    onChange={(newValue) => {
                                    setPurchase({...purchase, purchasedate:newValue}); 
                                    }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Bill Amount</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.billamount}
                                    type="Number"
                                    onChange={(e) => { setPurchase({ ...purchase, billamount: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Typography variant='subtitle1'>
                                <b>Address:</b> <br /> 
                                {purchase.addressone + ' ' + purchase.whatsapp}
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Business Location</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects 
                                 value= {{value: purchase.businesslocation ,label: purchase.businesslocation}}
                                    options={busilocations}
                                    styles={colourStyles}
                                    onChange={(e) => { setPurchase({ ...purchase, businesslocation: e.name});}} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Due date</InputLabel>
                            <FormControl size="small" fullWidth>
                                <TextField
                                    id="date"
                                    type="date"
                                    size="small"
                                    fullWidth
                                    value={purchase.duedate}
                                    onChange={(e) => { setPurchase({...purchase, duedate:e.target.value}); }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Invoice No<b style={{color:'red'}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.invoiceno}
                                    type="text"
                                    onChange={(e) => { setPurchase({ ...purchase, invoiceno: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Balance:-</InputLabel>
                            <Typography value={purchase.balance}
                             style={{color:'red', fontSize:'20px'}}
                            > {balanceClaculation()  == NaN ? 0 : balanceClaculation()}</Typography>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.filtercontent} style={{ minHeight: '300px', }}>
                    <Grid container style={{ justifyContent: "center", padding: '10px' }} sx={userStyle.textInput} >
                        <Grid md={8} sx={12} xs={12}>
                            <Grid sx={{ display: 'flex' }} >
                                <Grid sx={userStyle.spanIconTax}>< FaSearch /></Grid>
                                <FormControl size="small" fullWidth >
                                    <Selects
                                        sx={userStyle.textInput}
                                        styles={colourStyles}
                                        placeholder="Enter Product Name / SKU "
                                        options={productsList}
                                        onChange={(e) => 
                                            gethsncode(e)
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table aria-label="customized table">
                            <TableHead >
                                <StyledTableRow >
                                    <TableCell sx={{fontSize:'15px', background:'#5CB85C !important',color:'black !important'}}>Product</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Batch Number</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Tax</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Unit Cost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Quantity</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Netcost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Selling Price Unit Cost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {allProductsList.length > 0 &&
                                    allProductsList.map((data, i) => {
                                        return (
                                            <>
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Product Name</Typography>
                                                                <TextField 
                                                                    fullWidth
                                                                    size='small'
                                                                    sx={{minWidth:'230px'}}
                                                                    value={data?.prodname}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>SKU</Typography>
                                                                <TextField 
                                                                    fullWidth
                                                                    size='small'
                                                                    value={data?.sku}
                                                                />
                                                            </Grid>
                                                            {data?.hsn ?
                                                                (
                                                                    <>
                                                                        <Grid item md={12}>
                                                                            <Typography variant='body2'>HSN</Typography>
                                                                            <TextField size='small'
                                                                             fullWidth
                                                                                value={data?.hsn}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid item md={12}>
                                                                            <Typography variant='body2'>TAX</Typography>
                                                                            <TextField size='small'
                                                                             fullWidth
                                                                                value={data?.applicabletax}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            }
                                                            <Grid item md={12} sx={{ display: 'none' }}>
                                                                <Typography variant='body2'>TAX VALUE</Typography>
                                                                <TextField 
                                                                size='small'
                                                                fullWidth
                                                                    value={data?.applicabletaxrate}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12} sx={{ display: 'none' }}>
                                                                <Typography variant='body2'>HSN VALUE</Typography>
                                                                <TextField size='small'
                                                                 fullWidth
                                                                    value={data?.hsntax}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1} sx={{marginTop:'43px'}}>                                                        
                                                            <Grid item md={12}>
                                                            <Typography variant='body2'>Batch</Typography>
                                                                <TextField 
                                                                    size="small"
                                                                    fullWidth
                                                                    type="text"
                                                                    value={data?.batch}
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "batch", e.target.value, "Batch")
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                            <Typography variant='body2'>Packing charge</Typography>
                                                                <TextField 
                                                                    size="small"
                                                                    value={data?.packingcharge}                     
                                                                    fullWidth
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "packingcharge", e.target.value, "Packcharge")
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                            <Typography variant='body2'>Expiry date</Typography>
                                                                <TextField
                                                                    id="date"
                                                                    type="date"
                                                                    size="small"
                                                                    fullWidth
                                                                    value={data?.expirydate}
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "expirydate", e.target.value, "Expirydate")
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Purchase Tax</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <Select
                                                                        id="demo-select-small"
                                                                        value={data?.purchasetabletax}
                                                                        onChange={(e) => productUserInput(i, "purchasetabletax", e.target.value)}
                                                                        fullWidth
                                                                    >
                                                                        <MenuItem value="Exclusive" >Exclusive</MenuItem>
                                                                        <MenuItem value="Inclusive" >Inclusive</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Enter Amt</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField size='small'
                                                                        type="Number"
                                                                        value={data?.enteramt}
                                                                        onChange={(e) => {
                                                                            productUserInput(i, "enteramt", e.target.value, "Enteramt");
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Margin %</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField size='small'
                                                                        value= {data?.margin}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            productUserInput(i, "margin", e.target.value, "Margin")
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Exc Tax</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.purchaseexcludetax}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Alphaarate</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.excalpha}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Inc Tax</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.pruchaseincludetax}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Alphaarate</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.incalpha}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Quantity</Typography>
                                                                <TextField size='small'
                                                                    type="number"
                                                                    value={data?.quantity}
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "quantity", e.target.value, "Quantity"); 
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12} style={{ width: "auto !important" }} >
                                                                <Typography variant='body2'>Free Item</Typography>
                                                                <TextField size='small'
                                                                    value={data?.freeitem}
                                                                    type="number"
                                                                    onChange={(e) => productUserInput(i, "freeitem", e.target.value, "Free")}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Netcost</Typography>
                                                                <TextField size='small'
                                                                    value={data?.purchasenetcost}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Discount type</Typography>
                                                                    <FormControl size="small" fullWidth>
                                                                        <Select
                                                                            id="demo-select-small"
                                                                            value={data?.distypemod}
                                                                            onChange={(e) => productUserInput(i, "distypemod", e.target.value)}
                                                                        >
                                                                            <MenuItem value="None">None</MenuItem>
                                                                            <MenuItem value="Fixed">Fixed</MenuItem>
                                                                            <MenuItem value="Amount" >Amount</MenuItem>
                                                                            <MenuItem value="Percentage">Percentage</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <Typography variant='body2'>Disc.Val</Typography>
                                                                <TextField size='small'
                                                                    type="number"
                                                                    value={data?.disvaluemod}
                                                                    onChange={(e) => productUserInput(i, "disvaluemod", e.target.value, "Discount")}
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <Typography variant='body2'>Disc.Amt</Typography>
                                                                <TextField size='small'
                                                                    type="number"
                                                                    value={data?.differenceamt}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Netcost (After Discount)</Typography>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    id="component-outlined"
                                                                    value={data?.ratebydismod}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid item md={12}>
                                                            <Typography variant='body2'>Selling Price Tax</Typography>
                                                            <FormControl size="small" fullWidth >
                                                                <OutlinedInput
                                                                    id="demo-select-small"
                                                                    type="text"
                                                                    value={data?.sellingpricetax}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Selling price unit tax</Typography>
                                                                <FormControl size="small" >
                                                                    <TextField size='small'
                                                                        type="number"
                                                                        value={data?.sellingpriceunitcost}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell><AiOutlineClose style={{ color: 'red', fontWeight: '900', cursor: 'pointer', fontSize: 'large' }} onClick={(e) => deleteRow(i, e)}/></StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />                    
                </Box>
                <br />
                <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item md={1} sm={6} xs={12}>
                    <InputLabel id="demo-select-small">Total Item</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={Number(allProductsList.length)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Total Quantity</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={totalitem}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Total Amount:</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={totalNetCostCalc()}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Type</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    id="demo-select-small"  
                                    value={purchase.granddistype}
                                    onChange={(e) => {grandtotalCalc('discounttype', e.target.value)}}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Fixed">Fixed</MenuItem>
                                    <MenuItem value="Amount">Amount</MenuItem>
                                    <MenuItem value="Percentage">Percentage</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Value</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.gdiscountvalue}
                                    onChange={(e) => grandtotalCalc('discountamt', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Amount</InputLabel>
                            <FormControl size="small" fullWidth>
                                <TextField
                                    size="small"
                                    value={purchase.discountamt}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">After Disc Total Amount:</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.granddistype == "None" ? totalNetCostCalc() : purchase.nettotal}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">CGST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={(CGST ? CGST : 0).toFixed(2)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">GST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={(GST ? GST : 0).toFixed(2)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">IGST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={(IGST ? IGST : 0).toFixed(2)}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box><br />  
                <Box sx={userStyle.container}>
                     {/* purchasestatus section start */}
                    <Grid container>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Purchase Status</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    id="demo-select-small"
                                    value={purchase.purchasestatus}
                                    onChange={(e) => {
                                        setPurchase({ ...purchase, purchasestatus: e.target.value });
                                        purchaseStatusInput(e.target.value);
                                    }}
                                >
                                    <MenuItem value={purchaseStatusPending}>{purchaseStatusPending}</MenuItem>
                                    <MenuItem value={purchaseStatusReceived}>{purchaseStatusReceived}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid><br />
                    {/* purchase status section end */}
                {purchase.purchasestatus == "Received" ? 
                    (
                        <>
                            <Typography variant="h6" >Add payment</Typography>
                            <Grid container spacing={3} sx={userStyle.textInput}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant="body2" ><b>Advance Balance:</b> ₹ {purchase.advancebalance}</Typography>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <InputLabel htmlFor="component-outlined">Amount</InputLabel>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <Grid sx={userStyle.spanIconTax}><MoneyOutlinedIcon style={{ fontSize: 'large' }} /></Grid>
                                            <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                id="component-outlined"
                                                defaultValue={purchase}
                                                value={purchase.payamount}
                                                onChange={(e) => {
                                                    setPurchase({
                                                        ...purchase, payamount: e.target.value,
                                                        paydue: purchase.nettotal - +e.target.value
                                                    })
                                                }}
                                                type='number'
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12} >
                                <InputLabel htmlFor="component-outlined">Paid Date</InputLabel>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <FormControl size="small" fullWidth >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                renderInput={(props) => <TextField size="small" {...props} />}
                                                value={purchase.paidon}
                                                onChange={(newValue) => {
                                                    setPurchase({...purchase, paidon:newValue});
                                                }}
                                            />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                <InputLabel id="demo-select-small">Payment Method</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="demo-select-small"
                                            value={purchase.paymentmethod}
                                            onChange={(e) => {
                                                setPurchase({ ...purchase, paymentmethod: e.target.value });
                                            }}
                                        >
                                            <MenuItem value="None">None</MenuItem>
                                            <MenuItem value="Cash">Cash</MenuItem>
                                            <MenuItem value="Credit Card">Credit Card</MenuItem>
                                            <MenuItem value="UPI">UPI</MenuItem>
                                            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                                            <MenuItem value="Net Banking">Net Banking</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <InputLabel id="demo-select-small" sx={{ m: 1 }}>Payment Note</InputLabel>
                                    <FormControl size="small" fullWidth >
                                        <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                            value={purchase.additionalnotes}
                                            onChange={(e) => { setPurchase({ ...purchase, additionalnotes: e.target.value }) }}
                                        />
                                    </FormControl><br /><br />
                                    <hr />
                                </Grid>
                                <Grid container style={{ justifyContent: "right", }} sx={userStyle.textInput}>
                                    <Grid>
                                        <Typography variant='subtitle1'
                                        ><b>Payment due:</b> ₹ {purchase.paydue}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ): null
                }
                 <Grid container sx={userStyle.gridcontainer}>
                    <Grid sx={{ display: 'flex' }}>
                        <Link to="/purchase/purchase/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                        <Button sx={userStyle.buttonadd} type="submit">UPDATE</Button><br />
                    </Grid>
                </Grid>
                </Box>
            </form>
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                <Typography variant="h6" >{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const Purchaseedit = () => {
    return (
        <>
            <Box>
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Purchaseeditlist /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Purchaseedit;