import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Grid, Dialog, DialogContent, DialogActions, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { userStyle } from "../../PageStyle";
import axios from 'axios';
import jsPDF from "jspdf";
import $ from 'jquery';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/Appcontext';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SERVICE } from '../../../services/Baseservice';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import { useReactToPrint } from 'react-to-print';

function Productlisttable() {

  // show all products
  const [products, setProducts] = useState([]);
  //  Excel data
  const [exceldata, setExceldata] = useState([]);
  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  //alert delete popup
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [productId, setProductId] = useState([]);

  const { auth, } = useContext(AuthContext);

  const handleOpen = () => {
    setIsDeleteOpen(true);
  };

  const handleClose = () => {
    setIsDeleteOpen(false);
  };

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])


  // / Excel
  const fileName = "Products";
  const productexcel = async () => {
    var data = products.map(t => ({
      "Product name": t.prodname, "Product sku": t.prodcode, "Bussiness Location": t.businesslocation, "Category": t.category, "Molecules": t.molecules, "Schedule": t.schedule,
      "Manufacture": t.manufacture, " Unit": t.unit, "Applicabletax": t.applicabletax, "Minimum Quantity": t.minquantity, "Maximum Quantity": t.maxquantity, "Rack": t.rack, "Row": t.row, "Box No": t.boxno , 
      "Current stock" : t.currentstock , "PurchaseExcludetax":t.purchaseexcludetax,"Purchaseincludetax" :  t.pruchaseincludetax, "Sellingexcludetax":t.sellingexcludetax ,"ProuctType":t.producttype, "Selling Tax Type" :t.selltaxtype,
    }));
    setExceldata(data);
  }


  useEffect(() => {
    productexcel();
  }, [exceldata])
  // get all products
  const fetchProduct = async () => {
    try {
      let res_product = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setProducts(res_product.data.products);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // delete function api
  const rowData = async (id) => {
    try {
      let res = await axios.get(`${SERVICE.PRODUCT_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      })
      setProductId(res.data.sproduct);
      console.log(res.data.sproduct);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //alert delete popup
  let prodid = productId._id;
  const deleteProd = async (prodid) => {
    try {
        await axios.delete(`${SERVICE.PRODUCT_SINGLE}/${prodid}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    doc.autoTable({
      html: '#producttablepdf',
      margin: { top: 10 },
    })

    doc.save('Products.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | PRODUCTS',
    pageStyle: 'print'
  });

  useEffect(
    () => {
      fetchProduct();
    }, [products]
  )

  return (
    <Box >
      <Headtitle title={'List Product'} />
      { /* ****** Header Content ****** */}
      <Typography component={'span'} variant={'body2'} sx={userStyle.HeaderText}>Products <Typography component={'span'} variant={'body2'} sx={userStyle.SubHeaderText}>Manage your Products</Typography></Typography>

      { /* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container} >
          { /* Header Content */}
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography  component={'span'} variant={'body2'} sx={userStyle.importheadtext}>All your Products</Typography>
            </Grid>
            <Grid item xs={4}>
              {isUserRoleCompare[0].aproduct && (
                <>
                  <Link to="/product/product/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd} >ADD</Button></Link>
                </>
              )}
            </Grid>
          </Grid>
          { /* Header Buttons */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvproduct && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelproduct && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printproduct && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfproduct && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          {/* Table Grid Container */}
          { /* Table Start */}
          <TableContainer component={Paper}>
            <Table aria-label="customized table"  ref={tableRef}>
              <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
                <StyledTableRow >
                  <StyledTableCell >Actions</StyledTableCell>
                  <StyledTableCell >Product Name</StyledTableCell>
                  <StyledTableCell >SKU</StyledTableCell>
                  <StyledTableCell >Business location</StyledTableCell>
                  <StyledTableCell >Category</StyledTableCell>
                  <StyledTableCell >Molecules</StyledTableCell>
                  <StyledTableCell >Schedule</StyledTableCell>
                  <StyledTableCell >Manufactures</StyledTableCell>
                  <StyledTableCell >Unit</StyledTableCell>
                  <StyledTableCell >Tax</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody> 
                {
                  products.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row" colSpan={1}>
                        <Grid sx={{ display: 'flex' }}>
                          {isUserRoleCompare[0].eproduct && (
                            <>
                              <Link to={`/product/product/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                            </>
                          )}
                          {isUserRoleCompare[0].vproduct && (
                            <>
                              <Link to={`/product/product/view/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                            </>
                          )}
                          {isUserRoleCompare[0].dproduct && (
                            <>
                              <Button sx={userStyle.buttondelete} onClick={(e) => { handleOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                            </>
                          )}
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                      <StyledTableCell align="left">{row.prodcode}</StyledTableCell>
                      <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                      <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                      <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                      <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                      <StyledTableCell align="left">{row.unit}</StyledTableCell>
                      <StyledTableCell align="left">{row.hsn ? row.hsncode : row.applicabletax}</StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          { /* Table End */}
        </Box>
      </>
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table aria-label="simple table" id="producttablepdf" ref={componentRef}>
          <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
            <StyledTableRow >
              <StyledTableCell >Product Name</StyledTableCell>
              <StyledTableCell >SKU</StyledTableCell>
              <StyledTableCell >Business location</StyledTableCell>
              <StyledTableCell >Category</StyledTableCell>
              <StyledTableCell >Molecules</StyledTableCell>
              <StyledTableCell >Schedule</StyledTableCell>
              <StyledTableCell >Manufactures</StyledTableCell>
              <StyledTableCell >Unit</StyledTableCell>
              <StyledTableCell >Tax</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              products.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                  <StyledTableCell align="left">{row.prodcode}</StyledTableCell>
                  <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                  <StyledTableCell align="left">{row.category}</StyledTableCell>
                  <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                  <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                  <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                  <StyledTableCell align="left">{row.unit}</StyledTableCell>
                  <StyledTableCell align="left">{row.hsn ? row.hsncode : row.applicabletax}</StyledTableCell>
                </StyledTableRow>
              ))
           }
          </TableBody>
        </Table>
      </TableContainer>
      { /* ****** Table End ****** */}
      {/* ALERT DIALOG */}
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={(e) => deleteProd(prodid)} autoFocus variant="contained" sx={{cursor:"pointer"}} color='error'> OK </Button>
        </DialogActions>
      </Dialog>
      {/* PDF Model */}

    </Box>
  );
}

function Productlist() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Productlisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Productlist;