import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function CustomercomparisonList() {

    const [pos, setPos] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today,
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()


    let totSales = [];
    let allArray = [];
    let cusNameArr = []
    let finalresult = [];
    let percentArr = [];

    // fetch Pos
    const fetchPos = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            let posData = req.data.pos1;
            let result = posData.filter((item, i) => {
                let dateTrim = moment(item.date).utc().format('YYYY-MM-DD')
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    return item
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                    return item
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                    return item
                }
            })
            
            //pushing into arrays to get values in table
            let calculation = result.filter((value) => {
                cusNameArr.push(value.customer);
                totSales.push(+value.aftergranddisctotal)
                percentArr.push(((+value.aftergranddisctotal - +value.customerpaidamount) / +value.aftergranddisctotal * 100).toFixed(2))

            })
            allArray = cusNameArr.map(function (data, i) {
                return { customername: data, totalsales: totSales[i], percentage: percentArr[i] };
            });

            finalresult = [...allArray.reduce((r, o) => {
                const key = o.customername;
                const items = r.get(key) || Object.assign({}, o, {
                    totalsales: 0,
                    percentage: "",
                });
                items.totalsales += o.totalsales
                items.percentage += o.percentage + "%"
                return r.set(key, items);
            }, new Map).values()];
            setPos(finalresult);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Customer Comparison'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = pos.map((t, i) => ({
            'Customer': t.customername, 'Sales Amount': t.totalsales,
            'Percentage': t.percentage,
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CUSTOMER COMPARISON',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Customercomparison' })
        doc.save('Customer Comparison.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [pos]
    )

    return (
        <Box >
            <Headtitle title={'Customer Comparison'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Customer Comparison</Typography>
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.filtercontent} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={5} md={5}>
                            <Grid container>
                                <Grid item lg={3} md={3}>
                                    <Typography sx={{ marginTop: 1 }}>Date From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, startdate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} md={5}>
                            <Grid container>
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, enddate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={() => { fetchPos(); }}>Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvcustomercomparison && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelcustomercomparison && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printcustomercomparison && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfcustomercomparison && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Customercomparison" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Customer</StyledTableCell>
                                    <StyledTableCell>Sales Amount</StyledTableCell>
                                    <StyledTableCell>Percentage</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {pos.length > 0 && (
                                    pos.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.customername}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalsales}</StyledTableCell>
                                            <StyledTableCell align="left">{row.percentage}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls}>
                <Box>
                    <Typography variant='h5' >Customer Comparison Print</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="Customercomparison" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Customer</StyledTableCell>
                                        <StyledTableCell>Sales Amount</StyledTableCell>
                                        <StyledTableCell>Percentage</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {pos.length > 0 && (
                                        pos.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.customername}</StyledTableCell>
                                                <StyledTableCell align="left">{row.totalsales}</StyledTableCell>
                                                <StyledTableCell align="left">{row.percentage} </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}
function Customercomparison() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <CustomercomparisonList /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Customercomparison;