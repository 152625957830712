import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography, DialogContent,DialogActions,Dialog } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle,colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { useReactToPrint } from "react-to-print";
import Selects from 'react-select';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SERVICE } from "../../services/Baseservice";
import { AuthContext, UserRoleAccessContext } from '../../context/Appcontext';

function Stock() {

  //  Access
  const { auth } = useContext(AuthContext);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  const [purchases, setPurchases] = useState([]);
  const [isProducts, setisProducts] = useState([]);
  const [isEditEnable, setIsEditEnable] = useState("");
  const [isStockUpdate, setIsStockUpdate] = useState("");
  const [getData, setGetData] = useState({id:"", code:""});
  const [stockUpdate, setStockUpdate] = useState({itemname:"", reference:""});
  const [tableData, setTableData] = useState([]);
  const [exceldata, setExceldata] = useState([]);

  // Update popup modal
  const [isOpen, setIsopen] = useState(false);
  const handleOpen = () => { setIsopen(true); };
  const handleClose = () => { setIsopen(false); setIsEditEnable("");};

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  //products
  const fetchProducts = async () =>{
    try{
        let response = await axios.get(SERVICE.PRODUCT,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
              },
        });
        setisProducts(response?.data?.products?.map((d)=>({
          ...d,
          label: d.prodname,
          value: d.prodcode
        })));
        await fetchPurchases(response.data.products);
    }catch(err){
        const messages = err.response.data.message;
        toast.error(messages);
    }
  }

  //fetch all purchase
  const fetchPurchases = async (allproducts) => {
   
    try {
        let res_purchase = await axios.get(SERVICE.PURCHASEPRODUCTS,{
          headers: {
              'Authorization': `Bearer ${auth.APIToken}`
            },
        });

        let get_currentstock = res_purchase.data.purchases.map((value)=>{
            let data = value;
            allproducts.forEach((checkValue)=>{
              if(value.sku == checkValue.prodcode){
                data = {...value, currentstock:checkValue.currentstock}
              }
            })
             return data;
        })
        setPurchases(get_currentstock)
      
        $(document).ready(function () {
          $.fn.dataTable.ext.errMode = 'none';
          setTimeout(function () {
            $(tableRef.current).DataTable({
              language: { search: '', searchPlaceholder: "Search..." },
              lengthMenu: [
                [10, 1, 25, 50, 100, 200, 500, -1],
                [10, 1, 25, 50, 100, 200, 500, 'All'],
              ],
            });
          }, 1000);
        });
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
      }
  };

  const handleFetchProduct =  () =>{
   
    let res_purchase = purchases.filter((data, index)=>{
       if(stockUpdate.itemname == data.sku){
          return data
       }
    })
    setTableData(res_purchase)
  }  

  const updateStockData = (id, procode) =>{
    let res_purchaseupdate = purchases.filter((item,index)=>{
      if(id == item._id){
          axios.put(`${SERVICE.PURCHASEPRODUCTS_SINGLE}/${item._id}`,{
            headers: {
              'Authorization': `Bearer ${auth.APIToken}`
          },
          prodname:String(stockUpdate.reference == "prodname" ? isStockUpdate : item.prodname),
          expirydate:String(stockUpdate.reference == "expirydate" ? isStockUpdate : item.expirydate),
          quantity:Number(stockUpdate.reference == "quantity" ? isStockUpdate : item.quantity),
          sellingpriceunitcost:Number(stockUpdate.reference == "price" ? isStockUpdate : item.sellingpriceunitcost),
          applicabletax:String(stockUpdate.reference == "tax" ? isStockUpdate : item.applicabletax),

          })
      }
    })
  }
  
    //update uniq id in stock, product
    const handleChangeStockUpdate =  (reference, id, procode) =>{
      if(reference == "proname"){
        setIsEditEnable(id+reference)
        setGetData({...getData, id:id, code:procode});
        setStockUpdate({...stockUpdate, reference:reference})
      }else if(reference == "expirydate"){
        setIsEditEnable(id+reference)
        setGetData({...getData, id:id, code:procode});  
        setStockUpdate({...stockUpdate, reference:reference})
      }else if(reference == "currentstock"){
        setIsEditEnable(id+reference)
        setGetData({...getData, id:id, code:procode}); 
        setStockUpdate({...stockUpdate, reference:reference}) 
      }else if(reference == "quantity"){
        setIsEditEnable(id+reference)
        setGetData({...getData, id:id, code:procode}); 
        setStockUpdate({...stockUpdate, reference:reference}) 
      }else if(reference == "price"){
        setIsEditEnable(id+reference)
        setGetData({...getData, id:id, code:procode});  
        setStockUpdate({...stockUpdate, reference:reference})
      }else if(reference == "mrp"){
        setIsEditEnable(id+reference)
        setGetData({...getData, id:id, code:procode}); 
        setStockUpdate({...stockUpdate, reference:reference}) 
      }
      else if(reference == "tax"){
        setIsEditEnable(id+reference)
        setGetData({...getData, id:id, code:procode});  
        setStockUpdate({...stockUpdate, reference:reference})
      }
    
    }

  // Export Excel
  const fileName = "Batch Wise Stock Detail";

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = tableData.map(t => ({
      "Item Code": t.sku, "Item Name": t.prodname, "Batch No": t.batch, Expiry: t.pruchaseincludetax ? t.pruchaseincludetax : t.purchaseexcludetax,
      "Stock In Hand(Pieces)": t.currentstock,"Pack Qty": t.quantity, "Purchase Rate": t.enteramt, "Sales Rate": t.sellingpriceunitcost, "MRP Rate": t.sellingpriceunitcost, 
      "GST %": t.hsn ? t.hsn : t.applicabletax, GRN: t.sellingpriceunitcost
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()
  }, [tableData])

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#stockupdatetable" });
    doc.save("Stock Update.pdf");
  };
  
  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "HIPOS| STOCK UPDATE",
    pageStyle: "print",
  });

  useEffect(
    ()=>{
      fetchProducts();
    },[]
  )


  return (

      <Box>
        <Headtitle title={'Stock Update'} />
        <Typography sx={userStyle.HeaderText}>Stock Update</Typography>
        <Box sx={userStyle.filtercontent} >
          <Grid container spacing={1}>
            <Grid item lg={1} md={1}>
              <Typography sx={{ marginTop: 1 }}>Item Name</Typography>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
                <FormControl size="small" fullWidth >
                  <Selects
                    id="component-outlined"
                    sx={userStyle.textInput}
                    styles={colourStyles}
                    options={isProducts}
                    onChange={(e) => setStockUpdate({...stockUpdate, itemname:e.prodcode})}
                    placeholder="Item Name"
                  />
                </FormControl>
              </Grid>
            <Grid item lg={5} md={5} >
            </Grid>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <Button variant='contained' color='secondary' onClick={handleFetchProduct}>Generate</Button>
            </Grid>
          </Grid>
        </Box><br />
        {/* content start */}
        <Box sx={userStyle.container}>
          { /* ******************************************************EXPORT Buttons****************************************************** */}
          <Grid container spacing={2}>
              <Grid item xs={8} md={8} lg={8} sm={8}>
              <p style={{color: '#726c6c',fontSize: '15px',fontWeight: 400}}>Double click the below value that name should be changed(Expiry, Pack Qty, Sles rate, MRP rate, GST)</p>
              </Grid>
              <Grid item xs={4} md={4} lg={4} sm={4}>
                  <Button sx={userStyle.buttonadd} onClick={handleOpen}>Update </Button>
              </Grid>
          </Grid>
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvstockupdate && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelstockupdate && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printstockupdate && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}> &ensp; <FaPrint />  &ensp;Print&ensp;    </Button>
                </>
              )}
              {isUserRoleCompare[0].pdfstockupdate && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700 }} aria-label="customized table" id="stockupdatetable" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell>Item Name</StyledTableCell>
                    <StyledTableCell >Batch No</StyledTableCell>
                    <StyledTableCell >Expiry</StyledTableCell>
                    <StyledTableCell >Stock in Hand(pices)</StyledTableCell>
                    <StyledTableCell >Pack Qty</StyledTableCell>
                    <StyledTableCell >Purchase Rate</StyledTableCell>
                    <StyledTableCell >Sales Rate</StyledTableCell>
                    <StyledTableCell >MRP Rate</StyledTableCell>
                    <StyledTableCell >GST %</StyledTableCell>
                    <StyledTableCell >GRN</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {tableData.length > 0 && (
                    tableData.map((row, index)=>(
                      <StyledTableRow key={index}>
                      <StyledTableCell >{row.sku}</StyledTableCell>
                      {isEditEnable == (row._id+'proname') ? (
                            <TextField defaultValue={row.prodname}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('proname',row._id,row.sku)}>{row.prodname}</StyledTableCell>
                      )}
                      <StyledTableCell >{row.batch}</StyledTableCell>
                      {isEditEnable == (row._id+'expirydate') ? (
                            <TextField defaultValue={row.expirydate}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('expirydate',row._id,row.sku)}>{row.expirydate}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'currentstock' ? (
                            <TextField type="number" defaultValue={row.currentstock}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('currentstock',row._id,row.sku)}>{row.currentstock}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'quantity' ? (
                            <TextField type="number" defaultValue={Number(row.quantity)+Number(row.freeitem)}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('quantity',row._id,row.sku)}>{Number(row.quantity)+Number(row.freeitem)}</StyledTableCell>
                      )}
                      <StyledTableCell>{row.enteramt}</StyledTableCell>
                      {isEditEnable == row._id+'price' ? (
                            <TextField type="number" defaultValue={row.sellingpriceunitcost}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('price',row._id,row.sku)}>{row.sellingpriceunitcost}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'mrp' ? (
                            <TextField type="number" defaultValue={row.sellingpriceunitcost}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('mrp',row._id,row.sku)}>{row.sellingpriceunitcost}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'tax' ? (
                            <TextField defaultValue={row.hsn ? row.hsn : row.applicabletax}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('tax',row._id,row.sku)}>{row.hsn ? row.hsn : row.applicabletax}</StyledTableCell>
                      )}
                      <StyledTableCell>{row.invoicenumber}</StyledTableCell>
                    </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <TableContainer component={Paper}  sx={userStyle.printcls} >
          <TableBody align="left" ref={componentRef}>
                  {tableData.length > 0 && (
                    tableData.map((row, index)=>(
                      <StyledTableRow key={index}>
                      <StyledTableCell >{row.sku}</StyledTableCell>
                      {isEditEnable == (row._id+'proname') ? (
                            <TextField defaultValue={row.prodname}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('proname',row._id,row.sku)}>{row.prodname}</StyledTableCell>
                      )}
                      <StyledTableCell >{row.batch}</StyledTableCell>
                      {isEditEnable == (row._id+'expirydate') ? (
                            <TextField defaultValue={row.expirydate}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('expirydate',row._id,row.sku)}>{row.expirydate}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'currentstock' ? (
                            <TextField type="number" defaultValue={row.currentstock}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('currentstock',row._id,row.sku)}>{row.currentstock}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'quantity' ? (
                            <TextField type="number" defaultValue={Number(row.quantity)+Number(row.freeitem)}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('quantity',row._id,row.sku)}>{Number(row.quantity)+Number(row.freeitem)}</StyledTableCell>
                      )}
                      <StyledTableCell>{row.enteramt}</StyledTableCell>
                      {isEditEnable == row._id+'price' ? (
                            <TextField type="number" defaultValue={row.sellingpriceunitcost}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('price',row._id,row.sku)}>{row.sellingpriceunitcost}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'mrp' ? (
                            <TextField type="number" defaultValue={row.sellingpriceunitcost}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('mrp',row._id,row.sku)}>{row.sellingpriceunitcost}</StyledTableCell>
                      )}
                      {isEditEnable == row._id+'tax' ? (
                            <TextField defaultValue={row.hsn ? row.hsn : row.applicabletax}
                            onChange={(e) => { setIsStockUpdate(e.target.value);}}>
                        </TextField>
                      ) : (
                          <StyledTableCell align="left" onDoubleClick={(e) => handleChangeStockUpdate('tax',row._id,row.sku)}>{row.hsn ? row.hsn : row.applicabletax}</StyledTableCell>
                      )}
                      <StyledTableCell>{row.invoicenumber}</StyledTableCell>
                    </StyledTableRow>
                    ))
                  )}
                </TableBody>
            </TableContainer>
        </Box>

       {/* ALERT DIALOG */}
       <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent  sx={{ width: '350px',  textAlign:'center', alignItems:'center'}}>
                    <ErrorOutlineOutlinedIcon sx={{fontSize:"80px", color:'orange'}} />
                <Typography variant="h5" sx={{color:'red', textAlign:'center'}}>Are you sure?</Typography>

                </DialogContent>
                <DialogActions>
                <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                <Button autoFocus  variant="contained" color='error' onClick={(e)=>{updateStockData(getData.id, getData.code);handleClose();}}> OK  </Button>
                </DialogActions>
            </Dialog>
        {/* content end */}
      </Box>
  );
}


function Stockupdate() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Stock /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Stockupdate;