import React, { useState, useEffect,useRef, useContext} from 'react';
import { Box, Button, Grid, TextField, Dialog, DialogContent, DialogActions, Paper, Typography, TableContainer, Table, TableHead, TableBody } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import $ from 'jquery';
import {useReactToPrint} from 'react-to-print';
import { userStyle } from '../PageStyle';
import axios from 'axios';
import jsPDF from "jspdf";
import { UserRoleAccessContext } from "../../context/Appcontext";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../components/header/Headtitle';

function ManuFactureUpdateDetails() {

    const [products, setProducts] = useState([]);
    const [stocks, setStocks] = useState([]);

    const { auth } = useContext(AuthContext);
    const [isEditEnable, setIsEditEnable] = useState("");
    const [isUpdateManufacture, setIsUpdateManufacture] = useState("");
    const [getData, setGetData] = useState({id:"", code:""});
    const [changeUpdate, setChangeUpdate] = useState();
    const [exceldata,setExceldata]= useState([]);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Update popup modal
    const [isOpen, setIsopen] = useState(false);
    const handleOpen = () => { setIsopen(true); };
    const handleClose = () => { setIsopen(false); setIsEditEnable("");};

    //Export
    const fileName = "Manufacture Update";

       // get all stocks
     const fetchStocks = async () => {
        try {
        let res_stocks = await axios.get(SERVICE.STOCK, {
            headers: {
            'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        setStocks(res_stocks.data.stocks);
        } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
        }
    }

    // get all products
    const fetchProducts = async () => {
        try {
        let res_product = await axios.get(SERVICE.PRODUCT, {
            headers: {
            'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        setProducts(res_product.data.products);
        } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
        }
    }

    //DataTable
    $.DataTable = require('datatables.net')
    const tableRef = useRef();

    useEffect(
        ()=>{
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                  $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                      [10, 1, 25, 50, 100, 200, 500, -1],
                      [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                  });
                }, 1000);
              });
        },[]
    )

    //update uniq id in stock, product
    const handleChangeManufacture =  (id, procode) =>{
        setIsEditEnable(id)
        setGetData({...getData, id:id, code:procode});
    }

    const updateManufacture = async (id, procode) =>{

        let update_product = products.map((item, i)=>{
            if(item._id == id){
                axios.put(`${SERVICE.PRODUCT_SINGLE}/${id}`,{
                    headers: {
                        'Authorization': `Bearer ${auth.APIToken}`
                    },
                    manufacture:String(isUpdateManufacture)
                })
            }
        })

        let update_stock = stocks.map((item, i)=>{
            if(item.itemcode == procode){
                axios.put(`${SERVICE.STOCK_SINGLE}/${item._id}`,{
                    headers: {
                        'Authorization': `Bearer ${auth.APIToken}`
                    },
                    manufacture:String(isUpdateManufacture)
                })
            }
        })

        setChangeUpdate("");
    }

    // get particular columns for export excel
    const getexcelDatas= async () => {
        var data = products.map(t=>({ "Item Name": t.prodname, Manufacture: t.manufacture }));
        setExceldata(data);
    }

    useEffect(() => {
        getexcelDatas()
    }, [products])
    

    // Printing
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | MANUFACTURE UPDATE',
        pageStyle: 'print'
    });

    // PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#manufactureupdatetable' })
        doc.save('Manufacture Update.pdf')
    }

    useEffect(
        ()=>{
            fetchProducts();
            fetchStocks();
        },[changeUpdate]
    )
     
    return (
        <Box>
            <Headtitle title={'Manufacture Update'} />
            <Typography sx={userStyle.HeaderText}>Manufacture Update</Typography>
            <br />
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8} md={8} lg={8} sm={8}>
                    </Grid>
                   <Grid item xs={4} md={4} lg={4} sm={4}>
                        {isUserRoleCompare[0].umanufactureupdate && ( <Button sx={userStyle.buttonadd} onClick={handleOpen}>Update </Button> )}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].csvmanufactureupdate && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelmanufactureupdate && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printmanufactureupdate && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfmanufactureupdate && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid>
                <br />
                <Box>
                    {/* ****** Table Start ****** */}
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="manufactureupdatetable" ref={tableRef}>
                            <TableHead>
                                <StyledTableRow >
                                    <StyledTableCell >Item Name</StyledTableCell>
                                    <StyledTableCell >Manufacture<p style={{color: '#726c6c',fontSize: '13px',fontWeight: 400}}>Double click the below value that name should be changed</p></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                            {products.length > 0 ? (
                                products.map((row, index) => (
                                    <StyledTableRow key={index}>
                                    <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                                    {isEditEnable == row._id ? (
                                         <TextField defaultValue={row.manufacture}
                                         onChange={(e) => { setIsUpdateManufacture(e.target.value);}}>
                                     </TextField>
                                    ) : (
                                        <StyledTableCell align="left" onDoubleClick={(e) => handleChangeManufacture(row._id,row.prodcode)}>{row.manufacture}</StyledTableCell>
                                    )}
                                    </StyledTableRow>
                                ))
                                ) : (<StyledTableCell colSpan={8}><Typography>No data available in table</Typography></StyledTableCell>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer> 
                    {/* ****** Table End ****** */}
                </Box>
                {/* print layout */}
                <TableContainer component={Paper} sx={userStyle.printcls}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" id="manufactureupdatetable" ref={componentRef}>
                        <TableHead>
                            <StyledTableRow >
                                <StyledTableCell >Item Name</StyledTableCell>
                                <StyledTableCell >Manufacture</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                        {products.length > 0 ? (
                            products.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                                    <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                                </StyledTableRow>
                            ))
                            ) : (<StyledTableCell colSpan={8}><Typography>No data available in table</Typography></StyledTableCell>
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* ALERT DIALOG */}
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent  sx={{ width: '350px',  textAlign:'center', alignItems:'center'}}>
                    <ErrorOutlineOutlinedIcon sx={{fontSize:"80px", color:'orange'}} />
                    <Typography variant="h5" sx={{color:'red', textAlign:'center'}}>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                    <Button autoFocus  variant="contained" color='error' onClick={(e)=>{updateManufacture(getData.id, getData.code);handleClose();}}> OK  </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
function ManuFactureUpdate() {
    return (
        <>
            <Box >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        < ManuFactureUpdateDetails/><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default ManuFactureUpdate;