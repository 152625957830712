import React, { useState, useContext } from 'react';
import { Box, Button, Grid, FormControl, OutlinedInput, InputLabel, Typography, Dialog,DialogContent, DialogActions, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import axios from 'axios';
import { useNavigate,Link } from 'react-router-dom';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import Headtitle from '../../../components/header/Headtitle';
import {SERVICE} from '../../../services/Baseservice';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { AuthContext } from '../../../context/Appcontext';

function Departmentcreate() {

  const [departmentAdd, setdepartmentAdd] = useState({ departmentid: "", departmentname: ""});
  const { auth } = useContext(AuthContext);

  //popup model
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState()
  const handleClickOpenc = () => {
    setIsErrorOpen(true);
  };
  const handleClose = () => {
    setIsErrorOpen(false);
  };

  const backLPage = useNavigate();
  let newval =  "DA0001";

  // store department data to db
  const sendRequest = async () => {
    
    try {
      let res = await axios.post(SERVICE.DEPARTMENT_CREATE, {
        headers: {
          'Authorization':`Bearer ${auth.APIToken}`
        },
        departmentid: String(departmentAdd.departmentid),
        departmentname: String(departmentAdd.departmentname)
      });
      setdepartmentAdd(res.data);
      backLPage('/user/department/list');
    } catch (err) {
      const messages = err.response.data.errorMessage;
        setShowAlert(messages);
      handleClickOpenc();
    } 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(departmentAdd.departmentname ==""){
      setShowAlert("Please enter department name!");
        handleClickOpenc();
    }else{
      sendRequest();
    }
 
  }

  return (
    <Box>
      <Headtitle title={'Add Department'} />
      <Typography sx={userStyle.HeaderText}>Add Department</Typography>
      {/* content start */}
     <form onSubmit={handleSubmit}>
     <Box sx={userStyle.container}>
        <Grid container spacing={3} sx={userStyle.textInput}>
          <Grid item md={8} sm={12} xs={12}>
            <InputLabel htmlFor="component-outlined">Department Id<b style={{color:'red'}}>*</b></InputLabel>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                id="component-outlined"
                value={newval}
              />
            </FormControl>
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <InputLabel htmlFor="component-outlined">Department Name<b style={{color:'red'}}>*</b></InputLabel>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                id="component-outlined"
                value={departmentAdd.departmentname}
                onChange={(e) => { setdepartmentAdd({ ...departmentAdd, departmentname: e.target.value, departmentid:newval }) }}
                type="text"
              />
            </FormControl>
          </Grid>
        </Grid><br />
        <br />
        <Grid container sx={userStyle.gridcontainer}>
          <Grid sx={{display:'flex'}}>
            <Button sx={userStyle.buttonadd} type="submit">SAVE</Button>
            <Link to="/user/department/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
          </Grid>
        </Grid>
      </Box>
     </form>
      {/* ALERT DIALOG */}
      <Box>
        <Dialog
          open={isErrorOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
            <Typography variant="h6" >{showAlert}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

function Departmentscreate() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Departmentcreate /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Departmentscreate;