import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Grid, InputLabel, FormControl, Box, OutlinedInput, TextareaAutosize, } from '@mui/material';
import Navbar from '../../../../components/header/Navbar';
import Footer from '../../../../components/footer/Footer';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailIconOutlined from '@mui/icons-material/EmailOutlined';
import LocationOnIconOutlined from '@mui/icons-material/LocationOnOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { useParams, Link } from 'react-router-dom';
import { userStyle } from '../../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';
import { AuthContext } from '../../../../context/Appcontext';
import{ SERVICE } from '../../../../services/Baseservice';

function Supplierviewlist() {

    const [supplier, setSupplier] = useState({});
    const { auth } = useContext(AuthContext);

    const id = useParams().id

    // Fetch supplier by id
    const fetchHandler = async () => {
        try {
            let response = await axios.get(`${SERVICE.SUPPLIER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setSupplier(response.data.ssupplier);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(
        () => {
        fetchHandler()
    }, [id]
    )

    return (
        <Box>
            <Headtitle title={'Supplier View'} />
            <Typography sx={userStyle.HeaderText}>View Supplier</Typography>
            <Box sx={userStyle.container}>
                <Grid container spacing={3}>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6' sx={{ justifyContent: 'center' }}>Supplier Details</Typography>
                    </Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Fields</Typography>
                    </Grid>
                    <Grid item md={8} sm={6} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>1.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Supplier Code</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}  >
                            <Grid sx={userStyle.spanIcons}><ContactPageOutlinedIcon /></Grid>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.autogenerate}
                                    type="text"
                                    name="autogenerate"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>2.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Supplier Name</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}  >
                            <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.suppliername}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>3.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Address1</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth >
                            <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                value={supplier.addressone}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>4.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Address2</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth >
                            <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                value={supplier.addresstwo}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>5.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Country</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}>
                            <Grid sx={userStyle.spanIcons}><LanguageOutlinedIcon /></Grid>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.country}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>6.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>State</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}>
                            <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.state}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>7.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>City</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}  >
                            <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.city}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>8.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Pincode</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}  >
                            <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={supplier.pincode}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>9.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Email</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid sx={{ display: 'flex' }}  >
                            <Grid sx={userStyle.spanIcons}><EmailIconOutlined /></Grid>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.email}
                                    type="email"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>10.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>GSTN</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                id="component-outlined"
                                value={supplier.gstn}
                                type="text"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>11.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Phone</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item md={6} sm={6} xs={12}>
                                <InputLabel htmlFor="component-outlined">Phone1</InputLabel>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={supplier.phoneone}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <InputLabel htmlFor="component-outlined">Phone2</InputLabel>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={supplier.phonetwo}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <InputLabel htmlFor="component-outlined">Phone3</InputLabel>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={supplier.phonethree}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <InputLabel htmlFor="component-outlined">Phone4</InputLabel>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={supplier.phonefour}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>12.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Landline</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                id="component-outlined"
                                value={supplier.landline}
                                type="text"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>13.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Whatsapp</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                sx={userStyle.input}
                                id="component-outlined"
                                value={supplier.whatsapp}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>14.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Contact Person</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                id="component-outlined"
                                value={supplier.contactperson}
                                type="text"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>15.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>Credit Days</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                sx={userStyle.input}    
                                id="component-outlined"
                                value={supplier.creditdays}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>16.</Typography></Grid>
                    <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6'>DL NOS</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput
                                sx={userStyle.input}
                                id="component-outlined"
                                value={supplier.dlnos}
                                type="number"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}></Grid>
                    <Grid container sx={userStyle.gridcontainer}>
                        <Grid >
                            <Link to="/contact/supplier/list"><Button sx={userStyle.buttoncancel}>BACK</Button></Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

function Supplierview() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Supplierviewlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Supplierview;