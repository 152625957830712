import React, { useEffect, useState, useContext } from 'react';
import { userStyle, colourStyles } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, TextField, Select, MenuItem, Paper, TableCell, TextareaAutosize, Typography, Button, Table, TableContainer, TableHead, TableBody } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FaSearch } from "react-icons/fa";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import Selects from "react-select";
import { SERVICE } from '../../../services/Baseservice';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import moment from "moment";

const Purchaseviewlist = () => {

    const [purchase, setPurchase] = useState({
        supplier: "", purchasestatus: "", paymentmethod: "", businesslocation: "", nettotal: 0.0, gdiscountvalue: "", granddistype: "",discountamt:""
    });
    const { auth } = useContext(AuthContext);

    const [allProductsList, setAllProductsList] = useState([]);

    const id = useParams().id;

    const fetchHandlers = async () => {
        try {
            let req = await axios.get(`${SERVICE.PURCHASE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPurchase(req.data.spurchse)
            setAllProductsList(req.data.spurchse.products);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchHandlers()
    }, [id]);


    return (
        <Box>
            <Headtitle title={'Purchases View'} />
                <Typography sx={userStyle.HeaderText}>View Purchase</Typography>
                <Box sx={userStyle.container}>
                    <Grid container spacing={3} sx={userStyle.textInput}>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Supplier</InputLabel>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                    <OutlinedInput
                                        id="demo-select-small"
                                        type="text"
                                        value={purchase.supplier}
                                        fullWidth
                                    />
                            </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Reference No</InputLabel>
                            <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                <OutlinedInput
                                    id="demo-select-small"
                                    type="text"
                                    value={purchase.referenceno}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Purchase Date</InputLabel>
                            <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                <OutlinedInput
                                    id="demo-select-small"
                                    type="text"
                                    value={moment(purchase.purchasedate).utc().format('DD-MM-YYYY')}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Bill Amount</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.billamount}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='subtitle1'>
                                <b>Address:</b> <br />
                                {purchase.addressone}
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Business Location</InputLabel>
                            <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                <OutlinedInput
                                    id="demo-select-small"
                                    type="text"
                                    value={purchase.businesslocation}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Invoice No:</InputLabel>
                            <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                <OutlinedInput
                                    id="demo-select-small"
                                    type="text"
                                    value={purchase.invoiceno}
                                    fullWidth
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Balance:-</InputLabel>
                            <Typography value={purchase.balance} style={{color:'red', fontSize:'20px'}}
                            > {purchase.balance}</Typography>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} style={{ minHeight: '300px', }}>
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table aria-label="customized table">
                            <TableHead >
                                <StyledTableRow >
                                    <TableCell sx={userStyle.tableHead1}>Product</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Batch Number</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Tax</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Unit Cost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Quantity</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Netcost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Selling Price Unit Cost</TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {allProductsList.length > 0 &&
                                    allProductsList.map((data, i) => {
                                        return (
                                            <>
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Product Name</Typography>
                                                                <TextField size='small'
                                                                    value={data?.prodname}
                                                                    sx={{ minWidth: '230px' }}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>SKU</Typography>
                                                                <TextField size='small'
                                                                    value={data?.sku}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            {data?.hsn ?
                                                                (
                                                                    <>
                                                                        <Grid item md={12}>
                                                                            <Typography variant='body2'>HSN</Typography>
                                                                            <TextField size='small'
                                                                                value={data?.hsn}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid item md={12}>
                                                                            <Typography variant='body2'>TAX</Typography>
                                                                            <TextField size='small'
                                                                                value={data?.applicabletax}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            }
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container sx={{ marginTop: '43px' }}>
                                                            <Grid item md={12} >
                                                                <Typography variant='body2'>Batch</Typography>
                                                                <TextField
                                                                    size='small'
                                                                    fullWidth
                                                                    value={data?.batch}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Packing charge</Typography>
                                                                <TextField
                                                                    size='small'
                                                                    fullWidth
                                                                    value={data?.packingcharge}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Expiry date</Typography>
                                                                <TextField
                                                                    type="text"
                                                                    size="small"
                                                                    fullWidth
                                                                    value={moment(data?.expirydate).utc().format('DD-MM-YYYY')}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Purchase Tax</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                <TextField size='small'
                                                                        value={data?.purchasetabletax}
                                                                    />
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Enter Amt</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField size='small'
                                                                        value={data?.enteramt}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Margin %</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField size='small'
                                                                        value={data?.margin}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Exc Tax</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.purchaseexcludetax}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Alphaarate</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.excalpha}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Inc Tax</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.pruchaseincludetax}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Alphaarate</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.incalpha}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Quantity</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.quantity}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12} style={{ width: "auto !important" }} >
                                                                <Typography variant='body2'>Free Item</Typography>
                                                                <TextField size='small'
                                                                    value={data?.freeitem}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Netcost</Typography>
                                                                <TextField size='small'
                                                                    value={data?.purchasenetcost}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Discount type</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.distypemod}
                                                                />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <Typography variant='body2'>Disc.Val</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.disvaluemod}
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <Typography variant='body2'>Disc.Amt</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.differenceamt}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Netcost (After Discount)</Typography>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    id="component-outlined"
                                                                    value={data?.ratebydismod}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid item md={12}>
                                                            <Typography variant='body2'>Selling Price Tax</Typography>
                                                            <FormControl size="small" fullWidth >
                                                                <OutlinedInput
                                                                    size="small"
                                                                    id="component-outlined"
                                                                    value={data?.sellingpricetax}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Selling price unit tax</Typography>
                                                                <FormControl size="small" >
                                                                    <TextField size='small'
                                                                        type="text"
                                                                        value={data?.sellingpriceunitcost}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer><br />
                </Box><br />
                <Box sx={userStyle.container} >
                    <Grid container spacing={2}>
                        <Grid item md={1} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Total Item</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.totalcnt}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Total Quantity</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.totalitem}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Total Amount:</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.beforediscounttotal}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Type</InputLabel>
                            <FormControl size="small" fullWidth>
                            <TextField
                                    size="small"
                                    value={purchase.granddistype}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Value</InputLabel>
                            <FormControl size="small" fullWidth>
                                <TextField
                                    size="small"
                                    value={purchase.gdiscountvalue}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Amount</InputLabel>
                            <FormControl size="small" fullWidth>
                                <TextField
                                    size="small"
                                    value={purchase.discountamt}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">After Disc Total Amount:</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.nettotal}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">CGST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.cgst}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">GST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.gst}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">IGST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.igst}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box><br />
                {/* 2nd box */}
                <Box sx={userStyle.container}>
                    <Grid container>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Purchase Status</InputLabel>
                            <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                            <OutlinedInput
                                    id="demo-select-small"
                                    value={purchase.purchasestatus}
                                />
                            </FormControl>
                        </Grid>
                    </Grid><br />
                    {purchase.purchasestatus == "Received" ?
                    (
                        <>
                            <Typography variant="h6" >Add payment</Typography>
                                <Grid container spacing={3} sx={userStyle.textInput}>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <InputLabel htmlFor="component-outlined">Amount</InputLabel>
                                        <Grid sx={{ display: 'flex' }}  >
                                            <Grid sx={userStyle.spanIconTax}><MoneyOutlinedIcon style={{ fontSize: 'large' }} /></Grid>
                                            <FormControl size="small" fullWidth >
                                                <OutlinedInput
                                                    id="component-outlined"
                                                    value={purchase.payamount}
                                                    type='number'
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <InputLabel id="demo-select-small">Paid Date:*</InputLabel>
                                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                            <OutlinedInput
                                                    id="component-outlined"
                                                    value={purchase.paidon}
                                                    type='number'
                                                />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <InputLabel id="demo-select-small">Payment Method</InputLabel>
                                        <FormControl size="small" fullWidth>
                                            <OutlinedInput
                                                id="component-outlined"
                                                value={purchase.paymentmethod}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <InputLabel id="demo-select-small" sx={{ m: 1 }}>Payment Note</InputLabel>
                                        <FormControl size="small" fullWidth >
                                            <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                                value={purchase.additionalnotes}
                                            />
                                        </FormControl><br /><br />
                                        <hr />
                                    </Grid>
                                    <Grid container style={{ justifyContent: "right", }} sx={userStyle.textInput}>
                                        <Grid>
                                            <Typography variant='subtitle1'
                                                value={purchase.paydue}
                                            ><b>Payment due:</b> ₹{purchase.paydue}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </>
                    ) : null
                }
                 <Grid container sx={userStyle.gridcontainer}>
                    <Link to="/purchase/purchase/list"><Button sx={userStyle.buttoncancel}>BACK</Button></Link>
                </Grid><br />
                </Box>
        </Box>
    );
}

const Purchaseview = () => {
    return (
        <>
            <Box>
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Purchaseviewlist /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Purchaseview;