import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Typography, FormControl, InputLabel, OutlinedInput,Dialog, DialogContent, DialogActions, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function Uniteditlist() {

  const [isUnit, setIsUnit] = useState({});
  const { auth } = useContext(AuthContext);

  const id = useParams().id;

    //popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleOpen = () => {
      setIsErrorOpen(true);
    };
    const handleClose = () => {
      setIsErrorOpen(false);
    };

  // fetch particular id value
  const fetchCategory = async () => {
    try {
      let req = await axios.get(`${SERVICE.UNIT_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setIsUnit(req.data.sunit);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  let backPage = useNavigate();


  // store edited data to particular id update request
  const sendRequest = async () => {

    try {
      let res = await axios.put(`${SERVICE.UNIT_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        unit: String(isUnit.unit),
        shortname: String(isUnit.shortname),
        allowdecimal: String(isUnit.allowdecimal),
      });
      setIsUnit(res.data);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      backPage("/product/unit/list");
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(
    () => {
      fetchCategory();
    }, []
  )

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUnit.unit == "") {
      setShowAlert("Please enter unit name");
      handleOpen();
    } else {
      sendRequest();
    }
  }

  return (
    <Box>
      <Headtitle title={'Edit Units'} />
      {/* Form Start */}
      <form onSubmit={handleSubmit}>
        <Typography sx={userStyle.HeaderText} variant='body2'>Edit Unit</Typography>
        <Box sx={userStyle.container}>
          <Grid container spacing={3} sx={userStyle.textInput}>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel htmlFor="component-outlined">Unit<b style={{ color: "red" }}> *</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  id="component-outlined"
                  value={isUnit.unit}
                  onChange={(e) => { setIsUnit({ ...isUnit, unit: e.target.value }) }}
                  type="text"
                  name="categoryname"
                />
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel htmlFor="component-outlined">Short Name</InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  id="component-outlined"
                  value={isUnit.shortname}
                  onChange={(e) => { setIsUnit({ ...isUnit, shortname: e.target.value }) }}
                  type="text"
                  name="categorycode"
                />
              </FormControl>
            </Grid>
          </Grid><br />

          <Grid container sx={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center' }}>
            <Button sx={userStyle.buttonadd} type="submit" autoFocus>UPDATE</Button>
            <Link to="/product/unit/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
          </Grid>
        </Box>
      </form>
      {/* Form End */}
      {/* Alert box */}
      <Dialog
        open={isErrorOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h6" >{showAlert}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

const Unitedit = () => {
  return (
    <>
      <Box >
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Uniteditlist /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default Unitedit;