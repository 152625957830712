import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useReactToPrint } from "react-to-print";
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import { Link } from 'react-router-dom';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ExportXL, ExportCSV } from '../../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';

function Userslisttable() {

  const [users, setUsers] = useState();
  const [deltUser, setDeltUser] = useState({});
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);

  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  // Delete model
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const handleClickOpen = () => { setIsDeleteOpen(true); };
  const handleClose = () => { setIsDeleteOpen(false); };

  // User
  const fetchHandler = async () => {
    try {
      let user = await axios.get(`${SERVICE.USER}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let userData = user.data.users.filter((data) => {
        return data.termscondition == false
      })
      setUsers(userData);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //set function to get particular row
  const rowData = async (id) => {
    try {
      let res = await axios.get(`${SERVICE.USER_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setDeltUser(res.data.suser);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //alert delete popup
  let userid = deltUser._id;

  const deleteUser = async (userid) => {

    try {
      let res = await axios.delete(`${SERVICE.USER_SINGLE}/${userid}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Export Excel
  const fileName = 'User'
  // get perticular columns for export excel
  const getexcelDatas = async () => {
    var data = users.map(t => ({ "User Name": t.username, "Name": t.prefix + ' ' + t.first + ' ' + t.last, "Role": t.role, "Email": t.email }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | USERS',
    pageStyle: 'print'
  });

  const ref = createRef();
  const options = {
    orientation: 'portrait',
    unit: 'in'
  };

  //  PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#usertablePDF' })
    doc.save('usertable.pdf')
  }

  
  useEffect(() => {
    fetchHandler();
    getexcelDatas();
  }, [deleteUser]);

  //DATA TABLE
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });

  }, [(tableRef.current)])

  return (
    <Box>
      <Headtitle title={'Users'} />
      {/* header text */}
      <Typography sx={userStyle.HeaderText}>Users <Typography sx={userStyle.SubHeaderText}>Manage Users</Typography></Typography>
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography sx={userStyle.importheadtext}>All your users</Typography>
          </Grid>
          <Grid item xs={4}>
            {isUserRoleCompare[0].auser && (
              <>
                <Link to="/user/user/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvcustomergrp && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelcustomergrp && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printcustomergrp && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfcustomergrp && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>

              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell >Username</StyledTableCell>
                  <StyledTableCell >Name</StyledTableCell>
                  <StyledTableCell >Role</StyledTableCell>
                  <StyledTableCell >Email</StyledTableCell>
                  <StyledTableCell >Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {users && (
                  users.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">{row.username}</StyledTableCell>
                      <StyledTableCell >{row.prefix + ' ' + row.first + ' ' + row.last}</StyledTableCell>
                      <StyledTableCell >{row.role}</StyledTableCell>
                      <StyledTableCell >{row.email}</StyledTableCell>
                      <StyledTableCell >
                        <Grid SX={{ display: 'flex' }}>
                          {isUserRoleCompare[0].euser && <Link to={`/user/user/edit/${row._id}`} style={{ textDecoration: 'none', color: 'white', }}><Button sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>}
                          {isUserRoleCompare[0].vuser && <Link to={`/user/user/view/${row._id}`} style={{ textDecoration: 'none', color: 'white', }}><Button sx={userStyle.buttonview}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>}
                          {isUserRoleCompare[0].duser && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>)}
                        </Grid>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}

      {/* Delete */}
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={(e) => deleteUser(userid)} autoFocus variant="contained" color='error'> OK </Button>
        </DialogActions>
      </Dialog>

      { /* ****** Print ****** */}
      <Box sx={userStyle.printcls} >
        <Box>
          <Typography variant='h5' >Users</Typography>
        </Box>
        <>
          <Box>
            <TableContainer component={Paper} sx={userStyle.printcls}>
              <Table aria-label="simple table" id="usertablePDF" ref={componentRef}>
                <TableHead sx={{ fontWeight: "600" }} >
                  <StyledTableRow >
                    <StyledTableCell ><b>Username</b></StyledTableCell>
                    <StyledTableCell ><b>Name</b></StyledTableCell>
                    <StyledTableCell ><b>Role</b></StyledTableCell>
                    <StyledTableCell ><b>Email</b></StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {users && (
                    users.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row">{row.username}</StyledTableCell>
                        <StyledTableCell >{row.prefix + ' ' + row.first + ' ' + row.last}</StyledTableCell>
                        <StyledTableCell >{row.role}</StyledTableCell>
                        <StyledTableCell >{row.email}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      </Box>
    </Box>
  );
}

function Userslist() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Userslisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Userslist;