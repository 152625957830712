import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Grid, MenuItem, FormControl,TextField, Box, OutlinedInput,FormGroup, FormControlLabel, Checkbox, Select, Dialog, DialogContent, DialogActions } from '@mui/material';
import Selects from "react-select";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { useNavigate, Link } from 'react-router-dom';
import { userStyle, colourStyles } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import moment from 'moment';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function Custbillregister() {

    const [allCustomer, setAllCustomer] = useState([]);
    const [allBills, setAllBills] = useState([]);
    const [allBillsWithCustomer, setAllBillsWithCustomer] = useState([]);
    const [posAdd, setPosAdd] = useState({});
    const [checkDue, setCheckDue] = useState(false);

    //  Add Text Field
    const [customerBill, setCustomerBill] = useState({
        referenceno: "", name: "", billno: "", billdate: "", duedate: "", billamount: "", narration: "",
    });

    // Access
    const { auth } = useContext(AuthContext);

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    // Customer names
    const fetchCustomername = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAllCustomer(
                req?.data?.customers?.map((d) => ({
                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Fetch all bills
    const fetchPosBills = async () => {
        try {
            let res = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAllBills(res.data.pos1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Get customer bill based on customer name
    const getCustomerBill = (e) => {
        let getcustomerallbill = allBills?.filter((item) => {
            if (e.cusid == item.contactid) {
                return item
            }
        })
        setAllBillsWithCustomer(getcustomerallbill);
    }

    // Select particular customer bill to get bill detailes
    const searchCus = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.POS_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPosAdd(res.data.spos);
            setCustomerBill({ ...customerBill, billdate:(moment(res.data.spos.date).format("DD-MM-YYYY")), duedate:(moment(res.data.spos.duedate).format("DD-MM-YYYY")), billamount:res.data.spos.aftergranddisctotal, billno:res.data.spos.referenceno,  })
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchCustomername();
        fetchPosBills();
    }, [allBills])

    const backLPage = useNavigate();
    //New customer add page
    const addCustomerBill = async () => {

        // Update duedate in pos
        axios.put(`${SERVICE.POS_SINGLE}/${posAdd._id}`, {
            duedate: String(customerBill.duedate)
        });

        try {
            let req = await axios.post(SERVICE.CUSTOMER_BILL_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(customerBill.referenceno),
                name: String(customerBill.name),
                billno: String(posAdd.referenceno),
                billdate: String(posAdd.date),
                duedate: String(customerBill.duedate),
                billamount: Number(posAdd.aftergranddisctotal),
                narration: String(customerBill.narration),
            });
            setCustomerBill(req.data);
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            //redirecting list page 
            backLPage('/contact/customer/Customerbillsreport')
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };

    //submit button 
    const handleSubmit = (e) => {
        e.preventDefault();

        if (customerBill.referenceno == "") {
            setShowAlert('Please Enter Reference No!')
            handleClickOpen();
        }else if (customerBill.name == "") {
            setShowAlert('Please Enter customer name!')
            handleClickOpen();
       }else if(customerBill.billno == "" || customerBill.billno == 0){
            setShowAlert('Please Select Bill No!')
            handleClickOpen();
        }else if(customerBill.duedate == ""){
            setShowAlert('Please Choose Due Date!')
            handleClickOpen();
        }else{
            addCustomerBill();
        }
    }

    return (

        <Box>
            <Headtitle title={'Customer Bills Register'} />
            <Typography sx={userStyle.HeaderText}>Customer Bills Register</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: "flex" }}>Reference No<Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={customerBill.referenceno}
                                    onChange={(e) => { setCustomerBill({ ...customerBill, referenceno: e.target.value }) }}
                                    type="text"
                                    name="referenceno"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: "flex" }}>Name<Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <Selects
                                    onChange={(e) => {
                                        setCustomerBill({ ...customerBill, name: e.value })
                                        getCustomerBill(e);
                                    }}
                                    styles={colourStyles}
                                    options={allCustomer}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Bill No<b style={{color:'red'}}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={customerBill.billno}
                                    onChange={(e, i) => { setCustomerBill({ ...customerBill, billno: e.target.value }); }}
                                >
                                    {allBillsWithCustomer && (
                                        allBillsWithCustomer.map((row, index) => (
                                            <MenuItem value={row.referenceno} key={index} onClick={(e) => { searchCus(row._id) }}>{row.referenceno}</MenuItem>
                                        )))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Bill Date</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <Grid sx={userStyle.spanIcons}><CalendarMonthOutlinedIcon /></Grid>
                            <FormControl size="small" fullWidth>
                                  <OutlinedInput
                                   id="component-outlined"
                                   value={customerBill.billdate}
                                   size="small"
                                   name="billdate"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Due Date<b style={{color:'red'}}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                      renderInput={(props) => <TextField size="small" {...props} />}
                                        value={customerBill.duedate}
                                        onChange={(newValue) => {
                                            setCustomerBill({...customerBill, duedate:newValue});
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Bill Amount</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={posAdd.aftergranddisctotal}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Narration</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customerBill.narration}
                                    onChange={(e) => { setCustomerBill({ ...customerBill, narration: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <br />
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Link to="../contact/customer/Customerbillsreport"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                                <Button sx={userStyle.buttonadd} type='submit' >SAVE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>

            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Custbillregisterlist() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Custbillregister /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Custbillregisterlist;