import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { userStyle } from "../../PageStyle";
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Paper, Select, MenuItem, TextField, TableCell, Typography, Button, Table, TableContainer, TableHead, TableRow, TableBody, DialogActions, DialogContent, Dialog, DialogTitle, Card, } from "@mui/material";
import { FaMoneyBillAlt, FaRegWindowClose } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import Selects from "react-select";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { toast } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Mainlogo from "../../../assets/images/mainlogo.png";
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Headtitle from '../../../components/header/Headtitle';
import dayjs from 'dayjs';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import moment from 'moment';


const Quotationedit = () => {

    const [productsList, setProductsList] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [products, setProducts] = useState([]);
    const [taxrates, setTaxrates] = useState();
    const [businesslocation, setBusinesslocation] = useState([]);
    const [pos, setPos] = useState([]);
    const [discounts, setDiscounts] = useState("");
    const [setngs, setSetngs] = useState();
    const { auth } = useContext(AuthContext)

    // Access
    const { isUserRoleAccess } = useContext(UserRoleAccessContext);

    // pos inside products array data
    const productInputs = {
        productid: "", productname: "",sellingpriceunitcost:0,purchasevalue:0, subtax:[], quantity: "", sellingpricetax: "", taxtareval: "", subtotal: "", sellingexcludevalue: "", sellingincludevalue: "", applicabletax: "", discountamt: 0, purchaseexcludetax: "", productcgst:0, productigst:0, productsgst:0,batch:""
    }

    // pos db store data 
    const [isQuotation, setIsQuotation] = useState({granddistype:""});


    // Show Ledger Alert
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const alertOpen = () => { setIsErrorOpen(true); };
    const alertClose = () => { setIsErrorOpen(false); };
    const id = useParams().id; 
    const backPage = useNavigate();

    // Amount gain alter Alert
    const [isAmountgain, setisAmountgain] = useState("");

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | INVOICE',
        pageStyle: 'print'
    });

    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    let discount;
    let quantity;
    let tot;
    let getTaxrateData;

    //fetch draft
    const fetchQuotation = async () => {
        try {
            let res = await axios.get(`${SERVICE.QUOTATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setIsQuotation(res.data.squotation);
            setTableData(res.data.squotation.goods);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // get taxvrate data for products
    const taxrateRequest = async () => {
        try {
            let response = await axios.get(SERVICE.TAXRATE, {

                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setTaxrates(response.data.taxrates);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //fetch all locations
    const fetchLocation = async (settingslocation) => {
        try {
            var response = await axios.get(SERVICE.BUSINESS_LOCATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });

            var setngresult = response.data.busilocations.filter((item) => {
                if (item.name == settingslocation.businessname) {
                    return item
                }
            })
            setBusinesslocation(setngresult);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages)
        }
    }

    // get all product data
    const fetchHandleProduct = async () => {
        try {
            var response = await axios.get(SERVICE.PRODUCT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setProductsList(
                response.data.products?.map((d) => ({
                    ...d,
                    label: d.prodname + "-" + d.prodcode,
                    value: d.prodname,
                }))
            );
            setProducts(response.data.products);
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages)
            alertOpen();
        }
    }

    // get all discount 
    const fetchDiscounts = async () => {
        try {
            let res = await axios.get(SERVICE.DISCOUNT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDiscounts(res.data.discounts);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Delete
    const deleteQuote = async () => {
        try {
            let response = await axios.delete(`${SERVICE.QUOTATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
        } catch (err) {
            const messages = err.response.data.message;
            console.log(messages);
        }
    };

    // get settings data
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSetngs(response.data.settingid);
            await fetchLocation(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // fetch pos for recent transction
    const fetchpos = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPos(req.data.pos1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    let getTaxRateData = [];
    // product onchange table data calculation
    const fetchDataProd = (e) => {

        //get particular discount for prticular product
        let discAmtData = discounts?.filter((item) => {
            if (e.prodcode == item.productid) {
                return item
            }
        })

         getTaxRateData = taxrates?.filter((data) => {
            if (e.applicabletax == data.taxname) {
                return data
            }
        })

        //is product already added include quantity +1
        let isAlreadyAdded = false;
        let addQuantity = tableData.map((item) => {
            if (e.prodcode == item.productid) {
                isAlreadyAdded = true
                return { ...item, quantity: item.quantity + 1, subtotal: ((Number(item.sellingincludevalue) - Number(item.discountamt)) * Number(item.quantity + 1)) }
            } else {
                return item
            }
        })

        if (isAlreadyAdded) {
            setTableData(addQuantity)
        } else {
            setTableData((tableData) => {
                return [...tableData, {
                    ...productInputs,
                    productid: e.prodcode,
                    productname:
                        e.prodname,
                    quantity: 1,
                    batch:e.batch,
                    sellingpriceunitcost:e.sellingpriceunitcost,
                    purchasevalue:e.selltaxtype == 'Inclusive' ? e.pruchaseincludetax : e.purchaseexcludetax,
                    category: e.category,
                    molecule: e.molecules,
                    manufacture: e.manufacture,
                    schedule: e.schedule,
                    sellingpricetax: e.selltaxtype,
                    sellingexcludevalue: e.selltaxtype == 'Exclusive' ? e.sellingpriceunitcost : ((Number(e.sellingpriceunitcost) * (Number(getTaxRateData[0]?.taxtotal) / 100) - Number(e.sellingpriceunitcost))),
                    sellingincludevalue: e.selltaxtype == 'Inclusive' ? e.sellingpriceunitcost : ((Number(e.sellingpriceunitcost) * (Number(getTaxRateData[0]?.taxtotal) / 100)) + Number(e.sellingpriceunitcost)),
                    subtax: getTaxRateData,
                    applicabletax: e.hsncode || e.applicabletax,
                    discountamt: discAmtData[0] ? discAmtData[0].discountamt : 0,
                    taxtareval: getTaxRateData[0] ? getTaxRateData[0]?.taxtotal : 1,
                    subtotal: (Number(1) * (Number(e.selltaxtype == 'Inclusive' ? Number(e.sellingpriceunitcost) : ((Number(e.sellingpriceunitcost) * (Number(getTaxRateData[0]?.taxtotal) / 100)) + Number(e.sellingpriceunitcost))) - Number(discAmtData[0] ? Number(discAmtData[0].discountamt) : Number(0)))),
                    discountvalue: e.selltaxtype == 'Inclusive' ? ((Number(((Number(e.sellingpriceunitcost) * (Number(getTaxRateData[0]?.taxtotal) / 100)) + Number(e.sellingpriceunitcost))) * (Number(getTaxRateData[0]?.taxtotal) / 100) - Number(((Number(e.sellingpriceunitcost) * (Number(getTaxRateData[0]?.taxtotal) / 100)) + Number(e.sellingpriceunitcost)))) * (Number(getTaxRateData[0]?.taxtotal) / 100)) : ((((Number(e.sellingpriceunitcost) * (Number(getTaxRateData[0]?.taxtotal) / 100) - Number(e.sellingpriceunitcost))) * (Number(getTaxRateData[0]?.taxtotal) / 100)))
                }]
            });

        }

        
    }

    useEffect(
        () => {
            grandtotalCalc();
        }, [tableData, isQuotation.gdiscountvalue, isQuotation.granddistype]
    )

    //phone number onchange didn't accept more than 10 characters
    const handleChangeNumber = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            alertOpen();
            let num = e.slice(0, 10);
            setIsQuotation({ ...isQuotation, contactnumber: num })
        }
    }

    // grand total calculation
    const grandtotalCalc = () => {

        let totalgrandValue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                totalgrandValue += Number(value.subtotal)
            })
            if (isQuotation.granddistype == "Fixed" || isQuotation.granddistype == "Amount") {
                setIsQuotation(
                    {
                        ...isQuotation, gdiscountamtvalue: isQuotation.gdiscountvalue, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue) - Number(isQuotation.gdiscountvalue))
                    })
            }
            else if (isQuotation.granddistype == "Percentage") {
                let percentCalc = Number(totalgrandValue) * (Number(isQuotation.gdiscountvalue) / 100);
                setIsQuotation(
                    {
                        ...isQuotation, gdiscountamtvalue: percentCalc, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue) - percentCalc)
                    });
            }
            else {
                setIsQuotation(
                    {
                        ...isQuotation, grandtotal: Number(totalgrandValue), aftergranddisctotal: (Number(totalgrandValue))
                    }
                )
            }
        }
    }
    // total qunatity calculation
    const totalQuantityCalc = () => {
        let totalquanvalue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                totalquanvalue += Number(value.quantity)
            })
            return totalquanvalue.toFixed(0);
        }
    }

    //total taxvalue calc for invoice
    const totalTaxValCal = () => {
        let totaltaxvalue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                totaltaxvalue += Math.abs(((value.taxtareval/100) * value.sellingexcludevalue)) * Number(value.quantity)
            })
            return totaltaxvalue;
        }
    }
    let tempTotal = 0;

    // delete table data after data fetchparticular row
    const deleteRow = (i, e) => {
        setTableData(tableData.filter((e, item) => item !== i));
        tableData.splice(i, 1);
        if (tableData.length > 0) {
            tempTotal = 0
        }
    }

    // exit screen and re exit screen
    const fulscren = document.getElementById("fullScreen")

    function openFullscreen() {
        if (fulscren.requestFullscreen) {
            fulscren.requestFullscreen();
        } else if (fulscren.webkitRequestFullscreen) {
            fulscren.webkitRequestFullscreen();
        } else if (fulscren.msRequestFullscreen) {
            fulscren.msRequestFullscreen();
        }
    }

    // paynow button popup
    const [isPay, setIsPay] = useState(false);
    const handleClickOpenpay = () => {
        {
            if (tableData.length == 0) {
                setShowAlert("Please select any one of product details!");
                alertOpen();
            } else {
                setIsPay(true);
            }
        };
    }
    const handleClosepay = () => { setIsPay(false); };

    const handleSubmitclear = (e) => {
        backPage('/sell/quotation/list');
    };

    function totalNetCostCalcinclusive() {
        let totalvalue = 0;
        if (tableData?.length > 0) {
            tableData?.forEach((value) => {
                // if (value.sellingpricetax == "Inclusive")
                 totalvalue += Number(value.subtotal)
                // return totalvalue
            })
            return Number(totalvalue.toFixed(0));
        }
    }

    let cgst = 0;
    let gst = 0;
    let igst = 0;
    let CGST = 0;
    let GST = 0;
    let IGST = 0;

    tableData?.map((item) => {
       
        item?.subtax?.filter((data) => {
         
            cgst += Number(data?.taxratecgst);
            gst += Number(data?.taxrategst);
            igst += Number(data?.taxrateigst);
        })
    })

    CGST = Number(totalNetCostCalcinclusive()) * (cgst ? (cgst / 100) : 0).toFixed(1);
    GST = Number(totalNetCostCalcinclusive()) * (gst ? (gst / 100) : 0).toFixed(1);
    IGST = Number(totalNetCostCalcinclusive()) * (gst ? (igst / 100) : 0).toFixed(1);

    useEffect(() => {
        
        fetchpos();
    },[]);

    useEffect(
        ()=>{
            fetchQuotation();
        },[id]
    )

    useEffect(() => {
        fetchSettings();
        fetchDiscounts();
        taxrateRequest();
        fetchHandleProduct();
    }, []);

    // pos/draft/quotation auto id
    let newvalpos = setngs ? setngs.salesku + "0001" : "POS0001";
    let newvalquot = setngs ? setngs.quotationsku + "0001" : "QUO0001";

    // Auto id for pos
    {
        pos && (
            pos.map(
                () => {
                    let strings = setngs ? setngs.salesku : "POS";
                    let refNo = pos[pos.length - 1].referenceno;
                    let digits = (pos.length + 1).toString();
                    const stringLength = refNo.length;
                    let lastChar = refNo.charAt(stringLength - 1);
                    let getlastBeforeChar = refNo.charAt(stringLength - 2);
                    let getlastThreeChar = refNo.charAt(stringLength - 3);
                    let lastBeforeChar = refNo.slice(-2);
                    let lastThreeChar = refNo.slice(-3);
                    let lastDigit = refNo.slice(-4);
                    let refNOINC = parseInt(lastChar) + 1
                    let refLstTwo = parseInt(lastBeforeChar) + 1;
                    let refLstThree = parseInt(lastThreeChar) + 1;
                    let refLstDigit = parseInt(lastDigit) + 1;
                    if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                        refNOINC = ("000" + refNOINC).substr(-4);
                        newvalpos = strings + refNOINC;
                    } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                        refNOINC = ("00" + refLstTwo).substr(-4);
                        newvalpos = strings + refNOINC;
                    } else if (digits.length < 4 && getlastThreeChar > 0) {
                        refNOINC = ("0" + refLstThree).substr(-4);
                        newvalpos = strings + refNOINC;
                    } else {
                        refNOINC = (refLstDigit).substr(-4);
                        newvalpos = strings + refNOINC;
                    }
                }))
    }

    // save pos data to db cash
    const sendRequest = async () => {

        // reduce Current Stock in product
        tableData.map((item, index) => {
            products.forEach((data, i) => {
                if (item.productid == data.prodcode) {
                    axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                        currentstock: Number(data.currentstock) - Number(item.quantity),
                    });
                }
            })
        })

        var futureDate = new Date(new Date().setDate(new Date(isQuotation.date).getDate() + isQuotation.creditdays));
        try {
            let PRODUCT_REQ = await axios.post(SERVICE.POS_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newvalpos),
                customer: String(isQuotation.customer),
                location: String(isQuotation.location),
                contactnumber: Number(isQuotation.contactnumber),
                mobileno:Number(isQuotation.mobileno),
                date: String(isQuotation.date),
                counter: String(isUserRoleAccess.counter),
                dueamount: Number((Number(isQuotation.aftergranddisctotal) - Number(isQuotation.amountgain)) > 0 ? (Number(isQuotation.aftergranddisctotal) - Number(isQuotation.amountgain)) : (Number(isQuotation.aftergranddisctotal) == Number(isQuotation.amountgain)) ? 0 : 0),
                customerpaidamount: Number(isQuotation.balance == 0 ? Number(isQuotation.amountgain) : (Number(isQuotation.aftergranddisctotal) - Number(isQuotation.balance))),
                duedate: String(isQuotation.creditdays > 0 ? moment(new Date()).format("DD-MM-YYYY") : moment(futureDate).utc().format('DD-MM-YYYY')),
                creditlimit: Number(isQuotation.creditlimit),
                ledgerbalance: Number(isQuotation.ledgerbalance),
                creditdays: Number(isQuotation.creditdays),
                goods: [...tableData],
                totalitems: Number(tableData.length),
                taxcgst:Number(CGST ? CGST : 0),
                taxigst:Number(IGST ? IGST : 0),
                taxsgst:Number(GST ? GST : 0),
                totalproducts:Number(totalQuantityCalc()),
                grandtotal: Number(isQuotation.grandtotal),
                ordertax: Number(isQuotation.ordertax),
                shippingtax: Number(isQuotation.shippingtax),
                packcharge: Number(isQuotation.packcharge),
                granddistype: String(isQuotation.granddistype),
                gdiscountvalue: Number(isQuotation.gdiscountvalue),
                gdiscountamtvalue: Number(isQuotation.gdiscountamtvalue),
                aftergranddisctotal: Number(isQuotation.aftergranddisctotal),
                roundof: Number(isQuotation.aftergranddisctotal),
                amountgain: Number(isQuotation.amountgain),
                balance: Number((Number(isQuotation.aftergranddisctotal) < Number(isQuotation.amountgain))  ? (Number(isQuotation.amountgain) - Number(isQuotation.aftergranddisctotal)) : (Number(isQuotation.aftergranddisctotal) == Number(isQuotation.amountgain)) ? 0 : (Number(isQuotation.aftergranddisctotal) > Number(isQuotation.amountgain)) ? (Number(isQuotation.aftergranddisctotal) - Number(isQuotation.amountgain)) : 0),
                userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),
                contactid: String(isQuotation.contactid),
                doctorname: String(isQuotation.doctorname),
                address: String(isQuotation.address),
                diagnosis: String(isQuotation.diagnosis),
                paymentmethod: String("Cash"),
                customerledgerid: String(isQuotation.customerledgerid),
                activate:Boolean(isQuotation.activate),
            });
            handleprint();
            deleteQuote();
            backPage('/sell/quotation/list');
            
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            alertOpen();
        }
    };

    const cardCustomerLedgerbalance = () => {
        if (isQuotation.customer == "Walk-in-customer") {
            setShowAlert("Walk-in-Customer didn't give credit! Please choose cash!");
            alertOpen();
        } else if (tableData.length == 0) {
            setShowAlert("Please select any one of product details!");
            alertOpen();
        } else {
            if ((isQuotation.creditlimit <= isQuotation.ledgerbalance)) {
                setShowAlert("Check ledger balance!")
                alertOpen();
            } else {
                handleCreditSubmit();
            } 
        }
    }

    // save pos card to db for ledger balance calculation
    const sendRequestCredit = async () => {

        // reduce Current Stock in product
        tableData.map((item, index) => {
            products.forEach((data, i) => {
                if (item.productid == data.prodcode) {
                    axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                        currentstock: Number(data.currentstock) - Number(item.quantity),
                    });
                }
            })
        })

        //update ledger balance in customer
        axios.put(`${SERVICE.CUSTOMER_SINGLE}/${isQuotation.customerledgerid}`, {
            ledgerbalance: Number(isQuotation.ledgerbalance) + Number(isQuotation.aftergranddisctotal.toFixed(0))
        })


        try {
            let PRODUCT_REQ = await axios.post(SERVICE.POS_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(newvalpos),
                customer: String(isQuotation.customer),
                location: String(isQuotation.location),
                contactnumber: Number(isQuotation.contactnumber),
                mobileno:Number(isQuotation.mobile),
                date: String(isQuotation.date),
                counter: String(isUserRoleAccess.counter),
                dueamount: Number(0),
                customerpaidamount: Number(isQuotation.aftergranddisctotal),
                duedate: String(0),
                creditlimit: Number(isQuotation.creditlimit),
                ledgerbalance: Number(isQuotation.ledgerbalance),
                creditdays: Number(isQuotation.creditdays),
                goods: [...tableData],
                totalitems: Number(tableData.length),
                taxcgst:Number(CGST ? CGST : 0),
                taxigst:Number(IGST ? IGST : 0),
                taxsgst:Number(GST ? GST : 0),
                totalproducts:Number(totalQuantityCalc()),
                grandtotal: Number(isQuotation.grandtotal),
                ordertax: Number(isQuotation.ordertax),
                shippingtax: Number(isQuotation.shippingtax),
                packcharge: Number(isQuotation.packcharge),
                granddistype: String(isQuotation.granddistype),
                gdiscountvalue: Number(isQuotation.gdiscountvalue),
                gdiscountamtvalue: Number(isQuotation.gdiscountamtvalue),
                aftergranddisctotal: Number(isQuotation.aftergranddisctotal),
                roundof: Number(isQuotation.aftergranddisctotal.toFixed(0)),
                amountgain: Number(isQuotation.aftergranddisctotal),
                balance: Number(isQuotation.balance),
                userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),
                contactid: String(isQuotation.contactid),
                doctorname: String(isQuotation.doctorname),
                address: String(isQuotation.address),
                diagnosis: String(isQuotation.diagnosis),
                paymentmethod: String("Credit"),
                customerledgerid: String(isQuotation.customerledgerid),
                activate:Boolean(isQuotation.activate),
            });
            handleprint();
            deleteQuote();
            backPage('/sell/quotation/list');
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            alertOpen();
        }
    }


    // store draft data
    const sendQuot = async () => {

        try {
            let quotationreq = await axios.put(`${SERVICE.QUOTATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(isQuotation.referenceno),
                customer: String(isQuotation.customer),
                location: String(isQuotation.location),
                contactnumber: Number(isQuotation.contactnumber),
                mobileno:Number(isQuotation.mobileno),
                date: String(isQuotation.date),
                counter: String(isQuotation.counter),
                dueamount: Number(isQuotation.dueamount),
                customerpaidamount: Number(isQuotation.customerpaidamount),
                duedate: String(isQuotation.duedate),
                creditlimit: Number(isQuotation.creditlimit),
                ledgerbalance: Number(isQuotation.ledgerbalance),
                creditdays: Number(isQuotation.creditdays),
                goods: [...tableData],
                totalitems: Number(tableData.length),
                taxcgst:Number(CGST ? CGST : 0),
                taxigst:Number(IGST ? IGST : 0),
                taxsgst:Number(GST ? GST : 0),
                totalproducts:Number(totalQuantityCalc()),
                grandtotal: Number(isQuotation.grandtotal),
                ordertax: Number(isQuotation.ordertax),
                shippingtax: Number(isQuotation.shippingtax),
                packcharge: Number(isQuotation.packcharge),
                granddistype: String(isQuotation.granddistype),
                gdiscountvalue: Number(isQuotation.gdiscountvalue),
                gdiscountamtvalue: Number(isQuotation.gdiscountamtvalue),
                aftergranddisctotal: Number(isQuotation.aftergranddisctotal),
                roundof: Number(isQuotation.aftergranddisctotal),
                amountgain: Number(isQuotation.amountgain),
                balance: Number(isQuotation.balance),
                userbyadd: String(isUserRoleAccess.first + ' ' + isUserRoleAccess.last),
                contactid: String(isQuotation.contactid),
                doctorname: String(isQuotation.doctorname),
                address: String(isQuotation.address),
                diagnosis: String(isQuotation.diagnosis),
                paymentmethod: String("None"),
                customerledgerid: String(isQuotation.customerledgerid),
                activate:Boolean(isQuotation.activate),
            });

            toast.success(quotationreq.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/sell/quotation/list');
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const handlePosSubmit = (e) => {
        e.preventDefault();
        setIsQuotation({ ...isQuotation, referenceno: newvalpos })

        if (isQuotation.amountgain == "" || isQuotation.amountgain == 0) {
            setisAmountgain("please enter amount!");
        }else{
            setisAmountgain("");
            sendRequest();

        }
    }
    const handleCreditSubmit = () => {
        setIsQuotation({ ...isQuotation, referenceno: newvalpos });
        sendRequestCredit();
    }

    const handleSubmitquotation = (e) => {
        setIsQuotation({ ...isQuotation, referenceno: newvalquot })
        e.preventDefault();
        if (tableData.length == 0) {
            setShowAlert("Please select any one of product details!");
            alertOpen();
        }else{
            if (isQuotation.customer == "" || isQuotation.customer == undefined) {
                setIsQuotation({ ...isQuotation, customer: 'Walk-in-customer' });
                sendQuot();
            }else{
                sendQuot();
            }
        }
    };

    return (
        <Box id="fullScreen"
            sx={{
                backgroundColor: 'white',
                // color: "red",
                estimateition: "relative",
                overflow: "hidden",
            }}
        >
            <Headtitle title={'Pos Add'} />
            <form >
                {/* Navbar Start */}
                <Box sx={{ padding: "5px", backgroundColor: "#f0f2ff" }}>
                    <Grid container spacing={1}>
                        <Grid item lg={1} md={1} sm={2} xs={12}>
                            <Box sx={{ float: "left" }}>
                                <Link to="/">
                                    <img src={Mainlogo} alt="logo" style={{ width: '50px', height: '50px' }}></img>
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={2} sm={8} xs={11} sx={{ marginTop: "17px" }}>
                            <InputLabel sx={{ marginTop: '-21px' }}>Customer</InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: "38px" }}>
                                    <SearchOutlinedIcon />
                                </Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        type="text"
                                        value={isQuotation.customer}
                                        sx={userStyle.estimateNavbarInput}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={1} sm={1} xs={1} sx={{ marginTop: "17px" }}>
                            <Button onClick={(e) => { openFullscreen() }}><FullscreenOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{ padding: "5px", margin: '0px' }}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Date </InputLabel>
                            <FormControl size="small" fullWidth sx={userStyle.estimateDateTime}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DateTimePicker
                                        renderInput={(props) => <TextField size="small" {...props} />}
                                        size='small'
                                        sx={userStyle.estimateNavbarInput}
                                        value={isQuotation.date}
                                        onChange={(newValue) => {
                                            setIsQuotation({...isQuotation, date:newValue});
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={1} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Counter</InputLabel>
                            <FormControl
                                size="small"
                                fullWidth
                                sx={{ display: "flex" }}
                            >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={isUserRoleAccess.counter}
                                    type="text"
                                    sx={userStyle.estimateNavbarInput}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Credit Limit </InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: '34px' }}>
                                    <MoneyOutlinedIcon sx={{ marginTop: '-1px' }} />
                                </Grid>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{ display: "flex" }}
                                >
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={isQuotation.creditlimit}
                                        type="text"
                                        sx={userStyle.estimateNavbarInput}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12} sx={{}}>
                            <InputLabel id="demo-select-small" sx={{ marginTop: '-3px' }}> Ledger Balance </InputLabel>
                            <Grid sx={{ display: "flex" }}>
                                <Grid sx={userStyle.spanIcons} style={{ height: '34px' }}>
                                    <PersonOutlineOutlinedIcon sx={{ marginTop: '-1px' }} />
                                </Grid>
                                <FormControl
                                    size="small"
                                    fullWidth
                                    sx={{ display: "flex" }}
                                >
                                    <OutlinedInput
                                        type="text"
                                        value={isQuotation.ledgerbalance}
                                        sx={userStyle.estimateNavbarInput}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {/* Navbar Ends */}
                <Grid container sx={{ backgroundColor: "#f0f2ff", }} >
                    <Grid item xs={12} sm={12} md={8} lg={8} sx={{ paddingRight: '3px', backgroundColor: '#fff' }} >
                        <br />
                        <Grid container spacing={3}>
                            <Grid item md={1} sm={6} xs={12} sx={{ marginLeft: '25px' }}></Grid>
                            <Grid item md={9} sm={6} xs={12} sx={{ marginLeft: '25px' }}>
                                <Grid sx={{ display: "flex" }}>
                                    <Grid sx={userStyle.spanIcons} style={{ height: "38px" }}>
                                        <SearchOutlinedIcon />
                                    </Grid>
                                    <FormControl size="small" fullWidth>
                                        <Selects
                                            options={productsList}
                                            onChange={(e) => {
                                                fetchDataProd(e);
                                                totalQuantityCalc();
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item md={1} sm={6} xs={12} sx={{ marginLeft: '25px' }}></Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                {/* Table start */}
                                <TableContainer
                                    sx={{ paddingLeft: 1, height: '478px' }}
                                >
                                    <Table
                                        aria-label="customized table" padding='none'>
                                        <TableHead >
                                            <TableRow>
                                                <TableCell sx={userStyle.tableHead1} style={{ marginLeft: '5px' }}>  {" "}  Product Name  </TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Qty</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Unit Cost ( Ex. Tax )</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Tax</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Unit Cost ( Inc. Tax )</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Discount value</TableCell>
                                                <TableCell sx={userStyle.tableHead1}>Subtotal </TableCell>
                                                <TableCell sx={userStyle.tableHead1}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {tableData.length > 0 &&
                                                tableData.map((data, i) => {
                                                    return (
                                                        <>
                                                            <TableRow sx={{ height: '16px' }}>
                                                                <TableCell key={i}>{data?.productname}</TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {data?.quantity}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {Math.abs(data?.sellingexcludevalue)}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {data?.taxtareval}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography>
                                                                        {data?.sellingincludevalue}
                                                                    </Typography>
                                                                </TableCell>
                                                                <TableCell >
                                                                    <Grid container>
                                                                        <Grid item lg={8} md={9} sm={9} xs={9}>
                                                                            <Typography>
                                                                                {data?.discountamt}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Typography variant='subtitle1'>{data?.subtotal}</Typography>
                                                                </TableCell>
                                                                <TableCell sx={{ color: 'red', fontWeight: '900', cursor: 'pointer', fontSize: '15px !important' }}><AiOutlineClose onClick={(e) => { deleteRow(i, e); }} /></TableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {/* Table Ends */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '-24px' }}>
                                <Grid container spacing={1}>
                                    <Grid item md={3} sm={4} xs={12}>
                                        <Typography sx={{ marginLeft: '15px' }}>
                                            <b> Total Items :</b>{tableData.length}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={4} xs={12}>
                                    <InputLabel> Discount Type </InputLabel>
                                        <FormControl size="small" fullWidth>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                fullWidth
                                                label="Discount Type"
                                                value={isQuotation.granddistype}
                                                onChange={(e) => {
                                                    setIsQuotation({ ...isQuotation, granddistype: e.target.value })
                                                }}
                                            >
                                                <MenuItem value="None">None</MenuItem>
                                                <MenuItem value="Fixed">Fixed</MenuItem>
                                                <MenuItem value="Amount">Amount</MenuItem>
                                                <MenuItem value="Percentage">Percentage</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={2} sm={4} xs={12}>
                                        <InputLabel>Discount Amount</InputLabel>
                                        <TextField
                                            size="small"
                                            value={isQuotation.gdiscountvalue}
                                            onChange={(e) => {
                                                setIsQuotation({ ...isQuotation, gdiscountvalue: e.target.value });
                                            }}
                                            type="number"
                                            sx={userStyle.input}
                                        />
                                    </Grid>
                                    <Grid item md={5} sm={4} xs={12} sx={{ paddingLeft: '4px', paddingRight: '1px', marginTop: '-4px' }}>
                                        <Button fullWidth variant="contained" sx={{ marginTop: "5px", }}>
                                            <b>GRAND TOTAL :</b>&ensp;{isQuotation.grandtotal}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={2} sm={6} xs={12}>
                                        <Typography sx={{ marginTop: "5px", marginLeft: '15px' }}>
                                            <b>CGST:</b>{CGST ? CGST : 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={12}>
                                        <Typography sx={{ marginTop: "5px", marginLeft: '15px' }}>
                                            <b>SGST:</b>{GST ? GST : 0}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={2} sm={6} xs={12}>
                                        <Typography sx={{ marginTop: "5px", marginLeft: '15px' }}>
                                            <b>IGST:</b>{IGST ? IGST : 0}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} sx={{ p: 1, backgroundColor: '#fff', }}>
                        <Grid container  >
                            <Typography sx={userStyle.HeaderText}>Enter Details</Typography>
                            <Grid container sx={{ padding: '10px' }} spacing={3}>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <Grid container spacing={3}  >
                                        <Grid item xs={12} sm={12} md={12} lg={12} >
                                            <Grid container>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <InputLabel id="demo-select-small"> Dr Name </InputLabel>
                                                    <Grid sx={{ display: "flex" }}>
                                                        <Grid sx={userStyle.spanIcons}>
                                                            <MoneyOutlinedIcon />
                                                        </Grid>
                                                        <FormControl
                                                            size="small"
                                                            fullWidth
                                                            sx={{ display: "flex" }}
                                                        >
                                                            <OutlinedInput
                                                                id="component-outlined"
                                                                type="text"
                                                                value={isQuotation.doctorname}
                                                                onChange={(e) => { setIsQuotation({ ...isQuotation, doctorname: e.target.value,}) }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <br />
                                                </Grid>
                                                <Grid item md={12} sm={12} xs={12}>
                                                    <InputLabel id="demo-select-small">
                                                        Patient Name /Customer
                                                    </InputLabel>
                                                    <Grid sx={{ display: "flex" }}>
                                                        <Grid sx={userStyle.spanIcons}>
                                                            <MoneyOutlinedIcon />
                                                        </Grid>
                                                        <FormControl
                                                            size="small"
                                                            fullWidth
                                                            sx={{ display: "flex" }}
                                                        >
                                                            <OutlinedInput
                                                                id="component-outlined"
                                                                type="text"
                                                                value={isQuotation.customer}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <br />
                                                </Grid>
                                            </Grid>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Mobile
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ display: "flex" }}
                                                    >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="number"
                                                            value={isQuotation.contactnumber}
                                                            onChange={(e) => { setIsQuotation({ ...isQuotation, contactnumber: e.target.value }); handleChangeNumber(e.target.value); }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Address
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ display: "flex" }}
                                                    >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="text"
                                                            value={isQuotation.address}
                                                            onChange={(e) => { setIsQuotation({ ...isQuotation, address: e.target.value }) }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <br />
                                            <Grid item md={12} sm={12} xs={12}>
                                                <InputLabel id="demo-select-small">
                                                    Diagnosis
                                                </InputLabel>
                                                <Grid sx={{ display: "flex" }}>
                                                    <Grid sx={userStyle.spanIcons}>
                                                        <MoneyOutlinedIcon />
                                                    </Grid>
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                        sx={{ display: "flex" }}
                                                    >
                                                        <OutlinedInput
                                                            id="component-outlined"
                                                            type="text"
                                                            value={isQuotation.diagnosis}
                                                            onChange={(e) => { setIsQuotation({ ...isQuotation, diagnosis: e.target.value }) }}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <Grid container sx={userStyle.btnGrid}>
                    <Grid item md={8} sm={8} xs={12} sx={{ display: "flex", color: 'black' }}>
                    <Button disableRipple sx={userStyle.btnPause} type="submit" onClick={handleSubmitquotation}>
                            <EditOutlinedIcon style={{ fontSize: "large" }} />
                            &ensp;Quotation
                        </Button>
                        <Button disableRipple sx={userStyle.btnCash}
                            onClick={handleClickOpenpay}>
                            <FaMoneyBillAlt />
                            &ensp;Cash
                        </Button>
                        <Button disableRipple sx={userStyle.btnCancel} onClick={handleSubmitclear}>
                            <FaRegWindowClose />
                            &ensp;Cancel
                        </Button>
                        <Button disableRipple sx={userStyle.btnCard} onClick={cardCustomerLedgerbalance}>
                            <FaRegWindowClose />
                            &ensp;Credit
                        </Button>
                        <Typography value={isQuotation.totalbillamt}
                            sx={{ marginLeft: '15px', color: 'grey', fontSize: "20px" }}>
                            <b>Total:</b> <span style={{ color: 'green' }}>{(isQuotation.aftergranddisctotal)}</span>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
            {/* Cash dialog box */}
            <Dialog
                open={isPay}
                onClose={handleClosepay}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle>
                    <Typography sx={userStyle.HeaderText}>Enter Cash Details</Typography>
                </DialogTitle>
                <DialogContent sx={{ padding: '0px' }}>
                    <Grid container sx={{ padding: '10px' }} spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Grid container spacing={3}  >
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <Grid container>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <InputLabel id="demo-select-small"> Amount </InputLabel>
                                            <Grid sx={{ display: "flex" }}>
                                                <Grid sx={userStyle.spanIcons}>
                                                    <MoneyOutlinedIcon />
                                                </Grid>
                                                <FormControl
                                                    size="small"
                                                    fullWidth
                                                    sx={{ display: "flex" }}
                                                >
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        type="text"
                                                        value={(isQuotation.aftergranddisctotal)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <br />
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <p style={{color:'red'}}>{isAmountgain}</p>
                                            <InputLabel id="demo-select-small">
                                                Amount Gain
                                            </InputLabel>
                                            <Grid sx={{ display: "flex" }}>
                                                <Grid sx={userStyle.spanIcons}>
                                                    <MoneyOutlinedIcon />
                                                </Grid>
                                                <FormControl
                                                    size="small"
                                                    fullWidth
                                                    sx={{ display: "flex" }}
                                                >
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        type="text"
                                                        value={isQuotation.amountgain}
                                                        onChange={(e) => { setIsQuotation({ ...isQuotation, amountgain: e.target.value, balance: (Number(isQuotation.aftergranddisctotal) - Number(e.target.value)) }) }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <br />
                                        </Grid>
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <InputLabel id="demo-select-small">
                                            Balance
                                        </InputLabel>
                                        <Grid sx={{ display: "flex" }}>
                                            <Grid sx={userStyle.spanIcons}>
                                                <MoneyOutlinedIcon />
                                            </Grid>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                                sx={{ display: "flex" }}
                                            >
                                                <OutlinedInput
                                                    id="component-outlined"
                                                    type="text"
                                                    value={Math.abs(isQuotation.balance)}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ paddingLeft: '20px' }}>
                            <Card sx={{ padding: '30px' }}>
                                <TableContainer sx={{ boxShadow: '0 0 15px -2px #444444' }}>
                                    <Table>
                                        <TableBody>
                                            <StyledTableRow>
                                                <StyledTableCell><b>Total Products :</b></StyledTableCell>
                                                <StyledTableCell>{totalQuantityCalc()}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell><b>OrderTax :</b></StyledTableCell>
                                                <StyledTableCell>Rs.{isQuotation.ordertax}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell> <b>Shipping :</b></StyledTableCell>
                                                <StyledTableCell>Rs.{isQuotation.shippingtax}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell><b>Discount :</b></StyledTableCell>
                                                <StyledTableCell>{isQuotation.gdiscountamtvalue}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell> <b>Packing Charge :</b></StyledTableCell>
                                                <StyledTableCell>Rs.{isQuotation.packcharge}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell> <b>Round off :</b></StyledTableCell>
                                                <StyledTableCell>{(isQuotation.aftergranddisctotal)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow>
                                                <StyledTableCell><b>Grand Total :</b></StyledTableCell>
                                                <StyledTableCell>{(isQuotation.aftergranddisctotal)}</StyledTableCell>
                                            </StyledTableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosepay} variant="outlined">Cancel</Button>
                    <Button autoFocus variant="contained" color='primary' type="submit" onClick={(e) => { handlePosSubmit(e) }}> Print </Button>
                </DialogActions>
            </Dialog>
            {/* Cash Dialog Ends */}
            <Dialog
                open={isErrorOpen}
                onClose={alertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <Typography>{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={alertClose}>ok</Button>
                </DialogActions>
            </Dialog>
            <>
                <Box sx={userStyle.printcls} ref={componentRef}>
                    <Box sx={{ textAlign: 'center', width: '400px' }}>
                        {/* <img> </img> */}
                        <Typography>{setngs?.businessname}</Typography>
                        <Typography>{setngs?.buniessaddress}</Typography>
                        <Typography>{businesslocation[0]?.zipcde}</Typography>
                        <Typography>CIN : {setngs ? setngs.ciono : ""}</Typography>
                        <Typography>GST NO : {setngs ? setngs.gstno : ""}</Typography>
                        <Typography variant="h6">INVOICE</Typography>
                        <Box sx={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography name="print" align='left'><b>Invoice No :</b>{newvalpos}</Typography>
                                <Typography name="print" align='left'><b>Counter :</b> {isUserRoleAccess.counter}</Typography>
                                <Typography name="print" align='left'><b>Customer ID :</b> {isQuotation.contactid}</Typography>
                                <Typography name="print" align='left'><b>Mail ID :</b> {businesslocation[0]?.email}</Typography>
                            </Grid>
                            <Grid item xs={6} align="right" >
                                {/* <Typography sx={userStyle.printcls} name="print">STAR READYMADES</Typography>
                        <Box component="img" name="print" src={logo}></Box> */}
                                {/* <Typography name="print" >{currentdate}&nbsp;&nbsp; {currenttime}</Typography> */}
                                <Typography name="print" ><b>Cashier :</b>{isUserRoleAccess.username}</Typography>
                                <Typography name="print" ><b>Name : </b>{isUserRoleAccess.first + ' ' + isUserRoleAccess.last}</Typography>
                                <Typography name="print" ><b>Phone : </b>{businesslocation[0]?.whatsappno}</Typography>
                            </Grid>
                        </Grid>
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box>
                        <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
                            <Table aria-label="simple table" >
                                <TableHead >
                                    <TableRow sx={{ borderBottom: 'none' }}>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>ITEM</TableCell>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>QUANTITY</TableCell>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>PRICE</TableCell>
                                        <TableCell sx={{ fontSize: '13px', fontWeight: "1000", padding: '14px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}>AMOUNT</TableCell>
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {tableData.length > 0 &&
                                        tableData.map((data, i) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ fontSize: '14px', fontWeight: "1000", padding: '5px', borderBottom: "none" }} key={i}>{data?.productid} <br />

                                                        </TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', fontWeight: "1000", padding: '5px', borderBottom: "none" }}>
                                                            <Typography>
                                                                {data?.quantity}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', fontWeight: "1000", padding: '5px', borderBottom: "none" }}>
                                                            <Typography>
                                                                {Math.abs(data?.sellingincludevalue)}
                                                            </Typography>
                                                        </TableCell>

                                                        <TableCell align="center" sx={{ fontSize: '12px', fontWeight: "1000", padding: '5px', borderBottom: "none" }}>
                                                            <Typography variant='subtitle1'>{Number(data?.subtotal).toFixed(2)}</Typography>
                                                        </TableCell>

                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontSize: '14px', fontWeight: "1000", padding: '0px', borderBottom: "none" }} colSpan={4}>{data?.productname}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell sx={{ fontSize: '14px', fontWeight: "1000", padding: '0px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }} colSpan={2}> {"HSN CODE :"}{data?.applicabletax}</TableCell>
                                                        <TableCell sx={{ fontSize: '14px', fontWeight: "1000", padding: '0px', borderTop: '0px', borderLeft: '0px', borderRight: '0px', borderBottomWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }} colSpan={2}> {"Taxable value : "}{Math.abs(((data?.sellingexcludevalue) * data?.quantity))}</TableCell>
                                                    </TableRow>

                                                </>
                                            );
                                        })}

                                </TableBody>

                            </Table>
                        </TableContainer>
                        <br />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography name="print" ><b>GRAND TOTAL :</b></Typography>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Typography name="print" ><b>{Number(isQuotation.aftergranddisctotal)}</b> </Typography>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography name="print" ><b>NET TOTAL :</b></Typography>
                                <Typography name="print" ><b>NET TAX :</b></Typography>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Typography name="print" ><b>{Number(isQuotation.aftergranddisctotal)}</b> </Typography>
                                <Typography name="print" ><b>{Number(totalTaxValCal()).toFixed(2)}</b> </Typography>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />
                        <Typography variant="h6">TAX DETAILS</Typography>
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />
                        <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
                            <Table aria-label="simple table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>HSN/Tax</TableCell>
                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>TAX DESC</TableCell>
                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>Taxable VALUE</TableCell>
                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>TAX</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {tableData.length > 0 &&
                                        tableData.map((data, i) => {
                                            return (
                                                <>
                                                    <TableRow>
                                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>{data?.applicabletax}</TableCell>
                                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>{data?.applicabletax}</TableCell>
                                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>{Math.abs(data?.sellingexcludevalue)}</TableCell>
                                                        <TableCell sx={{ padding: '2px', borderBottom: '0px' }}>{Math.abs((data?.sellingexcludevalue * data?.taxtareval / 100) * data?.quantity).toFixed(2)}</TableCell>
                                                    </TableRow>

                                                </>


                                            );
                                        })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid container spacing={2}>

                            <Grid item xs={6}>
                                <Typography name="print" ><b>TOTAL :</b></Typography>
                            </Grid>
                            <Grid item xs={3}></Grid>
                            <Grid item xs={3}>
                                <Typography name="print" ><b>{Number(totalTaxValCal()).toFixed(2)}</b> </Typography>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />

                        <Grid container>
                            <Grid item xs={7}>
                                <Typography name="print" ><b></b></Typography>
                                <Typography name="print" ><b>TOTAL RECEIVED AMOUNT</b></Typography>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Typography name="print" ><b></b> </Typography>
                                <Typography name="print" ><b>{isQuotation.amountgain}</b> </Typography>
                            </Grid>
                        </Grid><br />
                        <Box style={{ borderWidth: 0.3, borderStyle: 'dashed', borderRadius: 1, borderColor: 'black' }}></Box><br />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography name="print" ><b>NO. OF ITEMS:  </b>{totalQuantityCalc()}</Typography>
                                <Typography name="print" ><b>TOTAL ITEMS: </b>{totalQuantityCalc()}</Typography>
                            </Grid>
                        </Grid><br />
                        <Grid container>
                            <Grid>
                                <Typography variatnt="subtitle2" align="left">* All Offers are subject to applicable T&C.</Typography>
                                <Typography variatnt="subtitle2" align="left">* No return / Exchange / Refund on Innerwear, Dresses, Cloths and Chudidhar.</Typography>
                                <Typography variatnt="subtitle2" align="left">* This is computer generated invoice and hence does not require any signature.</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <br />   <br />   <br />   <br />

                </Box>
                <br /><br />
                {/* Error model */}
                <Dialog
                    open={isErrorOpen}
                    onClose={alertClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                        </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={alertClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </>
        </Box>
    );
};
export default Quotationedit;