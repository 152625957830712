import React, { useContext} from 'react';
import { Box, Button, Grid, Paper, Typography, TableContainer, Table, TableHead, TableBody, DialogContent, DialogActions, Dialog } from '@mui/material';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { userStyle } from '../PageStyle';
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { ExportXL, ExportCSV } from "../Export";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { UserRoleAccessContext } from "../../context/Appcontext";
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from "../../services/Baseservice";

function PackQualityUpdateDetails() {
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);


    return (

        <Box>
            <Headtitle title={'Pack Quality Update'} />
            <Typography sx={userStyle.HeaderText}>Pack Quality Update</Typography>
            <br />
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                    </Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].upackqualityupdate && (
                            <>
                                <Button sx={userStyle.buttonadd}>UPDATE</Button>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].excelpackqualityupdate && (
                            <>
                                <ExportCSV />
                            </>
                        )}
                        {isUserRoleCompare[0].csvpackqualityupdate && (
                            <>
                                <ExportXL />
                            </>
                        )}
                        {isUserRoleCompare[0].printpackqualityupdate && (
                            <>
                                <Button sx={userStyle.buttongrp} >&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfpackqualityupdate && (
                            <>
                                <Button sx={userStyle.buttongrp} ><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                <Box>
                    {/* ****** Table Start ****** */}
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="packqualityupdate">
                            <TableHead>
                                <StyledTableRow >
                                    <StyledTableCell >Item Name</StyledTableCell>
                                    <StyledTableCell >Packing Quality</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody align="left">
                                <StyledTableRow >
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* ****** Table End ****** */}
                </Box>
            </Box>
            {/* Alert Dialog */}
            <Dialog
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined">Cancel</Button>
                    <Button autoFocus variant="contained" color='error'> OK </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function PackQualityUpdate() {
    return (
        <>
            <Box >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        < PackQualityUpdateDetails /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default PackQualityUpdate;