import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead,  Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import autoTable from 'jspdf-autotable';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useReactToPrint } from "react-to-print";
import $ from 'jquery';
import { SERVICE } from "../../services/Baseservice";
import { AuthContext, UserRoleAccessContext } from '../../context/Appcontext';

function MinimumStockllist() {

    //  Access
    const { auth } = useContext(AuthContext);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    const [products, setProducts] = useState([]);
    //Excel data
    const [exceldata, setExceldata] = useState([]);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef();

    //fetch all products
    const fetchProducts = async () => {
    try {
        let res_products = await axios.get(SERVICE.PRODUCT,{
            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        let resdata = res_products.data.products.filter((data, index)=>{
          if(data.currentstock <= data.reorderlevel){
            return data
          }
      })
        setProducts(resdata);
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
              $(tableRef.current).DataTable({
                language: { search: '', searchPlaceholder: "Search..." },
                lengthMenu: [
                  [10, 1, 25, 50, 100, 200, 500, -1],
                  [10, 1, 25, 50, 100, 200, 500, 'All'],
                ],
              });
            }, 1000);
          });
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
    };

    // Export Excel
    const fileName = 'Minimum Stock Report'

    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = products.map(t=>({ Category:t.category, "Item Code":t.prodcode,"Item Name": t.prodname, "Re-order Level":t.reorderlevel, "Stock in Hand(pieces)": t.currentstock, }));
        setExceldata(data);
    }

    useEffect(() => {
        getexcelDatas()
    }, [products])

    // PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#minimumstockreport' })
        doc.save('Minimum Stock Report.pdf')
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | MINIMUM STOCK REPORT',
        pageStyle: 'print'
    });

  useEffect(
    ()=>{
      fetchProducts();
    },[]
  )

  return (

      <Box>
        <Headtitle title={'Minimum Stock Report'} />
        <Typography sx={userStyle.HeaderText}>Minimum Stock Report</Typography>
        {/* content start */}
        <Box sx={userStyle.container}>
          { /* ******************************************************EXPORT Buttons****************************************************** */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvminimumwisestockreport && (
                  <>
                      <ExportCSV csvData={exceldata} fileName={fileName} />
                  </>
              )}
              {isUserRoleCompare[0].excelminimumwisestockreport && (
                  <>
                      <ExportXL csvData={exceldata} fileName={fileName} />
                  </>
              )}
              {isUserRoleCompare[0].printminimumwisestockreport && (
                  <>
                      <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                  </>
              )}
              {isUserRoleCompare[0].pdfminimumwisestockreport && (
                  <>
                      <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                  </>
              )}
            </Grid>
          </Grid><br />
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700 }} aria-label="customized table" id="minimumstockreport" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell >Item Name</StyledTableCell>
                    <StyledTableCell >Re-order Level</StyledTableCell>
                    <StyledTableCell >Stock in Hand(pieces)</StyledTableCell>
                    <StyledTableCell >Deflinct</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {products.length > 0 ? (
                    products.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.category}</StyledTableCell>
                          <StyledTableCell>{row.prodcode}</StyledTableCell>
                          <StyledTableCell>{row.prodname}</StyledTableCell>
                          <StyledTableCell>{row.reorderlevel}</StyledTableCell>
                          <StyledTableCell>{row.currentstock}</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={6}>No data record found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {/* Print Layout */}
        <TableContainer component={Paper} sx={userStyle.printcls}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="minimumstockreport" ref={componentRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Category</StyledTableCell>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell >Item Name</StyledTableCell>
                    <StyledTableCell >Re-order Level</StyledTableCell>
                    <StyledTableCell >Stock in Hand(pieces)</StyledTableCell>
                    <StyledTableCell >Deflinct</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {products.length > 0 ? (
                    products.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.category}</StyledTableCell>
                          <StyledTableCell>{row.prodcode}</StyledTableCell>
                          <StyledTableCell>{row.prodname}</StyledTableCell>
                          <StyledTableCell>{row.reorderlevel}</StyledTableCell>
                          <StyledTableCell>{row.currentstock}</StyledTableCell>
                          <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={6}>No data record found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
            </Table>
        </TableContainer>
      </Box>
  );
}

function MinimumStock() {

  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <MinimumStockllist /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default MinimumStock;