import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Button, Table, TableBody, TableContainer, TableHead, Paper, Grid, TableFooter } from '@mui/material';
import { userStyle } from '../PageStyle';
import { FaPrint, FaFilePdf, } from "react-icons/fa";
import $ from 'jquery';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import axios from 'axios';
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { SERVICE } from '../../services/Baseservice';
import { UserRoleAccessContext } from '../../context/Appcontext';
import Headtitle from '../../components/header/Headtitle';
import { AuthContext } from '../../context/Appcontext';

function Moleculewise() {

    const [products, setProducts] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);
    let counts = {};

    //role access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    const fetchProduct = async () => {
        try {
            let res_product = await axios.get(SERVICE.PRODUCT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            let listproduct = res_product.data.products.map((data) => {
                return data.manufacture
            })
            setProducts(listproduct);
            products.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

            const splitKeyValue = obj => {
                const keys = Object.keys(obj);
                const res = [];
                for (let i = 0; i < keys.length; i++) {
                    res.push({
                        'Manufacturename': keys[i],
                        'Totalitems': obj[keys[i]]
                    });
                };
                return res;
            };
            setMapping(splitKeyValue(counts));
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

     // total
     function totalNetCostCalc() {
        let totalvalue = 0;
        mapping?.forEach((value) => {
            totalvalue += Number(value.Totalitems)
        })
        return totalvalue.toFixed(0);
    }

    // Export Excel
    const fileName = 'Manufacture Wise Item Summary';
    //  get particular columns for export excel
    const getexcelDatas = async () => {
        var data = mapping.map(t => ({
            "Manufacture Name": t.Manufacturename, "Total Items": t.Totalitems,
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: ' HIPOS | MANUFACTURE WISE ITEM SUMMARY',
        pageStyle: 'print'
    });

    //PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        doc.autoTable({
            html: '#manufacturesummary',
            margin: { top: 10 },
        })
        doc.save('Manufacture Wise Item Summary.pdf')
    }

    useEffect(() => {
        fetchProduct()
        getexcelDatas()
    }, [mapping])

    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1,  25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <>
            <br />
            <Box sx={userStyle.container}>
                <Headtitle title={'Manufacture Wise Item Summary'} />
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="body2" sx={userStyle.HeaderText}>Manufacture Wise Item Summary</Typography>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
                    <Grid container sx={userStyle.gridcontainer}>
                        <Grid >
                            {isUserRoleCompare[0].csvmanufacturesummary && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelmanufacturesummary && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printmanufacturesummary && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfmanufactursummary && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    <Table aria-label="customized table" id="manufacturesummary" ref={tableRef}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="left">Manufacture Name</StyledTableCell>
                                <StyledTableCell align="left">Total items</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {mapping && (
                                mapping.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{row.Manufacturename}</StyledTableCell>
                                        <StyledTableCell align="left">{row.Totalitems}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
                            <StyledTableCell align="center" colSpan={6} sx={{ color: 'black', fontSize: '20px', justifyContent: 'center' }}>Total item records:{Number(totalNetCostCalc())}</StyledTableCell>
                        </TableFooter>
                    </Table>
                    <StyledTableRow>
                    </StyledTableRow>
                </TableContainer>

                {/* print layout     */}
                <>
                    <Box sx={userStyle.printcls} ref={componentRef}>
                        {/* <Headtitle title={'Category Print'} /> */}
                        <Box>
                            <Typography variant='h5'>Manufacture Wise Item Summary</Typography>
                        </Box><br />
                        <Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table" id="manufacturesummary" >
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell>Manufacture Name</StyledTableCell>
                                            <StyledTableCell >Total items</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody align="left">
                                        {mapping && (
                                            mapping.map((row, index) => (

                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left">{row.Manufacturename}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.Totalitems}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                    <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
                                        <StyledTableCell align="center" colSpan={6} sx={{ color: 'black', fontSize: '20px', justifyContent: 'center' }}>Total item records:{Number(totalNetCostCalc())}</StyledTableCell>

                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </>
            </Box>

        </>
    );
}

function Moleculewiselist() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Moleculewise /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Moleculewiselist;