import React, { useState, useEffect, useContext } from 'react';
import { TextField, Box, Grid, FormControl, InputLabel,IconButton, OutlinedInput, Tooltip, Select, MenuItem, Button } from '@mui/material';
import { FcInfo } from "react-icons/fc";
import { userStyle } from '../../PageStyle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import axios from 'axios';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function Businesscreate({setngs, setSetngs}) {
    const [file, setFile] = useState();
    const { auth } = useContext(AuthContext);

    const [busilocations, setBusilocations] = useState();

    // Start date
    const [startDate, setStartDate] = useState(dayjs());

    // Business Location
    const fetchLocation = async () => {
        try {
            let res= await axios.get(SERVICE.BUSINESS_LOCATION,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setBusilocations(res.data.busilocations);
        } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
        }
    };

    useEffect (()=> {
        fetchLocation();
    })

    // Image Upload
    function handleChange(e) {
        let businesslogo = document.getElementById("businesslogo")
        var path = (window.URL || window.webkitURL).createObjectURL(businesslogo.files[0]);
        toDataURL(path, function (dataUrl) {
            businesslogo.setAttribute('value', String(dataUrl));
            setSetngs({ ...setngs, businesslogo: String(dataUrl) })
            return dataUrl;
        })
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    return (
        <Box sx={userStyle.container}>
            <Grid container spacing={3} >
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel htmlFor="component-outlined">Business Name</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput 
                            id="component-outlined"
                            value={setngs.businessname}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,businessname:e.target.value};
                            })}
                            type="text"
                            name="businessname"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel htmlFor="component-outlined">Business Address</InputLabel>
                    <FormControl size="small" fullWidth>
                        <OutlinedInput 
                            id="component-outlined"
                            value={setngs.buniessaddress}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,buniessaddress:e.target.value};
                            })}
                            type="text"
                            name="buniessaddress"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel htmlFor="component-outlined">Business Location</InputLabel>
                    <FormControl size="small" fullWidth>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={setngs.businesslocation}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,businesslocation:e.target.value};
                            })}
                            fullWidth
                        >
                            {busilocations && (
                                busilocations.map((item, index) => (
                                    <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                                )))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel htmlFor="component-outlined">Start date</InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <FormControl size="small" fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    size='small'
                                    sx={userStyle.posNavbarInput}
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4} >
                    <InputLabel htmlFor="outlined-adornment-password">Default Profit Percent
                        <Tooltip title="Default Profit Percent">
                            <IconButton size="small">
                                <FcInfo />
                            </IconButton>
                        </Tooltip>
                    </InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <Grid sx={userStyle.spanIcons} >< AddCircleOutlineOutlinedIcon /> </Grid>
                        <FormControl size="small" fullWidth>
                            <OutlinedInput 
                                id="component-outlined"
                                value={setngs.dprofitpercent}
                                onChange={(e) => setSetngs((prevState)=> {
                                    return {...prevState,dprofitpercent:e.target.value};
                                })}
                                name="dprofitpercent"
                                type="text"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel id="demo-select-small">Currency</InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <Grid sx={userStyle.spanIcons} >
                            <MoneyOutlinedIcon />
                        </Grid>
                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                name="currency"
                                fullWidth
                                value={setngs.currency}
                                onChange={(e) => setSetngs((prevState)=> {
                                    return {...prevState,currency:e.target.value};
                                })}
                            >
                                <MenuItem value="India - Rupees(INR)">India - Rupees(INR)</MenuItem>
                               </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel id="demo-select-small">Currency Symbol Placement</InputLabel>
                    <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            name="currencysymbol"
                            value={setngs.currencysymbol}
                            onChange={(e) => setSetngs((prevState)=> {
                                return {...prevState,currencysymbol:e.target.value};
                            })}
                            fullWidth
                        >
                            <MenuItem value="Before Amount">Before Amount</MenuItem>
                            <MenuItem value="After Amount">After Amount</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel id="demo-select-small">Time Zone</InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <Grid sx={userStyle.spanIcons} >
                            <ScheduleOutlinedIcon />
                        </Grid>
                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                name="timezone"
                                value={setngs.timezone}
                                onChange={(e) => setSetngs((prevState)=> {
                                    return {...prevState,timezone:e.target.value};
                                })}
                                fullWidth
                            >
                                <MenuItem value="Asia/Kolkata" selected="selected">Asia/Kolkata</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <InputLabel>Upload Logo</InputLabel>
                    <FormControl size="small" fullWidth>
                        <Button component="label" sx={userStyle.uploadBtn}>
                            Upload 
                            <input type='file' id="businesslogo" name='file' hidden onChange={handleChange} />
                        </Button>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel id="demo-select-small">Financial Year Start Month</InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <Grid sx={userStyle.spanIcons}><CalendarTodayOutlinedIcon /></Grid>
                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={setngs.fyearsstartmonth}
                                onChange={(e) => setSetngs((prevState)=> {
                                    return {...prevState,fyearsstartmonth:e.target.value};
                                })}
                                name="fyearsstartmonth"
                                fullWidth
                            >
                                <MenuItem value="January">January</MenuItem>
                                <MenuItem value="Februar">Februar</MenuItem>
                                <MenuItem value="March">March</MenuItem>
                                <MenuItem value="April">April</MenuItem>
                                <MenuItem value="May">May</MenuItem>
                                <MenuItem value="June">June</MenuItem>
                                <MenuItem value="July">July</MenuItem>
                                <MenuItem value="August">August</MenuItem>
                                <MenuItem value="September">September</MenuItem>
                                <MenuItem value="October">October</MenuItem>
                                <MenuItem value="November">November</MenuItem>
                                <MenuItem value="December">December</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel id="demo-select-small">Stock Accounting Method</InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <Grid sx={userStyle.spanIcons} >
                            < CalculateOutlinedIcon />
                        </Grid>
                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={setngs.stockaccountmethod}
                                onChange={(e) => setSetngs((prevState)=> {
                                    return {...prevState,stockaccountmethod:e.target.value};
                                })}
                                name="stockaccountmethod"
                                fullWidth
                            >
                                <MenuItem value="FIFO(First in first out)">FIFO(First in first out)</MenuItem>
                                <MenuItem value="LIFO(Last in first out)">LIFO(Last in first out)</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel id="demo-select-small">Date Format</InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <Grid sx={userStyle.spanIcons} >
                            < CalendarTodayOutlinedIcon />
                        </Grid>
                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={setngs.dateformat}
                                onChange={(e) => setSetngs((prevState)=> {
                                    return {...prevState,dateformat:e.target.value};
                                })}
                                name="dateformat"
                                fullWidth
                            >
                                <MenuItem value="mm/dd/yyyy">mm/dd/yyyy</MenuItem>
                                <MenuItem value="dd/mm/yyyy">dd/mm/yyyy</MenuItem>
                                <MenuItem value="dd-mm-yyy">dd-mm-yyy</MenuItem>
                                <MenuItem value="mm-dd-yyy">mm-dd-yyy</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                    <InputLabel id="demo-select-small">Time Format</InputLabel>
                    <Grid sx={{ display: 'flex' }}  >
                        <Grid sx={userStyle.spanIcons} ><ScheduleOutlinedIcon /></Grid>
                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={setngs.timeformat}
                                onChange={(e) => setSetngs((prevState)=> {
                                    return {...prevState,timeformat:e.target.value};
                                })}
                                name="timeformat"
                                fullWidth
                            >
                                <MenuItem value="12 hour">12 hour</MenuItem>
                                <MenuItem value="24 hour">24 hour</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}