import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function CustomerwiseprofitwholesaleList() {

    const [customerWiseEstimate, setCustomerWiseEstimate] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today,
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    let itemQuanArr = [];
    let totSales = [];
    let allArray = [];
    let purchaseArr = [];
    let cusNameArr = []
    let finalresult = [];

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()


    const fetchCustomerWiseWholesale = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            //Filtering true values for retail
            let getDatawithFilter = req.data.pos1.filter((data) => {

                if (data.activate == false) {
                    let splittedMonth = data.date
                    let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
                    if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                        return data
                    } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                        return data
                    }
                    else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                        return data
                    }
                    else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
                        return data
                    }
                }
            })

            //pushing into arrays to get values in table
            let calculation = getDatawithFilter.filter((value) => {
                cusNameArr.push(value.customer);
                totSales.push(+value.aftergranddisctotal)
                //reducing quantiy in goods to push
                let goodsQuantity = value.goods.map((values) => values.quantity).reduce((val, dat) => val + dat)
                itemQuanArr.push(goodsQuantity)

                //reducing puchaseexcludetax in goods to push
                let purchase = value.goods.map((data) => {
                    return Number(data.quantity) * Number(data.purchasevalue == undefined || data.purchasevalue == "" ? 0 : data.purchasevalue)
                }).reduce((val, datas) => val + datas)
                purchaseArr.push(purchase)
            })
            allArray = cusNameArr.map(function (data, i) {
                return { customername: data, totquantity: itemQuanArr[i], totalsales: totSales[i], purchasevalue: purchaseArr[i] };
            });

            finalresult = [...allArray.reduce((r, o) => {
                const key = o.customername;
                const items = r.get(key) || Object.assign({}, o, {
                    totquantity: 0,
                    totalbills: 0,
                    purchasevalue: Number(0),
                });
                items.totquantity += o.totquantity
                items.totalbills += o.totalbills
                items.purchasevalue += Number(o.purchasevalue)
                return r.set(key, items);
            }, new Map).values()];
            setCustomerWiseEstimate(finalresult);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [25, 1, 50, 100, 200, 500, -1],
                            [25, 1, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });

        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Customer wise profit wholesale'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = customerWiseEstimate.map(t => ({
            'Customer Name': t.customername, 'Total Quantity': t.totquantity,
            'Bill Value': t.totalsales, 'Purchase Value': t.purchasevalue, 'Profit Amount': (t.totalsales - t.purchasevalue).toFixed(0)
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CUSTOMER WISE PROFIT WHOLESALE',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Customerwiseprofitwholesale' })
        doc.save('Customer Wise Profit Wholesale.pdf')
    }

    useEffect(() => {
        getexcelDatas();
    }, [customerWiseEstimate])

    return (
        <Box >
            <Headtitle title={'Customer Wise Profit Wholesale'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Customer Wise Profit Wholesale</Typography>

            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={5} md={5}>
                            <Grid container>
                                <Grid item lg={3} md={3}>
                                    <Typography sx={{ marginTop: 1 }}>Date From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, startdate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={5} md={5}>
                            <Grid container>
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, enddate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button onClick={fetchCustomerWiseWholesale} variant='contained' color='secondary' >Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvcustomerwiseprofitwholesale && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelcustomerwiseprofitwholesale && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printcustomerwiseprofitwholesale && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfcustomerwiseprofitwholesale && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />

                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Customerwiseprofitwholesale" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Customer Name</StyledTableCell>
                                    <StyledTableCell>Total Quantity</StyledTableCell>
                                    <StyledTableCell>Bill Value (Sales)</StyledTableCell>
                                    <StyledTableCell>Purchase Value</StyledTableCell>
                                    <StyledTableCell>Profit Amount</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customerWiseEstimate.length > 0 && (
                                    customerWiseEstimate.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.customername}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totquantity}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalsales}</StyledTableCell>
                                            <StyledTableCell align="left">{row.purchasevalue}</StyledTableCell>
                                            <StyledTableCell align="left">{(row.totalsales - row.purchasevalue).toFixed(0)}</StyledTableCell>
                                        </StyledTableRow>
                                )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls}>
                <Box>
                    <Typography variant='h5' >Customer Wise Profit Wholesale Print</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="Customerwiseprofitwholesale" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Customer Name</StyledTableCell>
                                        <StyledTableCell>Total Quantity</StyledTableCell>
                                        <StyledTableCell>Bill Value (Sales)</StyledTableCell>
                                        <StyledTableCell>Purchase Value</StyledTableCell>
                                        <StyledTableCell>Profit Amount</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                {customerWiseEstimate.length > 0 && (
                                    customerWiseEstimate.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.customername}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totquantity}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalsales}</StyledTableCell>
                                            <StyledTableCell align="left">{row.purchasevalue}</StyledTableCell>
                                            <StyledTableCell align="left">{(row.totalsales - row.purchasevalue).toFixed(0)}</StyledTableCell>
                                        </StyledTableRow>
                                )))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Customerwiseprofitwholesale() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <CustomerwiseprofitwholesaleList /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Customerwiseprofitwholesale;