import React, { useState } from 'react';
import { userStyle } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextareaAutosize } from '@mui/material';

function Salescreate() {

const [salesAdd, setSalesAdd] = useState(false);

const addOpen = () => {  setSalesAdd(true); };
const addClose = () => { setSalesAdd(false); };

const [salesComn, setSalesComn] = useState({
    prefixadd: "", firstnamead: "", lastnameadd: "", emailadd: "", contactadd: "", addressadd: "", salescomnperadd: ""
});

    return (
        <Box>
            <Box>
                <Button sx={userStyle.buttonadd} onClick={addOpen} >ADD</Button>
            </Box>
            <form action=''>
                <Dialog
                    onClose={addClose}
                    aria-labelledby="customized-dialog-title1"
                    open={salesAdd}
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #b97df0',
                        },
                    }}
                >
                    <DialogTitle id="customized-dialog-title1" onClose={addClose}>
                        Add sales commission agent
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={3}>
                            <Grid item md={2} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel htmlFor="component-outlined">Prefix</InputLabel>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={salesComn.prefixadd}
                                        onChange={(e) => { setSalesComn({ ...salesComn, prefixadd: e.target.value }) }}
                                        label="Prefix"
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={5} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel htmlFor="component-outlined">First Name *</InputLabel>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={salesComn.firstnamead}
                                        onChange={(e) => { setSalesComn({ ...salesComn, firstnamead: e.target.value }) }}
                                        label="First Name *"
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={5} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-select-small">Last Name </InputLabel>
                                    <OutlinedInput
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={salesComn.lastnameadd}
                                        onChange={(e) => { setSalesComn({ ...salesComn, lastnameadd: e.target.value }) }}
                                        label="Last Name"
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-select-small">Email </InputLabel>
                                    <OutlinedInput
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={salesComn.emailadd}
                                        onChange={(e) => { setSalesComn({ ...salesComn, emailadd: e.target.value }) }}
                                        label="Email"
                                        type="email"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-select-small">Contact Number </InputLabel>
                                    <OutlinedInput
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={salesComn.contactadd}
                                        onChange={(e) => { setSalesComn({ ...salesComn, contactadd: e.target.value }) }}
                                        label="Contact Number"
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <InputLabel id="demo-select-small">Address </InputLabel>
                                <FormControl size="small" fullWidth sx={{ border: '1px solid #b97fd0 !important', marginTop: '5px !important',}}>
                                    <TextareaAutosize
                                        aria-label="minimum height"
                                        minRows={3}
                                        placeholder="Address"
                                        style={{border:'none'}}
                                        value={salesComn.addressadd}
                                        onChange={(e) => { setSalesComn({ ...salesComn, addressadd: e.target.value }) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="demo-select-small">Sales Commission Percentage (%)</InputLabel>
                                    <OutlinedInput
                                        labelId="demo-select-small"
                                        id="demo-select-small"
                                        value={salesComn.salescomnperadd}
                                        onChange={(e) => { setSalesComn({ ...salesComn, salescomnperadd: e.target.value }) }}
                                        label="Sales Commission Percentage (%)"
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus variant='contained' color="success">
                            Save
                        </Button>
                        <Button onClick={addClose} variant='contained' color="error">Close</Button>
                    </DialogActions>
                </Dialog>
            </form>
        </Box>
    );
}

export default Salescreate;