import React, { useState, useEffect, useContext } from 'react';
import { userStyle } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Typography, Button, } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';

function Productviewlist() {

    const { auth,  } = useContext(AuthContext);
    // Text field
    const [product, setProduct] = useState({});

    const id = useParams().id;

    const backLPage = useNavigate();

    //fetch all p[roducts
    const fetchProducts = async () => {
        
        try {
            let res = await axios.get(`${SERVICE.PRODUCT_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            })
            setProduct(res.data.sproduct);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // fetch all data from page load
    useEffect(
        () => {
        fetchProducts();
    }, [id]
    );

    const handleBack = (e) =>{
        backLPage('/product/product/list')
    }

    return (
        <Box>
            <Headtitle title={'View Product'} />
            <form>
                {/* header text */}
                <Typography sx={userStyle.HeaderText}>View product</Typography>
                {/* content start */}
                <Box sx={userStyle.container}>
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Product Code</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.prodcode}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Product Name</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.prodname}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Category</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.category}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Schedule</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.schedule}
                                />
                            </FormControl>
                        </Grid>
                    </Grid><br />
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Molecule</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.molecules}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Manufacture</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.manufacture}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Business Location</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.businesslocation}
                                />
                            </FormControl>
                        </Grid>
                        {product.hsncodecheck ? (
                            <>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <InputLabel htmlFor="component-outlined" >HSN Code</InputLabel>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            id="component-outlined"
                                            value={product.hsn}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <InputLabel id="demo-select-small">Applicable Tax</InputLabel>
                                    <FormControl size="small" fullWidth >
                                        <OutlinedInput
                                            id="component-outlined"
                                            value={product.applicabletax}
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Unit Desc</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.unit}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Rack</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.rack}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Row</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.row}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" >Box No</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.boxno}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={2}>
                        {product.managestock && (
                            <>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <InputLabel htmlFor="outlined-adornment-password">Minimum Quantity</InputLabel>
                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            value={product.minquantity}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={12}>
                                    <InputLabel htmlFor="outlined-adornment-password">Maximum Quantity</InputLabel>
                                    <FormControl variant="outlined" size="small" fullWidth>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            value={product.maxquantity}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        )}
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Selling price tax type</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.selltaxtype}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Re-order Level</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={product.reorderlevel}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container sx={userStyle.gridcontainer}>
                        <Grid >
                            <Button sx={userStyle.buttoncancel} onClick={handleBack}>BACK</Button>
                        </Grid>
                    </Grid>
                </Box>
                {/* content end */}
            </form>
        </Box>
    );
}

function Productview() {
    return (

        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Productviewlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Productview;