import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableRow, TableCell, TableBody, TableHead, TableContainer, Button, TableFooter } from '@mui/material';
import { userStyle , colourStyles} from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';
import CreatableSelect from 'react-select/creatable';



function Bankstatementlist() {
    
    const [bankstatement, setBankstatement] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const [dateFilter, setDateFilter] = useState({ startdate: "", enddate: "", });
    const [actnofill, setActnofill] = useState({ actnofil: "", });
    const [actno, setActno] = useState();
    const { auth, setAuth } = useContext(AuthContext);



    //  Bank Statement...........
    const fetchBankstatement = async () => {
        try {
            let customer = await axios.get(SERVICE.CASHBOOK, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = customer.data.entry.filter((i) => {
                // let startdatefromsupplier = dateFilter.startdate;
                // let enddatefromsupplier = dateFilter.enddate;
                // return startdatefromsupplier <= data.createdAt && new Date(enddatefromsupplier) + 1 >= data.createdAt
                  return console.log(i);
            })
            setAuth({ ...auth, loginState: true });
            // setBankstatement(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 25, 50, 100, 200, 500, -1],
                            [10, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Fetch customer name for dropdown .............Name
    const fetchactno = async () => {
        try {
            let req = await axios.get(SERVICE.CASHBOOK);
            let ans=  req.data.account.map((d) => ({
                ...d,
                label: d.account,
                value: d.account,
            }));
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // actno handlechange
    const handleActnofill = (newValue, actionMeta) => {
        setActnofill({ ...actnofill, actnofil: newValue.value })
       
    };
    const handleInputActnofill = (inputValue, actionMeta) => {
        
    };


    useEffect(
        () => {
        }, [dateFilter]
    )

    // Excel...........
    const fileName = " Bank Statement ";
    const customerexcel = async () => {
        let res_customerpayment = await axios.get('http://localhost:8003/api/bankstatement');
        var data = res_customerpayment.data.customerpayment.map(t => ({
            contactid: t.contactid, firstname: t.firstname,
            lastname: t.lastname, email: t.email, creditlimit: t.creditlimit, payterm: t.payterm, paytermassign: t.paytermassign,
            openbalance: t.openbalance, customergroup: t.customergroup, contactperson: t.contactperson,
            addressone: t.addressone, addresstwo: t.addresstwo, phonenumber: t.phonenumber, whatsappno: t.whatsappno
        }));
        setExceldata(data);
    }

    //  PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Bankstatement' })
        doc.save('Bankstatement.pdf')
    }
    useEffect(
        () => {
            fetchBankstatement();
            customerexcel();
            fetchactno();
        }, []
    )
    let total = 0;

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10,1, 25, 50, 100, 200, 500, -1],
                        [10,1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [])

    return (
        <Box >
            <Headtitle title={'Bank Statement'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Bank Statement <Typography sx={userStyle.SubHeaderText}></Typography></Typography>
            <Box sx={userStyle.container} >
                <Grid container lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={3} md={3}>
                        <Grid container lg={12} md={12} >
                            <Grid item lg={3} md={3}>
                                <Typography sx={{ marginTop: 1 }}> Ac/No : </Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <CreatableSelect
                                        isClearable
                                        onChange={handleActnofill}
                                        styles={colourStyles}
                                        onInputChange={handleInputActnofill}
                                        options={actno}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Grid container lg={12} md={12} >
                            <Grid item lg={3} md={3}>
                                <Typography sx={{ marginTop: 1 }}> From</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.startdate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="startdate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Grid container lg={12} md={12} >
                            <Grid item lg={1} md={1}>
                                <Typography sx={{ marginTop: 1 }}>To</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.enddate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="enddate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={1} md={1}>
                        <Button variant='contained' color='secondary'
                            onClick={(e) => { fetchBankstatement() }}
                        >Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid>
                            <ExportCSV csvData={exceldata} fileName={fileName} />
                            <ExportXL csvData={exceldata} fileName={fileName} />
                            <Button sx={userStyle.buttongrp} onClick={(e) => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            <Link to="/contact/customer/bankstatementprint"><Button sx={userStyle.buttongrp} style={{ textDecoration: 'none', color: 'white', '&:hover': { color: 'red', backgroundColor: 'yellow' } }}><FaPrint />&ensp;Export to Print</Button></Link>
                        </Grid>
                        {/* ****** Table Grid Container ****** */}
                        <Grid container>
                            <Grid md={4} sm={2} xs={1}></Grid>
                            <Grid md={8} sm={10} xs={10} sx={{ align: "center" }}></Grid>
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Bankstatement" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Particulaus</StyledTableCell>
                                    <StyledTableCell>Debit</StyledTableCell>
                                    <StyledTableCell>Credit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {bankstatement.length > 0 && (
                                    bankstatement.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.date}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.Particulaus}</StyledTableCell >
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow >
                                    <TableCell>Net Total</TableCell>
                                    <TableCell>4646123</TableCell>
                                    <TableCell>541254</TableCell>
                                    <TableCell>4646123</TableCell>
                                    <TableCell>541254</TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>

                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
        </Box>
    );
}
function Bankstatement() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Bankstatementlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Bankstatement;
