import React, { useState, useEffect , useRef, useContext} from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../../Export';
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from 'react-to-print';
import {SERVICE} from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';

function Departmentlisttable() {

  const [department, setdepartments] = useState();

  //  Excel data
  const [exceldata, setExceldata] = useState([]);
  
  //role access
  const {isUserRoleCompare} = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //delete modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [departmentdel, setDepartmentDel] = useState({});

  const handleClickOpen = () => {
    setIsDeleteOpen(true);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };

      //DataTable
      $.DataTable = require('datatables.net')
      const tableRef = useRef()
    
      useEffect (() => {
        $(document).ready(function() {
          $.fn.dataTable.ext.errMode = 'none';
          setTimeout(function () {
          $(tableRef.current).DataTable({
              language: { search: '', searchPlaceholder: "Search..." },
              lengthMenu: [
                    [10, 25, 50, 100, 200, 500, -1],
                    [10, 25, 50, 100, 200, 500, 'All'],
                ],
                    });
        }, 1000);
        } );
        },[(tableRef.current)])

  //  Fetch department Data
  const fetchDepartments = async () => {
    try {
      let res = await axios.get(SERVICE.DEPARTMENT,{
        headers: {
          'Authorization':`Bearer ${auth.APIToken}`
        }
      });
      setdepartments(res.data.departments);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let res = await axios.get(SERVICE.DEPARTMENT,{
      headers: {
        'Authorization':`Bearer ${auth.APIToken}`
      }
    });
    var data = res.data.departments.map(t => ({
      id: t.departmentid, name: t.departmentname
    }));
    setExceldata(data);
  }

  //alert delete popup
  const rowData = async (id) => {
    try {
      let res = await axios.get(`${SERVICE.DEPARTMENT_SINGLE}/${id}`,{
        headers: {
          'Authorization':`Bearer ${auth.APIToken}`
        }
      })
      setDepartmentDel(res.data.sdepartment);//set function to get particular row
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //alert delete popup
  let deparatmentid = departmentdel._id;
  const deleteDepartment = async (deparatmentid) => {
    try {
      let res = await axios.delete(`${SERVICE.DEPARTMENT_SINGLE}/${deparatmentid}`,{
        headers: {
          'Authorization':`Bearer ${auth.APIToken}`
        }
      });
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(
    () => {
      
      fetchDepartments();
    }, [deleteDepartment]
  );

  useEffect(
    ()=>{
        getexcelDatas();
    },[]
  )

  // Export Excel
  const fileName = 'Department'

 // Print
 const componentRef = useRef();
 const handleprint = useReactToPrint({
   content: () => componentRef.current,
   documentTitle: 'Department',
   pageStyle: 'print'
 });

 //pdf
 const columns = [{ title: "DEPARTMENT ID", field: "departmentid", }, { title: "DEPARTMENT NAME", field: "departmentname", }]  // PDF
 
 const downloadPdf = () => {
   const newData = department.map(row => {
     delete row._id
     return row
   })
   const doc = new jsPDF();
   doc.autoTable({  theme: "grid", columns: columns.map(col => ({ ...col, dataKey: col.field })),      body: department    })
   doc.save('departments.pdf')
 }
 
  
  
  return (
    <Box>
      <Headtitle title={'Departments'} />
      {/* header text */}
      <Typography sx={userStyle.HeaderText}>Departments<Typography sx={userStyle.SubHeaderText}>Manage your departments</Typography></Typography>
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
          {/* {isUserRoleCompare[0].adepartment && ( */}
              <>
            <Link to="/user/department/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd} >ADD</Button></Link>
              </>
           {/* )} */}
          </Grid>
        </Grid>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
          {/* {isUserRoleCompare[0].exceldepartment && ( */}
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
           {/* )} */}
           {/* {isUserRoleCompare[0].csvdepartment && ( */}
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
           {/* )} */}
           {/* {isUserRoleCompare[0].printdepartment && ( */}
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
           {/* )} */}
           {/* {isUserRoleCompare[0].pdfdepartment && ( */}
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
           {/* )} */}
          </Grid>
        </Grid><br />
        <Box>
          {/* Table Start */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="departmenttable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                <StyledTableCell>Actions</StyledTableCell>
                  <StyledTableCell>Department ID </StyledTableCell>
                  <StyledTableCell >Department Name</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {department &&
                  (department.map((item, index) => (
                    <StyledTableRow >
                      <StyledTableCell>
                        <Grid sx={{display:'flex'}}>
                            {/* {isUserRoleCompare[0].edepartment && ( */}
                                <>
                                    <Link to={`/user/department/edit/${item._id}`} style={{ textDecoration: 'none', color: '#fff' }}><Button sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: "large" }} /></Button></Link>

                                </>
                            {/* )} */}
                            {/* {isUserRoleCompare[0].ddepartment && ( */}
                                <>
                                    <Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(item._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                                </>
                            {/* )} */}
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">{item.departmentid}</StyledTableCell>
                      <StyledTableCell>{item.departmentname }</StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Table End */}
        </Box>
      </Box>
      <TableContainer component={Paper} sx={userStyle.printcls}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="departmenttable" ref={componentRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Department ID </StyledTableCell>
                  <StyledTableCell >Department Name</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {department &&
                  (department.map((item, index) => (
                    <StyledTableRow >
                      <StyledTableCell component="th" scope="row">{item.departmentid}</StyledTableCell>
                      <StyledTableCell>{item.departmentname }</StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
      { /* content end */}
      {/* ALERT DIALOG */}
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={(e) => deleteDepartment(deparatmentid)} autoFocus variant="contained" color='error'> OK </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function Departmentlist() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Departmentlisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Departmentlist;