import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Grid, FormControl, Typography, TextField, Table, TableBody, TableContainer, TableHead, Paper, Button } from '@mui/material';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import $ from 'jquery';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment"
import { AuthContext } from '../../context/Appcontext';

function Categorywisepurchaseall() {

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);
  const [catewisepurchase, setCatewisepurchase] = useState([]);

  // Excel data
  const [exceldata, setExceldata] = useState([]);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = catewisepurchase.map(t => ({
      'Category Name': t.category, 'Total Quantity': t.quantity, 'Total Amount': t.ratebydismod
    }));
    setExceldata(data);
  }

  // Purchases date filter
  const fetchPurchases = async () => {
    try {
      let req = await axios.get(SERVICE.PURCHASE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let getDatawithFilter = req.data.purchases.filter((data) => {
        let splittedMonth = data.purchasedate
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })

      let purchase = [];
      getDatawithFilter != "" ? getDatawithFilter.map((data) => {
        data.products.map((item) => {
          purchase.push(item);
          const final = [...purchase.reduce((r, o) => {
            const key = o.category;
            const items = r.get(key) || Object.assign({}, o, {
              ratebydismod: 0,
              quantity: 0
            });
            items.ratebydismod += +o.ratebydismod
            items.quantity += +o.quantity
            return r.set(key, items);

          }, new Map).values()
          ];
          setCatewisepurchase(final);
        })
      }) : setCatewisepurchase([])

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Export Excel
  const fileName = 'Category Wise Purchase';

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CATEGORY WISE PURCHASE REPORT',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#categorytable' });
    doc.save('Category Wise Purchase.pdf')
  }

  useEffect(() => {
    getexcelDatas()
  }, [catewisepurchase])

  useEffect(() => {
    fetchPurchases();
  }, [])
  
  return (
    <Box >
      <Headtitle title={'Category Wise Purchase'} />
      { /* ************************************************** Header Content ****************************************************** */}
      <Typography variant="body2" sx={userStyle.HeaderText}>Category Wise Purchase</Typography>
      <Box sx={userStyle.container}>
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography variant="body2" sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography variant="body2" sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={() => { fetchPurchases() }}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />

      { /* ******************************************************EXPORT Buttons****************************************************** */}
      <>
        <Box sx={userStyle.container} >
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].excelcategorywisepurchase && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].csvcategorywisepurchase && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printcategorywisepurchase && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfcategorywisepurchase && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />

          { /*  *******************************************Table Start  *******************************************p*/}
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700, }} aria-label="customized table" id="categorytable" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell >Category Name</StyledTableCell>
                    <StyledTableCell >Total Quantity</StyledTableCell>
                    <StyledTableCell >Total Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {catewisepurchase ?
                    (catewisepurchase.map((row, index) => {
                      return (
                        <StyledTableRow key={index} >
                          <StyledTableCell >{row.category}</StyledTableCell>
                          <StyledTableCell >{row.quantity}</StyledTableCell>
                          <StyledTableCell >{row.ratebydismod}</StyledTableCell>
                        </StyledTableRow>
                      )
                    }
                    )) : "No data found"}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          { /* ******************************************* Table End **********************************************/}
        </Box>
      </>
      {/* *******************************************print layout **********************************************/}
      <>
        <Box sx={userStyle.printcls} ref={componentRef}>
          <Box>
            <Typography variant='h5'>Category</Typography>
          </Box><br />
          <TableContainer component={Paper} sx={userStyle.printcls} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable" ref={componentRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell >Category Name</StyledTableCell>
                  <StyledTableCell >Total Quantity</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {catewisepurchase ?
                  (catewisepurchase.map((row, index) => {
                    return (
                      <StyledTableRow key={index} >
                        <StyledTableCell >{row.category}</StyledTableCell>
                        <StyledTableCell >{row.quantity}</StyledTableCell>
                        <StyledTableCell >{row.ratebydismod}</StyledTableCell>
                      </StyledTableRow>
                    )
                  }
                  )) : "No data found"}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Box>
  );
}

function Categorywisepurchase() {
  return (
    <>
      <Box>
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Categorywisepurchaseall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Categorywisepurchase;