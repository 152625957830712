import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

function BankmasterReport() {

    const [bankdetail, setBankDetails] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

     //jquery
     $.DataTable = require("datatables.net");
     const tableRef = useRef();

    //Bank Details
    const fetchBankDetails = async () => {
        try {
            let req = await axios.get(SERVICE.BANKMASTER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }

            });
            setBankDetails(req.data.bankmasters);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Bank Master Report'
    const getexcelDatas = async () => {
        let res = await axios.get(SERVICE.BANKMASTER,{
            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            }
        });
        var data = res.data.bankmasters.map(t => ({
            'Account Number': t.accno, "Name": t.name, "Bank": t.bank, "Branch": t.branch, 'IFSC Code': t.ifsccode, "Remarks": t.remarks,
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | BANK MASTER REPORT',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#bankmastertable' })
        doc.save('Bank Master Report.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
            fetchBankDetails();
        }, [exceldata]
    )
   
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [ 10,1, 25, 50, 100, 200, 500, -1],
                        [ 10,1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box >
            <Headtitle title={'Bank Repor'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Bank Report</Typography>
            <br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid>
                            {isUserRoleCompare[0].excelbanklist && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].csvbanklist && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printbanklist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfbanklist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="bankmastertable" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>A/C NO</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Bank</StyledTableCell>
                                    <StyledTableCell>Branch</StyledTableCell>
                                    <StyledTableCell>IFSC Code</StyledTableCell>
                                    <StyledTableCell>Remarks</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {bankdetail.length > 0 && (
                                    bankdetail.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.accno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.bank}</StyledTableCell >
                                            <StyledTableCell align="left">{row.branch}</StyledTableCell>
                                            <StyledTableCell align="left">{row.ifsccode}</StyledTableCell>
                                            <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}

                    { /* ****** Print ****** */}
                    <Box sx={userStyle.printcls} >
                        <Box>
                            <TableContainer component={Paper} sx={userStyle.printcls}>
                                <Table aria-label="simple table" id="bankmastertable" ref={componentRef}>
                                    <TableHead sx={{ fontWeight: "600" }} >
                                    <Typography variant='h5' >Bank Master Report</Typography>
                                        <StyledTableRow >
                                            <StyledTableCell>A/C NO</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Bank</StyledTableCell>
                                            <StyledTableCell>Branch</StyledTableCell>
                                            <StyledTableCell>IFSC Code</StyledTableCell>
                                            <StyledTableCell>Remarks</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bankdetail.length > 0 && (
                                            bankdetail.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left">{row.accno}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.name}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.bank}</StyledTableCell >
                                                    <StyledTableCell align="left">{row.branch}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.ifsccode}</StyledTableCell>                                         
                                                    <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Box>
            </>
        </Box>
    );
}

function Bankreport() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <BankmasterReport /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Bankreport;