import React, { useState, useEffect, useContext } from 'react';
import { userStyle } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Select, MenuItem, Typography, FormGroup, FormControlLabel, Checkbox, Button, TextareaAutosize, Tooltip, IconButton, Dialog, DialogContent, DialogActions } from '@mui/material';
import { FcInfo } from "react-icons/fc";
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';

function Usercreatelist() {

    const [useractive, setuseractive] = useState(false);
    const [userlogin, setuserlogin] = useState(false);
    const [roles, setRoles] = useState();
    const [usname, setUsname] = useState();
    const [setngs, setSetngs] = useState();
    const [isCounter, setIsCounter] = useState();
    const { auth } = useContext(AuthContext);

    const [useradd, setUseradd] = useState({
        prefix: "", first: "", last: "", email: "", useractiveaccess: "", userloginaccess: "", username: "", password: "", cpassword: "", role: "None", sales: "",
        maxsales: "", contacts: "", dateobth: "", gender: "Male", maritalstatus: "Married", bloodgroup: "",
        phonenum: "", aphonenumber: "", fcphonenumber: "", facebook: "", twitter: "", socialmone: "", counter: "None",
        socialmtwo: "", customfone: "", customftwo: "", customfthree: "", customffour: "", guardianname: "", idpname: "",
        idpnumber: "", paddress: "", caddress: "", aholdername: "", anumber: "", bname: "", bidcode: "", bbranch: "", taxid: "", termscondition: false
    });

    // auto id for purchase number
    let newval = setngs ? setngs.usersku + "0001" : "UR0001";

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    //supplier auto id from settings
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSetngs(response.data.settingid);
            setIsCounter(response.data.settingid.counter)
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Role
    const fetchAllUser = async () => {
        try {
            let userAl = await axios.get(`${SERVICE.USER}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setUsname(userAl.data.users);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Role
    const fetchRole = async () => {
        try {
            let role = await axios.get(`${SERVICE.ROLE}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setRoles(role.data.roles);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Alert popup for phonenumber length
    const handleMobile = (e) => {
        if (e.length > 10) {
            setShowAlert("Mobile number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setUseradd({ ...useradd, phonenum: num })
        }
    }
    const handleAlter = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setUseradd({ ...useradd, aphonenumber: num })
        }
    }
    const handleFamily = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setUseradd({ ...useradd, fcphonenumber: num })
        }
    }

    // Alert popup for textfield to enter alphabets only
    const handleValidationForPrefix = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, prefix: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, prefix: value })
        }
    }
    const handleValidationForFirst = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, first: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, first: value })
        }
    }
    const handleValidationForLast = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[ `₹!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, last: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setUseradd({ ...useradd, last: value })
        }
    }

    const backPage = useNavigate();

    const fetchUser = async () => {
        try {
            let res = await axios.post(`${SERVICE.USER_CREATE}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                prefix: String(useradd.prefix),
                first: String(useradd.first),
                last: String(useradd.last),
                email: String(useradd.email),
                useractiveaccess: Boolean(useractive),
                userloginaccess: Boolean(userlogin),
                accesslocation: String(useradd.accesslocation),
                username: String(useradd.username),
                password: String(useradd.password),
                role: String(useradd.role),
                sales: Number(useradd.sales),
                maxsales: Number(useradd.maxsales),
                contacts: String(useradd.contacts),
                dateobth: String(useradd.dateobth),
                gender: String(useradd.gender),
                maritalstatus: String(useradd.maritalstatus),
                bloodgroup: String(useradd.bloodgroup),
                phonenum: Number(useradd.phonenum),
                aphonenumber: Number(useradd.aphonenumber),
                fcphonenumber: Number(useradd.fcphonenumber),
                facebook: String(useradd.facebook),
                twitter: String(useradd.twitter),
                socialmone: String(useradd.socialmone),
                socialmtwo: String(useradd.socialmtwo),
                customfone: String(useradd.customfone),
                customftwo: String(useradd.customftwo),
                customfthree: String(useradd.customfthree),
                customffour: String(useradd.customffour),
                guardianname: String(useradd.guardianname),
                idpname: String(useradd.idpname),
                idpnumber: String(useradd.idpnumber),
                paddress: String(useradd.paddress),
                caddress: String(useradd.caddress),
                aholdername: String(useradd.aholdername),
                anumber: String(useradd.anumber),
                bname: String(useradd.bname),
                bidcode: String(useradd.bidcode),
                bbranch: String(useradd.bbranch),
                taxid: String(useradd.taxid),
                counter:String(useradd.counter),
                termscondition: Boolean(useradd.termscondition)
            });
            setUseradd(res.data)
            toast.success(res.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/user/user/list');
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    }

    useEffect(
        () => {
            fetchSettings();
            fetchAllUser();
            fetchRole();
        }, []
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        if (useradd.first == "") {
            setShowAlert("Please Enter First Name!")
            handleClickOpen();
        }
        else if (useradd.email == "") {
            setShowAlert("Please Enter Email!")
            handleClickOpen();
        }
        else if (useradd.password == "") {
            setShowAlert("Please Enter Password!")
            handleClickOpen();
        } else if (!useradd.password == useradd.cpassword) {
            setShowAlert("Password and Confirm password didn't match!")
            handleClickOpen();
        }
        else if (useradd.role == "None") {
            setShowAlert("Please Enter Role!")
            handleClickOpen();
        }else if (useradd.counter == "None") {
            setShowAlert("Please Enter Counter!")
            handleClickOpen();
        }
        else {
            fetchUser();
        }
    }

    return (
        <Box>
            <Headtitle title={'Add User'} />
            <form onSubmit={handleSubmit}>
                <Box>
                    <Grid display="flex">
                        <Typography sx={userStyle.HeaderText}>Add User</Typography>
                    </Grid>
                </Box>
                <Box sx={userStyle.container}>
                    <Grid container spacing={4} sx={{
                        padding: '40px 20px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #b97df0',
                        },
                    }}>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel  >Prefix</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder="Mr/ Miss/ Mrs"
                                    value={useradd.prefix}
                                    onChange={(e) => { setUseradd({ ...useradd, prefix: e.target.value }); handleValidationForPrefix(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={5} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" sx={{ display: "flex" }}> First Name<Typography style={{ color: "red" , marginLeft: "2px"}}><b>*</b></Typography></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    placeholder="First Name"
                                    value={useradd.first}
                                    onChange={(e) => { setUseradd({ ...useradd, first: e.target.value }); handleValidationForFirst(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={5} sm={6} xs={12}>
                            <InputLabel >Last Name</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    placeholder="Last Name"
                                    value={useradd.last}
                                    onChange={(e) => { setUseradd({ ...useradd, last: e.target.value }); handleValidationForLast(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={5} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" sx={{ display: "flex" }}> Email<Typography style={{ color: "red" , marginLeft: "2px"}}><b>*</b></Typography></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    placeholder="Email"
                                    value={useradd.email}
                                    onChange={(e) => { setUseradd({ ...useradd, email: e.target.value }) }}
                                    type="email"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={7} sm={6} xs={12}>
                            <FormGroup>
                                <span><FormControlLabel control={<Checkbox
                                    checked={userlogin} onChange={() => setuserlogin(!userlogin)}
                                />} label="Is active ?" />
                                    <Tooltip title="Check/Uncheck to make a user active/inactive." arrow>
                                        <IconButton size="small">
                                            <FcInfo />
                                        </IconButton>
                                    </Tooltip></span>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container}>
                    <Grid display="flex" >
                        <Typography variant="h6" >Roles and Permissions</Typography>
                    </Grid>
                    <Grid container spacing={4} sx={{
                        padding: '40px 20px', '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #b97df0',
                        },
                    }}>
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox
                                    checked={useractive} onChange={() => setuseractive(!useractive)}
                                />} label="Allow login" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            {usname && (
                                usname.map(
                                    () => {
                                        let strings = setngs ? setngs.usersku : "UR";
                                        let refNo = usname[usname.length - 1].username;
                                        let digits = (usname.length + 1).toString();
                                        const stringLength = refNo.length;
                                        let lastChar = refNo.charAt(stringLength - 1);
                                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                                        let lastBeforeChar = refNo.slice(-2);
                                        let lastThreeChar = refNo.slice(-3);
                                        let lastDigit = refNo.slice(-4);
                                        let refNOINC = parseInt(lastChar) + 1
                                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                                        let refLstThree = parseInt(lastThreeChar) + 1;
                                        let refLstDigit = parseInt(lastDigit) + 1;
                                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                            refNOINC = ("000" + refNOINC);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                            refNOINC = ("00" + refLstTwo);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                                            refNOINC = ("0" + refLstThree);
                                            newval = strings + refNOINC;
                                        } else {
                                            refNOINC = (refLstDigit);
                                            newval = strings + refNOINC;
                                        }
                                    }))}
                            <InputLabel  >Username</InputLabel>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    value={newval}
                                    type="text"
                                    name="username"
                                />
                            </FormControl>
                            <Typography variant='body2' sx={{ opacity: '0.9', mt: 1 }}>Leave blank to auto generate username</Typography>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" sx={{ display: "flex" }}> Password<Typography style={{ color: "red" , marginLeft: "2px"}}><b>*</b></Typography></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    placeholder="Password"
                                    value={useradd.password}
                                    onChange={(e) => { setUseradd({ ...useradd, password: e.target.value, username: newval }) }}
                                    type="password"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined" sx={{ display: "flex" }}>Confirm Password<Typography style={{ color: "red" , marginLeft: "2px"}}><b>*</b></Typography></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    placeholder="Confirm Password"
                                    value={useradd.cpassword}
                                    onChange={(e) => { setUseradd({ ...useradd, cpassword: e.target.value }) }}
                                    type="password"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={5} sm={6} xs={12} lg={5}>
                            <InputLabel htmlFor="uncontrolled-native" sx={{ display: "flex" }}>Role<Typography style={{ color: "red" , marginLeft: "2px"}}><b>*</b></Typography></InputLabel>
                            <Grid sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        defaultValue={0}
                                        inputProps={{
                                            name: 'Role',
                                            id: 'uncontrolled-native',
                                        }}
                                        value={useradd.role}
                                        onChange={(e) => { setUseradd({ ...useradd, role: e.target.value }) }}
                                    >
                                        <MenuItem value="None">None</MenuItem>
                                        {roles && (
                                            roles.map((row, index) => (
                                                <MenuItem value={row.rolename} key={index}>{row.rolename}</MenuItem>
                                            )))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={7} sm={6} xs={12}>
                        <InputLabel htmlFor="uncontrolled-native" sx={{ display: "flex" }}>Counter<Typography style={{ color: "red" , marginLeft: "2px"}}><b>*</b></Typography></InputLabel>
                            <Grid sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <Select
                                        defaultValue={0}
                                        inputProps={{
                                            name: 'Counter',
                                            id: 'uncontrolled-native',
                                        }}
                                        value={useradd.counter}
                                        onChange={(e) => { setUseradd({ ...useradd, counter: e.target.value }) }}
                                    >
                                        <MenuItem value="None">None</MenuItem>
                                        {isCounter && (
                                            isCounter.map((row, index) => (
                                                <MenuItem value={row.countername} key={index}>{row.countername}</MenuItem>
                                            )))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container}>
                    <Grid display="flex" >
                        <Typography variant="h6" >More Informations</Typography>
                    </Grid>
                    <Grid container spacing={4} sx={{
                        padding: '40px 20px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #b97df0',
                        },
                    }}>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Date</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    value={useradd.dateobth}
                                    onChange={(e) => { setUseradd({ ...useradd, dateobth: e.target.value }) }}
                                    type="date"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Gender</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={useradd.gender}
                                    onChange={(e) => { setUseradd({ ...useradd, gender: e.target.value }) }}
                                >
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Marital Status</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={useradd.maritalstatus}
                                    onChange={(e) => { setUseradd({ ...useradd, maritalstatus: e.target.value }) }}

                                >
                                    <MenuItem value="Married">Married</MenuItem>
                                    <MenuItem value="Unmarried">Unmarried</MenuItem>
                                    <MenuItem value="Divorced">Divorced</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Blood Group</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Blood Group'
                                    value={useradd.bloodgroup}
                                    onChange={(e) => { setUseradd({ ...useradd, bloodgroup: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Mobile Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    placeholder='Mobile Number'
                                    value={useradd.phonenum}
                                    onChange={(e) => { setUseradd({ ...useradd, phonenum: e.target.value }); handleMobile(e.target.value) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Alternate contact number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    placeholder='Alternate contact number'
                                    value={useradd.aphonenumber}
                                    onChange={(e) => { setUseradd({ ...useradd, aphonenumber: e.target.value }); handleAlter(e.target.value) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Family contact number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    placeholder='Family contact number'
                                    value={useradd.fcphonenumber}
                                    onChange={(e) => { setUseradd({ ...useradd, fcphonenumber: e.target.value }); handleFamily(e.target.value) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Facebook Link</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Facebook Link'
                                    value={useradd.facebook}
                                    onChange={(e) => { setUseradd({ ...useradd, facebook: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Twitter Link</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Twitter Link'
                                    value={useradd.twitter}
                                    onChange={(e) => { setUseradd({ ...useradd, twitter: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Social Media 1</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Social Media 1'
                                    value={useradd.socialmone}
                                    onChange={(e) => { setUseradd({ ...useradd, socialmone: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Social Media 2</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Social Media 2'
                                    value={useradd.socialmtwo}
                                    onChange={(e) => { setUseradd({ ...useradd, socialmtwo: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Custom field 1</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Custom field 1'
                                    value={useradd.customfone}
                                    onChange={(e) => { setUseradd({ ...useradd, customfone: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Custom field 2</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Custom field 2'
                                    value={useradd.customftwo}
                                    onChange={(e) => { setUseradd({ ...useradd, customftwo: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Custom field 3</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Custom field 3'
                                    value={useradd.customfthree}
                                    onChange={(e) => { setUseradd({ ...useradd, customfthree: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Custom field 4</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Custom field 4'
                                    value={useradd.customffour}
                                    onChange={(e) => { setUseradd({ ...useradd, customffour: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Guardian Name</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Guardian Name'
                                    value={useradd.guardianname}
                                    onChange={(e) => { setUseradd({ ...useradd, guardianname: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >ID proof name</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='ID proof name'
                                    value={useradd.idpname}
                                    onChange={(e) => { setUseradd({ ...useradd, idpname: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >ID proof number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='ID proof number'
                                    value={useradd.idpnumber}
                                    onChange={(e) => { setUseradd({ ...useradd, idpnumber: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Permanent Address </InputLabel>
                            <FormControl size="small" fullWidth sx={{ border: '1px solid #b97fd0 !important', marginTop: '5px !important', }}>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    style={{ border: 'none' }}
                                    placeholder="Permanent Address"
                                    value={useradd.paddress}
                                    onChange={(e) => { setUseradd({ ...useradd, paddress: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Current Address </InputLabel>
                            <FormControl size="small" fullWidth sx={{ border: '1px solid #b97fd0 !important', marginTop: '5px !important', }}>
                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="Current Address"
                                    style={{ border: 'none' }}
                                    value={useradd.caddress}
                                    onChange={(e) => { setUseradd({ ...useradd, caddress: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <hr /><br />
                    <Grid display="flex" >
                        <Typography variant="h6" >Bank Details</Typography>
                    </Grid>
                    <Grid container spacing={4} sx={{
                        padding: '40px 20px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #b97df0',
                        },
                    }}>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Account Holder's Name</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Account Holder Name'
                                    value={useradd.aholdername}
                                    onChange={(e) => { setUseradd({ ...useradd, aholdername: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Account Number</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    placeholder='Account Number'
                                    value={useradd.anumber}
                                    onChange={(e) => { setUseradd({ ...useradd, anumber: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel >Bank Name</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput

                                    placeholder='Bank Name'
                                    value={useradd.bname}
                                    onChange={(e) => { setUseradd({ ...useradd, bname: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel>Bank Identifier Code
                                <Tooltip title='A unique code to identify the bank in your country. for example: IFSC code' placement="top" arrow>
                                    <IconButton size="small">
                                        <FcInfo />
                                    </IconButton>
                                </Tooltip>
                            </InputLabel>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <OutlinedInput
                                    placeholder='Bank Identifier Code'
                                    type="text"
                                    value={useradd.bidcode}
                                    onChange={(e) => { setUseradd({ ...useradd, bidcode: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel>Branch</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    placeholder='Branch'
                                    value={useradd.bbranch}
                                    onChange={(e) => { setUseradd({ ...useradd, bbranch: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel>Tax Payer ID
                                <Tooltip title='Tax number id of the employee, for example, PAN card in India' placement="top" arrow>
                                    <IconButton size="small">
                                        <FcInfo />
                                    </IconButton>
                                </Tooltip>
                            </InputLabel>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <OutlinedInput
                                    type="text"
                                    placeholder='Tax Payer ID'
                                    value={useradd.taxid}
                                    onChange={(e) => { setUseradd({ ...useradd, taxid: e.target.value }) }}
                                />
                            </FormControl>

                        </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center' }}>
                        <Grid >
                            <Link to="/user/user/list"> <Button sx={userStyle.buttoncancel} type="button">CANCEL</Button></Link>
                            <Button sx={userStyle.buttonadd} type="submit">SAVE</Button>
                        </Grid>
                    </Grid>
                </Box><br />
            </form>

            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Usercreate() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', '@media (max-width: 600px)': { paddingLeft: '30px', paddingRight: '30px' }}}>
                    <Usercreatelist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Usercreate;