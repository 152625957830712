import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import { FaFilePdf, } from 'react-icons/fa';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../../Export';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext, UserRoleAccessContext } from '../../../context/Appcontext';

function Paymentintegrationlisttable() {

    const [payments, setPayments] = useState();
    const [pay, setPay] = useState({});
    const [exceldata,setExceldata]= useState([]);
    const { auth } = useContext(AuthContext);

    // jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // User Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Payments
    const fetchPayments = async () => {
        try {
            let response = await axios.get(SERVICE.PAYMENTINTEGRATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPayments(response.data.paymentintegrations);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // get particular columns for export excel
    const getexcelDatas= async () => {
        var data = payments.map(t=>({'Business Location': t.businesslocation, Cash: t.cash,
            Card: t.card, Cheque: t.cheque, Bank: t.bank, Other: t.other, 'Card Number': t.cardnum, 'Card Holder Name': t.cardhname, 
            'Card Transaction Number': t.cardtransnum, 'Card Type': t.cardtype, Month: t.month, Year: t.year, 'Security Code': t.securitycode, 'Cheque No': t.checkno,
            'Bank Account Number':t.baccno, UPI:t.upi, 'UPI Number':t.upino
        }));
        setExceldata(data);
    }

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleClickOpen = () => { setIsDeleteOpen(true); };
    const handleClose = () => { setIsDeleteOpen(false); };

    //set function to get particular row
    const rowData = async (id) => {
        try {
            let response = await axios.get(`${SERVICE.PAYMENTINTEGRATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPay(response.data.spaymentintegration); 
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Alert delete popup
    let payid = pay._id;
    const deletePayment = async (payid) => {
        try {
            let response = await axios.delete(`${SERVICE.PAYMENTINTEGRATION_SINGLE}/${payid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchPayments();
            getexcelDatas();
        }, [deletePayment]
    );

    useEffect(
        ()=>{
            $(document).ready(function() {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                          [10, 1, 25, 50, 100, 200, 500, -1],
                          [10, 1, 25, 50, 100, 200, 500, 'All'],
                      ],
                          });
              }, 1000);
              } );
        },[tableRef.current]
    )
    
    // Export Excel
    const fileName = 'PaymentIntegration'

    // PDF
    const downloadPdf = () => {

        const newData = payments.map(row => {
            delete row._id
            delete row.cash
            delete row.card
            delete row.cheque
            delete row.bank
            delete row.upi
            delete row.createdAt;
            return row
        })
        const doc = new jsPDF();
        autoTable(doc, { html: '#paymentIntegrationTable' });
        doc.save('Payment Integration.pdf')
    }

    return (
        <Box>
            <Headtitle title={'Payment Integrations'} />
            {/* header text */}
            <Typography sx={userStyle.HeaderText}>Payment Integrations<Typography sx={userStyle.SubHeaderText}></Typography></Typography>
            {/* content start */}
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].apaymentintegration && (
                            <>
                                <Link to='/settings/paymentintegration/create' style={{ textDecoration: 'none', color: 'black' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].csvpaymentintegration && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelpaymentintegration && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].pdfpaymentintegration && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                <Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" ref={tableRef}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell >Actions</StyledTableCell>
                                    <StyledTableCell>Business Location</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody align="left">
                                {payments &&
                                    (payments.map((item, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>
                                                <Grid sx={{display:'flex'}}>
                                                    {isUserRoleCompare[0].epaymentintegration && (
                                                        <>
                                                            <Link to={`/settings/paymentintegration/edit/${item._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                        </>
                                                    )}
                                                    {isUserRoleCompare[0].dpaymentintegration && (
                                                        <>
                                                            <Button sx={userStyle.buttondelete} onClick={(e) => {handleClickOpen();rowData(item._id)}}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                                                        </>
                                                    )}
                                                    {isUserRoleCompare[0].vpaymentintegration && (
                                                        <>
                                                            <Link to={`/settings/paymentintegration/view/${item._id}`} style={{ textDecoration: 'none', color: 'white', }}><Button sx={userStyle.buttonview}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                        </>
                                                    )}
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>{item.businesslocation}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            {/* Delete Modal */}
            {/* ALERT DIALOG */}
            <Dialog
                open={isDeleteOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">Cancel</Button>
                    <Button  autoFocus variant="contained" color='error' onClick={(e) => deletePayment(payid)}> OK </Button>
                </DialogActions>
            </Dialog>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls}>
                <Box>
                    <Typography variant='h5' >Payment Integration </Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="paymentIntegrationTable" >
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Business Location</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody align="left">
                                {payments &&
                                    (payments.map((item, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>{item.businesslocation}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}
function Paymentintegrationlist() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Paymentintegrationlisttable /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Paymentintegrationlist;