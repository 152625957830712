import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography, Grid, InputLabel, FormControl, OutlinedInput, Dialog, DialogContent, DialogActions } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';

function Customergrpeditlist() {

  const { auth } = useContext(AuthContext);
  const [cgroups, setCgroups] = useState({ cusgroupname: "", cusgroupid: "" });

  //popup modal
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState()
  const handleClickOpen = () => { setIsErrorOpen(true); };
  const handleClose = () => { setIsErrorOpen(false); };

  const id = useParams().id

  const fetchHandler = async () => {
    try {
      let response = await axios.get(`${SERVICE.CUSTOMER_GROUP_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setCgroups(response.data.scgroup)
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  useEffect(() => {
    fetchHandler()
  }, [id]);

  const backLPage = useNavigate();

  // UPDATE
  const updateCgrps = async () => {
    try {
      let response = await axios.put(`${SERVICE.CUSTOMER_GROUP_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        cusgroupname: String(cgroups.cusgroupname),
        cusgroupid: String(cgroups.cusgroupid),
      });
      setCgroups(response.data);
      toast.success(response.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      backLPage('/contact/customergroup/list');
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cgroups.cusgroupname == "") {
      setShowAlert("Please Enter Customer Group Name!")
      handleClickOpen();
    }else if (cgroups.cusgroupid == "") {
      setShowAlert("Please Enter Customer Group Code!")
      handleClickOpen();
    }else {
      updateCgrps();
    }
  }

  return (
    <Box>
      <Headtitle title={'Edit Customer group'} />
      <Box>
        <Grid display="flex">
          <Typography sx={userStyle.HeaderText}>Edit Customer Group</Typography>
        </Grid>
      </Box>
      <Box sx={userStyle.container}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <InputLabel htmlFor="component-outlined">Contact Id</InputLabel>
              <Grid sx={{ display: 'flex' }}  >
                <Grid sx={userStyle.spanIcons}><ContactPageOutlinedIcon /></Grid>
                <FormControl size="small" fullWidth>
                  <OutlinedInput
                    id="component-outlined"
                    value={cgroups.cusgroupid}
                    type="text"
                    name="cusgroupid"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <InputLabel htmlFor="component-outlined" sx={{ display: "flex" }}>Customer Group Name<b style={{ color: "red", marginLeft: "2px" }}>*</b></InputLabel>
              <FormControl size="small" fullWidth >
                <OutlinedInput
                  id="addCustGroupName"
                  value={cgroups.cusgroupname}
                  onChange={(e) => { setCgroups({ ...cgroups, cusgroupname: e.target.value }) }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container sx={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center' }}>
            <Grid>
              <Link to="/contact/customergroup/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
              <Button sx={userStyle.buttonadd} type="submit">UPDATE</Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <br />
      {/* ALERT DIALOG */}
      <Box>
        <Dialog
          open={isErrorOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
            <Typography variant="h6" >{showAlert}</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

function Customergrpedit() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Customergrpeditlist /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Customergrpedit;