import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle, colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { AuthContext } from '../../context/Appcontext';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import { SERVICE } from '../../services/Baseservice';
import Headtitle from '../../components/header/Headtitle';
import { useReactToPrint } from "react-to-print";
import { toast } from 'react-toastify';
import moment from "moment";
import Selects from 'react-select';


function Drugregister() {

  const [pos, setPos] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const [schedules, setSchedule] = useState([])
  const [newdata, setNewdata] = useState([]);
  const { auth } = useContext(AuthContext);

  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  let values = [];

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // get all products
  const fetchProduct = async () => {
    try {
      let res_product = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let cate_data = res_product?.data?.products?.map((d) => {
        return d.schedule
      })
      setNewdata(cate_data);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  let newarray = [...new Set(newdata)];

  // Schedules
  const fetchCategory = async () => {
    try {
      setSchedule(
        newarray.map((d) => (
          {
            ...d,
            label: d,
            value: d,
          }
        ))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Pos to show all data
  const fetchPos = async () => {
    try {
      let res = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let posfetch = res.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })

      setPos(posfetch)

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Filter
  const searchSchedule = async (e) => {
    try {
      let res = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let req = res.data.pos1.filter((data) => {
        return data.schedule == e.schedule;
      })
      setPos(req);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchProduct();
    fetchCategory();
  }, [schedules])

  // Export Excel
  const fileName = 'Drug Register'
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = pos.map(t => ({
      "Bill No": t.referenceno, "Bill Date": moment(t.date).utc().format('DD-MM-YYYY'),
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  }, [exceldata])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | DRUG REGISTER',
    pageStyle: 'print'
  });

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#drugregister' })
    doc.save('Drug Register.pdf')
  }

  return (
    <Box>
      <Headtitle title={'Drug Register'} />
      <Typography sx={userStyle.HeaderText}>Drug Register</Typography>
      <Box sx={userStyle.filtercontent} >
        <Grid container sx={{ justifyContent: "center" }} spacing={2}>
          <Grid item lg={3} md={3}>
            <Grid container spacing={1}>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3}>
            <Grid container spacing={1}>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Button variant='contained' color='secondary' onClick={fetchPos}>Generate</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3} md={3} sx={{ display: 'flex' }}>
            <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
              <Selects
                defaultValue={"walk-in-customer"}
                options={schedules}
                onChange={searchSchedule}
                styles={colourStyles}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvselldrugregister && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].excelselldrugregister && (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printselldrugregister && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfselldrugregister && (<Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="drugregister" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell >Bill No</StyledTableCell>
                  <StyledTableCell >Date</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {pos &&
                  (pos.map((row, index) => (
                    <StyledTableRow >
                      <StyledTableCell>{row.referenceno}</StyledTableCell>
                      <StyledTableCell>{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls} >
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="drugregister" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell >Bill No</StyledTableCell>
              <StyledTableCell >Date</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {pos &&
              (pos.map((row, index) => (
                <StyledTableRow >
                  <StyledTableCell>{row.referenceno}</StyledTableCell>
                  <StyledTableCell>{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box >
  );
}

function Drugregisterlist() {

  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Drugregister /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Drugregisterlist;