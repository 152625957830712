import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { AuthContext } from '../../context/Appcontext'

function Schedulewise() {

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);
  const [exceldata, setExceldata] = useState([]);
  const [schedulewisesales, setSchedulewisesales] = useState([]);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //schedulewisesales fetching
  const fetchschedulewisesales = async () => {
    try {
      let request = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      let schedulewisesalesfetch = request.data.pos1.filter((data) => {
        let splittedMonth = data.createdAt
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')

        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate == dateTrim) {
          return data
        }
        else if (dateFilter.startdate == dateTrim && dateFilter.enddate == dateTrim) {
          return data
        }
        else if (dateTrim >= dateFilter.startdate && dateTrim <= dateFilter.enddate) {
          return data
        }

      })

      let posgoods = [];
      schedulewisesalesfetch != "" ? schedulewisesalesfetch.map(item => {
        item.goods.map(value => {
          posgoods.push(value)
          const result = [...posgoods.reduce((r, o) => {
            const key = o.schedule;
            const items = r.get(key) || Object.assign({}, o, {
              subtotal: 0,
              quantity: 0
            });
            items.subtotal += o.subtotal
            items.quantity += o.quantity

            return r.set(key, items);
          }, new Map).values()];
          setSchedulewisesales(result);
        })
      }) : setSchedulewisesales([])

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });

    } catch (error) {
      const messages = error.response.data.messages
      toast.error(messages)

    }
  }

  // Export Excel
  const fileName = 'schedulewisesales'
  // Xl datas
  const getexcelDatas = () => {

    let xldata = schedulewisesales.map((data) => ({

      "Schedule": data.schedule, "Total Quantity": data.quantity, "Total Amount": data.subtotal
    }))

    setExceldata(xldata)
  }

  useEffect(() => {
    getexcelDatas()
  }, [exceldata])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | Salesummary',
    pageStyle: 'print'
  });

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#schedulewisesales' })
    doc.save('schedulewisesales.pdf')
  }

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  useEffect(() => {

  }, [(tableRef.current)])

  return (
    <Box>
      <Headtitle title={'Schedule wise sales'} />
      <Typography variant={'body2'} sx={userStyle.HeaderText}>Schedule<Typography variant={'body2'} sx={userStyle.SubHeaderText}>Wise Sale</Typography></Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={5} md={5}>
            <Grid container >
              <Grid item lg={2} md={2}>
                <Typography variant={'body2'} sx={{ marginTop: 1 }}> From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} md={5}>
            <Grid container  >
              <Grid item lg={1} md={1}>
                <Typography variant={'body2'} sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchschedulewisesales}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
          </Grid>
          <Grid item xs={4}>
            {isUserRoleCompare[0].auser && (
              <>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvschedulewisesales && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].excelschedulewisesales && (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printschedulewisesales && (<Button sx={userStyle.buttongrp} onClick={handleprint} ><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfschedulewisesales && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="schedulewisesales" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Schedule</StyledTableCell>
                  <StyledTableCell >Total Quantity</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {schedulewisesales && schedulewisesales.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.schedule}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                      <StyledTableCell >{row.subtotal}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Schedule</StyledTableCell>
              <StyledTableCell >Total Quantity</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {schedulewisesales && schedulewisesales.map((row, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell >{row.schedule}</StyledTableCell>
                  <StyledTableCell >{row.quantity}</StyledTableCell>
                  <StyledTableCell >{row.subtotal}</StyledTableCell>
                </StyledTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function Schedulewisesalelist() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Schedulewise /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Schedulewisesalelist;