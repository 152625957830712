import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import { userStyle, colourStyles } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import Selects from 'react-select';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { AuthContext } from '../../context/Appcontext';
function Patientwise() {
  const [patientwisesales, setPatientwisesales] = useState();
  const [customers, setCustomers] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);
  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })
  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  const fetchCustomers = async () => {
    try {
      let req = await axios.get(SERVICE.CUSTOMER, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setCustomers(
        req?.data?.customers?.map((d) => ({
          ...d,
          label: d.name,
          value: d.name
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  let billNoArr = []
  let billdateArr = []
  let itemName = []
  let totalQuantity = []
  let netTotal = []
  let arrMixed = []

  // patientwisesales
  const fetchpatientwisesales = async () => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getDatawithFilter = req.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate > dateTrim && dateFilter.enddate == "") {
          return data
        }
      })

      getDatawithFilter.map(item => {
        let dateTrim = moment(item.date).utc().format('YYYY-MM-DD')

        billNoArr.push(item.referenceno)
        billdateArr.push(dateTrim)
        item.goods.map(value => {
          itemName.push(value.productname)
          totalQuantity.push(+value.quantity)
          value.subtax.map(i => {
          })
        },
          netTotal.push(+item.aftergranddisctotal),
        )
      })
      arrMixed = billNoArr.map(function (data, i) {
        return {
          billno: data,
          referenceno: billNoArr[i],
          date: billdateArr[i],
          productname: itemName[i],
          quantity: totalQuantity[i],
          aftergranddisctotal: netTotal[i],
        }
      });

      // calculation
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.billno;
        const items = r.get(key) || Object.assign({}, o, {
          date: "",
          productname: "",
          aftergranddisctotal: 0,
          quantity: 0,
        });
        items.date = o.date
        items.productname = o.productname + ","
        items.aftergranddisctotal += o.aftergranddisctotal
        items.quantity += o.quantity
        return r.set(key, items);
      }, new Map).values()];

      setPatientwisesales(result);

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  // Filter
  const searchCustomer = async (e) => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let results = req?.data?.pos1.filter((data) => {
        return data.customer == e.name
      })

      results.map(item => {
        let dateTrim = moment(item.date).utc().format('YYYY-MM-DD')

        billNoArr.push(item.referenceno)
        billdateArr.push(dateTrim)
        item.goods.map(value => {
          itemName.push(value.productname)
          totalQuantity.push(+value.quantity)
          value.subtax.map(i => {
          })
        },
          netTotal.push(+item.aftergranddisctotal),
        )
      })
      arrMixed = billNoArr.map(function (data, i) {
        return {
          billno: data,
          referenceno: billNoArr[i],
          date: billdateArr[i],
          productname: itemName[i],
          quantity: totalQuantity[i],
          aftergranddisctotal: netTotal[i],
        }
      });

      // calculation
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.billno;
        const items = r.get(key) || Object.assign({}, o, {
          date: "",
          productname: "",
          aftergranddisctotal: 0,
          quantity: 0,
        });
        items.date = o.date
        items.productname = o.productname + ","
        items.aftergranddisctotal += o.aftergranddisctotal
        items.quantity += o.quantity
        return r.set(key, items);
      }, new Map).values()];

      setPatientwisesales(result);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  useEffect(() => {
    fetchpatientwisesales();
    fetchCustomers();
  }, []);
  // Export Excel
  const fileName = 'Patient Wise Sales'
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let datas = patientwisesales.map(t => ({
      "Bill no": t.referenceno,
      "Bill date": moment(t.date).format('DD-MM-YYYY'),
      "Item Name": t.productname,
      Quantity: t.quantity,
      Amount: t.aftergranddisctotal,
      Batch: "",
      Expiry: "",
    }));
    setExceldata(datas);
  }
  useEffect(() => {
    getexcelDatas();
  }, [patientwisesales])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | PATIENT WISE SALES',
    pageStyle: 'print'
  });
  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#Patienttable' })
    doc.save('Patient Wise Sales.pdf')
  }
  return (
    <Box>
      <Headtitle title={'Patient Wise Sales'} />
      <Grid item xs={12}>
        <Typography sx={userStyle.importheadtext}>Patient Wise Sales (Individual)</Typography>
      </Grid><br />
      <Box sx={userStyle.filtercontent} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={6} md={5}>
            <Grid container>
              <Grid item lg={4} md={4}>
                <FormControl size="small" fullWidth>
                  <Selects
                    styles={colourStyles}
                    defaultValue={"walk-in-customer"}
                    options={customers}
                    onChange={searchCustomer}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1, marginLeft: "0.5em" }}>From</Typography>
              </Grid>
              <Grid item lg={6} md={6} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }); }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={5}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={6} md={6} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
              <Grid item lg={5} md={5}>
                <Button variant='contained' color='secondary' sx={{ marginLeft: "1em" }} onClick={fetchpatientwisesales}>Generate</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box><br />
      <Headtitle title={'Patient wise Sales'} />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
        </Grid><br />
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvpatientwisesales && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelpatientwisesales && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printpatientwisesales && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfpatientwisesales && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table id="Patienttable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill No</StyledTableCell>
                  <StyledTableCell>Bill Date</StyledTableCell>
                  <StyledTableCell>Item Name</StyledTableCell>
                  <StyledTableCell>Quantity</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Batch</StyledTableCell>
                  <StyledTableCell>Expiry</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {patientwisesales &&
                  (patientwisesales.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.referenceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.productname}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                      <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                      <StyledTableCell ></StyledTableCell>
                      <StyledTableCell ></StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls} >
        <Table id="Patienttable" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Bill No</StyledTableCell>
              <StyledTableCell>Bill Date</StyledTableCell>
              <StyledTableCell >Item Name</StyledTableCell>
              <StyledTableCell >Quantity</StyledTableCell>
              <StyledTableCell >Amount</StyledTableCell>
              <StyledTableCell >Batch</StyledTableCell>
              <StyledTableCell >Expiry</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {patientwisesales &&
              (patientwisesales.map((row, index) => (
                <StyledTableRow key={index}>
                      <StyledTableCell >{row.referenceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.productname}</StyledTableCell>
                      <StyledTableCell >{row.quantity}</StyledTableCell>
                      <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                      <StyledTableCell ></StyledTableCell>
                      <StyledTableCell ></StyledTableCell>
                    </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function Patientwisesales() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Patientwise /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Patientwisesales;