import React, { useState, useEffect, useRef, createRef, useContext } from 'react';
import { Box, Button, Grid, TextField, OutlinedInput, TextareaAutosize, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Accordion, AccordionSummary, AccordionDetails, Select, MenuItem, FormControl, InputLabel, ListItem } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { userStyle } from '../../PageStyle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import axios from 'axios';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import jsPDF from "jspdf";
import { ExportXL, ExportCSV } from '../../Export';
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

function Expensestablelist() {

    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const { auth } = useContext(AuthContext);
    const [excategorys, setExcategorys] = useState();
    const [expenses, setExpenses] = useState();
    const [busilocations, setBusilocations] = useState();
    const [expanded, setExpanded] = useState("panel1");
    const [exceldata, setExceldata] = useState([]);
    const [exp, setExp] = useState({});
    const [expenseForm, setExpenseForm] = useState({});

    const [duePayments, setduePayments] = useState({
        expid: "", getid: "", dueamt: "", date: "", paymentmethod: "", paymentaccount: "", dueimg: "", paymentnote: "", payaccount: "", paymethod: "",
        cardnum: "", cardhname: "", cardtransnum: "", cardtype: "", month: "", year: "", securitycode: "", checkno: "",
        baccno: "", transnum1: "", transnum2: "", transnum3: "", transnum4: "", transnum5: "", transnum6: "", transnum7: "", paynotes: "",
    });

    //delete model
    const [openDelete, setOpenDelete] = useState(false);
    const handleClickOpen = () => { setOpenDelete(true); };
    const handleCloseDelete = () => { setOpenDelete(false); };

    // View Payment Modal
    const [viewMod, setViewMod] = useState(false);
    const viewModOpen = () => { setViewMod(true); };
    const viewModClose = () => { setViewMod(false); };

    // Add Payment Modal
    const [addMod, setAddMod] = useState(false);
    const addModOpen = () => { setAddMod(true); };
    const addModClose = () => { setAddMod(false); };

    //DATA TABLE
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    //   add due payment model create call
    const sendRequest = async () => {
        try {
            let duecreate = await axios.post(SERVICE.EXPENSE_DUE_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                expid: String(duePayments.expid),
                dueamt: String(duePayments.dueamt),
                date: String(duePayments.date),
                paymentmethod: String(duePayments.paymentmethod),
                paymentaccount: String(duePayments.paymentaccount),
                dueimg: String(duePayments.dueimg),
                paymentnote: String(duePayments.paymentnote),
                payaccount: String(duePayments.payaccount),
                paymethod: String(duePayments.paymethod),
                cardnum: String(duePayments.cardnum),
                cardhname: String(duePayments.cardhname),
                cardtransnum: String(duePayments.cardtransnum),
                cardtype: String(duePayments.cardtype),
                month: String(duePayments.month),
                year: String(duePayments.year),
                securitycode: String(duePayments.securitycode),
                checkno: String(duePayments.checkno),
                baccno: String(duePayments.baccno),
                transnum1: String(duePayments.transnum1),
                transnum2: String(duePayments.transnum2),
                transnum3: String(duePayments.transnum3),
                transnum4: String(duePayments.transnum4),
                transnum5: String(duePayments.transnum5),
                transnum6: String(duePayments.transnum6),
                transnum7: String(duePayments.transnum7),
                paynotes: String(duePayments.paynotes),
            });
            setduePayments(duecreate.data);
            addModClose();
        } catch (err) {
            const messages = err.duecreate.data.message;
            toast.error(messages);
        }
    };

    const updateData = async () => {
        if (expenseForm.paydue != 0) {
            let response = await axios.put(`${SERVICE.EXPENSE_SINGLE}/${expenseForm._id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                paydue: String(duePayments.cardnum),
            })
            setExpenseForm(response.data);
            viewModClose();
        }
    }

    const addExpenseSubmit = (e) => {
        sendRequest();
        updateData();
    }

    //    DELETE DUEPAYMENT MODEL
    const deleteExpensedue = async (id) => {
        try {
            let response = await axios.delete(`${SERVICE.EXPENSE_DUE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            toast.success(response.data.message);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // GET DATAS FROM EXPENSE DUE TABLE WITH the match of REFERENCE NO
    const getduePayments = async (id) => {
        try {
            let res = await axios.get(SERVICE.EXPENSE_DUE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let dueData = res.data.expensedues.filter((data) => {
                return data.expid == id
            })
            setduePayments(dueData)
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    let total = 0.00;
    let sum = 0.00;

    // Expense
    const fetchExpense = async () => {
        try {
            let response = await axios.get(SERVICE.EXPENSE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setExpenses(response.data.expenses);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Expense Category
    const fetchExpenseCategory = async () => {
        try {
            let response = await axios.get(SERVICE.EXPENSE_CATEGORY, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setExcategorys(response.data.excategorys)
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Business Locations
    const fetchLocation = async () => {
        try {
            let response = await axios.get(SERVICE.BUSINESS_LOCATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBusilocations(response.data.busilocations);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const rowDataexp = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.EXPENSE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setExp(res.data.sexpense);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages)
        }
    }
    let expid = exp._id;

    // Delete
    const deleteExpense = async (id) => {
        try {
            let response = await axios.delete(`${SERVICE.EXPENSE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleCloseDelete();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Filter
    const searchLoc = async (e) => {
        try {
            let expense = await axios.get(SERVICE.EXPENSE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let expDataLocation = expense.data.expenses.filter((data) => {
                return data.busilocation === e.target.value;
            })
            setExpenses(expDataLocation);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const searchExpCate = async (e) => {
        try {
            let expense = await axios.get(SERVICE.EXPENSE);
            let expDataExpCate = expense.data.expenses.filter((data) => {
                return data.expcategory === e.target.value
            })
            setExpenses(expDataExpCate);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.EXPENSE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setExpenseForm(res.data.sexpense);
            viewModOpen();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }


    // Export Excel
    const fileName = 'Expenses'
    // get perticular columns for export excel
    const getexcelDatas = async () => {
        var data = expenses.map(t => ({
            Date: t.exppaidon, 'Reference No': t.referenceno,
            'Expense Category': t.expcategory, Location: t.busilocation, Tax: t.exptax,
            'Total Amount': t.totalamount, 'Payment Due': t.paydue, 'Expense Note': t.expnote,
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | EXPENSES',
        pageStyle: 'print'
    });

    // Print
    const componentRefModal = useRef();
    const handleprintModal = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | VIEW PAYMENTS',
        pageStyle: 'print'
    });


    // PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#expensetablepdf' })
        doc.save('Expenses.pdf')
    }

    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 25, 50, 100, 200, 500, -1],
                        [10, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });

    }, [(tableRef.current)])

    useEffect(
        () => {
            fetchExpenseCategory();
            fetchLocation();
            getexcelDatas();
            getduePayments();
        }, []
    );

    useEffect(
        () => {
            fetchExpense();
        }, [deleteExpense]
    );

    return (
        <Box>
            <Typography sx={userStyle.HeaderText}>Expenses</Typography>
            <br />
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography sx={userStyle.HeaderText}>All Expenses</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].aexpense && (
                            <>
                                <Link to="/expense/expense/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd} >ADD</Button></Link>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid>
                        {isUserRoleCompare[0].csvexpense && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelexpense && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printexpense && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>                            </>
                        )}
                        {isUserRoleCompare[0].pdfexpense && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Box>
                    {/* ****** Table Start ****** */}
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="expensetable" ref={tableRef}>
                            <TableHead>
                                <StyledTableRow >
                                    <StyledTableCell >Action</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Date</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Reference No.</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Expense Category</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Location</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Payment status</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Tax</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Total Amount</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Payment Due</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Expense Note</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {expenses &&
                                    expenses.map((item, index) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell component="th" scope="row">
                                                    <Grid sx={{ display: 'flex' }}>
                                                        {isUserRoleCompare[0].eexpense && (
                                                            <>
                                                                <Link to={`/expense/espense/edit/${item._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                            </>
                                                        )}
                                                        {isUserRoleCompare[0].dexpense && (
                                                            <>
                                                                <Button variant="contained" color="error" size="small" onClick={(e) => { handleClickOpen(); rowDataexp(item._id) }} sx={userStyle.buttondelete}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>
                                                            </>
                                                        )}
                                                        {isUserRoleCompare[0].vexpense && (
                                                            <>
                                                                <Button sx={userStyle.buttonview} style={{ minWidth: '0px' }} onClick={(e) => rowData(item._id)} ><MoneyOutlinedIcon style={{ fontSize: 'large' }} /></Button>
                                                            </>
                                                        )}
                                                        {isUserRoleCompare[0].payexpense && (
                                                            <>
                                                                <Button disableRipple sx={userStyle.buttonpay} style={{ minWidth: '0px' }} onClick={addModOpen}><MoneyOutlinedIcon style={{ fontSize: 'large' }} /></Button>
                                                            </>
                                                        )}
                                                    </Grid>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{moment(item.expdate).format("DD-MM-YYYY")}</StyledTableCell>
                                                <StyledTableCell align="left">{item.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{item.expcategory}</StyledTableCell>
                                                <StyledTableCell align="left">{item.busilocation}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <Button size="small" variant='contained' sx={{ padding: '0px 2px', fontSize: '11px', textTransform: 'capitalize', opacity: '0.9' }}
                                                        color={item.paydue === "0" ? "success" : "info"}
                                                        onClick={(e) => { rowData(item._id); getduePayments(item.referenceno); }} >
                                                        {item.paydue === "0" ? "Paid" : "Partial"}
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">{item.exptax}</StyledTableCell>
                                                <StyledTableCell align="left">₹ {Number(item.totalamount).toFixed(2)}</StyledTableCell>
                                                <StyledTableCell align="left">₹ {Number(item.paydue).toFixed(2)}</StyledTableCell>
                                                <StyledTableCell align="left">{item.expnote}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                            <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
                                <StyledTableRow className="table2_total" >
                                    {expenses && (
                                        expenses.forEach(
                                            (item => {
                                                total += +item.totalamount;
                                                sum += +item.paydue;
                                            })
                                        ))}
                                    <StyledTableCell align="center" colSpan={5} sx={{ color: 'black', fontSize: '20px', justifyContent: 'center', border: '1px solid white !important' }}>Total:</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px' }}></StyledTableCell>
                                    <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px', border: '1px solid white !important' }}></StyledTableCell>
                                    <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px', border: '1px solid white !important' }}>₹ {total.toFixed(2)}</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px', border: '1px solid white !important' }}>₹ {sum.toFixed(2)}</StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    {/* ****** Table End ****** */}
                </Box>
            </Box>
            {/* print layout */}
            {/* ****** Table Start ****** */}
            <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" id="expensetablepdf" ref={componentRef}>
                    <TableHead>
                        <StyledTableRow >
                            <StyledTableCell align="left" >Date</StyledTableCell>
                            <StyledTableCell align="left" >Reference No.</StyledTableCell>
                            <StyledTableCell align="left" >Expense Category</StyledTableCell>
                            <StyledTableCell align="left" >Location</StyledTableCell>
                            <StyledTableCell align="left" >Payment status</StyledTableCell>
                            <StyledTableCell align="left" >Tax</StyledTableCell>
                            <StyledTableCell align="left" >Total Amount</StyledTableCell>
                            <StyledTableCell align="left" >Payment Due</StyledTableCell>
                            <StyledTableCell align="left" >Expense Note</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {expenses &&
                            expenses.map((item, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{moment(item.expdate).format("DD-MM-YYYY")}</StyledTableCell>
                                        <StyledTableCell align="left">{item.referenceno}</StyledTableCell>
                                        <StyledTableCell align="left">{item.expcategory}</StyledTableCell>
                                        <StyledTableCell align="left">{item.busilocation}</StyledTableCell>
                                        <StyledTableCell align="left"></StyledTableCell>
                                        <StyledTableCell align="left">{item.exptax}</StyledTableCell>
                                        <StyledTableCell align="left">{Number(item.totalamount).toFixed(2)}</StyledTableCell>
                                        <StyledTableCell align="left">{Number(item.paydue).toFixed(2)}</StyledTableCell>
                                        <StyledTableCell align="left">{item.expnote}</StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
                        <StyledTableRow className="table2_total" >
                            {expenses && (
                                expenses.forEach(
                                    (item => {
                                        total += +item.totalamount;
                                        sum += +item.paydue;
                                    })
                                ))}
                            <StyledTableCell align="center" colSpan={4} sx={{ color: 'black', fontSize: '20px', justifyContent: 'center' }}>Total:</StyledTableCell>
                            <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px' }}></StyledTableCell>
                            <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px' }}></StyledTableCell>
                            <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px' }}>{total.toFixed(2)}</StyledTableCell>
                            <StyledTableCell align="left" sx={{ color: 'black', fontSize: '16px' }}>{sum.toFixed(2)}</StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                        </StyledTableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            {/* printlayout ends */}
            {/* View Payment Modal */}
            <Box>
                <form>
                    <Dialog
                        onClose={viewModClose}
                        aria-labelledby="customized-dialog-title1"
                        open={viewMod}
                        sx={userStyle.textInput}
                    >
                        <DialogTitle id="customized-dialog-title1">
                            View Payments ( Reference No: {expenseForm.referenceno} )
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container spacing={3}>
                                <Grid item md={4} sm={12} xs={12} display="block">
                                    <Typography variant="subtitle1" >Expense for: <br /> {expenseForm.expcontact}<br /></Typography>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12} display="block">
                                    <Typography variant="subtitle1" >Business: <br /> {expenseForm.busilocation}<br /></Typography>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12} display="block">
                                    <Typography variant="subtitle1" ><b>Reference No: </b> {expenseForm.referenceno}<br /> <b>Date : </b>{moment(expenseForm.expdate).format("DD-MM-YYYY")} <br /> <b>Payment Status: </b>  {expenseForm.paydue === "0" ? "Paid" : "Partial"} </Typography>
                                </Grid>
                            </Grid><br />
                            <Grid container spacing={1} sx={{ justifyContent: 'right' }}>
                                <Grid >
                                    <Button variant='contained' sx={userStyle.buttonadd} onClick={addModOpen}>+ Add Payment</Button>
                                </Grid>
                            </Grid>
                            <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Reference No</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Payment Method</TableCell>
                                            <TableCell>Payment Note</TableCell>
                                            <TableCell>Payment Account</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>{moment(expenseForm.expdate).format("DD-MM-YYYY")}</TableCell>
                                            <TableCell>{expenseForm.referenceno}</TableCell>
                                            <TableCell>{Number(expenseForm.expamount).toFixed(2)}</TableCell>
                                            <TableCell>{expenseForm.paymethod}</TableCell>
                                            <TableCell>{expenseForm.paynotes}</TableCell>
                                            <TableCell>{expenseForm.payaccount}</TableCell>
                                        </TableRow>
                                        {duePayments.length > 0 &&
                                            duePayments.map((item, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{item.date}</TableCell>
                                                        <TableCell>{item.expid}</TableCell>
                                                        <TableCell>{item.dueamt}</TableCell>
                                                        <TableCell>{item.paymethod}</TableCell>
                                                        <TableCell>{item.paynotes}</TableCell>
                                                        <TableCell>{item.paymentaccount}</TableCell>
                                                        <TableCell>
                                                            <Button size="small" sx={{ backgroundColor: '#5bc0de', borderColor: '#46b8da', color: 'white', height: '20px !important', maxWidth: '20px !important', '&:hover': { backgroundColor: '#5bc0de !important', } }}><VisibilityOutlinedIcon style={{ fontSize: '15px', margin: '1px' }} /></Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus variant='contained' color="primary" onClick={handleprintModal} >&ensp;<FaPrint />&ensp;Print </Button>
                            <Button onClick={viewModClose} variant='contained' color="error">Close</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>

            {/* Add Payment Modal */}
            <Box>
                <form>
                    <Dialog
                        onClose={addModClose}
                        aria-labelledby="customized-dialog-title1"
                        open={addMod}
                        sx={userStyle.textInput}
                    >
                        <DialogTitle id="customized-dialog-title1"> Add payment </DialogTitle>
                        <DialogContent dividers>
                            <FormControl size="small" fullWidth sx={{ visibility: 'hidden' }} >
                                <OutlinedInput
                                    id="component-outlined"
                                    value={duePayments.payduecalc}
                                    onClick={(e) => { setduePayments({ ...duePayments, payduecalc: e.target.value }); }}
                                    label="Amount *"
                                    type='text'
                                    name="getid"
                                />
                            </FormControl>
                            <FormControl size="small" fullWidth sx={{ visibility: 'hidden' }}>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={expenseForm.referenceno}
                                    onClick={(e) => { setduePayments({ ...duePayments, expid: expenseForm.referenceno }) }}
                                    label="Amount *"
                                    type='text'
                                    name="expid"
                                />
                            </FormControl>
                            <Grid container spacing={3}>
                                <Grid item md={4} sm={12} xs={12} display="block" fullWidth>
                                    <Box sx={{ background: '#f5f5f5', padding: '10px' }}>
                                        <Typography variant="subtitle1" ><b>Reference No:</b> <br />{expenseForm.referenceno}<br /><b>Location:</b>{expenseForm.busilocation} </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12} display="block" fullWidth>
                                    <Box sx={{ background: '#f5f5f5', padding: '10px' }}>
                                        <Typography variant="subtitle1"><b>Total amount: ₹ </b>{Number(expenseForm.totalamount).toFixed(2)}<br /> <b>Payment Note: -- : </b> </Typography>
                                    </Box>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12} display="block"></Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <Grid sx={userStyle.spanIcons}><MoneyOutlinedIcon /></Grid>
                                        <FormControl size="small" fullWidth >
                                            <InputLabel htmlFor="component-outlined" >Amount</InputLabel>
                                            <OutlinedInput
                                                id="component-outlined"
                                                value={duePayments.dueamt}
                                                onChange={(e) => { setduePayments({ ...duePayments, dueamt: e.target.value, expid: expenseForm.referenceno, payduecalc: expenseForm.paydue - +e.target.value }); }}
                                                label="Amount *"
                                                type='number'
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} sm={12} xs={12}>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <FormControl size="small" fullWidth >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={3}>
                                                    <TextField
                                                        type="datetime-local"
                                                        value={duePayments.date}
                                                        onChange={(e) => { setduePayments({ ...duePayments, date: e.target.value }) }}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <Grid sx={userStyle.spanIcons}><MoneyOutlinedIcon /></Grid>
                                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                            <InputLabel id="demo-select-small">Payment Method *</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={duePayments.paymentmethod}
                                                onChange={(e) => { setduePayments({ ...duePayments, paymentmethod: e.target.value }) }}
                                                label="Payment Method *"
                                                name="paymethod"
                                                type='text'
                                                fullWidth
                                            >
                                                <MenuItem value="Cash">Cash</MenuItem>
                                                <MenuItem value="Card">Card</MenuItem>
                                                <MenuItem value="Cheque">Cheque</MenuItem>
                                                <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <Grid sx={userStyle.spanIcons}><MoneyOutlinedIcon /></Grid>
                                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                            <InputLabel id="demo-select-small">Payment Account</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={duePayments.paymentaccount}
                                                onChange={(e) => { setduePayments({ ...duePayments, paymentaccount: e.target.value }) }}
                                                label="Payment Account"
                                                name="payaccount"
                                                type='text'
                                                fullWidth
                                            >
                                                <MenuItem value="None">None</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <FormControl size="small" fullWidth>
                                        <Button
                                            component="label"
                                            sx={userStyle.uploadBtn}
                                        >
                                            Upload Image
                                            <input
                                                type="file"
                                                hidden
                                                name="image"
                                                value={duePayments.dueimg}
                                                onChange={(e) => { setduePayments({ ...duePayments, dueimg: e.target.value }) }}

                                            />
                                        </Button>
                                        <Typography variant='body2' sx={{ opacity: '0.9', mt: 1 }}>Max File size: 5MB <br /> Allowed File: .pdf, .csv, .zip, .doc, .docx, .jpeg, .jpg, .png</Typography>
                                    </FormControl>
                                </Grid>
                                {/* ****** Dropdown options ****** */}
                                {/* ****** Card Section ****** */}
                                {duePayments.paymentmethod === "Card" &&
                                    (
                                        <>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Card Number</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.cardnum}
                                                        onChange={(e) => { setduePayments({ ...duePayments, cardnum: e.target.value }) }}
                                                        label="Card Number"
                                                        type='text'
                                                        name="cardnum"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Card Holder Name</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.cardhname}
                                                        onChange={(e) => { setduePayments({ ...duePayments, cardhname: e.target.value }) }}
                                                        label="Card Holder Name"
                                                        type="text"
                                                        name="cardhname"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={4} sm={6} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Card Transaction No</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.cardtransnum}
                                                        onChange={(e) => { setduePayments({ ...duePayments, cardtransnum: e.target.value }) }}
                                                        label="Card Transaction No"
                                                        type='text'
                                                        name="cardtransnum"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={3} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                                    <InputLabel id="demo-select-small">Card Type</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={duePayments.cardtype}
                                                        onChange={(e) => { setduePayments({ ...duePayments, cardtype: e.target.value }) }}
                                                        label="Card Type"
                                                        type='text'
                                                        fullWidth
                                                    >
                                                        <MenuItem value="Credit Card">Credit Card</MenuItem>
                                                        <MenuItem value="Debit Card">Debit Card</MenuItem>
                                                        <MenuItem value="Visa">Visa</MenuItem>
                                                        <MenuItem value="MasterCard">MasterCard</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={3} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Month</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.month}
                                                        onChange={(e) => { setduePayments({ ...duePayments, month: e.target.value }) }}
                                                        label="Month"
                                                        type='text'
                                                        name="month"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={3} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Year</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.year}
                                                        onChange={(e) => { setduePayments({ ...duePayments, year: e.target.value }) }}
                                                        label="Year"
                                                        type='text'
                                                        name="year"
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={3} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Security Code</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.securitycode}
                                                        onChange={(e) => { setduePayments({ ...duePayments, securitycode: e.target.value }) }}
                                                        label="Security Code"
                                                        type='text'
                                                        name="securitycode"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {/* ****** Cheque Section ****** */}
                                {duePayments.paymentmethod === "Cheque" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Cheque No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.checkno}
                                                        onChange={(e) => { setduePayments({ ...duePayments, checkno: e.target.value }) }}
                                                        label="Cheque No."
                                                        type='text'
                                                        name="checkno"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {/* ****** Bank Section ****** */}
                                {duePayments.paymentmethod === "Bank Transfer" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Bank Account No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.baccno}
                                                        onChange={(e) => { setduePayments({ ...duePayments, baccno: e.target.value }) }}
                                                        label="Bank Account No."
                                                        type='text'
                                                        name="baccno"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>

                                    )
                                }
                                {/* ****** Transaction Section Start ****** */}
                                {duePayments.paymentmethod === "Custom Payment 1" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Transaction No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.transnum1}
                                                        onChange={(e) => { setduePayments({ ...duePayments, transnum1: e.target.value }) }}
                                                        label="Transaction No."
                                                        type='text'
                                                        name="transnum1"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {duePayments.paymentmethod === "Custom Payment 2" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Transaction No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.transnum2}
                                                        onChange={(e) => { setduePayments({ ...duePayments, transnum2: e.target.value }) }}
                                                        label="Transaction No."
                                                        type='text'
                                                        name="transnum2"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {duePayments.paymentmethod === "Custom Payment 3" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Transaction No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.transnum3}
                                                        onChange={(e) => { setduePayments({ ...duePayments, transnum3: e.target.value }) }}
                                                        label="Transaction No."
                                                        type='text'
                                                        name="transnum3"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {duePayments.paymentmethod === "Custom Payment 4" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Transaction No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.transnum4}
                                                        onChange={(e) => { setduePayments({ ...duePayments, transnum4: e.target.value }) }}
                                                        label="Transaction No."
                                                        type='text'
                                                        name="transnum4"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {duePayments.paymentmethod === "Custom Payment 5" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Transaction No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.transnum5}
                                                        onChange={(e) => { setduePayments({ ...duePayments, transnum5: e.target.value }) }}
                                                        label="Transaction No."
                                                        type='text'
                                                        name="transnum5"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {duePayments.paymentmethod === "Custom Payment 6" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Transaction No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.transnum6}
                                                        onChange={(e) => { setduePayments({ ...duePayments, transnum6: e.target.value }) }}
                                                        label="Transaction No."
                                                        type='text'
                                                        name="transnum6"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {duePayments.paymentmethod === "Custom Payment 7" &&
                                    (
                                        <>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <FormControl size="small" fullWidth >
                                                    <InputLabel htmlFor="component-outlined" >Transaction No.</InputLabel>
                                                    <OutlinedInput
                                                        id="component-outlined"
                                                        value={duePayments.transnum7}
                                                        onChange={(e) => { setduePayments({ ...duePayments, transnum7: e.target.value }) }}
                                                        label="Transaction No."
                                                        type='text'
                                                        name="transnum7"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )
                                }
                                {/* *************** End ************ */}
                                <Grid item md={12} sm={12} xs={12}>
                                    <InputLabel id="demo-select-small" sx={{ m: 1 }}>Payment Note</InputLabel>
                                    <FormControl size="small" fullWidth >
                                        <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                            value={duePayments.paynotes}
                                            onChange={(e) => { setduePayments({ ...duePayments, paynotes: e.target.value }) }}
                                            name="paynotes"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus variant='contained' color="primary" onClick={() => { addExpenseSubmit() }}>SAVE</Button>
                            <Button onClick={addModClose} variant='contained' color="error">CLOSE</Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </Box>

            <Box sx={userStyle.printcls} ref={componentRefModal}>
                View Payments ( Reference No: {expenseForm.referenceno} )
                <Grid container spacing={3}>
                    <Grid item md={4} sm={12} xs={12} display="block">
                        <Typography variant="subtitle1" >Expense for: <br /> {expenseForm.expcontact}<br /></Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} display="block">
                        <Typography variant="subtitle1" >Business: <br /> {expenseForm.busilocation}<br /></Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12} display="block">
                        <Typography variant="subtitle1" ><b>Reference No: </b> {expenseForm.referenceno}<br /> <b>Date : </b>{moment(expenseForm.expdate).format("DD-MM-YYYY")} <br /> <b>Payment Status: </b>  {expenseForm.paydue === "0" ? "Paid" : "Partial"} </Typography>
                    </Grid>
                </Grid><br />
                <Grid container spacing={1} sx={{ justifyContent: 'right' }}>
                </Grid>
                <TableContainer component={Paper} sx={userStyle.tablecontainer} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Reference No</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Payment Method</TableCell>
                                <TableCell>Payment Note</TableCell>
                                <TableCell>Payment Account</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{moment(expenseForm.expdate).format("DD-MM-YYYY")}</TableCell>
                                <TableCell>{expenseForm.referenceno}</TableCell>
                                <TableCell>{Number(expenseForm.duppaydue).toFixed(2)}</TableCell>
                                <TableCell>{expenseForm.paymethod}</TableCell>
                                <TableCell>{expenseForm.paynotes}</TableCell>
                                <TableCell>{expenseForm.payaccount}</TableCell>
                            </TableRow>
                            {duePayments.length > 0 &&
                                duePayments.map((item, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>{item.date}</TableCell>
                                            <TableCell>{item.expid}</TableCell>
                                            <TableCell>{item.dueamt}</TableCell>
                                            <TableCell>{item.paymethod}</TableCell>
                                            <TableCell>{item.paynotes}</TableCell>
                                            <TableCell>{item.paymentaccount}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* ALERT DIALOG */}
            <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                    <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete} variant="outlined">Cancel</Button>
                    <Button onClick={(e) => deleteExpense(expid)} autoFocus variant="contained" color='error'> OK  </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
function Expenselist() {
    return (
        <>
            <Box >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Expensestablelist /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Expenselist;