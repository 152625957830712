import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, Grid, Paper, Table, TableBody, TableHead, DialogActions, DialogContent, Dialog, TableContainer, Button } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import moment from 'moment';

function Voucherlisttable() {

    const [vouchers, setVouchers] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);
    const [iscustomer, setIsCustomer] = useState({});

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // Fetch Voucher data
    const fetchVoucher = async () => {
        try {
            let req = await axios.get(SERVICE.VOUCHER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setVouchers(req.data.vouchers);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Delete popup
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => { setIsDeleteOpen(true); };
    const handleClose = () => { setIsDeleteOpen(false); };

    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.VOUCHER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setIsCustomer(res.data.svoucher);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // alert delete popup
    let cusid = iscustomer._id;

    const deleteVoucher = async (cusid) => {
        try {
            let res = await axios.delete(`${SERVICE.VOUCHER_SINGLE}/${cusid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Voucher List'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = vouchers.map(t => ({
            'Voucher No': t.voucherno, 'Date': t.date, 'From To': t.fromto, 'For What': t.forwhat, 'Amount': t.amount, 'Pay Mode': t.paymode,
            'Account Group': t.accountgroup, 'Entry': t.entry, 'Remarks': t.remarks,
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | VOUCHER LIST',
        pageStyle: 'print'
    });

    // PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#vouchertable' })
        doc.save('Voucher List.pdf')
    }

    useEffect(
        () => {
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        }, [(tableRef.current)]
    )

    useEffect(
        () => {
            fetchVoucher();
            getexcelDatas();
        }, [vouchers]
    )

    return (
        <Box >
            <Headtitle title={'Voucher List'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>List Voucher</Typography>
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container spacing={2}>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            {isUserRoleCompare[0].addvoucherlist && (
                                <>
                                    <Link to="/account/voucher/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvvoucherlist && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelvoucherlist && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printvoucherlist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfvoucherlist && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />

                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow>
                                    <StyledTableCell>Actions</StyledTableCell>
                                    <StyledTableCell>Voucher No</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>From To</StyledTableCell>
                                    <StyledTableCell>For What</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Pay Mode</StyledTableCell>
                                    <StyledTableCell>Account Group</StyledTableCell>
                                    <StyledTableCell>Entry</StyledTableCell>
                                    <StyledTableCell>Remarks</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {vouchers.length > 0 && (
                                    vouchers.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row" colSpan={1}>
                                                <Grid sx={{ display: 'flex' }}>
                                                    {isUserRoleCompare[0].evoucherlist && (<Link to={`/account/voucher/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                    {isUserRoleCompare[0].vvoucherlist && <Link to={`/account/voucher/view/${row._id}`} style={{ textDecoration: 'none', color: 'white', }}><Button sx={userStyle.buttonview}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>}
                                                    {isUserRoleCompare[0].dvoucherlist && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'small' }} /></Button>)}
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.voucherno}</StyledTableCell>
                                            <StyledTableCell>{moment(row.purchasedate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{row.fromto}</StyledTableCell >
                                            <StyledTableCell align="left">{row.forwhat}</StyledTableCell>
                                            <StyledTableCell align="left">{row.amount}</StyledTableCell>
                                            <StyledTableCell align="left">{row.paymode}</StyledTableCell>
                                            <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                                            <StyledTableCell align="left">{row.entry}</StyledTableCell>
                                            <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}

                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined">Cancel</Button>
                            <Button onClick={(e) => deleteVoucher(cusid)} autoFocus variant="contained" color='error'> OK </Button>
                        </DialogActions>
                    </Dialog>
                    { /* Table End */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} >
                <Box>
                    <Typography variant='h5' >Voucher List Print</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="vouchertable" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Voucher No</StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>From To</StyledTableCell>
                                        <StyledTableCell>For What</StyledTableCell>
                                        <StyledTableCell>Amount</StyledTableCell>
                                        <StyledTableCell>Pay Mode</StyledTableCell>
                                        <StyledTableCell>Account Group</StyledTableCell>
                                        <StyledTableCell>Entry</StyledTableCell>
                                        <StyledTableCell>Remarks</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {vouchers.length > 0 && (
                                        vouchers?.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left"> {row.voucherno}</StyledTableCell>
                                                <StyledTableCell>{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                                <StyledTableCell align="left" >{row.fromto}</StyledTableCell >
                                                <StyledTableCell align="left">{row.forwhat}</StyledTableCell>
                                                <StyledTableCell align="left">{row.amount}</StyledTableCell>
                                                <StyledTableCell align="left">{row.paymode}</StyledTableCell>
                                                <StyledTableCell align="left">{row.accountgroup}</StyledTableCell>
                                                <StyledTableCell align="left">{row.entry}</StyledTableCell>
                                                <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Voucherlist() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Voucherlisttable /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Voucherlist;