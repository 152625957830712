import React, { useState, useMemo, useEffect, } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
// plugin
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import Applicationstack from './routes/Applicationstack';
import Authstack from './routes/Authstack';
import { AuthContext, UserRoleAccessContext } from './context/Appcontext';

function App() {

  // Auth login state for access user to dashboard
  const [auth, setAuth] = useState({ loader: true, loginState: false, APIToken:"", loginuserid:"" })
  const [forgotAuth, setForgotAuth] = useState({ email: "", password: "", cpassword: "" })
  const [setingsChange, setSetingsChange] = useState();
  const [isUserRoleAccess, setIsUserRoleAccess] = useState({});
  const [isAllRole, setIsAllRole] = useState();
  const [isUserRoleCompare, setIsUserRoleCompare] = useState();

  const authContextData = useMemo(() => {
    return { auth, setAuth, forgotAuth, setForgotAuth, setingsChange, setSetingsChange}
  })
  const applicationContextData = useMemo(() => {
    return {isUserRoleAccess, setIsUserRoleAccess, isUserRoleCompare, setIsUserRoleCompare}
  })


  useEffect(()=> {
    isCheckUserLogin();
  },[]);
  const isCheckUserLogin = async () => {
    let getApiToken = localStorage.getItem('APIToken');
    let getLoginUserid = localStorage.getItem('LoginUserId');
    if(getApiToken){
      
        try{
          let loginuserdata = await axios.get(`http://localhost:8003/api/auth/${getLoginUserid}`,
          {
            headers: {
              'Authorization':`Bearer ${getApiToken}`
            }
          })

          let userrole = await axios.get(`http://localhost:8003/api/roles`,{
            headers: {
              'Authorization':`Bearer ${getApiToken}`
            }
          })
          setIsAllRole(userrole.data.roles)
          setIsUserRoleAccess(loginuserdata.data.suser);
          userRoleCompare(userrole.data.roles,loginuserdata.data.suser)
          setAuth((prevAuth)=> {
            return {...prevAuth,loader : false,loginState : true, APIToken : getApiToken, loginuserid: getLoginUserid}
        });
        }catch(err){
          console.log(err.response.data.message);
        }
        // return backPage('/dashboard')
    }else{
        setAuth({...auth, loader:false, loginState: false})
        // return backPage('/signin')
    }  
}

const userRoleCompare = (roledata, userroledata) =>{
  let getuserrolecomparedata = roledata.filter((item, i)=>{
    if(userroledata.role == item.rolename){
      return item
    }
  })
  setIsUserRoleCompare(getuserrolecomparedata);
}
 
  return (
    <>
      <div>
        <AuthContext.Provider value={authContextData}>
          <UserRoleAccessContext.Provider value={applicationContextData}>
            {/* <Testloacl /> */}
            {/* {auth.loader ? <Loaderstack /> : ( */}
            {!auth.loginState ? <Authstack /> : <Applicationstack /> }
            {/* ) } */}
          </UserRoleAccessContext.Provider>
        </AuthContext.Provider>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;