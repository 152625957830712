import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { Box, Typography, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button, FormGroup, FormControlLabel, Checkbox, DialogTitle, DialogContentText, Dialog, DialogActions, DialogContent } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { userStyle } from '../../../PageStyle';
import Navbar from '../../../../components/header/Navbar';
import Footer from '../../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';
import $ from 'jquery';
import { AuthContext } from '../../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from "../../../../services/Baseservice";

function Supplierlisttable() {

    const [suppliers, setSuppliers] = useState([]);
    const [deletesup, setDeletesup] = useState({});
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // User Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Pdffield
    const [isPdfData, setIsPdfData] = useState({
        isSupplierId: false, isSupplierName: false, isAddressOne: false, isAddressTwo: false,
        isCountry: false, isState: false, isCity: false, isPincode: false, isEmail: false, isGstn: false,
        isPhone: false, isPhtwo: false, isPhthree: false, isPhfour: false, isLandline: false, isWhatsapp: false,
        isPurDue: false, isPurRtnDue: false, isContact: false, isCreditdays: false, isDlnos: false,
    })

    // jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => { setIsDeleteOpen(true); };
    const handleClose = () => { setIsDeleteOpen(false); };

    // Pdf
    const [openPdf, setOpenPdf] = useState(false);
    const handleOpenPdf = () => { setOpenPdf(true) };
    const handleClosePdf = () => { setOpenPdf(false) };

    // Suppliers
    const fetchSuppliers = async () => {
        try {
            let req = await axios.get(SERVICE.SUPPLIER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSuppliers(req.data.suppliers);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Set function to get particular row
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.SUPPLIER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDeletesup(res.data.ssupplier);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Alert delete popup
    let supid = deletesup._id;
    const deleteSupplier = async () => {
        try {
            let res = await axios.delete(`${SERVICE.SUPPLIER_SINGLE}/${supid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchSuppliers();
        }, [deleteSupplier]
    )

    // Excel
    const fileName = 'Suppliers'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = suppliers.map(t => ({
            "Supplier Code": t.autogenerate, "Supplier Name": t.suppliername, "Address1": t.addressone, "Address2": t.addresstwo,
            "Country": t.country, "State": t.state, "City": t.city, "Pincode": t.pincode, "Email": t.email, "GSTN": t.gstn, "Mobile No": t.phoneone,
            "Landline": t.landline, "Whatsapp": t.whatsapp, "Contactperson": t.contactperson, "Creditdays": t.creditdays, "Dlnos": t.dlnos
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | SUPPLIERS LIST',
        pageStyle: 'print'
    });

    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    // PDF
    var nestedArray = [];

    {
        suppliers && (suppliers.map((item, index) => {
            var cateData = [
                item.autogenerate,
                item.suppliername,
                item.addressone,
                item.addresstwo,
                item.country,
                item.state,
                item.city,
                item.pincode,
                item.email,
                item.gstn,
                item.phoneone,
                item.phonetwo,
                item.phonethree,
                item.phonefour,
                item.landline,
                item.whatsapp,
                item.contactperson,
                item.creditdays,
                item.dlnos
            ]
            nestedArray.push(cateData);
        })
        )
    }

    const downloadPdf = () => {
        const newData = suppliers.map(row => {
            delete row._id;
            delete row.createdAt;
            delete row.__v;
            { !isPdfData.isSupplierId && delete row.autogenerate };
            { !isPdfData.isSupplierName && delete row.suppliername };
            { !isPdfData.isAddressOne && delete row.addressone };
            { !isPdfData.isAddressTwo && delete row.addresstwo };
            { !isPdfData.isCountry && delete row.country };
            { !isPdfData.isState && delete row.state };
            { !isPdfData.isCity && delete row.city };
            { !isPdfData.isPincode && delete row.pincode };
            { !isPdfData.isEmail && delete row.email };
            { !isPdfData.isGstn && delete row.gstn };
            { !isPdfData.isPhone && delete row.phoneone };
            { !isPdfData.isPhtwo && delete row.phonetwo };
            { !isPdfData.isPhthree && delete row.phonethree };
            { !isPdfData.isPhfour && delete row.phonefour };
            { !isPdfData.isLandline && delete row.landline };
            { !isPdfData.isWhatsapp && delete row.whatsapp };
            { !isPdfData.isContact && delete row.contactperson };
            { !isPdfData.isCreditdays && delete row.creditdays };
            { !isPdfData.isDlnos && delete row.dlnos };

            setIsPdfData(row)
        })

        const doc = new jsPDF()
        doc.autoTable({
            theme: "grid",
            body: suppliers,
        })
        doc.save('Suppliers.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [suppliers]
    )

    useEffect(() => {

        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });

    }, [(tableRef.current)])

    return (
        <Box >
            <Headtitle title={'Suppliers'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Suppliers  <Typography sx={userStyle.SubHeaderText}>Manage your Suppliers</Typography></Typography>

            { /* ****** Table Start ****** */}
            <>

                <Box sx={userStyle.container} >
                    { /* ****** Header Content ****** */}
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography sx={userStyle.importheadtext}>All your Suppliers</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            {isUserRoleCompare[0].asupplier && (
                                <>
                                    <Link to={'/contact/supplier/create'} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button variant="contained" sx={userStyle.buttonadd}>ADD</Button></Link>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvsupplier && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelsupplier && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printsupplier && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfsupplier && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => handleOpenPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="suppliertable" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Actions</StyledTableCell>
                                    <StyledTableCell>Supplier Code</StyledTableCell>
                                    <StyledTableCell>Supplier Name</StyledTableCell>
                                    <StyledTableCell>Address1</StyledTableCell>
                                    <StyledTableCell>Address2</StyledTableCell>
                                    <StyledTableCell>Country</StyledTableCell>
                                    <StyledTableCell>State</StyledTableCell>
                                    <StyledTableCell>City</StyledTableCell>
                                    <StyledTableCell>Pincode</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>GSTN</StyledTableCell>
                                    <StyledTableCell>Mobile</StyledTableCell>
                                    <StyledTableCell>Landline</StyledTableCell>
                                    <StyledTableCell>Whatsapp</StyledTableCell>
                                    <StyledTableCell>Contact Person Name</StyledTableCell>
                                    <StyledTableCell>Credit Days</StyledTableCell>
                                    <StyledTableCell>DL No</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {suppliers.length > 0 && (
                                    suppliers.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row" colSpan={1}>
                                                <Grid sx={{ display: 'flex' }}>
                                                    {isUserRoleCompare[0].esupplier && (<Link to={`/contact/supplier/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                    {isUserRoleCompare[0].dsupplier && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>)}
                                                    {isUserRoleCompare[0].vsupplier && (<Link to={`/contact/supplier/view/${row._id}`} style={{ textDecoration: 'none', color: 'white', }}><Button sx={userStyle.buttonview}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.autogenerate}</StyledTableCell>
                                            <StyledTableCell align="left">{row.suppliername}</StyledTableCell>
                                            <StyledTableCell align="left">{row.addressone}</StyledTableCell >
                                            <StyledTableCell align="left">{row.addresstwo}</StyledTableCell>
                                            <StyledTableCell align="left">{row.country}</StyledTableCell>
                                            <StyledTableCell align="left">{row.state}</StyledTableCell>
                                            <StyledTableCell align="left">{row.city}</StyledTableCell>
                                            <StyledTableCell align="left">{row.pincode}</StyledTableCell>
                                            <StyledTableCell align="left">{row.email}</StyledTableCell>
                                            <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                            <StyledTableCell align="left">{row.phoneone}</StyledTableCell>
                                            <StyledTableCell align="left">{row.landline}</StyledTableCell>
                                            <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                                            <StyledTableCell align="left">{row.contactperson}</StyledTableCell>
                                            <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                                            <StyledTableCell align="left">{row.dlnos}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Table End ****** */}

            {/* Delete Modal */}
            <Box>
                {/* ALERT DIALOG */}
                <Dialog
                    open={isDeleteOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined">Cancel</Button>
                        <Button autoFocus variant="contained" color='error' onClick={(e) => deleteSupplier(supid)}> OK </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} >
                <Box>
                    <Typography variant='h5' >Suppliers</Typography>
                </Box>
                <>
                    <Box>
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="suppliertable2" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Supplier Code</StyledTableCell>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                        <StyledTableCell>Address1</StyledTableCell>
                                        <StyledTableCell>Address2</StyledTableCell>
                                        <StyledTableCell>Country</StyledTableCell>
                                        <StyledTableCell>State</StyledTableCell>
                                        <StyledTableCell>City</StyledTableCell>
                                        <StyledTableCell>Pincode</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>GSTN</StyledTableCell>
                                        <StyledTableCell>Mobile</StyledTableCell>
                                        <StyledTableCell>Landline</StyledTableCell>
                                        <StyledTableCell>Whatsapp</StyledTableCell>
                                        <StyledTableCell>Contact Person Name</StyledTableCell>
                                        <StyledTableCell>Credit Days</StyledTableCell>
                                        <StyledTableCell>DL No</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {suppliers.length > 0 && (
                                        suppliers.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.autogenerate}</StyledTableCell>
                                                <StyledTableCell align="left">{row.suppliername}</StyledTableCell>
                                                <StyledTableCell align="left">{row.addressone}</StyledTableCell >
                                                <StyledTableCell align="left">{row.addresstwo}</StyledTableCell>
                                                <StyledTableCell align="left">{row.country}</StyledTableCell>
                                                <StyledTableCell align="left">{row.state}</StyledTableCell>
                                                <StyledTableCell align="left">{row.city}</StyledTableCell>
                                                <StyledTableCell align="left">{row.pincode}</StyledTableCell>
                                                <StyledTableCell align="left">{row.email}</StyledTableCell>
                                                <StyledTableCell align="left">{row.gstn}</StyledTableCell>
                                                <StyledTableCell align="left">{row.phoneone}</StyledTableCell>
                                                <StyledTableCell align="left">{row.landline}</StyledTableCell>
                                                <StyledTableCell align="left">{row.whatsapp}</StyledTableCell>
                                                <StyledTableCell align="left">{row.contactperson}</StyledTableCell>
                                                <StyledTableCell align="left">{row.creditdays}</StyledTableCell>
                                                <StyledTableCell align="left">{row.dlnos}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>

            {/* PDF Model */}
            <Box>
                <Dialog
                    open={openPdf}
                    onClose={handleClosePdf}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <DialogTitle id="alert-dialog-title">
                        Select Option to Print PDF
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography variant="subtitle1">Choose any 6</Typography>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isSupplierId} onChange={(e) => { setIsPdfData({ ...isPdfData, isSupplierId: !isPdfData.isSupplierId }) }} />} label="Supplier Code" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isSupplierName} onChange={(e) => setIsPdfData({ ...isPdfData, isSupplierName: !isPdfData.isSupplierName })} />} label="Supplier Name" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isAddressOne} onChange={(e) => setIsPdfData({ ...isPdfData, isAddressOne: !isPdfData.isAddressOne })} />} label="Address 1" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isAddressTwo} onChange={(e) => setIsPdfData({ ...isPdfData, isAddressTwo: !isPdfData.isAddressTwo })} />} label="Address 2" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCountry} onChange={(e) => setIsPdfData({ ...isPdfData, isCountry: !isPdfData.isCountry })} />} label="Country" />

                                    </FormGroup> </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isState} onChange={(e) => setIsPdfData({ ...isPdfData, isState: !isPdfData.isState })} />} label="State" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCity} onChange={(e) => setIsPdfData({ ...isPdfData, isCity: !isPdfData.isCity })} />} label="City" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isPincode} onChange={(e) => setIsPdfData({ ...isPdfData, isPincode: !isPdfData.isPincode })} />} label="Pincode" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isEmail} onChange={(e) => setIsPdfData({ ...isPdfData, isEmail: !isPdfData.isEmail })} />} label="Email" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isGstn} onChange={(e) => setIsPdfData({ ...isPdfData, isGstn: !isPdfData.isGstn })} />} label="GSTN" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isPhone} onChange={(e) => setIsPdfData({ ...isPdfData, isPhone: !isPdfData.isPhone })} />} label="Phone1" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isPhtwo} onChange={(e) => setIsPdfData({ ...isPdfData, isPhtwo: !isPdfData.isPhtwo })} />} label="Phone2" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isPhthree} onChange={(e) => setIsPdfData({ ...isPdfData, isPhthree: !isPdfData.isPhthree })} />} label="Phone3" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isPhfour} onChange={(e) => setIsPdfData({ ...isPdfData, isPhfour: !isPdfData.isPhfour })} />} label="Phone4" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isLandline} onChange={(e) => setIsPdfData({ ...isPdfData, isLandline: !isPdfData.isLandline })} />} label="Landline" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isWhatsapp} onChange={(e) => setIsPdfData({ ...isPdfData, isWhatsapp: !isPdfData.isWhatsapp })} />} label="Whatsapp" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isContact} onChange={(e) => setIsPdfData({ ...isPdfData, isContact: !isPdfData.isContact })} />} label="Contact Person" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCreditdays} onChange={(e) => setIsPdfData({ ...isPdfData, isCreditdays: !isPdfData.isCreditdays })} />} label="Credit Days" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isDlnos} onChange={(e) => setIsPdfData({ ...isPdfData, isDlnos: !isPdfData.isDlnos })} />} label="Dl nos" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color='primary' onClick={() => downloadPdf()} autoFocus>PDF</Button>
                        <Button variant='contained' color='error' onClick={handleClosePdf}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Supplierlist() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Supplierlisttable /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Supplierlist;