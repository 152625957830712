import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Grid, Box, Dialog, DialogContent, DialogActions, Typography, Table, TableHead, TableContainer, TableBody, Paper } from '@mui/material';
import { FaFilePdf, FaPrint } from 'react-icons/fa';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import autoTable from 'jspdf-autotable';
import { Link } from 'react-router-dom';
import jsPDF from "jspdf";
import Headtitle from '../../../components/header/Headtitle';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ExportXL, ExportCSV } from '../../Export';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { useReactToPrint } from "react-to-print";

const Taxratelisttable = () => {

  const [taxRate, setTaxRate] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const [tax, setTax] = useState({});
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);


  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // Get Datas
  const fetchTaxrate = async () => {
    try {
      let response = await axios.get(SERVICE.TAXRATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      setTaxRate(response.data.taxrates)
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //delete model
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const rowData = async (id) => {
    try {
      let response = await axios.get(`${SERVICE.TAXRATE_SINGLE}/${id}`,
        {
          headers: {
            'Authorization': `Bearer ${auth.APIToken}`
          }
        });
      setTax(response.data.staxrate);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }
  let taxid = tax._id;

  // Delete 
  const deleteTaxRate = async (taxid) => {
    try {
      let response = await axios.delete(`${SERVICE.TAXRATE_SINGLE}/${taxid}`,
        {
          headers: {
            'Authorization': `Bearer ${auth.APIToken}`
          }
        });
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Excel
  const fileName = 'Tax Rate'

  // get particular columns for export excel
  const getexcelDatas = async () => {

    var data = taxRate.map((t, i) => ({
    'Tax Name': t.taxname, 'Total Tax': t.taxtotal, GST: t.taxrategst, CGST: t.taxratecgst, IGST: t.taxrateigst
    }));

    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | TAX RATE',
    pageStyle: 'print'
  });

  //  PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#alltaxratepdf' })
    doc.save('All Taxrate.pdf')
  }

  useEffect(() => {
    fetchTaxrate();
    getexcelDatas();
  }, [deleteTaxRate]);

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  return (
    <>
      <Headtitle title={'Tax Rates'} />
      <Typography sx={userStyle.HeaderText}>Tax Rates </Typography>
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography sx={userStyle.importheadtext}>All your tax rates</Typography>
          </Grid>
          <Grid item xs={4}>
            <Link to="/settings/taxrate/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
          </Grid>
        </Grid>
        {/* EXPORT BUTTONS START */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid container sx={{ justifyContent: "center" }} >
            <Grid >
              {isUserRoleCompare[0].csvtaxrate && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].exceltaxrate && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printtaxrate && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdftaxrate && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* EXPORT BUTTONS END */}

        {/* TAX RATE TABLE START */}
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ margin: '20px' }} aria-label="customized table" id="alltaxrate" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important', textAlign: 'center' }}>Action</StyledTableCell>
                  <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Name </StyledTableCell>
                  <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Tax %</StyledTableCell>
                  <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>GST %</StyledTableCell>
                  <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>CGST %</StyledTableCell>
                  <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>IGST %</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {taxRate && (
                  taxRate.map((item, index) => (
                    <StyledTableRow key={index}>
                       <StyledTableCell align="center">
                        <Grid sx={{display: 'flex'}}>
                          {isUserRoleCompare[0].etaxrate && (<Link to={`/settings/taxrate/edit/${item._id}`} style={{ textDecoration: 'none', color: '#fff' }}><Button sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                          {isUserRoleCompare[0].dtaxrate && (<Button onClick={(e) => { handleClickOpen(); rowData(item._id) }} sx={userStyle.buttondelete}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>)}
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">{item.taxname}</StyledTableCell>
                      <StyledTableCell component="th" scope="row">{item.taxtotal}</StyledTableCell>
                      <StyledTableCell align="left">{item.taxrategst}</StyledTableCell>
                      <StyledTableCell align="left">{item.taxratecgst}</StyledTableCell>
                      <StyledTableCell align="left">{item.taxrateigst}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* ******* TAX RATE TABLE END ******* */}

        { /* ****** Print ****** */}
        <Box sx={userStyle.printcls}>
          <Box>
            <Typography variant='h5' >Tax Rate Print</Typography>
          </Box>
          <>
            <Box  >
              <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table sx={{ margin: '20px' }} aria-label="customized table" id="alltaxratepdf" ref={componentRef}>
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Name </StyledTableCell>
                      <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>Tax %</StyledTableCell>
                      <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>GST %</StyledTableCell>
                      <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>CGST %</StyledTableCell>
                      <StyledTableCell align="left" sx={{ border: '1px solid #dddddd7d !important' }}>IGST %</StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {taxRate && (
                      taxRate.map((item, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row">{item.taxname}</StyledTableCell>
                          <StyledTableCell component="th" scope="row">{item.taxtotal}</StyledTableCell>
                          <StyledTableCell align="left">{item.taxratecgst}</StyledTableCell>
                          <StyledTableCell align="left">{item.taxrateigst}</StyledTableCell>
                          <StyledTableCell align="left">{item.taxrategst}</StyledTableCell>
                        </StyledTableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        </Box>
      </Box>
      <br />
      {/* ALERT DIALOG */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"

      >

        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={(e) => deleteTaxRate(taxid)} autoFocus variant="contained" color='error'> OK  </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

function Taxratelist() {
  return (
    <Box  >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Taxratelisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Taxratelist;