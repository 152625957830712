import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { ExportXL, ExportCSV } from '../../Export';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import moment from 'moment'
import { UserRoleAccessContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle'


function Poslisttable() {
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const [pos, setPos] = useState();
    const [deletePoss, setDeletePoss] = useState({});
    const { auth, setAuth } = useContext(AuthContext);

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleClickOpen = () => {
        setIsDeleteOpen(true);
    };

    const handleCloseMod = () => {
        setIsDeleteOpen(false);
    };

    // Excel
    const fileName = "Pos";
    const [posData, setPosData] = useState([]);
    // get particular columns for export excel
    const getexcelDatas = async () => {
        let response = await axios.get(SERVICE.POS, {
            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        var data = response.data.pos1.map(t => {
            delete t._id
            delete t.__v
            delete t.createdAt
            return t
        });
        setPosData(data);

    }

    // Pos
    const fetchPos = async () => {
        try {
            let res = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });

            setPos(res.data.pos1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //set function to get particular row
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.POS_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAuth({ ...auth, loginState: true });
            setDeletePoss(res.data.spos);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Alert delete popup
    let supid = deletePoss._id;
    const deletePos = async () => {
        try {
            await axios.delete(`${SERVICE.POS_SINGLE}/${supid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleCloseMod();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchPos();
            getexcelDatas();
        }, [deletePos]
    );


    // Printing
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | POS',
        pageStyle: 'print'
    });

    // PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#postable' })
        doc.save('pos.pdf')
    }

   //  JQUERY
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box>
              <Headtitle title={'POS LIST'} />
            <Typography sx={userStyle.HeaderText}>POS</Typography>
            {/* Table */}
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].aposlist && (<Link to="/sell/pos/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>)}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].csvposlist && (<ExportCSV csvData={posData} fileName={fileName} />)}
                        {isUserRoleCompare[0].excelposlist && (<ExportXL csvData={posData} fileName={fileName} />)}
                        {isUserRoleCompare[0].printposlist && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
                        {isUserRoleCompare[0].pdfposlist && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
                    </Grid>
                </Grid>
                <Box>
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} ref={tableRef} aria-label="customized table" id="postab"  >
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Action</StyledTableCell>
                                    <StyledTableCell align="left">Date</StyledTableCell>
                                    <StyledTableCell align="left">Invoice No.</StyledTableCell>
                                    <StyledTableCell align="left">Customer Name</StyledTableCell>
                                    <StyledTableCell align="left">Contact Number</StyledTableCell>
                                    <StyledTableCell align="left">Location</StyledTableCell>
                                    <StyledTableCell align="left">Total Amount</StyledTableCell>
                                    <StyledTableCell align="left">Total Items</StyledTableCell>
                                    <StyledTableCell align="left">Added By</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {pos && (
                                    pos.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <Grid sx={{ display: 'flex' }}>
                                                {isUserRoleCompare[0].dpos && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>)}
                                                {/* {isUserRoleCompare[0].eposlist && (<Link to={`/sell/pos/create/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)} */}
                                                {isUserRoleCompare[0].vpos && (<Link to={`/sell/pos/view/${row._id}`}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }} ><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                {/* {isUserRoleCompare[0].paypos && (<Button disableRipple sx={userStyle.buttonpay} style={{ minWidth: '0px' }}><MoneyOutlinedIcon style={{ fontSize: 'large' }} /></Button>)} */}
                                            </Grid>
                                            <StyledTableCell component="th" scope="row">{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                            <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.location}</StyledTableCell>
                                            <StyledTableCell align="left">{row.aftergranddisctotal}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                            <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                            <StyledTableCell component="th" scope="row"></StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            {/* table for print */}
            <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table sx={{ minWidth: 700 }} ref={componentRef} aria-label="customized table" id="postable"  >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="left">Date</StyledTableCell>
                            <StyledTableCell align="left">Invoice No.</StyledTableCell>
                            <StyledTableCell align="left">Customer Name</StyledTableCell>
                            <StyledTableCell align="left">Contact Number</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Total Amount</StyledTableCell>
                            <StyledTableCell align="left">Total Items</StyledTableCell>
                            <StyledTableCell align="left">Added By</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {pos && (
                            pos.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                    <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                    <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                    <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.location}</StyledTableCell>
                                    <StyledTableCell align="left">{row.aftergranddisctotal}</StyledTableCell>
                                    <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                    <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row">

                                    </StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <>
                <Box>
                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleCloseMod}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseMod} variant="outlined">Cancel</Button>
                            <Button autoFocus variant="contained" color='error'
                                onClick={(e) => deletePos(supid)}
                            > OK </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>
        </Box>
    );
}
const Poslist = () => {
    return (
        <>
            <Box >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Poslisttable /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Poslist;