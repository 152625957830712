import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Box,
  Typography,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  TextField,
  FormControl,
  FormControlLabel,
  Grid,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { userStyle } from "../PageStyle";
import Navbar from "../../components/header/Navbar";
import Footer from "../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../components/Table";
import axios from "axios";
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ExportXL, ExportCSV } from "../Export";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import Headtitle from "../../components/header/Headtitle";
import $ from "jquery";
import { UserRoleAccessContext } from "../../context/Appcontext";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import moment from "moment";
import { SERVICE } from "../../services/Baseservice";
import { AuthContext } from '../../context/Appcontext';

function StockExpiryReportDetails() {
  const [pos, setPos] = useState([]);
  const [deletesup, setDeletesup] = useState({});
  const [exceldata, setExceldata] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    startdate: "",
  });

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth, setAuth } = useContext(AuthContext);
  const getcmonth = new Date();
  const getmonthnum = getcmonth.getMonth() + 1;

  // Customers

  const fetchCustomerDuesReport = async () => {
    try {
        let req = await axios.get(SERVICE.POS, {headers: {
            'Authorization':`Bearer ${auth.APIToken}`
            }});
            setAuth({ ...auth, loginState: true });
        let getDatawithFilter = req.data.pos1.filter((data)=>{
            //console.log(data)
            return data
        })
        setPos(getDatawithFilter);
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
};

  useEffect(() => {
    fetchCustomerDuesReport();

  }, []);

  // Delete model
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleClickOpen = () => {
    setIsDeleteOpen(true);
  };

  const handleClose = () => {
    setIsDeleteOpen(false);
  };

  //set function to get particular row
  const rowData = async (id) => {
    try {
      let res = await axios.get(`http://localhost:8003/api/supplier/${id}`);
      setDeletesup(res.data.ssupplier);
    } catch (err) {}
  };

  // Alert delete popup
  let supid = deletesup._id;
  const deleteSupplier = async () => {
    try {
      let res = await axios.delete(
        `http://localhost:8003/api/supplier/${supid}`
      );
      handleClose();
    } catch (err) {}
  };

  // Excel
  const fileName = "Suppliers";
  // get particular columns for export excel
  const getexcelDatas = async () => {
    let res = await axios.get(SERVICE.POS);
    var data = res.data.pos1.map((t) => ({
      name: t.name,
      billno: t.billno,
      duedate: t.duedate,
      daysdiff: t.daysdiff,
      dueamount: t.dueamount,
    }));
    setExceldata(data);
  };

  //  PDF
  const downloadpdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#Customerduesreport" });
    doc.save("Customerduesreport.pdf");
  };

  useEffect(() => {
    getexcelDatas();
  }, [deleteSupplier]);

  let total = 0;

  //jquery
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  useEffect(() => {}, [tableRef.current]);
  $(document).ready(function () {
    $.fn.dataTable.ext.errMode = "none";
    setTimeout(function () {
      $(tableRef.current).DataTable({
        language: { search: "", searchPlaceholder: "Search..." },
        lengthMenu: [
          [10, 25, 50, 100, 200, 500, -1],
          [10, 25, 50, 100, 200, 500, "All"],
        ],
      });
    }, 1000);
  });

  return (
    <Box>
      <Headtitle title={"Stock Expiry Report"} />
      {/* ****** Header Content ****** */}
      <Typography sx={userStyle.HeaderText}>
      Stock Expiry Report
      </Typography>
      <Box sx={userStyle.container}>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ justifyContent: "center" }}
          spacing={1}
        >
          <Grid item lg={2} md={2}>
            <Typography sx={{ marginTop: "7px", float: "right" }}>
              Expiry Date
            </Typography>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormControl fullWidth size="small">
              {/* <InputLabel id="demo-simple-select-helper-label" >Balance</InputLabel> */}
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                // value={purchaseAdd.purchasestatus}
                // onChange={(e) => { setPurchaseAdd({ ...purchaseAdd, purchasestatus: e.target.value }) }}
                // label="Balance"
              >
                <MenuItem value="greaterthan"> &gt; </MenuItem>
                <MenuItem value="lesserthan"> &lt; </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3}>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                id="component-outlined"
                // value={duePayments.dueamt}
                // onChange={(e) => { setDuePayments({ ...duePayments, dueamt: e.target.value, expid: purchaseAdd.referenceno, payduecalc: purchaseAdd.paydue - +e.target.value }); }}
                // label="Amount *"
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                // checked={role.usermanagement} onChange={(e) => setRole({...role, usermanagement:!role.usermanagement})}
                />
              }
              label="Select All"
            />
          </Grid>
          <Grid item lg={2} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={fetchCustomerDuesReport}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      {/* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container}>
          {/* ****** Header Buttons ****** */}
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid>
              <ExportCSV csvData={exceldata} fileName={fileName} />
              <ExportXL csvData={exceldata} fileName={fileName} />
              <Button sx={userStyle.buttongrp} onClick={(e) => downloadpdf()}>
                <FaFilePdf />
                &ensp;Export to PDF&ensp;
              </Button>{" "}
            </Grid>
            <Link to="/contact/customer/Customerduesreportprint">
              <Button
                sx={userStyle.buttongrp}
                style={{
                  textDecoration: "none",
                  color: "white",
                  "&:hover": { color: "red", backgroundColor: "yellow" },
                }}
              >
                <FaPrint />
                &ensp;Export to Print
              </Button>
            </Link>
            {/* ****** Table Grid Container ****** */}
            <Grid container>
              <Grid md={4} sm={2} xs={1}></Grid>
              <Grid md={8} sm={10} xs={10} sx={{ align: "center" }}></Grid>
            </Grid>
          </Grid>
          <br />
          {/* ****** Table start ****** */}
          <TableContainer component={Paper}>
            <Table
              sx={{}}
              aria-label="simple table"
              id="Customerduesreport"
              ref={tableRef}
            >
              <TableHead sx={{ fontWeight: "600" }}>
                <StyledTableRow>
                  <StyledTableCell>Item Code</StyledTableCell>
                  <StyledTableCell>Item Name</StyledTableCell>
                  <StyledTableCell>Stock in Hand</StyledTableCell>
                  <StyledTableCell>Stock(packs)</StyledTableCell>
                  <StyledTableCell>Free</StyledTableCell>
                  <StyledTableCell>Batch No</StyledTableCell>
                  <StyledTableCell>Expiry Date</StyledTableCell>
                  <StyledTableCell>Pay Amount</StyledTableCell>
                  <StyledTableCell>Stock Location</StyledTableCell>
                  <StyledTableCell>GRN No</StyledTableCell>
                  <StyledTableCell>GRN Date</StyledTableCell>
                  <StyledTableCell>Supplier Name</StyledTableCell>
                  <StyledTableCell>Purchase Bill</StyledTableCell>
                  <StyledTableCell>Date of Purchase</StyledTableCell>
                  <StyledTableCell>Purchase Date</StyledTableCell>
                  <StyledTableCell>MRP</StyledTableCell>
                  <StyledTableCell>Sales Return</StyledTableCell>
                  <StyledTableCell>Purchase Value</StyledTableCell>
                  <StyledTableCell>MRP Value</StyledTableCell>
                  <StyledTableCell>Sales value</StyledTableCell>

                </StyledTableRow>
              </TableHead>
              {/* <TableBody> */}
                {/* {pos.length > 0 &&
                  pos.map((row, index) => (
                    <StyledTableRow key={index}>
                      {pos.map((item) => {
                        {
                          customers.forEach((data) => {
                            if (item.cusid == data.cusid) {
                              let getpurchasedate = new Date(item.purchasedate);
                              let getpurchaseday = getpurchasedate.getDay();
                              let totaldays =
                                Number(data.creditdays) - getpurchaseday;
                              total = Math.floor(totaldays);
                              return total;
                            }
                          });
                        }
                      })}
                      <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                      <StyledTableCell align="left">{row.ledgerbalance}</StyledTableCell>
                      <StyledTableCell align="left">{row.ledgerbalance}</StyledTableCell>
                      <StyledTableCell align="left">{total}</StyledTableCell>
                      <StyledTableCell align="left">{row.creditlimit}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody> */}
            </Table>
          </TableContainer>
          {/* ****** Table End ****** */}
        </Box>
      </>
      {/* ****** Table End ****** */}

      {/* Delete Modal */}
      <Box>
        {/* ALERT DIALOG */}
        <Dialog
          open={isDeleteOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent
            sx={{ width: "350px", textAlign: "center", alignItems: "center" }}
          >
            <ErrorOutlineOutlinedIcon
              sx={{ fontSize: "80px", color: "orange" }}
            />
            <Typography variant="h5" sx={{ color: "red", textAlign: "center" }}>
              Are you sure?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              autoFocus
              variant="contained"
              color="error"
              onClick={(e) => deleteSupplier(supid)}
            >
              {" "}
              OK{" "}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

function StockExpiryReport() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <StockExpiryReportDetails />
          <br />
          <br />
          <br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default StockExpiryReport ;
