import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import axios from 'axios';
import { useReactToPrint } from "react-to-print";
import { toast } from 'react-toastify';
import { ExportXL, ExportCSV } from '../../Export';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import Headtitle from '../../../components/header/Headtitle';
import moment from 'moment';
import jsPDF from "jspdf";

function Sellreturnlisttable() {

    const [sellreturns, setSellReturns] = useState([]);
    const [deleteSell, setDeleteSell] = useState({});
    const { auth, setAuth } = useContext(AuthContext);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleClickOpen = () => {
        setIsDeleteOpen(true);
    };

    const handleCloseMod = () => {
        setIsDeleteOpen(false);
    };

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Sell return',
        pageStyle: 'print'
    });

    //  PDF
    const columns = [
        { title: "Date", field: "date" },
        { title: "Invoiceno", field: "referenceno" },
        { title: "Customer Name", field: "customer" },
        { title: "Contact Number", field: 'contactnumber' },
        { title: "Location", field: 'location', },
        { title: "Total Amount", field: 'aftergranddisctotal' },
        { title: "Added By", field: 'userbyadd', },
        { title: "Total Amount", field: 'totalitems' },
    ]

    const downloadPdf = () => {
        const doc = new jsPDF()
        doc.autoTable({
            theme: "grid",
            columns: columns.map(col => ({ ...col, dataKey: col.field })),
            body: sellreturns
        })
        doc.save('sellreturn.pdf')
    }

    // Excel
    const fileName = "sellreturns";

    const [sellReturnData, setSellReturnData] = useState([]);

    // get particular columns for export excel
    const getexcelDatas = async () => {
        let response = await axios.get(SERVICE.SELLRETURN, {
            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        var data = response.data.sell1.map(t => ({
            date: t.date, referenceno: t.referenceno,
            customer: t.customer, contactnumber: t.contactnumber, location: t.location,
            aftergranddisctotal: t.aftergranddisctotal, total: t.totalitems, userbyadd: t.userbyadd
        }));
        setAuth({ ...auth, loginState: true });
        setSellReturnData(data);

    }

    // get all sellreturn
    const fetchSellreturn = async () => {
        try {
            let res_sell = await axios.get(SERVICE.SELLRETURN, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setSellReturns(res_sell.data.sell1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //set function to get particular row
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.SELLRETURN_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAuth({ ...auth, loginState: true });
            setDeleteSell(res.data.sreturn);
        } catch (err) {
        }
    }

    // Alert delete popup
    let supid = deleteSell._id;
    const delSellreturn = async () => {
        try {
            let res = await axios.delete(`${SERVICE.SELLRETURN_SINGLE}/${supid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAuth({ ...auth, loginState: true });
            handleCloseMod();
        } catch (err) {
        }
    };

    useEffect(
        () => {
            fetchSellreturn();
            getexcelDatas();
        }, [delSellreturn]
    );

    // Print
    const ref = useRef();

    //  JQUERY
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 25, 50, 100, 200, 500, -1],
                        [10, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box>
            <Headtitle title={'Sellreturn List'} />
            <Typography sx={userStyle.HeaderText}>Sell Return</Typography>
            {/* Table */}
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        <Link to="/sell/sellreturn/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                </Grid><br />
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].excelsellreturn && (
                            <>
                                <ExportCSV csvData={sellreturns} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].csvsellreturn && (
                            <>
                                <ExportXL csvData={sellreturns} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printsellreturn && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfsellreturn && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                <Box>
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} ref={tableRef} aria-label="customized table" id="postable" refone={ref} >
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Action</StyledTableCell>
                                    <StyledTableCell align="left">Date</StyledTableCell>
                                    <StyledTableCell align="left">Invoice No.</StyledTableCell>
                                    <StyledTableCell align="left">Customer Name</StyledTableCell>
                                    <StyledTableCell align="left">Contact Number</StyledTableCell>
                                    <StyledTableCell align="left">Location</StyledTableCell>
                                    <StyledTableCell align="left">Total Amount</StyledTableCell>
                                    <StyledTableCell align="left">Total Items</StyledTableCell>
                                    <StyledTableCell align="left">Added By</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {sellreturns && (
                                    sellreturns.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell component="th" scope="row">
                                                <Grid sx={{ display: 'flex' }}>
                                                    <Link to={`/sell/sellreturn/create/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                    <Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>
                                                    <Link to={`/sell/sellreturn/view/${row._id}`}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }} ><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>{moment(row.date).format('DD/MM/YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                            <StyledTableCell align="left">{row.mobile}</StyledTableCell>
                                            <StyledTableCell align="left">{row.location}</StyledTableCell>
                                            <StyledTableCell align="left">{row.aftergranddisctotal}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                            <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            {/* print layout */}
            <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" id="postable" ref={componentRef}  >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="left">Date</StyledTableCell>
                            <StyledTableCell align="left">Invoice No.</StyledTableCell>
                            <StyledTableCell align="left">Customer Name</StyledTableCell>
                            <StyledTableCell align="left">Contact Number</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Total Amount</StyledTableCell>
                            <StyledTableCell align="left">Total Items</StyledTableCell>
                            <StyledTableCell align="left">Added By</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {sellreturns && (
                            sellreturns.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>{row.date?.slice(5, 5) + moment(row.date).format('DD/MM/YYYY')}</StyledTableCell>
                                    <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                    <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                    <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.location}</StyledTableCell>
                                    <StyledTableCell align="left">{row.aftergranddisctotal}</StyledTableCell>
                                    <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                    <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <>
                <Box>
                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleCloseMod}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseMod} variant="outlined">Cancel</Button>
                            <Button autoFocus variant="contained" color='error'
                                onClick={(e) => delSellreturn(supid)}
                            > OK </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>
        </Box>
    );
}
const Sellreturnlist = () => {
    return (
        <>
            <Box sx={{ display: 'flex', }} >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Sellreturnlisttable /><br /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Sellreturnlist;