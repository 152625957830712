import React, { useState, useEffect, useContext,useRef} from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography,FormControl,TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import { useReactToPrint } from "react-to-print";
import {SERVICE} from '../../services/Baseservice'; 
import {AuthContext} from '../../context/Appcontext';
import moment from "moment";

function Estimate() {

   //Excel data
   const [exceldata, setExceldata] = useState([]);
  const [estimatereport, setEstimateReport] = useState();

  const {isUserRoleCompare} = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today
  })
 
  //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()


    const fetchEstimate = async () => {
      try {
        let request = await axios.get(SERVICE.ESTIMATE, {
          headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    }
            });
        let estimatefetch = request.data.estimate.filter((data) => {
          let splittedMonth = data.date;
          let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
        } 
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        });
        setEstimateReport(estimatefetch)

        $(document).ready(function() {
          $.fn.dataTable.ext.errMode = 'none';
          setTimeout(function () {
          $(tableRef.current).DataTable({
              language: { search: '', searchPlaceholder: "Search..." },
              lengthMenu: [
                    [10, 1, 25, 50, 100, 200, 500, -1],
                    [10, 1, 25, 50, 100, 200, 500, 'All'],
                ],
                    });
        }, 1000);
        } );
      } catch (err) {
        const messages = err.response.data.message;
      toast.error(messages);
      }
    };

  // Export Excel
  const fileName = 'Estimate Report'

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = estimatereport.map(t => ({
      "Bill No ": t.referenceno, "Bill Date": t.date, "Customer Name": t.customer, "Total Items": t.totalitems, "Total Qty": t.totalproducts, "Sub Total": t.goods.map((value) => value.subtotal).reduce((val , dat)=> val + dat),
      Discount: t.gdiscountvalue, Total: t.aftergranddisctotal 
    }));   
    setExceldata(data);
  }
  
  useEffect(() => {
    getexcelDatas();
  }, [estimatereport])

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#estimatereporttable' })
    doc.save('Estimate Report.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | ESTIMATE REPORT',
    pageStyle: 'print'
  });

  return (
    <Box>
       <Headtitle title={'Estimate Report'} />
       <Typography sx={userStyle.HeaderText}>Estimate Report</Typography>
       <Box sx={userStyle.container} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={4} md={4}>
                            <Grid container>
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{marginTop:1}}>From</Typography>
                                </Grid>
                                <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            onChange={(e) => {setDateFilter({...dateFilter, startdate: e.target.value})}}
                                            type="date"
                                            size="small"
                                            name="startdate"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Grid container>
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{marginTop:1}}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            onChange={(e) => {setDateFilter({...dateFilter, enddate: e.target.value})}}
                                            type="date"
                                            size="small"
                                            name="enddate"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={(e)=>{fetchEstimate()}} >Generate</Button>
                        </Grid>
                    </Grid>
                    </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        
        { /* ******************************************************EXPORT Buttons****************************************************** */}
{/*       
          <Box sx={userStyle.container} > */}
              <Grid container sx={userStyle.gridcontainer}>
                      <Grid >
                      {isUserRoleCompare[0].csvestimatereport && (
                          <>
                              <ExportCSV csvData={exceldata} fileName={fileName} />
                          </>
                      )}
                      {isUserRoleCompare[0].excelestimatereport && (
                          <>
                              <ExportXL csvData={exceldata} fileName={fileName} />
                          </>
                      )}
                      {isUserRoleCompare[0].printestimatereport && (
                          <>
                      <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                          </>
                      )}
                      {isUserRoleCompare[0].pdfestimatereport && (
                          <>
                              <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                          </>
                      )}
                </Grid>
              </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="estimatereporttable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Bill No</StyledTableCell>
                  <StyledTableCell >Bill Date</StyledTableCell>
                  <StyledTableCell >Customer Name</StyledTableCell>
                  <StyledTableCell >Total Items</StyledTableCell>
                  <StyledTableCell >Total Qty</StyledTableCell>
                  <StyledTableCell >Sub Total</StyledTableCell>
                  <StyledTableCell >Discount</StyledTableCell>
                  <StyledTableCell >Total</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {estimatereport &&
                  (estimatereport.map((row, index) => (
                    <StyledTableRow  key={index}>
                      <StyledTableCell >{row.referenceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.customer}</StyledTableCell>
                      <StyledTableCell >{row.totalitems}</StyledTableCell>
                      <StyledTableCell >{row.totalproducts}</StyledTableCell>
                      <StyledTableCell >{row.goods.map((value) => value.subtotal).reduce((val , dat)=> val + dat)}</StyledTableCell>
                      <StyledTableCell >{row.gdiscountvalue}</StyledTableCell>
                      <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
        {/* print layout */}
        <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="estimatereporttable" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
            <StyledTableCell>Bill No</StyledTableCell>
                  <StyledTableCell >Bill Date</StyledTableCell>
                  <StyledTableCell >Customer Name</StyledTableCell>
                  <StyledTableCell >Total Items</StyledTableCell>
                  <StyledTableCell >Total Qty</StyledTableCell>
                  <StyledTableCell >Sub Total</StyledTableCell>
                  <StyledTableCell >Discount</StyledTableCell>
                  <StyledTableCell >Total</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {estimatereport &&
              (estimatereport.map((row, index) => (
                <StyledTableRow  key={index}>
                  <StyledTableCell >{row.referenceno}</StyledTableCell>
                  <StyledTableCell >{row.date}</StyledTableCell>
                  <StyledTableCell >{row.customer}</StyledTableCell>
                  <StyledTableCell >{row.totalitems}</StyledTableCell>
                  <StyledTableCell >{row.totalproducts}</StyledTableCell>
                  <StyledTableCell >{row.goods.map((value) => value.subtotal).reduce((val , dat)=> val + dat)}</StyledTableCell>
                  <StyledTableCell >{row.gdiscountvalue}</StyledTableCell>
                  <StyledTableCell >{row.aftergranddisctotal}</StyledTableCell>
                </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Estimatereport() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Estimate /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Estimatereport;