import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";


function Referencewisepaymentsummarylist() {

    const [cusreference, setCustomerReference] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    //  Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;

    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today,
    })

    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const { auth } = useContext(AuthContext);

    // Reference payment Register
    const fetchCustomerpayments = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.customerpays.filter((data) => {
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    return data
                } else {
                    return dateFilter.startdate <= data.date && dateFilter.enddate + 1 >= data.date

                }
            })
            setCustomerReference(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Referencewisepaymentsummarylist'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = cusreference.map(t => ({
            referenceno: t.referenceno,
            totalentries: t.billno,
            totalamount: t.totalamount,
        }));
        setExceldata(data);
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [cusreference]
    )

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Reference Wise Summary',
        pageStyle: 'print'
    });


    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Referencewisepaymentsummary' })
        doc.save('Referencewisepaymentsummary.pdf')
    }

    useEffect(
        () => {
            fetchCustomerpayments()
        }, []
    )
    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()


    return (
        <Box >
            <Headtitle title={'Reference Wise Payments Summary'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Reference Wise Payments Summary</Typography>
            <Box sx={userStyle.container} >
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={5} md={5}>
                        <Grid container >
                            <Grid item lg={3} md={3} sx={{ marginTop: 1 }}>
                                <Typography>Date From</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.startdate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="startdate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={5} md={5}>
                        <Grid container >
                            <Grid item lg={1} md={1}>
                                <Typography sx={{ marginTop: 1 }}>To</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.enddate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="enddate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <Button variant='contained' color='secondary' onClick={fetchCustomerpayments}>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br /><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvreferencewisepaymentsummary && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelreferencewisepaymentsummary && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printreferencewisepaymentsummary && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfreferencewisepaymentsummary && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Referencewisepaymentsummarylist" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Total Entries</StyledTableCell>
                                    <StyledTableCell>Total Amount</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {cusreference.length > 0 && (
                                    cusreference.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.billno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalamount}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                    { /* ****** Print ****** */}
                    <Box sx={userStyle.printcls} ref={componentRef}>
                        <Box>
                            <Typography variant='h5' >Referenc Wise Payment Summary list</Typography>
                        </Box>
                        <>
                            <Box  >
                                <TableContainer component={Paper} sx={userStyle.printcls}>
                                    <Table aria-label="simple table" id="Referencewisepaymentsummary">
                                        <TableHead sx={{ fontWeight: "600" }} >
                                            <StyledTableRow >
                                                <StyledTableCell>Reference No</StyledTableCell>
                                                <StyledTableCell>Total Entries</StyledTableCell>
                                                <StyledTableCell>Total Amount</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cusreference.length > 0 && (
                                                cusreference.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.billno}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.totalamount}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </>
                    </Box>
                </Box>
            </>
        </Box>
    );
}

function Referencewisepaymentsummary() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Referencewisepaymentsummarylist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Referencewisepaymentsummary;