import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import moment from "moment";
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import { AuthContext } from '../../../src/context/Appcontext';

function Daywiseestimatetable() {

  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  const [dayestimate, setDayestimate] = useState([]);
  const [count, setCount] = useState([]);
  //Excel data
  const [exceldata, setExceldata] = useState([]);

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); 
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  let dateArr = []
  let totBillArr = []
  let totSaleArr = []
  let allArray = []
  const counts = [];
  const res = []

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  //  Fetch Pos Data
  const fetchPos = async () => {
    try {
      let request = await axios.get(SERVICE.ESTIMATE,{
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let estimatefetch = request.data.estimate.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
            return data
        } 
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
            return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
            return data
        }
      })
      estimatefetch.map(item => {
        let splittedMonth = item.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        dateArr.push(dateTrim)
        totSaleArr.push(+item.grandtotal)
        totBillArr.push(item.referenceno);
      })
      //funtion for total bills
      dateArr.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });

      //spliting key and value
      const splitKeyValue = obj => {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          res.push({
            'billcount': obj[keys[i]]
          });
        };
        return res;
      };

      setCount(splitKeyValue(counts))

      //merge all Arrays
      allArray = dateArr.map(function (data, i) {
        return { date: data, totalsale: totSaleArr[i], billno: totBillArr[i], bills: res[i] };
      });

      //function for total calculation
      const result = [...allArray.reduce((r, o) => {
        const key = o.date;
        const items = r.get(key) || Object.assign({}, o, {
          totalsale: 0,
          billno: "",
        });
        items.totalsale += o.totalsale
        items.billno += o.billno + ","
        return r.set(key, items);
      }, new Map).values()];
      setDayestimate(result);

      //jquery
      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Export Excel
  const fileName = 'Day Wise Estimate'

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = dayestimate.map(t => ({
      Date: t.date, "Bill No (From -to)": t.billno, "Total Bills": t?.bills?.billcount, "Total Sales": t.totalsale
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()
  }, [dayestimate])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | DAY WISE ESTIMATE',
    pageStyle: 'print'
  });

  // PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#daywiseestimate' })
    doc.save('Day Wise Estimate.pdf')
  }

  return (
    <Box>
      <Headtitle title={'Day Wise Estimate'} />
      <Typography sx={userStyle.HeaderText}>Day Wise Estimate</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={3} md={3}>
                <Typography sx={{ marginTop: 1 }}> From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchPos} >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvdaywiseestimatereport && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].exceldaywiseestimatereport && (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printdaywiseestimatereport && (<Button sx={userStyle.buttongrp} onClick={handleprint} ><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfdaywiseestimatereport && (<Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="daywiseestimate" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell >Bill No (From -to)</StyledTableCell>
                  <StyledTableCell >Total Bills</StyledTableCell>
                  <StyledTableCell >Total Sales</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {dayestimate &&
                  (dayestimate.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.billno}</StyledTableCell>
                      <StyledTableCell >{row?.bills?.billcount}</StyledTableCell>
                      <StyledTableCell >{row.totalsale}</StyledTableCell>
                    </StyledTableRow>
                  )))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <TableContainer component={Paper} sx={userStyle.printcls} >
          <Table sx={{ minWidth: 700, }} aria-label="customized table" id="daywiseestimate" ref={componentRef}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell >Bill No (From -to)</StyledTableCell>
                <StyledTableCell >Total Bills</StyledTableCell>
                <StyledTableCell >Total Sales</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody align="left">
              {dayestimate &&
                (dayestimate.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell >{moment(row.date).format('DD-MM-YYYY')}</StyledTableCell>
                    <StyledTableCell >{row.billno}</StyledTableCell>
                    <StyledTableCell >{row?.bills?.billcount}</StyledTableCell>
                    <StyledTableCell >{row.totalsale}</StyledTableCell>
                  </StyledTableRow>
                )))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* content end */}
    </Box>
  );
}

function Daywiseestimate() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Daywiseestimatetable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Daywiseestimate;