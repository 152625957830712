import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button, InputLabel, OutlinedInput } from '@mui/material';
import { userStyle, colourStyles } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function Supplierwisepaymentsreportlist() {

    const [purchases, setPurchases] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //  Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;

    // Datefilter
    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today
    })

    //jquery
    $.DataTable = require('datatables.net')
    const tableRefsupwisepay = useRef()

    // filter supplier
    const purchasess = purchases.filter(
        ({ supplier }) =>
            supplier.toLowerCase().includes(searchText.toLowerCase())
    );

    // Purchases
    const fetchPurchases = async () => {
        try {
            let req = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.purchases.filter((data) => {
                let dateTrim = moment(data.paidon).utc().format('YYYY-MM-DD');

                if (dateFilter.startdate == "" && dateFilter.enddate == "" ) {
                    return data
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
                    return data
                }
            })

            setPurchases(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefsupwisepay.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchPurchases();
        }, []
    )

    // Excel
    const fileName = 'SuppliersWisePaymentReport'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = purchasess.map(t => ({
            "Reference No": t.referenceno, "Paid Date": moment(t.paidon).utc().format('DD-MM-YYYY'), "Name": t.supplier, "Total Amount": t.nettotal, "Discounts": t.discountamt, "Net Paid Amount": t.advancebalance, "Pay": t.paymentmethod
        }));
        setExceldata(data);
    }

    // Pdf
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#supplierwisepaymentsreport' })
        doc.save('supplierwisepaymentsreport.pdf')
    }
    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | SUPPLIER WISE PAYMENTS REPORT',
        pageStyle: 'print'
    });

    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    useEffect(
        () => {
            getexcelDatas();
        }, [purchasess]
    )

    return (
        <Box >
            <Headtitle title={'Supplier Wise Payments Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Supplier Wise Payments Report</Typography>

            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.filtercontent} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={2}>
                        <Grid item lg={3} md={3}>
                            <Grid container spacing={1}>
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{ marginTop: 1 }}>From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                            type="date"
                                            size="small"
                                            name="startdate"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={3}>
                            <Grid container spacing={1}>
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                            type="date"
                                            size="small"
                                            name="enddate"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Grid container>
                                <Grid item lg={2} md={2}>
                                    <Button variant='contained' color='secondary' onClick={fetchPurchases}>Generate</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={3} md={3} sx={{ display: 'flex' }}>
                            <Grid container>
                                <Grid item lg={9} md={9} sm={6} sx={{ display: 'flex' }}>
                                    <Grid sx={userStyle.spanIcons}><SearchOutlinedIcon /></Grid>
                                    <Grid>
                                        <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                            <InputLabel id="demo-select-small">Supplier Name</InputLabel>
                                            <OutlinedInput
                                                id="component-outlined"
                                                value={searchText}
                                                onChange={({ target }) => setSearchText(target.value)}
                                                label="Supplier Name"
                                            />
                                </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box><br />
            <Box sx={userStyle.container} >
                { /* ****** Header Buttons ****** */}
                <Grid container sx={{ justifyContent: "center" }} >
                    <Grid >
                        {isUserRoleCompare[0].csvsupplierwisepaymentsreport && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelsupplierwisepaymentsreport && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printsupplierwisepaymentsreport && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfsupplierwisepaymentsreport && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                { /* ****** Table start ****** */}
                <TableContainer component={Paper} >
                    <Table aria-label="simple table" id="supplierwisepaymentsreport" ref={tableRefsupwisepay}>
                        <TableHead sx={{ fontWeight: "600" }} >
                            <StyledTableRow >
                                <StyledTableCell>Reference No</StyledTableCell>
                                <StyledTableCell>Paid Date</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Total Amount</StyledTableCell>
                                <StyledTableCell>Returns & Discounts</StyledTableCell>
                                <StyledTableCell>Net Paid Amount</StyledTableCell>
                                <StyledTableCell>Pay</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {purchasess.length > 0 && (
                                purchasess.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                        <StyledTableCell align="left">{moment(row.paidon).utc().format('DD-MM-YYYY')}</StyledTableCell >
                                        <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                        <StyledTableCell align="left">{row.nettotal}</StyledTableCell>
                                        <StyledTableCell align="left">{row.discountamt}</StyledTableCell>
                                        <StyledTableCell align="left">{row.advancebalance}</StyledTableCell>
                                        <StyledTableCell align="left">{row.paymentmethod}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                { /* ****** Table End ****** */}
            </Box>
        </>
            { /* ****** Table End ****** */ }
    { /* ****** Print ****** */ }
    <Box sx={userStyle.printcls} >
        <Box>
            <Typography variant='h5' >Supplier Wise Payments Report</Typography>
        </Box>
        <>
            <Box  >
                <TableContainer component={Paper} sx={userStyle.printcls} >
                    <Table aria-label="simple table" id="supplierwisepaymentsreport" ref={componentRef}>
                        <TableHead sx={{ fontWeight: "600" }} >
                            <StyledTableRow >
                                <StyledTableCell>Reference No</StyledTableCell>
                                <StyledTableCell>Paid Date</StyledTableCell>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Total Amount</StyledTableCell>
                                <StyledTableCell>Returns & Discounts</StyledTableCell>
                                <StyledTableCell>Net Paid Amount</StyledTableCell>
                                <StyledTableCell>Pay</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {purchasess.length > 0 && (
                                purchasess.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                        <StyledTableCell align="left">{moment(row.paidon).utc().format('DD-MM-YYYY')}</StyledTableCell >
                                        <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                        <StyledTableCell align="left">{row.nettotal}</StyledTableCell>
                                        <StyledTableCell align="left">{row.discountamt}</StyledTableCell>
                                        <StyledTableCell align="left">{row.advancebalance}</StyledTableCell>
                                        <StyledTableCell align="left">{row.paymentmethod}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    </Box>
        </Box >
    );
}

function Supplierwisepaymentsreport() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Supplierwisepaymentsreportlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Supplierwisepaymentsreport;