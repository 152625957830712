import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Dialog, DialogContent, DialogActions, Grid, Typography, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import { ExportXL, ExportCSV } from '../../Export';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from 'moment'
import Headtitle from '../../../components/header/Headtitle';

function Estimatelisttable() {

    const [estimate, setEstimate] = useState();
    const [deleteestimates, setDeleteestimates] = useState({});
    const { auth } = useContext(AuthContext);
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const [estimateData, setEstimateData] = useState([]);

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => {  setIsDeleteOpen(true);  };
    const handleCloseMod = () => { setIsDeleteOpen(false);  };

    // estimate
    const fetchestimate = async () => {
        try {
            let res = await axios.get(SERVICE.ESTIMATE, {

                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            setEstimate(res.data.estimate);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //set function to get particular row
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.ESTIMATE_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDeleteestimates(res.data.sestimate);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Alert delete popup
    let supid = deleteestimates._id;
    const deleteestimate = async () => {
        try {
            await axios.delete(`${SERVICE.ESTIMATE_SINGLE}/${supid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleCloseMod();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

     // Excel
     const fileName = "Estimate";
     // get particular columns for export excel
     const getexcelDatas = async () => {
         let response = await axios.get(SERVICE.ESTIMATE,
             {
                 headers: {
                     'Authorization': `Bearer ${auth.APIToken}`
                 }
             });
         var data = response.data.estimate.map(t => {
             delete t._id
             delete t.__v
             delete t.createdAt
             return t
         });
         setEstimateData(data);
     }
 
     useEffect(() => {
         getexcelDatas();
     }, [estimateData])
 
    // Print
    const ref = useRef();
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "  HIPOS | ESTIMATE",
        pageStyle: "print",
    });


    // PDF
    const downloadpdf = () => {
        const doc = new jsPDF();
        autoTable(doc, { html: "#estimatetable" });
        doc.save("Estimates.pdf");
    };

    useEffect(
        () => {
            fetchestimate();
        }, [deleteestimate]
    );

    //  JQUERY
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1,25, 50, 100, 200, 500, -1],
                        [10,1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box>
           <Headtitle title={'Estimate List'} />
            <Typography sx={userStyle.HeaderText}>Estimate</Typography>
            {/* Table */}
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].addestimatelist && (
                            <>
                                <Link to="/estimate/estimate/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].excelestimatelist && (
                            <>
                                <ExportCSV csvData={estimateData} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].csvestimatelist && (
                            <>
                                <ExportXL csvData={estimateData} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printestimatelist && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfestimatelist && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Box>
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} ref={tableRef} aria-label="customized table" id="estimatetab" refone={ref} >
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Action</StyledTableCell>
                                    <StyledTableCell align="left">Date</StyledTableCell>
                                    <StyledTableCell align="left">Invoice No.</StyledTableCell>
                                    <StyledTableCell align="left">Customer Name</StyledTableCell>
                                    <StyledTableCell align="left">Contact Number</StyledTableCell>
                                    <StyledTableCell align="left">Location</StyledTableCell>
                                    <StyledTableCell align="left">Total Amount</StyledTableCell>
                                    <StyledTableCell align="left">Total Items</StyledTableCell>
                                    <StyledTableCell align="left">Added By</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {estimate && (
                                    estimate.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <Grid sx={{ display: 'flex' }}>
                                                {isUserRoleCompare[0].deleteestiamatelist && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>)}
                                                {isUserRoleCompare[0].viewestiamatelist && (<Link to={`/estimate/estimate/view/${row._id}`}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }} ><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                            </Grid>
                                            <StyledTableCell component="th" scope="row">{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                            <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.address}</StyledTableCell>
                                            <StyledTableCell align="left">{row.aftergranddisctotal}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                            <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <>
                <Box>
                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleCloseMod}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"

                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseMod} variant="outlined">Cancel</Button>
                            <Button autoFocus variant="contained" color='error'
                                onClick={(e) => deleteestimate(supid)}
                            > OK </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>
            <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" id="estimatetable" ref={componentRef} >
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell align="left">Date</StyledTableCell>
                            <StyledTableCell align="left">Invoice No.</StyledTableCell>
                            <StyledTableCell align="left">Customer Name</StyledTableCell>
                            <StyledTableCell align="left">Contact Number</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Total Amount</StyledTableCell>
                            <StyledTableCell align="left">Total Items</StyledTableCell>
                            <StyledTableCell align="left">Added By</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {estimate && (
                            estimate.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                    <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                    <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                    <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.address}</StyledTableCell>
                                    <StyledTableCell align="left">{row.aftergranddisctotal}</StyledTableCell>
                                    <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                    <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>

                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>

                </Table>
            </TableContainer>
        </Box>
    );
}
const Estimatelist = () => {
    return (
        <>
            <Box>
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Estimatelisttable /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Estimatelist;