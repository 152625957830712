import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { userStyle } from '../PageStyle';
import { Link } from 'react-router-dom';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UserRoleAccessContext } from '../../context/Appcontext';
import $ from 'jquery';
import Headtitle from '../../components/header/Headtitle';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useReactToPrint } from "react-to-print";

function Suplierwisereturn() {


  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); 
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  // Export Excel
  const fileName = 'Supplierwise Sale Return'
  //Excel data
  const [exceldata, setExceldata] = useState([]);

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#supplierwiseestimatereturn' })
    doc.save('Supplierwise Sale Return.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Supplierwise Sale Return',
    pageStyle: 'print'
  });

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        console.log(tableRef.current)
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 25, 50, 100, 200, 500, -1],
            [10, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  return (
    <Box>
      <Headtitle title={'Supplier Wise Sales Return Retail'} />
      <Typography sx={userStyle.HeaderText}>Supplier Wise Estimate Return<Typography sx={userStyle.SubHeaderText}>(Retail)</Typography></Typography>
      <Box sx={userStyle.container} >
        <Grid container lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={5} md={5}>
            <Grid container lg={12} md={12} >
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>Date From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <Grid sx={userStyle.spanIcons}><CalendarMonthOutlinedIcon /></Grid>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    // value={dateFilter.startdate}
                    // onChange={(e) => {setDateFilter({...dateFilter, startdate: e.target.value})}}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} md={5}>
            <Grid container lg={12} md={12} >
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <Grid sx={userStyle.spanIcons}><CalendarMonthOutlinedIcon /></Grid>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    // value={dateFilter.enddate}
                    // onChange={(e) => {setDateFilter({...dateFilter, enddate: e.target.value})}}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary'>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />

      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
      
        { /* ******************************************************EXPORT Buttons****************************************************** */}
        {/*       
          <Box sx={userStyle.container} > */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvsupplierwiseestimatereturn && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelsupplierwiseestimatereturn && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printsupplierwiseestimatereturn && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfsupplierwiseestimatereturn && (
              <>
                <Button sx={userStyle.buttongrp} ><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />

        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="supplierwiseestimatereturn" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Supplier</StyledTableCell>
                  <StyledTableCell >Total Qty</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                <StyledTableRow >
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell ></StyledTableCell>
                  <StyledTableCell ></StyledTableCell>

                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* printlayout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="supplierwiseestimatereturn" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Supplier</StyledTableCell>
              <StyledTableCell >Total Qty</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            <StyledTableRow >
              <StyledTableCell></StyledTableCell>
              <StyledTableCell ></StyledTableCell>
              <StyledTableCell ></StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function Suplierwisereturnlist() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Suplierwisereturn /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Suplierwisereturnlist;