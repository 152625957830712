import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle,colourStyles } from '../../PageStyle';
import Selects from "react-select";
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from 'moment';

function Referencewiseduesreportlist() {

    const [pos, setPos] = useState([]);
    const [checked, setChecked] = useState(false);
    const [exceldata, setExceldata] = useState([]);
    const [reference, setReference] = useState();

    const [dateFilter, setDateFilter] = useState({
        referenceno: ""
    });

    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const { auth } = useContext(AuthContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    //function 
    const fetchCustomerDues = async (e) => {

        setChecked(s => !s)
        try {
            let response = await axios.get(SERVICE.CUSTOMER_PAYMENT);
            if (checked === true) {
                setPos(response.data.customerpays);

            } else if (checked === false) {
                setPos([])

            }
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Customer area in dropdown
    const fetchReference = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setReference(
                req?.data?.pos1?.map((d) => ({
                    ...d,
                    label: d.referenceno,
                    value: d.referenceno,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const handleChangeReference = (newvalue)=>{
        setDateFilter({ ...dateFilter, referenceno: newvalue.value });
        setChecked(false)
    }

    //Customers
    const fetchCustomers = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.customerpays.filter((data) => {
                if (dateFilter.referenceno == data.billno) {
                    return data
                }
                else if (dateFilter.referenceno == "") {
                    return data

                }
            })
            setPos(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 25, 50, 100, 200, 500, -1],
                            [10, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchReference();
            fetchCustomers();
        }, []
    )


    // Excel
    const fileName = 'Reference Wise Dues Report'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = pos.map(t => ({
            "Name": t.name,
            "Reference": t.referenceno,
            "Date": moment(t.date).format('DD-MM-YYYY'),
            "Bill No": t.billno,
            "Bill Date": moment(t.billduedate).format('DD-MM-YYYY'),
            "Due Date": moment(t.duedate).format('DD-MM-YYYY'),
            "Days Diff": Math.ceil((new Date(t.duedate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)),
            "Due Amount": t.amountbalance
        }));
        setExceldata(data);
    }

    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Referencewiseduesreport' })
        doc.save('Referece Wise Dues Report.pdf')
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Referece Wise Dues Report',
        pageStyle: 'print'
    });

    useEffect(
        () => {
            getexcelDatas();
        }, [pos]
    )

    let total = 0;

    return (
        <Box >
            <Headtitle title={'Reference Wise Dues Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Reference Wise Dues Report <Typography sx={userStyle.SubHeaderText} component={'span'}>(Estimate)</Typography></Typography>
            <Box sx={userStyle.filtercontent}>
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={2} md={2}>
                        <Typography sx={{ marginTop: '7px', float: 'right' }}>Reference :</Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sx={{ display: 'flex' }}>
                        <FormControl size="small" fullWidth>
                            <Selects
                                isClearable
                                onChange={handleChangeReference}
                                styles={userStyle.colourStyles}
                                options={reference}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3}>
                        <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onClick={(e) => { fetchCustomerDues(e) }} checked={!checked} />} label="Select All" />
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <Button variant='contained' color='secondary' onClick={fetchCustomers}>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvreferencewisecusduesreportestimate && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelreferencewisecusduesreportestimate && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printreferencewisecusduesreportestimate && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfreferencewisecusduesreportestimate && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                        {/* ****** Table Grid Container ****** */}

                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Referencewiseduesreportlist" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Reference </StyledTableCell>
                                    <StyledTableCell>Date </StyledTableCell>
                                    <StyledTableCell>Bill No</StyledTableCell>
                                    <StyledTableCell>Bill Date</StyledTableCell>
                                    <StyledTableCell>Due Date</StyledTableCell>
                                    <StyledTableCell>Days Diff </StyledTableCell>
                                    <StyledTableCell>Due Amount</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {pos.length > 0 && (
                                    pos.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell >
                                            <StyledTableCell align="left">{row.date ?  moment(row.date).format('DD-MM-YYYY') : " " }</StyledTableCell >
                                            <StyledTableCell align="left">{row.billno}</StyledTableCell >
                                            <StyledTableCell align="left">{moment(row.billduedate).format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(row.duedate).format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{ row.billduedate ? Math.ceil((new Date(row.duedate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)): " "}</StyledTableCell>
                                            <StyledTableCell align="left">{row.amountbalance}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} >
                <Box>
                    <Typography variant='h5' >Referece Wise Dues Report</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table sx={{}} aria-label="simple table" id="Referencewiseduesreport" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Reference </StyledTableCell>
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Bill No</StyledTableCell>
                                        <StyledTableCell>Bill Date</StyledTableCell>
                                        <StyledTableCell>Due Date</StyledTableCell>
                                        <StyledTableCell>Days Diff </StyledTableCell>
                                        <StyledTableCell>Due Amount</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {pos.length > 0 && (
                                        pos.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.name}</StyledTableCell>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell >
                                                <StyledTableCell align="left">{row.date ? " " : moment(row.date).format('DD-MM-YYYY')}</StyledTableCell >
                                                <StyledTableCell align="left">{row.billno}</StyledTableCell >
                                                <StyledTableCell align="left">{moment(row.billduedate).format('DD-MM-YYYY')}</StyledTableCell>
                                                <StyledTableCell align="left">{moment(row.duedate).format('DD-MM-YYYY')}</StyledTableCell>
                                                <StyledTableCell align="left">{Math.ceil((new Date(row.duedate).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))}</StyledTableCell>
                                                <StyledTableCell align="left">{row.amountbalance}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Referencewiseduesreport() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Referencewiseduesreportlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Referencewiseduesreport;