import React from 'react';
import CanvasJSReact from './canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart; const getcyear = new Date().getUTCFullYear();
const getcmonth = new Date();
const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const valnmonth = monthNames[getcmonth.getMonth()];

const options = {
	animationEnabled: true,
	exportEnabled: true,
	theme: "light1", // "light1", "dark1", "dark2"
	title: {
		text: `Top 5 Customers (${valnmonth})`,
		fontSize: 20,
	},
	data: [{
		type: "pie",
		startAngle: 75,
		color: "rgba(103, 58, 183, 0.85)",
		indexLabel: "{label} - {y}%",
		toolTipContent: "<b>{label}</b>: {y}%",
		legendText: "{label}",
		startAngle: -90,
		dataPoints: [
			{ y: 40, label: "Walk-in-customer 1", color: "#0d5bc3" },
			{ y: 10, label: "Walk-in-customer 2", color: "#2885a2" },
			{ y: 5, label: "Walk-in-customer 3", color: "#2d7d9f" },
			{ y: 10, label: "Walk-in-customer 4", color: "#24b6ce" },
			{ y: 5, label: "Walk-in-customer 5", color: "#1cdfec" }
		]
	}]
};

function Dashpiechart() {
	return (
		<div>
			<CanvasJSChart options={options} />
		</div>
	);
}
export default Dashpiechart;