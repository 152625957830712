import React, { useEffect, createRef, useRef } from 'react';
import { Box, Typography, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { userStyle } from '../../PageStyle';
import { useReactToPrint } from 'react-to-print';

function Print() {

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | Sales Commission Agents',
        pageStyle: 'print'
    });
    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    useEffect(() => {
        handleprint();
    }, []);

    return (
        <Box>
            <Box>
                <Typography variant='h5'>Sales Commission Agents</Typography>
            </Box><br />
            <Box>
          <TableContainer component={Paper} sx={userStyle.tablecontainer}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="salesagenttable" ref={componentRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell >Email</StyledTableCell>
                  <StyledTableCell >Contact Number</StyledTableCell>
                  <StyledTableCell >Address</StyledTableCell>
                  <StyledTableCell >Sales Commission Percentage&ensp;(%)</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                <StyledTableRow>
                  <StyledTableCell component="th" scope="row"></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                  <StyledTableCell></StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        </Box>
    );
}

export default Print;