import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Grid, Dialog, DialogContent, DialogActions, Typography, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import $ from 'jquery';
import { Link, } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../Export';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import axios from 'axios';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import moment from 'moment'
import Headtitle from '../../../components/header/Headtitle'



const Quotationlisttable = () => {

    const [quotations, setQuotations] = useState();
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);
    const [deleteqot, setDeleteqot] = useState({});
    const { auth } = useContext(AuthContext);

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleClickOpen = () => {
        setIsDeleteOpen(true);
    };

    const handleClose = () => {
        setIsDeleteOpen(false);
    };

    // Export Excel
    const fileName = 'Quotations'
    const [quotData, setQuotData] = useState([]);

    // get particular columns for export excel
    const getexcelDatas = async () => {
        let response = await axios.get(SERVICE.QUOTATION,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
        });
        var data = response.data.quotations.map(t => {
           delete t._id
           delete t.__v
           delete t.createdAt
           return t
        });
        setQuotData(data);
    }


    const fetchQuotation = async () => {
        try {
            let quotelist = await axios.get(SERVICE.QUOTATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setQuotations(quotelist.data.quotations);
        } catch (err) {
          const messages = err.response.data.messages
          toast.error(messages)

         }
    };


    //set function to get particular row
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.QUOTATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDeleteqot(res.data.squotation);
        } catch (err) {
            const messages = err.response.data.messages
            toast.error(messages)

        }
    }

    // Alert delete popup
    let supid = deleteqot._id;
    const deleteQuot = async () => {
        try {
           await axios.delete(`${SERVICE.QUOTATION_SINGLE}/${supid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
             const messages = err.response.data.messages
            toast.error(messages)
        }

    };

    // Printing
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | QUOTATIONS',
        pageStyle: 'print'
    });

    // PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#itemwisesales' })
        doc.save('posquotations.pdf')
    }
    useEffect(
        () => {
            fetchQuotation();
            getexcelDatas()
        }, [deleteQuot]
    );

    //  JQUERY
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                console.log(tableRef.current)
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10,1, 25, 50, 100, 200, 500, -1],
                        [10,1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box>
            <Headtitle title={'Quoatations list'} />
            <Typography sx={userStyle.HeaderText}>Quotations</Typography>
            {/* Table */}
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                    {isUserRoleCompare[0].aposquotation && (<Link to="/sell/pos/create" style={{ textDecoration: 'none', color: 'white' }}> <Button sx={userStyle.buttonadd} >ADD</Button></Link>)}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].csvposquotation && (<ExportCSV csvData={quotData} fileName={fileName} />)}
                        {isUserRoleCompare[0].excelposquotation && (<ExportXL csvData={quotData} fileName={fileName} />)}
                        {isUserRoleCompare[0].printposquotation && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button> )}
                        {isUserRoleCompare[0].pdfposquotation && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
                    </Grid>
                </Grid>
                <Box>
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="listquotationtab" ref={tableRef}>
                            <TableHead>
                                <StyledTableRow>
                                   <StyledTableCell align="left">Action</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell align="left">Quotation No.</StyledTableCell>
                                    <StyledTableCell align="left">Customer Name</StyledTableCell>
                                    <StyledTableCell align="left">Contact Number</StyledTableCell>
                                    <StyledTableCell align="left">Location</StyledTableCell>
                                    <StyledTableCell align="left">Total Items</StyledTableCell>
                                    <StyledTableCell align="left">Added By</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {quotations && (
                                    quotations.map((row, index) => (
                                        <StyledTableRow key={index}>
                                             <StyledTableCell >
                                                <Grid sx={{display:'flex'}}>
                                                    {isUserRoleCompare[0].eposquotation && (<Link to={`/sell/quotation/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                    {isUserRoleCompare[0].dposquotation && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button> )}
                                                    {isUserRoleCompare[0].vposquotation && (<Link to={`/sell/quotation/view/${row._id}`}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }} ><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}

                                                </Grid>
                                           </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{moment(row.date).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                            <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.address}</StyledTableCell>
                                            <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                            <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                        </StyledTableRow>
                                    )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <>
                <Box>
                    {/* ALERT DIALOG */}
                    <Dialog
                        open={isDeleteOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant="outlined">Cancel</Button>
                            <Button autoFocus variant="contained" color='error' onClick={(e) => deleteQuot(supid)}> OK </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </>


            <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" id="listquotationtable" ref={componentRef}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell align="left">Quotation No.</StyledTableCell>
                            <StyledTableCell align="left">Customer Name</StyledTableCell>
                            <StyledTableCell align="left">Contact Number</StyledTableCell>
                            <StyledTableCell align="left">Location</StyledTableCell>
                            <StyledTableCell align="left">Total Items</StyledTableCell>
                            <StyledTableCell align="left">Added By</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {quotations && (
                            quotations.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row">{row.date}</StyledTableCell>
                                    <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                    <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                    <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.address}</StyledTableCell>
                                    <StyledTableCell align="left">{row.totalitems}</StyledTableCell>
                                    <StyledTableCell align="left">{row.userbyadd}</StyledTableCell>
                                </StyledTableRow>
                            )))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
const Quotationlist = () => {
    return (
        <>
            <Box >
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Quotationlisttable /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Quotationlist;