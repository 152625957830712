import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { Box, Typography, Select, OutlinedInput, InputLabel, MenuItem, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import moment from 'moment';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

function Supplierduesreportlist() {

    const [purchases, setPurchases] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const [checked, setChecked] = useState(true);
    const [code, setCode] = useState([]);
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Datefilter
    const [daysfilter, setDaysfilter] = useState({
        dueoption: "", duevalue: "",
    });

    // Dayscalculation
    let totalDays = 0;

    // jquery
    $.DataTable = require('datatables.net')
    const tableRefsuppdues = useRef()

    // Select all function 
    const fetchPurchaseselect = async (e) => {
        setChecked(!checked)
        try {
            let response = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let req = response.data.purchases.filter((item) => {
                totalDays = (Number(item.creditdays)) - ((new Date().getDate() - new Date(item.purchasedate).getDate()) + 1)
                if (item.paydue > 0 && totalDays > 0) {
                    if (checked === true) {
                        return item
                    }
                    else if (checked === false) {
                        setPurchases([])
                    }
                }
            })
            setPurchases(req)
            setDaysfilter({
                dueoption: "", duevalue: "",
            })
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const getCateName = (e) => {
        if (e) {
            setPurchases([]) || setChecked(true)
        }
        setCode(e.dueoption)
    }

    // Purchases
    const fetchPurchases = async () => {
        try {
            let req = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let filterdata = req.data.purchases.filter((item) => {
                if (item.paydue > 0) {
                    totalDays = (Number(item.creditdays)) - ((new Date().getDate() - new Date(item.purchasedate).getDate()) + 1)
                    if (daysfilter.dueoption == "" && totalDays > 0 && item.paydue > 0) {
                        return item
                    }
                    else if (daysfilter.dueoption === "greaterthan" && totalDays > 0 && item.paydue > 0) {
                        return item.paydue >= daysfilter.duevalue
                    }
                    else if (daysfilter.dueoption === "lesserthan" && totalDays > 0 && item.paydue > 0) {
                        return item.paydue <= daysfilter.duevalue
                    }
                    else if(daysfilter.dueoption === code){
                        return item
                    }
                }
            })
            setPurchases(filterdata);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefsuppdues.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchPurchases()
        }, []
    )

    // Excel
    const fileName = 'SupplierDuesReport'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = purchases.map(t => ({
            "Name": t.supplier, "Bill No": t.referenceno, "Purchase Date": moment(t.purchasedate).utc().format('DD-MM-YYYY'), "Due Date": moment(t.duedate).format('DD-MM-YYYY'), "Days Difference": (Number(t.creditdays)) - ((new Date().getDate() - 1 - new Date(t.purchasedate).getDate()) + 1), "Due Amount": t.paydue
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | SUPPLIER DUES REPORT',
        pageStyle: 'print'
    });

    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    // Pdf
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#supplierduesreport' })
        doc.save('Supplier Dues Report.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [purchases]
    )

    return (
        <Box >
            <Headtitle title={'Supplier Dues Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Supplier Dues Report</Typography>

            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container}>
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>

                        <Grid item lg={2} md={2}>
                            <Typography sx={{ marginTop: '7px', float: 'right' }}>Dues</Typography>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-helper-label">Please Select</InputLabel>
                                <Select
                                    label="Please Select"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={daysfilter.dueoption}
                                    onChange={(e) => { setDaysfilter({ ...daysfilter, dueoption: e.target.value });
                                    getCateName(e);
                                    setChecked(true)
                                 }}
                                >
                                    <MenuItem value="greaterthan"> &gt; </MenuItem>
                                    <MenuItem value="lesserthan"> &lt; </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    placeholder="Enter Amount"
                                    id="component-outlined"
                                    value={daysfilter.duevalue}
                                    onChange={(e) => { setDaysfilter({ ...daysfilter, duevalue: e.target.value, }); }}
                                    type='text'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3}>
                            <FormControlLabel control={<Checkbox checked={!checked}
                                onChange={(e) => fetchPurchaseselect(e)}
                            />} label="Select All" />
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={fetchPurchases}>Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    <br />
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvsupduesreport && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelsupduesreport && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printsupduesreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfsupduesreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table aria-label="simple table" id="supplierduesreport" ref={tableRefsuppdues}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Bill No</StyledTableCell>
                                    <StyledTableCell>Purchase Date</StyledTableCell>
                                    <StyledTableCell>Due Date</StyledTableCell>
                                    <StyledTableCell>Days Difference</StyledTableCell>
                                    <StyledTableCell>Due Amount</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {purchases.length > 0 && (
                                    purchases.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(row.purchasedate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(row.duedate).format('DD-MM-YYYY')}</StyledTableCell >
                                            <StyledTableCell align="left">{(Number(row.creditdays)) - ((new Date().getDate() - 1 - new Date(row.purchasedate).getDate()) + 1)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.paydue}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Table End ****** */}
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls}>
                <Box>
                    <Typography variant='h5' >Supplier Dues Report</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table sx={{}} aria-label="simple table" id="supplierduesreport" ref={componentRef}>
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Bill No</StyledTableCell>
                                        <StyledTableCell>Due Date</StyledTableCell>
                                        <StyledTableCell>Days Difference</StyledTableCell>
                                        <StyledTableCell>Due Amount</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {purchases.length > 0 && (
                                        purchases.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.supplier}</StyledTableCell>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{moment(row.purchasedate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                                <StyledTableCell align="left">{moment(row.duedate).format('DD-MM-YYYY')}</StyledTableCell >
                                                <StyledTableCell align="left">{(Number(row.creditdays)) - ((new Date().getDate() - 1 - new Date(row.purchasedate).getDate()) + 1)}</StyledTableCell>
                                                <StyledTableCell align="left">{row.paydue}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Supplierduesreport() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Supplierduesreportlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Supplierduesreport;