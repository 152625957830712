import React, { useState, useEffect } from 'react';
import { userStyle } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Typography, FormGroup, FormControlLabel, Checkbox, Divider, Button, Radio, Tooltip, IconButton } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';

function Roleeditlist() {

    const [role, setRole] = useState({
        rolename: "",

        // User Management Start
        usermanagement: true,

        // User
        alluser: false,
        checkalluser: false,
        vuser: false,
        auser: false,
        euser: false,
        duser: false,
        exceluser: false,
        csvuser: false,
        printuser: false,
        pdfuser: false,

        // Role
        allrole: false,
        checkallrole: false,
        arole: false,
        erole: false,
        drole: false,
        excelrole: false,
        csvrole: false,
        printrole: false,
        pdfrole: false,

        // Sales Commission Agent
        allsalescommissionagent: false,
        checkallsalescommissionagent: false,
        asalescommissionagent: false,
        esalescommissionagent: false,
        dsalescommissionagent: false,
        excelsalescommissionagent: false,
        csvsalescommissionagent: false,
        printsalescommissionagent: false,
        pdfsalescommissionagent: false,

        //  Department
        alldepartment: false,
        checkalldepartment: false,
        adepartment: false,
        exceldepartment: false,
        csvdepartment: false,
        printdepartment: false,
        pdfdepartment: false,
        edepartment: false,
        ddepartment: false,

        // User Management End

        // Supplier Management Start
        suppliermanagement: false,

        allsupplier: false,
        checkallsupplier: false,
        vsupplier: false,
        asupplier: false,
        esupplier: false,
        dsupplier: false,
        excelsupplier: false,
        csvsupplier: false,
        printsupplier: false,
        pdfsupplier: false,

        // Import Supplier List
        allimpsupplierlist: false,
        checkallimpsupplierlist: false,
        excelimpsupplierlist: false,
        csvimpsupplierlist: false,
        printimpsupplierlist: false,
        pdfimpsupplierlist: false,
        eimpsupplierlist: false,
        dimpsupplierlist: false,
        vimpsupplierlist: false,

        // balance wise supplier dues report
        allbalwisesupduesreport: false,
        checkallbalwisesupduesreport: false,
        csvbalwisesupduesreport: false,
        excelbalwisesupduesreport: false,
        printbalwisesupduesreport: false,
        pdfbalwisesupduesreport: false,

        // Supplier Balance Abstract
        allsupplierbalanceabstract: false,
        checkallsupplierbalanceabstract: false,
        csvsupplierbalanceabstract: false,
        excelsupplierbalanceabstract: false,
        printsupplierbalanceabstract: false,
        pdfsupplierbalanceabstract: false,

        // Supplier Bills Report
        allsupplierbillsreport: false,
        checkallsupplierbillsreport: false,
        csvsupplierbillsreport: false,
        excelsupplierbillsreport: false,
        printsupplierbillsreport: false,
        pdfsupplierbillsreport: false,

        // Supplier Dues Report
        allsupduesreport: false,
        checkallsupduesreport: false,
        csvsupduesreport: false,
        excelsupduesreport: false,
        printsupduesreport: false,
        pdfsupduesreport: false,

        // Supplier Over Dues Report
        allsupplieroverduereport: false,
        checkallsupplieroverduereport: false,
        csvsupplieroverduereport: false,
        excelsupplieroverduereport: false,
        printsupplieroverduereport: false,
        pdfsupplieroverduereport: false,

        // Supplier Payments Report
        allsupplierpaymentsreport: false,
        checkallsupplierpaymentsreport: false,
        csvsupplierpaymentsreport: false,
        excelsupplierpaymentsreport: false,
        printsupplierpaymentsreport: false,
        pdfsupplierpaymentsreport: false,

        // Supplier Report
        allsuppliersreport: false,
        checkallsuppliersreport: false,
        csvsuppliersreport: false,
        excelsuppliersreport: false,
        printsuppliersreport: false,
        pdfsuppliersreport: false,

        // Supplier wise payments report
        allsupplierwisepaymentsreport: false,
        checkallsupplierwisepaymentsreport: false,
        csvsupplierwisepaymentsreport: false,
        excelsupplierwisepaymentsreport: false,
        printsupplierwisepaymentsreport: false,
        pdfsupplierwisepaymentsreport: false,

        // Supplier End

        // Customer Start
        customermanagement: false,
        allcustomer: false,
        checkallcustomer: false,
        acustomer: false,
        ecustomer: false,
        dcustomer: false,
        excelcustomer: false,
        csvcustomer: false,
        printcustomer: false,
        pdfcustomer: false,

        // Area Wise Customer
        allareawisecustomerlist: false,
        checkallareawisecustomerlist: false,
        csvareawisecustomer: false,
        excelareawisecustomer: false,
        printareawisecustomer: false,
        pdfareawisecustomer: false,

        // Balance Wise Customer Dues Report
        allbalancewisecusduesreport: false,
        checkallbalancewisecusduesreport: false,
        excelbalancewisecusduesreport: false,
        csvbalancewisecusduesreport: false,
        printbalancewisecusduesreport: false,
        pdfbalancewisecusduesreport: false,

        //  Category Wise Customer List
        allcategorywisecustomerlist: false,
        checkallcategorywisecustomerlist: false,
        excelcategorywisecustomerlist: false,
        csvcategorywisecustomerlist: false,
        printcategorywisecustomerlist: false,
        pdfcategorywisecustomerlist: false,

        //  Customer Account Statement
        allcustomeraccountstatement: false,
        checkallcustomeraccountstatement: false,
        excelcustomeraccountstatement: false,
        csvcustomeraccountstatement: false,
        printcustomeraccountstatement: false,
        pdfcustomeraccountstatement: false,

        //  Customer Account Summary
        allcustomeraccountsummary: false,
        checkallcustomeraccountsummary: false,
        excelcustomeraccountsummary: false,
        csvcustomeraccountsummary: false,
        printcustomeraccountsummary: false,
        pdfcustomeraccountsummary: false,

        // Customer Balance Register
        allCustomerbalanceregister: false,
        checkallCustomerbalanceregister: false,
        excelCustomerbalanceregister: false,
        csvCustomerbalanceregister: false,
        printCustomerbalanceregister: false,
        pdfCustomerbalanceregister: false,

        // Customer Bills Report
        allcustomerbillsreport: false,
        checkallcustomerbillsreport: false,
        excelcustomerbillsreport: false,
        csvcustomerbillsreport: false,
        printcustomerbillsreport: false,
        pdfcustomerbillsreport: false,

        // Customer Drug Entry
        allcusdrugentry: false,
        checkallcusdrugentry: false,
        acusdrugentry: false,
        excelcusdrugentry: false,
        csvcusdrugentry: false,
        printcusdrugentry: false,
        pdfcusdrugentry: false,

        // Customer Dues Report
        allcustduesreport: false,
        checkallcustduesreport: false,
        excelcustduesreport: false,
        csvcustduesreport: false,
        printcustduesreport: false,
        pdfcustduesreport: false,

        // Customer Over Dues Abstract
        allcusoverduesabstract: false,
        checkallcusoverduesabstract: false,
        excelcusoverduesabstract: false,
        csvcusoverduesabstract: false,
        printcusoverduesabstract: false,
        pdfcusoverduesabstract: false,

        // Customer Over Dues Sreport
        allcusoverduesrep: false,
        checkallcusoverduesrep: false,
        excelcusoverduesrep: false,
        csvcusoverduesrep: false,
        printcusoverduesrep: false,
        pdfcusoverduesrep: false,

        // Customer Payment Report
        allcustomerpaymentreport: false,
        checkallcustomerpaymentreport: false,
        excelcustomerpaymentreport: false,
        csvcustomerpaymentreport: false,
        printcustomerpaymentreport: false,
        pdfcustomerpaymentreport: false,

        //  Customer Wise Payment Report
        allcustomerwisepaymentreport: false,
        checkallcustomerwisepaymentreport: false,
        excelcustomerwisepaymentreport: false,
        csvcustomerwisepaymentreport: false,
        printcustomerwisepaymentreport: false,
        pdfcustomerwisepaymentreport: false,

        // Retail Followup Customer
        allretailfollowupcustomer: false,
        checkallretailfollowupcustomer: false,
        excelretailfollowupcustomer: false,
        csvretailfollowupcustomer: false,
        printretailfollowupcustomer: false,
        pdfretailfollowupcustomer: false,

        // Wholesale Followup Customer
        allwholesalefollowupcustomer: false,
        checkallwholesalefollowupcustomer: false,
        excelwholesalefollowupcustomer: false,
        csvwholesalefollowupcustomer: false,
        printwholesalefollowupcustomer: false,
        pdfwholesalefollowupcustomer: false,

        //  Customer List Retail
        allcustomerlistretail: false,
        checkallcustomerlistretail: false,
        acustomerlistretail: false,
        excelcustomerlistretail: false,
        csvcustomerlistretail: false,
        printcustomerlistretail: false,
        pdfcustomerlistretail: false,
        ecustomerlistretail: false,
        dcustomerlistretail: false,

        // Customer List Whole Sale
        allcustomerlistwholesale: false,
        checkallcustomerlistwholesale: false,
        excelcustomerlistwholesale: false,
        acustomerlistwholesale: false,
        csvcustomerlistwholesale: false,
        printcustomerlistwholesale: false,
        pdfcustomerlistwholesale: false,
        ecustomerlistwholesale: false,
        dcustomerlistwholesale: false,

        // Reference Wise Customer Dues Report Estimate
        allreferencewisecusduesreportestimate: false,
        checkallreferencewisecusduesreportestimate: false,
        excelreferencewisecusduesreportestimate: false,
        csvreferencewisecusduesreportestimate: false,
        printreferencewisecusduesreportestimate: false,
        pdfreferencewisecusduesreportestimate: false,

        // Reference Wise Customer Dues Report Sales
        allreferencewisecusduesreportsales: false,
        checkallreferencewisecusduesreportsales: false,
        excelreferencewisecusduesreportsales: false,
        csvreferencewisecusduesreportsales: false,
        printreferencewisecusduesreportsales: false,
        pdfreferencewisecusduesreportsales: false,

        //  Reference Wise Payment Report
        allreferencewisepaymentreport: false,
        checkallreferencewisepaymentreport: false,
        excelreferencewisepaymentreport: false,
        csvreferencewisepaymentreport: false,
        printreferencewisepaymentreport: false,
        pdfreferencewisepaymentreport: false,

        // Reference Wise Payment Summary
        allreferencewisepaymentsummary: false,
        checkallreferencewisepaymentsummary: false,
        excelreferencewisepaymentsummary: false,
        csvreferencewisepaymentsummary: false,
        printreferencewisepaymentsummary: false,
        pdfreferencewisepaymentsummary: false,

        // Remark Wise Customer Report
        allremarkwisecustomerreport: false,
        checkallremarkwisecustomerreport: false,
        excelremarkwisecustomerreport: false,
        csvremarkwisecustomerreport: false,
        printremarkwisecustomerreport: false,
        pdfremarkwisecustomerreport: false,

        // customer group
        allcustomergrp: false,
        checkallcustomergrp: false,
        acustomergrp: false,
        ecustomergrp: false,
        dcustomergrp: false,
        excelcustomergrp: false,
        csvcustomergrp: false,
        printcustomergrp: false,
        pdfcustomergrp: false,
        // Customer end

        // Product Management

        // Products
        productmanagement: false,
        allproduct: false,
        checkallproduct: false,
        vproduct: false,
        iproduct: false,
        aproduct: false,
        eproduct: false,
        dproduct: false,
        excelproduct: false,
        csvproduct: false,
        printproduct: false,
        pdfproduct: false,

        // Category
        allcategory: false,
        checkallcategory: false,
        acategory: false,
        ecategory: false,
        dcategory: false,
        printcategory: false,
        pdfcategory: false,
        excelcategory: false,
        csvcategory: false,

        // Discount
        alldiscount: false,
        checkalldiscount: false,
        adiscount: false,
        ediscount: false,
        ddiscount: false,
        exceldiscount: false,
        csvdiscount: false,
        printdiscount: false,
        pdfdiscount: false,

        // Unit
        allunit: false,
        checkallunit: false,
        aunit: false,
        eunit: false,
        dunit: false,
        excelunit: false,
        csvunit: false,
        printunit: false,
        pdfunit: false,

        // Category Wise List
        allcatelist: false,
        checkallcatelist: false,
        excelcatelist: false,
        csvcatelist: false,
        printcatelist: false,
        pdfcatelist: false,

        // Category Wise Summary 
        allcatesummary: false,
        checkallcatesummary: false,
        excelcatesummary: false,
        csvcatesummary: false,
        printcatesummary: false,
        pdfcatesummary: false,

        // Manufacture Wise List
        allmanufacturelist: false,
        checkallmanufacturelist: false,
        excelmanufacturelist: false,
        csvmanufacturelist: false,
        printmanufacturelist: false,
        pdfmanufacturelist: false,

        // Manufacture Wise List
        allmanufacturesummary: false,
        checkallmanufacturesummary: false,
        excelmanufacturesummary: false,
        csvmanufacturesummary: false,
        pdfmanufactursummary: false,
        printmanufacturesummary: false,

        // Molecule Wise List
        allmolecule: false,
        checkallmolecule: false,
        excelmolecule: false,
        csvmoleslist: false,
        printsmolelist: false,
        pdfmolecule: false,

        // Molecule Wise Summary
        allmolesummary: false,
        checkallmolesummary: false,
        excelmolesummary: false,
        csvmolessummary: false,
        printsmolesummary: false,
        pdfmolesummary: false,

        // Schedule Wise List
        allschedulelist: false,
        checkallschedulelist: false,
        excelschedulelist: false,
        csvschedulelist: false,
        printschedulelist: false,
        pdfschedulelist: false,

        // Schedule
        allschedule: false,
        checkallschedule: false,
        excelschedule: false,
        csvschedule: false,
        printschedule: false,
        pdfschedule: false,
        eschedule: false,
        dschedule: false,

        // Product end

        //  Purchase Start
        purchasemanagement: false,
        allpurchase: false,
        checkallpurchase: false,
        apurchase: false,
        vpurchase: false,
        epurchase: false,
        dpurchase: false,
        excelpurchase: false,
        csvpurchase: false,
        printpurchase: false,
        pdfpurchase: false,
        //  purchase return
        allpurchasereturnlist: false,
        checkallpurchasereturnlist: false,
        apurchasereturnlist: false,
        vpurchasereturnlist: false,
        epurchasereturnlist: false,
        dpurchasereturnlist: false,
        csvpurchasereturnlist: false,
        excelpurchasereturnlist: false,
        printpurchasereturnlist: false,
        pdfpurchasereturnlist: false,
        //  Supplier product
        allsupplierproduct: false,
        checkallsupplierproduct: false,
        supplierproductadd: false,
        supplierproductview: false,
        supplierproductedit: false,
        supplierproductdelete: false,
        supplierproductcsv: false,
        supplierproductexcel: false,
        supplierproductprint: false,
        supplierproductpdf: false,
        //  category wise purchase
        allcategorywisepurchase: false,
        checkallcategorywisepurchase: false,
        csvcategorywisepurchase: false,
        excelcategorywisepurchase: false,
        printcategorywisepurchase: false,
        pdfcategorywisepurchase: false,
        //  item wise purchase
        allitemwisepurchasereport: false,
        checkallitemwisepurchasereport: false,
        csvitemwisepurchasereport: false,
        excelitemwisepurchasereport: false,
        printitemwisepurchasereport: false,
        pdfitemwisepurchasereport: false,
        //  item wise purchase return
        allitemwisepurchasereturn: false,
        checkallitemwisepurchasereturn: false,
        csvitemwisepurchasereturn: false,
        excelitemwisepurchasereturn: false,
        printitemwisepurchasereturn: false,
        pdfitemwisepurchasereturn: false,
        //  manufacture wise purchase
        allmanufacturewisepurchase: false,
        checkallmanufacturewisepurchase: false,
        csvmanufacturewisepurchase: false,
        excelmanufacturwisepurchase: false,
        printmanufacturewisepurchase: false,
        pdfmanufacturewisepurchase: false,
        //  purchase detailed report
        allpurchasedetailedreport: false,
        checkallpurchasedetailedreport: false,
        csvpurchasedetailedreport: false,
        excelpurchasedetailedreport: false,
        printpurchasedetailedreport: false,
        pdfpurchasedetailedreport: false,
        //  purchase report
        allpurchasereport: false,
        checkallpurchasereport: false,
        csvpurchasereport: false,
        excelpurchasereport: false,
        printpurchasereport: false,
        pdfpurchasereport: false,
        //  purchase return report
        allpurchasereturnreport: false,
        checkallpurchasereturnreport: false,
        csvpurchasereturnreport: false,
        excelpurchasereturnreport: false,
        printpurchasereturnreport: false,
        pdfpurchasereturnreport: false,
        //  supplier wise purchase
        allsupplierwisepurchase: false,
        checkallsupplierwisepurchase: false,
        csvsupplierwisepurchase: false,
        excelsupplierwisepurchase: false,
        printsupplierwisepurchase: false,
        pdfsupplierwisepurchase: false,
        //  supplier wise purchase report
        allsupplierwisepurchasereport: false,
        checkallsupplierwisepurchasereport: false,
        csvsupplierwisepurchasereport: false,
        excelsupplierwisepurchasereport: false,
        printsupplierwisepurchasereport: false,
        pdfsupplierwisepurchasereport: false,
        //  Purchase End

        //  Sell module start
        //  draft
        allposdraft: false,
        checkallposdraft: false,
        vposdraft: false,
        aposdraft: false,
        eposdraft: false,
        dposdraft: false,
        csvposdraft: false,
        excelposdraft: false,
        printposdraft: false,
        pdfposdraft: false,
        // pos
        allposlist: false,
        checkallposlist: false,
        aposlist: false,
        eposlist: false,
        vposlist: false,
        dposlist: false,
        csvposlist: false,
        excelposlist: false,
        printposlist: false,
        pdfposlist: false,
        //  quotation
        allposquotation: false,
        checkallposquotation: false,
        aposquotation: false,
        vposquotation: false,
        eposquotation: false,
        dposquotation: false,
        csvposquotation: false,
        excelposquotation: false,
        printposquotation: false,
        pdfposquotation: false,
        //  area wise sales
        allareawisesales: false,
        checkallareawisesales: false,
        csvareawisesales: false,
        excelareawisesales: false,
        printareawisesales: false,
        pdfareawisesales: false,
        //  category wise sales
        allcategorywisesales: false,
        checkallcategorywisesales: false,
        csvcategorywisesales: false,
        excelcategorywisesales: false,
        printcategorywisesales: false,
        pdfcategorywisesales: false,
        //  day wise sales
        alldaywisesales: false,
        checkalldaywisesales: false,
        csvdaywisesales: false,
        exceldaywisesales: false,
        printdaywisesales: false,
        pdfdaywisesales: false,
        //  diagnosis wise sales
        alldiagnosiswisesales: false,
        checkalldiagnosiswisesales: false,
        csvdiagnosiswisesales: false,
        exceldiagnosiswisesales: false,
        printdiagnosiswisesales: false,
        pdfdiagnosiswisesales: false,
        //  diagnosis wise sales summary
        alldiagnosiswisesalessummary: false,
        checkalldiagnosiswisesalessummary: false,
        csvdiagnosiswisesalessummary: false,
        exceldiagnosiswisesalessummary: false,
        printdiagnosiswisesalessummary: false,
        pdfdiagnosiswisesalessummary: false,
        //  drug register
        allselldrugregister: false,
        checkallselldrugregister: false,
        csvselldrugregister: false,
        excelselldrugregister: false,
        printselldrugregister: false,
        pdfselldrugregister: false,
        //  dr wise sale report
        alldrwisesalereport: false,
        checkalldrwisesalereport: false,
        csvdrwisesalereport: false,
        exceldrwisesalereport: false,
        printdrwisesalereport: false,
        pdfdrwisesalereport: false,
        //  dr wise sale
        alldrwisesale: false,
        checkalldrwisesale: false,
        csvdrwisesale: false,
        exceldrwisesale: false,
        printdrwisesale: false,
        pdfdrwisesale: false,
        //  item wise sales return
        allitemwisesalesreturn: false,
        checkallitemwisesalesreturn: false,
        csvitemwisesalesreturn: false,
        excelitemwisesalesreturn: false,
        printitemwisesalesreturn: false,
        pdfitemwisesalesreturn: false,
        //  item wise sales
        allitemwisesales: false,
        checkallitemwisesales: false,
        csvitemwisesales: false,
        excelitemwisesales: false,
        printitemwisesales: false,
        pdfitemwisesales: false,
        //  manufacture wise sales return
        allmanufacturewisesalesreturn: false,
        checkallmanufacturewisesalesreturn: false,
        csvmanufacturewisesalesreturn: false,
        excelmanufacturewisesalesreturn: false,
        printmanufacturewisesalesreturn: false,
        pdfmanufacturewisesalesreturn: false,
        //  manufacture wise sales
        allmanufacturewisesales: false,
        checkallmanufacturewisesales: false,
        csvmanufacturewisesales: false,
        excelmanufacturewisesales: false,
        printmanufacturewisesales: false,
        pdfmanufacturewisesales: false,
        //  molecule wise sales
        allmoleculewisesales: false,
        checkallmoleculewisesales: false,
        csvmoleculewisesales: false,
        excelmoleculewisesales: false,
        printmoleculewisesales: false,
        pdfmoleculewisesales: false,
        //  patient wise sales
        allpatientwisesales: false,
        checkallpatientwisesales: false,
        csvpatientwisesales: false,
        excelpatientwisesales: false,
        printpatientwisesales: false,
        pdfpatientwisesales: false,
        //  patient wise sales report
        allpatientwisesalesreport: false,
        checkallpatientwisesalesreport: false,
        csvpatientwisesalesreport: false,
        excelpatientwisesalesreport: false,
        printpatientwisesalesreport: false,
        pdfpatientwisesalesreport: false,
        //  salesreturndetails
        allsalesreturndetails: false,
        checkallsalesreturndetails: false,
        csvsalesreturndetails: false,
        excelsalesreturndetails: false,
        printsalesreturndetails: false,
        pdfsalesreturndetails: false,
        //  sales return report
        allsalesreturnreport: false,
        checkallsalesreturnreport: false,
        csvsalesreturnreport: false,
        excelsalesreturnreport: false,
        printsalesreturnreport: false,
        pdfsalesreturnreport: false,
        //  sales return summary
        allsalesreturnsummary: false,
        checkallsalesreturnsummary: false,
        csvsalesreturnsummary: false,
        excelsalesreturnsummary: false,
        printsalesreturnsummary: false,
        pdfsalesreturnsummary: false,
        //  sales report
        allsalesreport: false,
        checkallsalesreport: false,
        csvsalesreport: false,
        excelsalesreport: false,
        printsalesreport: false,
        pdfsalesreport: false,
        //  sales summary
        allsalessummary: false,
        checkallsalessummary: false,
        csvsalessummary: false,
        excelsalessummary: false,
        printsalessummary: false,
        pdfsalessummary: false,
        //  schedule wise sales
        allschedulewisesales: false,
        checkallschedulewisesales: false,
        csvschedulewisesales: false,
        excelschedulewisesales: false,
        printschedulewisesales: false,
        pdfschedulewisesales: false,
        //  sell return
        allsellreturn: false,
        checkallsellreturn: false,
        csvsellreturn: false,
        excelsellreturn: false,
        printsellreturn: false,
        pdfsellreturn: false,
        esellreturn: false,
        vsellreturn: false,
        dsellreturn: false,
        //  supplier wise sales return
        allsupplierwisesalesreturn: false,
        checkallsupplierwisesalesreturn: false,
        csvsupplierwisesalesreturn: false,
        excelsupplierwisesalesreturn: false,
        printsupplierwisesalesreturn: false,
        pdfsupplierwisesalesreturn: false,
        //  supplier wise sales
        allsupplierwisesales: false,
        checkallsupplierwisesales: false,
        excelsupplierwisesales: false,
        csvsupplierwisesales: false,
        printsupplierwisesales: false,
        pdfsupplierwisesales: false,

        //  Sell End

        //  Estimate module
        estimatemanagement: false,
        //  draft
        allestimatedraft: false,
        checkallestimatedraft: false,
        vestimatedraft: false,
        aestimatedraft: false,
        eestimatedraft: false,
        destimatedraft: false,
        csvestimatedraft: false,
        excelestimatedraft: false,
        printestimatedraft: false,
        pdfestimatedraft: false,
        //  estimate list
        allestimatelist: false,
        checkallestimatelist: false,
        aestimatelist: false,
        eestimatelist: false,
        destimatelist: false,
        vestimatelist: false,
        csvestimatelist: false,
        excelestimatelist: false,
        printestimatelist: false,
        pdfestimatelist: false,
        //  quotation
        allestimatequotation: false,
        checkallestimatequotation: false,
        vestimatequotation: false,
        aestimatequotation: false,
        eestimatequotation: false,
        destimatequotation: false,
        csvestimatequotation: false,
        excelestimatequotation: false,
        printestimatequotation: false,
        pdfestimatequotation: false,
        //  area wise estimate
        allareawiseestimate: false,
        checkallareawiseestimate: false,
        csvareawiseestimate: false,
        excelareawiseestimate: false,
        printareawiseestimate: false,
        pdfareawiseestimate: false,
        //  category wise estimates
        allcategorywiseestimates: false,
        checkallcategorywiseestimates: false,
        csvcategorywiseestimates: false,
        excelcategorywiseestimates: false,
        printcategorywiseestimates: false,
        pdfcategorywiseestimates: false,
        //  customer wise estimate report
        allcustomerwiseestimatereport: false,
        checkallcustomerwiseestimatereport: false,
        csvcustomerwiseestimatereport: false,
        excelcustomerwiseestimatereport: false,
        printcustomerwiseestimatereport: false,
        pdfcustomerwiseestimatereport: false,
        //  daywiseestimatereport
        alldaywiseestimatereport: false,
        checkalldaywiseestimatereport: false,
        csvdaywiseestimatereport: false,
        exceldaywiseestimatereport: false,
        printdaywiseestimatereport: false,
        pdfdaywiseestimatereport: false,
        //  estimate report
        allestimatereport: false,
        checkallestimatereport: false,
        csvestimatereport: false,
        excelestimatereport: false,
        printestimatereport: false,
        pdfestimatereport: false,
        //  estimate return register
        allestimatereturnregister: false,
        checkallestimatereturnregister: false,
        csvestimatereturnregister: false,
        excelestimatereturnregister: false,
        printestimatereturnregister: false,
        pdfestimatereturnregister: false,
        //  estimate return report
        allestimatereturnreport: false,
        checkallestimatereturnreport: false,
        csvestimatereturnreport: false,
        excelestimatereturnreport: false,
        printestimatereturnreport: false,
        pdfestimatereturnreport: false,
        //  item wise estimate
        allitemwiseestimate: false,
        checkallitemwiseestimate: false,
        csvitemwiseestimate: false,
        excelitemwiseestimate: false,
        printitemwiseestimate: false,
        pdfitemwiseestimate: false,
        //  item wise estimate return
        allitemwiseestimatereturn: false,
        checkallitemwiseestimatereturn: false,
        csvitemwiseestimatereturn: false,
        excelitemwiseestimatereturn: false,
        printitemwiseestimatereturn: false,
        pdfitemwiseestimatereturn: false,
        //  manufacture wise estimate
        allmanufacturewiseestimate: false,
        checkallmanufacturewiseestimate: false,
        csvmanufacturewiseestimate: false,
        excelmanufacturewiseestimate: false,
        printmanufacturewiseestimate: false,
        pdfmanufacturewiseestimate: false,
        //  manufacture wise estimate return
        allmanufacturewiseestimatereturn: false,
        checkallmanufacturewiseestimatereturn: false,
        csvmanufacturewiseestimatereturn: false,
        excelmanufacturewiseestimatereturn: false,
        printmanufacturewiseestimatereturn: false,
        pdfmanufacturewiseestimatereturn: false,
        //  reference wise estimate
        allreferencewiseestimate: false,
        checkallreferencewiseestimate: false,
        csvreferencewiseestimate: false,
        excelreferencewiseestimate: false,
        printreferencewiseestimate: false,
        pdfreferencewiseestimate: false,

        //  sale return summary
        allestimatesalereturnsummary: false,
        checkallestimatesalereturnsummary: false,
        csvestimatesalereturnsummary: false,
        excelestimatesalereturnsummary: false,
        printestimatesalereturnsummary: false,
        pdfestimatesalereturnsummary: false,

        //  supplier wise estimate
        allsupplierwiseestimate: false,
        checkallsupplierwiseestimate: false,
        csvsupplierwiseestimate: false,
        excelsupplierwiseestimate: false,
        printsupplierwiseestimate: false,
        pdfsupplierwiseestimate: false,
        //  supplier wise estimate return
        allsupplierwiseestimatereturn: false,
        checkallsupplierwiseestimatereturn: false,
        csvsupplierwiseestimatereturn: false,
        excelsupplierwiseestimatereturn: false,
        printsupplierwiseestimatereturn: false,
        pdfsupplierwiseestimatereturn: false,

        //  Estimate module end

        //  Stock Management Start
        stockmanagement: false,

        // Batch Wise Stock Details
        allbatchwisestockdetails: false,
        checkallbatchwisestockdetails: false,
        csvbatchwisestockdetails: false,
        excelbatchwisestockdetails: false,
        printbatchwisestockdetails: false,
        pdfbatchwisestockdetails: false,

        // Batch Wise Stock Report
        allbatchwisestockreport: false,
        checkallbatchwisestockreport: false,
        csvbatchwisestockreport: false,
        excelbatchwisestockreport: false,
        printbatchwisestockreport: false,
        pdfbatchwisestockreport: false,

        // Stock Expiry Report
        allstockexpriyreport: false,
        checkallstockexpriyreport: false,
        csvstockexpriyreport: false,
        excelstockexpriyreport: false,
        printstockexpriyreport: false,
        pdfstockexpriyreport: false,

        // Item Code Update
        allitemcodeupdate: false,
        checkallitemcodeupdate: false,
        csvitemcodeupdate: false,
        excelitemcodeupdate: false,
        printitemcodeupdate: false,
        pdfitemcodeupdate: false,

        // Item Wise Stock Report
        allitemwisestockreport: false,
        checkallitemwisestockreport: false,
        csvitemwisestockreport: false,
        excelitemwisestockreport: false,
        printitemwisestockreport: false,
        pdfitemwisestockreport: false,

        // Manual Stock Entry
        allmanualstockentry: false,
        checkallmanualstockentry: false,
        csvmanualstockentry: false,
        excelmanualstockentry: false,
        printmanualstockentry: false,
        pdfmanualstockentry: false,

        // Manufacture Update
        allmanufactureupdate: false,
        checkallmanufactureupdate: false,
        umanufactureupdate: false,
        csvmanufactureupdate: false,
        excelmanufactureupdate: false,
        printmanufactureupdate: false,
        pdfmanufactureupdate: false,

        // Minimum Wise Stock Report
        allminimumwisestockreport: false,
        checkallminimumwisestockreport: false,
        uminimumwisestockreport: false,
        csvminimumwisestockreport: false,
        excelminimumwisestockreport: false,
        printminimumwisestockreport: false,
        pdfminimumwisestockreport: false,

        // Nile Stock Report
        allnilestockreport: false,
        checkallnilestockreport: false,
        csvnilestockreport: false,
        excelnilestockreport: false,
        printnilestockreport: false,
        pdfnilestockreport: false,

        // Non Moving Stock Report
        allnonmovingstockreport: false,
        checkallnonmovingstockreport: false,
        csvnonmovingstockreport: false,
        excelnonmovingstockreport: false,
        printnonmovingstockreport: false,
        pdfnonmovingstockreport: false,

        // Pack Quality Update
        allpackqualityupdate: false,
        checkallpackqualityupdate: false,
        upackqualityupdate: false,
        excelpackqualityupdate: false,
        csvpackqualityupdate: false,
        printpackqualityupdate: false,
        pdfpackqualityupdate: false,

        // Stock Expiry Report
        allstockexpiryreport: false,
        checkallstockexpiryreport: false,
        excelstockexpiryreport: false,
        csvstockexpiryreport: false,
        printstockexpiryreport: false,
        pdfstockexpiryreport: false,

        // Stock Over View Report
        allstockoverviewreport: false,
        checkallstockoverviewreport: false,
        excelstockoverviewreport: false,
        csvstockoverviewreport: false,
        printstockoverviewreport: false,
        pdfstockoverviewreport: false,

        // Stock Update
        allstockupdate: false,
        checkallstockupdate: false,
        excelstockupdate: false,
        csvstockupdate: false,
        printstockupdate: false,
        pdfstockupdate: false,

        //  Stock Management End


        // Expance Management
        expensemanagement: false,

        //  Expense
        allexpense: false,
        checkallexpense: false,
        aexpense: false,
        eexpense: false,
        dexpense: false,
        excelexpense: false,
        csvexpense: false,
        printexpense: false,
        pdfexpense: false,
        payexpense: false,

        // Expense Category
        dallexpensecategoryuser: false,
        checkallexpensecategory: false,
        aexpensecategory: false,
        eexpensecategory: false,
        dexpensecategory: false,
        excelexpensecategory: false,
        csvexpensecategory: false,
        printexpensecategory: false,
        pdfexpensecategory: false,

        // Account Management Start

        accountmanagement: false,

        // Account Creation
        allaccountcreation: false,
        checkallaccountcreation: false,
        excelaccountcreation: false,
        csvaccountcreation: false,
        printaccountcreation: false,
        pdfaccountcreation: false,
        aaccountcreation: false,
        eaccountcreation: false,
        daccountcreation: false,

        // Group Creation
        allgroupcreation: false,
        checkallgroupcreation: false,
        excelgroupcreation: false,
        csvgroupcreation: false,
        printgroupcreation: false,
        pdfgroupcreation: false,
        agroupcreation: false,
        egroupcreation: false,
        dgroupcreation: false,

        // Voucher
        allvoucherlist: false,
        checkallvoucherlist: false,
        excelvoucherlist: false,
        csvvoucherlist: false,
        printvoucherlist: false,
        pdfvoucherlist: false,
        addvoucherlist: false,
        evoucherlist: false,
        dvoucherlist: false,
        vvoucherlist: false,

        // Bank Statement
        allbankstatement: false,
        checkallbankstatement: false,
        csvbankstatement: false,
        excelbankstatement: false,
        printbankstatement: false,
        pdfbankstatement: false,

        // Cashbook Register Report 
        allcashbookregreport: false,
        checkallcashbookregreport: false,
        csvcashbookregreport: false,
        excelcashbookregreport: false,
        printcashbookregreport: false,
        pdfcashbookregreport: false,

        // Cashbook Report
        allcashbookreport: false,
        checkallcashbookreport: false,
        csvcashbookreport: false,
        excelcashbookreport: false,
        printcashbookreport: false,
        pdfcashbookreport: false,

        // Group Summary
        allgroupsummary: false,
        checkallgroupsummary: false,
        csvgroupsummary: false,
        excelgroupsummary: false,
        printgroupsummary: false,
        pdfgroupsummary: false,

        // Profit Loss Account
        allprofitlossaccount: false,
        checkallprofitlossaccount: false,
        printprofitlossaccount: false,
        pdfprofitlossaccount: false,
        alltrailbalance: false,
        checkalltrailbalance: false,
        csvtrailbalance: false,
        exceltrailbalance: false,
        printtrailbalance: false,
        pdftrailbalance: false,

        // Bank Management 
        bankmanagement: false,

        // Bank Master
        allbankmaster: false,
        checkallbankmaster: false,
        csvbankmaster: false,
        excelbankmaster: false,
        printbankmaster: false,
        pdfbankmaster: false,
        viewbankmaster: false,
        editbankmaster: false,
        deletebankmaster: false,

        // Bank Master Report
        allbankmasterreport: false,
        checkallbankmasterreport: false,
        csvbankmasterreport: false,
        excelbankmasterreport: false,
        printbankmasterreport: false,
        pdfbankmasterreport: false,

        // Bank Book Report
        allbankbookreport: false,
        checkallbankbookreport: false,
        csvbankbookreport: false,
        excelbankbookreport: false,
        printbankbookreport: false,
        pdfbankbookreport: false,

        // Bank End

        // Settings Management Start
        settingsmanagement: false,

        // Alpharate
        allalpharate: false,
        checkallalpharate: false,
        valpharate: false,
        aalpharate: false,

        // Business Location
        allbusinesslocation: false,
        checkallbusinesslocation: false,
        activatebusinesslocation: false,
        abusinesslocation: false,
        ebusinesslocation: false,
        dbusinesslocation: false,
        excelbusinesslocation: false,
        csvbusinesslocation: false,
        printbusinesslocation: false,
        pdfbusinesslocation: false,

        // Payment Integration
        allpaymentintegration: false,
        checkallpaymentintegration: false,
        vpaymentintegration: false,
        apaymentintegration: false,
        epaymentintegration: false,
        dpaymentintegration: false,
        excelpaymentintegration: false,
        csvpaymentintegration: false,
        pdfpaymentintegration: false,

        // Tax Rate
        alltaxrate: false,
        checkalltaxrate: false,
        ataxrate: false,
        etaxrate: false,
        dtaxrate: false,
        exceltaxrate: false,
        csvtaxrate: false,
        printtaxrate: false,
        pdftaxrate: false,

        // Tax Rate Group
        alltaxrategroup: false,
        checkalltaxrategroup: false,
        ataxrategroup: false,
        dtaxrategroup: false,
        exceltaxrategroup: false,
        csvtaxrategroup: false,
        printtaxrategroup: false,
        pdftaxrategroup: false,

        // Taxrate Hsn
        alltaxratehsn: false,
        checkalltaxratehsn: false,
        ataxratehsn: false,
        dtaxratehsn: false,
        exceltaxratehsn: false,
        csvtaxratehsn: false,
        printtaxratehsn: false,
        pdftaxratehsn: false,

        // Gstr Purchase
        allgstrpurchase: false,
        checkallgstrpurchase: false,
        excelgstrpurchase: false,
        csvgstrpurchase: false,
        printgstrpurchase: false,
        pdfgstrpurchase: false,

        // Gstr Purchase Return
        allgstrpurchasereturn: false,
        checkallgstrpurchasereturn: false,
        excelgstrpurchasereturn: false,
        csvgstrpurchasereturn: false,
        printgstrpurchasereturn: false,
        pdfgstrpurchasereturn: false,

        // Gstr Retail Sales Return
        allgstrretailsalesret: false,
        checkallgstrretailsalesret: false,
        excelgstrretailsalesret: false,
        csvgstrretailsalesret: false,
        printgstrretailsalesret: false,
        pdfgstrretailsalesret: false,

        // Gstr Sales Retail
        allgstrsalesretail: false,
        checkallgstrsalesretail: false,
        excelgstrsalesretail: false,
        csvgstrsalesretail: false,
        printgstrsalesretail: false,
        pdfgstrsalesretail: false,

        // Gstr Wholesale
        allgstrwholesale: false,
        checkallgstrwholesale: false,
        excelgstrwholesale: false,
        csvgstrwholesale: false,
        printgstrwholesale: false,
        pdfgstrwholesale: false,

        // Gstr Whole Sale Return
        allgstrwholesaleret: false,
        checkallgstrwholesaleret: false,
        excelgstrwholesaleret: false,
        csvgstrwholesaleret: false,
        printgstrwholesaleret: false,
        pdfgstrwholesaleret: false,

        // Hsn Wise Report Retails
        allhsnwisereportretails: false,
        checkallhsnwisereportretails: false,
        excelhsnwisereportretails: false,
        csvhsnwisereportretails: false,
        printhsnwisereportretails: false,
        pdfhsnwisereportretails: false,

        // Hsn Wise Report Retail Sales Return
        allhsnwiserepretailsalesret: false,
        checkallhsnwiserepretailsalesret: false,
        excelhsnwiserepretailsalesret: false,
        csvhsnwiserepretailsalesret: false,
        printhsnwiserepretailsalesret: false,
        pdfhsnwiserepretailsalesret: false,

        // Hsn Wise Report Whole Sales
        allhsnwiserepwholesales: false,
        checkallhsnwiserepwholesales: false,
        excelhsnwiserepwholesales: false,
        csvhsnwiserepwholesales: false,
        printhsnwiserepwholesales: false,
        pdfhsnwiserepwholesales: false,

        // Hsn Wise Report Whole Sale Return
        allhsnwiserepwholesalereturn: false,
        checkallhsnwiserepwholesalereturn: false,
        excelhsnwiserepwholesalereturn: false,
        csvhsnwiserepwholesalereturn: false,
        printhsnwiserepwholesalereturn: false,
        pdfhsnwiserepwholesalereturn: false,

        // Hsn Wise Summary Purchase
        allhsnwisesummarypurchase: false,
        checkallhsnwisesummarypurchase: false,
        excelhsnwisesummarypurchase: false,
        csvhsnwisesummarypurchase: false,
        printhsnwisesummarypurchase: false,
        pdfhsnwisesummarypurchase: false,

        // Settings Management End

        // Report Management Start..
        reportmanagement: false,

        // Bill wise Profit Estimate
        allbillwiseprofitestimate: false,
        checkallbillwiseprofitestimate: false,
        csvbillwiseprofitestimate: false,
        excelbillwiseprofitestimate: false,
        printbillwiseprofitestimate: false,
        pdfbillwiseprofitestimate: false,

        // Bill wise Profit Retail
        allbillwiseprofitretail: false,
        checkallbillwiseprofitretail: false,
        csvbillwiseprofitretail: false,
        excelbillwiseprofitretail: false,
        printbillwiseprofitretail: false,
        pdfbillwiseprofitretail: false,

        // Bill wise Profit Whole Sale
        allbillwiseprofitwholesale: false,
        checkallbillwiseprofitwholesale: false,
        csvbillwiseprofitwholesale: false,
        excelbillwiseprofitwholesale: false,
        printbillwiseprofitwholesale: false,
        pdfbillwiseprofitwholesale: false,

        // Customer Comparison
        allcustomercomparison: false,
        checkallcustomercomparison: false,
        csvcustomercomparison: false,
        excelcustomercomparison: false,
        printcustomercomparison: false,
        pdfcustomercomparison: false,

        // Customer Wise Profit Estimate
        allcustomerwiseprofitestimate: false,
        checkallcustomerwiseprofitestimate: false,
        csvcustomerwiseprofitestimate: false,
        excelcustomerwiseprofitestimate: false,
        printcustomerwiseprofitestimate: false,
        pdfcustomerwiseprofitestimate: false,

        // Customer Wise Profit Whole Sale
        allcustomerwiseprofitwholesale: false,
        checkallcustomerwiseprofitwholesale: false,
        csvcustomerwiseprofitwholesale: false,
        excelcustomerwiseprofitwholesale: false,
        printcustomerwiseprofitwholesale: false,
        pdfcustomerwiseprofitwholesale: false,

        // Day Wise Profit Estimate
        alldaywiseprofitestimate: false,
        checkalldaywiseprofitestimate: false,
        csvdaywiseprofitestimate: false,
        exceldaywiseprofitestimate: false,
        printdaywiseprofitestimate: false,
        pdfdaywiseprofitestimate: false,

        // Day Wise Profit Retail
        alldaywiseprofitretail: false,
        checkalldaywiseprofitretail: false,
        csvdaywiseprofitretail: false,
        exceldaywiseprofitretail: false,
        printdaywiseprofitretail: false,
        pdfdaywiseprofitretail: false,

        // Day Wise Profit Whole sale
        alldaywiseprofitwholesale: false,
        checkalldaywiseprofitwholesale: false,
        csvdaywiseprofitwholesale: false,
        exceldaywiseprofitwholesale: false,
        printdaywiseprofitwholesale: false,
        pdfdaywiseprofitwholesale: false,

        // Item Wise Profit Estimate
        allitemwiseprofitestimate: false,
        checkallitemwiseprofitestimate: false,
        csvitemwiseprofitestimate: false,
        excelitemwiseprofitestimate: false,
        printitemwiseprofitestimate: false,
        pdfitemwiseprofitestimate: false,

        // Item Wise Profit Retail
        allitemwiseprofitretail: false,
        checkallitemwiseprofitretail: false,
        csvitemwiseprofitretail: false,
        excelitemwiseprofitretail: false,
        printitemwiseprofitretail: false,
        pdfitemwiseprofitretail: false,

        // Item Wise Profit Whole Sale
        allitemwiseprofitwholesale: false,
        checkallitemwiseprofitwholesale: false,
        csvitemwiseprofitwholesale: false,
        excelitemwiseprofitwholesale: false,
        printitemwiseprofitwholesale: false,
        pdfitemwiseprofitwholesale: false,

        // Reference Comparison
        allreferencecomparison: false,
        checkallreferencecomparison: false,
        csvreferencecomparison: false,
        excelreferencecomparison: false,
        printreferencecomparison: false,
        pdfreferencecomparison: false,

        // Reference Summary
        allreferencesummary: false,
        checkallreferencesummary: false,
        csvreferencesummary: false,
        excelreferencesummary: false,
        printreferencesummary: false,
        pdfreferencesummary: false,

        // Report Management End..
    });
    const id = useParams().id

    // User Management....Start

    const userAllSelect = () => {
        setRole((prevUser) => {
            return { ...prevUser, checkalluser: !role.checkalluser, vuser: !role.vuser, auser: !role.auser, euser: !role.euser, duser: !role.duser, exceluser: !role.exceluser, csvuser: !role.csvuser, printuser: !role.printuser, pdfuser: !role.pdfuser }
        }
        )
    }

    const roleAllSelect = () => {
        setRole((prevRole) => {
            return { ...prevRole, checkallrole: !role.checkallrole, arole: !role.arole, erole: !role.erole, drole: !role.drole, excelrole: !role.excelrole, csvrole: !role.csvrole, printrole: !role.printrole, pdfrole: !role.pdfrole, }
        })
    }

    const salesCommissionAgentAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsalescommissionagent: !role.checkallsalescommissionagent,
                asalescommissionagent: !role.asalescommissionagent,
                esalescommissionagent: !role.esalescommissionagent,
                dsalescommissionagent: !role.dsalescommissionagent,
                excelsalescommissionagent: !role.excelsalescommissionagent,
                csvsalescommissionagent: !role.csvsalescommissionagent,
                printsalescommissionagent: !role.printsalescommissionagent,
                pdfsalescommissionagent: !role.pdfsalescommissionagent,
            }
        })
    }

    const departmentAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldepartment: !role.checkalldepartment,
                adepartment: !role.adepartment,
                exceldepartment: !role.exceldepartment,
                csvdepartment: !role.csvdepartment,
                printdepartment: !role.printdepartment,
                pdfdepartment: !role.pdfdepartment,
                edepartment: !role.edepartment,
                ddepartment: !role.ddepartment,
            }
        })
    }

    // User Management...End

    // Supplier  Management..start

    const importSupplierAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallimpsupplierlist: !role.checkallimpsupplierlist,
                csvimpsupplierlist: !role.csvimpsupplierlist,
                excelimpsupplierlist: !role.excelimpsupplierlist,
                printimpsupplierlist: !role.printimpsupplierlist,
                pdfimpsupplierlist: !role.pdfimpsupplierlist,
                eimpsupplierlist: !role.eimpsupplierlist,
                dimpsupplierlist: !role.dimpsupplierlist,
                vimpsupplierlist: !role.vimpsupplierlist,
            }
        })
    }

    const balancewiseSupplierAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbalwisesupduesreport: !role.checkallbalwisesupduesreport,
                csvbalwisesupduesreport: !role.csvbalwisesupduesreport,
                excelbalwisesupduesreport: !role.excelbalwisesupduesreport,
                printbalwisesupduesreport: !role.printbalwisesupduesreport,
                pdfbalwisesupduesreport: !role.pdfbalwisesupduesreport,
            }
        })
    }

    const subBalAbstractSupplierAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierbalanceabstract: !role.checkallsupplierbalanceabstract,
                csvsupplierbalanceabstract: !role.csvsupplierbalanceabstract,
                excelsupplierbalanceabstract: !role.excelsupplierbalanceabstract,
                printsupplierbalanceabstract: !role.printsupplierbalanceabstract,
                pdfsupplierbalanceabstract: !role.pdfsupplierbalanceabstract,
            }
        })
    }

    const supplierBillsReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierbillsreport: !role.checkallsupplierbillsreport,
                csvsupplierbillsreport: !role.csvsupplierbillsreport,
                excelsupplierbillsreport: !role.excelsupplierbillsreport,
                printsupplierbillsreport: !role.printsupplierbillsreport,
                pdfsupplierbillsreport: !role.pdfsupplierbillsreport,
            }
        })
    }

    const supplierduesReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupduesreport: !role.checkallsupduesreport,
                csvsupduesreport: !role.csvsupduesreport,
                excelsupduesreport: !role.excelsupduesreport,
                printsupduesreport: !role.printsupduesreport,
                pdfsupduesreport: !role.pdfsupduesreport,
            }
        })
    }

    const supplieroverduesReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplieroverduereport: !role.checkallsupplieroverduereport,
                csvsupplieroverduereport: !role.csvsupplieroverduereport,
                excelsupplieroverduereport: !role.excelsupplieroverduereport,
                printsupplieroverduereport: !role.printsupplieroverduereport,
                pdfsupplieroverduereport: !role.pdfsupplieroverduereport,
            }
        })
    }

    const supplierpaymentsReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierpaymentsreport: !role.checkallsupplierpaymentsreport,
                csvsupplierpaymentsreport: !role.csvsupplierpaymentsreport,
                excelsupplierpaymentsreport: !role.excelsupplierpaymentsreport,
                printsupplierpaymentsreport: !role.printsupplierpaymentsreport,
                pdfsupplierpaymentsreport: !role.pdfsupplierpaymentsreport,
            }
        })
    }

    const supplierReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsuppliersreport: !role.checkallsuppliersreport,
                csvsuppliersreport: !role.csvsuppliersreport,
                excelsuppliersreport: !role.excelsuppliersreport,
                printsuppliersreport: !role.printsuppliersreport,
                pdfsuppliersreport: !role.pdfsuppliersreport,
            }
        })
    }

    const supplierwisepayReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierwisepaymentsreport: !role.checkallsupplierwisepaymentsreport,
                csvsupplierwisepaymentsreport: !role.csvsupplierwisepaymentsreport,
                excelsupplierwisepaymentsreport: !role.excelsupplierwisepaymentsreport,
                printsupplierwisepaymentsreport: !role.printsupplierwisepaymentsreport,
                pdfsupplierwisepaymentsreport: !role.pdfsupplierwisepaymentsreport,
            }
        })
    }

    const supplierAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplier: !role.checkallsupplier,
                vsupplier: !role.vsupplier,
                asupplier: !role.asupplier,
                esupplier: !role.esupplier,
                dsupplier: !role.dsupplier,
                excelsupplier: !role.excelsupplier,
                csvsupplier: !role.csvsupplier,
                printsupplier: !role.printsupplier,
                pdfsupplier: !role.pdfsupplier,
            }
        })
    }

    // Suppler Management...End

    // Customer Management ...Start

    const customerAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomer: !role.checkallcustomer,
                acustomer: !role.acustomer,
                ecustomer: !role.ecustomer,
                dcustomer: !role.dcustomer,
                excelcustomer: !role.excelcustomer,
                csvcustomer: !role.csvcustomer,
                printcustomer: !role.printcustomer,
                pdfcustomer: !role.pdfcustomer,
            }
        })
    }

    const areaWiseCustomerAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallareawisecustomerlist: !role.checkallareawisecustomerlist,
                csvareawisecustomer: !role.csvareawisecustomer,
                excelareawisecustomer: !role.excelareawisecustomer,
                printareawisecustomer: !role.printareawisecustomer,
                pdfareawisecustomer: !role.pdfareawisecustomer,
            }
        })
    }

    const balWiseCustomerAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbalancewisecusduesreport: !role.checkallbalancewisecusduesreport,
                excelbalancewisecusduesreport: !role.excelbalancewisecusduesreport,
                csvbalancewisecusduesreport: !role.csvbalancewisecusduesreport,
                printbalancewisecusduesreport: !role.printbalancewisecusduesreport,
                pdfbalancewisecusduesreport: !role.pdfbalancewisecusduesreport,
            }
        })
    }

    const categoryWiseCustomerAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcategorywisecustomerlist: !role.checkallcategorywisecustomerlist,
                excelcategorywisecustomerlist: !role.excelcategorywisecustomerlist,
                csvcategorywisecustomerlist: !role.csvcategorywisecustomerlist,
                printcategorywisecustomerlist: !role.printcategorywisecustomerlist,
                pdfcategorywisecustomerlist: !role.pdfcategorywisecustomerlist,
            }
        })
    }

    const customerAccountStaAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomeraccountstatement: !role.checkallcustomeraccountstatement,
                excelcustomeraccountstatement: !role.excelcustomeraccountstatement,
                csvcustomeraccountstatement: !role.csvcustomeraccountstatement,
                printcustomeraccountstatement: !role.printcustomeraccountstatement,
                pdfcustomeraccountstatement: !role.pdfcustomeraccountstatement,
            }
        })
    }
    const customerAccountSumAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomeraccountsummary: !role.checkallcustomeraccountsummary,
                excelcustomeraccountsummary: !role.excelcustomeraccountsummary,
                csvcustomeraccountsummary: !role.csvcustomeraccountsummary,
                printcustomeraccountsummary: !role.printcustomeraccountsummary,
                pdfcustomeraccountsummary: !role.pdfcustomeraccountsummary,
            }
        })
    }

    const customerBalanceRegAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallCustomerbalanceregister: !role.checkallCustomerbalanceregister,
                excelCustomerbalanceregister: !role.excelCustomerbalanceregister,
                csvCustomerbalanceregister: !role.csvCustomerbalanceregister,
                printCustomerbalanceregister: !role.printCustomerbalanceregister,
                pdfCustomerbalanceregister: !role.pdfCustomerbalanceregister,
            }
        })
    }

    const customerBillsRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerbillsreport: !role.checkallcustomerbillsreport,
                excelcustomerbillsreport: !role.excelcustomerbillsreport,
                csvcustomerbillsreport: !role.csvcustomerbillsreport,
                printcustomerbillsreport: !role.printcustomerbillsreport,
                pdfcustomerbillsreport: !role.pdfcustomerbillsreport,
            }
        })
    }

    const customerDrugEntAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcusdrugentry: !role.checkallcusdrugentry,
                acusdrugentry: !role.acusdrugentry,
                excelcusdrugentry: !role.excelcusdrugentry,
                csvcusdrugentry: !role.csvcusdrugentry,
                printcusdrugentry: !role.printcusdrugentry,
                pdfcusdrugentry: !role.pdfcusdrugentry,
            }
        })
    }

    const customerDuesRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustduesreport: !role.checkallcustduesreport,
                excelcustduesreport: !role.excelcustduesreport,
                csvcustduesreport: !role.csvcustduesreport,
                printcustduesreport: !role.printcustduesreport,
                pdfcustduesreport: !role.pdfcustduesreport,
            }
        })
    }

    const customerOvDuesAbRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcusoverduesabstract: !role.checkallcusoverduesabstract,
                excelcusoverduesabstract: !role.excelcusoverduesabstract,
                csvcusoverduesabstract: !role.csvcusoverduesabstract,
                printcusoverduesabstract: !role.printcusoverduesabstract,
                pdfcusoverduesabstract: !role.pdfcusoverduesabstract,
            }
        })
    }

    const customerOvDuesrepRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcusoverduesrep: !role.checkallcusoverduesrep,
                excelcusoverduesrep: !role.excelcusoverduesrep,
                csvcusoverduesrep: !role.csvcusoverduesrep,
                printcusoverduesrep: !role.printcusoverduesrep,
                pdfcusoverduesrep: !role.pdfcusoverduesrep,
            }
        })
    }

    const customerpayrepRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerpaymentreport: !role.checkallcustomerpaymentreport,
                excelcustomerpaymentreport: !role.excelcustomerpaymentreport,
                csvcustomerpaymentreport: !role.csvcustomerpaymentreport,
                printcustomerpaymentreport: !role.printcustomerpaymentreport,
                pdfcustomerpaymentreport: !role.pdfcustomerpaymentreport,
            }
        })
    }

    const customerwisepayrepRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerwisepaymentreport: !role.checkallcustomerwisepaymentreport,
                excelcustomerwisepaymentreport: !role.excelcustomerwisepaymentreport,
                csvcustomerwisepaymentreport: !role.csvcustomerwisepaymentreport,
                printcustomerwisepaymentreport: !role.printcustomerwisepaymentreport,
                pdfcustomerwisepaymentreport: !role.pdfcustomerwisepaymentreport,
            }
        })
    }

    const retailFolwCusRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallretailfollowupcustomer: !role.checkallretailfollowupcustomer,
                excelretailfollowupcustomer: !role.excelretailfollowupcustomer,
                csvretailfollowupcustomer: !role.csvretailfollowupcustomer,
                printretailfollowupcustomer: !role.printretailfollowupcustomer,
                pdfretailfollowupcustomer: !role.pdfretailfollowupcustomer,
            }
        })
    }

    const wholesaleFolwCusRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallwholesalefollowupcustomer: !role.checkallwholesalefollowupcustomer,
                excelwholesalefollowupcustomer: !role.excelwholesalefollowupcustomer,
                csvwholesalefollowupcustomer: !role.csvwholesalefollowupcustomer,
                printwholesalefollowupcustomer: !role.printwholesalefollowupcustomer,
                pdfwholesalefollowupcustomer: !role.pdfwholesalefollowupcustomer,
            }
        })
    }

    const customerlistretailAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerlistretail: !role.checkallcustomerlistretail,
                acustomerlistretail: !role.acustomerlistretail,
                excelcustomerlistretail: !role.excelcustomerlistretail,
                csvcustomerlistretail: !role.csvcustomerlistretail,
                printcustomerlistretail: !role.printcustomerlistretail,
                pdfcustomerlistretail: !role.pdfcustomerlistretail,
                ecustomerlistretail: !role.ecustomerlistretail,
                dcustomerlistretail: !role.dcustomerlistretail,
            }
        })
    }

    const customerlistwholesaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerlistwholesale: !role.checkallcustomerlistwholesale,
                acustomerlistwholesale: !role.acustomerlistwholesale,
                excelcustomerlistwholesale: !role.excelcustomerlistwholesale,
                csvcustomerlistwholesale: !role.csvcustomerlistwholesale,
                printcustomerlistwholesale: !role.printcustomerlistwholesale,
                pdfcustomerlistwholesale: !role.pdfcustomerlistwholesale,
                ecustomerlistwholesale: !role.ecustomerlistwholesale,
                dcustomerlistwholesale: !role.dcustomerlistwholesale,
            }
        })
    }

    const refWiseduesRepEsAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallreferencewisecusduesreportestimate: !role.checkallreferencewisecusduesreportestimate,
                excelreferencewisecusduesreportestimate: !role.excelreferencewisecusduesreportestimate,
                csvreferencewisecusduesreportestimate: !role.csvreferencewisecusduesreportestimate,
                printreferencewisecusduesreportestimate: !role.printreferencewisecusduesreportestimate,
                pdfreferencewisecusduesreportestimate: !role.pdfreferencewisecusduesreportestimate,
            }
        })
    }

    const refWiseCusDuesRepSaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallreferencewisecusduesreportsales: !role.checkallreferencewisecusduesreportsales,
                excelreferencewisecusduesreportsales: !role.excelreferencewisecusduesreportsales,
                csvreferencewisecusduesreportsales: !role.csvreferencewisecusduesreportsales,
                printreferencewisecusduesreportsales: !role.printreferencewisecusduesreportsales,
                pdfreferencewisecusduesreportsales: !role.pdfreferencewisecusduesreportsales,

            }
        })
    }

    const referenceWisePayRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallreferencewisepaymentreport: !role.checkallreferencewisepaymentreport,
                excelreferencewisepaymentreport: !role.excelreferencewisepaymentreport,
                csvreferencewisepaymentreport: !role.csvreferencewisepaymentreport,
                printreferencewisepaymentreport: !role.printreferencewisepaymentreport,
                pdfreferencewisepaymentreport: !role.pdfreferencewisepaymentreport,

            }
        })
    }

    const referenceWisePaySummRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallreferencewisepaymentsummary: !role.checkallreferencewisepaymentsummary,
                excelreferencewisepaymentsummary: !role.excelreferencewisepaymentsummary,
                csvreferencewisepaymentsummary: !role.csvreferencewisepaymentsummary,
                printreferencewisepaymentsummary: !role.printreferencewisepaymentsummary,
                pdfreferencewisepaymentsummary: !role.pdfreferencewisepaymentsummary,

            }
        })
    }

    const remarkWiseCustomerRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallremarkwisecustomerreport: !role.checkallremarkwisecustomerreport,
                excelremarkwisecustomerreport: !role.excelremarkwisecustomerreport,
                csvremarkwisecustomerreport: !role.csvremarkwisecustomerreport,
                printremarkwisecustomerreport: !role.printremarkwisecustomerreport,
                pdfremarkwisecustomerreport: !role.pdfremarkwisecustomerreport,

            }
        })
    }

    const customerGroupAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomergrp: !role.checkallcustomergrp,
                acustomergrp: !role.acustomergrp,
                ecustomergrp: !role.ecustomergrp,
                dcustomergrp: !role.dcustomergrp,
                excelcustomergrp: !role.excelcustomergrp,
                csvcustomergrp: !role.csvcustomergrp,
                printcustomergrp: !role.printcustomergrp,
                pdfcustomergrp: !role.pdfcustomergrp
            }
        })
    }
    // Customer Management ...End

    // Product Management...Start

    const productAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallproduct: !role.checkallproduct,
                vproduct: !role.vproduct,
                iproduct: !role.iproduct,
                aproduct: !role.aproduct,
                eproduct: !role.eproduct,
                dproduct: !role.dproduct,
                excelproduct: !role.excelproduct,
                csvproduct: !role.csvproduct,
                printproduct: !role.printproduct,
                pdfproduct: !role.pdfproduct
            }
        })
    }

    const categoryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcategory: !role.checkallcategory,
                acategory: !role.acategory,
                ecategory: !role.ecategory,
                dcategory: !role.dcategory,
                printcategory: !role.printcategory,
                pdfcategory: !role.pdfcategory,
                excelcategory: !role.excelcategory,
                csvcategory: !role.csvcategory,
            }
        })
    }

    const discountAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldiscount: !role.checkalldiscount,
                adiscount: !role.adiscount,
                ediscount: !role.ediscount,
                ddiscount: !role.ddiscount,
                exceldiscount: !role.exceldiscount,
                csvdiscount: !role.csvdiscount,
                printdiscount: !role.printdiscount,
                pdfdiscount: !role.pdfdiscount,
            }
        })
    }

    const unitAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallunit: !role.checkallunit,
                aunit: !role.aunit,
                eunit: !role.eunit,
                dunit: !role.dunit,
                excelunit: !role.excelunit,
                csvunit: !role.csvunit,
                printunit: !role.printunit,
                pdfunit: !role.pdfunit
            }
        })
    }

    const cateListAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcatelist: !role.checkallcatelist,
                excelcatelist: !role.excelcatelist,
                csvcatelist: !role.csvcatelist,
                printcatelist: !role.printcatelist,
                pdfcatelist: !role.pdfcatelist,
            }
        })
    }

    const cateSummaryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcatesummary: !role.checkallcatesummary,
                excelcatesummary: !role.excelcatesummary,
                csvcatesummary: !role.csvcatesummary,
                printcatesummary: !role.printcatesummary,
                pdfcatesummary: !role.pdfcatesummary,
            }
        })
    }

    const manufactureWiseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufacturelist: !role.checkallmanufacturelist,
                excelmanufacturelist: !role.excelmanufacturelist,
                csvmanufacturelist: !role.csvmanufacturelist,
                printmanufacturelist: !role.printmanufacturelist,
                pdfmanufacturelist: !role.pdfmanufacturelist,
            }
        })
    }

    const manufactureWiseSummAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufacturesummary: !role.checkallmanufacturesummary,
                excelmanufacturesummary: !role.excelmanufacturesummary,
                csvmanufacturesummary: !role.csvmanufacturesummary,
                pdfmanufactursummary: !role.pdfmanufactursummary,
                printmanufacturesummary: !role.printmanufacturesummary,
            }
        })
    }

    const moleculeWiseListAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmolecule: !role.checkallmolecule,
                excelmolecule: !role.excelmolecule,
                csvmoleslist: !role.csvmoleslist,
                pdfmolecule: !role.pdfmolecule,
                printsmolelist: !role.printsmolelist,
            }
        })
    }

    const moleculeWiseSummAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmolesummary: !role.checkallmolesummary,
                excelmolesummary: !role.excelmolesummary,
                csvmolessummary: !role.csvmolessummary,
                printsmolesummary: !role.printsmolesummary,
                pdfmolesummary: !role.pdfmolesummary,
            }
        })
    }

    const scheduleWiseListAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallschedulelist: !role.checkallschedulelist,
                excelschedulelist: !role.excelschedulelist,
                csvschedulelist: !role.csvschedulelist,
                printschedulelist: !role.printschedulelist,
                pdfschedulelist: !role.pdfschedulelist,
            }
        })
    }

    const scheduleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallschedule: !role.checkallschedule,
                excelschedule: !role.excelschedule,
                csvschedule: !role.csvschedule,
                printschedule: !role.printschedule,
                pdfschedule: !role.pdfschedule,
                eschedule: !role.eschedule,
                dschedule: !role.dschedule,
            }
        })
    }

    // Product Management..End

    //  purchase module start
    const purchaseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpurchase: !role.checkallpurchase,
                apurchase: !role.apurchase,
                vpurchase: !role.vpurchase,
                epurchase: !role.epurchase,
                dpurchase: !role.dpurchase,
                excelpurchase: !role.excelpurchase,
                csvpurchase: !role.csvpurchase,
                printpurchase: !role.printpurchase,
                pdfpurchase: !role.pdfpurchase,
            }
        })
    }
    //  purchase return
    const purchaseReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpurchasereturnlist: !role.checkallpurchasereturnlist,
                apurchasereturnlist: !role.apurchasereturnlist,
                vpurchasereturnlist: !role.vpurchasereturnlist,
                epurchasereturnlist: !role.epurchasereturnlist,
                dpurchasereturnlist: !role.dpurchasereturnlist,
                csvpurchasereturnlist: !role.csvpurchasereturnlist,
                excelpurchasereturnlist: !role.excelpurchasereturnlist,
                printpurchasereturnlist: !role.printpurchasereturnlist,
                pdfpurchasereturnlist: !role.pdfpurchasereturnlist,
            }
        })
    }
    //  supplier product
    const supplierProductAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierproduct: !role.checkallsupplierproduct,
                supplierproductadd: !role.supplierproductadd,
                supplierproductview: !role.supplierproductview,
                supplierproductedit: !role.supplierproductedit,
                supplierproductdelete: !role.supplierproductdelete,
                supplierproductcsv: !role.supplierproductcsv,
                supplierproductexcel: !role.supplierproductexcel,
                supplierproductprint: !role.supplierproductprint,
                supplierproductpdf: !role.supplierproductpdf,
            }
        })
    }
    //  category wise purchase
    const categoryWisePurchaseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcategorywisepurchase: !role.checkallcategorywisepurchase,
                csvcategorywisepurchase: !role.csvcategorywisepurchase,
                excelcategorywisepurchase: !role.excelcategorywisepurchase,
                printcategorywisepurchase: !role.printcategorywisepurchase,
                pdfcategorywisepurchase: !role.pdfcategorywisepurchase,
            }
        })
    }
    //  item wise purchase
    const itemWisePurchaseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwisepurchasereport: !role.checkallitemwisepurchasereport,
                csvitemwisepurchasereport: !role.csvitemwisepurchasereport,
                excelitemwisepurchasereport: !role.excelitemwisepurchasereport,
                printitemwisepurchasereport: !role.printitemwisepurchasereport,
                pdfitemwisepurchasereport: !role.pdfitemwisepurchasereport,
            }
        })
    }
    //  item wise purchase return
    const itemWisePurchaseReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwisepurchasereturn: !role.checkallitemwisepurchasereturn,
                csvitemwisepurchasereturn: !role.csvitemwisepurchasereturn,
                excelitemwisepurchasereturn: !role.excelitemwisepurchasereturn,
                printitemwisepurchasereturn: !role.printitemwisepurchasereturn,
                pdfitemwisepurchasereturn: !role.pdfitemwisepurchasereturn,
            }
        })
    }
    //  manufacture wise purchase
    const manufactureWisePurchaseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufacturewisepurchase: !role.checkallmanufacturewisepurchase,
                csvmanufacturewisepurchase: !role.csvmanufacturewisepurchase,
                excelmanufacturwisepurchase: !role.excelmanufacturwisepurchase,
                printmanufacturewisepurchase: !role.printmanufacturewisepurchase,
                pdfmanufacturewisepurchase: !role.pdfmanufacturewisepurchase,
            }
        })
    }
    //  purchase detailed report
    const purchaseDetailedReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpurchasedetailedreport: !role.checkallpurchasedetailedreport,
                csvpurchasedetailedreport: !role.csvpurchasedetailedreport,
                excelpurchasedetailedreport: !role.excelpurchasedetailedreport,
                printpurchasedetailedreport: !role.printpurchasedetailedreport,
                pdfpurchasedetailedreport: !role.pdfpurchasedetailedreport,
            }
        })
    }
    //  purchase report
    const purchaseReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpurchasereport: !role.checkallpurchasereport,
                csvpurchasereport: !role.csvpurchasereport,
                excelpurchasereport: !role.excelpurchasereport,
                printpurchasereport: !role.printpurchasereport,
                pdfpurchasereport: !role.pdfpurchasereport,
            }
        })
    }
    //  purchase return report
    const purchaseReturnReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpurchasereturnreport: !role.checkallpurchasereturnreport,
                csvpurchasereturnreport: !role.csvpurchasereturnreport,
                excelpurchasereturnreport: !role.excelpurchasereturnreport,
                printpurchasereturnreport: !role.printpurchasereturnreport,
                pdfpurchasereturnreport: !role.pdfpurchasereturnreport,
            }
        })
    }
    //  supplier wise purchase
    const supplierWisePurchaseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierwisepurchase: !role.checkallsupplierwisepurchase,
                csvsupplierwisepurchase: !role.csvsupplierwisepurchase,
                excelsupplierwisepurchase: !role.excelsupplierwisepurchase,
                printsupplierwisepurchase: !role.printsupplierwisepurchase,
                pdfsupplierwisepurchase: !role.pdfsupplierwisepurchase,
            }
        })
    }
    //  supplier wise purchase report
    const supplierWisePurchaseReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierwisepurchasereport: !role.checkallsupplierwisepurchasereport,
                csvsupplierwisepurchasereport: !role.csvsupplierwisepurchasereport,
                excelsupplierwisepurchasereport: !role.excelsupplierwisepurchasereport,
                printsupplierwisepurchasereport: !role.printsupplierwisepurchasereport,
                pdfsupplierwisepurchasereport: !role.pdfsupplierwisepurchasereport,
            }
        })
    }
    //  purchase end

    //  estimate start
    //  draft
    const estimateDraftAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallestimatedraft: !role.checkallestimatedraft,
                vestimatedraft: !role.vestimatedraft,
                aestimatedraft: !role.aestimatedraft,
                eestimatedraft: !role.eestimatedraft,
                destimatedraft: !role.destimatedraft,
                csvestimatedraft: !role.csvestimatedraft,
                excelestimatedraft: !role.excelestimatedraft,
                printestimatedraft: !role.printestimatedraft,
                pdfestimatedraft: !role.pdfestimatedraft,
            }
        })
    }
    //  estimate list
    const estimateListtAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallestimatelist: !role.checkallestimatelist,
                aestimatelist: !role.aestimatelist,
                eestimatelist: !role.eestimatelist,
                destimatelist: !role.destimatelist,
                vestimatelist: !role.vestimatelist,
                csvestimatelist: !role.csvestimatelist,
                excelestimatelist: !role.excelestimatelist,
                printestimatelist: !role.printestimatelist,
                pdfestimatelist: !role.pdfestimatelist,
            }
        })
    }
    //  quotation
    const estimateQuotationtAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallestimatequotation: !role.checkallestimatequotation,
                vestimatequotation: !role.vestimatequotation,
                aestimatequotation: !role.aestimatequotation,
                eestimatequotation: !role.eestimatequotation,
                destimatequotation: !role.destimatequotation,
                csvestimatequotation: !role.csvestimatequotation,
                excelestimatequotation: !role.excelestimatequotation,
                printestimatequotation: !role.printestimatequotation,
                pdfestimatequotation: !role.pdfestimatequotation,
            }
        })
    }
    //  area wise estimate
    const areaWiseEstimateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallareawiseestimate: !role.checkallareawiseestimate,
                csvareawiseestimate: !role.csvareawiseestimate,
                excelareawiseestimate: !role.excelareawiseestimate,
                printareawiseestimate: !role.printareawiseestimate,
                pdfareawiseestimate: !role.pdfareawiseestimate,
            }
        })
    }
    //  category wise estimate
    const categoryWiseEstimateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcategorywiseestimates: !role.checkallcategorywiseestimates,
                csvcategorywiseestimates: !role.csvcategorywiseestimates,
                excelcategorywiseestimates: !role.excelcategorywiseestimates,
                printcategorywiseestimates: !role.printcategorywiseestimates,
                pdfcategorywiseestimates: !role.pdfcategorywiseestimates,
            }
        })
    }
    //  customer wise estimate report
    const customerWiseEstimateReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerwiseestimatereport: !role.checkallcustomerwiseestimatereport,
                csvcustomerwiseestimatereport: !role.csvcustomerwiseestimatereport,
                excelcustomerwiseestimatereport: !role.excelcustomerwiseestimatereport,
                printcustomerwiseestimatereport: !role.printcustomerwiseestimatereport,
                pdfcustomerwiseestimatereport: !role.pdfcustomerwiseestimatereport,
            }
        })
    }
    //  day wise estimate report
    const dayWiseEstimateReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldaywiseestimatereport: !role.checkalldaywiseestimatereport,
                csvdaywiseestimatereport: !role.csvdaywiseestimatereport,
                exceldaywiseestimatereport: !role.exceldaywiseestimatereport,
                printdaywiseestimatereport: !role.printdaywiseestimatereport,
                pdfdaywiseestimatereport: !role.pdfdaywiseestimatereport,
            }
        })
    }
    //  estimate report
    const estimateReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallestimatereport: !role.checkallestimatereport,
                csvestimatereport: !role.csvestimatereport,
                excelestimatereport: !role.excelestimatereport,
                printestimatereport: !role.printestimatereport,
                pdfestimatereport: !role.pdfestimatereport,
            }
        })
    }
    //  estimate return register
    const estimateReturnRegisterAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallestimatereturnregister: !role.checkallestimatereturnregister,
                csvestimatereturnregister: !role.csvestimatereturnregister,
                excelestimatereturnregister: !role.excelestimatereturnregister,
                printestimatereturnregister: !role.printestimatereturnregister,
                pdfestimatereturnregister: !role.pdfestimatereturnregister,
            }
        })
    }
    //  estimate return report
    const estimateReturnReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallestimatereturnreport: !role.checkallestimatereturnreport,
                csvestimatereturnreport: !role.csvestimatereturnreport,
                excelestimatereturnreport: !role.excelestimatereturnreport,
                printestimatereturnreport: !role.printestimatereturnreport,
                pdfestimatereturnreport: !role.pdfestimatereturnreport,
            }
        })
    }
    //  item wise estimate
    const itemWiseEstimateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwiseestimate: !role.checkallitemwiseestimate,
                csvitemwiseestimate: !role.csvitemwiseestimate,
                excelitemwiseestimate: !role.excelitemwiseestimate,
                printitemwiseestimate: !role.printitemwiseestimate,
                pdfitemwiseestimate: !role.pdfitemwiseestimate,
            }
        })
    }
    //  item wise estimate return
    const itemWiseEstimateReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwiseestimatereturn: !role.checkallitemwiseestimatereturn,
                csvitemwiseestimatereturn: !role.csvitemwiseestimatereturn,
                excelitemwiseestimatereturn: !role.excelitemwiseestimatereturn,
                printitemwiseestimatereturn: !role.printitemwiseestimatereturn,
                pdfitemwiseestimatereturn: !role.pdfitemwiseestimatereturn,
            }
        })
    }
    //  manufacture wise estimate 
    const manufactureWiseEstimateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufacturewiseestimate: !role.checkallmanufacturewiseestimate,
                csvmanufacturewiseestimate: !role.csvmanufacturewiseestimate,
                excelmanufacturewiseestimate: !role.excelmanufacturewiseestimate,
                printmanufacturewiseestimate: !role.printmanufacturewiseestimate,
                pdfmanufacturewiseestimate: !role.pdfmanufacturewiseestimate,
            }
        })
    }
    //  manufacture wise estimate return
    const manufactureWiseEstimateReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufacturewiseestimatereturn: !role.checkallmanufacturewiseestimatereturn,
                csvmanufacturewiseestimatereturn: !role.csvmanufacturewiseestimatereturn,
                excelmanufacturewiseestimatereturn: !role.excelmanufacturewiseestimatereturn,
                printmanufacturewiseestimatereturn: !role.printmanufacturewiseestimatereturn,
                pdfmanufacturewiseestimatereturn: !role.pdfmanufacturewiseestimatereturn,
            }
        })
    }
    //  reference wise estimate
    const referenceWiseEstimateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallreferencewiseestimate: !role.checkallreferencewiseestimate,
                csvreferencewiseestimate: !role.csvreferencewiseestimate,
                excelreferencewiseestimate: !role.excelreferencewiseestimate,
                printreferencewiseestimate: !role.printreferencewiseestimate,
                pdfreferencewiseestimate: !role.pdfreferencewiseestimate,
            }
        })
    }

    //  sale return summary
    const saleReturnSummaryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallestimatesalereturnsummary: !role.checkallestimatesalereturnsummary,
                csvestimatesalereturnsummary: !role.csvestimatesalereturnsummary,
                excelestimatesalereturnsummary: !role.excelestimatesalereturnsummary,
                printestimatesalereturnsummary: !role.printestimatesalereturnsummary,
                pdfestimatesalereturnsummary: !role.pdfestimatesalereturnsummary,
            }
        })
    }
    //  supplier wise estimate
    const supplierWiseEstimateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierwiseestimate: !role.checkallsupplierwiseestimate,
                csvsupplierwiseestimate: !role.csvsupplierwiseestimate,
                excelsupplierwiseestimate: !role.excelsupplierwiseestimate,
                printsupplierwiseestimate: !role.printsupplierwiseestimate,
                pdfsupplierwiseestimate: !role.pdfsupplierwiseestimate,
            }
        })
    }
    //  supplier wise estimate return
    const supplierWiseEstimateReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierwiseestimatereturn: !role.checkallsupplierwiseestimatereturn,
                csvsupplierwiseestimatereturn: !role.csvsupplierwiseestimatereturn,
                excelsupplierwiseestimatereturn: !role.excelsupplierwiseestimatereturn,
                printsupplierwiseestimatereturn: !role.printsupplierwiseestimatereturn,
                pdfsupplierwiseestimatereturn: !role.pdfsupplierwiseestimatereturn,
            }
        })
    }
    //  Estimate end

    // Stock Management Start

    const batchwiseStockdetAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbatchwisestockdetails: !role.checkallbatchwisestockdetails,
                csvbatchwisestockdetails: !role.csvbatchwisestockdetails,
                excelbatchwisestockdetails: !role.excelbatchwisestockdetails,
                printbatchwisestockdetails: !role.printbatchwisestockdetails,
                pdfbatchwisestockdetails: !role.pdfbatchwisestockdetails,
            }
        })
    }

    const batchwiseStockrepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbatchwisestockreport: !role.checkallbatchwisestockreport,
                csvbatchwisestockreport: !role.csvbatchwisestockreport,
                excelbatchwisestockreport: !role.excelbatchwisestockreport,
                printbatchwisestockreport: !role.printbatchwisestockreport,
                pdfbatchwisestockreport: !role.pdfbatchwisestockreport,
            }
        })
    }

    const stockExpiryrepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallstockexpriyreport: !role.checkallstockexpriyreport,
                csvstockexpriyreport: !role.csvstockexpriyreport,
                excelstockexpriyreport: !role.excelstockexpriyreport,
                printstockexpriyreport: !role.printstockexpriyreport,
                pdfstockexpriyreport: !role.pdfstockexpriyreport,
            }
        })
    }

    const itemCodeUpdateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemcodeupdate: !role.checkallitemcodeupdate,
                csvitemcodeupdate: !role.csvitemcodeupdate,
                excelitemcodeupdate: !role.excelitemcodeupdate,
                printitemcodeupdate: !role.printitemcodeupdate,
                pdfitemcodeupdate: !role.pdfitemcodeupdate,
            }
        })
    }

    const itemWiseStockRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwisestockreport: !role.checkallitemwisestockreport,
                csvitemwisestockreport: !role.csvitemwisestockreport,
                excelitemwisestockreport: !role.excelitemwisestockreport,
                printitemwisestockreport: !role.printitemwisestockreport,
                pdfitemwisestockreport: !role.pdfitemwisestockreport,
            }
        })
    }

    const manualStockEntryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanualstockentry: !role.checkallmanualstockentry,
                csvmanualstockentry: !role.csvmanualstockentry,
                excelmanualstockentry: !role.excelmanualstockentry,
                printmanualstockentry: !role.printmanualstockentry,
                pdfmanualstockentry: !role.pdfmanualstockentry,
            }
        })
    }

    const manufactureUpdateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufactureupdate: !role.checkallmanufactureupdate,
                umanufactureupdate: !role.umanufactureupdate,
                csvmanufactureupdate: !role.csvmanufactureupdate,
                excelmanufactureupdate: !role.excelmanufactureupdate,
                printmanufactureupdate: !role.printmanufactureupdate,
                pdfmanufactureupdate: !role.pdfmanufactureupdate,
            }
        })
    }

    const minimumWiseStockRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallminimumwisestockreport: !role.checkallminimumwisestockreport,
                uminimumwisestockreport: !role.uminimumwisestockreport,
                csvminimumwisestockreport: !role.csvminimumwisestockreport,
                excelminimumwisestockreport: !role.excelminimumwisestockreport,
                printminimumwisestockreport: !role.printminimumwisestockreport,
                pdfminimumwisestockreport: !role.pdfminimumwisestockreport,
            }
        })
    }

    const nileStockRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallnilestockreport: !role.checkallnilestockreport,
                csvnilestockreport: !role.csvnilestockreport,
                excelnilestockreport: !role.excelnilestockreport,
                printnilestockreport: !role.printnilestockreport,
                pdfnilestockreport: !role.pdfnilestockreport,
            }
        })
    }

    const nonMoveStockRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallnonmovingstockreport: !role.checkallnonmovingstockreport,
                csvnonmovingstockreport: !role.csvnonmovingstockreport,
                excelnonmovingstockreport: !role.excelnonmovingstockreport,
                printnonmovingstockreport: !role.printnonmovingstockreport,
                pdfnonmovingstockreport: !role.pdfnonmovingstockreport,
            }
        })
    }

    const packQualityUpdateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpackqualityupdate: !role.checkallpackqualityupdate,
                upackqualityupdate: !role.upackqualityupdate,
                excelpackqualityupdate: !role.excelpackqualityupdate,
                csvpackqualityupdate: !role.csvpackqualityupdate,
                printpackqualityupdate: !role.printpackqualityupdate,
                pdfpackqualityupdate: !role.pdfpackqualityupdate,
            }
        })
    }

    const stockExpiryRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallstockexpiryreport: !role.checkallstockexpiryreport,
                excelstockexpiryreport: !role.excelstockexpiryreport,
                csvstockexpiryreport: !role.csvstockexpiryreport,
                printstockexpiryreport: !role.printstockexpiryreport,
                pdfstockexpiryreport: !role.pdfstockexpiryreport,
            }
        })
    }

    const stockOverViewRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallstockoverviewreport: !role.checkallstockoverviewreport,
                excelstockoverviewreport: !role.excelstockoverviewreport,
                csvstockoverviewreport: !role.csvstockoverviewreport,
                printstockoverviewreport: !role.printstockoverviewreport,
                pdfstockoverviewreport: !role.pdfstockoverviewreport,
            }
        })
    }

    const stockUpdateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallstockupdate: !role.checkallstockupdate,
                excelstockupdate: !role.excelstockupdate,
                csvstockupdate: !role.csvstockupdate,
                printstockupdate: !role.printstockupdate,
                pdfstockupdate: !role.pdfstockupdate,
            }
        })
    }

    // Stock Management End

    //  Sell Start
    //  draft
    const posDraftAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallposdraft: !role.checkallposdraft,
                vposdraft: !role.vposdraft,
                aposdraft: !role.aposdraft,
                eposdraft: !role.eposdraft,
                dposdraft: !role.dposdraft,
                csvposdraft: !role.csvposdraft,
                excelposdraft: !role.excelposdraft,
                printposdraft: !role.printposdraft,
                pdfposdraft: !role.pdfposdraft,
            }
        })
    }
    //  pos 
    const posListAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallposlist: !role.checkallposlist,
                aposlist: !role.aposlist,
                eposlist: !role.eposlist,
                vposlist: !role.vposlist,
                dposlist: !role.dposlist,
                csvposlist: !role.csvposlist,
                excelposlist: !role.excelposlist,
                printposlist: !role.printposlist,
                pdfposlist: !role.pdfposlist,
            }
        })
    }
    //  quotation 
    const posQuotationAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallposquotation: !role.checkallposquotation,
                aposquotation: !role.aposquotation,
                vposquotation: !role.vposquotation,
                eposquotation: !role.eposquotation,
                dposquotation: !role.dposquotation,
                csvposquotation: !role.csvposquotation,
                excelposquotation: !role.excelposquotation,
                printposquotation: !role.printposquotation,
                pdfposquotation: !role.pdfposquotation,
            }
        })
    }
    //  area wise sales
    const areaWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallareawisesales: !role.checkallareawisesales,
                csvareawisesales: !role.csvareawisesales,
                excelareawisesales: !role.excelareawisesales,
                printareawisesales: !role.printareawisesales,
                pdfareawisesales: !role.pdfareawisesales,
            }
        })
    }
    //  category wise sales
    const categoryWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcategorywisesales: !role.checkallcategorywisesales,
                csvcategorywisesales: !role.csvcategorywisesales,
                excelcategorywisesales: !role.excelcategorywisesales,
                printcategorywisesales: !role.printcategorywisesales,
                pdfcategorywisesales: !role.pdfcategorywisesales,
            }
        })
    }
    //  day wise sales
    const dayWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldaywisesales: !role.checkalldaywisesales,
                csvdaywisesales: !role.csvdaywisesales,
                exceldaywisesales: !role.exceldaywisesales,
                printdaywisesales: !role.printdaywisesales,
                pdfdaywisesales: !role.pdfdaywisesales,
            }
        })
    }
    //  diagnosis wise sales
    const diagnosisWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldiagnosiswisesales: !role.checkalldiagnosiswisesales,
                csvdiagnosiswisesales: !role.csvdiagnosiswisesales,
                exceldiagnosiswisesales: !role.exceldiagnosiswisesales,
                printdiagnosiswisesales: !role.printdiagnosiswisesales,
                pdfdiagnosiswisesales: !role.pdfdiagnosiswisesales,
            }
        })
    }
    //  diagnosis wise sales summary
    const diagnosisWiseSalesSummaryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldiagnosiswisesalessummary: !role.checkalldiagnosiswisesalessummary,
                csvdiagnosiswisesalessummary: !role.csvdiagnosiswisesalessummary,
                exceldiagnosiswisesalessummary: !role.exceldiagnosiswisesalessummary,
                printdiagnosiswisesalessummary: !role.printdiagnosiswisesalessummary,
                pdfdiagnosiswisesalessummary: !role.pdfdiagnosiswisesalessummary,
            }
        })
    }
    //  drug register
    const drugregisterAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallselldrugregister: !role.checkallselldrugregister,
                csvselldrugregister: !role.csvselldrugregister,
                excelselldrugregister: !role.excelselldrugregister,
                printselldrugregister: !role.printselldrugregister,
                pdfselldrugregister: !role.pdfselldrugregister,
            }
        })
    }
    //  dr wise sale report
    const drWiseSaleReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldrwisesalereport: !role.checkalldrwisesalereport,
                csvdrwisesalereport: !role.csvdrwisesalereport,
                exceldrwisesalereport: !role.exceldrwisesalereport,
                printdrwisesalereport: !role.printdrwisesalereport,
                pdfdrwisesalereport: !role.pdfdrwisesalereport,
            }
        })
    }
    //  dr wise sale
    const drWiseSaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldrwisesale: !role.checkalldrwisesale,
                csvdrwisesale: !role.csvdrwisesale,
                exceldrwisesale: !role.exceldrwisesale,
                printdrwisesale: !role.printdrwisesale,
                pdfdrwisesale: !role.pdfdrwisesale,
            }
        })
    }
    //  item wise sales return
    const itemWiseSalesReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwisesalesreturn: !role.checkallitemwisesalesreturn,
                csvitemwisesalesreturn: !role.csvitemwisesalesreturn,
                excelitemwisesalesreturn: !role.excelitemwisesalesreturn,
                printitemwisesalesreturn: !role.printitemwisesalesreturn,
                pdfitemwisesalesreturn: !role.pdfitemwisesalesreturn,
            }
        })
    }
    //  item wise sales
    const itemWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwisesales: !role.checkallitemwisesales,
                csvitemwisesales: !role.csvitemwisesales,
                excelitemwisesales: !role.excelitemwisesales,
                printitemwisesales: !role.printitemwisesales,
                pdfitemwisesales: !role.pdfitemwisesales,
            }
        })
    }
    //  manufacture wise sales return
    const manufactureWiseSalesReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufacturewisesalesreturn: !role.checkallmanufacturewisesalesreturn,
                csvmanufacturewisesalesreturn: !role.csvmanufacturewisesalesreturn,
                excelmanufacturewisesalesreturn: !role.excelmanufacturewisesalesreturn,
                printmanufacturewisesalesreturn: !role.printmanufacturewisesalesreturn,
                pdfmanufacturewisesalesreturn: !role.pdfmanufacturewisesalesreturn,
            }
        })
    }
    //  manufacture wise sales
    const manufactureWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmanufacturewisesales: !role.checkallmanufacturewisesales,
                csvmanufacturewisesales: !role.csvmanufacturewisesales,
                excelmanufacturewisesales: !role.excelmanufacturewisesales,
                printmanufacturewisesales: !role.printmanufacturewisesales,
                pdfmanufacturewisesales: !role.pdfmanufacturewisesales,
            }
        })
    }
    //  molecule wise sales
    const moleculeWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallmoleculewisesales: !role.checkallmoleculewisesales,
                csvmoleculewisesales: !role.csvmoleculewisesales,
                excelmoleculewisesales: !role.excelmoleculewisesales,
                printmoleculewisesales: !role.printmoleculewisesales,
                pdfmoleculewisesales: !role.pdfmoleculewisesales,
            }
        })
    }
    //  patient wise sales
    const patientWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpatientwisesales: !role.checkallpatientwisesales,
                csvpatientwisesales: !role.csvpatientwisesales,
                excelpatientwisesales: !role.excelpatientwisesales,
                printpatientwisesales: !role.printpatientwisesales,
                pdfpatientwisesales: !role.pdfpatientwisesales,
            }
        })
    }
    //  patient wise sales report
    const patientWiseSalesReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpatientwisesalesreport: !role.checkallpatientwisesalesreport,
                csvpatientwisesalesreport: !role.csvpatientwisesalesreport,
                excelpatientwisesalesreport: !role.excelpatientwisesalesreport,
                printpatientwisesalesreport: !role.printpatientwisesalesreport,
                pdfpatientwisesalesreport: !role.pdfpatientwisesalesreport,
            }
        })
    }
    //  sales return details
    const salesReturnDetailsAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsalesreturndetails: !role.checkallsalesreturndetails,
                csvsalesreturndetails: !role.csvsalesreturndetails,
                excelsalesreturndetails: !role.excelsalesreturndetails,
                printsalesreturndetails: !role.printsalesreturndetails,
                pdfsalesreturndetails: !role.pdfsalesreturndetails,
            }
        })
    }
    //  sales return report
    const salesReturnReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsalesreturnreport: !role.checkallsalesreturnreport,
                csvsalesreturnreport: !role.csvsalesreturnreport,
                excelsalesreturnreport: !role.excelsalesreturnreport,
                printsalesreturnreport: !role.printsalesreturnreport,
                pdfsalesreturnreport: !role.pdfsalesreturnreport,
            }
        })
    }
    //  sales return summary
    const salesReturnSummaryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsalesreturnsummary: !role.checkallsalesreturnsummary,
                csvsalesreturnsummary: !role.csvsalesreturnsummary,
                excelsalesreturnsummary: !role.excelsalesreturnsummary,
                printsalesreturnsummary: !role.printsalesreturnsummary,
                pdfsalesreturnsummary: !role.pdfsalesreturnsummary,
            }
        })
    }
    //  sales report
    const salesReportAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsalesreport: !role.checkallsalesreport,
                csvsalesreport: !role.csvsalesreport,
                excelsalesreport: !role.excelsalesreport,
                printsalesreport: !role.printsalesreport,
                pdfsalesreport: !role.pdfsalesreport,
            }
        })
    }
    //  sales summary
    const salesSummaryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsalessummary: !role.checkallsalessummary,
                csvsalessummary: !role.csvsalessummary,
                excelsalessummary: !role.excelsalessummary,
                printsalessummary: !role.printsalessummary,
                pdfsalessummary: !role.pdfsalessummary,
            }
        })
    }
    //  schedule wise sales
    const scheduleWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallschedulewisesales: !role.checkallschedulewisesales,
                csvschedulewisesales: !role.csvschedulewisesales,
                excelschedulewisesales: !role.excelschedulewisesales,
                printschedulewisesales: !role.printschedulewisesales,
                pdfschedulewisesales: !role.pdfschedulewisesales,
            }
        })
    }
    //  sell return
    const sellReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsellreturn: !role.checkallsellreturn,
                csvsellreturn: !role.csvsellreturn,
                excelsellreturn: !role.excelsellreturn,
                printsellreturn: !role.printsellreturn,
                pdfsellreturn: !role.pdfsellreturn,
                esellreturn: !role.esellreturn,
                vsellreturn: !role.vsellreturn,
                dsellreturn: !role.dsellreturn,
            }
        })
    }
    //  supplier wise sales return
    const supplierWiseSalesReturnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierwisesalesreturn: !role.checkallsupplierwisesalesreturn,
                csvsupplierwisesalesreturn: !role.csvsupplierwisesalesreturn,
                excelsupplierwisesalesreturn: !role.excelsupplierwisesalesreturn,
                printsupplierwisesalesreturn: !role.printsupplierwisesalesreturn,
                pdfsupplierwisesalesreturn: !role.pdfsupplierwisesalesreturn,
            }
        })
    }
    //  supplier wise sales
    const supplierWiseSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallsupplierwisesales: !role.checkallsupplierwisesales,
                excelsupplierwisesales: !role.excelsupplierwisesales,
                csvsupplierwisesales: !role.csvsupplierwisesales,
                printsupplierwisesales: !role.printsupplierwisesales,
                pdfsupplierwisesales: !role.pdfsupplierwisesales,
            }
        })
    }

    // Sell End Area

    // Account Management......Start

    const accountCreationAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallaccountcreation: !role.checkallaccountcreation,
                excelaccountcreation: !role.excelaccountcreation,
                csvaccountcreation: !role.csvaccountcreation,
                printaccountcreation: !role.printaccountcreation,
                pdfaccountcreation: !role.pdfaccountcreation,
                aaccountcreation: !role.aaccountcreation,
                eaccountcreation: !role.eaccountcreation,
                daccountcreation: !role.daccountcreation,
            }
        })
    }

    const groupCreationAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgroupcreation: !role.checkallgroupcreation,
                excelgroupcreation: !role.excelgroupcreation,
                csvgroupcreation: !role.csvgroupcreation,
                printgroupcreation: !role.printgroupcreation,
                pdfgroupcreation: !role.pdfgroupcreation,
                agroupcreation: !role.agroupcreation,
                egroupcreation: !role.egroupcreation,
                dgroupcreation: !role.dgroupcreation,
            }
        })
    }

    const voucherAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallvoucherlist: !role.checkallvoucherlist,
                excelvoucherlist: !role.excelvoucherlist,
                csvvoucherlist: !role.csvvoucherlist,
                printvoucherlist: !role.printvoucherlist,
                pdfvoucherlist: !role.pdfvoucherlist,
                addvoucherlist: !role.addvoucherlist,
                evoucherlist: !role.evoucherlist,
                dvoucherlist: !role.dvoucherlist,
                vvoucherlist: !role.vvoucherlist,
            }
        })
    }

    const bankStatementAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbankstatement: !role.checkallbankstatement,
                csvbankstatement: !role.csvbankstatement,
                excelbankstatement: !role.excelbankstatement,
                printbankstatement: !role.printbankstatement,
                pdfbankstatement: !role.pdfbankstatement,
            }
        })
    }

    const cashbookRegRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcashbookregreport: !role.checkallcashbookregreport,
                csvcashbookregreport: !role.csvcashbookregreport,
                excelcashbookregreport: !role.excelcashbookregreport,
                printcashbookregreport: !role.printcashbookregreport,
                pdfcashbookregreport: !role.pdfcashbookregreport,
            }
        })
    }

    const cashbookRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcashbookreport: !role.checkallcashbookreport,
                csvcashbookreport: !role.csvcashbookreport,
                excelcashbookreport: !role.excelcashbookreport,
                printcashbookreport: !role.printcashbookreport,
                pdfcashbookreport: !role.pdfcashbookreport,
            }
        })
    }

    const groupSummaryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgroupsummary: !role.checkallgroupsummary,
                csvgroupsummary: !role.csvgroupsummary,
                excelgroupsummary: !role.excelgroupsummary,
                printgroupsummary: !role.printgroupsummary,
                pdfgroupsummary: !role.pdfgroupsummary,
            }
        })
    }

    const profitLossAccAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallprofitlossaccount: !role.checkallprofitlossaccount,
                printprofitlossaccount: !role.printprofitlossaccount,
                pdfprofitlossaccount: !role.pdfprofitlossaccount,
            }
        })
    }

    const trialBalanceAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalltrailbalance: !role.checkalltrailbalance,
                csvtrailbalance: !role.csvtrailbalance,
                exceltrailbalance: !role.exceltrailbalance,
                printtrailbalance: !role.printtrailbalance,
                pdftrailbalance: !role.pdftrailbalance,
            }
        })
    }

    // Account Management ....End

    // Bank Management...Start

    const BankMasterAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbankmaster: !role.checkallbankmaster,
                csvbankmaster: !role.csvbankmaster,
                excelbankmaster: !role.excelbankmaster,
                printbankmaster: !role.printbankmaster,
                pdfbankmaster: !role.pdfbankmaster,
                viewbankmaster: !role.viewbankmaster,
                editbankmaster: !role.editbankmaster,
                deletebankmaster: !role.deletebankmaster,
            }
        })
    }

    const BankMasterRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbankmasterreport: !role.checkallbankmasterreport,
                csvbankmasterreport: !role.csvbankmasterreport,
                excelbankmasterreport: !role.excelbankmasterreport,
                printbankmasterreport: !role.printbankmasterreport,
                pdfbankmasterreport: !role.pdfbankmasterreport,
            }
        })
    }

    const BankBookRepAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbankbookreport: !role.checkallbankbookreport,
                csvbankbookreport: !role.csvbankbookreport,
                excelbankbookreport: !role.excelbankbookreport,
                printbankbookreport: !role.printbankbookreport,
                pdfbankbookreport: !role.pdfbankbookreport,
            }
        })
    }
    // Bank Management...End

    // Settings Management ....Start

    const alpharateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallalpharate: !role.checkallalpharate,
                valpharate: !role.valpharate,
                aalpharate: !role.aalpharate
            }
        })
    }

    const businessLocationAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbusinesslocation: !role.checkallbusinesslocation,
                activatebusinesslocation: !role.activatebusinesslocation,
                abusinesslocation: !role.abusinesslocation,
                ebusinesslocation: !role.ebusinesslocation,
                dbusinesslocation: !role.dbusinesslocation,
                excelbusinesslocation: !role.excelbusinesslocation,
                csvbusinesslocation: !role.csvbusinesslocation,
                printbusinesslocation: !role.printbusinesslocation,
                pdfbusinesslocation: !role.pdfbusinesslocation,
            }
        })
    }

    const paymentIntegrationAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallpaymentintegration: !role.checkallpaymentintegration,
                vpaymentintegration: !role.vpaymentintegration,
                apaymentintegration: !role.apaymentintegration,
                epaymentintegration: !role.epaymentintegration,
                dpaymentintegration: !role.dpaymentintegration,
                excelpaymentintegration: !role.excelpaymentintegration,
                csvpaymentintegration: !role.csvpaymentintegration,
                pdfpaymentintegration: !role.pdfpaymentintegration
            }
        })
    }

    const taxrateAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalltaxrate: !role.checkalltaxrate,
                ataxrate: !role.ataxrate,
                etaxrate: !role.etaxrate,
                dtaxrate: !role.dtaxrate,
                exceltaxrate: !role.exceltaxrate,
                csvtaxrate: !role.csvtaxrate,
                printtaxrate: !role.printtaxrate,
                pdftaxrate: !role.pdftaxrate,
            }
        })
    }

    const taxrateGroupAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalltaxrategroup: !role.checkalltaxrategroup,
                ataxrategroup: !role.ataxrategroup,
                dtaxrategroup: !role.dtaxrategroup,
                exceltaxrategroup: !role.exceltaxrategroup,
                csvtaxrategroup: !role.csvtaxrategroup,
                printtaxrategroup: !role.printtaxrategroup,
                pdftaxrategroup: !role.pdftaxrategroup
            }
        })
    }

    const hsnAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalltaxratehsn: !role.checkalltaxratehsn,
                ataxratehsn: !role.ataxratehsn,
                dtaxratehsn: !role.dtaxratehsn,
                exceltaxratehsn: !role.exceltaxratehsn,
                csvtaxratehsn: !role.csvtaxratehsn,
                printtaxratehsn: !role.printtaxratehsn,
                pdftaxratehsn: !role.pdftaxratehsn
            }
        })
    }

    const gstrPurchaseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgstrpurchase: !role.checkallgstrpurchase,
                excelgstrpurchase: !role.excelgstrpurchase,
                csvgstrpurchase: !role.csvgstrpurchase,
                printgstrpurchase: !role.printgstrpurchase,
                pdfgstrpurchase: !role.pdfgstrpurchase
            }
        })
    }

    const gstrPurchaseRetAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgstrpurchasereturn: !role.checkallgstrpurchasereturn,
                excelgstrpurchasereturn: !role.excelgstrpurchasereturn,
                csvgstrpurchasereturn: !role.csvgstrpurchasereturn,
                printgstrpurchasereturn: !role.printgstrpurchasereturn,
                pdfgstrpurchasereturn: !role.pdfgstrpurchasereturn
            }
        })
    }

    const gstrRetailSaleRetAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgstrretailsalesret: !role.checkallgstrretailsalesret,
                excelgstrretailsalesret: !role.excelgstrretailsalesret,
                csvgstrretailsalesret: !role.csvgstrretailsalesret,
                printgstrretailsalesret: !role.printgstrretailsalesret,
                pdfgstrretailsalesret: !role.pdfgstrretailsalesret
            }
        })
    }

    const gstrSalesRetailAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgstrsalesretail: !role.checkallgstrsalesretail,
                excelgstrsalesretail: !role.excelgstrsalesretail,
                csvgstrsalesretail: !role.csvgstrsalesretail,
                printgstrsalesretail: !role.printgstrsalesretail,
                pdfgstrsalesretail: !role.pdfgstrsalesretail
            }
        })
    }

    const gstrWholeSaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgstrwholesale: !role.checkallgstrwholesale,
                excelgstrwholesale: !role.excelgstrwholesale,
                csvgstrwholesale: !role.csvgstrwholesale,
                printgstrwholesale: !role.printgstrwholesale,
                pdfgstrwholesale: !role.pdfgstrwholesale
            }
        })
    }

    const gstrWholeSaleRetAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallgstrwholesaleret: !role.checkallgstrwholesaleret,
                excelgstrwholesaleret: !role.excelgstrwholesaleret,
                csvgstrwholesaleret: !role.csvgstrwholesaleret,
                printgstrwholesaleret: !role.printgstrwholesaleret,
                pdfgstrwholesaleret: !role.pdfgstrwholesaleret
            }
        })
    }

    const hsnWiseRepRetailAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallhsnwisereportretails: !role.checkallhsnwisereportretails,
                excelhsnwisereportretails: !role.excelhsnwisereportretails,
                csvhsnwisereportretails: !role.csvhsnwisereportretails,
                printhsnwisereportretails: !role.printhsnwisereportretails,
                pdfhsnwisereportretails: !role.pdfhsnwisereportretails
            }
        })
    }

    const hsnWiseRepRetailSalesRetAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallhsnwiserepretailsalesret: !role.checkallhsnwiserepretailsalesret,
                excelhsnwiserepretailsalesret: !role.excelhsnwiserepretailsalesret,
                csvhsnwiserepretailsalesret: !role.csvhsnwiserepretailsalesret,
                printhsnwiserepretailsalesret: !role.printhsnwiserepretailsalesret,
                pdfhsnwiserepretailsalesret: !role.pdfhsnwiserepretailsalesret
            }
        })
    }

    const hsnWiseRepWholeSalesAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallhsnwiserepwholesales: !role.checkallhsnwiserepwholesales,
                excelhsnwiserepwholesales: !role.excelhsnwiserepwholesales,
                csvhsnwiserepwholesales: !role.csvhsnwiserepwholesales,
                printhsnwiserepwholesales: !role.printhsnwiserepwholesales,
                pdfhsnwiserepwholesales: !role.pdfhsnwiserepwholesales
            }
        })
    }

    const hsnWiseRepWholeSalesRetAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallhsnwiserepwholesalereturn: !role.checkallhsnwiserepwholesalereturn,
                excelhsnwiserepwholesalereturn: !role.excelhsnwiserepwholesalereturn,
                csvhsnwiserepwholesalereturn: !role.csvhsnwiserepwholesalereturn,
                printhsnwiserepwholesalereturn: !role.printhsnwiserepwholesalereturn,
                pdfhsnwiserepwholesalereturn: !role.pdfhsnwiserepwholesalereturn
            }
        })
    }

    const hsnWiseSummarypurchaseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallhsnwisesummarypurchase: !role.checkallhsnwisesummarypurchase,
                excelhsnwisesummarypurchase: !role.excelhsnwisesummarypurchase,
                csvhsnwisesummarypurchase: !role.csvhsnwisesummarypurchase,
                printhsnwisesummarypurchase: !role.printhsnwisesummarypurchase,
                pdfhsnwisesummarypurchase: !role.pdfhsnwisesummarypurchase
            }
        })
    }
    // Settings Management...End

    // Report Management....Start

    const billWiseProfitEstAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbillwiseprofitestimate: !role.checkallbillwiseprofitestimate,
                csvbillwiseprofitestimate: !role.csvbillwiseprofitestimate,
                excelbillwiseprofitestimate: !role.excelbillwiseprofitestimate,
                printbillwiseprofitestimate: !role.printbillwiseprofitestimate,
                pdfbillwiseprofitestimate: !role.pdfbillwiseprofitestimate
            }
        })
    }

    const billWiseProfitRetAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbillwiseprofitretail: !role.checkallbillwiseprofitretail,
                csvbillwiseprofitretail: !role.csvbillwiseprofitretail,
                excelbillwiseprofitretail: !role.excelbillwiseprofitretail,
                printbillwiseprofitretail: !role.printbillwiseprofitretail,
                pdfbillwiseprofitretail: !role.pdfbillwiseprofitretail
            }
        })
    }

    const billWiseProfitWholesaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallbillwiseprofitwholesale: !role.checkallbillwiseprofitwholesale,
                csvbillwiseprofitwholesale: !role.csvbillwiseprofitwholesale,
                excelbillwiseprofitwholesale: !role.excelbillwiseprofitwholesale,
                printbillwiseprofitwholesale: !role.printbillwiseprofitwholesale,
                pdfbillwiseprofitwholesale: !role.pdfbillwiseprofitwholesale
            }
        })
    }

    const customerComparisonAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomercomparison: !role.checkallcustomercomparison,
                csvcustomercomparison: !role.csvcustomercomparison,
                excelcustomercomparison: !role.excelcustomercomparison,
                printcustomercomparison: !role.printcustomercomparison,
                pdfcustomercomparison: !role.pdfcustomercomparison
            }
        })
    }

    const customerWiseProfitEstAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerwiseprofitestimate: !role.checkallcustomerwiseprofitestimate,
                csvcustomerwiseprofitestimate: !role.csvcustomerwiseprofitestimate,
                excelcustomerwiseprofitestimate: !role.excelcustomerwiseprofitestimate,
                printcustomerwiseprofitestimate: !role.printcustomerwiseprofitestimate,
                pdfcustomerwiseprofitestimate: !role.pdfcustomerwiseprofitestimate
            }
        })
    }

    const customerWiseProfitWholesaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallcustomerwiseprofitwholesale: !role.checkallcustomerwiseprofitwholesale,
                csvcustomerwiseprofitwholesale: !role.csvcustomerwiseprofitwholesale,
                excelcustomerwiseprofitwholesale: !role.excelcustomerwiseprofitwholesale,
                printcustomerwiseprofitwholesale: !role.printcustomerwiseprofitwholesale,
                pdfcustomerwiseprofitwholesale: !role.pdfcustomerwiseprofitwholesale
            }
        })
    }

    const dayWiseProfitEstAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldaywiseprofitestimate: !role.checkalldaywiseprofitestimate,
                csvdaywiseprofitestimate: !role.csvdaywiseprofitestimate,
                exceldaywiseprofitestimate: !role.exceldaywiseprofitestimate,
                printdaywiseprofitestimate: !role.printdaywiseprofitestimate,
                pdfdaywiseprofitestimate: !role.pdfdaywiseprofitestimate
            }
        })
    }

    const dayWiseProfitWholesaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldaywiseprofitwholesale: !role.checkalldaywiseprofitwholesale,
                csvdaywiseprofitwholesale: !role.csvdaywiseprofitwholesale,
                exceldaywiseprofitwholesale: !role.exceldaywiseprofitwholesale,
                printdaywiseprofitwholesale: !role.printdaywiseprofitwholesale,
                pdfdaywiseprofitwholesale: !role.pdfdaywiseprofitwholesale
            }
        })
    }

    const dayWiseProfitRetailAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkalldaywiseprofitretail: !role.checkalldaywiseprofitretail,
                csvdaywiseprofitretail: !role.csvdaywiseprofitretail,
                exceldaywiseprofitretail: !role.exceldaywiseprofitretail,
                printdaywiseprofitretail: !role.printdaywiseprofitretail,
                pdfdaywiseprofitretail: !role.pdfdaywiseprofitretail
            }
        })
    }

    const itemWiseProfitEstAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwiseprofitestimate: !role.checkallitemwiseprofitestimate,
                csvitemwiseprofitestimate: !role.csvitemwiseprofitestimate,
                excelitemwiseprofitestimate: !role.excelitemwiseprofitestimate,
                printitemwiseprofitestimate: !role.printitemwiseprofitestimate,
                pdfitemwiseprofitestimate: !role.pdfitemwiseprofitestimate
            }
        })
    }

    const itemWiseProfitRetailAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwiseprofitretail: !role.checkallitemwiseprofitretail,
                csvitemwiseprofitretail: !role.csvitemwiseprofitretail,
                excelitemwiseprofitretail: !role.excelitemwiseprofitretail,
                printitemwiseprofitretail: !role.printitemwiseprofitretail,
                pdfitemwiseprofitretail: !role.pdfitemwiseprofitretail
            }
        })
    }

    const itemWiseProfitWholesaleAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallitemwiseprofitwholesale: !role.checkallitemwiseprofitwholesale,
                csvitemwiseprofitwholesale: !role.csvitemwiseprofitwholesale,
                excelitemwiseprofitwholesale: !role.excelitemwiseprofitwholesale,
                printitemwiseprofitwholesale: !role.printitemwiseprofitwholesale,
                pdfitemwiseprofitwholesale: !role.pdfitemwiseprofitwholesale
            }
        })
    }

    const referenceComparisonAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallreferencecomparison: !role.checkallreferencecomparison,
                csvreferencecomparison: !role.csvreferencecomparison,
                excelreferencecomparison: !role.excelreferencecomparison,
                printreferencecomparison: !role.printreferencecomparison,
                pdfreferencecomparison: !role.pdfreferencecomparison
            }
        })
    }

    const referenceSummaryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallreferencesummary: !role.checkallreferencesummary,
                csvreferencesummary: !role.csvreferencesummary,
                excelreferencesummary: !role.excelreferencesummary,
                printreferencesummary: !role.printreferencesummary,
                pdfreferencesummary: !role.pdfreferencesummary
            }
        })
    }


    const expenseAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallexpense: !role.checkallexpense,
                aexpense: !role.aexpense,
                eexpense: !role.eexpense,
                dexpense: !role.dexpense,
                excelexpense: !role.excelexpense,
                csvexpense: !role.csvexpense,
                printexpense: !role.printexpense,
                pdfexpense: !role.pdfexpense,
                payexpense: !role.payexpense,
            }
        })
    }

    const expenseCategoryAllSelect = () => {
        setRole((prevData) => {
            return {
                ...prevData,
                checkallexpensecategory: !role.checkallexpensecategory,
                aexpensecategory: !role.aexpensecategory,
                eexpensecategory: !role.eexpensecategory,
                dexpensecategory: !role.dexpensecategory,
                excelexpensecategory: !role.excelexpensecategory,
                csvexpensecategory: !role.csvexpensecategory,
                printexpensecategory: !role.printexpensecategory,
                pdfexpensecategory: !role.pdfexpensecategory
            }
        })
    }
    const fetchHandler = async () => {
        try {
            let response = await axios.get(`http://localhost:8003/api/role/${id}`)
            setRole(response.data.srole);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(
        () => {
            fetchHandler();
        }, [id]
    )

    const backPage = useNavigate();

    const fetchRole = async () => {
        try {
            let roles = await axios.put(`http://localhost:8003/api/role/${id}`, {

                //  User Management
                usermanagement: Boolean(role.usermanagement),

                // USer
                alluser: Boolean(role.alluser),
                checkalluser: Boolean(role.checkalluser),
                vuser: Boolean(role.vuser),
                auser: Boolean(role.auser),
                euser: Boolean(role.euser),
                duser: Boolean(role.duser),
                exceluser: Boolean(role.exceluser),
                csvuser: Boolean(role.csvuser),
                printuser: Boolean(role.printuser),
                pdfuser: Boolean(role.pdfuser),

                // Role
                allrole: Boolean(role.allrole),
                checkallrole: Boolean(role.checkallrole),
                arole: Boolean(role.arole),
                erole: Boolean(role.erole),
                drole: Boolean(role.drole),
                excelrole: Boolean(role.excelrole),
                csvrole: Boolean(role.csvrole),
                printrole: Boolean(role.printrole),
                pdfrole: Boolean(role.pdfrole),

                // Sales Commission Agent
                allsalescommissionagent: Boolean(role.allsalescommissionagent),
                checkallsalescommissionagent: Boolean(role.checkallsalescommissionagent),
                asalescommissionagent: Boolean(role.asalescommissionagent),
                esalescommissionagent: Boolean(role.esalescommissionagent),
                dsalescommissionagent: Boolean(role.dsalescommissionagent),
                excelsalescommissionagent: Boolean(role.excelsalescommissionagent),
                csvsalescommissionagent: Boolean(role.csvsalescommissionagent),
                printsalescommissionagent: Boolean(role.printsalescommissionagent),
                pdfsalescommissionagent: Boolean(role.pdfsalescommissionagent),

                // Department
                alldepartment: Boolean(role.alldepartment),
                checkalldepartment: Boolean(role.checkalldepartment),
                adepartment: Boolean(role.adepartment),
                exceldepartment: Boolean(role.exceldepartment),
                csvdepartment: Boolean(role.csvdepartment),
                printdepartment: Boolean(role.printdepartment),
                pdfdepartment: Boolean(role.pdfdepartment),
                edepartment: Boolean(role.edepartment),
                ddepartment: Boolean(role.ddepartment),


                // Supplier Management
                suppliermanagement: Boolean(role.suppliermanagement),

                // Supplier
                allsupplier: Boolean(role.allsupplier),
                checkallsupplier: Boolean(role.checkallsupplier),
                vsupplier: Boolean(role.vsupplier),
                asupplier: Boolean(role.asupplier),
                esupplier: Boolean(role.esupplier),
                dsupplier: Boolean(role.dsupplier),
                excelsupplier: Boolean(role.excelsupplier),
                csvsupplier: Boolean(role.csvsupplier),
                printsupplier: Boolean(role.printsupplier),
                pdfsupplier: Boolean(role.pdfsupplier),

                // Import Supplier
                allimpsupplierlist: Boolean(role.allimpsupplierlist),
                checkallimpsupplierlist: Boolean(role.checkallimpsupplierlist),
                csvimpsupplierlist: Boolean(role.csvimpsupplierlist),
                excelimpsupplierlist: Boolean(role.excelimpsupplierlist),
                printimpsupplierlist: Boolean(role.printimpsupplierlist),
                pdfimpsupplierlist: Boolean(role.pdfimpsupplierlist),
                eimpsupplierlist: Boolean(role.eimpsupplierlist),
                dimpsupplierlist: Boolean(role.dimpsupplierlist),
                vimpsupplierlist: Boolean(role.vimpsupplierlist),

                // Import Supplier List
                allbalwisesupduesreport: Boolean(role.allbalwisesupduesreport),
                checkallbalwisesupduesreport: Boolean(role.checkallbalwisesupduesreport),
                csvbalwisesupduesreport: Boolean(role.csvbalwisesupduesreport),
                excelbalwisesupduesreport: Boolean(role.excelbalwisesupduesreport),
                printbalwisesupduesreport: Boolean(role.printbalwisesupduesreport),
                pdfbalwisesupduesreport: Boolean(role.pdfbalwisesupduesreport),

                // Supplier Balance Abstract 
                allsupplierbalanceabstract: Boolean(role.allsupplierbalanceabstract),
                checkallsupplierbalanceabstract: Boolean(role.checkallsupplierbalanceabstract),
                csvsupplierbalanceabstract: Boolean(role.csvsupplierbalanceabstract),
                excelsupplierbalanceabstract: Boolean(role.excelsupplierbalanceabstract),
                printsupplierbalanceabstract: Boolean(role.printsupplierbalanceabstract),
                pdfsupplierbalanceabstract: Boolean(role.pdfsupplierbalanceabstract),

                // Supplier Bills Report 
                allsupplierbillsreport: Boolean(role.allsupplierbillsreport),
                checkallsupplierbillsreport: Boolean(role.checkallsupplierbillsreport),
                excelsupplierbillsreport: Boolean(role.excelsupplierbillsreport),
                printsupplierbillsreport: Boolean(role.printsupplierbillsreport),
                pdfsupplierbillsreport: Boolean(role.pdfsupplierbillsreport),
                csvsupplierbillsreport: Boolean(role.csvsupplierbillsreport),

                // Supplier Dues Report 
                allsupduesreport: Boolean(role.allsupduesreport),
                checkallsupduesreport: Boolean(role.checkallsupduesreport),
                csvsupduesreport: Boolean(role.csvsupduesreport),
                excelsupduesreport: Boolean(role.excelsupduesreport),
                printsupduesreport: Boolean(role.printsupduesreport),
                pdfsupduesreport: Boolean(role.pdfsupduesreport),

                //  Supplier Over Dues Report
                allsupplieroverduereport: Boolean(role.allsupplieroverduereport),
                checkallsupplieroverduereport: Boolean(role.checkallsupplieroverduereport),
                csvsupplieroverduereport: Boolean(role.csvsupplieroverduereport),
                excelsupplieroverduereport: Boolean(role.excelsupplieroverduereport),
                printsupplieroverduereport: Boolean(role.printsupplieroverduereport),
                pdfsupplieroverduereport: Boolean(role.pdfsupplieroverduereport),

                // Supplier Payments Report
                allsupplierpaymentsreport: Boolean(role.allsupplierpaymentsreport),
                checkallsupplierpaymentsreport: Boolean(role.checkallsupplierpaymentsreport),
                csvsupplierpaymentsreport: Boolean(role.csvsupplierpaymentsreport),
                excelsupplierpaymentsreport: Boolean(role.excelsupplierpaymentsreport),
                printsupplierpaymentsreport: Boolean(role.printsupplierpaymentsreport),
                pdfsupplierpaymentsreport: Boolean(role.pdfsupplierpaymentsreport),

                // Supplier Reports
                allsuppliersreport: Boolean(role.allsuppliersreport),
                checkallsuppliersreport: Boolean(role.checkallsuppliersreport),
                csvsuppliersreport: Boolean(role.csvsuppliersreport),
                excelsuppliersreport: Boolean(role.excelsuppliersreport),
                printsuppliersreport: Boolean(role.printsuppliersreport),
                pdfsuppliersreport: Boolean(role.pdfsuppliersreport),

                // Supplier Wise Payments Report
                allsupplierwisepaymentsreport: Boolean(role.allsupplierwisepaymentsreport),
                checkallsupplierwisepaymentsreport: Boolean(role.checkallsupplierwisepaymentsreport),
                csvsupplierwisepaymentsreport: Boolean(role.csvsupplierwisepaymentsreport),
                excelsupplierwisepaymentsreport: Boolean(role.excelsupplierwisepaymentsreport),
                printsupplierwisepaymentsreport: Boolean(role.printsupplierwisepaymentsreport),
                pdfsupplierwisepaymentsreport: Boolean(role.pdfsupplierwisepaymentsreport),

                // customer Management Start.............

                customermanagement: Boolean(role.customermanagement),
                // Customer start
                allcustomer: Boolean(role.allcustomer),
                checkallcustomer: Boolean(role.checkallcustomer),
                acustomer: Boolean(role.acustomer),
                ecustomer: Boolean(role.ecustomer),
                dcustomer: Boolean(role.dcustomer),
                excelcustomer: Boolean(role.excelcustomer),
                csvcustomer: Boolean(role.csvcustomer),
                printcustomer: Boolean(role.printcustomer),
                pdfcustomer: Boolean(role.pdfcustomer),

                //  Area Wise Customer List
                allareawisecustomerlist: Boolean(role.allareawisecustomerlist),
                checkallareawisecustomerlist: Boolean(role.checkallareawisecustomerlist),
                csvareawisecustomer: Boolean(role.csvareawisecustomer),
                excelareawisecustomer: Boolean(role.excelareawisecustomer),
                printareawisecustomer: Boolean(role.printareawisecustomer),
                pdfareawisecustomer: Boolean(role.pdfareawisecustomer),

                // Balance Wise Customer Dues Report 
                allbalancewisecusduesreport: Boolean(role.allbalancewisecusduesreport),
                checkallbalancewisecusduesreport: Boolean(role.checkallbalancewisecusduesreport),
                excelbalancewisecusduesreport: Boolean(role.excelbalancewisecusduesreport),
                csvbalancewisecusduesreport: Boolean(role.csvbalancewisecusduesreport),
                printbalancewisecusduesreport: Boolean(role.printbalancewisecusduesreport),
                pdfbalancewisecusduesreport: Boolean(role.pdfbalancewisecusduesreport),

                //  Category Wise Customer List 
                allcategorywisecustomerlist: Boolean(role.allcategorywisecustomerlist),
                checkallcategorywisecustomerlist: Boolean(role.checkallcategorywisecustomerlist),
                excelcategorywisecustomerlist: Boolean(role.excelcategorywisecustomerlist),
                csvcategorywisecustomerlist: Boolean(role.csvcategorywisecustomerlist),
                printcategorywisecustomerlist: Boolean(role.printcategorywisecustomerlist),
                pdfcategorywisecustomerlist: Boolean(role.pdfcategorywisecustomerlist),

                //  Customer Account Statement 
                allcustomeraccountstatement: Boolean(role.allcustomeraccountstatement),
                checkallcustomeraccountstatement: Boolean(role.checkallcustomeraccountstatement),
                excelcustomeraccountstatement: Boolean(role.excelcustomeraccountstatement),
                csvcustomeraccountstatement: Boolean(role.csvcustomeraccountstatement),
                printcustomeraccountstatement: Boolean(role.printcustomeraccountstatement),
                pdfcustomeraccountstatement: Boolean(role.pdfcustomeraccountstatement),

                //  Customer Account Summary
                allcustomeraccountsummary: Boolean(role.allcustomeraccountsummary),
                checkallcustomeraccountsummary: Boolean(role.checkallcustomeraccountsummary),
                excelcustomeraccountsummary: Boolean(role.excelcustomeraccountsummary),
                csvcustomeraccountsummary: Boolean(role.csvcustomeraccountsummary),
                printcustomeraccountsummary: Boolean(role.printcustomeraccountsummary),
                pdfcustomeraccountsummary: Boolean(role.pdfcustomeraccountsummary),

                //  Customer Balance Register
                allCustomerbalanceregister: Boolean(role.allCustomerbalanceregister),
                checkallCustomerbalanceregister: Boolean(role.checkallCustomerbalanceregister),
                excelCustomerbalanceregister: Boolean(role.excelCustomerbalanceregister),
                csvCustomerbalanceregister: Boolean(role.csvCustomerbalanceregister),
                printCustomerbalanceregister: Boolean(role.printCustomerbalanceregister),
                pdfCustomerbalanceregister: Boolean(role.pdfCustomerbalanceregister),

                //  Customer Bills Report
                allcustomerbillsreport: Boolean(role.allcustomerbillsreport),
                checkallcustomerbillsreport: Boolean(role.checkallcustomerbillsreport),
                excelcustomerbillsreport: Boolean(role.excelcustomerbillsreport),
                csvcustomerbillsreport: Boolean(role.csvcustomerbillsreport),
                printcustomerbillsreport: Boolean(role.printcustomerbillsreport),
                pdfcustomerbillsreport: Boolean(role.pdfcustomerbillsreport),

                // Customer Drug Entry  
                allcusdrugentry: Boolean(role.allcusdrugentry),
                checkallcusdrugentry: Boolean(role.checkallcusdrugentry),
                acusdrugentry: Boolean(role.acusdrugentry),
                excelcusdrugentry: Boolean(role.excelcusdrugentry),
                csvcusdrugentry: Boolean(role.csvcusdrugentry),
                printcusdrugentry: Boolean(role.printcusdrugentry),
                pdfcusdrugentry: Boolean(role.pdfcusdrugentry),

                //  Customer Dues Report
                allcustduesreport: Boolean(role.allcustduesreport),
                checkallcustduesreport: Boolean(role.checkallcustduesreport),
                excelcustduesreport: Boolean(role.excelcustduesreport),
                csvcustduesreport: Boolean(role.csvcustduesreport),
                printcustduesreport: Boolean(role.printcustduesreport),
                pdfcustduesreport: Boolean(role.pdfcustduesreport),

                // Customer Over Dues Abstract
                allcusoverduesabstract: Boolean(role.allcusoverduesabstract),
                checkallcusoverduesabstract: Boolean(role.checkallcusoverduesabstract),
                excelcusoverduesabstract: Boolean(role.excelcusoverduesabstract),
                csvcusoverduesabstract: Boolean(role.csvcusoverduesabstract),
                printcusoverduesabstract: Boolean(role.printcusoverduesabstract),
                pdfcusoverduesabstract: Boolean(role.pdfcusoverduesabstract),

                //  Customer Over Dues Report
                allcusoverduesrep: Boolean(role.allcusoverduesrep),
                checkallcusoverduesrep: Boolean(role.checkallcusoverduesrep),
                excelcusoverduesrep: Boolean(role.excelcusoverduesrep),
                csvcusoverduesrep: Boolean(role.csvcusoverduesrep),
                printcusoverduesrep: Boolean(role.printcusoverduesrep),
                pdfcusoverduesrep: Boolean(role.pdfcusoverduesrep),

                // Customer Payment Report
                allcustomerpaymentreport: Boolean(role.allcustomerpaymentreport),
                checkallcustomerpaymentreport: Boolean(role.checkallcustomerpaymentreport),
                excelcustomerpaymentreport: Boolean(role.excelcustomerpaymentreport),
                csvcustomerpaymentreport: Boolean(role.csvcustomerpaymentreport),
                printcustomerpaymentreport: Boolean(role.printcustomerpaymentreport),
                pdfcustomerpaymentreport: Boolean(role.pdfcustomerpaymentreport),

                //  Customer Wise Payment Report
                allcustomerwisepaymentreport: Boolean(role.allcustomerwisepaymentreport),
                checkallcustomerwisepaymentreport: Boolean(role.checkallcustomerwisepaymentreport),
                excelcustomerwisepaymentreport: Boolean(role.excelcustomerwisepaymentreport),
                csvcustomerwisepaymentreport: Boolean(role.csvcustomerwisepaymentreport),
                printcustomerwisepaymentreport: Boolean(role.printcustomerwisepaymentreport),
                pdfcustomerwisepaymentreport: Boolean(role.pdfcustomerwisepaymentreport),

                // Retail Followup Customer
                allretailfollowupcustomer: Boolean(role.allretailfollowupcustomer),
                checkallretailfollowupcustomer: Boolean(role.checkallretailfollowupcustomer),
                excelretailfollowupcustomer: Boolean(role.excelretailfollowupcustomer),
                csvretailfollowupcustomer: Boolean(role.csvretailfollowupcustomer),
                printretailfollowupcustomer: Boolean(role.printretailfollowupcustomer),
                pdfretailfollowupcustomer: Boolean(role.pdfretailfollowupcustomer),

                // Wholesale Followup Customer
                allwholesalefollowupcustomer: Boolean(role.allwholesalefollowupcustomer),
                checkallwholesalefollowupcustomer: Boolean(role.checkallwholesalefollowupcustomer),
                excelwholesalefollowupcustomer: Boolean(role.excelwholesalefollowupcustomer),
                csvwholesalefollowupcustomer: Boolean(role.csvwholesalefollowupcustomer),
                printwholesalefollowupcustomer: Boolean(role.printwholesalefollowupcustomer),
                pdfwholesalefollowupcustomer: Boolean(role.pdfwholesalefollowupcustomer),

                //  Customer List Retail
                allcustomerlistretail: Boolean(role.allcustomerlistretail),
                checkallcustomerlistretail: Boolean(role.checkallcustomerlistretail),
                acustomerlistretail: Boolean(role.acustomerlistretail),
                excelcustomerlistretail: Boolean(role.excelcustomerlistretail),
                csvcustomerlistretail: Boolean(role.csvcustomerlistretail),
                printcustomerlistretail: Boolean(role.printcustomerlistretail),
                pdfcustomerlistretail: Boolean(role.pdfcustomerlistretail),
                ecustomerlistretail: Boolean(role.ecustomerlistretail),
                dcustomerlistretail: Boolean(role.dcustomerlistretail),

                //  Customer List Whole Sale
                allcustomerlistwholesale: Boolean(role.allcustomerlistwholesale),
                checkallcustomerlistwholesale: Boolean(role.checkallcustomerlistwholesale),
                acustomerlistwholesale: Boolean(role.acustomerlistwholesale),
                excelcustomerlistwholesale: Boolean(role.excelcustomerlistwholesale),
                csvcustomerlistwholesale: Boolean(role.csvcustomerlistwholesale),
                printcustomerlistwholesale: Boolean(role.printcustomerlistwholesale),
                pdfcustomerlistwholesale: Boolean(role.pdfcustomerlistwholesale),
                ecustomerlistwholesale: Boolean(role.ecustomerlistwholesale),
                dcustomerlistwholesale: Boolean(role.dcustomerlistwholesale),

                //  Reference Wise Customer Dues Report Estimate
                allreferencewisecusduesreportestimate: Boolean(role.allreferencewisecusduesreportestimate),
                checkallreferencewisecusduesreportestimate: Boolean(role.checkallreferencewisecusduesreportestimate),
                excelreferencewisecusduesreportestimate: Boolean(role.excelreferencewisecusduesreportestimate),
                csvreferencewisecusduesreportestimate: Boolean(role.csvreferencewisecusduesreportestimate),
                printreferencewisecusduesreportestimate: Boolean(role.printreferencewisecusduesreportestimate),
                pdfreferencewisecusduesreportestimate: Boolean(role.pdfreferencewisecusduesreportestimate),

                //  Reference Wise Customer Dues Report Sales
                allreferencewisecusduesreportsales: Boolean(role.allreferencewisecusduesreportsales),
                checkallreferencewisecusduesreportsales: Boolean(role.checkallreferencewisecusduesreportsales),
                excelreferencewisecusduesreportsales: Boolean(role.excelreferencewisecusduesreportsales),
                csvreferencewisecusduesreportsales: Boolean(role.csvreferencewisecusduesreportsales),
                printreferencewisecusduesreportsales: Boolean(role.printreferencewisecusduesreportsales),
                pdfreferencewisecusduesreportsales: Boolean(role.pdfreferencewisecusduesreportsales),

                // Reference Wise Payment Report
                allreferencewisepaymentreport: Boolean(role.allreferencewisepaymentreport),
                checkallreferencewisepaymentreport: Boolean(role.checkallreferencewisepaymentreport),
                excelreferencewisepaymentreport: Boolean(role.excelreferencewisepaymentreport),
                csvreferencewisepaymentreport: Boolean(role.csvreferencewisepaymentreport),
                printreferencewisepaymentreport: Boolean(role.printreferencewisepaymentreport),
                pdfreferencewisepaymentreport: Boolean(role.pdfreferencewisepaymentreport),

                //  Reference Wise Payment Summary
                allreferencewisepaymentsummary: Boolean(role.allreferencewisepaymentsummary),
                checkallreferencewisepaymentsummary: Boolean(role.checkallreferencewisepaymentsummary),
                excelreferencewisepaymentsummary: Boolean(role.excelreferencewisepaymentsummary),
                csvreferencewisepaymentsummary: Boolean(role.csvreferencewisepaymentsummary),
                printreferencewisepaymentsummary: Boolean(role.printreferencewisepaymentsummary),
                pdfreferencewisepaymentsummary: Boolean(role.pdfreferencewisepaymentsummary),

                // Remark Wise Customer Report
                allremarkwisecustomerreport: Boolean(role.allremarkwisecustomerreport),
                checkallremarkwisecustomerreport: Boolean(role.checkallremarkwisecustomerreport),
                excelremarkwisecustomerreport: Boolean(role.excelremarkwisecustomerreport),
                csvremarkwisecustomerreport: Boolean(role.csvremarkwisecustomerreport),
                printremarkwisecustomerreport: Boolean(role.printremarkwisecustomerreport),
                pdfremarkwisecustomerreport: Boolean(role.pdfremarkwisecustomerreport),

                // Customer End........

                // Product Management Start ...........

                // Product
                productmanagement: Boolean(role.productmanagement),
                allproduct: Boolean(role.allproduct),
                checkallproduct: Boolean(role.checkallproduct),
                vproduct: Boolean(role.vproduct),
                iproduct: Boolean(role.iproduct),
                aproduct: Boolean(role.aproduct),
                eproduct: Boolean(role.eproduct),
                dproduct: Boolean(role.dproduct),
                excelproduct: Boolean(role.excelproduct),
                csvproduct: Boolean(role.csvproduct),
                printproduct: Boolean(role.printproduct),
                pdfproduct: Boolean(role.pdfproduct),

                // Category 
                allcategory: Boolean(role.allcategory),
                checkallcategory: Boolean(role.checkallcategory),
                acategory: Boolean(role.acategory),
                ecategory: Boolean(role.ecategory),
                dcategory: Boolean(role.dcategory),
                printcategory: Boolean(role.printcategory),
                pdfcategory: Boolean(role.pdfcategory),
                excelcategory: Boolean(role.excelcategory),
                csvcategory: Boolean(role.csvcategory),

                // Discount
                alldiscount: Boolean(role.alldiscount),
                checkalldiscount: Boolean(role.checkalldiscount),
                adiscount: Boolean(role.adiscount),
                ediscount: Boolean(role.ediscount),
                ddiscount: Boolean(role.ddiscount),
                exceldiscount: Boolean(role.exceldiscount),
                csvdiscount: Boolean(role.csvdiscount),
                printdiscount: Boolean(role.printdiscount),
                pdfdiscount: Boolean(role.pdfdiscount),

                // Unit
                allunit: Boolean(role.allunit),
                checkallunit: Boolean(role.checkallunit),
                aunit: Boolean(role.aunit),
                eunit: Boolean(role.eunit),
                dunit: Boolean(role.dunit),
                excelunit: Boolean(role.excelunit),
                csvunit: Boolean(role.csvunit),
                printunit: Boolean(role.printunit),
                pdfunit: Boolean(role.pdfunit),

                //  Category List
                allcatelist: Boolean(role.allcatelist),
                checkallcatelist: Boolean(role.checkallcatelist),
                excelcatelist: Boolean(role.excelcatelist),
                csvcatelist: Boolean(role.csvcatelist),
                printcatelist: Boolean(role.printcatelist),
                pdfcatelist: Boolean(role.pdfcatelist),

                // Category Summary
                allcatesummary: Boolean(role.allcatesummary),
                checkallcatesummary: Boolean(role.checkallcatesummary),
                excelcatesummary: Boolean(role.excelcatesummary),
                csvcatesummary: Boolean(role.csvcatesummary),
                printcatesummary: Boolean(role.printcatesummary),
                pdfcatesummary: Boolean(role.pdfcatesummary),

                // Manufacture Wise List
                allmanufacturelist: Boolean(role.allmanufacturelist),
                checkallmanufacturelist: Boolean(role.checkallmanufacturelist),
                excelmanufacturelist: Boolean(role.excelmanufacturelist),
                csvmanufacturelist: Boolean(role.csvmanufacturelist),
                printmanufacturelist: Boolean(role.printmanufacturelist),
                pdfmanufacturelist: Boolean(role.pdfmanufacturelist),

                //  Manufacture Wise Summary
                allmanufacturesummary: Boolean(role.allmanufacturesummary),
                checkallmanufacturesummary: Boolean(role.checkallmanufacturesummary),
                excelmanufacturesummary: Boolean(role.excelmanufacturesummary),
                csvmanufacturesummary: Boolean(role.csvmanufacturesummary),
                printmanufacturesummary: Boolean(role.printmanufacturesummary),
                pdfmanufactursummary: Boolean(role.pdfmanufactursummary),

                //  Molecule Wise Summary
                allmolecule: Boolean(role.allmolecule),
                checkallmolecule: Boolean(role.checkallmolecule),
                excelmolecule: Boolean(role.excelmolecule),
                csvmoleslist: Boolean(role.csvmoleslist),
                printsmolelist: Boolean(role.printsmolelist),
                pdfmolecule: Boolean(role.pdfmolecule),

                //  Molecule Wise Summary
                allmolesummary: Boolean(role.allmolesummary),
                checkallmolesummary: Boolean(role.checkallmolesummary),
                excelmolesummary: Boolean(role.excelmolesummary),
                csvmolessummary: Boolean(role.csvmolessummary),
                printsmolesummary: Boolean(role.printsmolesummary),
                pdfmolesummary: Boolean(role.pdfmolesummary),

                //  Schedule Wise List
                allschedulelist: Boolean(role.allschedulelist),
                checkallschedulelist: Boolean(role.checkallschedulelist),
                excelschedulelist: Boolean(role.excelschedulelist),
                csvschedulelist: Boolean(role.csvschedulelist),
                printschedulelist: Boolean(role.printschedulelist),
                pdfschedulelist: Boolean(role.pdfschedulelist),

                //  Schedule
                allschedule: Boolean(role.allschedule),
                checkallschedule: Boolean(role.checkallschedule),
                excelschedule: Boolean(role.excelschedule),
                csvschedule: Boolean(role.csvschedule),
                printschedule: Boolean(role.printschedule),
                pdfschedule: Boolean(role.pdfschedule),
                eschedule: Boolean(role.eschedule),
                dschedule: Boolean(role.dschedule),

                // Product End

                //  Purchase Management start
                allpurchase: Boolean(role.allpurchase),
                checkallpurchase: Boolean(role.checkallpurchase),
                apurchase: Boolean(role.apurchase),
                vpurchase: Boolean(role.vpurchase),
                epurchase: Boolean(role.epurchase),
                dpurchase: Boolean(role.dpurchase),
                excelpurchase: Boolean(role.excelpurchase),
                csvpurchase: Boolean(role.csvpurchase),
                printpurchase: Boolean(role.printpurchase),
                pdfpurchase: Boolean(role.pdfpurchase),

                //  Purchase return
                allpurchasereturnlist: Boolean(role.allpurchasereturnlist),
                checkallpurchasereturnlist: Boolean(role.checkallpurchasereturnlist),
                apurchasereturnlist: Boolean(role.apurchasereturnlist),
                vpurchasereturnlist: Boolean(role.vpurchasereturnlist),
                epurchasereturnlist: Boolean(role.epurchasereturnlist),
                dpurchasereturnlist: Boolean(role.dpurchasereturnlist),
                csvpurchasereturnlist: Boolean(role.csvpurchasereturnlist),
                excelpurchasereturnlist: Boolean(role.excelpurchasereturnlist),
                printpurchasereturnlist: Boolean(role.printpurchasereturnlist),
                pdfpurchasereturnlist: Boolean(role.pdfpurchasereturnlist),

                //  supplier product
                allsupplierproduct: Boolean(role.allsupplierproduct),
                checkallsupplierproduct: Boolean(role.checkallsupplierproduct),
                supplierproductadd: Boolean(role.supplierproductadd),
                supplierproductview: Boolean(role.supplierproductview),
                supplierproductedit: Boolean(role.supplierproductedit),
                supplierproductdelete: Boolean(role.supplierproductdelete),
                supplierproductcsv: Boolean(role.supplierproductcsv),
                supplierproductexcel: Boolean(role.supplierproductexcel),
                supplierproductprint: Boolean(role.supplierproductprint),
                supplierproductpdf: Boolean(role.supplierproductpdf),

                //  category wise purchase
                allcategorywisepurchase: Boolean(role.allcategorywisepurchase),
                checkallcategorywisepurchase: Boolean(role.checkallcategorywisepurchase),
                csvcategorywisepurchase: Boolean(role.csvcategorywisepurchase),
                excelcategorywisepurchase: Boolean(role.excelcategorywisepurchase),
                printcategorywisepurchase: Boolean(role.printcategorywisepurchase),
                pdfcategorywisepurchase: Boolean(role.pdfcategorywisepurchase),

                //  item wise purchase
                allitemwisepurchasereport: Boolean(role.allitemwisepurchasereport),
                checkallitemwisepurchasereport: Boolean(role.checkallitemwisepurchasereport),
                csvitemwisepurchasereport: Boolean(role.csvitemwisepurchasereport),
                excelitemwisepurchasereport: Boolean(role.excelitemwisepurchasereport),
                printitemwisepurchasereport: Boolean(role.printitemwisepurchasereport),
                pdfitemwisepurchasereport: Boolean(role.pdfitemwisepurchasereport),

                //  item wise purchase return
                allitemwisepurchasereturn: Boolean(role.allitemwisepurchasereturn),
                checkallitemwisepurchasereturn: Boolean(role.checkallitemwisepurchasereturn),
                csvitemwisepurchasereturn: Boolean(role.csvitemwisepurchasereturn),
                excelitemwisepurchasereturn: Boolean(role.excelitemwisepurchasereturn),
                printitemwisepurchasereturn: Boolean(role.printitemwisepurchasereturn),
                pdfitemwisepurchasereturn: Boolean(role.pdfitemwisepurchasereturn),

                //  manufacture wise purchase return
                allmanufacturewisepurchase: Boolean(role.allmanufacturewisepurchase),
                checkallmanufacturewisepurchase: Boolean(role.checkallmanufacturewisepurchase),
                csvmanufacturewisepurchase: Boolean(role.csvmanufacturewisepurchase),
                excelmanufacturwisepurchase: Boolean(role.excelmanufacturwisepurchase),
                printmanufacturewisepurchase: Boolean(role.printmanufacturewisepurchase),
                pdfmanufacturewisepurchase: Boolean(role.pdfmanufacturewisepurchase),

                //  purchase detailed report
                allpurchasedetailedreport: Boolean(role.allpurchasedetailedreport),
                checkallpurchasedetailedreport: Boolean(role.checkallpurchasedetailedreport),
                csvpurchasedetailedreport: Boolean(role.csvpurchasedetailedreport),
                excelpurchasedetailedreport: Boolean(role.excelpurchasedetailedreport),
                printpurchasedetailedreport: Boolean(role.printpurchasedetailedreport),
                pdfpurchasedetailedreport: Boolean(role.pdfpurchasedetailedreport),

                //  purchase report
                allpurchasereport: Boolean(role.allpurchasereport),
                checkallpurchasereport: Boolean(role.checkallpurchasereport),
                csvpurchasereport: Boolean(role.csvpurchasereport),
                excelpurchasereport: Boolean(role.excelpurchasereport),
                printpurchasereport: Boolean(role.printpurchasereport),
                pdfpurchasereport: Boolean(role.pdfpurchasereport),

                //  purchase return report
                allpurchasereturnreport: Boolean(role.allpurchasereturnreport),
                checkallpurchasereturnreport: Boolean(role.checkallpurchasereturnreport),
                csvpurchasereturnreport: Boolean(role.csvpurchasereturnreport),
                excelpurchasereturnreport: Boolean(role.excelpurchasereturnreport),
                printpurchasereturnreport: Boolean(role.printpurchasereturnreport),
                pdfpurchasereturnreport: Boolean(role.pdfpurchasereturnreport),

                //  supplier wise purchase
                allsupplierwisepurchase: Boolean(role.allsupplierwisepurchase),
                checkallsupplierwisepurchase: Boolean(role.checkallsupplierwisepurchase),
                csvsupplierwisepurchase: Boolean(role.csvsupplierwisepurchase),
                excelsupplierwisepurchase: Boolean(role.excelsupplierwisepurchase),
                printsupplierwisepurchase: Boolean(role.printsupplierwisepurchase),
                pdfsupplierwisepurchase: Boolean(role.pdfsupplierwisepurchase),

                //  supplier wise purchase report
                allsupplierwisepurchasereport: Boolean(role.allsupplierwisepurchasereport),
                checkallsupplierwisepurchasereport: Boolean(role.checkallsupplierwisepurchasereport),
                csvsupplierwisepurchasereport: Boolean(role.csvsupplierwisepurchasereport),
                excelsupplierwisepurchasereport: Boolean(role.excelsupplierwisepurchasereport),
                printsupplierwisepurchasereport: Boolean(role.printsupplierwisepurchasereport),
                pdfsupplierwisepurchasereport: Boolean(role.pdfsupplierwisepurchasereport),
                //  Purchase End

                //  Estimate Start
                //  draft
                allestimatedraft: Boolean(role.allestimatedraft),
                checkallestimatedraft: Boolean(role.checkallestimatedraft),
                vestimatedraft: Boolean(role.vestimatedraft),
                aestimatedraft: Boolean(role.aestimatedraft),
                eestimatedraft: Boolean(role.eestimatedraft),
                destimatedraft: Boolean(role.destimatedraft),
                csvestimatedraft: Boolean(role.csvestimatedraft),
                excelestimatedraft: Boolean(role.excelestimatedraft),
                printestimatedraft: Boolean(role.printestimatedraft),
                pdfestimatedraft: Boolean(role.pdfestimatedraft),
                //  estimate list
                allestimatelist: Boolean(role.allestimatelist),
                checkallestimatelist: Boolean(role.checkallestimatelist),
                aestimatelist: Boolean(role.aestimatelist),
                eestimatelist: Boolean(role.eestimatelist),
                destimatelist: Boolean(role.destimatelist),
                vestimatelist: Boolean(role.vestimatelist),
                csvestimatelist: Boolean(role.csvestimatelist),
                excelestimatelist: Boolean(role.excelestimatelist),
                printestimatelist: Boolean(role.printestimatelist),
                pdfestimatelist: Boolean(role.pdfestimatelist),
                //  estimate list
                allestimatequotation: Boolean(role.allestimatequotation),
                checkallestimatequotation: Boolean(role.checkallestimatequotation),
                vestimatequotation: Boolean(role.vestimatequotation),
                aestimatequotation: Boolean(role.aestimatequotation),
                eestimatequotation: Boolean(role.eestimatequotation),
                destimatequotation: Boolean(role.destimatequotation),
                csvestimatequotation: Boolean(role.csvestimatequotation),
                excelestimatequotation: Boolean(role.excelestimatequotation),
                printestimatequotation: Boolean(role.printestimatequotation),
                pdfestimatequotation: Boolean(role.pdfestimatequotation),
                //  area wise estimate
                allareawiseestimate: Boolean(role.allareawiseestimate),
                checkallareawiseestimate: Boolean(role.checkallareawiseestimate),
                csvareawiseestimate: Boolean(role.csvareawiseestimate),
                excelareawiseestimate: Boolean(role.excelareawiseestimate),
                printareawiseestimate: Boolean(role.printareawiseestimate),
                pdfareawiseestimate: Boolean(role.pdfareawiseestimate),
                //  category wise estimate
                allcategorywiseestimates: Boolean(role.allcategorywiseestimates),
                checkallcategorywiseestimates: Boolean(role.checkallcategorywiseestimates),
                csvcategorywiseestimates: Boolean(role.csvcategorywiseestimates),
                excelcategorywiseestimates: Boolean(role.excelcategorywiseestimates),
                printcategorywiseestimates: Boolean(role.printcategorywiseestimates),
                pdfcategorywiseestimates: Boolean(role.pdfcategorywiseestimates),
                //  customer wise estimate report
                allcustomerwiseestimatereport: Boolean(role.allcustomerwiseestimatereport),
                checkallcustomerwiseestimatereport: Boolean(role.checkallcustomerwiseestimatereport),
                csvcustomerwiseestimatereport: Boolean(role.csvcustomerwiseestimatereport),
                excelcustomerwiseestimatereport: Boolean(role.excelcustomerwiseestimatereport),
                printcustomerwiseestimatereport: Boolean(role.printcustomerwiseestimatereport),
                pdfcustomerwiseestimatereport: Boolean(role.pdfcustomerwiseestimatereport),
                //  day wise estimate
                alldaywiseestimatereport: Boolean(role.alldaywiseestimatereport),
                checkalldaywiseestimatereport: Boolean(role.checkalldaywiseestimatereport),
                csvdaywiseestimatereport: Boolean(role.csvdaywiseestimatereport),
                exceldaywiseestimatereport: Boolean(role.exceldaywiseestimatereport),
                printdaywiseestimatereport: Boolean(role.printdaywiseestimatereport),
                pdfdaywiseestimatereport: Boolean(role.pdfdaywiseestimatereport),
                //  estimate report
                allestimatereport: Boolean(role.allestimatereport),
                checkallestimatereport: Boolean(role.checkallestimatereport),
                csvestimatereport: Boolean(role.csvestimatereport),
                excelestimatereport: Boolean(role.excelestimatereport),
                printestimatereport: Boolean(role.printestimatereport),
                pdfestimatereport: Boolean(role.pdfestimatereport),
                //  estimate return register
                allestimatereturnregister: Boolean(role.allestimatereturnregister),
                checkallestimatereturnregister: Boolean(role.checkallestimatereturnregister),
                csvestimatereturnregister: Boolean(role.csvestimatereturnregister),
                excelestimatereturnregister: Boolean(role.excelestimatereturnregister),
                printestimatereturnregister: Boolean(role.printestimatereturnregister),
                pdfestimatereturnregister: Boolean(role.pdfestimatereturnregister),
                //  estimate return report
                allestimatereturnreport: Boolean(role.allestimatereturnreport),
                checkallestimatereturnreport: Boolean(role.checkallestimatereturnreport),
                csvestimatereturnreport: Boolean(role.csvestimatereturnreport),
                excelestimatereturnreport: Boolean(role.excelestimatereturnreport),
                printestimatereturnreport: Boolean(role.printestimatereturnreport),
                pdfestimatereturnreport: Boolean(role.pdfestimatereturnreport),
                //  item wise estimate
                allitemwiseestimate: Boolean(role.allitemwiseestimate),
                checkallitemwiseestimate: Boolean(role.checkallitemwiseestimate),
                csvitemwiseestimate: Boolean(role.csvitemwiseestimate),
                excelitemwiseestimate: Boolean(role.excelitemwiseestimate),
                printitemwiseestimate: Boolean(role.printitemwiseestimate),
                pdfitemwiseestimate: Boolean(role.pdfitemwiseestimate),
                //  item wise estimate return
                allitemwiseestimatereturn: Boolean(role.allitemwiseestimatereturn),
                checkallitemwiseestimatereturn: Boolean(role.checkallitemwiseestimatereturn),
                csvitemwiseestimatereturn: Boolean(role.csvitemwiseestimatereturn),
                excelitemwiseestimatereturn: Boolean(role.excelitemwiseestimatereturn),
                printitemwiseestimatereturn: Boolean(role.printitemwiseestimatereturn),
                pdfitemwiseestimatereturn: Boolean(role.pdfitemwiseestimatereturn),
                //  manufacture wise estimate
                allmanufacturewiseestimate: Boolean(role.allmanufacturewiseestimate),
                checkallmanufacturewiseestimate: Boolean(role.checkallmanufacturewiseestimate),
                csvmanufacturewiseestimate: Boolean(role.csvmanufacturewiseestimate),
                excelmanufacturewiseestimate: Boolean(role.excelmanufacturewiseestimate),
                printmanufacturewiseestimate: Boolean(role.printmanufacturewiseestimate),
                pdfmanufacturewiseestimate: Boolean(role.pdfmanufacturewiseestimate),
                //  manufacture wise estimate return
                allmanufacturewiseestimatereturn: Boolean(role.allmanufacturewiseestimatereturn),
                checkallmanufacturewiseestimatereturn: Boolean(role.checkallmanufacturewiseestimatereturn),
                csvmanufacturewiseestimatereturn: Boolean(role.csvmanufacturewiseestimatereturn),
                excelmanufacturewiseestimatereturn: Boolean(role.excelmanufacturewiseestimatereturn),
                printmanufacturewiseestimatereturn: Boolean(role.printmanufacturewiseestimatereturn),
                pdfmanufacturewiseestimatereturn: Boolean(role.pdfmanufacturewiseestimatereturn),
                //  reference wise estimate
                allreferencewiseestimate: Boolean(role.allreferencewiseestimate),
                checkallreferencewiseestimate: Boolean(role.checkallreferencewiseestimate),
                csvreferencewiseestimate: Boolean(role.csvreferencewiseestimate),
                excelreferencewiseestimate: Boolean(role.excelreferencewiseestimate),
                printreferencewiseestimate: Boolean(role.printreferencewiseestimate),
                pdfreferencewiseestimate: Boolean(role.pdfreferencewiseestimate),

                //  sale return summary
                allestimatesalereturnsummary: Boolean(role.allestimatesalereturnsummary),
                checkallestimatesalereturnsummary: Boolean(role.checkallestimatesalereturnsummary),
                csvestimatesalereturnsummary: Boolean(role.csvestimatesalereturnsummary),
                excelestimatesalereturnsummary: Boolean(role.excelestimatesalereturnsummary),
                printestimatesalereturnsummary: Boolean(role.printestimatesalereturnsummary),
                pdfestimatesalereturnsummary: Boolean(role.pdfestimatesalereturnsummary),

                //  supplier wise estimate
                allsupplierwiseestimate: Boolean(role.allsupplierwiseestimate),
                checkallsupplierwiseestimate: Boolean(role.checkallsupplierwiseestimate),
                csvsupplierwiseestimate: Boolean(role.csvsupplierwiseestimate),
                excelsupplierwiseestimate: Boolean(role.excelsupplierwiseestimate),
                printsupplierwiseestimate: Boolean(role.printsupplierwiseestimate),
                pdfsupplierwiseestimate: Boolean(role.pdfsupplierwiseestimate),
                //  supplier wise estimate return
                allsupplierwiseestimatereturn: Boolean(role.allsupplierwiseestimatereturn),
                checkallsupplierwiseestimatereturn: Boolean(role.checkallsupplierwiseestimatereturn),
                csvsupplierwiseestimatereturn: Boolean(role.csvsupplierwiseestimatereturn),
                excelsupplierwiseestimatereturn: Boolean(role.excelsupplierwiseestimatereturn),
                printsupplierwiseestimatereturn: Boolean(role.printsupplierwiseestimatereturn),
                pdfsupplierwiseestimatereturn: Boolean(role.pdfsupplierwiseestimatereturn),
                //  Estimate End

                //  stock Management Start

                // Batch Wise Stock Details
                allbatchwisestockdetails: Boolean(role.allbatchwisestockdetails),
                checkallbatchwisestockdetails: Boolean(role.checkallbatchwisestockdetails),
                csvbatchwisestockdetails: Boolean(role.csvbatchwisestockdetails),
                excelbatchwisestockdetails: Boolean(role.excelbatchwisestockdetails),
                printbatchwisestockdetails: Boolean(role.printbatchwisestockdetails),
                pdfbatchwisestockdetails: Boolean(role.pdfbatchwisestockdetails),

                // Batch Wise Stock Report
                allbatchwisestockreport: Boolean(role.allbatchwisestockreport),
                checkallbatchwisestockreport: Boolean(role.checkallbatchwisestockreport),
                csvbatchwisestockreport: Boolean(role.csvbatchwisestockreport),
                excelbatchwisestockreport: Boolean(role.excelbatchwisestockreport),
                printbatchwisestockreport: Boolean(role.printbatchwisestockreport),
                pdfbatchwisestockreport: Boolean(role.pdfbatchwisestockreport),

                // Stock Expiry Report
                allstockexpriyreport: Boolean(role.allstockexpriyreport),
                checkallstockexpriyreport: Boolean(role.checkallstockexpriyreport),
                csvstockexpriyreport: Boolean(role.csvstockexpriyreport),
                excelstockexpriyreport: Boolean(role.excelstockexpriyreport),
                printstockexpriyreport: Boolean(role.printstockexpriyreport),
                pdfstockexpriyreport: Boolean(role.pdfstockexpriyreport),

                // Item Code Update
                allitemcodeupdate: Boolean(role.allitemcodeupdate),
                checkallitemcodeupdate: Boolean(role.checkallitemcodeupdate),
                csvitemcodeupdate: Boolean(role.csvitemcodeupdate),
                excelitemcodeupdate: Boolean(role.excelitemcodeupdate),
                printitemcodeupdate: Boolean(role.printitemcodeupdate),
                pdfitemcodeupdate: Boolean(role.pdfitemcodeupdate),

                // Item Wise Stock Report
                allitemwisestockreport: Boolean(role.allitemwisestockreport),
                checkallitemwisestockreport: Boolean(role.checkallitemwisestockreport),
                csvitemwisestockreport: Boolean(role.csvitemwisestockreport),
                excelitemwisestockreport: Boolean(role.excelitemwisestockreport),
                printitemwisestockreport: Boolean(role.printitemwisestockreport),
                pdfitemwisestockreport: Boolean(role.pdfitemwisestockreport),

                // Manual Stock Entry
                allmanualstockentry: Boolean(role.allmanualstockentry),
                checkallmanualstockentry: Boolean(role.checkallmanualstockentry),
                csvmanualstockentry: Boolean(role.csvmanualstockentry),
                excelmanualstockentry: Boolean(role.excelmanualstockentry),
                printmanualstockentry: Boolean(role.printmanualstockentry),
                pdfmanualstockentry: Boolean(role.pdfmanualstockentry),

                // Manufacture Update
                allmanufactureupdate: Boolean(role.allmanufactureupdate),
                checkallmanufactureupdate: Boolean(role.checkallmanufactureupdate),
                umanufactureupdate: Boolean(role.umanufactureupdate),
                csvmanufactureupdate: Boolean(role.csvmanufactureupdate),
                excelmanufactureupdate: Boolean(role.excelmanufactureupdate),
                printmanufactureupdate: Boolean(role.printmanufactureupdate),
                pdfmanufactureupdate: Boolean(role.pdfmanufactureupdate),

                // Minimum Wise Stock Report
                allminimumwisestockreport: Boolean(role.allminimumwisestockreport),
                checkallminimumwisestockreport: Boolean(role.checkallminimumwisestockreport),
                uminimumwisestockreport: Boolean(role.uminimumwisestockreport),
                csvminimumwisestockreport: Boolean(role.csvminimumwisestockreport),
                excelminimumwisestockreport: Boolean(role.excelminimumwisestockreport),
                printminimumwisestockreport: Boolean(role.printminimumwisestockreport),
                pdfminimumwisestockreport: Boolean(role.pdfminimumwisestockreport),

                //  Nile Stock Report
                allnilestockreport: Boolean(role.allnilestockreport),
                checkallnilestockreport: Boolean(role.checkallnilestockreport),
                csvnilestockreport: Boolean(role.csvnilestockreport),
                excelnilestockreport: Boolean(role.excelnilestockreport),
                printnilestockreport: Boolean(role.printnilestockreport),
                pdfnilestockreport: Boolean(role.pdfnilestockreport),

                //  Non Moving Stock Report
                allnonmovingstockreport: Boolean(role.allnonmovingstockreport),
                checkallnonmovingstockreport: Boolean(role.checkallnonmovingstockreport),
                csvnonmovingstockreport: Boolean(role.csvnonmovingstockreport),
                excelnonmovingstockreport: Boolean(role.excelnonmovingstockreport),
                printnonmovingstockreport: Boolean(role.printnonmovingstockreport),
                pdfnonmovingstockreport: Boolean(role.pdfnonmovingstockreport),

                //  Pack Quality Update
                allpackqualityupdate: Boolean(role.allpackqualityupdate),
                checkallpackqualityupdate: Boolean(role.checkallpackqualityupdate),
                upackqualityupdate: Boolean(role.upackqualityupdate),
                excelpackqualityupdate: Boolean(role.excelpackqualityupdate),
                csvpackqualityupdate: Boolean(role.csvpackqualityupdate),
                printpackqualityupdate: Boolean(role.printpackqualityupdate),
                pdfpackqualityupdate: Boolean(role.pdfpackqualityupdate),

                //  Stock Expiry Report
                allstockexpiryreport: Boolean(role.allstockexpiryreport),
                checkallstockexpiryreport: Boolean(role.checkallstockexpiryreport),
                excelstockexpiryreport: Boolean(role.excelstockexpiryreport),
                csvstockexpiryreport: Boolean(role.csvstockexpiryreport),
                printstockexpiryreport: Boolean(role.printstockexpiryreport),
                pdfstockexpiryreport: Boolean(role.pdfstockexpiryreport),

                //  Stock Over View Report
                allstockoverviewreport: Boolean(role.allstockoverviewreport),
                checkallstockoverviewreport: Boolean(role.checkallstockoverviewreport),
                excelstockoverviewreport: Boolean(role.excelstockoverviewreport),
                csvstockoverviewreport: Boolean(role.csvstockoverviewreport),
                printstockoverviewreport: Boolean(role.printstockoverviewreport),
                pdfstockoverviewreport: Boolean(role.pdfstockoverviewreport),

                // Stock Update
                allstockupdate: Boolean(role.allstockupdate),
                checkallstockupdate: Boolean(role.checkallstockupdate),
                excelstockupdate: Boolean(role.excelstockupdate),
                csvstockupdate: Boolean(role.csvstockupdate),
                printstockupdate: Boolean(role.printstockupdate),
                pdfstockupdate: Boolean(role.pdfstockupdate),


                //  stock Management End

                //  Sell start
                //  Draft
                allposdraft: Boolean(role.allposdraft),
                checkallposdraft: Boolean(role.checkallposdraft),
                vposdraft: Boolean(role.vposdraft),
                aposdraft: Boolean(role.aposdraft),
                eposdraft: Boolean(role.eposdraft),
                dposdraft: Boolean(role.dposdraft),
                csvposdraft: Boolean(role.csvposdraft),
                excelposdraft: Boolean(role.excelposdraft),
                printposdraft: Boolean(role.printposdraft),
                pdfposdraft: Boolean(role.pdfposdraft),
                //  POS
                allposlist: Boolean(role.allposlist),
                checkallposlist: Boolean(role.checkallposlist),
                aposlist: Boolean(role.aposlist),
                eposlist: Boolean(role.eposlist),
                vposlist: Boolean(role.vposlist),
                dposlist: Boolean(role.dposlist),
                csvposlist: Boolean(role.csvposlist),
                excelposlist: Boolean(role.excelposlist),
                printposlist: Boolean(role.printposlist),
                pdfposlist: Boolean(role.pdfposlist),
                //  Quotation
                allposquotation: Boolean(role.allposquotation),
                checkallposquotation: Boolean(role.checkallposquotation),
                aposquotation: Boolean(role.aposquotation),
                vposquotation: Boolean(role.vposquotation),
                eposquotation: Boolean(role.eposquotation),
                dposquotation: Boolean(role.dposquotation),
                csvposquotation: Boolean(role.csvposquotation),
                excelposquotation: Boolean(role.excelposquotation),
                printposquotation: Boolean(role.printposquotation),
                pdfposquotation: Boolean(role.pdfposquotation),
                //  area wise sales
                allareawisesales: Boolean(role.allareawisesales),
                checkallareawisesales: Boolean(role.checkallareawisesales),
                csvareawisesales: Boolean(role.csvareawisesales),
                excelareawisesales: Boolean(role.excelareawisesales),
                printareawisesales: Boolean(role.printareawisesales),
                pdfareawisesales: Boolean(role.pdfareawisesales),
                //  category wise sales
                allcategorywisesales: Boolean(role.allcategorywisesales),
                checkallcategorywisesales: Boolean(role.checkallcategorywisesales),
                csvcategorywisesales: Boolean(role.csvcategorywisesales),
                excelcategorywisesales: Boolean(role.excelcategorywisesales),
                printcategorywisesales: Boolean(role.printcategorywisesales),
                pdfcategorywisesales: Boolean(role.pdfcategorywisesales),
                //  day wise sales
                alldaywisesales: Boolean(role.alldaywisesales),
                checkalldaywisesales: Boolean(role.checkalldaywisesales),
                csvdaywisesales: Boolean(role.csvdaywisesales),
                exceldaywisesales: Boolean(role.exceldaywisesales),
                printdaywisesales: Boolean(role.printdaywisesales),
                pdfdaywisesales: Boolean(role.pdfdaywisesales),
                //  diagnosis wise sales
                alldiagnosiswisesales: Boolean(role.alldiagnosiswisesales),
                checkalldiagnosiswisesales: Boolean(role.checkalldiagnosiswisesales),
                csvdiagnosiswisesales: Boolean(role.csvdiagnosiswisesales),
                exceldiagnosiswisesales: Boolean(role.exceldiagnosiswisesales),
                printdiagnosiswisesales: Boolean(role.printdiagnosiswisesales),
                pdfdiagnosiswisesales: Boolean(role.pdfdiagnosiswisesales),
                //  diagnosis wise sales summary
                alldiagnosiswisesalessummary: Boolean(role.alldiagnosiswisesalessummary),
                checkalldiagnosiswisesalessummary: Boolean(role.checkalldiagnosiswisesalessummary),
                csvdiagnosiswisesalessummary: Boolean(role.csvdiagnosiswisesalessummary),
                exceldiagnosiswisesalessummary: Boolean(role.exceldiagnosiswisesalessummary),
                printdiagnosiswisesalessummary: Boolean(role.printdiagnosiswisesalessummary),
                pdfdiagnosiswisesalessummary: Boolean(role.pdfdiagnosiswisesalessummary),
                //  drug register
                allselldrugregister: Boolean(role.allselldrugregister),
                checkallselldrugregister: Boolean(role.checkallselldrugregister),
                csvselldrugregister: Boolean(role.csvselldrugregister),
                excelselldrugregister: Boolean(role.excelselldrugregister),
                printselldrugregister: Boolean(role.printselldrugregister),
                pdfselldrugregister: Boolean(role.pdfselldrugregister),
                //  dr wise sale report
                alldrwisesalereport: Boolean(role.alldrwisesalereport),
                checkalldrwisesalereport: Boolean(role.checkalldrwisesalereport),
                csvdrwisesalereport: Boolean(role.csvdrwisesalereport),
                exceldrwisesalereport: Boolean(role.exceldrwisesalereport),
                printdrwisesalereport: Boolean(role.printdrwisesalereport),
                pdfdrwisesalereport: Boolean(role.pdfdrwisesalereport),
                //  dr wise sale
                alldrwisesale: Boolean(role.alldrwisesale),
                checkalldrwisesale: Boolean(role.checkalldrwisesale),
                csvdrwisesale: Boolean(role.csvdrwisesale),
                exceldrwisesale: Boolean(role.exceldrwisesale),
                printdrwisesale: Boolean(role.printdrwisesale),
                pdfdrwisesale: Boolean(role.pdfdrwisesale),
                //  item wise sale return
                allitemwisesalesreturn: Boolean(role.allitemwisesalesreturn),
                checkallitemwisesalesreturn: Boolean(role.checkallitemwisesalesreturn),
                csvitemwisesalesreturn: Boolean(role.csvitemwisesalesreturn),
                excelitemwisesalesreturn: Boolean(role.excelitemwisesalesreturn),
                printitemwisesalesreturn: Boolean(role.printitemwisesalesreturn),
                pdfitemwisesalesreturn: Boolean(role.pdfitemwisesalesreturn),
                //  item wise sales
                allitemwisesales: Boolean(role.allitemwisesales),
                checkallitemwisesales: Boolean(role.checkallitemwisesales),
                csvitemwisesales: Boolean(role.csvitemwisesales),
                excelitemwisesales: Boolean(role.excelitemwisesales),
                printitemwisesales: Boolean(role.printitemwisesales),
                pdfitemwisesales: Boolean(role.pdfitemwisesales),
                //  manufacture wise sales return
                allmanufacturewisesalesreturn: Boolean(role.allmanufacturewisesalesreturn),
                checkallmanufacturewisesalesreturn: Boolean(role.checkallmanufacturewisesalesreturn),
                csvmanufacturewisesalesreturn: Boolean(role.csvmanufacturewisesalesreturn),
                excelmanufacturewisesalesreturn: Boolean(role.excelmanufacturewisesalesreturn),
                printmanufacturewisesalesreturn: Boolean(role.printmanufacturewisesalesreturn),
                pdfmanufacturewisesalesreturn: Boolean(role.pdfmanufacturewisesalesreturn),
                //  manufacture wise sales
                allmanufacturewisesales: Boolean(role.allmanufacturewisesales),
                checkallmanufacturewisesales: Boolean(role.checkallmanufacturewisesales),
                csvmanufacturewisesales: Boolean(role.csvmanufacturewisesales),
                excelmanufacturewisesales: Boolean(role.excelmanufacturewisesales),
                printmanufacturewisesales: Boolean(role.printmanufacturewisesales),
                pdfmanufacturewisesales: Boolean(role.pdfmanufacturewisesales),
                //  molecule wise sales
                allmoleculewisesales: Boolean(role.allmoleculewisesales),
                checkallmoleculewisesales: Boolean(role.checkallmoleculewisesales),
                csvmoleculewisesales: Boolean(role.csvmoleculewisesales),
                excelmoleculewisesales: Boolean(role.excelmoleculewisesales),
                printmoleculewisesales: Boolean(role.printmoleculewisesales),
                pdfmoleculewisesales: Boolean(role.pdfmoleculewisesales),
                //  patient wise sales
                allpatientwisesales: Boolean(role.allpatientwisesales),
                checkallpatientwisesales: Boolean(role.checkallpatientwisesales),
                csvpatientwisesales: Boolean(role.csvpatientwisesales),
                excelpatientwisesales: Boolean(role.excelpatientwisesales),
                printpatientwisesales: Boolean(role.printpatientwisesales),
                pdfpatientwisesales: Boolean(role.pdfpatientwisesales),
                //  patient wise sales report
                allpatientwisesalesreport: Boolean(role.allpatientwisesalesreport),
                checkallpatientwisesalesreport: Boolean(role.checkallpatientwisesalesreport),
                csvpatientwisesalesreport: Boolean(role.csvpatientwisesalesreport),
                excelpatientwisesalesreport: Boolean(role.excelpatientwisesalesreport),
                printpatientwisesalesreport: Boolean(role.printpatientwisesalesreport),
                pdfpatientwisesalesreport: Boolean(role.pdfpatientwisesalesreport),
                //  sales return details
                allsalesreturndetails: Boolean(role.allsalesreturndetails),
                checkallsalesreturndetails: Boolean(role.checkallsalesreturndetails),
                csvsalesreturndetails: Boolean(role.csvsalesreturndetails),
                excelsalesreturndetails: Boolean(role.excelsalesreturndetails),
                printsalesreturndetails: Boolean(role.printsalesreturndetails),
                pdfsalesreturndetails: Boolean(role.pdfsalesreturndetails),
                //  sales return report
                allsalesreturnreport: Boolean(role.allsalesreturnreport),
                checkallsalesreturnreport: Boolean(role.checkallsalesreturnreport),
                csvsalesreturnreport: Boolean(role.csvsalesreturnreport),
                excelsalesreturnreport: Boolean(role.excelsalesreturnreport),
                printsalesreturnreport: Boolean(role.printsalesreturnreport),
                pdfsalesreturnreport: Boolean(role.pdfsalesreturnreport),
                //  sales return summary
                allsalesreturnsummary: Boolean(role.allsalesreturnsummary),
                checkallsalesreturnsummary: Boolean(role.checkallsalesreturnsummary),
                csvsalesreturnsummary: Boolean(role.csvsalesreturnsummary),
                excelsalesreturnsummary: Boolean(role.excelsalesreturnsummary),
                printsalesreturnsummary: Boolean(role.printsalesreturnsummary),
                pdfsalesreturnsummary: Boolean(role.pdfsalesreturnsummary),
                //  sales report
                allsalesreport: Boolean(role.allsalesreport),
                checkallsalesreport: Boolean(role.checkallsalesreport),
                csvsalesreport: Boolean(role.csvsalesreport),
                excelsalesreport: Boolean(role.excelsalesreport),
                printsalesreport: Boolean(role.printsalesreport),
                pdfsalesreport: Boolean(role.pdfsalesreport),
                //  sales summary
                allsalessummary: Boolean(role.allsalessummary),
                checkallsalessummary: Boolean(role.checkallsalessummary),
                csvsalessummary: Boolean(role.csvsalessummary),
                excelsalessummary: Boolean(role.excelsalessummary),
                printsalessummary: Boolean(role.printsalessummary),
                pdfsalessummary: Boolean(role.pdfsalessummary),
                //  schedule wise sales
                allschedulewisesales: Boolean(role.allschedulewisesales),
                checkallschedulewisesales: Boolean(role.checkallschedulewisesales),
                csvschedulewisesales: Boolean(role.csvschedulewisesales),
                excelschedulewisesales: Boolean(role.excelschedulewisesales),
                printschedulewisesales: Boolean(role.printschedulewisesales),
                pdfschedulewisesales: Boolean(role.pdfschedulewisesales),
                //  sell return
                allsellreturn: Boolean(role.allsellreturn),
                checkallsellreturn: Boolean(role.checkallsellreturn),
                csvsellreturn: Boolean(role.csvsellreturn),
                excelsellreturn: Boolean(role.excelsellreturn),
                printsellreturn: Boolean(role.printsellreturn),
                pdfsellreturn: Boolean(role.pdfsellreturn),
                esellreturn: Boolean(role.esellreturn),
                vsellreturn: Boolean(role.vsellreturn),
                dsellreturn: Boolean(role.dsellreturn),
                //  supplier wise sales return
                allsupplierwisesalesreturn: Boolean(role.allsupplierwisesalesreturn),
                checkallsupplierwisesalesreturn: Boolean(role.checkallsupplierwisesalesreturn),
                csvsupplierwisesalesreturn: Boolean(role.csvsupplierwisesalesreturn),
                excelsupplierwisesalesreturn: Boolean(role.excelsupplierwisesalesreturn),
                printsupplierwisesalesreturn: Boolean(role.printsupplierwisesalesreturn),
                pdfsupplierwisesalesreturn: Boolean(role.pdfsupplierwisesalesreturn),
                //  supplier wise sales
                allsupplierwisesales: Boolean(role.allsupplierwisesales),
                checkallsupplierwisesales: Boolean(role.checkallsupplierwisesales),
                excelsupplierwisesales: Boolean(role.excelsupplierwisesales),
                csvsupplierwisesales: Boolean(role.csvsupplierwisesales),
                printsupplierwisesales: Boolean(role.printsupplierwisesales),
                pdfsupplierwisesales: Boolean(role.pdfsupplierwisesales),
                //  Sell end

                // Account Module Start
                accountmanagement: Boolean(role.accountmanagement),

                // Account Creation 
                allaccountcreation: Boolean(role.allaccountcreation),
                checkallaccountcreation: Boolean(role.checkallaccountcreation),
                excelaccountcreation: Boolean(role.excelaccountcreation),
                csvaccountcreation: Boolean(role.csvaccountcreation),
                printaccountcreation: Boolean(role.printaccountcreation),
                pdfaccountcreation: Boolean(role.pdfaccountcreation),
                aaccountcreation: Boolean(role.aaccountcreation),
                eaccountcreation: Boolean(role.eaccountcreation),
                daccountcreation: Boolean(role.daccountcreation),

                // Group Creation 
                allgroupcreation: Boolean(role.allgroupcreation),
                checkallgroupcreation: Boolean(role.checkallgroupcreation),
                excelgroupcreation: Boolean(role.excelgroupcreation),
                csvgroupcreation: Boolean(role.csvgroupcreation),
                printgroupcreation: Boolean(role.printgroupcreation),
                pdfgroupcreation: Boolean(role.pdfgroupcreation),
                agroupcreation: Boolean(role.agroupcreation),
                egroupcreation: Boolean(role.egroupcreation),
                dgroupcreation: Boolean(role.dgroupcreation),

                // Voucher Creation 
                allvoucherlist: Boolean(role.allvoucherlist),
                checkallvoucherlist: Boolean(role.checkallvoucherlist),
                excelvoucherlist: Boolean(role.excelvoucherlist),
                csvvoucherlist: Boolean(role.csvvoucherlist),
                printvoucherlist: Boolean(role.printvoucherlist),
                pdfvoucherlist: Boolean(role.pdfvoucherlist),
                addvoucherlist: Boolean(role.addvoucherlist),
                evoucherlist: Boolean(role.evoucherlist),
                dvoucherlist: Boolean(role.dvoucherlist),
                vvoucherlist: Boolean(role.vvoucherlist),

                // Bank Statement
                allbankstatement: Boolean(role.allbankstatement),
                checkallbankstatement: Boolean(role.checkallbankstatement),
                csvbankstatement: Boolean(role.csvbankstatement),
                excelbankstatement: Boolean(role.excelbankstatement),
                printbankstatement: Boolean(role.printbankstatement),
                pdfbankstatement: Boolean(role.pdfbankstatement),

                // Cash Book Register Report
                allcashbookregreport: Boolean(role.allcashbookregreport),
                checkallcashbookregreport: Boolean(role.checkallcashbookregreport),
                csvcashbookregreport: Boolean(role.csvcashbookregreport),
                excelcashbookregreport: Boolean(role.excelcashbookregreport),
                printcashbookregreport: Boolean(role.printcashbookregreport),
                pdfcashbookregreport: Boolean(role.pdfcashbookregreport),

                // Cash Book Report
                allcashbookreport: Boolean(role.allcashbookreport),
                checkallcashbookreport: Boolean(role.checkallcashbookreport),
                csvcashbookreport: Boolean(role.csvcashbookreport),
                excelcashbookreport: Boolean(role.excelcashbookreport),
                printcashbookreport: Boolean(role.printcashbookreport),
                pdfcashbookreport: Boolean(role.pdfcashbookreport),

                // Group Summary
                allgroupsummary: Boolean(role.allgroupsummary),
                checkallgroupsummary: Boolean(role.checkallgroupsummary),
                csvgroupsummary: Boolean(role.csvgroupsummary),
                excelgroupsummary: Boolean(role.excelgroupsummary),
                printgroupsummary: Boolean(role.printgroupsummary),
                pdfgroupsummary: Boolean(role.pdfgroupsummary),

                // Profit and Loss Account
                allprofitlossaccount: Boolean(role.allprofitlossaccount),
                checkallprofitlossaccount: Boolean(role.checkallprofitlossaccount),
                printprofitlossaccount: Boolean(role.printprofitlossaccount),
                pdfprofitlossaccount: Boolean(role.pdfprofitlossaccount),
                alltrailbalance: Boolean(role.alltrailbalance),
                checkalltrailbalance: Boolean(role.checkalltrailbalance),
                csvtrailbalance: Boolean(role.csvtrailbalance),
                exceltrailbalance: Boolean(role.exceltrailbalance),
                printtrailbalance: Boolean(role.printtrailbalance),
                pdftrailbalance: Boolean(role.pdftrailbalance),

                // Bank Management 
                bankmanagement: Boolean(role.bankmanagement),

                // Bank Master
                allbankmaster: Boolean(role.allbankmaster),
                checkallbankmaster: Boolean(role.checkallbankmaster),
                csvbankmaster: Boolean(role.csvbankmaster),
                excelbankmaster: Boolean(role.excelbankmaster),
                printbankmaster: Boolean(role.printbankmaster),
                pdfbankmaster: Boolean(role.pdfbankmaster),
                viewbankmaster: Boolean(role.viewbankmaster),
                editbankmaster: Boolean(role.editbankmaster),
                deletebankmaster: Boolean(role.deletebankmaster),

                // Bank Master Report
                allbankmasterreport: Boolean(role.allbankmasterreport),
                checkallbankmasterreport: Boolean(role.checkallbankmasterreport),
                csvbankmasterreport: Boolean(role.csvbankmasterreport),
                excelbankmasterreport: Boolean(role.excelbankmasterreport),
                printbankmasterreport: Boolean(role.printbankmasterreport),
                pdfbankmasterreport: Boolean(role.pdfbankmasterreport),

                // Bank Book Report
                allbankbookreport: Boolean(role.allbankbookreport),
                checkallbankbookreport: Boolean(role.checkallbankbookreport),
                csvbankbookreport: Boolean(role.csvbankbookreport),
                excelbankbookreport: Boolean(role.excelbankbookreport),
                printbankbookreport: Boolean(role.printbankbookreport),
                pdfbankbookreport: Boolean(role.pdfbankbookreport),


                // Settings Managements Start
                allalpharate: Boolean(role.allalpharate),
                checkallalpharate: Boolean(role.checkallalpharate),
                valpharate: Boolean(role.valpharate),
                aalpharate: Boolean(role.aalpharate),

                //  Business Location
                allbusinesslocation: Boolean(role.allbusinesslocation),
                checkallbusinesslocation: Boolean(role.checkallbusinesslocation),
                activatebusinesslocation: Boolean(role.activatebusinesslocation),
                abusinesslocation: Boolean(role.abusinesslocation),
                ebusinesslocation: Boolean(role.ebusinesslocation),
                dbusinesslocation: Boolean(role.dbusinesslocation),
                excelbusinesslocation: Boolean(role.excelbusinesslocation),
                csvbusinesslocation: Boolean(role.csvbusinesslocation),
                printbusinesslocation: Boolean(role.printbusinesslocation),
                pdfbusinesslocation: Boolean(role.pdfbusinesslocation),

                // Payment Integration
                allpaymentintegration: Boolean(role.allpaymentintegration),
                checkallpaymentintegration: Boolean(role.checkallpaymentintegration),
                vpaymentintegration: Boolean(role.vpaymentintegration),
                apaymentintegration: Boolean(role.apaymentintegration),
                epaymentintegration: Boolean(role.epaymentintegration),
                dpaymentintegration: Boolean(role.dpaymentintegration),
                excelpaymentintegration: Boolean(role.excelpaymentintegration),
                csvpaymentintegration: Boolean(role.csvpaymentintegration),
                pdfpaymentintegration: Boolean(role.pdfpaymentintegration),

                // Tax Rate
                alltaxrate: Boolean(role.alltaxrate),
                checkalltaxrate: Boolean(role.checkalltaxrate),
                ataxrate: Boolean(role.ataxrate),
                etaxrate: Boolean(role.etaxrate),
                dtaxrate: Boolean(role.dtaxrate),
                exceltaxrate: Boolean(role.exceltaxrate),
                csvtaxrate: Boolean(role.csvtaxrate),
                printtaxrate: Boolean(role.printtaxrate),
                pdftaxrate: Boolean(role.pdftaxrate),

                // Taxrate Group
                alltaxrategroup: Boolean(role.alltaxrategroup),
                checkalltaxrategroup: Boolean(role.checkalltaxrategroup),
                ataxrategroup: Boolean(role.ataxrategroup),
                dtaxrategroup: Boolean(role.dtaxrategroup),
                exceltaxrategroup: Boolean(role.exceltaxrategroup),
                csvtaxrategroup: Boolean(role.csvtaxrategroup),
                printtaxrategroup: Boolean(role.printtaxrategroup),
                pdftaxrategroup: Boolean(role.pdftaxrategroup),

                //  Taxrate Hsn
                alltaxratehsn: Boolean(role.alltaxratehsn),
                checkalltaxratehsn: Boolean(role.checkalltaxratehsn),
                ataxratehsn: Boolean(role.ataxratehsn),
                dtaxratehsn: Boolean(role.dtaxratehsn),
                exceltaxratehsn: Boolean(role.exceltaxratehsn),
                csvtaxratehsn: Boolean(role.csvtaxratehsn),
                printtaxratehsn: Boolean(role.printtaxratehsn),
                pdftaxratehsn: Boolean(role.pdftaxratehsn),

                //  Gstr Purchase
                allgstrpurchase: Boolean(role.allgstrpurchase),
                checkallgstrpurchase: Boolean(role.checkallgstrpurchase),
                excelgstrpurchase: Boolean(role.excelgstrpurchase),
                csvgstrpurchase: Boolean(role.csvgstrpurchase),
                printgstrpurchase: Boolean(role.printgstrpurchase),
                pdfgstrpurchase: Boolean(role.pdfgstrpurchase),

                //  Gstr Purchase Return
                allgstrpurchasereturn: Boolean(role.allgstrpurchasereturn),
                checkallgstrpurchasereturn: Boolean(role.checkallgstrpurchasereturn),
                excelgstrpurchasereturn: Boolean(role.excelgstrpurchasereturn),
                csvgstrpurchasereturn: Boolean(role.csvgstrpurchasereturn),
                printgstrpurchasereturn: Boolean(role.printgstrpurchasereturn),
                pdfgstrpurchasereturn: Boolean(role.pdfgstrpurchasereturn),

                //  Gstr Retail Sales Return
                allgstrretailsalesret: Boolean(role.allgstrretailsalesret),
                checkallgstrretailsalesret: Boolean(role.checkallgstrretailsalesret),
                excelgstrretailsalesret: Boolean(role.excelgstrretailsalesret),
                csvgstrretailsalesret: Boolean(role.csvgstrretailsalesret),
                printgstrretailsalesret: Boolean(role.printgstrretailsalesret),
                pdfgstrretailsalesret: Boolean(role.pdfgstrretailsalesret),

                //  Gstr Sales Retail
                allgstrsalesretail: Boolean(role.allgstrsalesretail),
                checkallgstrsalesretail: Boolean(role.checkallgstrsalesretail),
                excelgstrsalesretail: Boolean(role.excelgstrsalesretail),
                csvgstrsalesretail: Boolean(role.csvgstrsalesretail),
                printgstrsalesretail: Boolean(role.printgstrsalesretail),
                pdfgstrsalesretail: Boolean(role.pdfgstrsalesretail),

                //  Gstr Whole Sales
                allgstrwholesale: Boolean(role.allgstrwholesale),
                checkallgstrwholesale: Boolean(role.checkallgstrwholesale),
                excelgstrwholesale: Boolean(role.excelgstrwholesale),
                csvgstrwholesale: Boolean(role.csvgstrwholesale),
                printgstrwholesale: Boolean(role.printgstrwholesale),
                pdfgstrwholesale: Boolean(role.pdfgstrwholesale),

                //  Gstr Whole Sales Return
                allgstrwholesaleret: Boolean(role.allgstrwholesaleret),
                checkallgstrwholesaleret: Boolean(role.checkallgstrwholesaleret),
                excelgstrwholesaleret: Boolean(role.excelgstrwholesaleret),
                csvgstrwholesaleret: Boolean(role.csvgstrwholesaleret),
                printgstrwholesaleret: Boolean(role.printgstrwholesaleret),
                pdfgstrwholesaleret: Boolean(role.pdfgstrwholesaleret),

                //  Hsn Wise Report Retail
                allhsnwisereportretails: Boolean(role.allhsnwisereportretails),
                checkallhsnwisereportretails: Boolean(role.checkallhsnwisereportretails),
                excelhsnwisereportretails: Boolean(role.excelhsnwisereportretails),
                csvhsnwisereportretails: Boolean(role.csvhsnwisereportretails),
                printhsnwisereportretails: Boolean(role.printhsnwisereportretails),
                pdfhsnwisereportretails: Boolean(role.pdfhsnwisereportretails),

                //  Hsn Wise Report Retail Sales Return
                allhsnwiserepretailsalesret: Boolean(role.allhsnwiserepretailsalesret),
                checkallhsnwiserepretailsalesret: Boolean(role.checkallhsnwiserepretailsalesret),
                excelhsnwiserepretailsalesret: Boolean(role.excelhsnwiserepretailsalesret),
                csvhsnwiserepretailsalesret: Boolean(role.csvhsnwiserepretailsalesret),
                printhsnwiserepretailsalesret: Boolean(role.printhsnwiserepretailsalesret),
                pdfhsnwiserepretailsalesret: Boolean(role.pdfhsnwiserepretailsalesret),

                //  Hsn Wise Report Whole Sales
                allhsnwiserepwholesales: Boolean(role.allhsnwiserepwholesales),
                checkallhsnwiserepwholesales: Boolean(role.checkallhsnwiserepwholesales),
                excelhsnwiserepwholesales: Boolean(role.excelhsnwiserepwholesales),
                csvhsnwiserepwholesales: Boolean(role.csvhsnwiserepwholesales),
                printhsnwiserepwholesales: Boolean(role.printhsnwiserepwholesales),
                pdfhsnwiserepwholesales: Boolean(role.pdfhsnwiserepwholesales),

                //  Hsn Wise Report Whole Sales Return
                allhsnwiserepwholesalereturn: Boolean(role.allhsnwiserepwholesalereturn),
                checkallhsnwiserepwholesalereturn: Boolean(role.checkallhsnwiserepwholesalereturn),
                excelhsnwiserepwholesalereturn: Boolean(role.excelhsnwiserepwholesalereturn),
                csvhsnwiserepwholesalereturn: Boolean(role.csvhsnwiserepwholesalereturn),
                printhsnwiserepwholesalereturn: Boolean(role.printhsnwiserepwholesalereturn),
                pdfhsnwiserepwholesalereturn: Boolean(role.pdfhsnwiserepwholesalereturn),

                //  Hsn Wise Report Whole Sales Return
                allhsnwisesummarypurchase: Boolean(role.allhsnwisesummarypurchase),
                checkallhsnwisesummarypurchase: Boolean(role.checkallhsnwisesummarypurchase),
                excelhsnwisesummarypurchase: Boolean(role.excelhsnwisesummarypurchase),
                csvhsnwisesummarypurchase: Boolean(role.csvhsnwisesummarypurchase),
                printhsnwisesummarypurchase: Boolean(role.printhsnwisesummarypurchase),
                pdfhsnwisesummarypurchase: Boolean(role.pdfhsnwisesummarypurchase),

                // Settings Management End......

                // Report Management Start.....

                // Bills Wise Profit Estimate
                allbillwiseprofitestimate: Boolean(role.allbillwiseprofitestimate),
                checkallbillwiseprofitestimate: Boolean(role.checkallbillwiseprofitestimate),
                csvbillwiseprofitestimate: Boolean(role.csvbillwiseprofitestimate),
                excelbillwiseprofitestimate: Boolean(role.excelbillwiseprofitestimate),
                printbillwiseprofitestimate: Boolean(role.printbillwiseprofitestimate),
                pdfbillwiseprofitestimate: Boolean(role.pdfbillwiseprofitestimate),

                // Bills Wise Profit Retail
                allbillwiseprofitretail: Boolean(role.allbillwiseprofitretail),
                checkallbillwiseprofitretail: Boolean(role.checkallbillwiseprofitretail),
                csvbillwiseprofitretail: Boolean(role.csvbillwiseprofitretail),
                excelbillwiseprofitretail: Boolean(role.excelbillwiseprofitretail),
                printbillwiseprofitretail: Boolean(role.printbillwiseprofitretail),
                pdfbillwiseprofitretail: Boolean(role.pdfbillwiseprofitretail),

                // Bills Wise Profit Whole Sale
                allbillwiseprofitwholesale: Boolean(role.allbillwiseprofitwholesale),
                checkallbillwiseprofitwholesale: Boolean(role.checkallbillwiseprofitwholesale),
                csvbillwiseprofitwholesale: Boolean(role.csvbillwiseprofitwholesale),
                excelbillwiseprofitwholesale: Boolean(role.excelbillwiseprofitwholesale),
                printbillwiseprofitwholesale: Boolean(role.printbillwiseprofitwholesale),
                pdfbillwiseprofitwholesale: Boolean(role.pdfbillwiseprofitwholesale),

                // Customer Comparison
                allcustomercomparison: Boolean(role.allcustomercomparison),
                checkallcustomercomparison: Boolean(role.checkallcustomercomparison),
                csvcustomercomparison: Boolean(role.csvcustomercomparison),
                excelcustomercomparison: Boolean(role.excelcustomercomparison),
                printcustomercomparison: Boolean(role.printcustomercomparison),
                pdfcustomercomparison: Boolean(role.pdfcustomercomparison),

                // Customer Wise Profit Estimate
                allcustomerwiseprofitestimate: Boolean(role.allcustomerwiseprofitestimate),
                checkallcustomerwiseprofitestimate: Boolean(role.checkallcustomerwiseprofitestimate),
                csvcustomerwiseprofitestimate: Boolean(role.csvcustomerwiseprofitestimate),
                excelcustomerwiseprofitestimate: Boolean(role.excelcustomerwiseprofitestimate),
                printcustomerwiseprofitestimate: Boolean(role.printcustomerwiseprofitestimate),
                pdfcustomerwiseprofitestimate: Boolean(role.pdfcustomerwiseprofitestimate),

                // Customer Wise Profit Whole Sale
                allcustomerwiseprofitwholesale: Boolean(role.allcustomerwiseprofitwholesale),
                checkallcustomerwiseprofitwholesale: Boolean(role.checkallcustomerwiseprofitwholesale),
                csvcustomerwiseprofitwholesale: Boolean(role.csvcustomerwiseprofitwholesale),
                excelcustomerwiseprofitwholesale: Boolean(role.excelcustomerwiseprofitwholesale),
                printcustomerwiseprofitwholesale: Boolean(role.printcustomerwiseprofitwholesale),
                pdfcustomerwiseprofitwholesale: Boolean(role.pdfcustomerwiseprofitwholesale),

                //  Day Wise Profit Estimate
                alldaywiseprofitestimate: Boolean(role.alldaywiseprofitestimate),
                checkalldaywiseprofitestimate: Boolean(role.checkalldaywiseprofitestimate),
                csvdaywiseprofitestimate: Boolean(role.csvdaywiseprofitestimate),
                exceldaywiseprofitestimate: Boolean(role.exceldaywiseprofitestimate),
                printdaywiseprofitestimate: Boolean(role.printdaywiseprofitestimate),
                pdfdaywiseprofitestimate: Boolean(role.pdfdaywiseprofitestimate),

                //  Day Wise Profit Whole Sale
                alldaywiseprofitwholesale: Boolean(role.alldaywiseprofitwholesale),
                checkalldaywiseprofitwholesale: Boolean(role.checkalldaywiseprofitwholesale),
                csvdaywiseprofitwholesale: Boolean(role.csvdaywiseprofitwholesale),
                exceldaywiseprofitwholesale: Boolean(role.exceldaywiseprofitwholesale),
                printdaywiseprofitwholesale: Boolean(role.printdaywiseprofitwholesale),
                pdfdaywiseprofitwholesale: Boolean(role.pdfdaywiseprofitwholesale),

                //  Day Wise Profit Retail
                alldaywiseprofitretail: Boolean(role.alldaywiseprofitretail),
                checkalldaywiseprofitretail: Boolean(role.checkalldaywiseprofitretail),
                csvdaywiseprofitretail: Boolean(role.csvdaywiseprofitretail),
                exceldaywiseprofitretail: Boolean(role.exceldaywiseprofitretail),
                printdaywiseprofitretail: Boolean(role.printdaywiseprofitretail),
                pdfdaywiseprofitretail: Boolean(role.pdfdaywiseprofitretail),

                //  Item Wise Profit Estimate
                allitemwiseprofitestimate: Boolean(role.allitemwiseprofitestimate),
                checkallitemwiseprofitestimate: Boolean(role.checkallitemwiseprofitestimate),
                csvitemwiseprofitestimate: Boolean(role.csvitemwiseprofitestimate),
                excelitemwiseprofitestimate: Boolean(role.excelitemwiseprofitestimate),
                printitemwiseprofitestimate: Boolean(role.printitemwiseprofitestimate),
                pdfitemwiseprofitestimate: Boolean(role.pdfitemwiseprofitestimate),

                //  Item Wise Profit Retail
                allitemwiseprofitretail: Boolean(role.allitemwiseprofitretail),
                checkallitemwiseprofitretail: Boolean(role.checkallitemwiseprofitretail),
                csvitemwiseprofitretail: Boolean(role.csvitemwiseprofitretail),
                excelitemwiseprofitretail: Boolean(role.excelitemwiseprofitretail),
                printitemwiseprofitretail: Boolean(role.printitemwiseprofitretail),
                pdfitemwiseprofitretail: Boolean(role.pdfitemwiseprofitretail),

                //  Item Wise Profit Whole Sale
                allitemwiseprofitwholesale: Boolean(role.allitemwiseprofitwholesale),
                checkallitemwiseprofitwholesale: Boolean(role.checkallitemwiseprofitwholesale),
                csvitemwiseprofitwholesale: Boolean(role.csvitemwiseprofitwholesale),
                excelitemwiseprofitwholesale: Boolean(role.excelitemwiseprofitwholesale),
                printitemwiseprofitwholesale: Boolean(role.printitemwiseprofitwholesale),
                pdfitemwiseprofitwholesale: Boolean(role.pdfitemwiseprofitwholesale),

                // Reference Comparison
                allreferencecomparison: Boolean(role.allreferencecomparison),
                checkallreferencecomparison: Boolean(role.checkallreferencecomparison),
                csvreferencecomparison: Boolean(role.csvreferencecomparison),
                excelreferencecomparison: Boolean(role.excelreferencecomparison),
                printreferencecomparison: Boolean(role.printreferencecomparison),
                pdfreferencecomparison: Boolean(role.pdfreferencecomparison),

                // Reference Summary
                allreferencesummary: Boolean(role.allreferencesummary),
                checkallreferencesummary: Boolean(role.checkallreferencesummary),
                csvreferencesummary: Boolean(role.csvreferencesummary),
                excelreferencesummary: Boolean(role.excelreferencesummary),
                printreferencesummary: Boolean(role.printreferencesummary),
                pdfreferencesummary: Boolean(role.pdfreferencesummary),

                // Report Management End.....

                //  Expance Management Start
                // Expance start 
                expensemanagement: Boolean(role.expensemanagement),
                allexpense: Boolean(role.allexpense),
                checkallexpense: Boolean(role.checkallexpense),
                aexpense: Boolean(role.aexpense),
                eexpense: Boolean(role.eexpense),
                dexpense: Boolean(role.dexpense),
                excelexpense: Boolean(role.excelexpense),
                csvexpense: Boolean(role.csvexpense),
                printexpense: Boolean(role.printexpense),
                pdfexpense: Boolean(role.pdfexpense),
                payexpense: Boolean(role.payexpense),

                // Expance Category
                dallexpensecategoryuser: Boolean(role.dallexpensecategoryuser),
                checkallexpensecategory: Boolean(role.checkallexpensecategory),
                aexpensecategory: Boolean(role.aexpensecategory),
                eexpensecategory: Boolean(role.eexpensecategory),
                dexpensecategory: Boolean(role.dexpensecategory),
                excelexpensecategory: Boolean(role.excelexpensecategory),
                csvexpensecategory: Boolean(role.csvexpensecategory),
                printexpensecategory: Boolean(role.printexpensecategory),
                pdfexpensecategory: Boolean(role.pdfexpensecategory),

            });
            setRole(roles.data);
            toast.success(roles.data.message);
            backPage('/user/role/list');
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchRole();
    }

    return (
        <Box>
            <Headtitle title={'Edit Role'} />
            <form onSubmit={handleSubmit}>
                <Box>
                    <Grid display="flex">
                        <Typography sx={userStyle.HeaderText}>Edit Role</Typography>
                    </Grid>
                </Box>
                <Box sx={userStyle.container}>
                    <Grid container spacing={2} sx={{
                        padding: '40px 20px',
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: '1px solid #b97df0',
                        },
                    }}>
                        <Grid item md={5}>
                            <InputLabel htmlFor="component-outlined">Role Name *</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={role.rolename}
                                    onChange={(e) => { setRole({ ...role, rolename: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={7}></Grid>
                        <Grid item md={12}>
                            <InputLabel sx={{ fontWeight: '600' }}>Permissions</InputLabel>
                        </Grid>

                        {/* User Start............ */}
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.usermanagement} onChange={(e) => setRole({ ...role, usermanagement: !role.usermanagement })} />} label="User management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alluser} onChange={(e) => setRole({ ...role, alluser: !role.alluser })} />} label="User" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalluser} onChange={(e) => userAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vuser} onChange={(e) => setRole({ ...role, vuser: !role.vuser })} />} label="View user" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.auser} onChange={(e) => setRole({ ...role, auser: !role.auser })} />} label="Add user" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.euser} onChange={(e) => setRole({ ...role, euser: !role.euser })} />} label="Edit user" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.duser} onChange={(e) => setRole({ ...role, duser: !role.duser })} />} label="Delete user" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceluser} onChange={(e) => setRole({ ...role, exceluser: !role.exceluser })} />} label="Excel user" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvuser} onChange={(e) => setRole({ ...role, csvuser: !role.csvuser })} />} label="CSV user" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printuser} onChange={(e) => setRole({ ...role, printuser: !role.printuser })} />} label="Print user" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfuser} onChange={(e) => setRole({ ...role, pdfuser: !role.pdfuser })} />} label="Pdf user" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allrole} onChange={(e) => setRole({ ...role, allrole: !role.allrole })} />} label="Role" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallrole} onChange={(e) => roleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.arole} onChange={(e) => setRole({ ...role, arole: !role.arole })} />} label="Add role" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.erole} onChange={(e) => setRole({ ...role, erole: !role.erole })} />} label="Edit role" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.drole} onChange={(e) => setRole({ ...role, drole: !role.drole })} />} label="Delete role" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelrole} onChange={(e) => setRole({ ...role, excelrole: !role.excelrole })} />} label="Excel role" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvrole} onChange={(e) => setRole({ ...role, csvrole: !role.csvrole })} />} label="CSV role" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printrole} onChange={(e) => setRole({ ...role, printrole: !role.printrole })} />} label="Print role" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfrole} onChange={(e) => setRole({ ...role, pdfrole: !role.pdfrole })} />} label="Pdf role" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsalescommissionagent} onChange={(e) => setRole({ ...role, allsalescommissionagent: !role.allsalescommissionagent })} />} label="Sales commission agent" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsalescommissionagent} onChange={(e) => salesCommissionAgentAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.asalescommissionagent} onChange={(e) => setRole({ ...role, asalescommissionagent: !role.asalescommissionagent })} />} label="Add sales commission agent" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.esalescommissionagent} onChange={(e) => setRole({ ...role, esalescommissionagent: !role.esalescommissionagent })} />} label="Edit sales commission agent" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dsalescommissionagent} onChange={(e) => setRole({ ...role, dsalescommissionagent: !role.dsalescommissionagent })} />} label="Delete sales commission agent" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsalescommissionagent} onChange={(e) => setRole({ ...role, excelsalescommissionagent: !role.excelsalescommissionagent })} />} label="Excel sales commission agent" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsalescommissionagent} onChange={(e) => setRole({ ...role, csvsalescommissionagent: !role.csvsalescommissionagent })} />} label="CSV sales commission agent" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsalescommissionagent} onChange={(e) => setRole({ ...role, printsalescommissionagent: !role.printsalescommissionagent })} />} label="Print sales commission agent" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsalescommissionagent} onChange={(e) => setRole({ ...role, pdfsalescommissionagent: !role.pdfsalescommissionagent })} />} label="Pdf sales commission agent" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldepartment} onChange={(e) => setRole({ ...role, alldepartment: !role.alldepartment })} />} label="Department" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldepartment} onChange={(e) => departmentAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.adepartment} onChange={(e) => setRole({ ...role, adepartment: !role.adepartment })} />} label="Add Department" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.edepartment} onChange={(e) => setRole({ ...role, edepartment: !role.edepartment })} />} label="Edit Department" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ddepartment} onChange={(e) => setRole({ ...role, ddepartment: !role.ddepartment })} />} label="Delete Department" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldepartment} onChange={(e) => setRole({ ...role, exceldepartment: !role.exceldepartment })} />} label="Excel Department" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdepartment} onChange={(e) => setRole({ ...role, csvdepartment: !role.csvdepartment })} />} label="Csv Department" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdepartment} onChange={(e) => setRole({ ...role, printdepartment: !role.printdepartment })} />} label="Print Department" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdepartment} onChange={(e) => setRole({ ...role, pdfdepartment: !role.pdfdepartment })} />} label="Pdf Department" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* User End.......................... */}

                        {/* Suppler Management.........Start */}

                        {/* Supplier */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.suppliermanagement} onChange={(e) => setRole({ ...role, suppliermanagement: !role.suppliermanagement })} />} label="Supplier management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplier} onChange={(e) => setRole({ ...role, allsupplier: !role.allsupplier })} />} label="Supplier" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplier} onChange={(e) => supplierAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vsupplier} onChange={(e) => setRole({ ...role, vsupplier: !role.vsupplier })} />} label="View supplier" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.asupplier} onChange={(e) => setRole({ ...role, asupplier: !role.asupplier })} />} label="Add supplier" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.esupplier} onChange={(e) => setRole({ ...role, esupplier: !role.esupplier })} />} label="Edit supplier" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dsupplier} onChange={(e) => setRole({ ...role, dsupplier: !role.dsupplier })} />} label="Delete supplier" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplier} onChange={(e) => setRole({ ...role, excelsupplier: !role.excelsupplier })} />} label="Excel supplier" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplier} onChange={(e) => setRole({ ...role, csvsupplier: !role.csvsupplier })} />} label="CSV supplier" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplier} onChange={(e) => setRole({ ...role, printsupplier: !role.printsupplier })} />} label="Print supplier" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplier} onChange={(e) => setRole({ ...role, pdfsupplier: !role.pdfsupplier })} />} label="Pdf supplier" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        <Divider sx={{ my: 2 }} />

                        {/* Import Supplier */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allimpsupplierlist} onChange={(e) => setRole({ ...role, allimpsupplierlist: !role.allimpsupplierlist })} />} label="Import Supplier" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallimpsupplierlist} onChange={(e) => importSupplierAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvimpsupplierlist} onChange={(e) => setRole({ ...role, csvimpsupplierlist: !role.csvimpsupplierlist })} />} label="Csv Import Supplier List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelimpsupplierlist} onChange={(e) => setRole({ ...role, excelimpsupplierlist: !role.excelimpsupplierlist })} />} label="Excel Import Supplier List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printimpsupplierlist} onChange={(e) => setRole({ ...role, printimpsupplierlist: !role.printimpsupplierlist })} />} label="Print Import Supplier List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfimpsupplierlist} onChange={(e) => setRole({ ...role, pdfimpsupplierlist: !role.pdfimpsupplierlist })} />} label="Pdf Import Supplier List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eimpsupplierlist} onChange={(e) => setRole({ ...role, eimpsupplierlist: !role.eimpsupplierlist })} />} label="Edit Import Supplier List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dimpsupplierlist} onChange={(e) => setRole({ ...role, dimpsupplierlist: !role.dimpsupplierlist })} />} label="Delete Import Supplier List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vimpsupplierlist} onChange={(e) => setRole({ ...role, vimpsupplierlist: !role.vimpsupplierlist })} />} label="View Import Supplier List" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Balance Wise Supplier Dues Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbalwisesupduesreport} onChange={(e) => setRole({ ...role, allbalwisesupduesreport: !role.allbalwisesupduesreport })} />} label="Balance Wise Supplier Dues Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbalwisesupduesreport} onChange={(e) => balancewiseSupplierAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbalwisesupduesreport} onChange={(e) => setRole({ ...role, csvbalwisesupduesreport: !role.csvbalwisesupduesreport })} />} label="Csv Balance Wise Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbalwisesupduesreport} onChange={(e) => setRole({ ...role, excelbalwisesupduesreport: !role.excelbalwisesupduesreport })} />} label="Excel Balance Wise Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbalwisesupduesreport} onChange={(e) => setRole({ ...role, printbalwisesupduesreport: !role.printbalwisesupduesreport })} />} label="Print Balance Wise Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbalwisesupduesreport} onChange={(e) => setRole({ ...role, pdfbalwisesupduesreport: !role.pdfbalwisesupduesreport })} />} label="Pdf Balance Wise Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier Balance Abstract */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierbalanceabstract} onChange={(e) => setRole({ ...role, allsupplierbalanceabstract: !role.allsupplierbalanceabstract })} />} label="Supplier Balance Abstract" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierbalanceabstract} onChange={(e) => subBalAbstractSupplierAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierbalanceabstract} onChange={(e) => setRole({ ...role, csvsupplierbalanceabstract: !role.csvsupplierbalanceabstract })} />} label="Csv Supplier Balance Abstract" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierbalanceabstract} onChange={(e) => setRole({ ...role, excelsupplierbalanceabstract: !role.excelsupplierbalanceabstract })} />} label="Excel Supplier Balance Abstract" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierbalanceabstract} onChange={(e) => setRole({ ...role, printsupplierbalanceabstract: !role.printsupplierbalanceabstract })} />} label="Print Supplier Balance Abstract" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierbalanceabstract} onChange={(e) => setRole({ ...role, pdfsupplierbalanceabstract: !role.pdfsupplierbalanceabstract })} />} label="Pdf Supplier Balance Abstract" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier Bills Abstract */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierbillsreport} onChange={(e) => setRole({ ...role, allsupplierbillsreport: !role.allsupplierbillsreport })} />} label="Supplier Bills Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierbillsreport} onChange={(e) => supplierBillsReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierbillsreport} onChange={(e) => setRole({ ...role, csvsupplierbillsreport: !role.csvsupplierbillsreport })} />} label="Csv Supplier Bills Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierbillsreport} onChange={(e) => setRole({ ...role, excelsupplierbillsreport: !role.excelsupplierbillsreport })} />} label="Excel Supplier Bills Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierbillsreport} onChange={(e) => setRole({ ...role, printsupplierbillsreport: !role.printsupplierbillsreport })} />} label="Print Supplier Bills Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierbillsreport} onChange={(e) => setRole({ ...role, pdfsupplierbillsreport: !role.pdfsupplierbillsreport })} />} label="Pdf Supplier Bills Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier Dues Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupduesreport} onChange={(e) => setRole({ ...role, allsupduesreport: !role.allsupduesreport })} />} label="Supplier Dues Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupduesreport} onChange={(e) => supplierduesReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupduesreport} onChange={(e) => setRole({ ...role, csvsupduesreport: !role.csvsupduesreport })} />} label="Csv Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupduesreport} onChange={(e) => setRole({ ...role, excelsupduesreport: !role.excelsupduesreport })} />} label="Excel Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupduesreport} onChange={(e) => setRole({ ...role, printsupduesreport: !role.printsupduesreport })} />} label="Print Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupduesreport} onChange={(e) => setRole({ ...role, pdfsupduesreport: !role.pdfsupduesreport })} />} label="Pdf Supplier Dues Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier Over Dues Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplieroverduereport} onChange={(e) => setRole({ ...role, allsupplieroverduereport: !role.allsupplieroverduereport })} />} label="Supplier Over Dues Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplieroverduereport} onChange={(e) => supplieroverduesReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplieroverduereport} onChange={(e) => setRole({ ...role, csvsupplieroverduereport: !role.csvsupplieroverduereport })} />} label="Csv Supplier Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplieroverduereport} onChange={(e) => setRole({ ...role, excelsupplieroverduereport: !role.excelsupplieroverduereport })} />} label="Excel Supplier Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplieroverduereport} onChange={(e) => setRole({ ...role, printsupplieroverduereport: !role.printsupplieroverduereport })} />} label="Print Supplier Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplieroverduereport} onChange={(e) => setRole({ ...role, pdfsupplieroverduereport: !role.pdfsupplieroverduereport })} />} label="Pdf Supplier Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier Payments Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierpaymentsreport} onChange={(e) => setRole({ ...role, allsupplierpaymentsreport: !role.allsupplierpaymentsreport })} />} label="Supplier Payments Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierpaymentsreport} onChange={(e) => supplierpaymentsReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierpaymentsreport} onChange={(e) => setRole({ ...role, csvsupplierpaymentsreport: !role.csvsupplierpaymentsreport })} />} label="Csv Supplier Payments Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierpaymentsreport} onChange={(e) => setRole({ ...role, excelsupplierpaymentsreport: !role.excelsupplierpaymentsreport })} />} label="Excel Supplier Payments Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierpaymentsreport} onChange={(e) => setRole({ ...role, printsupplierpaymentsreport: !role.printsupplierpaymentsreport })} />} label="Print Supplier Payments Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierpaymentsreport} onChange={(e) => setRole({ ...role, pdfsupplierpaymentsreport: !role.pdfsupplierpaymentsreport })} />} label="Pdf Supplier Payments Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier wise Payments Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierwisepaymentsreport} onChange={(e) => setRole({ ...role, allsupplierwisepaymentsreport: !role.allsupplierwisepaymentsreport })} />} label="Supplier Wise Payments Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierwisepaymentsreport} onChange={(e) => supplierwisepayReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierwisepaymentsreport} onChange={(e) => setRole({ ...role, csvsupplierwisepaymentsreport: !role.csvsupplierwisepaymentsreport })} />} label="Csv Supplier Wise Payments Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierwisepaymentsreport} onChange={(e) => setRole({ ...role, excelsupplierwisepaymentsreport: !role.excelsupplierwisepaymentsreport })} />} label="Excel Supplier Wise Payments Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierwisepaymentsreport} onChange={(e) => setRole({ ...role, printsupplierwisepaymentsreport: !role.printsupplierwisepaymentsreport })} />} label="Print Supplier Wise Payments Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierwisepaymentsreport} onChange={(e) => setRole({ ...role, pdfsupplierwisepaymentsreport: !role.pdfsupplierwisepaymentsreport })} />} label="Pdf Supplier Wise Payments Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier Payments Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsuppliersreport} onChange={(e) => setRole({ ...role, allsuppliersreport: !role.allsuppliersreport })} />} label="Supplier Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsuppliersreport} onChange={(e) => supplierReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsuppliersreport} onChange={(e) => setRole({ ...role, csvsuppliersreport: !role.csvsuppliersreport })} />} label="Csv Supplier Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsuppliersreport} onChange={(e) => setRole({ ...role, excelsuppliersreport: !role.excelsuppliersreport })} />} label="Excel Supplier Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsuppliersreport} onChange={(e) => setRole({ ...role, printsuppliersreport: !role.printsuppliersreport })} />} label="Print Supplier Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsuppliersreport} onChange={(e) => setRole({ ...role, pdfsuppliersreport: !role.pdfsuppliersreport })} />} label="Pdf Supplier Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Supplier End .................*/}


                        {/* Customer Management Start........... */}

                        {/* Customer Start */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.customermanagement} onChange={(e) => setRole({ ...role, customermanagement: !role.customermanagement })} />} label="Customer management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomer} onChange={(e) => setRole({ ...role, allscustomer: !role.allcustomer })} />} label="Customer" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomer} onChange={(e) => customerAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.acustomer} onChange={(e) => setRole({ ...role, acustomer: !role.acustomer })} />} label="Add customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ecustomer} onChange={(e) => setRole({ ...role, ecustomer: !role.ecustomer })} />} label="Edit customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dcustomer} onChange={(e) => setRole({ ...role, dcustomer: !role.dcustomer })} />} label="Delete customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomer} onChange={(e) => setRole({ ...role, excelcustomer: !role.excelcustomer })} />} label="Excel customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomer} onChange={(e) => setRole({ ...role, csvcustomer: !role.csvcustomer })} />} label="CSV customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomer} onChange={(e) => setRole({ ...role, printcustomer: !role.printcustomer })} />} label="Print customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomer} onChange={(e) => setRole({ ...role, pdfcustomer: !role.pdfcustomer })} />} label="Pdf customer" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Area Wise Customer Start */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allareawisecustomerlist} onChange={(e) => setRole({ ...role, allareawisecustomerlist: !role.allareawisecustomerlist })} />} label="Area wise customer list" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallareawisecustomer} onChange={(e) => areaWiseCustomerAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvareawisecustomer} onChange={(e) => setRole({ ...role, csvareawisecustomer: !role.csvareawisecustomer })} />} label="Csv Area wise customer list" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelareawisecustomer} onChange={(e) => setRole({ ...role, excelareawisecustomer: !role.excelareawisecustomer })} />} label="Excel Area wise customer list" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printareawisecustomer} onChange={(e) => setRole({ ...role, printareawisecustomer: !role.printareawisecustomer })} />} label="Print Area wise customer list" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfareawisecustomer} onChange={(e) => setRole({ ...role, pdfareawisecustomer: !role.pdfareawisecustomer })} />} label="Pdf Area wise customer list" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Balance Wise Customer Dues Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbalancewisecusduesreport} onChange={(e) => setRole({ ...role, allbalancewisecusduesreport: !role.allbalancewisecusduesreport })} />} label="Balance Wise Customer Dues Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbalancewisecusduesreport} onChange={(e) => balWiseCustomerAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbalancewisecusduesreport} onChange={(e) => setRole({ ...role, excelbalancewisecusduesreport: !role.excelbalancewisecusduesreport })} />} label="Excel Balance Wise Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbalancewisecusduesreport} onChange={(e) => setRole({ ...role, csvbalancewisecusduesreport: !role.csvbalancewisecusduesreport })} />} label="Csv Balance Wise Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbalancewisecusduesreport} onChange={(e) => setRole({ ...role, printbalancewisecusduesreport: !role.printbalancewisecusduesreport })} />} label="Print Balance Wise Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbalancewisecusduesreport} onChange={(e) => setRole({ ...role, pdfbalancewisecusduesreport: !role.pdfbalancewisecusduesreport })} />} label="Pdf Balance Wise Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Category Wise Customer List */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcategorywisecustomerlist} onChange={(e) => setRole({ ...role, allcategorywisecustomerlist: !role.allcategorywisecustomerlist })} />} label="Category Wise Customer List" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcategorywisecustomerlist} onChange={(e) => categoryWiseCustomerAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcategorywisecustomerlist} onChange={(e) => setRole({ ...role, excelcategorywisecustomerlist: !role.excelcategorywisecustomerlist })} />} label="Excel Category Wise Customer List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcategorywisecustomerlist} onChange={(e) => setRole({ ...role, csvcategorywisecustomerlist: !role.csvcategorywisecustomerlist })} />} label="Cvs Category Wise Customer List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcategorywisecustomerlist} onChange={(e) => setRole({ ...role, printcategorywisecustomerlist: !role.printcategorywisecustomerlist })} />} label="Print Category Wise Customer List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcategorywisecustomerlist} onChange={(e) => setRole({ ...role, pdfcategorywisecustomerlist: !role.pdfcategorywisecustomerlist })} />} label="Pdf Category Wise Customer List" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Account Statement*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomeraccountstatement} onChange={(e) => setRole({ ...role, allcustomeraccountstatement: !role.allcustomeraccountstatement })} />} label="Customer Account Statement" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomeraccountstatement} onChange={(e) => customerAccountStaAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomeraccountstatement} onChange={(e) => setRole({ ...role, excelcustomeraccountstatement: !role.excelcustomeraccountstatement })} />} label="Excel Customer Account Statement" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomeraccountstatement} onChange={(e) => setRole({ ...role, csvcustomeraccountstatement: !role.csvcustomeraccountstatement })} />} label="Csv Customer Account Statement" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomeraccountstatement} onChange={(e) => setRole({ ...role, printcustomeraccountstatement: !role.printcustomeraccountstatement })} />} label="Print Customer Account Statement" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomeraccountstatement} onChange={(e) => setRole({ ...role, pdfcustomeraccountstatement: !role.pdfcustomeraccountstatement })} />} label="Pdf Customer Account Statement" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Account Summary*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomeraccountsummary} onChange={(e) => setRole({ ...role, customeraccountsummary: !role.customeraccountsummary })} />} label="Customer Account Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomeraccountsummary} onChange={(e) => customerAccountSumAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomeraccountsummary} onChange={(e) => setRole({ ...role, excelcustomeraccountsummary: !role.excelcustomeraccountsummary })} />} label="Excel Customer Account Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomeraccountsummary} onChange={(e) => setRole({ ...role, csvcustomeraccountsummary: !role.csvcustomeraccountsummary })} />} label="Csv Customer Account Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomeraccountsummary} onChange={(e) => setRole({ ...role, printcustomeraccountsummary: !role.printcustomeraccountsummary })} />} label="Print Customer Account Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomeraccountsummary} onChange={(e) => setRole({ ...role, pdfcustomeraccountsummary: !role.pdfcustomeraccountsummary })} />} label="Pdf Customer Account Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Balance Register*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allCustomerbalanceregister} onChange={(e) => setRole({ ...role, allCustomerbalanceregister: !role.allCustomerbalanceregister })} />} label="Customer Balance Register" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallCustomerbalanceregister} onChange={(e) => customerBalanceRegAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelCustomerbalanceregister} onChange={(e) => setRole({ ...role, excelCustomerbalanceregister: !role.excelCustomerbalanceregister })} />} label="Excel Customer Balance Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvCustomerbalanceregister} onChange={(e) => setRole({ ...role, csvCustomerbalanceregister: !role.csvCustomerbalanceregister })} />} label="Csv Customer Balance Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printCustomerbalanceregister} onChange={(e) => setRole({ ...role, printCustomerbalanceregister: !role.printCustomerbalanceregister })} />} label="Print Customer Balance Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfCustomerbalanceregister} onChange={(e) => setRole({ ...role, pdfCustomerbalanceregister: !role.pdfCustomerbalanceregister })} />} label="Pdf Customer Balance Register" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Bills Report*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerbillsreport} onChange={(e) => setRole({ ...role, allcustomerbillsreport: !role.allcustomerbillsreport })} />} label="Customer Bills Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerbillsreport} onChange={(e) => customerBillsRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerbillsreport} onChange={(e) => setRole({ ...role, excelcustomerbillsreport: !role.excelcustomerbillsreport })} />} label="Excel Customer Bills Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerbillsreport} onChange={(e) => setRole({ ...role, csvcustomerbillsreport: !role.csvcustomerbillsreport })} />} label="Csv Customer Bills Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerbillsreport} onChange={(e) => setRole({ ...role, printcustomerbillsreport: !role.printcustomerbillsreport })} />} label="Print Customer Bill Sreport" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerbillsreport} onChange={(e) => setRole({ ...role, pdfcustomerbillsreport: !role.pdfcustomerbillsreport })} />} label="Pdf Customer Bills Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Drug Entry*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcusdrugentry} onChange={(e) => setRole({ ...role, allcusdrugentry: !role.allcusdrugentry })} />} label="Customer Drug Entry" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcusdrugentry} onChange={(e) => customerDrugEntAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.acusdrugentry} onChange={(e) => setRole({ ...role, acusdrugentry: !role.acusdrugentry })} />} label="Add Customer Drug Entry" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcusdrugentry} onChange={(e) => setRole({ ...role, excelcusdrugentry: !role.excelcusdrugentry })} />} label="Excel Customer Drug Entry" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcusdrugentry} onChange={(e) => setRole({ ...role, csvcusdrugentry: !role.csvcusdrugentry })} />} label="Csv Customer Drug Entry" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcusdrugentry} onChange={(e) => setRole({ ...role, printcusdrugentry: !role.printcusdrugentry })} />} label="Print Customer Drug Entry" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcusdrugentry} onChange={(e) => setRole({ ...role, pdfcusdrugentry: !role.pdfcusdrugentry })} />} label="Pdf Customer Drug Entry" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Dues Report*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustduesreport} onChange={(e) => setRole({ ...role, allcustduesreport: !role.allcustduesreport })} />} label="Customer Dues Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustduesreport} onChange={(e) => customerDuesRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustduesreport} onChange={(e) => setRole({ ...role, excelcustduesreport: !role.excelcustduesreport })} />} label="Excel Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustduesreport} onChange={(e) => setRole({ ...role, csvcustduesreport: !role.csvcustduesreport })} />} label="Csv Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustduesreport} onChange={(e) => setRole({ ...role, printcustduesreport: !role.printcustduesreport })} />} label="Print Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustduesreport} onChange={(e) => setRole({ ...role, pdfcustduesreport: !role.pdfcustduesreport })} />} label="Pdf Customer Dues Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Over Dues Abstract*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcusoverduesabstract} onChange={(e) => setRole({ ...role, allcusoverduesabstract: !role.allcusoverduesabstract })} />} label="Customer Over Dues Abstract" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcusoverduesabstract} onChange={(e) => customerOvDuesAbRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcusoverduesabstract} onChange={(e) => setRole({ ...role, excelcusoverduesabstract: !role.excelcusoverduesabstract })} />} label="Excel Customer Over Dues Abstract" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcusoverduesabstract} onChange={(e) => setRole({ ...role, csvcusoverduesabstract: !role.csvcusoverduesabstract })} />} label="Cvs Customer Over Dues Abstract" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcusoverduesabstract} onChange={(e) => setRole({ ...role, printcusoverduesabstract: !role.printcusoverduesabstract })} />} label="Print Customer Over Dues Abstract" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcusoverduesabstract} onChange={(e) => setRole({ ...role, pdfcusoverduesabstract: !role.pdfcusoverduesabstract })} />} label="Pdf Customer Over Dues Abstract" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Over Dues Report*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcusoverduesrep} onChange={(e) => setRole({ ...role, allcusoverduesrep: !role.allcusoverduesrep })} />} label="Customer Over Dues Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcusoverduesrep} onChange={(e) => customerOvDuesrepRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcusoverduesrep} onChange={(e) => setRole({ ...role, excelcusoverduesrep: !role.excelcusoverduesrep })} />} label="Excel Customer Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcusoverduesrep} onChange={(e) => setRole({ ...role, csvcusoverduesrep: !role.csvcusoverduesrep })} />} label="Csv Customer Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcusoverduesrep} onChange={(e) => setRole({ ...role, printcusoverduesrep: !role.printcusoverduesrep })} />} label="Print Customer Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcusoverduesrep} onChange={(e) => setRole({ ...role, pdfcusoverduesrep: !role.pdfcusoverduesrep })} />} label="Pdf Customer Over Dues Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Payment Report*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerpaymentreport} onChange={(e) => setRole({ ...role, allcustomerpaymentreport: !role.allcustomerpaymentreport })} />} label="Customer Payment Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerpaymentreport} onChange={(e) => customerpayrepRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerpaymentreport} onChange={(e) => setRole({ ...role, excelcustomerpaymentreport: !role.excelcustomerpaymentreport })} />} label="Excel Customer Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerpaymentreport} onChange={(e) => setRole({ ...role, csvcustomerpaymentreport: !role.csvcustomerpaymentreport })} />} label="Csv Customer Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerpaymentreport} onChange={(e) => setRole({ ...role, printcustomerpaymentreport: !role.printcustomerpaymentreport })} />} label="Print Customer Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerpaymentreport} onChange={(e) => setRole({ ...role, pdfcustomerpaymentreport: !role.pdfcustomerpaymentreport })} />} label="Pdf Customer Payment Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Wise Payment Report*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerwisepaymentreport} onChange={(e) => setRole({ ...role, allcustomerwisepaymentreport: !role.allcustomerwisepaymentreport })} />} label="Customer Wise Payment Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerwisepaymentreport} onChange={(e) => customerwisepayrepRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerwisepaymentreport} onChange={(e) => setRole({ ...role, excelcustomerwisepaymentreport: !role.excelcustomerwisepaymentreport })} />} label="Excel Customer Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerwisepaymentreport} onChange={(e) => setRole({ ...role, csvcustomerwisepaymentreport: !role.csvcustomerwisepaymentreport })} />} label="Csv Customer Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerwisepaymentreport} onChange={(e) => setRole({ ...role, printcustomerwisepaymentreport: !role.printcustomerwisepaymentreport })} />} label="Print Customer Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerwisepaymentreport} onChange={(e) => setRole({ ...role, pdfcustomerwisepaymentreport: !role.pdfcustomerwisepaymentreport })} />} label="Pdf Customer Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Retail Followup Customer*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allretailfollowupcustomer} onChange={(e) => setRole({ ...role, allretailfollowupcustomer: !role.allretailfollowupcustomer })} />} label="Retail Followup Customer" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallretailfollowupcustomer} onChange={(e) => retailFolwCusRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelretailfollowupcustomer} onChange={(e) => setRole({ ...role, excelretailfollowupcustomer: !role.excelretailfollowupcustomer })} />} label="Excel Retail Followup Customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvretailfollowupcustomer} onChange={(e) => setRole({ ...role, csvretailfollowupcustomer: !role.csvretailfollowupcustomer })} />} label="Csv Retail Followup Customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printretailfollowupcustomer} onChange={(e) => setRole({ ...role, printretailfollowupcustomer: !role.printretailfollowupcustomer })} />} label="Print Retail Followup Customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfretailfollowupcustomer} onChange={(e) => setRole({ ...role, pdfretailfollowupcustomer: !role.pdfretailfollowupcustomer })} />} label="Pdf Retail Followup Customer" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Whole Sale Followup Customer*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allwholesalefollowupcustomer} onChange={(e) => setRole({ ...role, allwholesalefollowupcustomer: !role.allwholesalefollowupcustomer })} />} label="Whole Sale Followup Customer" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallwholesalefollowupcustomer} onChange={(e) => wholesaleFolwCusRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelwholesalefollowupcustomer} onChange={(e) => setRole({ ...role, excelwholesalefollowupcustomer: !role.excelwholesalefollowupcustomer })} />} label="Excel Whole Sale Followup Customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvwholesalefollowupcustomer} onChange={(e) => setRole({ ...role, csvwholesalefollowupcustomer: !role.csvwholesalefollowupcustomer })} />} label="Csv Whole Sale Followup Customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printwholesalefollowupcustomer} onChange={(e) => setRole({ ...role, printwholesalefollowupcustomer: !role.printwholesalefollowupcustomer })} />} label="Print Whole Sale Followup Customer" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfwholesalefollowupcustomer} onChange={(e) => setRole({ ...role, pdfwholesalefollowupcustomer: !role.pdfwholesalefollowupcustomer })} />} label="Pdf Whole Sale Followup Customer" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer List Retail*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerlistretail} onChange={(e) => setRole({ ...role, allcustomerlistretail: !role.allcustomerlistretail })} />} label="Customer List Retail" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerlistretail} onChange={(e) => customerlistretailAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.acustomerlistretail} onChange={(e) => setRole({ ...role, acustomerlistretail: !role.acustomerlistretail })} />} label="Add Retail Customers" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerlistretail} onChange={(e) => setRole({ ...role, excelcustomerlistretail: !role.excelcustomerlistretail })} />} label="Excel Customer List Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerlistretail} onChange={(e) => setRole({ ...role, csvcustomerlistretail: !role.csvcustomerlistretail })} />} label="Csv Customer List Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerlistretail} onChange={(e) => setRole({ ...role, printcustomerlistretail: !role.printcustomerlistretail })} />} label="Print Customer List Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerlistretail} onChange={(e) => setRole({ ...role, pdfcustomerlistretail: !role.pdfcustomerlistretail })} />} label="Pdf Customer List Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ecustomerlistretail} onChange={(e) => setRole({ ...role, ecustomerlistretail: !role.ecustomerlistretail })} />} label="Edit Customer List Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dcustomerlistretail} onChange={(e) => setRole({ ...role, dcustomerlistretail: !role.dcustomerlistretail })} />} label="Delete Customer List Retail" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer List Whole Sale*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerlistwholesale} onChange={(e) => setRole({ ...role, allcustomerlistwholesale: !role.allcustomerlistwholesale })} />} label="Customer List Whole Sale" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerlistwholesale} onChange={(e) => customerlistwholesaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.acustomerlistwholesale} onChange={(e) => setRole({ ...role, acustomerlistwholesale: !role.acustomerlistwholesale })} />} label="Add Wholesale Customers" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerlistwholesale} onChange={(e) => setRole({ ...role, excelcustomerlistwholesale: !role.excelcustomerlistwholesale })} />} label="Excel Customer List Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerlistwholesale} onChange={(e) => setRole({ ...role, csvcustomerlistwholesale: !role.csvcustomerlistwholesale })} />} label="Csv Customer List Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerlistwholesale} onChange={(e) => setRole({ ...role, printcustomerlistwholesale: !role.printcustomerlistwholesale })} />} label="Print Customer List Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerlistwholesale} onChange={(e) => setRole({ ...role, pdfcustomerlistwholesale: !role.pdfcustomerlistwholesale })} />} label="Pdf Customer List Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ecustomerlistwholesale} onChange={(e) => setRole({ ...role, ecustomerlistwholesale: !role.ecustomerlistwholesale })} />} label="Edi Customer List Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dcustomerlistwholesale} onChange={(e) => setRole({ ...role, dcustomerlistwholesale: !role.dcustomerlistwholesale })} />} label="Delete Customer List Whole Sale" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Reference Wise Dues Report Estimate*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allreferencewisecusduesreportestimate} onChange={(e) => setRole({ ...role, allreferencewisecusduesreportestimate: !role.allreferencewisecusduesreportestimate })} />} label="Reference Wise Dues Report Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallreferencewisecusduesreportestimate} onChange={(e) => refWiseduesRepEsAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelreferencewisecusduesreportestimate} onChange={(e) => setRole({ ...role, excelreferencewisecusduesreportestimate: !role.excelreferencewisecusduesreportestimate })} />} label="Excel Reference Wise Dues Report Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvreferencewisecusduesreportestimate} onChange={(e) => setRole({ ...role, csvreferencewisecusduesreportestimate: !role.csvreferencewisecusduesreportestimate })} />} label="Csv Reference Wise Dues Report Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printreferencewisecusduesreportestimate} onChange={(e) => setRole({ ...role, printreferencewisecusduesreportestimate: !role.printreferencewisecusduesreportestimate })} />} label="Print Reference Wise Dues Report Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfreferencewisecusduesreportestimate} onChange={(e) => setRole({ ...role, pdfreferencewisecusduesreportestimate: !role.pdfreferencewisecusduesreportestimate })} />} label="Pdf Reference Wise Dues Report Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Reference Wise Dues Report Sales*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allreferencewisecusduesreportsales} onChange={(e) => setRole({ ...role, allreferencewisecusduesreportsales: !role.allreferencewisecusduesreportsales })} />} label="Reference Wise Dues Report Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallreferencewisecusduesreportsales} onChange={(e) => refWiseCusDuesRepSaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelreferencewisecusduesreportsales} onChange={(e) => setRole({ ...role, excelreferencewisecusduesreportsales: !role.excelreferencewisecusduesreportsales })} />} label="Excel Reference Wise Dues Report Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvreferencewisecusduesreportsales} onChange={(e) => setRole({ ...role, csvreferencewisecusduesreportsales: !role.csvreferencewisecusduesreportsales })} />} label="Csv Reference Wise Dues Report Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printreferencewisecusduesreportsales} onChange={(e) => setRole({ ...role, printreferencewisecusduesreportsales: !role.printreferencewisecusduesreportsales })} />} label="Print Reference Wise Dues Report Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfreferencewisecusduesreportsales} onChange={(e) => setRole({ ...role, pdfreferencewisecusduesreportsales: !role.pdfreferencewisecusduesreportsales })} />} label="Pdf Reference Wise Dues Report Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Reference Wise Payment Report*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allreferencewisepaymentreport} onChange={(e) => setRole({ ...role, allreferencewisepaymentreport: !role.allreferencewisepaymentreport })} />} label="Reference Wise Payment Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallreferencewisepaymentreport} onChange={(e) => referenceWisePayRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelreferencewisepaymentreport} onChange={(e) => setRole({ ...role, excelreferencewisepaymentreport: !role.excelreferencewisepaymentreport })} />} label="Excel Reference Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvreferencewisepaymentreport} onChange={(e) => setRole({ ...role, csvreferencewisepaymentreport: !role.csvreferencewisepaymentreport })} />} label="Csv Reference Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printreferencewisepaymentreport} onChange={(e) => setRole({ ...role, printreferencewisepaymentreport: !role.printreferencewisepaymentreport })} />} label="Print Reference Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfreferencewisepaymentreport} onChange={(e) => setRole({ ...role, pdfreferencewisepaymentreport: !role.pdfreferencewisepaymentreport })} />} label="Pdf Reference Wise Payment Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Reference Wise Payment Summary*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allreferencewisepaymentsummary} onChange={(e) => setRole({ ...role, allreferencewisepaymentsummary: !role.allreferencewisepaymentsummary })} />} label="Reference Wise Payment Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallreferencewisepaymentsummary} onChange={(e) => referenceWisePaySummRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelreferencewisepaymentsummary} onChange={(e) => setRole({ ...role, excelreferencewisepaymentsummary: !role.excelreferencewisepaymentsummary })} />} label="Excel Reference Wise Payment Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvreferencewisepaymentsummary} onChange={(e) => setRole({ ...role, csvreferencewisepaymentsummary: !role.csvreferencewisepaymentsummary })} />} label="Csv Reference Wise Payment Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printreferencewisepaymentsummary} onChange={(e) => setRole({ ...role, printreferencewisepaymentsummary: !role.printreferencewisepaymentsummary })} />} label="Print Reference Wise Payment Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfreferencewisepaymentsummary} onChange={(e) => setRole({ ...role, pdfreferencewisepaymentsummary: !role.pdfreferencewisepaymentsummary })} />} label="Pdf Reference Wise Payment Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Remark Wise Customer Report*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allremarkwisecustomerreport} onChange={(e) => setRole({ ...role, allremarkwisecustomerreport: !role.allremarkwisecustomerreport })} />} label="Remark Wise Customer Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallremarkwisecustomerreport} onChange={(e) => remarkWiseCustomerRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelremarkwisecustomerreport} onChange={(e) => setRole({ ...role, excelremarkwisecustomerreport: !role.excelremarkwisecustomerreport })} />} label="Excel Remark Wise Customer Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvremarkwisecustomerreport} onChange={(e) => setRole({ ...role, csvremarkwisecustomerreport: !role.csvremarkwisecustomerreport })} />} label="Csv Remark Wise Customer Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printremarkwisecustomerreport} onChange={(e) => setRole({ ...role, printremarkwisecustomerreport: !role.printremarkwisecustomerreport })} />} label="Print Remark Wise Customer Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfremarkwisecustomerreport} onChange={(e) => setRole({ ...role, pdfremarkwisecustomerreport: !role.pdfremarkwisecustomerreport })} />} label="Pdf Remark Wise Customer Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Customer Group*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomergrp} onChange={(e) => setRole({ ...role, allcustomergrp: !role.allcustomergrp })} />} label="Customer group" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomergrp} onChange={(e) => customerGroupAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.acustomergrp} onChange={(e) => setRole({ ...role, acustomergrp: !role.acustomergrp })} />} label="Add customer group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ecustomergrp} onChange={(e) => setRole({ ...role, ecustomergrp: !role.ecustomergrp })} />} label="Edit customer group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dcustomergrp} onChange={(e) => setRole({ ...role, dcustomergrp: !role.dcustomergrp })} />} label="Delete customer group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomergrp} onChange={(e) => setRole({ ...role, excelcustomergrp: !role.excelcustomergrp })} />} label="Excel customer group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomergrp} onChange={(e) => setRole({ ...role, csvcustomergrp: !role.csvcustomergrp })} />} label="CSV customer group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomergrp} onChange={(e) => setRole({ ...role, printcustomergrp: !role.printcustomergrp })} />} label="Print customer group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomergrp} onChange={(e) => setRole({ ...role, pdfcustomergrp: !role.pdfcustomergrp })} />} label="Pdf customer group" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Product Management Start */}

                        {/* Product */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.productmanagement} onChange={(e) => setRole({ ...role, productmanagement: !role.productmanagement })} />} label="Product management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allproduct} onChange={(e) => setRole({ ...role, allproduct: !role.allproduct })} />} label="Product" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallproduct} onChange={(e) => productAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vproduct} onChange={(e) => setRole({ ...role, vproduct: !role.vproduct })} />} label="view product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.iproduct} onChange={(e) => setRole({ ...role, iproduct: !role.iproduct })} />} label="Import product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aproduct} onChange={(e) => setRole({ ...role, aproduct: !role.aproduct })} />} label="Add product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eproduct} onChange={(e) => setRole({ ...role, eproduct: !role.eproduct })} />} label="Edit product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dproduct} onChange={(e) => setRole({ ...role, dproduct: !role.dproduct })} />} label="Delete product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelproduct} onChange={(e) => setRole({ ...role, excelproduct: !role.excelproduct })} />} label="Excel product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvproduct} onChange={(e) => setRole({ ...role, csvproduct: !role.csvproduct })} />} label="CSV product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printproduct} onChange={(e) => setRole({ ...role, printproduct: !role.printproduct })} />} label="Print product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfproduct} onChange={(e) => setRole({ ...role, pdfproduct: !role.pdfproduct })} />} label="Pdf product" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Category */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcategory} onChange={(e) => setRole({ ...role, allcategory: !role.allcategory })} />} label="Category" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcategory} onChange={(e) => categoryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.acategory} onChange={(e) => setRole({ ...role, acategory: !role.acategory })} />} label="Add category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ecategory} onChange={(e) => setRole({ ...role, ecategory: !role.ecategory })} />} label="Edit category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dcategory} onChange={(e) => setRole({ ...role, dcategory: !role.dcategory })} />} label="Delete category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcategory} onChange={(e) => setRole({ ...role, printcategory: !role.printcategory })} />} label="Print category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcategory} onChange={(e) => setRole({ ...role, pdfcategory: !role.pdfcategory })} />} label="Pdf category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcategory} onChange={(e) => setRole({ ...role, excelcategory: !role.excelcategory })} />} label="Excel category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcategory} onChange={(e) => setRole({ ...role, csvcategory: !role.csvcategory })} />} label="Csv category" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Discount */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldiscount} onChange={(e) => setRole({ ...role, alldiscount: !role.alldiscount })} />} label="Discount" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldiscount} onChange={(e) => discountAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.adiscount} onChange={(e) => setRole({ ...role, adiscount: !role.adiscount })} />} label="Add discount" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ediscount} onChange={(e) => setRole({ ...role, ediscount: !role.ediscount })} />} label="Edit discount" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ddiscount} onChange={(e) => setRole({ ...role, ddiscount: !role.ddiscount })} />} label="Delete discount" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldiscount} onChange={(e) => setRole({ ...role, exceldiscount: !role.exceldiscount })} />} label="Excel discount" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdiscount} onChange={(e) => setRole({ ...role, csvdiscount: !role.csvdiscount })} />} label="CSV discount" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdiscount} onChange={(e) => setRole({ ...role, printdiscount: !role.printdiscount })} />} label="Print discount" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdiscount} onChange={(e) => setRole({ ...role, pdfdiscount: !role.pdfdiscount })} />} label="Pdf discount" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Unit */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allunit} onChange={(e) => setRole({ ...role, allunit: !role.allunit })} />} label="Unit" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallunit} onChange={(e) => unitAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aunit} onChange={(e) => setRole({ ...role, aunit: !role.aunit })} />} label="Add unit" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eunit} onChange={(e) => setRole({ ...role, eunit: !role.eunit })} />} label="Edit unit" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dunit} onChange={(e) => setRole({ ...role, dunit: !role.dunit })} />} label="Delete unit" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelunit} onChange={(e) => setRole({ ...role, excelunit: !role.excelunit })} />} label="Excel unit" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvunit} onChange={(e) => setRole({ ...role, csvunit: !role.csvunit })} />} label="CSV unit" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printunit} onChange={(e) => setRole({ ...role, printunit: !role.printunit })} />} label="Print unit" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfunit} onChange={(e) => setRole({ ...role, pdfunit: !role.pdfunit })} />} label="Pdf unit" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Category Wise List */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcatelist} onChange={(e) => setRole({ ...role, allcatelist: !role.allcatelist })} />} label="Category Wise List" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcatelist} onChange={(e) => cateListAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcatelist} onChange={(e) => setRole({ ...role, csvcatelist: !role.csvcatelist })} />} label="CSV Category Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcatelist} onChange={(e) => setRole({ ...role, excelcatelist: !role.excelcatelist })} />} label="Excel Category Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcatelist} onChange={(e) => setRole({ ...role, printcatelist: !role.printcatelist })} />} label="Print Category Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcatelist} onChange={(e) => setRole({ ...role, pdfcatelist: !role.pdfcatelist })} />} label="Pdf Category Wise List" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Category Wise Summary */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcatesummary} onChange={(e) => setRole({ ...role, allcatesummary: !role.allcatesummary })} />} label="Category Wise Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcatesummary} onChange={(e) => cateSummaryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcatesummary} onChange={(e) => setRole({ ...role, excelcatesummary: !role.excelcatesummary })} />} label="Excel Category Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcatesummary} onChange={(e) => setRole({ ...role, csvcatesummary: !role.csvcatesummary })} />} label="Csv Category Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcatesummary} onChange={(e) => setRole({ ...role, printcatesummary: !role.printcatesummary })} />} label="Print Category Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcatesummary} onChange={(e) => setRole({ ...role, pdfcatesummary: !role.pdfcatesummary })} />} label="Pdf Category Wise Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Manufacture Wise List */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufacturelist} onChange={(e) => setRole({ ...role, allmanufacturelist: !role.allmanufacturelist })} />} label="Manufacture Wise List" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufacturelist} onChange={(e) => manufactureWiseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufacturelist} onChange={(e) => setRole({ ...role, excelmanufacturelist: !role.excelmanufacturelist })} />} label="Excel Manufacture Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufacturelist} onChange={(e) => setRole({ ...role, csvmanufacturelist: !role.csvmanufacturelist })} />} label="Csv Manufacture Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufacturelist} onChange={(e) => setRole({ ...role, printmanufacturelist: !role.printmanufacturelist })} />} label="Print Manufacture Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufacturelist} onChange={(e) => setRole({ ...role, pdfmanufacturelist: !role.pdfmanufacturelist })} />} label="Pdf Manufacture Wise List" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Manufacture Wise summary */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufacturesummary} onChange={(e) => setRole({ ...role, allmanufacturesummary: !role.allmanufacturesummary })} />} label="Manufacture Wise summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufacturesummary} onChange={(e) => manufactureWiseSummAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufacturesummary} onChange={(e) => setRole({ ...role, excelmanufacturesummary: !role.excelmanufacturesummary })} />} label="Excel Manufacture Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufacturesummary} onChange={(e) => setRole({ ...role, csvmanufacturesummary: !role.csvmanufacturesummary })} />} label="Csv Manufacture Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufacturesummary} onChange={(e) => setRole({ ...role, printmanufacturesummary: !role.printmanufacturesummary })} />} label="Print Manufacture Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufactursummary} onChange={(e) => setRole({ ...role, pdfmanufactursummary: !role.pdfmanufactursummary })} />} label="Pdf Manufacture Wise Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Molecule Wise List */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmolecule} onChange={(e) => setRole({ ...role, allmolecule: !role.allmolecule })} />} label="Molecule Wise List" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmolecule} onChange={(e) => moleculeWiseListAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmolecule} onChange={(e) => setRole({ ...role, excelmolecule: !role.excelmolecule })} />} label="Excel Molecule Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmoleslist} onChange={(e) => setRole({ ...role, csvmoleslist: !role.csvmoleslist })} />} label="Csv Molecule Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsmolelist} onChange={(e) => setRole({ ...role, printsmolelist: !role.printsmolelist })} />} label="Print Molecule Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmolecule} onChange={(e) => setRole({ ...role, pdfmolecule: !role.pdfmolecule })} />} label="Pdf Molecule Wise List" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Molecule Wise summary */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmolesummary} onChange={(e) => setRole({ ...role, allmolesummary: !role.allmolesummary })} />} label="Molecule Wise Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmolesummary} onChange={(e) => moleculeWiseSummAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmolesummary} onChange={(e) => setRole({ ...role, excelmolesummary: !role.excelmolesummary })} />} label="Excel Molecule Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmolessummary} onChange={(e) => setRole({ ...role, csvmolessummary: !role.csvmolessummary })} />} label="Csv Molecule Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsmolesummary} onChange={(e) => setRole({ ...role, printsmolesummary: !role.printsmolesummary })} />} label="Print Molecule Wise Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmolesummary} onChange={(e) => setRole({ ...role, pdfmolesummary: !role.pdfmolesummary })} />} label="Pdf Molecule Wise Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Schedule Wise List */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allschedulelist} onChange={(e) => setRole({ ...role, allschedulelist: !role.allschedulelist })} />} label="Schedule Wise List" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallschedulelist} onChange={(e) => scheduleWiseListAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelschedulelist} onChange={(e) => setRole({ ...role, excelschedulelist: !role.excelschedulelist })} />} label="Excel Schedule Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvschedulelist} onChange={(e) => setRole({ ...role, csvschedulelist: !role.csvschedulelist })} />} label="Csv Schedule Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printschedulelist} onChange={(e) => setRole({ ...role, printschedulelist: !role.printschedulelist })} />} label="Print Schedule Wise List" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfschedulelist} onChange={(e) => setRole({ ...role, pdfschedulelist: !role.pdfschedulelist })} />} label="Pdf Schedule Wise List" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Schedule */}

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allschedule} onChange={(e) => setRole({ ...role, allschedule: !role.allschedule })} />} label="Schedule" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallschedule} onChange={(e) => scheduleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelschedule} onChange={(e) => setRole({ ...role, excelschedule: !role.excelschedule })} />} label="Excel Schedule" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvschedule} onChange={(e) => setRole({ ...role, csvschedule: !role.csvschedule })} />} label="Csv Schedule" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printschedule} onChange={(e) => setRole({ ...role, printschedule: !role.printschedule })} />} label="Print Schedule" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfschedule} onChange={(e) => setRole({ ...role, pdfschedule: !role.pdfschedule })} />} label="Pdf Schedule" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eschedule} onChange={(e) => setRole({ ...role, eschedule: !role.eschedule })} />} label="Edit Schedule" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dschedule} onChange={(e) => setRole({ ...role, dschedule: !role.dschedule })} />} label="Delete Schedule" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Product Management End */}

                        {/* Purchase Module */}
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.purchasemanagement} onChange={(e) => setRole({ ...role, purchasemanagement: !role.purchasemanagement })} />} label="Purchase management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpurchase} onChange={(e) => setRole({ ...role, allpurchase: !role.allpurchase })} />} label="Purchase" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpurchase} onChange={(e) => purchaseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.apurchase} onChange={(e) => setRole({ ...role, apurchase: !role.apurchase })} />} label="Add Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vpurchase} onChange={(e) => setRole({ ...role, vpurchase: !role.vpurchase })} />} label="View Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.epurchase} onChange={(e) => setRole({ ...role, epurchase: !role.epurchase })} />} label="Edit Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dpurchase} onChange={(e) => setRole({ ...role, dpurchase: !role.dpurchase })} />} label="Delete Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpurchase} onChange={(e) => setRole({ ...role, excelpurchase: !role.excelpurchase })} />} label="Excel Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpurchase} onChange={(e) => setRole({ ...role, csvpurchase: !role.csvpurchase })} />} label="CSV Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpurchase} onChange={(e) => setRole({ ...role, printpurchase: !role.printpurchase })} />} label="Print Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpurchase} onChange={(e) => setRole({ ...role, pdfpurchase: !role.pdfpurchase })} />} label="Pdf Purchase" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Purchase return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpurchasereturnlist} onChange={(e) => setRole({ ...role, allpurchasereturnlist: !role.allpurchasereturnlist })} />} label="Purchase Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpurchasereturnlist} onChange={(e) => purchaseReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.apurchasereturnlist} onChange={(e) => setRole({ ...role, apurchasereturnlist: !role.apurchasereturnlist })} />} label="Add Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vpurchasereturnlist} onChange={(e) => setRole({ ...role, vpurchasereturnlist: !role.vpurchasereturnlist })} />} label="View Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.epurchasereturnlist} onChange={(e) => setRole({ ...role, epurchasereturnlist: !role.epurchasereturnlist })} />} label="Edit Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dpurchasereturnlist} onChange={(e) => setRole({ ...role, dpurchasereturnlist: !role.dpurchasereturnlist })} />} label="Delete Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpurchasereturnlist} onChange={(e) => setRole({ ...role, csvpurchasereturnlist: !role.csvpurchasereturnlist })} />} label="Excel Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpurchasereturnlist} onChange={(e) => setRole({ ...role, excelpurchasereturnlist: !role.excelpurchasereturnlist })} />} label="CSV Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpurchasereturnlist} onChange={(e) => setRole({ ...role, printpurchasereturnlist: !role.printpurchasereturnlist })} />} label="Print Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpurchasereturnlist} onChange={(e) => setRole({ ...role, pdfpurchasereturnlist: !role.pdfpurchasereturnlist })} />} label="Pdf Purchase Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Supplier product */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierproduct} onChange={(e) => setRole({ ...role, allsupplierproduct: !role.allsupplierproduct })} />} label="Supplier Product" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierproduct} onChange={(e) => supplierProductAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductadd} onChange={(e) => setRole({ ...role, supplierproductadd: !role.supplierproductadd })} />} label="Add Supplier Product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductview} onChange={(e) => setRole({ ...role, supplierproductview: !role.supplierproductview })} />} label="View Supplier Product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductedit} onChange={(e) => setRole({ ...role, supplierproductedit: !role.supplierproductedit })} />} label="Edit Supplier Product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductdelete} onChange={(e) => setRole({ ...role, supplierproductdelete: !role.supplierproductdelete })} />} label="Delete Supplier Product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductcsv} onChange={(e) => setRole({ ...role, supplierproductcsv: !role.supplierproductcsv })} />} label="CSV Supplier Product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductexcel} onChange={(e) => setRole({ ...role, supplierproductexcel: !role.supplierproductexcel })} />} label="Excel Supplier Product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductprint} onChange={(e) => setRole({ ...role, supplierproductprint: !role.supplierproductprint })} />} label="Print Supplier Product" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.supplierproductpdf} onChange={(e) => setRole({ ...role, supplierproductpdf: !role.supplierproductpdf })} />} label="Pdf Supplier Product" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Category Wise Purchase */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcategorywisepurchase} onChange={(e) => setRole({ ...role, allcategorywisepurchase: !role.allcategorywisepurchase })} />} label="Category Wise Purchase" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcategorywisepurchase} onChange={(e) => categoryWisePurchaseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcategorywisepurchase} onChange={(e) => setRole({ ...role, csvcategorywisepurchase: !role.csvcategorywisepurchase })} />} label="CSV Category Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcategorywisepurchase} onChange={(e) => setRole({ ...role, excelcategorywisepurchase: !role.excelcategorywisepurchase })} />} label="Excel Category Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcategorywisepurchase} onChange={(e) => setRole({ ...role, printcategorywisepurchase: !role.printcategorywisepurchase })} />} label="Print Category Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcategorywisepurchase} onChange={(e) => setRole({ ...role, pdfcategorywisepurchase: !role.pdfcategorywisepurchase })} />} label="Pdf Category Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Item Wise Purchase */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwisepurchasereport} onChange={(e) => setRole({ ...role, allitemwisepurchasereport: !role.allitemwisepurchasereport })} />} label="Item Wise Purchase" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwisepurchasereport} onChange={(e) => itemWisePurchaseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwisepurchasereport} onChange={(e) => setRole({ ...role, csvitemwisepurchasereport: !role.csvitemwisepurchasereport })} />} label="CSV Item Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwisepurchasereport} onChange={(e) => setRole({ ...role, excelitemwisepurchasereport: !role.excelitemwisepurchasereport })} />} label="Excel Item Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwisepurchasereport} onChange={(e) => setRole({ ...role, printitemwisepurchasereport: !role.printitemwisepurchasereport })} />} label="Print Item Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwisepurchasereport} onChange={(e) => setRole({ ...role, pdfitemwisepurchasereport: !role.pdfitemwisepurchasereport })} />} label="Pdf Category Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Item Wise Purchase Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwisepurchasereturn} onChange={(e) => setRole({ ...role, allitemwisepurchasereturn: !role.allitemwisepurchasereturn })} />} label="Item Wise Purchase Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwisepurchasereturn} onChange={(e) => itemWisePurchaseReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwisepurchasereturn} onChange={(e) => setRole({ ...role, csvitemwisepurchasereturn: !role.csvitemwisepurchasereturn })} />} label="CSV Item Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwisepurchasereturn} onChange={(e) => setRole({ ...role, excelitemwisepurchasereturn: !role.excelitemwisepurchasereturn })} />} label="Excel Item Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwisepurchasereturn} onChange={(e) => setRole({ ...role, printitemwisepurchasereturn: !role.printitemwisepurchasereturn })} />} label="Print Item Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwisepurchasereturn} onChange={(e) => setRole({ ...role, pdfitemwisepurchasereturn: !role.pdfitemwisepurchasereturn })} />} label="Pdf Item Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Manufacture Wise Purchase */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufacturewisepurchase} onChange={(e) => setRole({ ...role, allmanufacturewisepurchase: !role.allmanufacturewisepurchase })} />} label="Manufacture Wise Purchase Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufacturewisepurchase} onChange={(e) => manufactureWisePurchaseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufacturewisepurchase} onChange={(e) => setRole({ ...role, csvmanufacturewisepurchase: !role.csvmanufacturewisepurchase })} />} label="CSV Manufacture Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufacturwisepurchase} onChange={(e) => setRole({ ...role, excelmanufacturwisepurchase: !role.excelmanufacturwisepurchase })} />} label="Excel Manufacture Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufacturewisepurchase} onChange={(e) => setRole({ ...role, printmanufacturewisepurchase: !role.printmanufacturewisepurchase })} />} label="Print Manufacture Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufacturewisepurchase} onChange={(e) => setRole({ ...role, pdfmanufacturewisepurchase: !role.pdfmanufacturewisepurchase })} />} label="Pdf Manufacture Wise Purchase Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Purchase detailed report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpurchasedetailedreport} onChange={(e) => setRole({ ...role, allpurchasedetailedreport: !role.allpurchasedetailedreport })} />} label="Purchase Detailed Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpurchasedetailedreport} onChange={(e) => purchaseDetailedReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpurchasedetailedreport} onChange={(e) => setRole({ ...role, csvpurchasedetailedreport: !role.csvpurchasedetailedreport })} />} label="CSV Purchase Detailed Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpurchasedetailedreport} onChange={(e) => setRole({ ...role, excelpurchasedetailedreport: !role.excelpurchasedetailedreport })} />} label="Excel Purchase Detailed Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpurchasedetailedreport} onChange={(e) => setRole({ ...role, printpurchasedetailedreport: !role.printpurchasedetailedreport })} />} label="Print Purchase Detailed Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpurchasedetailedreport} onChange={(e) => setRole({ ...role, pdfpurchasedetailedreport: !role.pdfpurchasedetailedreport })} />} label="Pdf Purchase Detailed Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Purchase report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpurchasereport} onChange={(e) => setRole({ ...role, allpurchasereport: !role.allpurchasereport })} />} label="Purchase Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpurchasereport} onChange={(e) => purchaseReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpurchasereport} onChange={(e) => setRole({ ...role, csvpurchasereport: !role.csvpurchasereport })} />} label="CSV Purchase Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpurchasereport} onChange={(e) => setRole({ ...role, excelpurchasereport: !role.excelpurchasereport })} />} label="Excel Purchase Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpurchasereport} onChange={(e) => setRole({ ...role, printpurchasereport: !role.printpurchasereport })} />} label="Print Purchase Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpurchasereport} onChange={(e) => setRole({ ...role, pdfpurchasereport: !role.pdfpurchasereport })} />} label="Pdf Purchase Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Purchase return report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpurchasereturnreport} onChange={(e) => setRole({ ...role, allpurchasereturnreport: !role.allpurchasereturnreport })} />} label="Purchase Return Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpurchasereturnreport} onChange={(e) => purchaseReturnReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpurchasereturnreport} onChange={(e) => setRole({ ...role, csvpurchasereturnreport: !role.csvpurchasereturnreport })} />} label="CSV Purchase Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpurchasereturnreport} onChange={(e) => setRole({ ...role, excelpurchasereturnreport: !role.excelpurchasereturnreport })} />} label="Excel Purchase Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpurchasereturnreport} onChange={(e) => setRole({ ...role, printpurchasereturnreport: !role.printpurchasereturnreport })} />} label="Print Purchase Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpurchasereturnreport} onChange={(e) => setRole({ ...role, pdfpurchasereturnreport: !role.pdfpurchasereturnreport })} />} label="Pdf Purchase Return Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Supplier wise purchase */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierwisepurchase} onChange={(e) => setRole({ ...role, allsupplierwisepurchase: !role.allsupplierwisepurchase })} />} label="Supplier Wise Purchase" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierwisepurchase} onChange={(e) => supplierWisePurchaseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierwisepurchase} onChange={(e) => setRole({ ...role, csvsupplierwisepurchase: !role.csvsupplierwisepurchase })} />} label="CSV Supplier Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierwisepurchase} onChange={(e) => setRole({ ...role, excelsupplierwisepurchase: !role.excelsupplierwisepurchase })} />} label="Excel Supplier Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierwisepurchase} onChange={(e) => setRole({ ...role, printsupplierwisepurchase: !role.printsupplierwisepurchase })} />} label="Print Supplier Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierwisepurchase} onChange={(e) => setRole({ ...role, pdfsupplierwisepurchase: !role.pdfsupplierwisepurchase })} />} label="Pdf Supplier Wise Purchase" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Supplier wise purchase report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierwisepurchasereport} onChange={(e) => setRole({ ...role, allsupplierwisepurchasereport: !role.allsupplierwisepurchasereport })} />} label="Supplier Wise Purchase Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierwisepurchasereport} onChange={(e) => supplierWisePurchaseReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierwisepurchasereport} onChange={(e) => setRole({ ...role, csvsupplierwisepurchasereport: !role.csvsupplierwisepurchasereport })} />} label="CSV Supplier Wise Purchase Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierwisepurchasereport} onChange={(e) => setRole({ ...role, excelsupplierwisepurchasereport: !role.excelsupplierwisepurchasereport })} />} label="Excel Supplier Wise Purchase Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierwisepurchasereport} onChange={(e) => setRole({ ...role, printsupplierwisepurchasereport: !role.printsupplierwisepurchasereport })} />} label="Print Supplier Wise Purchase Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierwisepurchasereport} onChange={(e) => setRole({ ...role, pdfsupplierwisepurchasereport: !role.pdfsupplierwisepurchasereport })} />} label="Pdf Supplier Wise Purchase Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Purchase Module End */}
                        {/* Sell Start */}
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.sellmanagement} onChange={(e) => setRole({ ...role, sellmanagement: !role.sellmanagement })} />} label="Sell management" />
                            </FormGroup>
                        </Grid>
                        {/* Draft */}
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allposdraft} onChange={(e) => setRole({ ...role, allposdraft: !role.allposdraft })} />} label="Sell Draft" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallposdraft} onChange={(e) => posDraftAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vposdraft} onChange={(e) => setRole({ ...role, vposdraft: !role.vposdraft })} />} label="View Sell Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aposdraft} onChange={(e) => setRole({ ...role, aposdraft: !role.aposdraft })} />} label="Add Sell Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eposdraft} onChange={(e) => setRole({ ...role, eposdraft: !role.eposdraft })} />} label="Edit Sell Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dposdraft} onChange={(e) => setRole({ ...role, dposdraft: !role.dposdraft })} />} label="Delete Sell Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvposdraft} onChange={(e) => setRole({ ...role, csvposdraft: !role.csvposdraft })} />} label="CSV Sell Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelposdraft} onChange={(e) => setRole({ ...role, excelposdraft: !role.excelposdraft })} />} label="Excel Sell Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printposdraft} onChange={(e) => setRole({ ...role, printposdraft: !role.printposdraft })} />} label="Print Sell Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfposdraft} onChange={(e) => setRole({ ...role, pdfposdraft: !role.pdfposdraft })} />} label="Pdf Sell Draft" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* POS */}
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allposlist} onChange={(e) => setRole({ ...role, allposlist: !role.allposlist })} />} label="POS" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallposlist} onChange={(e) => posListAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aposlist} onChange={(e) => setRole({ ...role, aposlist: !role.aposlist })} />} label="Add POS" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eposlist} onChange={(e) => setRole({ ...role, eposlist: !role.eposlist })} />} label="Edit POS" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vposlist} onChange={(e) => setRole({ ...role, vposlist: !role.vposlist })} />} label="View POS" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dposlist} onChange={(e) => setRole({ ...role, dposlist: !role.dposlist })} />} label="Delete POS" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvposlist} onChange={(e) => setRole({ ...role, csvposlist: !role.csvposlist })} />} label="CSV POS" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelposlist} onChange={(e) => setRole({ ...role, excelposlist: !role.excelposlist })} />} label="Excel POS" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printposlist} onChange={(e) => setRole({ ...role, printposlist: !role.printposlist })} />} label="Print POS" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfposlist} onChange={(e) => setRole({ ...role, pdfposlist: !role.pdfposlist })} />} label="Pdf POS" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Quotation */}
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allposquotation} onChange={(e) => setRole({ ...role, allposquotation: !role.allposquotation })} />} label="Sell Quotation" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallposquotation} onChange={(e) => posQuotationAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aposquotation} onChange={(e) => setRole({ ...role, aposquotation: !role.aposquotation })} />} label="Add Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vposquotation} onChange={(e) => setRole({ ...role, vposquotation: !role.vposquotation })} />} label="View Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eposquotation} onChange={(e) => setRole({ ...role, eposquotation: !role.eposquotation })} />} label="Edit Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dposquotation} onChange={(e) => setRole({ ...role, dposquotation: !role.dposquotation })} />} label="Delete Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvposquotation} onChange={(e) => setRole({ ...role, csvposquotation: !role.csvposquotation })} />} label="CSV Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelposquotation} onChange={(e) => setRole({ ...role, excelposquotation: !role.excelposquotation })} />} label="Excel Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printposquotation} onChange={(e) => setRole({ ...role, printposquotation: !role.printposquotation })} />} label="Print Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfposquotation} onChange={(e) => setRole({ ...role, pdfposquotation: !role.pdfposquotation })} />} label="Pdf Sell Quotation" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Area Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allareawisesales} onChange={(e) => setRole({ ...role, allareawisesales: !role.allareawisesales })} />} label="Area Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallareawisesales} onChange={(e) => areaWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvareawisesales} onChange={(e) => setRole({ ...role, csvareawisesales: !role.csvareawisesales })} />} label="CSV Area Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelareawisesales} onChange={(e) => setRole({ ...role, excelareawisesales: !role.excelareawisesales })} />} label="Excel Area Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printareawisesales} onChange={(e) => setRole({ ...role, printareawisesales: !role.printareawisesales })} />} label="Print Area Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfareawisesales} onChange={(e) => setRole({ ...role, pdfareawisesales: !role.pdfareawisesales })} />} label="Pdf Area Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Category Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcategorywisesales} onChange={(e) => setRole({ ...role, allcategorywisesales: !role.allcategorywisesales })} />} label="Category Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcategorywisesales} onChange={(e) => categoryWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcategorywisesales} onChange={(e) => setRole({ ...role, csvcategorywisesales: !role.csvcategorywisesales })} />} label="CSV Category Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcategorywisesales} onChange={(e) => setRole({ ...role, excelcategorywisesales: !role.excelcategorywisesales })} />} label="Excel Category Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcategorywisesales} onChange={(e) => setRole({ ...role, printcategorywisesales: !role.printcategorywisesales })} />} label="Print Category Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcategorywisesales} onChange={(e) => setRole({ ...role, pdfcategorywisesales: !role.pdfcategorywisesales })} />} label="Pdf Category Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Day Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldaywisesales} onChange={(e) => setRole({ ...role, alldaywisesales: !role.alldaywisesales })} />} label="Day Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldaywisesales} onChange={(e) => dayWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdaywisesales} onChange={(e) => setRole({ ...role, csvdaywisesales: !role.csvdaywisesales })} />} label="CSV Day Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldaywisesales} onChange={(e) => setRole({ ...role, exceldaywisesales: !role.exceldaywisesales })} />} label="Excel Day Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdaywisesales} onChange={(e) => setRole({ ...role, printdaywisesales: !role.printdaywisesales })} />} label="Print Day Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdaywisesales} onChange={(e) => setRole({ ...role, pdfdaywisesales: !role.pdfdaywisesales })} />} label="Pdf Day Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Diagnosis Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldiagnosiswisesales} onChange={(e) => setRole({ ...role, alldiagnosiswisesales: !role.alldiagnosiswisesales })} />} label="Diagnosis Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldiagnosiswisesales} onChange={(e) => diagnosisWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdiagnosiswisesales} onChange={(e) => setRole({ ...role, csvdiagnosiswisesales: !role.csvdiagnosiswisesales })} />} label="CSV Diagnosis Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldiagnosiswisesales} onChange={(e) => setRole({ ...role, exceldiagnosiswisesales: !role.exceldiagnosiswisesales })} />} label="Excel Diagnosis Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdiagnosiswisesales} onChange={(e) => setRole({ ...role, printdiagnosiswisesales: !role.printdiagnosiswisesales })} />} label="Print Diagnosis Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdiagnosiswisesales} onChange={(e) => setRole({ ...role, pdfdiagnosiswisesales: !role.pdfdiagnosiswisesales })} />} label="Pdf Diagnosis Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Diagnosis Wise Sales Summary */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldiagnosiswisesalessummary} onChange={(e) => setRole({ ...role, alldiagnosiswisesalessummary: !role.alldiagnosiswisesalessummary })} />} label="Diagnosis Wise Sales Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldiagnosiswisesalessummary} onChange={(e) => diagnosisWiseSalesSummaryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdiagnosiswisesalessummary} onChange={(e) => setRole({ ...role, csvdiagnosiswisesalessummary: !role.csvdiagnosiswisesalessummary })} />} label="CSV Diagnosis Wise Sales Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldiagnosiswisesalessummary} onChange={(e) => setRole({ ...role, exceldiagnosiswisesalessummary: !role.exceldiagnosiswisesalessummary })} />} label="Excel Diagnosis Wise Sales Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdiagnosiswisesalessummary} onChange={(e) => setRole({ ...role, printdiagnosiswisesalessummary: !role.printdiagnosiswisesalessummary })} />} label="Print Diagnosis Wise Sales Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdiagnosiswisesalessummary} onChange={(e) => setRole({ ...role, pdfdiagnosiswisesalessummary: !role.pdfdiagnosiswisesalessummary })} />} label="Pdf Diagnosis Wise Sales Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Drug Register */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allselldrugregister} onChange={(e) => setRole({ ...role, allselldrugregister: !role.allselldrugregister })} />} label="Sell Drug Register" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallselldrugregister} onChange={(e) => drugregisterAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvselldrugregister} onChange={(e) => setRole({ ...role, csvselldrugregister: !role.csvselldrugregister })} />} label="CSV Sell Drug Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelselldrugregister} onChange={(e) => setRole({ ...role, excelselldrugregister: !role.excelselldrugregister })} />} label="Excel Sell Drug Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printselldrugregister} onChange={(e) => setRole({ ...role, printselldrugregister: !role.printselldrugregister })} />} label="Print Sell Drug Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfselldrugregister} onChange={(e) => setRole({ ...role, pdfselldrugregister: !role.pdfselldrugregister })} />} label="Pdf Sell Drug Register" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Dr Wise Sale Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldrwisesalereport} onChange={(e) => setRole({ ...role, alldrwisesalereport: !role.alldrwisesalereport })} />} label="Dr Wise Sale Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldrwisesalereport} onChange={(e) => drWiseSaleReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdrwisesalereport} onChange={(e) => setRole({ ...role, csvdrwisesalereport: !role.csvdrwisesalereport })} />} label="CSV Dr Wise Sale Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldrwisesalereport} onChange={(e) => setRole({ ...role, exceldrwisesalereport: !role.exceldrwisesalereport })} />} label="Excel Dr Wise Sale Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdrwisesalereport} onChange={(e) => setRole({ ...role, printdrwisesalereport: !role.printdrwisesalereport })} />} label="Print Dr Wise Sale Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdrwisesalereport} onChange={(e) => setRole({ ...role, pdfdrwisesalereport: !role.pdfdrwisesalereport })} />} label="Pdf Dr Wise Sale Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Dr Wise Sale */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldrwisesale} onChange={(e) => setRole({ ...role, alldrwisesale: !role.alldrwisesale })} />} label="Dr Wise Sale" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldrwisesale} onChange={(e) => drWiseSaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdrwisesale} onChange={(e) => setRole({ ...role, csvdrwisesale: !role.csvdrwisesale })} />} label="CSV Dr Wise Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldrwisesale} onChange={(e) => setRole({ ...role, exceldrwisesale: !role.exceldrwisesale })} />} label="Excel Dr Wise Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdrwisesale} onChange={(e) => setRole({ ...role, printdrwisesale: !role.printdrwisesale })} />} label="Print Dr Wise Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdrwisesale} onChange={(e) => setRole({ ...role, pdfdrwisesale: !role.pdfdrwisesale })} />} label="Pdf Dr Wise Sale" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Item Wise Sales Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwisesalesreturn} onChange={(e) => setRole({ ...role, allitemwisesalesreturn: !role.allitemwisesalesreturn })} />} label="Item Wise Sales Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwisesalesreturn} onChange={(e) => itemWiseSalesReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwisesalesreturn} onChange={(e) => setRole({ ...role, csvitemwisesalesreturn: !role.csvitemwisesalesreturn })} />} label="CSV Item Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwisesalesreturn} onChange={(e) => setRole({ ...role, excelitemwisesalesreturn: !role.excelitemwisesalesreturn })} />} label="Excel Item Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwisesalesreturn} onChange={(e) => setRole({ ...role, printitemwisesalesreturn: !role.printitemwisesalesreturn })} />} label="Print Item Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwisesalesreturn} onChange={(e) => setRole({ ...role, pdfitemwisesalesreturn: !role.pdfitemwisesalesreturn })} />} label="Pdf Item Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Item Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwisesales} onChange={(e) => setRole({ ...role, allitemwisesales: !role.allitemwisesales })} />} label="Item Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwisesales} onChange={(e) => itemWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwisesales} onChange={(e) => setRole({ ...role, csvitemwisesales: !role.csvitemwisesales })} />} label="CSV Item Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwisesales} onChange={(e) => setRole({ ...role, excelitemwisesales: !role.excelitemwisesales })} />} label="Excel Item Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwisesales} onChange={(e) => setRole({ ...role, printitemwisesales: !role.printitemwisesales })} />} label="Print Item Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwisesales} onChange={(e) => setRole({ ...role, pdfitemwisesales: !role.pdfitemwisesales })} />} label="Pdf Item Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Manufacture Wise Sales Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufacturewisesalesreturn} onChange={(e) => setRole({ ...role, allmanufacturewisesalesreturn: !role.allmanufacturewisesalesreturn })} />} label="Manufacture Wise Sales Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufacturewisesalesreturn} onChange={(e) => manufactureWiseSalesReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufacturewisesalesreturn} onChange={(e) => setRole({ ...role, csvmanufacturewisesalesreturn: !role.csvmanufacturewisesalesreturn })} />} label="CSV Manufacture Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufacturewisesalesreturn} onChange={(e) => setRole({ ...role, excelmanufacturewisesalesreturn: !role.excelmanufacturewisesalesreturn })} />} label="Excel Manufacture Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufacturewisesalesreturn} onChange={(e) => setRole({ ...role, printmanufacturewisesalesreturn: !role.printmanufacturewisesalesreturn })} />} label="Print Manufacture Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufacturewisesalesreturn} onChange={(e) => setRole({ ...role, pdfmanufacturewisesalesreturn: !role.pdfmanufacturewisesalesreturn })} />} label="Pdf Manufacture Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Manufacture Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufacturewisesales} onChange={(e) => setRole({ ...role, allmanufacturewisesales: !role.allmanufacturewisesales })} />} label="Manufacture Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufacturewisesales} onChange={(e) => manufactureWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufacturewisesales} onChange={(e) => setRole({ ...role, csvmanufacturewisesales: !role.csvmanufacturewisesales })} />} label="CSV Manufacture Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufacturewisesales} onChange={(e) => setRole({ ...role, excelmanufacturewisesales: !role.excelmanufacturewisesales })} />} label="Excel Manufacture Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufacturewisesales} onChange={(e) => setRole({ ...role, printmanufacturewisesales: !role.printmanufacturewisesales })} />} label="Print Manufacture Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufacturewisesales} onChange={(e) => setRole({ ...role, pdfmanufacturewisesales: !role.pdfmanufacturewisesales })} />} label="Pdf Manufacture Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Molecule Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmoleculewisesales} onChange={(e) => setRole({ ...role, allmoleculewisesales: !role.allmoleculewisesales })} />} label="Molecule Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmoleculewisesales} onChange={(e) => moleculeWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmoleculewisesales} onChange={(e) => setRole({ ...role, csvmoleculewisesales: !role.csvmoleculewisesales })} />} label="CSV Molecule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmoleculewisesales} onChange={(e) => setRole({ ...role, excelmoleculewisesales: !role.excelmoleculewisesales })} />} label="Excel Molecule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmoleculewisesales} onChange={(e) => setRole({ ...role, printmoleculewisesales: !role.printmoleculewisesales })} />} label="Print Molecule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmoleculewisesales} onChange={(e) => setRole({ ...role, pdfmoleculewisesales: !role.pdfmoleculewisesales })} />} label="Pdf Molecule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Patient Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpatientwisesales} onChange={(e) => setRole({ ...role, allpatientwisesales: !role.allpatientwisesales })} />} label="Patient Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpatientwisesales} onChange={(e) => patientWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpatientwisesales} onChange={(e) => setRole({ ...role, csvpatientwisesales: !role.csvpatientwisesales })} />} label="CSV Patient Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpatientwisesales} onChange={(e) => setRole({ ...role, excelpatientwisesales: !role.excelpatientwisesales })} />} label="Excel Patient Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpatientwisesales} onChange={(e) => setRole({ ...role, printpatientwisesales: !role.printpatientwisesales })} />} label="Print Patient Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpatientwisesales} onChange={(e) => setRole({ ...role, pdfpatientwisesales: !role.pdfpatientwisesales })} />} label="Pdf Patient Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Patient Wise Sales Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpatientwisesalesreport} onChange={(e) => setRole({ ...role, allpatientwisesalesreport: !role.allpatientwisesalesreport })} />} label="Patient Wise Sales Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpatientwisesalesreport} onChange={(e) => patientWiseSalesReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpatientwisesalesreport} onChange={(e) => setRole({ ...role, csvpatientwisesalesreport: !role.csvpatientwisesalesreport })} />} label="CSV Patient Wise Sales Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpatientwisesalesreport} onChange={(e) => setRole({ ...role, excelpatientwisesalesreport: !role.excelpatientwisesalesreport })} />} label="Excel Patient Wise Sales Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpatientwisesalesreport} onChange={(e) => setRole({ ...role, printpatientwisesalesreport: !role.printpatientwisesalesreport })} />} label="Print Patient Wise Sales Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpatientwisesalesreport} onChange={(e) => setRole({ ...role, pdfpatientwisesalesreport: !role.pdfpatientwisesalesreport })} />} label="Pdf Patient Wise Sales Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Sales Return Details */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsalesreturndetails} onChange={(e) => setRole({ ...role, allsalesreturndetails: !role.allsalesreturndetails })} />} label="Sales Return Details" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsalesreturndetails} onChange={(e) => salesReturnDetailsAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsalesreturndetails} onChange={(e) => setRole({ ...role, csvsalesreturndetails: !role.csvsalesreturndetails })} />} label="CSV Sales Return Details" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsalesreturndetails} onChange={(e) => setRole({ ...role, excelsalesreturndetails: !role.excelsalesreturndetails })} />} label="Excel Sales Return Details" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsalesreturndetails} onChange={(e) => setRole({ ...role, printsalesreturndetails: !role.printsalesreturndetails })} />} label="Print Sales Return Details" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsalesreturndetails} onChange={(e) => setRole({ ...role, pdfsalesreturndetails: !role.pdfsalesreturndetails })} />} label="Pdf Sales Return Details" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Sales Return Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsalesreturnreport} onChange={(e) => setRole({ ...role, allsalesreturnreport: !role.allsalesreturnreport })} />} label="Sales Return Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsalesreturnreport} onChange={(e) => salesReturnReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsalesreturnreport} onChange={(e) => setRole({ ...role, csvsalesreturnreport: !role.csvsalesreturnreport })} />} label="CSV Sales Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsalesreturnreport} onChange={(e) => setRole({ ...role, excelsalesreturnreport: !role.excelsalesreturnreport })} />} label="Excel Sales Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsalesreturnreport} onChange={(e) => setRole({ ...role, printsalesreturnreport: !role.printsalesreturnreport })} />} label="Print Sales Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsalesreturnreport} onChange={(e) => setRole({ ...role, pdfsalesreturnreport: !role.pdfsalesreturnreport })} />} label="Pdf Sales Return Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Sales Return Summary */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsalesreturnsummary} onChange={(e) => setRole({ ...role, allsalesreturnsummary: !role.allsalesreturnsummary })} />} label="Sales Return Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsalesreturnsummary} onChange={(e) => salesReturnSummaryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsalesreturnsummary} onChange={(e) => setRole({ ...role, csvsalesreturnsummary: !role.csvsalesreturnsummary })} />} label="CSV Sales Return Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsalesreturnsummary} onChange={(e) => setRole({ ...role, excelsalesreturnsummary: !role.excelsalesreturnsummary })} />} label="Excel Sales Return Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsalesreturnsummary} onChange={(e) => setRole({ ...role, printsalesreturnsummary: !role.printsalesreturnsummary })} />} label="Print Sales Return Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsalesreturnsummary} onChange={(e) => setRole({ ...role, pdfsalesreturnsummary: !role.pdfsalesreturnsummary })} />} label="Pdf Sales Return Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Sales Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsalesreport} onChange={(e) => setRole({ ...role, allsalesreport: !role.allsalesreport })} />} label="Sales Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsalesreport} onChange={(e) => salesReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsalesreport} onChange={(e) => setRole({ ...role, csvsalesreport: !role.csvsalesreport })} />} label="CSV Sales Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsalesreport} onChange={(e) => setRole({ ...role, excelsalesreport: !role.excelsalesreport })} />} label="Excel Sales Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsalesreport} onChange={(e) => setRole({ ...role, printsalesreport: !role.printsalesreport })} />} label="Print Sales Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsalesreport} onChange={(e) => setRole({ ...role, pdfsalesreport: !role.pdfsalesreport })} />} label="Pdf Sales Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Sales Summary */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsalessummary} onChange={(e) => setRole({ ...role, allsalessummary: !role.allsalessummary })} />} label="Sales Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsalessummary} onChange={(e) => salesSummaryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsalessummary} onChange={(e) => setRole({ ...role, csvsalessummary: !role.csvsalessummary })} />} label="CSV Sales Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsalessummary} onChange={(e) => setRole({ ...role, excelsalessummary: !role.excelsalessummary })} />} label="Excel Sales Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsalessummary} onChange={(e) => setRole({ ...role, printsalessummary: !role.printsalessummary })} />} label="Print Sales Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsalessummary} onChange={(e) => setRole({ ...role, pdfsalessummary: !role.pdfsalessummary })} />} label="Pdf Sales Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Schedule Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allschedulewisesales} onChange={(e) => setRole({ ...role, allschedulewisesales: !role.allschedulewisesales })} />} label="Schedule Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallschedulewisesales} onChange={(e) => scheduleWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvschedulewisesales} onChange={(e) => setRole({ ...role, csvschedulewisesales: !role.csvschedulewisesales })} />} label="CSV Schedule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelschedulewisesales} onChange={(e) => setRole({ ...role, excelschedulewisesales: !role.excelschedulewisesales })} />} label="Excel Schedule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printschedulewisesales} onChange={(e) => setRole({ ...role, printschedulewisesales: !role.printschedulewisesales })} />} label="Print Schedule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfschedulewisesales} onChange={(e) => setRole({ ...role, pdfschedulewisesales: !role.pdfschedulewisesales })} />} label="Pdf Schedule Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Sell Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsellreturn} onChange={(e) => setRole({ ...role, allsellreturn: !role.allsellreturn })} />} label="Sell Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsellreturn} onChange={(e) => sellReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsellreturn} onChange={(e) => setRole({ ...role, csvsellreturn: !role.csvsellreturn })} />} label="CSV Sell Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsellreturn} onChange={(e) => setRole({ ...role, excelsellreturn: !role.excelsellreturn })} />} label="Excel Sell Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsellreturn} onChange={(e) => setRole({ ...role, printsellreturn: !role.printsellreturn })} />} label="Print Sell Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsellreturn} onChange={(e) => setRole({ ...role, pdfsellreturn: !role.pdfsellreturn })} />} label="Pdf Sell Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.esellreturn} onChange={(e) => setRole({ ...role, esellreturn: !role.esellreturn })} />} label="Edit Sell Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vsellreturn} onChange={(e) => setRole({ ...role, vsellreturn: !role.vsellreturn })} />} label="View Sell Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dsellreturn} onChange={(e) => setRole({ ...role, dsellreturn: !role.dsellreturn })} />} label="Delete Sell Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Supplier Wise Sales Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierwisesalesreturn} onChange={(e) => setRole({ ...role, allsupplierwisesalesreturn: !role.allsupplierwisesalesreturn })} />} label="Supplier Wise Sales Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierwisesalesreturn} onChange={(e) => supplierWiseSalesReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierwisesalesreturn} onChange={(e) => setRole({ ...role, csvsupplierwisesalesreturn: !role.csvsupplierwisesalesreturn })} />} label="CSV Supplier Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierwisesalesreturn} onChange={(e) => setRole({ ...role, excelsupplierwisesalesreturn: !role.excelsupplierwisesalesreturn })} />} label="Excel Supplier Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierwisesalesreturn} onChange={(e) => setRole({ ...role, printsupplierwisesalesreturn: !role.printsupplierwisesalesreturn })} />} label="Print Supplier Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierwisesalesreturn} onChange={(e) => setRole({ ...role, pdfsupplierwisesalesreturn: !role.pdfsupplierwisesalesreturn })} />} label="Pdf Supplier Wise Sales Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Supplier Wise Sales */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierwisesales} onChange={(e) => setRole({ ...role, allsupplierwisesales: !role.allsupplierwisesales })} />} label="Supplier Wise Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierwisesales} onChange={(e) => supplierWiseSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierwisesales} onChange={(e) => setRole({ ...role, excelsupplierwisesales: !role.excelsupplierwisesales })} />} label="CSV Supplier Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierwisesales} onChange={(e) => setRole({ ...role, csvsupplierwisesales: !role.csvsupplierwisesales })} />} label="Excel Supplier Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierwisesales} onChange={(e) => setRole({ ...role, printsupplierwisesales: !role.printsupplierwisesales })} />} label="Print Supplier Wise Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierwisesales} onChange={(e) => setRole({ ...role, pdfsupplierwisesales: !role.pdfsupplierwisesales })} />} label="Pdf Supplier Wise Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>



                        {/* Sell End */}
                        {/* Estimate Module */}
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.estimatemanagement} onChange={(e) => setRole({ ...role, estimatemanagement: !role.estimatemanagement })} />} label="Estimate management" />
                            </FormGroup>
                        </Grid>
                        {/* Draft */}
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allestimatedraft} onChange={(e) => setRole({ ...role, allestimatedraft: !role.allestimatedraft })} />} label="Estimate Draft" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallestimatedraft} onChange={(e) => estimateDraftAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vestimatedraft} onChange={(e) => setRole({ ...role, vestimatedraft: !role.vestimatedraft })} />} label="View Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aestimatedraft} onChange={(e) => setRole({ ...role, aestimatedraft: !role.aestimatedraft })} />} label="Add Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eestimatedraft} onChange={(e) => setRole({ ...role, eestimatedraft: !role.eestimatedraft })} />} label="Edit Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.destimatedraft} onChange={(e) => setRole({ ...role, destimatedraft: !role.destimatedraft })} />} label="Delete Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvestimatedraft} onChange={(e) => setRole({ ...role, csvestimatedraft: !role.csvestimatedraft })} />} label="CSV Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelestimatedraft} onChange={(e) => setRole({ ...role, excelestimatedraft: !role.excelestimatedraft })} />} label="Excel Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printestimatedraft} onChange={(e) => setRole({ ...role, printestimatedraft: !role.printestimatedraft })} />} label="Print Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfestimatedraft} onChange={(e) => setRole({ ...role, pdfestimatedraft: !role.pdfestimatedraft })} />} label="Pdf Estimate Draft" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Estimate List */}
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allestimatelist} onChange={(e) => setRole({ ...role, allestimatelist: !role.allestimatelist })} />} label="Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallestimatelist} onChange={(e) => estimateListtAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aestimatelist} onChange={(e) => setRole({ ...role, aestimatelist: !role.aestimatelist })} />} label="Add Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eestimatelist} onChange={(e) => setRole({ ...role, eestimatelist: !role.eestimatelist })} />} label="Edit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.destimatelist} onChange={(e) => setRole({ ...role, destimatelist: !role.destimatelist })} />} label="Delete Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vestimatelist} onChange={(e) => setRole({ ...role, vestimatelist: !role.vestimatelist })} />} label="View Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvestimatelist} onChange={(e) => setRole({ ...role, csvestimatelist: !role.csvestimatelist })} />} label="CSV Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelestimatelist} onChange={(e) => setRole({ ...role, excelestimatelist: !role.excelestimatelist })} />} label="Excel Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printestimatelist} onChange={(e) => setRole({ ...role, printestimatelist: !role.printestimatelist })} />} label="Print Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfestimatelist} onChange={(e) => setRole({ ...role, pdfestimatelist: !role.pdfestimatelist })} />} label="Pdf Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Quotation */}
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allestimatequotation} onChange={(e) => setRole({ ...role, allestimatequotation: !role.allestimatequotation })} />} label="Estimate Quotation" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallestimatequotation} onChange={(e) => estimateQuotationtAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vestimatequotation} onChange={(e) => setRole({ ...role, vestimatequotation: !role.vestimatequotation })} />} label="View Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aestimatequotation} onChange={(e) => setRole({ ...role, aestimatequotation: !role.aestimatequotation })} />} label="Add Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eestimatequotation} onChange={(e) => setRole({ ...role, eestimatequotation: !role.eestimatequotation })} />} label="Edit Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.destimatequotation} onChange={(e) => setRole({ ...role, destimatequotation: !role.destimatequotation })} />} label="Delete Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvestimatequotation} onChange={(e) => setRole({ ...role, csvestimatequotation: !role.csvestimatequotation })} />} label="CSV Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelestimatequotation} onChange={(e) => setRole({ ...role, excelestimatequotation: !role.excelestimatequotation })} />} label="Excel Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printestimatequotation} onChange={(e) => setRole({ ...role, printestimatequotation: !role.printestimatequotation })} />} label="Print Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfestimatequotation} onChange={(e) => setRole({ ...role, pdfestimatequotation: !role.pdfestimatequotation })} />} label="Pdf Estimate Quotation" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Area Wise Estimate */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allareawiseestimate} onChange={(e) => setRole({ ...role, allareawiseestimate: !role.allareawiseestimate })} />} label="Area Wise Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallareawiseestimate} onChange={(e) => areaWiseEstimateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvareawiseestimate} onChange={(e) => setRole({ ...role, csvareawiseestimate: !role.csvareawiseestimate })} />} label="CSV Area Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelareawiseestimate} onChange={(e) => setRole({ ...role, excelareawiseestimate: !role.excelareawiseestimate })} />} label="Excel Area Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printareawiseestimate} onChange={(e) => setRole({ ...role, printareawiseestimate: !role.printareawiseestimate })} />} label="Print Area Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfareawiseestimate} onChange={(e) => setRole({ ...role, pdfareawiseestimate: !role.pdfareawiseestimate })} />} label="Pdf Area Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Category Wise Estimate */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcategorywiseestimates} onChange={(e) => setRole({ ...role, allcategorywiseestimates: !role.allcategorywiseestimates })} />} label="Category Wise Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcategorywiseestimates} onChange={(e) => categoryWiseEstimateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcategorywiseestimates} onChange={(e) => setRole({ ...role, csvcategorywiseestimates: !role.csvcategorywiseestimates })} />} label="CSV Category Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcategorywiseestimates} onChange={(e) => setRole({ ...role, excelcategorywiseestimates: !role.excelcategorywiseestimates })} />} label="Excel Category Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcategorywiseestimates} onChange={(e) => setRole({ ...role, printcategorywiseestimates: !role.printcategorywiseestimates })} />} label="Print Category Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcategorywiseestimates} onChange={(e) => setRole({ ...role, pdfcategorywiseestimates: !role.pdfcategorywiseestimates })} />} label="Pdf Category Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Customer Wise Estimate Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerwiseestimatereport} onChange={(e) => setRole({ ...role, allcustomerwiseestimatereport: !role.allcustomerwiseestimatereport })} />} label="Customer Wise Estimate Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerwiseestimatereport} onChange={(e) => customerWiseEstimateReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerwiseestimatereport} onChange={(e) => setRole({ ...role, csvcustomerwiseestimatereport: !role.csvcustomerwiseestimatereport })} />} label="CSV Customer Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerwiseestimatereport} onChange={(e) => setRole({ ...role, excelcustomerwiseestimatereport: !role.excelcustomerwiseestimatereport })} />} label="Excel Customer Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerwiseestimatereport} onChange={(e) => setRole({ ...role, printcustomerwiseestimatereport: !role.printcustomerwiseestimatereport })} />} label="Print Customer Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerwiseestimatereport} onChange={(e) => setRole({ ...role, pdfcustomerwiseestimatereport: !role.pdfcustomerwiseestimatereport })} />} label="Pdf Customer Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Day Wise Estimate Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldaywiseestimatereport} onChange={(e) => setRole({ ...role, alldaywiseestimatereport: !role.alldaywiseestimatereport })} />} label="Day Wise Estimate Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldaywiseestimatereport} onChange={(e) => dayWiseEstimateReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdaywiseestimatereport} onChange={(e) => setRole({ ...role, csvdaywiseestimatereport: !role.csvdaywiseestimatereport })} />} label="CSV Day Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldaywiseestimatereport} onChange={(e) => setRole({ ...role, exceldaywiseestimatereport: !role.exceldaywiseestimatereport })} />} label="Excel Day Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdaywiseestimatereport} onChange={(e) => setRole({ ...role, printdaywiseestimatereport: !role.printdaywiseestimatereport })} />} label="Print Day Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdaywiseestimatereport} onChange={(e) => setRole({ ...role, pdfdaywiseestimatereport: !role.pdfdaywiseestimatereport })} />} label="Pdf Day Wise Estimate Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Estimate Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allestimatereport} onChange={(e) => setRole({ ...role, allestimatereport: !role.allestimatereport })} />} label="Estimate Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallestimatereport} onChange={(e) => estimateReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvestimatereport} onChange={(e) => setRole({ ...role, csvestimatereport: !role.csvestimatereport })} />} label="CSV Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelestimatereport} onChange={(e) => setRole({ ...role, excelestimatereport: !role.excelestimatereport })} />} label="Excel Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printestimatereport} onChange={(e) => setRole({ ...role, printestimatereport: !role.printestimatereport })} />} label="Print Estimate Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfestimatereport} onChange={(e) => setRole({ ...role, pdfestimatereport: !role.pdfestimatereport })} />} label="Pdf Estimate Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Estimate Return Register */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allestimatereturnregister} onChange={(e) => setRole({ ...role, allestimatereturnregister: !role.allestimatereturnregister })} />} label="Estimate Return Register" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallestimatereturnregister} onChange={(e) => estimateReturnRegisterAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvestimatereturnregister} onChange={(e) => setRole({ ...role, csvestimatereturnregister: !role.csvestimatereturnregister })} />} label="CSV Estimate Return Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelestimatereturnregister} onChange={(e) => setRole({ ...role, excelestimatereturnregister: !role.excelestimatereturnregister })} />} label="Excel Estimate Return Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printestimatereturnregister} onChange={(e) => setRole({ ...role, printestimatereturnregister: !role.printestimatereturnregister })} />} label="Print Estimate Return Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfestimatereturnregister} onChange={(e) => setRole({ ...role, pdfestimatereturnregister: !role.pdfestimatereturnregister })} />} label="Pdf Estimate Return Register" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Estimate Return Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allestimatereturnreport} onChange={(e) => setRole({ ...role, allestimatereturnreport: !role.allestimatereturnreport })} />} label="Estimate Return Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallestimatereturnreport} onChange={(e) => estimateReturnReportAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvestimatereturnreport} onChange={(e) => setRole({ ...role, csvestimatereturnreport: !role.csvestimatereturnreport })} />} label="CSV Estimate Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelestimatereturnreport} onChange={(e) => setRole({ ...role, excelestimatereturnreport: !role.excelestimatereturnreport })} />} label="Excel Estimate Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printestimatereturnreport} onChange={(e) => setRole({ ...role, printestimatereturnreport: !role.printestimatereturnreport })} />} label="Print Estimate Return Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfestimatereturnreport} onChange={(e) => setRole({ ...role, pdfestimatereturnreport: !role.pdfestimatereturnreport })} />} label="Pdf Estimate Return Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Item Wise Estimate */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwiseestimate} onChange={(e) => setRole({ ...role, allitemwiseestimate: !role.allitemwiseestimate })} />} label="Item Wise Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwiseestimate} onChange={(e) => itemWiseEstimateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwiseestimate} onChange={(e) => setRole({ ...role, csvitemwiseestimate: !role.csvitemwiseestimate })} />} label="CSV Item Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwiseestimate} onChange={(e) => setRole({ ...role, excelitemwiseestimate: !role.excelitemwiseestimate })} />} label="Excel Item Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwiseestimate} onChange={(e) => setRole({ ...role, printitemwiseestimate: !role.printitemwiseestimate })} />} label="Print Item Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwiseestimate} onChange={(e) => setRole({ ...role, pdfitemwiseestimate: !role.pdfitemwiseestimate })} />} label="Pdf Item Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Item Wise Estimate Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwiseestimatereturn} onChange={(e) => setRole({ ...role, allitemwiseestimatereturn: !role.allitemwiseestimatereturn })} />} label="Item Wise Estimate Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwiseestimatereturn} onChange={(e) => itemWiseEstimateReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwiseestimatereturn} onChange={(e) => setRole({ ...role, csvitemwiseestimatereturn: !role.csvitemwiseestimatereturn })} />} label="CSV Item Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwiseestimatereturn} onChange={(e) => setRole({ ...role, excelitemwiseestimatereturn: !role.excelitemwiseestimatereturn })} />} label="Excel Item Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwiseestimatereturn} onChange={(e) => setRole({ ...role, printitemwiseestimatereturn: !role.printitemwiseestimatereturn })} />} label="Print Item Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwiseestimatereturn} onChange={(e) => setRole({ ...role, pdfitemwiseestimatereturn: !role.pdfitemwiseestimatereturn })} />} label="Pdf Item Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Manufacture Wise Estimate */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufacturewiseestimate} onChange={(e) => setRole({ ...role, allmanufacturewiseestimate: !role.allmanufacturewiseestimate })} />} label="Manufacture Wise Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufacturewiseestimate} onChange={(e) => manufactureWiseEstimateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufacturewiseestimate} onChange={(e) => setRole({ ...role, csvmanufacturewiseestimate: !role.csvmanufacturewiseestimate })} />} label="CSV Manufacture Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufacturewiseestimate} onChange={(e) => setRole({ ...role, excelmanufacturewiseestimate: !role.excelmanufacturewiseestimate })} />} label="Excel Manufacture Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufacturewiseestimate} onChange={(e) => setRole({ ...role, printmanufacturewiseestimate: !role.printmanufacturewiseestimate })} />} label="Print Manufacture Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufacturewiseestimate} onChange={(e) => setRole({ ...role, pdfmanufacturewiseestimate: !role.pdfmanufacturewiseestimate })} />} label="Pdf Manufacture Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Manufacture Wise Estimate Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufacturewiseestimatereturn} onChange={(e) => setRole({ ...role, allmanufacturewiseestimatereturn: !role.allmanufacturewiseestimatereturn })} />} label="Manufacture Wise Estimate Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufacturewiseestimatereturn} onChange={(e) => manufactureWiseEstimateReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufacturewiseestimatereturn} onChange={(e) => setRole({ ...role, csvmanufacturewiseestimatereturn: !role.csvmanufacturewiseestimatereturn })} />} label="CSV Manufacture Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufacturewiseestimatereturn} onChange={(e) => setRole({ ...role, excelmanufacturewiseestimatereturn: !role.excelmanufacturewiseestimatereturn })} />} label="Excel Manufacture Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufacturewiseestimatereturn} onChange={(e) => setRole({ ...role, printmanufacturewiseestimatereturn: !role.printmanufacturewiseestimatereturn })} />} label="Print Manufacture Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufacturewiseestimatereturn} onChange={(e) => setRole({ ...role, pdfmanufacturewiseestimatereturn: !role.pdfmanufacturewiseestimatereturn })} />} label="Pdf Manufacture Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Reference Wise Estimate */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allreferencewiseestimate} onChange={(e) => setRole({ ...role, allreferencewiseestimate: !role.allreferencewiseestimate })} />} label="Reference Wise Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallreferencewiseestimate} onChange={(e) => referenceWiseEstimateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvreferencewiseestimate} onChange={(e) => setRole({ ...role, csvreferencewiseestimate: !role.csvreferencewiseestimate })} />} label="CSV Reference Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelreferencewiseestimate} onChange={(e) => setRole({ ...role, excelreferencewiseestimate: !role.excelreferencewiseestimate })} />} label="Excel Reference Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printreferencewiseestimate} onChange={(e) => setRole({ ...role, printreferencewiseestimate: !role.printreferencewiseestimate })} />} label="Print Reference Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfreferencewiseestimate} onChange={(e) => setRole({ ...role, pdfreferencewiseestimate: !role.pdfreferencewiseestimate })} />} label="Pdf Reference Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Sale Return Summary Start */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allestimatesalereturnsummary} onChange={(e) => setRole({ ...role, allestimatesalereturnsummary: !role.allestimatesalereturnsummary })} />} label="Sale Return Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallestimatesalereturnsummary} onChange={(e) => saleReturnSummaryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvestimatesalereturnsummary} onChange={(e) => setRole({ ...role, csvestimatesalereturnsummary: !role.csvestimatesalereturnsummary })} />} label="CSV Sale Return Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelestimatesalereturnsummary} onChange={(e) => setRole({ ...role, excelestimatesalereturnsummary: !role.excelestimatesalereturnsummary })} />} label="Excel Sale Return Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printestimatesalereturnsummary} onChange={(e) => setRole({ ...role, printestimatesalereturnsummary: !role.printestimatesalereturnsummary })} />} label="Print Sale Return Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfestimatesalereturnsummary} onChange={(e) => setRole({ ...role, pdfestimatesalereturnsummary: !role.pdfestimatesalereturnsummary })} />} label="Pdf Sale Return Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Sale Return Summary End */}

                        {/* Supplier Wise Estimate */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierwiseestimate} onChange={(e) => setRole({ ...role, allsupplierwiseestimate: !role.allsupplierwiseestimate })} />} label="Supplier Wise Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierwiseestimate} onChange={(e) => supplierWiseEstimateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierwiseestimate} onChange={(e) => setRole({ ...role, csvsupplierwiseestimate: !role.csvsupplierwiseestimate })} />} label="CSV Supplier Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierwiseestimate} onChange={(e) => setRole({ ...role, excelsupplierwiseestimate: !role.excelsupplierwiseestimate })} />} label="Excel Supplier Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierwiseestimate} onChange={(e) => setRole({ ...role, printsupplierwiseestimate: !role.printsupplierwiseestimate })} />} label="Print Supplier Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierwiseestimate} onChange={(e) => setRole({ ...role, pdfsupplierwiseestimate: !role.pdfsupplierwiseestimate })} />} label="Pdf Supplier Wise Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Supplier Wise Estimate Return */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allsupplierwiseestimatereturn} onChange={(e) => setRole({ ...role, allsupplierwiseestimatereturn: !role.allsupplierwiseestimatereturn })} />} label="Supplier Wise Estimate Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallsupplierwiseestimatereturn} onChange={(e) => supplierWiseEstimateReturnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvsupplierwiseestimatereturn} onChange={(e) => setRole({ ...role, csvsupplierwiseestimatereturn: !role.csvsupplierwiseestimatereturn })} />} label="CSV Supplier Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelsupplierwiseestimatereturn} onChange={(e) => setRole({ ...role, excelsupplierwiseestimatereturn: !role.excelsupplierwiseestimatereturn })} />} label="Excel Supplier Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printsupplierwiseestimatereturn} onChange={(e) => setRole({ ...role, printsupplierwiseestimatereturn: !role.printsupplierwiseestimatereturn })} />} label="Print Supplier Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfsupplierwiseestimatereturn} onChange={(e) => setRole({ ...role, pdfsupplierwiseestimatereturn: !role.pdfsupplierwiseestimatereturn })} />} label="Pdf Supplier Wise Estimate Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Estimate End */}

                        {/* Stock Management Star */}

                        {/* Batch Wise Stock Details */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.stockmanagement} onChange={(e) => setRole({ ...role, stockmanagement: !role.stockmanagement })} />} label="Stock Management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbatchwisestockdetails} onChange={(e) => setRole({ ...role, allbatchwisestockdetails: !role.allbatchwisestockdetails })} />} label="Batch Wise Stock Details" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbatchwisestockdetails} onChange={(e) => batchwiseStockdetAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbatchwisestockdetails} onChange={(e) => setRole({ ...role, csvbatchwisestockdetails: !role.csvbatchwisestockdetails })} />} label="Csv Batch Wise Stock Details" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbatchwisestockdetails} onChange={(e) => setRole({ ...role, excelbatchwisestockdetails: !role.excelbatchwisestockdetails })} />} label="Excel Batch Wise Stock Details" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbatchwisestockdetails} onChange={(e) => setRole({ ...role, printbatchwisestockdetails: !role.printbatchwisestockdetails })} />} label="Print Batch Wise Stock Details" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbatchwisestockdetails} onChange={(e) => setRole({ ...role, pdfbatchwisestockdetails: !role.pdfbatchwisestockdetails })} />} label="Pdf Batch Wise Stock Details" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Batch Wise Stock Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbatchwisestockreport} onChange={(e) => setRole({ ...role, allbatchwisestockreport: !role.allbatchwisestockreport })} />} label="Batch Wise Stock Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbatchwisestockreport} onChange={(e) => batchwiseStockrepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbatchwisestockreport} onChange={(e) => setRole({ ...role, csvbatchwisestockreport: !role.csvbatchwisestockreport })} />} label="Csv Batch Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbatchwisestockreport} onChange={(e) => setRole({ ...role, excelbatchwisestockreport: !role.excelbatchwisestockreport })} />} label="Excel Batch Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbatchwisestockreport} onChange={(e) => setRole({ ...role, printbatchwisestockreport: !role.printbatchwisestockreport })} />} label="Print Batch Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbatchwisestockreport} onChange={(e) => setRole({ ...role, pdfbatchwisestockreport: !role.pdfbatchwisestockreport })} />} label="Pdf Batch Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Stock Expriy Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allstockexpriyreport} onChange={(e) => setRole({ ...role, allstockexpriyreport: !role.allstockexpriyreport })} />} label="Stock Expriy Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallstockexpriyreport} onChange={(e) => stockExpiryrepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvstockexpriyreport} onChange={(e) => setRole({ ...role, csvstockexpriyreport: !role.csvstockexpriyreport })} />} label="Csv Stock Expriy Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelstockexpriyreport} onChange={(e) => setRole({ ...role, excelstockexpriyreport: !role.excelstockexpriyreport })} />} label="Excel Stock Expriy Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printstockexpriyreport} onChange={(e) => setRole({ ...role, printstockexpriyreport: !role.printstockexpriyreport })} />} label="Print Stock Expriy Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfstockexpriyreport} onChange={(e) => setRole({ ...role, pdfstockexpriyreport: !role.pdfstockexpriyreport })} />} label="Pdf Stock Expriy Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Item Code Update */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemcodeupdate} onChange={(e) => setRole({ ...role, allitemcodeupdate: !role.allitemcodeupdate })} />} label="Item Code Update" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemcodeupdate} onChange={(e) => itemCodeUpdateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemcodeupdate} onChange={(e) => setRole({ ...role, csvitemcodeupdate: !role.csvitemcodeupdate })} />} label="Csv Item Code Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemcodeupdate} onChange={(e) => setRole({ ...role, excelitemcodeupdate: !role.excelitemcodeupdate })} />} label="Excel Item Code Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemcodeupdate} onChange={(e) => setRole({ ...role, printitemcodeupdate: !role.printitemcodeupdate })} />} label="Print Item Code Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemcodeupdate} onChange={(e) => setRole({ ...role, pdfitemcodeupdate: !role.pdfitemcodeupdate })} />} label="Pdf Item Code Update" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Item Wise Stock Report */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwisestockreport} onChange={(e) => setRole({ ...role, allitemwisestockreport: !role.allitemwisestockreport })} />} label="Item Wise Stock Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwisestockreport} onChange={(e) => itemWiseStockRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwisestockreport} onChange={(e) => setRole({ ...role, csvitemwisestockreport: !role.csvitemwisestockreport })} />} label="Csv Item Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwisestockreport} onChange={(e) => setRole({ ...role, excelitemwisestockreport: !role.excelitemwisestockreport })} />} label="Excel Item Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwisestockreport} onChange={(e) => setRole({ ...role, printitemwisestockreport: !role.printitemwisestockreport })} />} label="Print Item Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwisestockreport} onChange={(e) => setRole({ ...role, pdfitemwisestockreport: !role.pdfitemwisestockreport })} />} label="Pdf Item Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>


                        {/* Manual Stock Entry */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanualstockentry} onChange={(e) => setRole({ ...role, allmanualstockentry: !role.allmanualstockentry })} />} label="Manual Stock Entry" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanualstockentry} onChange={(e) => manualStockEntryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanualstockentry} onChange={(e) => setRole({ ...role, csvmanualstockentry: !role.csvmanualstockentry })} />} label="Csv Manual Stock Entry" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanualstockentry} onChange={(e) => setRole({ ...role, excelmanualstockentry: !role.excelmanualstockentry })} />} label="Excel Manual Stock Entry" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanualstockentry} onChange={(e) => setRole({ ...role, printmanualstockentry: !role.printmanualstockentry })} />} label="Print Manual Stock Entry" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanualstockentry} onChange={(e) => setRole({ ...role, pdfmanualstockentry: !role.pdfmanualstockentry })} />} label="Pdf Manual Stock Entry" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Manufacture Update*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allmanufactureupdate} onChange={(e) => setRole({ ...role, allmanufactureupdate: !role.allmanufactureupdate })} />} label="Manufacture Update" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallmanufactureupdate} onChange={(e) => manufactureUpdateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.umanufactureupdate} onChange={(e) => setRole({ ...role, umanufactureupdate: !role.umanufactureupdate })} />} label="Update Manufacture Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvmanufactureupdate} onChange={(e) => setRole({ ...role, csvmanufactureupdate: !role.csvmanufactureupdate })} />} label="Csv Manufacture Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelmanufactureupdate} onChange={(e) => setRole({ ...role, excelmanufactureupdate: !role.excelmanufactureupdate })} />} label="Excel Manufacture Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printmanufactureupdate} onChange={(e) => setRole({ ...role, printmanufactureupdate: !role.printmanufactureupdate })} />} label="Print Manufacture Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfmanufactureupdate} onChange={(e) => setRole({ ...role, pdfmanufactureupdate: !role.pdfmanufactureupdate })} />} label="Pdf Manufacture Update" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Minimum Wise Stock Report*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allminimumwisestockreport} onChange={(e) => setRole({ ...role, allminimumwisestockreport: !role.allminimumwisestockreport })} />} label="Minimum Wise Stock Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallminimumwisestockreport} onChange={(e) => minimumWiseStockRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.uminimumwisestockreport} onChange={(e) => setRole({ ...role, uminimumwisestockreport: !role.uminimumwisestockreport })} />} label="Update Minimum Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvminimumwisestockreport} onChange={(e) => setRole({ ...role, csvminimumwisestockreport: !role.csvminimumwisestockreport })} />} label="Csv Minimum Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelminimumwisestockreport} onChange={(e) => setRole({ ...role, excelminimumwisestockreport: !role.excelminimumwisestockreport })} />} label="Excel Minimum Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printminimumwisestockreport} onChange={(e) => setRole({ ...role, printminimumwisestockreport: !role.printminimumwisestockreport })} />} label="Print Minimum Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfminimumwisestockreport} onChange={(e) => setRole({ ...role, pdfminimumwisestockreport: !role.pdfminimumwisestockreport })} />} label="Pdf Minimum Wise Stock Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Nile Stock Report*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allnilestockreport} onChange={(e) => setRole({ ...role, allnilestockreport: !role.allnilestockreport })} />} label="Nile Stock Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallnilestockreport} onChange={(e) => nileStockRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvnilestockreport} onChange={(e) => setRole({ ...role, csvnilestockreport: !role.csvnilestockreport })} />} label="Csv Nile Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelnilestockreport} onChange={(e) => setRole({ ...role, excelnilestockreport: !role.excelnilestockreport })} />} label="Excel Nile Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printnilestockreport} onChange={(e) => setRole({ ...role, printnilestockreport: !role.printnilestockreport })} />} label="Print Nile Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfnilestockreport} onChange={(e) => setRole({ ...role, pdfnilestockreport: !role.pdfnilestockreport })} />} label="Pdf Nile Stock Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Non Moving Stock Report*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allnonmovingstockreport} onChange={(e) => setRole({ ...role, allnonmovingstockreport: !role.allnonmovingstockreport })} />} label="Non Moving Stock Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallnonmovingstockreport} onChange={(e) => nonMoveStockRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvnonmovingstockreport} onChange={(e) => setRole({ ...role, csvnonmovingstockreport: !role.csvnonmovingstockreport })} />} label="Csv Non Moving Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelnonmovingstockreport} onChange={(e) => setRole({ ...role, excelnonmovingstockreport: !role.excelnonmovingstockreport })} />} label="Excel Non Moving Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printnonmovingstockreport} onChange={(e) => setRole({ ...role, printnonmovingstockreport: !role.printnonmovingstockreport })} />} label="Print Non Moving Stock Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfnonmovingstockreport} onChange={(e) => setRole({ ...role, pdfnonmovingstockreport: !role.pdfnonmovingstockreport })} />} label="Pdf Non Moving Stock Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Pack Quality Update*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpackqualityupdate} onChange={(e) => setRole({ ...role, allpackqualityupdate: !role.allpackqualityupdate })} />} label="Pack Quality Update" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpackqualityupdate} onChange={(e) => packQualityUpdateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.upackqualityupdate} onChange={(e) => setRole({ ...role, upackqualityupdate: !role.upackqualityupdate })} />} label="Update Pack Quality Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpackqualityupdate} onChange={(e) => setRole({ ...role, excelpackqualityupdate: !role.excelpackqualityupdate })} />} label="Excel Pack Quality Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpackqualityupdate} onChange={(e) => setRole({ ...role, csvpackqualityupdate: !role.csvpackqualityupdate })} />} label="Csv Pack Quality Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printpackqualityupdate} onChange={(e) => setRole({ ...role, printpackqualityupdate: !role.printpackqualityupdate })} />} label="Print Pack Quality Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpackqualityupdate} onChange={(e) => setRole({ ...role, pdfpackqualityupdate: !role.pdfpackqualityupdate })} />} label="Pdf Pack Quality Update" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Stock Expiry Report*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allstockexpiryreport} onChange={(e) => setRole({ ...role, allstockexpiryreport: !role.allstockexpiryreport })} />} label="Stock Expiry Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallstockexpiryreport} onChange={(e) => stockExpiryRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelstockexpiryreport} onChange={(e) => setRole({ ...role, excelstockexpiryreport: !role.excelstockexpiryreport })} />} label="Excel Stock Expiry Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvstockexpiryreport} onChange={(e) => setRole({ ...role, csvstockexpiryreport: !role.csvstockexpiryreport })} />} label="Csv Stock Expiry Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printstockexpiryreport} onChange={(e) => setRole({ ...role, printstockexpiryreport: !role.printstockexpiryreport })} />} label="Print Stock Expiry Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfstockexpiryreport} onChange={(e) => setRole({ ...role, pdfstockexpiryreport: !role.pdfstockexpiryreport })} />} label="Pdf Stock Expiry Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Stock Over View Report*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allstockoverviewreport} onChange={(e) => setRole({ ...role, allstockoverviewreport: !role.allstockoverviewreport })} />} label="Stock Over View Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallstockoverviewreport} onChange={(e) => stockOverViewRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelstockoverviewreport} onChange={(e) => setRole({ ...role, excelstockoverviewreport: !role.excelstockoverviewreport })} />} label="Excel Stock Over View Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvstockoverviewreport} onChange={(e) => setRole({ ...role, csvstockoverviewreport: !role.csvstockoverviewreport })} />} label="Csv Stock Over View Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printstockoverviewreport} onChange={(e) => setRole({ ...role, printstockoverviewreport: !role.printstockoverviewreport })} />} label="Print Stock Over View Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfstockoverviewreport} onChange={(e) => setRole({ ...role, pdfstockoverviewreport: !role.pdfstockoverviewreport })} />} label="Pdf Stock Over View Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Stock Update*/}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allstockupdate} onChange={(e) => setRole({ ...role, allstockupdate: !role.allstockupdate })} />} label="Stock Update" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallstockupdate} onChange={(e) => stockUpdateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelstockupdate} onChange={(e) => setRole({ ...role, excelstockupdate: !role.excelstockupdate })} />} label="Excel Stock Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvstockupdate} onChange={(e) => setRole({ ...role, csvstockupdate: !role.csvstockupdate })} />} label="Csv Stock Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printstockupdate} onChange={(e) => setRole({ ...role, printstockupdate: !role.printstockupdate })} />} label="Print Stock Update" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfstockupdate} onChange={(e) => setRole({ ...role, pdfstockupdate: !role.pdfstockupdate })} />} label="Pdf Stock Update" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Stock Management End */}

                        {/* Expance Management */}

                        {/* Expance Start */}
                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.expensemanagement} onChange={(e) => setRole({ ...role, expensemanagement: !role.expensemanagement })} />} label="Expense management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allexpense} onChange={(e) => setRole({ ...role, allexpense: !role.allexpense })} />} label="Expense" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallexpense} onChange={(e) => expenseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aexpense} onChange={(e) => setRole({ ...role, aexpense: !role.aexpense })} />} label="Add expense" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eexpense} onChange={(e) => setRole({ ...role, eexpense: !role.eexpense })} />} label="Edit expense" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dexpense} onChange={(e) => setRole({ ...role, dexpense: !role.dexpense })} />} label="Delete expense" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelexpense} onChange={(e) => setRole({ ...role, excelexpense: !role.excelexpense })} />} label="Excel expense" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvexpense} onChange={(e) => setRole({ ...role, csvexpense: !role.csvexpense })} />} label="Csv expense" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printexpense} onChange={(e) => setRole({ ...role, printexpense: !role.printexpense })} />} label="Print expense" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfexpense} onChange={(e) => setRole({ ...role, pdfexpense: !role.pdfexpense })} />} label="Pdf expense" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.payexpense} onChange={(e) => setRole({ ...role, payexpense: !role.payexpense })} />} label="Pay expense" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Expance Category */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allexpensecategory} onChange={(e) => setRole({ ...role, allexpensecategory: !role.allexpensecategory })} />} label="Expense category" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallexpensecategory} onChange={(e) => expenseCategoryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aexpensecategory} onChange={(e) => setRole({ ...role, aexpensecategory: !role.aexpensecategory })} />} label="Add expense category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eexpensecategory} onChange={(e) => setRole({ ...role, eexpensecategory: !role.eexpensecategory })} />} label="Edit expense category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dexpensecategory} onChange={(e) => setRole({ ...role, dexpensecategory: !role.dexpensecategory })} />} label="Delete expense category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelexpensecategory} onChange={(e) => setRole({ ...role, excelexpensecategory: !role.excelexpensecategory })} />} label="Excel expense category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvexpensecategory} onChange={(e) => setRole({ ...role, csvexpensecategory: !role.csvexpensecategory })} />} label="Csv expense category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printexpensecategory} onChange={(e) => setRole({ ...role, printexpensecategory: !role.printexpensecategory })} />} label="Print expense category" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfexpensecategory} onChange={(e) => setRole({ ...role, pdfexpensecategory: !role.pdfexpensecategory })} />} label="Pdf expense category" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Expance End */}

                        {/* Account Management Start*/}

                        {/* Account Creation */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.accountmanagement} onChange={(e) => setRole({ ...role, accountmanagement: !role.accountmanagement })} />} label="Account management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allaccountcreation} onChange={(e) => setRole({ ...role, allaccountcreation: !role.allaccountcreation })} />} label=" Account Creation" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallaccountcreation} onChange={(e) => accountCreationAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelaccountcreation} onChange={(e) => setRole({ ...role, excelaccountcreation: !role.excelaccountcreation })} />} label="Excel Account Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvaccountcreation} onChange={(e) => setRole({ ...role, csvaccountcreation: !role.csvaccountcreation })} />} label="Csv Account Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printaccountcreation} onChange={(e) => setRole({ ...role, printaccountcreation: !role.printaccountcreation })} />} label="Print Account Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfaccountcreation} onChange={(e) => setRole({ ...role, pdfaccountcreation: !role.pdfaccountcreation })} />} label="Pdf Account Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aaccountcreation} onChange={(e) => setRole({ ...role, aaccountcreation: !role.aaccountcreation })} />} label="Add Account Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.eaccountcreation} onChange={(e) => setRole({ ...role, eaccountcreation: !role.eaccountcreation })} />} label="Edit Account Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.daccountcreation} onChange={(e) => setRole({ ...role, daccountcreation: !role.daccountcreation })} />} label="Delete Account Creation" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Group Creation */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgroupcreation} onChange={(e) => setRole({ ...role, allgroupcreation: !role.allgroupcreation })} />} label="Group Creation" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgroupcreation} onChange={(e) => groupCreationAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgroupcreation} onChange={(e) => setRole({ ...role, excelgroupcreation: !role.excelgroupcreation })} />} label="Excel Group Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgroupcreation} onChange={(e) => setRole({ ...role, csvgroupcreation: !role.csvgroupcreation })} />} label="Csv Group Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgroupcreation} onChange={(e) => setRole({ ...role, printgroupcreation: !role.printgroupcreation })} />} label="Print Group Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgroupcreation} onChange={(e) => setRole({ ...role, pdfgroupcreation: !role.pdfgroupcreation })} />} label="Pdf Group Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.agroupcreation} onChange={(e) => setRole({ ...role, agroupcreation: !role.agroupcreation })} />} label="Add Group Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.egroupcreation} onChange={(e) => setRole({ ...role, egroupcreation: !role.egroupcreation })} />} label="Edit Group Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dgroupcreation} onChange={(e) => setRole({ ...role, dgroupcreation: !role.dgroupcreation })} />} label="Delete Group Creation" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Voucher Creation */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allvoucherlist} onChange={(e) => setRole({ ...role, allvoucherlist: !role.allvoucherlist })} />} label="Voucher Creation" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallvoucherlist} onChange={(e) => voucherAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelvoucherlist} onChange={(e) => setRole({ ...role, excelvoucherlist: !role.excelvoucherlist })} />} label="Excel Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvvoucherlist} onChange={(e) => setRole({ ...role, csvvoucherlist: !role.csvvoucherlist })} />} label="Csv Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printvoucherlist} onChange={(e) => setRole({ ...role, printvoucherlist: !role.printvoucherlist })} />} label="Print Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfvoucherlist} onChange={(e) => setRole({ ...role, pdfvoucherlist: !role.pdfvoucherlist })} />} label="Pdf Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.addvoucherlist} onChange={(e) => setRole({ ...role, addvoucherlist: !role.addvoucherlist })} />} label="Add Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.evoucherlist} onChange={(e) => setRole({ ...role, evoucherlist: !role.evoucherlist })} />} label="Edit Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dvoucherlist} onChange={(e) => setRole({ ...role, dvoucherlist: !role.dvoucherlist })} />} label="Delete Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vvoucherlist} onChange={(e) => setRole({ ...role, vvoucherlist: !role.vvoucherlist })} />} label="View Voucher Creation" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Bank Statement */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbankstatement} onChange={(e) => setRole({ ...role, allbankstatement: !role.allbankstatement })} />} label="Bank Statement" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbankstatement} onChange={(e) => bankStatementAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbankstatement} onChange={(e) => setRole({ ...role, csvbankstatement: !role.csvbankstatement })} />} label="Excel Bank Statement" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbankstatement} onChange={(e) => setRole({ ...role, excelbankstatement: !role.excelbankstatement })} />} label="Csv Bank Statement" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbankstatement} onChange={(e) => setRole({ ...role, printbankstatement: !role.printbankstatement })} />} label="Print Bank Statement" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbankstatement} onChange={(e) => setRole({ ...role, pdfbankstatement: !role.pdfbankstatement })} />} label="Pdf Bank Statement" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Cashbook Register Report */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcashbookregreport} onChange={(e) => setRole({ ...role, allcashbookregreport: !role.allcashbookregreport })} />} label="Cashbook Register Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcashbookregreport} onChange={(e) => cashbookRegRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcashbookregreport} onChange={(e) => setRole({ ...role, csvcashbookregreport: !role.csvcashbookregreport })} />} label="Csv Cashbook Register Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcashbookregreport} onChange={(e) => setRole({ ...role, excelcashbookregreport: !role.excelcashbookregreport })} />} label="Excel Cashbook Register Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcashbookregreport} onChange={(e) => setRole({ ...role, printcashbookregreport: !role.printcashbookregreport })} />} label="Print Cashbook Register Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcashbookregreport} onChange={(e) => setRole({ ...role, pdfcashbookregreport: !role.pdfcashbookregreport })} />} label="Pdf Cashbook Register Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Cashbook Register */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcashbookreport} onChange={(e) => setRole({ ...role, allcashbookreport: !role.allcashbookreport })} />} label="Cashbook Register" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcashbookreport} onChange={(e) => cashbookRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcashbookreport} onChange={(e) => setRole({ ...role, csvcashbookreport: !role.csvcashbookreport })} />} label="Csv Cashbook Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcashbookreport} onChange={(e) => setRole({ ...role, excelcashbookreport: !role.excelcashbookreport })} />} label="Excel Cashbook Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcashbookreport} onChange={(e) => setRole({ ...role, printcashbookreport: !role.printcashbookreport })} />} label="Print Cashbook Register" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcashbookreport} onChange={(e) => setRole({ ...role, pdfcashbookreport: !role.pdfcashbookreport })} />} label="Pdf Cashbook Register" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Group Summary */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgroupsummary} onChange={(e) => setRole({ ...role, allgroupsummary: !role.allgroupsummary })} />} label="Group Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgroupsummary} onChange={(e) => groupSummaryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgroupsummary} onChange={(e) => setRole({ ...role, csvgroupsummary: !role.csvgroupsummary })} />} label="Csv Group Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgroupsummary} onChange={(e) => setRole({ ...role, excelgroupsummary: !role.excelgroupsummary })} />} label="Excel Group Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgroupsummary} onChange={(e) => setRole({ ...role, printgroupsummary: !role.printgroupsummary })} />} label="Print Group Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgroupsummary} onChange={(e) => setRole({ ...role, pdfgroupsummary: !role.pdfgroupsummary })} />} label="Pdf Group Summary" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Trial Balance */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alltrailbalance} onChange={(e) => setRole({ ...role, alltrailbalance: !role.alltrailbalance })} />} label="Trail Balance" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalltrailbalance} onChange={(e) => trialBalanceAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvtrailbalance} onChange={(e) => setRole({ ...role, csvtrailbalance: !role.csvtrailbalance })} />} label="Csv Trail Balance" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceltrailbalance} onChange={(e) => setRole({ ...role, exceltrailbalance: !role.exceltrailbalance })} />} label="Excel Trail Balance" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printtrailbalance} onChange={(e) => setRole({ ...role, printtrailbalance: !role.printtrailbalance })} />} label="Print Trail Balance" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdftrailbalance} onChange={(e) => setRole({ ...role, pdftrailbalance: !role.pdftrailbalance })} />} label="Pdf Trail Balance" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Profit and Loss Account */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allprofitlossaccount} onChange={(e) => setRole({ ...role, allprofitlossaccount: !role.allprofitlossaccount })} />} label="Profit and Loss Account" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallprofitlossaccount} onChange={(e) => profitLossAccAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printprofitlossaccount} onChange={(e) => setRole({ ...role, printprofitlossaccount: !role.printprofitlossaccount })} />} label="Print Profit and Loss Account" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfprofitlossaccount} onChange={(e) => setRole({ ...role, pdfprofitlossaccount: !role.pdfprofitlossaccount })} />} label="Pdf Profit and Loss Account" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Account Management end */}

                        {/* Account Management Start*/}

                        {/* Bank Master */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.bankmanagement} onChange={(e) => setRole({ ...role, bankmanagement: !role.bankmanagement })} />} label="Bank management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbankmaster} onChange={(e) => setRole({ ...role, allbankmaster: !role.allbankmaster })} />} label=" Bank Master" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbankmaster} onChange={(e) => BankMasterAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbankmaster} onChange={(e) => setRole({ ...role, csvbankmaster: !role.csvbankmaster })} />} label="Csv Bank Master" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbankmaster} onChange={(e) => setRole({ ...role, excelbankmaster: !role.excelbankmaster })} />} label="Excel Bank Master" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbankmaster} onChange={(e) => setRole({ ...role, printbankmaster: !role.printbankmaster })} />} label="Print Bank Master" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbankmaster} onChange={(e) => setRole({ ...role, pdfbankmaster: !role.pdfbankmaster })} />} label="Pdf Bank Master" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.viewbankmaster} onChange={(e) => setRole({ ...role, viewbankmaster: !role.viewbankmaster })} />} label="View Bank Master" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.editbankmaster} onChange={(e) => setRole({ ...role, editbankmaster: !role.editbankmaster })} />} label="Edit Bank Master" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.deletebankmaster} onChange={(e) => setRole({ ...role, deletebankmaster: !role.deletebankmaster })} />} label="Delete Bank Master" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Bank Master Report */}

                        <Divider sx={{ my: 2 }} />

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbankmasterreport} onChange={(e) => setRole({ ...role, allbankmasterreport: !role.allbankmasterreport })} />} label="Bank Master Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbankmasterreport} onChange={(e) => BankMasterRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbankmasterreport} onChange={(e) => setRole({ ...role, csvbankmasterreport: !role.csvbankmasterreport })} />} label="Csv Bank Master Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbankmasterreport} onChange={(e) => setRole({ ...role, excelbankmasterreport: !role.excelbankmasterreport })} />} label="Excel Bank Master Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbankmasterreport} onChange={(e) => setRole({ ...role, printbankmasterreport: !role.printbankmasterreport })} />} label="Print Bank Master Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbankmasterreport} onChange={(e) => setRole({ ...role, pdfbankmasterreport: !role.pdfbankmasterreport })} />} label="Pdf Bank Master Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Bank Book Report */}

                        <Divider sx={{ my: 2 }} />

                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbankbookreport} onChange={(e) => setRole({ ...role, allbankbookreport: !role.allbankbookreport })} />} label="Bank Book Report" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbankbookreport} onChange={(e) => BankBookRepAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbankbookreport} onChange={(e) => setRole({ ...role, csvbankbookreport: !role.csvbankbookreport })} />} label="Csv Bank Book Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbankbookreport} onChange={(e) => setRole({ ...role, excelbankbookreport: !role.excelbankbookreport })} />} label="Excel Bank Book Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbankbookreport} onChange={(e) => setRole({ ...role, printbankbookreport: !role.printbankbookreport })} />} label="Print Bank Book Report" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbankbookreport} onChange={(e) => setRole({ ...role, pdfbankbookreport: !role.pdfbankbookreport })} />} label="Pdf Bank Book Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Settings Management */}

                        {/* Business Location */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.settingsmanagement} onChange={(e) => setRole({ ...role, settingsmanagement: !role.settingsmanagement })} />} label="Settings management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbusinesslocation} onChange={(e) => setRole({ ...role, allbusinesslocation: !role.allbusinesslocation })} />} label="Business location" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbusinesslocation} onChange={(e) => businessLocationAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.activatebusinesslocation} onChange={(e) => setRole({ ...role, activatebusinesslocation: !role.activatebusinesslocation })} />} label="Activate business location" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.abusinesslocation} onChange={(e) => setRole({ ...role, abusinesslocation: !role.abusinesslocation })} />} label="Add business location" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ebusinesslocation} onChange={(e) => setRole({ ...role, ebusinesslocation: !role.ebusinesslocation })} />} label="Edit business location" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dbusinesslocation} onChange={(e) => setRole({ ...role, dbusinesslocation: !role.dbusinesslocation })} />} label="Delete business location" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbusinesslocation} onChange={(e) => setRole({ ...role, excelbusinesslocation: !role.excelbusinesslocation })} />} label="Excel business location" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbusinesslocation} onChange={(e) => setRole({ ...role, csvbusinesslocation: !role.csvbusinesslocation })} />} label="Csv business location" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbusinesslocation} onChange={(e) => setRole({ ...role, printbusinesslocation: !role.printbusinesslocation })} />} label="Print business location" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbusinesslocation} onChange={(e) => setRole({ ...role, pdfbusinesslocation: !role.pdfbusinesslocation })} />} label="Pdf expense category" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Alpha Rate */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allalpharate} onChange={(e) => setRole({ ...role, allalpharate: !role.allalpharate })} />} label="Alpharate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallalpharate} onChange={(e) => alpharateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.valpharate} onChange={(e) => setRole({ ...role, valpharate: !role.valpharate })} />} label="View alpharate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.aalpharate} onChange={(e) => setRole({ ...role, aalpharate: !role.aalpharate })} />} label="Add alpharate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Payment Integration */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allpaymentintegration} onChange={(e) => setRole({ ...role, allpaymentintegration: !role.allpaymentintegration })} />} label="Payment Integration" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallpaymentintegration} onChange={(e) => paymentIntegrationAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.vpaymentintegration} onChange={(e) => setRole({ ...role, vpaymentintegration: !role.vpaymentintegration })} />} label="View payment integration" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.apaymentintegration} onChange={(e) => setRole({ ...role, apaymentintegration: !role.apaymentintegration })} />} label="Add payment integration" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.epaymentintegration} onChange={(e) => setRole({ ...role, epaymentintegration: !role.epaymentintegration })} />} label="Edit payment integration" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dpaymentintegration} onChange={(e) => setRole({ ...role, dpaymentintegration: !role.dpaymentintegration })} />} label="Delete payment integration" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelpaymentintegration} onChange={(e) => setRole({ ...role, excelpaymentintegration: !role.excelpaymentintegration })} />} label="Excel payment integration" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvpaymentintegration} onChange={(e) => setRole({ ...role, csvpaymentintegration: !role.csvpaymentintegration })} />} label="Csv payment integration" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfpaymentintegration} onChange={(e) => setRole({ ...role, pdfpaymentintegration: !role.pdfpaymentintegration })} />} label="Pdf payment integration" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Tax Rate */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alltaxrate} onChange={(e) => setRole({ ...role, alltaxrate: !role.alltaxrate })} />} label="Taxrate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalltaxrate} onChange={(e) => taxrateAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ataxrate} onChange={(e) => setRole({ ...role, ataxrate: !role.ataxrate })} />} label="Add taxrate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.etaxrate} onChange={(e) => setRole({ ...role, etaxrate: !role.etaxrate })} />} label="Edit taxrate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dtaxrate} onChange={(e) => setRole({ ...role, dtaxrate: !role.dtaxrate })} />} label="Delete taxrate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceltaxrate} onChange={(e) => setRole({ ...role, exceltaxrate: !role.exceltaxrate })} />} label="Excel taxrate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvtaxrate} onChange={(e) => setRole({ ...role, csvtaxrate: !role.csvtaxrate })} />} label="Csv taxrate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printtaxrate} onChange={(e) => setRole({ ...role, printtaxrate: !role.printtaxrate })} />} label="Print taxrate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdftaxrate} onChange={(e) => setRole({ ...role, pdftaxrate: !role.pdftaxrate })} />} label="Pdf taxrate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Taxrate Group */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alltaxrategroup} onChange={(e) => setRole({ ...role, alltaxrategroup: !role.alltaxrategroup })} />} label="Taxrate group" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalltaxrategroup} onChange={(e) => taxrateGroupAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ataxrategroup} onChange={(e) => setRole({ ...role, ataxrategroup: !role.ataxrategroup })} />} label="Add taxrate group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dtaxrategroup} onChange={(e) => setRole({ ...role, dtaxrategroup: !role.dtaxrategroup })} />} label="Delete taxrate group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceltaxrategroup} onChange={(e) => setRole({ ...role, exceltaxrategroup: !role.exceltaxrategroup })} />} label="Excel taxrate group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvtaxrategroup} onChange={(e) => setRole({ ...role, csvtaxrategroup: !role.csvtaxrategroup })} />} label="Csv taxrate group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printtaxrategroup} onChange={(e) => setRole({ ...role, printtaxrategroup: !role.printtaxrategroup })} />} label="Print taxrate group" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdftaxrategroup} onChange={(e) => setRole({ ...role, pdftaxrategroup: !role.pdftaxrategroup })} />} label="Pdf taxrate group" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Tax Rate Hsn */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alltaxratehsn} onChange={(e) => setRole({ ...role, alltaxratehsn: !role.alltaxratehsn })} />} label="Taxrate Hsn" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalltaxratehsn} onChange={(e) => hsnAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.ataxratehsn} onChange={(e) => setRole({ ...role, ataxratehsn: !role.ataxratehsn })} />} label="Add Taxrate hsn" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.dtaxratehsn} onChange={(e) => setRole({ ...role, dtaxratehsn: !role.dtaxratehsn })} />} label="Delete Taxrate hsn" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceltaxratehsn} onChange={(e) => setRole({ ...role, exceltaxratehsn: !role.exceltaxratehsn })} />} label="Excel Taxrate hsn" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvtaxratehsn} onChange={(e) => setRole({ ...role, csvtaxratehsn: !role.csvtaxratehsn })} />} label="Csv Taxrate hsn" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printtaxratehsn} onChange={(e) => setRole({ ...role, printtaxratehsn: !role.printtaxratehsn })} />} label="Print Taxrate hsn" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdftaxratehsn} onChange={(e) => setRole({ ...role, pdftaxratehsn: !role.pdftaxratehsn })} />} label="Pdf Taxrate hsn" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Gstr Purchase */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgstrpurchase} onChange={(e) => setRole({ ...role, allgstrpurchase: !role.allgstrpurchase })} />} label="Gstr Purchase" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgstrpurchase} onChange={(e) => gstrPurchaseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgstrpurchase} onChange={(e) => setRole({ ...role, excelgstrpurchase: !role.excelgstrpurchase })} />} label="Excel Gstr Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgstrpurchase} onChange={(e) => setRole({ ...role, csvgstrpurchase: !role.csvgstrpurchase })} />} label="Csv Gstr Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgstrpurchase} onChange={(e) => setRole({ ...role, printgstrpurchase: !role.printgstrpurchase })} />} label="Print Gstr Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgstrpurchase} onChange={(e) => setRole({ ...role, pdfgstrpurchase: !role.pdfgstrpurchase })} />} label="Pdf Gstr Purchase" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Gstr Purchase Return */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgstrpurchasereturn} onChange={(e) => setRole({ ...role, allgstrpurchasereturn: !role.allgstrpurchasereturn })} />} label="Gstr Purchase Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgstrpurchasereturn} onChange={(e) => gstrPurchaseRetAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgstrpurchasereturn} onChange={(e) => setRole({ ...role, excelgstrpurchasereturn: !role.excelgstrpurchasereturn })} />} label="Excel Gstr Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgstrpurchasereturn} onChange={(e) => setRole({ ...role, csvgstrpurchasereturn: !role.csvgstrpurchasereturn })} />} label="Csv Gstr Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgstrpurchasereturn} onChange={(e) => setRole({ ...role, printgstrpurchasereturn: !role.printgstrpurchasereturn })} />} label="Print Gstr Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgstrpurchasereturn} onChange={(e) => setRole({ ...role, pdfgstrpurchasereturn: !role.pdfgstrpurchasereturn })} />} label="Pdf Gstr Purchase Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Gstr Retail sales Return */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgstrretailsalesret} onChange={(e) => setRole({ ...role, allgstrretailsalesret: !role.allgstrretailsalesret })} />} label="Gstr Retail Sales Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgstrretailsalesret} onChange={(e) => gstrRetailSaleRetAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgstrretailsalesret} onChange={(e) => setRole({ ...role, excelgstrretailsalesret: !role.excelgstrretailsalesret })} />} label="Excel Gstr Retail Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgstrretailsalesret} onChange={(e) => setRole({ ...role, csvgstrretailsalesret: !role.csvgstrretailsalesret })} />} label="Csv Gstr Retail Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgstrretailsalesret} onChange={(e) => setRole({ ...role, printgstrretailsalesret: !role.printgstrretailsalesret })} />} label="Print Gstr Retail Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgstrretailsalesret} onChange={(e) => setRole({ ...role, pdfgstrretailsalesret: !role.pdfgstrretailsalesret })} />} label="Pdf Gstr Retail Sales Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Gstr Sales Retail */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgstrsalesretail} onChange={(e) => setRole({ ...role, allgstrsalesretail: !role.allgstrsalesretail })} />} label="Gstr Sales Retail" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgstrsalesretail} onChange={(e) => gstrSalesRetailAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgstrsalesretail} onChange={(e) => setRole({ ...role, excelgstrsalesretail: !role.excelgstrsalesretail })} />} label="Excel Gstr Sales Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgstrsalesretail} onChange={(e) => setRole({ ...role, csvgstrsalesretail: !role.csvgstrsalesretail })} />} label="Csv Gstr Sales Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgstrsalesretail} onChange={(e) => setRole({ ...role, printgstrsalesretail: !role.printgstrsalesretail })} />} label="Print Gstr Sales Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgstrsalesretail} onChange={(e) => setRole({ ...role, pdfgstrsalesretail: !role.pdfgstrsalesretail })} />} label="Pdf Gstr Sales Retail" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Gstr Whole sales */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgstrwholesale} onChange={(e) => setRole({ ...role, allgstrwholesale: !role.allgstrwholesale })} />} label="Gstr Whole Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgstrwholesale} onChange={(e) => gstrWholeSaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgstrwholesale} onChange={(e) => setRole({ ...role, excelgstrwholesale: !role.excelgstrwholesale })} />} label="Excel Gstr Whole Sales " />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgstrwholesale} onChange={(e) => setRole({ ...role, csvgstrwholesale: !role.csvgstrwholesale })} />} label="Csv Gstr Whole Sales " />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgstrwholesale} onChange={(e) => setRole({ ...role, printgstrwholesale: !role.printgstrwholesale })} />} label="Print Gstr Whole Sales " />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgstrwholesale} onChange={(e) => setRole({ ...role, pdfgstrwholesale: !role.pdfgstrwholesale })} />} label="Pdf Gstr Whole Sales " />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Gstr Whole Sales Return */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allgstrwholesaleret} onChange={(e) => setRole({ ...role, allgstrwholesaleret: !role.allgstrwholesaleret })} />} label="Gstr Whole Sales Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallgstrwholesaleret} onChange={(e) => gstrWholeSaleRetAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelgstrwholesaleret} onChange={(e) => setRole({ ...role, excelgstrwholesaleret: !role.excelgstrwholesaleret })} />} label="Excel Gstr Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvgstrwholesaleret} onChange={(e) => setRole({ ...role, csvgstrwholesaleret: !role.csvgstrwholesaleret })} />} label="Csv Gstr Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printgstrwholesaleret} onChange={(e) => setRole({ ...role, printgstrwholesaleret: !role.printgstrwholesaleret })} />} label="Print Gstr Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfgstrwholesaleret} onChange={(e) => setRole({ ...role, pdfgstrwholesaleret: !role.pdfgstrwholesaleret })} />} label="Pdf Gstr Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Hsn Wise Report Retails */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allhsnwisereportretails} onChange={(e) => setRole({ ...role, allhsnwisereportretails: !role.allhsnwisereportretails })} />} label="Hsn Wise Report Retails" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallhsnwisereportretails} onChange={(e) => hsnWiseRepRetailAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelhsnwisereportretails} onChange={(e) => setRole({ ...role, excelhsnwisereportretails: !role.excelhsnwisereportretails })} />} label="Excel Hsn Wise Report Retails" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvhsnwisereportretails} onChange={(e) => setRole({ ...role, csvhsnwisereportretails: !role.csvhsnwisereportretails })} />} label="Csv Hsn Wise Report Retails" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printhsnwisereportretails} onChange={(e) => setRole({ ...role, printhsnwisereportretails: !role.printhsnwisereportretails })} />} label="Print Hsn Wise Report Retails" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfhsnwisereportretails} onChange={(e) => setRole({ ...role, pdfhsnwisereportretails: !role.pdfhsnwisereportretails })} />} label="Pdf Hsn Wise Report Retails" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Hsn Wise Report Retails Sales Return */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allhsnwiserepretailsalesret} onChange={(e) => setRole({ ...role, allhsnwiserepretailsalesret: !role.allhsnwiserepretailsalesret })} />} label="Hsn Wise Report Retails Sales Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallhsnwiserepretailsalesret} onChange={(e) => hsnWiseRepRetailSalesRetAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelhsnwiserepretailsalesret} onChange={(e) => setRole({ ...role, excelhsnwiserepretailsalesret: !role.excelhsnwiserepretailsalesret })} />} label="Excel Hsn Wise Report Retails Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvhsnwiserepretailsalesret} onChange={(e) => setRole({ ...role, csvhsnwiserepretailsalesret: !role.csvhsnwiserepretailsalesret })} />} label="Csv Hsn Wise Report Retails Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printhsnwiserepretailsalesret} onChange={(e) => setRole({ ...role, printhsnwiserepretailsalesret: !role.printhsnwiserepretailsalesret })} />} label="Print Hsn Wise Report Retails Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfhsnwiserepretailsalesret} onChange={(e) => setRole({ ...role, pdfhsnwiserepretailsalesret: !role.pdfhsnwiserepretailsalesret })} />} label="Pdf Hsn Wise Report Retails Sales Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Hsn Wise Report Whole Sales*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allhsnwiserepwholesales} onChange={(e) => setRole({ ...role, allhsnwiserepwholesales: !role.allhsnwiserepwholesales })} />} label="Hsn Wise Report Whole Sales" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallhsnwiserepwholesales} onChange={(e) => hsnWiseRepWholeSalesAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelhsnwiserepwholesales} onChange={(e) => setRole({ ...role, excelhsnwiserepwholesales: !role.excelhsnwiserepwholesales })} />} label="Excel Hsn Wise Report Whole Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvhsnwiserepwholesales} onChange={(e) => setRole({ ...role, csvhsnwiserepwholesales: !role.csvhsnwiserepwholesales })} />} label="Csv Hsn Wise Report Whole Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printhsnwiserepwholesales} onChange={(e) => setRole({ ...role, printhsnwiserepwholesales: !role.printhsnwiserepwholesales })} />} label="Print Hsn Wise Report Whole Sales" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfhsnwiserepwholesales} onChange={(e) => setRole({ ...role, pdfhsnwiserepwholesales: !role.pdfhsnwiserepwholesales })} />} label="Pdf Hsn Wise Report Whole Sales" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Hsn Wise Report Whole Sales Return*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allhsnwiserepwholesalereturn} onChange={(e) => setRole({ ...role, allhsnwiserepwholesalereturn: !role.allhsnwiserepwholesalereturn })} />} label="Hsn Wise Report Whole Sales Return" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallhsnwiserepwholesalereturn} onChange={(e) => hsnWiseRepWholeSalesRetAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelhsnwiserepwholesalereturn} onChange={(e) => setRole({ ...role, excelhsnwiserepwholesalereturn: !role.excelhsnwiserepwholesalereturn })} />} label="Excel Hsn Wise Report Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvhsnwiserepwholesalereturn} onChange={(e) => setRole({ ...role, csvhsnwiserepwholesalereturn: !role.csvhsnwiserepwholesalereturn })} />} label="Csv Hsn Wise Report Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printhsnwiserepwholesalereturn} onChange={(e) => setRole({ ...role, printhsnwiserepwholesalereturn: !role.printhsnwiserepwholesalereturn })} />} label="Print Hsn Wise Report Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfhsnwiserepwholesalereturn} onChange={(e) => setRole({ ...role, pdfhsnwiserepwholesalereturn: !role.pdfhsnwiserepwholesalereturn })} />} label="Pdf Hsn Wise Report Whole Sales Return" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Hsn Wise Summary Purchase*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allhsnwisesummarypurchase} onChange={(e) => setRole({ ...role, allhsnwisesummarypurchase: !role.allhsnwisesummarypurchase })} />} label="Hsn Wise Summary Purchase" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallhsnwisesummarypurchase} onChange={(e) => hsnWiseSummarypurchaseAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelhsnwisesummarypurchase} onChange={(e) => setRole({ ...role, excelhsnwisesummarypurchase: !role.excelhsnwisesummarypurchase })} />} label="Excel Hsn Wise Summary Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvhsnwisesummarypurchase} onChange={(e) => setRole({ ...role, csvhsnwisesummarypurchase: !role.csvhsnwisesummarypurchase })} />} label="Csv Hsn Wise Summary Purchase" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printhsnwisesummarypurchase} onChange={(e) => setRole({ ...role, printhsnwisesummarypurchase: !role.printhsnwisesummarypurchase })} />} label="Print Hsn Summary Purchase Return" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfhsnwisesummarypurchase} onChange={(e) => setRole({ ...role, pdfhsnwisesummarypurchase: !role.pdfhsnwisesummarypurchase })} />} label="Pdf Hsn Wise Summary Purchase" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>
                        {/* Settings Management End.. */}

                        {/* Report Management Start.... */}

                        {/* Bill Wise Estimate */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={12}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.reportmanagement} onChange={(e) => setRole({ ...role, reportmanagement: !role.reportmanagement })} />} label="Report management" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbillwiseprofitestimate} onChange={(e) => setRole({ ...role, allbillwiseprofitestimate: !role.allbillwiseprofitestimate })} />} label="Bill Wise Profit Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbillwiseprofitestimate} onChange={(e) => billWiseProfitEstAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbillwiseprofitestimate} onChange={(e) => setRole({ ...role, csvbillwiseprofitestimate: !role.csvbillwiseprofitestimate })} />} label="Csv Bill Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbillwiseprofitestimate} onChange={(e) => setRole({ ...role, excelbillwiseprofitestimate: !role.excelbillwiseprofitestimate })} />} label="Excel Bill Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbillwiseprofitestimate} onChange={(e) => setRole({ ...role, printbillwiseprofitestimate: !role.printbillwiseprofitestimate })} />} label="Print Bill Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbillwiseprofitestimate} onChange={(e) => setRole({ ...role, pdfbillwiseprofitestimate: !role.pdfbillwiseprofitestimate })} />} label="Pdf Bill Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Bill Wise Retail */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbillwiseprofitretail} onChange={(e) => setRole({ ...role, allbillwiseprofitretail: !role.allbillwiseprofitretail })} />} label="Bill Wise Profit Retail" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbillwiseprofitretail} onChange={(e) => billWiseProfitRetAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbillwiseprofitretail} onChange={(e) => setRole({ ...role, csvbillwiseprofitretail: !role.csvbillwiseprofitretail })} />} label="Csv Bill Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbillwiseprofitretail} onChange={(e) => setRole({ ...role, excelbillwiseprofitretail: !role.excelbillwiseprofitretail })} />} label="Excel Bill Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbillwiseprofitretail} onChange={(e) => setRole({ ...role, printbillwiseprofitretail: !role.printbillwiseprofitretail })} />} label="Print Bill Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbillwiseprofitretail} onChange={(e) => setRole({ ...role, pdfbillwiseprofitretail: !role.pdfbillwiseprofitretail })} />} label="Pdf Bill Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Bill Wise Whole Sale */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allbillwiseprofitwholesale} onChange={(e) => setRole({ ...role, allbillwiseprofitwholesale: !role.allbillwiseprofitwholesale })} />} label="Bill Wise Profit Whole Sale" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallbillwiseprofitwholesale} onChange={(e) => billWiseProfitWholesaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvbillwiseprofitwholesale} onChange={(e) => setRole({ ...role, csvbillwiseprofitwholesale: !role.csvbillwiseprofitwholesale })} />} label="Csv Bill Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelbillwiseprofitwholesale} onChange={(e) => setRole({ ...role, excelbillwiseprofitwholesale: !role.excelbillwiseprofitwholesale })} />} label="Excel Bill Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printbillwiseprofitwholesale} onChange={(e) => setRole({ ...role, printbillwiseprofitwholesale: !role.printbillwiseprofitwholesale })} />} label="Print Bill Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfbillwiseprofitwholesale} onChange={(e) => setRole({ ...role, pdfbillwiseprofitwholesale: !role.pdfbillwiseprofitwholesale })} />} label="Pdf Bill Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Customer Comparison */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomercomparison} onChange={(e) => setRole({ ...role, allcustomercomparison: !role.allcustomercomparison })} />} label="Customer Comparison" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomercomparison} onChange={(e) => customerComparisonAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomercomparison} onChange={(e) => setRole({ ...role, csvcustomercomparison: !role.csvcustomercomparison })} />} label="Csv Customer Comparison" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomercomparison} onChange={(e) => setRole({ ...role, excelcustomercomparison: !role.excelcustomercomparison })} />} label=" Excel Customer Comparison" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomercomparison} onChange={(e) => setRole({ ...role, printcustomercomparison: !role.printcustomercomparison })} />} label="Print Customer Comparison" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomercomparison} onChange={(e) => setRole({ ...role, pdfcustomercomparison: !role.pdfcustomercomparison })} />} label="Pdf Customer Comparison" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Customer Wise Profit Estimate */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerwiseprofitestimate} onChange={(e) => setRole({ ...role, allcustomerwiseprofitestimate: !role.allcustomerwiseprofitestimate })} />} label="Customer Wise Profit Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerwiseprofitestimate} onChange={(e) => customerWiseProfitEstAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerwiseprofitestimate} onChange={(e) => setRole({ ...role, csvcustomerwiseprofitestimate: !role.csvcustomerwiseprofitestimate })} />} label="Csv Customer Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerwiseprofitestimate} onChange={(e) => setRole({ ...role, excelcustomerwiseprofitestimate: !role.excelcustomerwiseprofitestimate })} />} label=" Excel Customer Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerwiseprofitestimate} onChange={(e) => setRole({ ...role, printcustomerwiseprofitestimate: !role.printcustomerwiseprofitestimate })} />} label="Print Customer Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerwiseprofitestimate} onChange={(e) => setRole({ ...role, pdfcustomerwiseprofitestimate: !role.pdfcustomerwiseprofitestimate })} />} label="Pdf Customer Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/* Customer Wise Profit Whole Sale */}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allcustomerwiseprofitwholesale} onChange={(e) => setRole({ ...role, allcustomerwiseprofitwholesale: !role.allcustomerwiseprofitwholesale })} />} label="Customer Wise Profit Whole Sale" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallcustomerwiseprofitwholesale} onChange={(e) => customerWiseProfitWholesaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvcustomerwiseprofitwholesale} onChange={(e) => setRole({ ...role, csvcustomerwiseprofitwholesale: !role.csvcustomerwiseprofitwholesale })} />} label="Csv Customer Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelcustomerwiseprofitwholesale} onChange={(e) => setRole({ ...role, excelcustomerwiseprofitwholesale: !role.excelcustomerwiseprofitwholesale })} />} label=" Excel Customer Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printcustomerwiseprofitwholesale} onChange={(e) => setRole({ ...role, printcustomerwiseprofitwholesale: !role.printcustomerwiseprofitwholesale })} />} label="Print Customer Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfcustomerwiseprofitwholesale} onChange={(e) => setRole({ ...role, pdfcustomerwiseprofitwholesale: !role.pdfcustomerwiseprofitwholesale })} />} label="Pdf Customer Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Day Wise Profit Estimate*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldaywiseprofitestimate} onChange={(e) => setRole({ ...role, alldaywiseprofitestimate: !role.alldaywiseprofitestimate })} />} label="Day Wise Profit Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldaywiseprofitestimate} onChange={(e) => dayWiseProfitEstAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdaywiseprofitestimate} onChange={(e) => setRole({ ...role, csvdaywiseprofitestimate: !role.csvdaywiseprofitestimate })} />} label="Csv Day Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldaywiseprofitestimate} onChange={(e) => setRole({ ...role, exceldaywiseprofitestimate: !role.exceldaywiseprofitestimate })} />} label="Excel Day Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdaywiseprofitestimate} onChange={(e) => setRole({ ...role, printdaywiseprofitestimate: !role.printdaywiseprofitestimate })} />} label="Print Day Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdaywiseprofitestimate} onChange={(e) => setRole({ ...role, pdfdaywiseprofitestimate: !role.pdfdaywiseprofitestimate })} />} label="Pdf Day Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Day Wise Profit Whole Sale*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldaywiseprofitwholesale} onChange={(e) => setRole({ ...role, alldaywiseprofitwholesale: !role.alldaywiseprofitwholesale })} />} label="Day Wise Profit Whole Sale" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldaywiseprofitwholesale} onChange={(e) => dayWiseProfitWholesaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdaywiseprofitwholesale} onChange={(e) => setRole({ ...role, csvdaywiseprofitwholesale: !role.csvdaywiseprofitwholesale })} />} label="Csv Day Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldaywiseprofitwholesale} onChange={(e) => setRole({ ...role, exceldaywiseprofitwholesale: !role.exceldaywiseprofitwholesale })} />} label="Excel Day Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdaywiseprofitwholesale} onChange={(e) => setRole({ ...role, printdaywiseprofitwholesale: !role.printdaywiseprofitwholesale })} />} label="Print Day Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdaywiseprofitwholesale} onChange={(e) => setRole({ ...role, pdfdaywiseprofitwholesale: !role.pdfdaywiseprofitwholesale })} />} label="Pdf Day Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Day Wise Profit Retail*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.alldaywiseprofitretail} onChange={(e) => setRole({ ...role, alldaywiseprofitretail: !role.alldaywiseprofitretail })} />} label="Day Wise Profit Retail" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkalldaywiseprofitretail} onChange={(e) => dayWiseProfitRetailAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvdaywiseprofitretail} onChange={(e) => setRole({ ...role, csvdaywiseprofitretail: !role.csvdaywiseprofitretail })} />} label="Csv Day Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.exceldaywiseprofitretail} onChange={(e) => setRole({ ...role, exceldaywiseprofitretail: !role.exceldaywiseprofitretail })} />} label="Excel Day Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printdaywiseprofitretail} onChange={(e) => setRole({ ...role, printdaywiseprofitretail: !role.printdaywiseprofitretail })} />} label="Print Day Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfdaywiseprofitretail} onChange={(e) => setRole({ ...role, pdfdaywiseprofitretail: !role.pdfdaywiseprofitretail })} />} label="Pdf Day Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Item Wise Profit Estimate*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwiseprofitestimate} onChange={(e) => setRole({ ...role, allitemwiseprofitestimate: !role.allitemwiseprofitestimate })} />} label="Item Wise Profit Estimate" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwiseprofitestimate} onChange={(e) => itemWiseProfitEstAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwiseprofitestimate} onChange={(e) => setRole({ ...role, csvitemwiseprofitestimate: !role.csvitemwiseprofitestimate })} />} label="Csv Item Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwiseprofitestimate} onChange={(e) => setRole({ ...role, excelitemwiseprofitestimate: !role.excelitemwiseprofitestimate })} />} label="Excel Item Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwiseprofitestimate} onChange={(e) => setRole({ ...role, printitemwiseprofitestimate: !role.printitemwiseprofitestimate })} />} label="Print Item Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwiseprofitestimate} onChange={(e) => setRole({ ...role, pdfitemwiseprofitestimate: !role.pdfitemwiseprofitestimate })} />} label="Pdf Item Wise Profit Estimate" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Item Wise Profit Retail*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allItemwiseprofitretail} onChange={(e) => setRole({ ...role, allitemwiseprofitretail: !role.allitemwiseprofitretail })} />} label="Item Wise Profit Retail" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwiseprofitretail} onChange={(e) => itemWiseProfitRetailAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwiseprofitretail} onChange={(e) => setRole({ ...role, csvitemwiseprofitretail: !role.csvitemwiseprofitretail })} />} label="Csv Item Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwiseprofitretail} onChange={(e) => setRole({ ...role, excelitemwiseprofitretail: !role.excelitemwiseprofitretail })} />} label="Excel Item Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwiseprofitretail} onChange={(e) => setRole({ ...role, printitemwiseprofitretail: !role.printitemwiseprofitretail })} />} label="Print Item Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwiseprofitretail} onChange={(e) => setRole({ ...role, pdfitemwiseprofitretail: !role.pdfitemwiseprofitretail })} />} label="Pdf Item Wise Profit Retail" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Item Wise Profit Whole Sale*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allitemwiseprofitwholesale} onChange={(e) => setRole({ ...role, allitemwiseprofitwholesale: !role.allitemwiseprofitwholesale })} />} label="Item Wise Profit Whole Sale" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallitemwiseprofitwholesale} onChange={(e) => itemWiseProfitWholesaleAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvitemwiseprofitwholesale} onChange={(e) => setRole({ ...role, csvitemwiseprofitwholesale: !role.csvitemwiseprofitwholesale })} />} label="Csv Item Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelitemwiseprofitwholesale} onChange={(e) => setRole({ ...role, excelitemwiseprofitwholesale: !role.excelitemwiseprofitwholesale })} />} label="Excel Item Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printitemwiseprofitwholesale} onChange={(e) => setRole({ ...role, printitemwiseprofitwholesale: !role.printitemwiseprofitwholesale })} />} label="Print Item Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfitemwiseprofitwholesale} onChange={(e) => setRole({ ...role, pdfitemwiseprofitwholesale: !role.pdfitemwiseprofitwholesale })} />} label="Pdf Item Wise Profit Whole Sale" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Reference Comparison*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allreferencecomparison} onChange={(e) => setRole({ ...role, allreferencecomparison: !role.allreferencecomparison })} />} label="Reference Comparison" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallreferencecomparison} onChange={(e) => referenceComparisonAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvreferencecomparison} onChange={(e) => setRole({ ...role, csvreferencecomparison: !role.csvreferencecomparison })} />} label="Csv Reference Comparison" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelreferencecomparison} onChange={(e) => setRole({ ...role, excelreferencecomparison: !role.excelreferencecomparison })} />} label="Excel Reference Comparison" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printreferencecomparison} onChange={(e) => setRole({ ...role, printreferencecomparison: !role.printreferencecomparison })} />} label="Print Reference Comparison" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfreferencecomparison} onChange={(e) => setRole({ ...role, pdfreferencecomparison: !role.pdfreferencecomparison })} />} label="Pdf Reference Comparison" />
                                    </FormGroup>
                                </Grid>
                            </Grid><br /><hr /><br />
                        </Grid>

                        {/*Reference Summary*/}

                        <Divider sx={{ my: 2 }} />
                        <Grid item md={3}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.allreferencesummary} onChange={(e) => setRole({ ...role, allreferencesummary: !role.allreferencesummary })} />} label="Reference Summary" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={2}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={role.checkallreferencesummary} onChange={(e) => referenceSummaryAllSelect()} />} label="Select All" />
                            </FormGroup>
                        </Grid>
                        <Grid item md={6}>
                            <Grid display="block">
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.csvreferencesummary} onChange={(e) => setRole({ ...role, csvreferencesummary: !role.csvreferencesummary })} />} label="Csv Reference Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.excelreferencesummary} onChange={(e) => setRole({ ...role, excelreferencesummary: !role.excelreferencesummary })} />} label="Excel Reference Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.printreferencesummary} onChange={(e) => setRole({ ...role, printreferencesummary: !role.printreferencesummary })} />} label="Print Reference Summary" />
                                    </FormGroup>
                                </Grid>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.pdfreferencesummary} onChange={(e) => setRole({ ...role, pdfreferencesummary: !role.pdfreferencesummary })} />} label="Pdf Reference Summary" />
                                    </FormGroup>
                                </Grid><br /><br />
                                <Divider sx={{ my: 2 }} />
                                <Grid item md={12}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={role.businesssettings} onChange={(e) => setRole({ ...role, businesssettings: !role.businesssettings })} />} label="Business settings" />
                                    </FormGroup>
                                </Grid>
                                <Divider sx={{ my: 2 }} />
                                <Grid item md={2}>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked checked={role.home} onChange={(e) => setRole({ ...role, home: !role.home })} />} label="Home" />
                                    </FormGroup>
                                </Grid>
                                <Grid item md={12} sm={6} xs={6} >
                                    <Button sx={userStyle.buttonadd} type="submit">SAVE</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Report Management End.... */}
                    </Grid>
                </Box>
            </form>
        </Box >
    );
}

function Roleedit() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Roleeditlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Roleedit;
