import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jsPDF from "jspdf";
import { ExportXL, ExportCSV } from '../../Export';
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { useReactToPrint } from 'react-to-print';


function Categorieslisttable() {

  const [categories, setCategories] = useState();
  //  Excel data
  const [exceldata, setExceldata] = useState([]);
  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //delete modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [cats, setCats] = useState({});

  const { auth } = useContext(AuthContext);

  const handleClickOpen = () => {
    setIsDeleteOpen(true);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  //  Fetch Category Data
  const fetchCategory = async () => {
    try {
      let req = await axios.get(SERVICE.CATEGORIES, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setCategories(req.data.categories);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let req = await axios.get(SERVICE.CATEGORIES, {
      headers: {
        'Authorization': `Bearer ${auth.APIToken}`
      }
    });
    var data = req.data.categories.map(t => ({
      "Category Name": t.categoryname, "Category Code": t.categorycode, "Description": t.categorydescription,

    }));
    setExceldata(data);
  }

  const rowData = async (id) => {
    try {
      let req = await axios.get(`${SERVICE.CATEGORY}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      setCats(req.data.scategory);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //alert delete popup
  let catid = cats._id;
  const deleteCats = async () => {
    try {
      let res = await axios.delete(`${SERVICE.CATEGORY}/${catid}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CATEGORY',
    pageStyle: 'print'
  });

useEffect(
  ()=>{
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  },[]
)
  useEffect(
    () => {
      getexcelDatas();
      fetchCategory();
    },[categories]
  );

  // Export Excel
  const fileName = 'Category'

  const columns = [{ title: "CATEGORY NAME", field: "categoryname", }, { title: "CATEGORY CODE", field: "categorycode", }, { title: "CATEGORY DESCRIPTION", field: "categorydescription" }]  // PDF

   //PDF
   const downloadPdf = () => {
    const doc = new jsPDF()
    doc.autoTable({
      html: '#categorytablepdf',
      margin: { top: 10 },
    })

    doc.save('Category.pdf')
  }
  return (
    <Box>
      <Headtitle title={'Categories'} />
      {/* header text */}
      <Typography sx={userStyle.HeaderText} variant='body2'>Categories<Typography sx={userStyle.SubHeaderText} component="span" variant='body2'>Manage your categories</Typography></Typography>
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}><Typography sx={userStyle.HeaderText} variant='body2'>All Your Categories </Typography></Grid>
          <Grid item xs={4}>
            {isUserRoleCompare[0].acategory && (
              <>
                <Link to="/product/category/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd} s="true" >ADD</Button></Link>
              </>
            )}
          </Grid>
           <Grid container sx={userStyle.gridcontainer}>
              <Grid>
              {isUserRoleCompare[0].csvcategory && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelcategory && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printcategory && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint} s="true">&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfcategory && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
              </Grid>
            </Grid>
        </Grid><br />
        <Box>
          {/* Table Start */}
          <TableContainer component={Paper}>
            <Table aria-label="customized table" id="categorytable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Actions</StyledTableCell>
                  <StyledTableCell>Category Name</StyledTableCell>
                  <StyledTableCell >Category Code</StyledTableCell>
                  <StyledTableCell >Description</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {categories &&
                  (categories.map((item, index) => (
                    <StyledTableRow key={index} >
                      <StyledTableCell>
                        <Grid sx={{ display: 'flex' }}>
                          {isUserRoleCompare[0].ecategory && (
                            <>
                              <Link to={`/product/category/edit/${item._id}`} style={{ textDecoration: 'none', color: '#fff' }}><Button sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: "large" }} /></Button></Link>
                            </>
                          )}
                          {isUserRoleCompare[0].dcategory && (
                            <>
                              <Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(item._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                            </>
                          )}
                        </Grid>
                      </StyledTableCell>
                      <StyledTableCell>{item.categoryname}</StyledTableCell>
                      <StyledTableCell>{item.categorycode}</StyledTableCell>
                      <StyledTableCell>{item.categorydescription} </StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Table End */}
        </Box>
      </Box>
      { /* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="categorytablepdf" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Category Name</StyledTableCell>
              <StyledTableCell >Category Code</StyledTableCell>
              <StyledTableCell >Description</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {categories &&
              (categories.map((item, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">{item.categoryname}</StyledTableCell>
                  <StyledTableCell>{item.categorycode}</StyledTableCell>
                  <StyledTableCell>{item.categorydescription} </StyledTableCell>
                </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* ALERT DIALOG */}
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={(e) => deleteCats(catid)} autoFocus variant="contained" color='error'> OK </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export function Categorieslist() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Categorieslisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Categorieslist;