import React, { useState, useContext } from 'react';
import { Button, Typography, Grid, FormControl, Box, OutlinedInput, Dialog, TextareaAutosize, DialogContent, DialogActions } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { useNavigate } from 'react-router-dom';
import { userStyle } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from "../../../services/Baseservice";
function Bankmasterlist() {
    const { auth } = useContext(AuthContext);
    //  Add Text Field
    const [bankDetail, setBankDetail] = useState({
        accno: "", name: "", bank: "", branch: "", ifsccode: "", remarks: "",
    });
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };
    const backLPage = useNavigate();
    //New customer Bank details add page
    const addbankDetails = async () => {
        try {
            let req = await axios.post(SERVICE.BANKMASTER_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                accno: Number(bankDetail.accno),
                name: String(bankDetail.name),
                bank: String(bankDetail.bank),
                branch: String(bankDetail.branch),
                ifsccode: String(bankDetail.ifsccode),
                remarks: String(bankDetail.remarks),
            }
            );
            setBankDetail(req.data);
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            //redirecting list page 
            backLPage('/bank/bank/list')
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };
    //New customer Bank details add page for submit button
    const addbankDetailsAnother = async () => {
        try {
            let req = await axios.post(SERVICE.BANKMASTER_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                accno: Number(bankDetail.accno),
                name: String(bankDetail.name),
                bank: String(bankDetail.bank),
                branch: String(bankDetail.branch),
                ifsccode: String(bankDetail.ifsccode),
                remarks: String(bankDetail.remarks),
            }
            );
            setBankDetail(req.data);
            setBankDetail({
                accno: "", name: "", bank: "", branch: "", ifsccode: "", remarks: "",
            });

            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    };
    //save and another button 
    const handleSubmitAnother = (e) => {
        e.preventDefault();
        if (bankDetail.accno == "") {
            setShowAlert("Please enter Account number!");
            handleClickOpen();
        }
        else if (bankDetail.name == "") {
            setShowAlert("Please enter Account name!");
            handleClickOpen();
        }
        else if (bankDetail.ifsccode == "") {
            setShowAlert("Please enter IFSC code!");
            handleClickOpen();
        }
        else {
            addbankDetailsAnother();
        }
    }
    //save button
    const handleSubmit = (e) => {
        e.preventDefault();
        if (bankDetail.accno == "") {
            setShowAlert("Please enter Account number!");
            handleClickOpen();
        }
        else if (bankDetail.name == "") {
            setShowAlert("Please enter Account name!");
            handleClickOpen();
        }
        else if (bankDetail.ifsccode == "") {
            setShowAlert("Please enter IFSC code!");
            handleClickOpen();
        }
        else {
            addbankDetails();
        }
    }
    // cancel button
    const handleBack = (e) => {
        backLPage('/bank/bank/list')
    }
    const handleValidationName = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setBankDetail({ ...bankDetail, name: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setBankDetail({ ...bankDetail, name: value })
        }
    }


    const handleValidationBranch = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setBankDetail({ ...bankDetail, branch: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setBankDetail({ ...bankDetail, branch: value })
        }
    }
    const handleValidationIfsc = (e) => {
        let val = e.target.value;
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter (a-z) and number (0-9) only!")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setBankDetail({ ...bankDetail, ifsccode: value })
        }
    }
    const handleValidationBank = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setBankDetail({ ...bankDetail, bank: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setBankDetail({ ...bankDetail, bank: value })
        }
    }

    return (
        <Box>
            <Headtitle title={'ADD BANK'} />
            <Typography sx={userStyle.HeaderText}>Add Bank Master</Typography>
            <Box sx={userStyle.container}>
                <form >
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>A/C No <b style={{ color: 'red' }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={bankDetail.accno}
                                        onChange={(e) => { setBankDetail({ ...bankDetail, accno: e.target.value }) }}
                                        type="number"
                                        onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Name <b style={{ color: 'red' }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={bankDetail.name}
                                    onChange={(e) => { setBankDetail({ ...bankDetail, name: e.target.value }); handleValidationName(e) }}
                                    type="text"
                                    onKeyPress={event => (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Bank</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={bankDetail.bank}
                                    onChange={(e) => { setBankDetail({ ...bankDetail, bank: e.target.value }); handleValidationBank(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Branch</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={bankDetail.branch}
                                    onChange={(e) => { setBankDetail({ ...bankDetail, branch: e.target.value }); handleValidationBranch(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>IFSC Code <b style={{ color: 'red' }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    value={bankDetail.ifsccode}
                                    onChange={(e) => { setBankDetail({ ...bankDetail, ifsccode: e.target.value }); handleValidationIfsc(e) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Remarks</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #B97DF0' }}
                                    value={bankDetail.remarks}
                                    onChange={(e) => { setBankDetail({ ...bankDetail, remarks: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <br />
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Button sx={userStyle.buttoncancel} onClick={handleBack} >CANCEL</Button>
                                <Button sx={userStyle.buttonadd} type='button' onClick={handleSubmitAnother} >SAVE AND ANOTHER</Button>
                                <Button sx={userStyle.buttonadd} type='button' onClick={handleSubmit} >SAVE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}
function Bankcreate() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Bankmasterlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Bankcreate;