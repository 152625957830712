import React, { useState, useEffect, createRef, useRef } from 'react';
import { Box, Typography, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { userStyle } from '../../PageStyle';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import { toast } from 'react-toastify';

function Print() {

    const [roles, setRoles] = useState();

    // Roles
    const URL = "http://localhost:8003/api/roles";
    const fetchHandler = async () => {
        try {
            let role = await axios.get(URL);
            setRoles(role.data.roles)
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | ROLE',
        pageStyle: 'print'
    });
    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
    };

    useEffect(() => {
        fetchHandler().then(handleprint);
    }, []);

    return (
        <Box>
            <Box>
                <Typography variant='h5'>Role</Typography>
            </Box><br />
            <Box>
                <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table" id="example" ref={componentRef}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width: '600px !important' }}>ROLE NAME</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody align="left">
                            {roles && (
                                roles.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell component="th" scope="row">{row.rolename}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
}

export default Print;