import React, { useState, useContext } from 'react';
import { Box, Button, Grid, FormControl, OutlinedInput, InputLabel, Typography, TextareaAutosize, Dialog, DialogContent, DialogActions, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function Categorycreate() {
  //textfield
  const [categoryForm, setCategoryForm] = useState({ categoryname: "", categorycode: "", categorydescription: "", });
  const { auth } = useContext(AuthContext);

  //popup model
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState()
  const handleClickOpenc = () => {
    setIsErrorOpen(true);
  };
  const handleClose = () => {
    setIsErrorOpen(false);
  };

  const backLPage = useNavigate();

  // store category data to db
  const sendRequest = async () => {
    try {
      let req = await axios.post(SERVICE.CATEGORIES_CREATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        categoryname: String(categoryForm.categoryname),
        categorycode: String(categoryForm.categorycode),
        categorydescription: String(categoryForm.categorydescription),
      });
      setCategoryForm(req.data);
      toast.success(req.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      backLPage('/product/category/list');
    } catch (err) {
      const messages = err.response.data.message;
      setShowAlert(messages);
      handleClickOpenc();
    }
  };

  const addCateSubmit = (e) => {
    e.preventDefault();
    if (categoryForm.categoryname == "") {
      setShowAlert("Please enter name!");
      handleClickOpenc();
    } else if (categoryForm.categorycode == "") {
      setShowAlert("Please enter code!");
      handleClickOpenc();
    } else {
      sendRequest();
    }
  }

  return (
    <Box>
      <Headtitle title={'Add Category'} />
      <Typography sx={userStyle.HeaderText} variant='body2'>Add Category</Typography>
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={3} sx={userStyle.textInput}>
          <Grid item md={12} sm={12} xs={12}>
            <InputLabel htmlFor="component-outlined">Category Name <b style={{ color: "red" }}> *</b></InputLabel>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                id="component-outlined"
                value={categoryForm.categoryname}
                onChange={(e) => { setCategoryForm({ ...categoryForm, categoryname: e.target.value }) }}
                type="text"
                name="categoryname"
              />
            </FormControl>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <InputLabel htmlFor="component-outlined">Category Code<b style={{ color: "red" }}> *</b></InputLabel>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                id="component-outlined"
                value={categoryForm.categorycode}
                onChange={(e) => { setCategoryForm({ ...categoryForm, categorycode: e.target.value }) }}
                type="text"
                name="categorycode"
              />
            </FormControl>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <InputLabel id="demo-select-small">Description</InputLabel>
            <FormControl size="small" fullWidth >
              <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                value={categoryForm.categorydescription}
                onChange={(e) => { setCategoryForm({ ...categoryForm, categorydescription: e.target.value }) }}
                name="categorydescription"
              />
            </FormControl>
          </Grid>
        </Grid><br />
        <br />
        <Grid container sx={userStyle.gridcontainer}>
          <Grid sx={{ display: 'flex' }}>
            <Button sx={userStyle.buttonadd} type="submit" onClick={addCateSubmit}>SAVE</Button>
            <Link to="/product/category/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
          </Grid>
        </Grid>
      </Box>
      {/* ALERT DIALOG */}
      <Dialog
        open={isErrorOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h6" >{showAlert}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
function Catcreate() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Categorycreate /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Catcreate;