import React, { useState, useContext } from 'react';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, Button, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';

function CreateCatMod({setSaveExpcate}) {

    const [expenseCategoryForm, setExpenseCategoryForm] = useState({
        categoryname: "", categorycode: "",
    });
    const { auth } = useContext(AuthContext);
    //error
    const [isError, setIsError] = useState();    
    // Add Modal
    const [expcategorymodadd, setExpCategorymodadd] = useState(false);
    const openAdd = () => { setExpCategorymodadd(true); };
    const closeAdd = () => { setExpCategorymodadd(false); setExpenseCategoryForm({...expenseCategoryForm, categoryname:"",categorycode:""}); setIsError("")};

    const sendexpRequest = async() => {
        try {
            let res =  await axios.post(SERVICE.EXPENSE_CATEGORY_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                categoryname: String(expenseCategoryForm.categoryname),
                categorycode: String(expenseCategoryForm.categorycode)
            });
            setSaveExpcate("None");
            setExpenseCategoryForm(res.data);
            toast.success(res.data.message);
            closeAdd();
        } catch (err) {
            const messages = err.response.data.message;
            setIsError(messages);
        }
    };

    const addExpCateSubmit = (e) => {
        e.preventDefault();
        if(expenseCategoryForm.categoryname == ""){
            setIsError("Please enter catergory name!")
        }else  if(expenseCategoryForm.categorycode == ""){
            setIsError("Please enter catergory code!")
        }else {
            sendexpRequest();
        }
    }

    return (
        <Box>
            <Grid sx={userStyle.spanPlusIcons} onClick={openAdd}><AddCircleOutlineOutlinedIcon /></Grid>
            <Dialog
                aria-labelledby="customized-dialog-title1"
                open={expcategorymodadd}
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #b97df0',
                    },
                }}
            >
                <form>
                    <DialogTitle id="customized-dialog-title1"> Add Expense Category </DialogTitle>
                    <DialogContent dividers>
                        <p style={{color:'red'}}>{isError}</p>
                        <Grid container spacing={3}>
                            <Grid item md={12} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel htmlFor="component-outlined">Category name <b style={{color:'red'}}>*</b></InputLabel>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={expenseCategoryForm.categoryname}
                                        onChange={(e) => { setExpenseCategoryForm({ ...expenseCategoryForm, categoryname: e.target.value }) }} 
                                        label="Category name *"
                                        type="text"
                                        name="categoryname"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <FormControl size="small" fullWidth>
                                    <InputLabel htmlFor="component-outlined">Category Code <b style={{color:'red'}}>*</b></InputLabel>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={expenseCategoryForm.categorycode}
                                        onChange={(e) => { setExpenseCategoryForm({ ...expenseCategoryForm, categorycode: e.target.value }) }}
                                        label="Category Code *"
                                        type="text"
                                        name="categorycode"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant='contained' color="success" onClick={addExpCateSubmit} type="submit"> Save </Button>
                        <Button variant='contained' color="error" onClick={closeAdd}>Close</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}

export default CreateCatMod;