import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, FormGroup, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button } from "@mui/material";
import { userStyle, colourStyles } from "../../PageStyle";
import Selects from "react-select";
import Navbar from "../../../components/header/Navbar";
import Footer from "../../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../../components/Table";
import axios from "axios";
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { ExportXL, ExportCSV } from "../../Export";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import Headtitle from "../../../components/header/Headtitle";
import $ from "jquery";
import { UserRoleAccessContext } from "../../../context/Appcontext";
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function Customeroverdueabstractlist() {

  const [pos, setPos] = useState({});
  const [customernames, setCustomernames] = useState({});
  const [exceldata, setExceldata] = useState([]);
  const [newdata, setNewdata] = useState([]);
  const [checked, setChecked] = useState(true);
  const [code, setCode] = useState([]);
  const { auth } = useContext(AuthContext);

  // Areafilter ...................Area
  const [customerArea, setCustomerArea] = useState({
    area: "",
  });

  // Access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  let totalDays = 0;
  let datefiltervalue;
  let areaArr = []
  let allArray = [];
  let dueamntArr = [];
  let totCounArr = [];

  const fetchSelectAll = async (e) => {
    setChecked(!checked)
    try {
      let response = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let req = response.data.pos1.filter((item) => {

        var futuredate = new Date(new Date().setDate(new Date(item.date).getDate() + item.creditdays));
        datefiltervalue = moment(futuredate).utc().format('DD-MM-YYYY')
        totalDays = (Number(item.creditdays)) - ((new Date().getDate() - 1 - new Date(item.date).getDate()) - 1)
        const d = new Date(futuredate);
        let x = d.getDay()

        if (totalDays >= x) {
          totalDays = totalDays + x
        }
        if (item.dueamount > 0 && totalDays < 0) {
          return item
        }
      })

      if (checked === true) {
        //pushing into arrays to get values in table
        req.filter((value) => {
          areaArr.push(value.location)
          dueamntArr.push(+value.dueamount)
          totCounArr.push(value.referenceno)
        })
        allArray = areaArr.map(function (data, i) {
          return { area: data, totaldueamount: dueamntArr[i], totalcount: totCounArr[i] };
        });

        const finalresult = [...allArray.reduce((r, o) => {
          const res = o.area;
          const items = r.get(res) || Object.assign({}, o, {
            totaldueamount: 0,
          });
          items.totaldueamount += o.totaldueamount;
          items.totalcount += o.totalcount + ","

          return r.set(res, items);
        }, new Map).values()];
        setPos(finalresult);
      } else if (checked === false) {
        setPos([])
      }
      setCustomerArea({
        area: ""
      })
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Fetch customer adress 
  const fetchCustomerAddress = async () => {
    try {
      let res_group = await axios.get(SERVICE.CUSTOMER, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let masters_data = res_group?.data?.customers?.map((d) => {
        return d.city
      })
      setNewdata(masters_data);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  let newarray = [...new Set(newdata)];

  // Fetch customer Area for dropdown......Area
  const fetchcustomername = async () => {
    try {
      let req = await axios.get(SERVICE.CUSTOMER, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setCustomernames(
        newarray.map((d) => ({
          ...d,
          label: d,
          value: d,
        }))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchcustomername();
  }, [customernames])

  const getCateName = (e) => {
    if (e) {
      setPos([]) || setChecked(true)
    }
    setCode(e.area)
  }

  //jquery
  $.DataTable = require("datatables.net");
  const tableRef = useRef();

  //Customers
  const fetchPos = async () => {
    try {
      let req = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let getAddress = req.data.pos1.filter((data) => {
        var futuredate = new Date(new Date().setDate(new Date(data.date).getDate() + (data.creditdays * 24 * 60 * 60 * 1000)));
        datefiltervalue = moment(futuredate).utc().format('DD-MM-YYYY')
        totalDays = (Number(data.creditdays)) - ((new Date().getDate() - 1 - new Date(data.date).getDate()) + 1)
        const d = new Date(futuredate);
        let x = d.getDay()

        if (totalDays >= x) {
          totalDays = totalDays + x
          return totalDays
        }

        if (data.dueamount > 0 && totalDays < 0) {
          if (customerArea.area == "") {
            return data
          } else if (data.location == customerArea.area && totalDays < 0) {
            return data
          }
          else if (customerArea.area === code) {
            return data
          }
        }
      })

      //pushing into arrays to get values in table
      let calculation = getAddress.filter((value) => {
        areaArr.push(value.location)
        dueamntArr.push(+value.dueamount)
        totCounArr.push(value.referenceno)
      })
      allArray = areaArr.map(function (data, i) {
        return { area: data, totaldueamount: dueamntArr[i], totalcount: totCounArr[i] };
      });

      const finalresult = [...allArray.reduce((r, o) => {
        const res = o.area;
        const items = r.get(res) || Object.assign({}, o, {
          totaldueamount: 0,
        });
        items.totaldueamount += o.totaldueamount;
        items.totalcount += o.totalcount + ","

        return r.set(res, items);
      }, new Map).values()];
      setPos(finalresult);

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = "none";
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: "", searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, "All"],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchCustomerAddress();
  }, []);

  const fileName = 'Customer over due abstract'
  // get particular columns for export excel
  const getexcelDatas = async () => {
    var data = pos.map(t => ({
      "Area": t.area, "Total Bills": t.totalcount.split(',').length - 1, "Total Due Amount": t.totaldueamount.toFixed(2),

    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  }, [pos])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | CUSTOMER OVER DUES ABSTRACT LIST',
    pageStyle: 'print'
  });

  //  PDF
  const downloadpdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#Customeroverdueabstractlist" });
    doc.save("Customeroverdueabstractlist.pdf");
  };

  return (
    <Box>
      <Headtitle title={"Customer Over Due Abstract"} />
      {/* ****** Header Content ****** */}
      <Typography sx={userStyle.HeaderText}>
        Customer Over Due Abstract
      </Typography>
      <Box sx={userStyle.filtercontent}>
        <Grid container sx={{ justifyContent: "center" }} spacing={1} >
          <Grid item lg={2} md={2}>
            <Typography sx={{ marginTop: "7px", float: "right" }}>
              Area
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sx={{ display: "flex" }}>
            <FormControl size="small" fullWidth >
              <Selects
                onChange={(e) => {
                  setCustomerArea({ ...customerArea, area: e.value });
                  getCateName(e);
                  setChecked(true)
                }}
                styles={colourStyles}
                options={customernames}
              />
            </FormControl>
          </Grid>
          <Grid item lg={2} md={2}>
            <FormGroup>
              <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onClick={(e) => { fetchSelectAll(e) }} checked={!checked} />} label="Select All" />
            </FormGroup>
          </Grid>
          <Grid item lg={3} md={3}>
            <Button
              onClick={fetchPos}
              variant="contained"
              color="secondary"
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      <br />
      {/* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container}>
          { /* ****** Header Buttons ****** */}
          <Grid container sx={{ justifyContent: "center" }} >
            <Grid >
              {isUserRoleCompare[0].csvcusoverduesabstract && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelcusoverduesabstract && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printcusoverduesabstract && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfcusoverduesabstract && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          {/* ****** Table start ****** */}
          <TableContainer component={Paper}>
            <Table
              aria-label="simple table"
              id="Customeroverdueabstractlist"
              ref={tableRef}
            >
              <TableHead sx={{ fontWeight: "600" }}>
                <StyledTableRow>
                  <StyledTableCell>Area</StyledTableCell>
                  <StyledTableCell>Total Bill Due</StyledTableCell>
                  <StyledTableCell>Total Due Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {pos.length > 0 &&
                  pos.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.area}</StyledTableCell>
                      <StyledTableCell align="left">{row.totalcount.split(',').length - 1} </StyledTableCell>
                      <StyledTableCell align="left">{row.totaldueamount.toFixed(2)}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* ****** Table End ****** */}
          { /* ****** Print ****** */}
          <Box sx={userStyle.printcls} ref={componentRef}>
            <Box>
              <Typography variant='h5' >Customer Over Due Abstract</Typography>
            </Box>
            <>
              <Box  >
                <TableContainer component={Paper} sx={userStyle.printcls}>
                  <Table aria-label="simple table" id="Customeroverdueabstractlist">
                    <TableHead sx={{ fontWeight: "600" }} >
                      <StyledTableRow >
                        <StyledTableCell>Area</StyledTableCell>
                        <StyledTableCell>Total Bill Due</StyledTableCell>
                        <StyledTableCell>Total Due Amount</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {pos.length > 0 && (
                        pos.map((row, index) => (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="left">{row.area}</StyledTableCell>
                            <StyledTableCell align="left">{row.totalcount.split(',').length - 1}</StyledTableCell>
                            <StyledTableCell align="left">{row.totaldueamount.toFixed(2)}</StyledTableCell>
                          </StyledTableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          </Box>
        </Box>
      </>
    </Box>
  );
}

function Customeroverdueabstract() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Customeroverdueabstractlist />
          <br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Customeroverdueabstract;