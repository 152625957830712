import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, InputLabel, OutlinedInput, Select, MenuItem, FormGroup, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button, } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import moment from 'moment';

function Balancewisecustomerduesreport() {

    const [pos, setPos] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const [checked, setChecked] = useState(true);
    const [code, setCode] = useState([]);
    const { auth } = useContext(AuthContext);

    // Datefield
    const [daysFilter, setDaysFilter] = useState({
        baloptions: "", balvalue: ""
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // Days total
    let totalDays = 0;

    //function 
    const fetchCBalanceDues = async (e) => {
        setChecked(s => !s)
        try {
            let response = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let req = response.data.pos1.filter((data) => {
                totalDays = (Number(data.creditdays)) - ((new Date().getDate() - new Date(data.date).getDate()) + 1)
                if (data.dueamount > 0 && totalDays > 0) {
                    if (checked === true) {
                        return data
                    }
                    else if (checked === false) {
                        setPos([])
                    }
                }
            })
            setPos(req)
            setDaysFilter({ baloptions: "", balvalue: "" })
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const getCateName = (e) => {
        if (e) {
            setPos([]) || setChecked(true)
        }
        setCode(e.baloptions)
    }

    //Customers
    const fetchCustomers = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.pos1.filter((data) => {
                if (data.dueamount > 0 && totalDays > 0) {
                    totalDays = (Number(data.creditdays)) - ((new Date().getDate() - new Date(data.date).getDate()) + 1)
                    if (daysFilter.dueoption == "" && totalDays > 0 && data.dueamount > 0) {
                        return data
                    }
                    else if (daysFilter.baloptions === "greaterthan" && totalDays > 0 && data.dueamount > 0) {
                        return data.dueamount >= daysFilter.balvalue
                    }
                    else if (daysFilter.baloptions === "lesserthan" && totalDays > 0 && data.dueamount > 0) {
                        return data.dueamount <= daysFilter.balvalue
                    }
                    else if (daysFilter.baloptions == code) {
                        return data
                    }
                }
            })
            setPos(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchCustomers();
        }, []
    )

    // Excel
    const fileName = 'Balance Wise Customer Dues Report'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = pos.map(t => ({
            "Name": t.customer,
            "Bill No": t.referenceno,
            "Due Date": moment(t.duedate).format('DD-MM-YYYY'),
            "Days Diff": (Number(t.creditdays)) - ((new Date().getDate() - 1 - new Date(t.duedate).getDate()) + 1),
            "Due Amount": t.dueamount
        }));
        setExceldata(data);
    }
    useEffect(
        () => {
            getexcelDatas();
        }, [pos]
    )

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | BALANCE WISE CUSTOMER DUES REPORT',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Balancewisecustomerduesreport' })
        doc.save('Balancewisecustomerduesreport.pdf')
    }

    return (
        <Box >
            <Headtitle title={'Balance Wise Customer Dues Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Balance Wise Customer Dues Report</Typography>
            <Box sx={userStyle.container}>
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={2} md={2}>
                        <Typography sx={{ marginTop: '7px', float: 'right' }}>Balance</Typography>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-helper-label">Please Select</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                label="Please Select"
                                id="demo-simple-select-helper"
                                value={daysFilter.baloptions}
                                onChange={(e) => {
                                    setDaysFilter({ ...daysFilter, baloptions: e.target.value })
                                    getCateName(e);
                                    setChecked(true)
                                }}
                            >
                                <MenuItem value="greaterthan"> &gt; </MenuItem>
                                <MenuItem value="lesserthan"> &lt; </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={3} md={2}>
                        <FormControl size="small" fullWidth >
                            <OutlinedInput
                                sx={userStyle.input}
                                placeholder="Enter Amount"
                                id="component-outlined"
                                value={daysFilter.balvalue}
                                onChange={(e) => { setDaysFilter({ ...daysFilter, balvalue: e.target.value }) }}
                                type='number'
                            />
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <FormGroup>
                            <FormControlLabel sx={{ marginLeft: "1em" }} control={<Checkbox onClick={(e) => { fetchCBalanceDues(e) }} checked={!checked} />} label="Select All" />
                        </FormGroup>
                    </Grid>
                    <Grid item lg={2} md={2}>
                        <Button onClick={fetchCustomers} variant='contained' color='secondary'>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br /><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvbalancewisecusduesreport && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelbalancewisecusduesreport && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printbalancewisecusduesreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfbalancewisecusduesreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Balancewisecustomerduesreport" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Bill No</StyledTableCell>
                                    <StyledTableCell>Due Date</StyledTableCell>
                                    <StyledTableCell>Days Diff </StyledTableCell>
                                    <StyledTableCell>Due Amount</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {pos.length > 0 && (
                                    pos.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell >
                                            <StyledTableCell align="left">{moment(row.duedate).format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell align="left">{(Number(row.creditdays)) - ((new Date().getDate() - 1 - new Date(row.duedate).getDate()) + 1)}</StyledTableCell>
                                            <StyledTableCell align="left">{row.dueamount}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                    { /* ****** Print ****** */}
                    <Box sx={userStyle.printcls} ref={componentRef}>
                        <Box>
                            <Typography variant='h5' >Balance Wise Customer Dues Report</Typography>
                        </Box>
                        <>
                            <Box  >
                                <TableContainer component={Paper} sx={userStyle.printcls}>
                                    <Table
                                        sx={{}}
                                        aria-label="simple table"
                                        id="Balancewisecustomerduesreport"
                                    >
                                        <TableHead sx={{ fontWeight: "600" }}>
                                            <StyledTableRow>
                                                <StyledTableCell>Name</StyledTableCell>
                                                <StyledTableCell>Bill No</StyledTableCell>
                                                <StyledTableCell>Due Date</StyledTableCell>
                                                <StyledTableCell>Days Diff </StyledTableCell>
                                                <StyledTableCell>Due Amount</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {pos.length > 0 &&
                                                pos.map((row, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.referenceno}</StyledTableCell >
                                                        <StyledTableCell align="left">{moment(row.duedate).format('DD-MM-YYYY')}</StyledTableCell>
                                                        <StyledTableCell align="left">{(Number(row.creditdays)) - ((new Date().getDate() - 1 - new Date(row.duedate).getDate()) + 1)}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.dueamount}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </>
                    </Box>
                </Box>
            </>
        </Box>
    );
}

function Balancewisecustomerdues() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Balancewisecustomerduesreport /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Balancewisecustomerdues;