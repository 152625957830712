import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Grid, FormControl, Select, MenuItem, TextField, Table, Paper, TableContainer, TableHead, TableBody, Box, OutlinedInput, TextareaAutosize, Dialog, DialogContent, DialogActions } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { useNavigate } from 'react-router-dom';
import { userStyle, colourStyles } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import Selects from "react-select";
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function Customerpaymentregisterlist() {

    const [pos, setPos] = useState({});
    const [bankMaster, setBankMaster] = useState([]);
    const [allCustomer, setAllCustomer] = useState([]);
    const [allBills, setAllBills] = useState([]);
    const [allBillsWithCustomer, setAllBillsWithCustomer] = useState([]);
    const [billDetail, setBillDetail] = useState([]);
    const [customerpayments, setCustomerpayments] = useState([]);
    const [tableResult, setTableResult] = useState([]);
    const { auth } = useContext(AuthContext);
    const [payDate, setPaydate] = useState(dayjs());

    const [customerPayment, setCustomerPayment] = useState({
        referenceno: "", date: "", name: "", billno: "", billbalance: "", amountpaid: "", totalamount: "",
        paymode: "Cash", accountno: "", remarks: "", billtotalitem: "", amountbalance: "", netpaidamount: ""
    });

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    // Pos
    const fetchPos = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPos(req.data.pos1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //  Amount paid
    const getAmountPaid = (e) => {
        var res = e.target.value;
        if (res > billDetail.dueamount) {
            setShowAlert('Please give balance amount ');
            handleClickOpen();
        }
        setCustomerPayment({ ...customerPayment, amountpaid: e.target.value, amountbalance: Number(billDetail.dueamount - e.target.value).toFixed(0) })
    }
    // Fetch all bills
    const fetchPosBills = async () => {
        try {
            let res = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAllBills(res.data.pos1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Customer names
    const fetchcustomername = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAllCustomer(
                req?.data?.customers?.map((d) => ({
                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Get all bill no for perticular customer
    const getCustomerBill = (e) => {
        let getcustomerallbill = allBills?.filter((item) => {
            if (e.cusid == item.contactid) {
                if (item.dueamount > 0) {
                    return item
                }
            }
        })

        setAllBillsWithCustomer(getcustomerallbill);
    }

    // Fetch customer accountno from bankmaster
    const fetchcustomeraccount = async () => {
        try {
            let req = await axios.get(SERVICE.BANKMASTER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBankMaster(
                req?.data?.bankmasters?.map((d) => ({
                    ...d,
                    label: d.accno,
                    value: d.accno,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Select particular customer bill to get bill detailes
    const searchBilldetail = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.POS_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBillDetail(res.data.spos);
            setCustomerPayment({...customerPayment, billno:res.data.spos.referenceno, dueamount: (res.data.spos.dueamount).toFixed(0), amountbalance: Number(res.data.spos.dueamount).toFixed(0)})
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Customerpayments
    const fetchCustomerpayments = async (e) => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomerpayments(req.data.customerpays);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const fetchCustPayment = async (e) => {
        try {
            let resTable = customerpayments?.filter((item) => {
                if (item.billno == e.referenceno) {

                    return item
                }
            })
            setTableResult(resTable)
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(() => {
        fetchPos();
        fetchcustomername();
        fetchcustomeraccount();
        fetchPosBills();
        fetchCustomerpayments();
    }, [])

    const backPage = useNavigate();

    const addCustomerPayment = async () => {


        // update due amount and customer paid amount in pos
        allBillsWithCustomer.forEach((data, i) => {
            if (customerPayment.billno == data.referenceno) {
                axios.put(`${SERVICE.POS_SINGLE}/${data._id}`, {
                    dueamount: Number(data.dueamount) - Number(customerPayment.amountpaid),
                    customerpaidamount: Number(data.customerpaidamount) + Number(customerPayment.amountpaid),
                });
            }
        })

        try {
            let req = await axios.post(SERVICE.CUSTOMER_PAYMENT_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                referenceno: String(customerPayment.referenceno),
                date: String(payDate),
                name: String(customerPayment.name),
                billno: String(customerPayment.billno),
                billbalance: Number(customerPayment.dueamount),
                amountpaid: Number(customerPayment.amountpaid),
                totalamount: Number(billDetail.aftergranddisctotal),
                paymode: String(customerPayment.paymode),
                accountno: String(customerPayment.accountno),
                remarks: String(customerPayment.remarks),
                discounts: Number(billDetail.gdiscountvalue),
                duedate:String(billDetail.duedate),
                billduedate:String(billDetail.date),
                billtotalitem: Number(allBillsWithCustomer.length),
                amountbalance: Number(customerPayment.amountbalance),
                netpaidamount: Number(Number(billDetail.customerpaidamount) + Number(customerPayment.amountpaid)),
            });
            setCustomerPayment(req.data)
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/contact/customer/customerpaymentreport')
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (customerPayment.referenceno == "") {
            setShowAlert('Please Enter Reference No!')
            handleClickOpen();
        } else if (customerPayment.name == "") {
            setShowAlert('Please Enter customer name!')
            handleClickOpen();
        }else if (customerPayment.billno == "") {
            setShowAlert('Please select bill number!')
            handleClickOpen();
        }else if (customerPayment.amountpaid == "" || customerPayment.amountpaid == 0) {
            setShowAlert('Please Enter pay amonu!')
            handleClickOpen();
        } else {
            addCustomerPayment();
        }
    }

    const handleBack = () => {
        backPage('/contact/customer/customerpaymentreport')
    }

    return (
        <Box>
            <Headtitle title={'Customer Payment Register'} />
            <Typography sx={userStyle.HeaderText}>Customer Payment Register</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: 'flex' }}>Reference No <Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={customerPayment.referenceno}
                                    onChange={(e) => { setCustomerPayment({ ...customerPayment, referenceno: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <Typography variant='h6'>Date &ensp;</Typography>
                        </Grid>
                        <Grid item md={6} sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        renderInput={(props) => <TextField size="small" {...props} />}
                                        value={payDate}
                                        onChange={(newValue) => {
                                            setPaydate(newValue);
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: 'flex' }}>Name <Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <Selects
                                        onChange={(e) => {
                                            getCustomerBill(e);
                                            setCustomerPayment({ ...customerPayment, name: e.value })
                                        }}
                                        styles={colourStyles}
                                        options={allCustomer}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Bill No<b style={{color:'red'}}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    placeholder='Select'
                                    value={customerPayment.billno}
                                    onChange={(e) => { setCustomerPayment({ ...customerPayment, billno: e.target.value }) }}
                                >
                                    {allBillsWithCustomer && (
                                        allBillsWithCustomer.map((row, index) => (
                                            <MenuItem value={row.referenceno} key={index} onClick={() => {
                                                searchBilldetail(row._id);
                                                fetchCustPayment(row)
                                            }}>{row.referenceno}</MenuItem>
                                        )))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Bill Balance</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={customerPayment.dueamount}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Amount Paid<b style={{color:'red'}}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        onChange={
                                            (e) => { getAmountPaid(e) }
                                        }
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Total Amount</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={billDetail.aftergranddisctotal}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Amount Balance</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        type="number"
                                        value={Math.abs(customerPayment.amountbalance)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Paymode</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Select
                                    labelId="demo-select-small"
                                    id="demo-select-small"
                                    value={customerPayment.paymode}
                                    onChange={(e) => { setCustomerPayment({ ...customerPayment, paymode: e.target.value }) }}
                                >
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="Card">Card</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>AC/No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    isClearable
                                    onChange={(e) => {
                                        setCustomerPayment({ ...customerPayment, accountno: e.value })
                                    }}
                                    styles={colourStyles}
                                    options={bankMaster}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Remarks</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                    value={customerPayment.remarks}
                                    onChange={(e) => { setCustomerPayment({ ...customerPayment, remarks: e.target.value }) }}
                                />
                            </FormControl><br />
                        </Grid>
                        <TableContainer component={Paper} >
                            <Table sx={{}} aria-label="simple table" id="" >
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Bill no </StyledTableCell>
                                        <StyledTableCell>Paid Amount</StyledTableCell>
                                        <StyledTableCell>Reference</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {tableResult.length > 0 && (
                                        tableResult.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.billno}</StyledTableCell>
                                                <StyledTableCell align="left">{Number(row.amountpaid)}</StyledTableCell>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell >
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Button sx={userStyle.buttoncancel} onClick={handleBack}>CANCEL</Button>
                                <Button sx={userStyle.buttonadd} type="submit" >SAVE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Customerpaymentregister() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Customerpaymentregisterlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Customerpaymentregister;