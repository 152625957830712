import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography, } from "@mui/material";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { userStyle } from "../PageStyle";
import Navbar from "../../components/header/Navbar";
import Footer from "../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../components/Table";
import { ExportXL, ExportCSV } from "../Export";
import Headtitle from "../../components/header/Headtitle";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import axios from "axios";
import { SERVICE } from "../../services/Baseservice";
import moment from "moment";
import $ from "jquery";
import { toast } from 'react-toastify';
import { useReactToPrint } from "react-to-print";
import { UserRoleAccessContext } from '../../context/Appcontext';
import { AuthContext } from '../../../src/context/Appcontext';
function Areawise() {
  const [areawiseestimate, setAreawiseEstimate] = useState([]);
  const [count, setCount] = useState([]);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);
  //jquery
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  //Excel data
  const [exceldata, setExceldata] = useState([]);
  let addressArr = [];
  let totArr = [];
  let arrMixed = [];
  let counts = [];
  let res = [];
  //estimate fetching
  const fetchEstimate = async () => {
    try {
      let request = await axios.get(SERVICE.ESTIMATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let estimatefetch = request.data.estimate.filter((data) => {
        let splittedMonth = data.date;
        let dateTrim = moment(splittedMonth).utc().format("YYYY-MM-DD");
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      });
      estimatefetch.map((item) => {
        addressArr.push(item.address);
        totArr.push(+item.grandtotal);
      });
      addressArr.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
      const splitKeyValue = (obj) => {
        const keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          res.push({
            billcount: obj[keys[i]],
          });
        }
        return res;
      };
      setCount(splitKeyValue(counts));
      arrMixed = addressArr.map(function (data, i) {
        return { address: data, totalbillamt: totArr[i], bills: res[i] };
      });
      const result = [
        ...arrMixed
          .reduce((r, o) => {
            const key = o.address;
            const items =
              r.get(key) ||
              Object.assign({}, o, {
                totalbillamt: 0,
              });
            items.totalbillamt += o.totalbillamt;
            return r.set(key, items);
          }, new Map())
          .values(),
      ];
      setAreawiseEstimate(result);
      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = "none";
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: "", searchPlaceholder: "Search..." },
            lengthMenu: [
              [10,1, 25, 50, 100, 200, 500, -1],
              [10,1, 25, 50, 100, 200, 500, "All"],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Areawise Estimate",
    pageStyle: "print",
  });
  // Export Excel
  const fileName = "Areawise Estimate";
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = areawiseestimate.map(t => ({
      Areaname: t.address, Bills: t.bills?.billcount, Totalbillamount: t.totalbillamt
    }));
    setExceldata(data);
  }
  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#usertable" });
    doc.save("Areawiseestimate.pdf");
  };
  useEffect(() => {
    getexcelDatas();
  }, [areawiseestimate]);
  return (
    <Box>
      <Headtitle title={'Areawise Estimate'} />
      <Grid item xs={12}>
        <Typography sx={userStyle.importheadtext}>
          Area Wise Estimate
        </Typography>
      </Grid>
      <Box sx={userStyle.container}>
        <Grid container
          sx={{ justifyContent: "center" }}
          spacing={1}
        >
          <Grid item lg={5} md={5}>
            <Grid container>
              <Grid item lg={3} md={3}>
                <Typography sx={{ marginLeft: "1.3em" }}> From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: "flex" }}>
                <FormControl size="small" fullWidth>
                  <TextField id="component-outlined" value={dateFilter.startdate}
                    onChange={(e) => {
                      setDateFilter({
                        ...dateFilter,
                        startdate: e.target.value,
                      });
                    }}
                    type="date" size="small" name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} md={5}>
            <Grid container >
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: "flex" }}>
                <FormControl size="small" fullWidth>
                  <TextField id="component-outlined" value={dateFilter.enddate}
                    onChange={(e) => {
                      setDateFilter({ ...dateFilter, enddate: e.target.value });
                    }}
                    type="date" size="small" name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant="contained" color="secondary" onClick={fetchEstimate}>
              Generate
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      <br />
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          {/* header text */}
          {/* content start */}
        </Grid>
        { /* ******************************************************EXPORT Buttons****************************************************** */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].excelareawiseestimate && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].csvareawiseestimate && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printareawiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfareawiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="usertable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Area name</StyledTableCell>
                  <StyledTableCell>Total Bills</StyledTableCell>
                  <StyledTableCell>Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {areawiseestimate &&
                  (areawiseestimate.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell component="th" scope="row"> {row.address} </StyledTableCell>
                        <StyledTableCell>{row?.bills?.billcount}</StyledTableCell>
                        <StyledTableCell>{row.totalbillamt}</StyledTableCell>
                      </StyledTableRow>
                    );
                  }))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* printlayout */}
      <Box sx={userStyle.printcls}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" id="usertable" ref={componentRef} >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Area name</StyledTableCell>
                <StyledTableCell>Total Bills</StyledTableCell>
                <StyledTableCell>Total Amount</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody align="left">
              {areawiseestimate &&
                (areawiseestimate.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row"> {row.address} </StyledTableCell>
                      <StyledTableCell>{row?.bills?.billcount}</StyledTableCell>
                      <StyledTableCell>{row.totalbillamt}</StyledTableCell>
                    </StyledTableRow>
                  );
                }))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
function Areawisesales() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Areawise /> <br /> <br /> <br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Areawisesales;