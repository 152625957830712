import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Grid, FormGroup, FormControlLabel, Checkbox, Paper, Typography, TableContainer, Table, TableHead, TableBody, FormControl, } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import $ from 'jquery';
import { userStyle, colourStyles } from '../PageStyle';
import axios from 'axios';
import Selects from 'react-select';
import jsPDF from "jspdf";
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { useReactToPrint } from "react-to-print";
import { UserRoleAccessContext } from '../../context/Appcontext';
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';

function Schedulewiselistall() {

  const [products, setProducts] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [getCode, setCode] = useState([]);
  const [checked, setChecked] = useState(true);
  const [selectFilter, setSelectFilter] = useState({ schedule: "" })
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // Categorys
  const fetchSchedule = async () => {
    try {
      let productlist = await axios.get(SERVICE.SCHEDULE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setSchedule(
        productlist?.data?.schedule?.map((d) => ({
          ...d,
          label: d.name,
          value: d.name,
        }))
      );
    } catch (err) {
      const mesasages = err.response.mesasage
      toast.error(mesasages)
    }
  };

  const getCateName = (e) => {
    if (e) {
      setProducts([]) || setChecked(true)
    } else {
      setCode(e.name)
    }
  }

  const fetchproductSelect = async (e) => {

    setChecked(s => !s)
    try {
      let response = await axios.get(SERVICE.PRODUCT,{
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      if (checked === true) {
        setProducts(response.data.products);

      } else if (checked === false) {
        setProducts([])
      }
    } catch (err) {
      const mesasages = err.response.mesasage
      toast.error(mesasages)
    }
  }

  const searchLoc = async () => {

    try {
      let product1 = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      let prodDataLocation = product1.data.products.filter((data) => {
        if (data.schedule == selectFilter.schedule) {
          return data
        }

      });
      setProducts(prodDataLocation);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Export Excel
  const fileName = 'Schedule Wise Item List';
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    var data = products.map(t => ({
      "Item Code": t.prodcode, "Item Name": t.prodname, "Molecule": t.molecules, "Category": t.category, "Schedule": t.schedule,
      "Manufacture": t.manufacture, "Location": t.businesslocation, "HSN Code": t.hsncode ? t.hsncode : t.applicabletax,
    }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | SCHEDULE WISE ITEM LIST',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    doc.autoTable({
      html: '#scheduletable',
      margin: { top: 10 },
    })
    doc.save('Schedule Wise Item List.pdf')
  }

  useEffect(() => {
    fetchSchedule();
    getexcelDatas();
  }, [products])

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  return (
    <Box >
      { /* ****** Header Content ****** */}
      <Headtitle title={'Schedule Wise Item List'} />
      <Typography variant='body2' sx={userStyle.HeaderText}>Schedule<Typography variant='body2' sx={userStyle.SubHeaderText}>Schedule Wise Item list</Typography></Typography>
      <Box sx={userStyle.filtercontent}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <FormControl size="small" fullWidth>
              <Selects
                onChange={(e)=>{
                  setSelectFilter({ ...selectFilter, schedule: e.name })
                  getCateName(e.name);
                  setChecked(true)
                }}
                placeholder={"Schedule"}
                styles={colourStyles}
                options={schedule}
              />
            </FormControl>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <FormGroup>
              <FormControlLabel control={<Checkbox onClick={(e) => { fetchproductSelect(e) }} checked={!checked} />} label="Select All" />
            </FormGroup>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Button variant='contained' color='secondary' onClick={searchLoc}>Generate&ensp;</Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      { /* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container} >
          { /* Header Content */}

          { /* Header Buttons */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvschedulelist && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelschedulelist && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printschedulelist && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfschedulelist && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          { /* Table Start */}
          <TableContainer component={Paper} >
            <Table ref={tableRef} id="tableRef">
              <TableHead>
                <StyledTableRow >
                  <StyledTableCell >Item Code</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Molecule</StyledTableCell>
                  <StyledTableCell >Category</StyledTableCell>
                  <StyledTableCell >Schedule</StyledTableCell>
                  <StyledTableCell >Manufacture</StyledTableCell>
                  <StyledTableCell >Location</StyledTableCell>
                  <StyledTableCell >HSN Code</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {products &&
                  products?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.prodcode}  </StyledTableCell>
                      <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                      <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                      <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                      <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                      <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                      <StyledTableCell align="left">{row.hsncode == "" ? row.applicabletax : row.hsncode}</StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
      { /* Table End */}

      {/* print layout */}
      <Box sx={userStyle.printcls}>
        <TableContainer component={Paper}  >
          <Table aria-label="simple table" id="scheduletable" ref={componentRef}>
            <TableHead>
              <StyledTableRow >
                <StyledTableCell >Item Code</StyledTableCell>
                <StyledTableCell >Item Name</StyledTableCell>
                <StyledTableCell >Molecule</StyledTableCell>
                <StyledTableCell >Category</StyledTableCell>
                <StyledTableCell >Schedule</StyledTableCell>
                <StyledTableCell >Manufacture</StyledTableCell>
                <StyledTableCell >Location</StyledTableCell>
                <StyledTableCell >HSN Code</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {products &&
                products?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{row.prodcode}  </StyledTableCell>
                    <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                    <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                    <StyledTableCell align="left">{row.category}</StyledTableCell>
                    <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                    <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                    <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                    <StyledTableCell align="left">{row.hsncode}</StyledTableCell>
                  </StyledTableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

function Schedulewiselist() {
  return (
    <>
      <Box >
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Schedulewiselistall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Schedulewiselist;