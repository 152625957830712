import React, { useState, useEffect, useRef, useContext } from 'react';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { Box, Grid, FormControl, Typography, TextField, Table, TableBody, TableContainer, TableHead, Paper, Button } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import $ from 'jquery';
import { userStyle } from '../PageStyle';
import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { AuthContext } from '../../context/Appcontext';

function Itemwisepurchasereturnall() {

  const [itemwisepurchasesreturn, setItemwisepurchasesreturn] = useState([]);

  let quantityArr = []
  let arr = [];
  let numArr = [];
  let arrMixed = [];
  let arrSku = [];

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  // Export Excel
  const [exceldata, setExceldata] = useState([]);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  //  Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //  Fetch Purchases
  const fetchPurchases = async () => {
    try {
      let res_purchase = await axios.get(SERVICE.PURCHASE_RETURN, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let getDatawithFilter = res_purchase.data.purchasesrtn.filter((data) => {
        let splittedMonth = data.purchasedate
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })
      getDatawithFilter.map(item => {
        item.products.map(iterator => {
          quantityArr.push(+iterator.quantity)
          arr.push(iterator.prodname)
          numArr.push(+iterator.ratebydismod)
          arrSku.push(iterator.sku)
        })
      })
      arrMixed = arr.map(function (data, i) {
        return { itemname: data, itemcode: arrSku[i], totalbillamt: numArr[i], totlquantity: quantityArr[i] };
      });
      const result = [...arrMixed.reduce((r, o) => {
        const key = o.itemcode
        const items = r.get(key) || Object.assign({}, o, {
          totalbillamt: 0,
          totlquantity: 0
        });
        items.totalbillamt += o.totalbillamt
        items.totlquantity += o.totlquantity

        return r.set(key, items);
      }, new Map).values()];

      setItemwisepurchasesreturn(result)
      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.err(messages);
    }
  }

  // Export Excel
  const fileName = 'Item Wise Purchase Return Report';

  const getexcelDatas = async () => {
    let data = itemwisepurchasesreturn.map(t => ({
      'Item Code': t.itemcode, 'Item Name': t.itemname, 'Total Quantity': t.totlquantity, 'Total Amount': t.totalbillamt
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()
  }, [itemwisepurchasesreturn])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | ITEM WISE PURCHASE RETURN REPORT',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#purchasetable' });
    doc.save('Item Wise Purchase Return Report.pdf')
  }

  return (

    <Box >
      <Headtitle title={'Item Wise Purchase Return Report'} />
      { /* ************************************************** Header Content ****************************************************** */}
      <Typography sx={userStyle.HeaderText}>Item Wise Purchase Return</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography variant="body2" sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography variant="body2" sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchPurchases}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />

      { /* ******************************************************EXPORT Buttons****************************************************** */}

      <>
        <Box sx={userStyle.container} >
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvitemwisepurchasereport && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelitemwisepurchasereport && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printitemwisepurchasereport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfitemwisepurchasereport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />

          { /*  *******************************************Table Start  *******************************************p*/}
          <Box>
            <TableContainer component={Paper} >
              <Table id="purchasetable" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Item Code</StyledTableCell>
                    <StyledTableCell >Item Name</StyledTableCell>
                    <StyledTableCell >Total Quantity</StyledTableCell>
                    <StyledTableCell>Total Amount</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {itemwisepurchasesreturn &&
                    (itemwisepurchasesreturn.map((row, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell >{row.itemcode}</StyledTableCell>
                        <StyledTableCell >{row.itemname}</StyledTableCell>
                        <StyledTableCell >{row.totlquantity}</StyledTableCell>
                        <StyledTableCell >{row.totalbillamt}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </>
      {/* *******************************************print layout **********************************************/}

      <>
        <Box sx={userStyle.printcls} >
          <TableContainer component={Paper} >
            <Table id="usertable" ref={componentRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Item Code</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Total Quantity</StyledTableCell>
                  <StyledTableCell>Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {itemwisepurchasesreturn.map((row, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell >{row.itemcode}</StyledTableCell>
                      <StyledTableCell >{row.itemname}</StyledTableCell>
                      <StyledTableCell >{row.totlquantity}</StyledTableCell>
                      <StyledTableCell >{row.totalbillamt}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>
    </Box>
  );

}
function Itemwisepurchasereturn() {
  return (
    <>
      <Box>
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Itemwisepurchasereturnall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Itemwisepurchasereturn;