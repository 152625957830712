import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Grid, FormGroup, FormControlLabel, Checkbox, Paper, TableRow, Typography, TableContainer, Table, TableHead, TableBody, FormControl} from '@mui/material';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import $ from 'jquery';
import { userStyle, colourStyles } from '../PageStyle';
import axios from 'axios';
import Selects from 'react-select';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { useReactToPrint } from "react-to-print";
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { AuthContext } from '../../context/Appcontext';

function Moleculewiselistall() {

  const [products, setProducts] = useState([]);
  const [molecules, setMolecules] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const [selectFilter, setSelectFilter] = useState({ molecules: "" })
  const [newdata, setNewdata] = useState([]);
  const [checked, setChecked] = useState(true);
  const { auth } = useContext(AuthContext);

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // get all products
  const fetchProduct = async () => {
    try {
      let res_product = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let mole_data = res_product?.data?.products?.map((d) => {
        return d.molecules
      })
      setNewdata(mole_data);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  let newarray = [...new Set(newdata)];

  // Categorys
  const fetchCategory = async () => {
    try {
      setMolecules(
        newarray.map((d) => (
          {
            ...d,
            label: d,
            value: d,
          }
        ))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const getCateName = (e) => {
    if (e) {
      setProducts([]) || setChecked(true)
    }
  }

  const fetchproductSelect = async (e) => {

    setChecked(s => !s)
    try {
      let response = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      if (checked === true) {
        setProducts(response.data.products);

      } else if (checked === false) {
        setProducts([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  const searchLoc = async () => {
    try {
      let product1 = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let prodDataLocation = product1.data.products.filter((data) => {

        if (data.molecules == selectFilter.molecules) {
          return data
        }
      });
      setProducts(prodDataLocation);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [])

  // Export Excel
  const fileName = 'Molecule Wise Item List';

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    var data = products.map(t => ({
      "Item Code": t.prodcode, "Item Name": t.prodname, "Molecule": t.molecules, "Category": t.category, "Schedule": t.schedule,
      "Manufacture": t.manufacture, "Location": t.businesslocation, "HSN Code": t.hsncode ? t.hsncode : t.applicabletax,
    }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | MOLECULES WISE ITEM LIST',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    doc.autoTable({
      html: '#moleculewiselist',
      margin: { top: 10 },
    })
    doc.save('Molecule Wise Item List.pdf')
  }

  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  useEffect(() => {
    fetchCategory();
    getexcelDatas();
  }, [molecules])

  return (
    <Box >
      <Headtitle title={'Molecule Wise Item List'} />
      { /* ****** Header Content ****** */}
      <Typography variant='body2' sx={userStyle.HeaderText}>Molecule<Typography variant='body2' sx={userStyle.SubHeaderText}>Moleculewise Item list</Typography></Typography>
      <Box sx={userStyle.filtercontent}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl fullWidth size="small">
              <FormControl size="small" fullWidth>
                <Selects
                  onChange={(e)=>{
                    setSelectFilter({ ...selectFilter, molecules: e.value })
                    getCateName(e.value);
                    setChecked(true)
                  }}
                  options={molecules}
                  placeholder={"Molecules"}
                  styles={colourStyles}
                />
              </FormControl>
            </FormControl>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <FormGroup>
              <FormControlLabel control={<Checkbox onClick={(e) => { fetchproductSelect(e) }} checked={!checked} />} label="Select All" />
            </FormGroup>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Button sx={userStyle.buttonadd} onClick={(e) => { searchLoc() }}>Generate&ensp;</Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      { /* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container} >
          { /* Header Content */}
          { /* Header Buttons */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvmoleslist && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelmolecule && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printsmolelist && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfmolecule && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          { /* Table Start */}
          <TableContainer component={Paper} >
            <Table ref={tableRef} id="tableRef">
              <TableHead>
                <StyledTableRow >
                  <StyledTableCell >Item Code</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Molecule</StyledTableCell>
                  <StyledTableCell >Category</StyledTableCell>
                  <StyledTableCell >Schedule</StyledTableCell>
                  <StyledTableCell >Manufacture</StyledTableCell>
                  <StyledTableCell >Location</StyledTableCell>
                  <StyledTableCell >HSN Code</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {products &&
                  products?.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell align="left">{row.prodcode}  </StyledTableCell>
                      <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                      <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                      <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                      <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                      <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                      <StyledTableCell align="left">{row.hsncode ? row.hsncode : row.applicabletax}</StyledTableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          { /* Table End */}
        </Box>
      </>

      {/* print layout */}
      <Box sx={userStyle.printcls} >
        <TableContainer component={Paper} >
          <Table sx={{ minWidth: 700 }} aria-label="customized table" id="moleculewiselist" ref={componentRef}>
            <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
              <StyledTableRow >
                <StyledTableCell >Item Code</StyledTableCell>
                <StyledTableCell >Item Name</StyledTableCell>
                <StyledTableCell >Molecule</StyledTableCell>
                <StyledTableCell >Category</StyledTableCell>
                <StyledTableCell >Schedule</StyledTableCell>
                <StyledTableCell >Manufacture</StyledTableCell>
                <StyledTableCell >Location</StyledTableCell>
                <StyledTableCell >HSN Code</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {products && (
                products.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{row.prodcode}  </StyledTableCell>
                    <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                    <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                    <StyledTableCell align="left">{row.category}</StyledTableCell>
                    <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                    <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                    <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                    <StyledTableCell align="left">{row.hsncode ? row.hsncode : row.applicabletax}</StyledTableCell>
                  </StyledTableRow>
                ))
              )
                

              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

function Moleculewiselist() {
  return (
    <>
      <Box >
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Moleculewiselistall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Moleculewiselist;