import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogContent, DialogActions, Typography, Grid, InputLabel, FormControl, FormControlLabel, Checkbox, Box, OutlinedInput, TextareaAutosize, } from '@mui/material';
import Navbar from '../../../../components/header/Navbar';
import Footer from '../../../../components/footer/Footer';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailIconOutlined from '@mui/icons-material/EmailOutlined';
import LocationOnIconOutlined from '@mui/icons-material/LocationOnOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { userStyle } from '../../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';
import { AuthContext } from '../../../../context/Appcontext';
import{ SERVICE } from '../../../../services/Baseservice';

function Suppliereditlist() {

    const { auth } = useContext(AuthContext);
    const [suppliers, setSuppliers] = useState({});
    const [purchases, setPurchases] = useState({});

    const [supplier, setSupplier] = useState({
        autogenerate: "", suppliername: "", addressone: "", addresstwo: "", country: "", state: "",
        city: "", pincode: "", email: "", gstn: "", phoneone: "", phonetwo: "", phonethree: "", phonecheck: false,
        phonefour: "", landline: "", whatsapp: "", contactperson: "", creditdays: "", dlnos: "",
    });


    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => {
        setIsErrorOpen(true);
    };
    const handleClose = () => {
        setIsErrorOpen(false);
    };


    // Suppliers
    const fetchSuppliers = async () => {
        try {
            let req = await axios.get(SERVICE.SUPPLIER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setSuppliers(req.data.suppliers);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Purchases
    const fetchPurchases = async () => {
        try {
            let req = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPurchases(req.data.purchases);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchSuppliers();
        fetchPurchases();
    }, [])

     // Phone number length
     const handlePincode = (e) => {
        if(e.length > 6){
            setShowAlert("Pincode can't have more than 6 characters!")
            handleClickOpen();
           let num = e.slice(0, 6);
           setSupplier({...supplier, pincode: num})
        }
    }
    const handlePhoneone = (e) => {
        if(e.length > 10){
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
           let num = e.slice(0, 10);
           setSupplier({...supplier, phoneone: num})
        }
    }
    const handlePhonetwo = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setSupplier({...supplier, phonetwo: num})
        }
    }
    const handlePhonethree = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setSupplier({...supplier, phonethree: num})
        }
    }
    const handlePhonefour = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setSupplier({...supplier, phonefour: num})
        }
    }
    const handleWhatsapp = (e) => {
        if (e.length > 10) {
            setShowAlert("Whatsapp number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setSupplier({...supplier, whatsapp: num})
        }
    }
    const handleGstn = (e) => {
        if (e.length > 15) {
            setShowAlert("GSTN number can't more than 15 characters!")
            handleClickOpen();
            let num = e.slice(0, 15);
            setSupplier({...supplier, gstn: num})
        }
    }

    const handleValidationName = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, suppliername: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, suppliername: value })
        }
    }

    const handleValidationCountry = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+.\-=\[\]{};':"\\|,<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, country: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, country: value })
        }
    }

    const handleValidationState = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+.\-=\[\]{};':"\\|,<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, state: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, state: value })
        }
    }

    const handleValidationCity = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]')
        var regExSpecialChar = /[`₹!@#$%^&*()_+.\-=\[\]{};':"\\|,<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, city: value })
        }
        else if (regExSpecialChar.test(e.target.value)) {
            setShowAlert("Please enter characters only! (A-Z or a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setSupplier({ ...supplier, city: value })
        }
    }
    const id = useParams().id

    // Fetch supplier by id
    const fetchHandler = async () => {
        try {
            let response = await axios.get(`${SERVICE.SUPPLIER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setSupplier(response.data.ssupplier);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(() => {
        fetchHandler()
    }, [id]);

    const bactToPage = useNavigate();

    const updateSupplier = async () => {
        // Update supplier details in purchase
        {
            purchases.map((item) => {
                    if (item.supplierid == suppliers.autogenerate) {
                        axios.put(`${SERVICE.PURCHASE_SINGLE}/${item._id}`, {
                            supplierid: String(supplier.autogenerate),
                            supplier: String(supplier.suppliername),
                            supaddressone: String(supplier.addressone),
                            addresstwo: String(supplier.addresstwo),
                            country: String(supplier.country),
                            state: String(supplier.state),
                            city: String(supplier.city),
                            pincode: Number(supplier.pincode),
                            email: String(supplier.email),
                            gstn: String(supplier.gstn),
                            phoneone: Number(supplier.phoneone),
                            phonetwo: Number(supplier.phonetwo),
                            phonethree: Number(supplier.phonethree),
                            phonefour: Number(supplier.phonefour),
                            landline: String(supplier.landline),
                            whatsapp: Number(supplier.whatsapp),
                            phonecheck: Boolean(supplier.phonecheck),
                            contactperson: String(supplier.contactperson),
                            creditdays: Number(supplier.creditdays),
                            dlnos: Number(supplier.dlnos),
                        });
                    }
                })
            
        }

        try {
            let req = await axios.put(`${SERVICE.SUPPLIER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                autogenerate: String(supplier.autogenerate),
                suppliername: String(supplier.suppliername),
                addressone: String(supplier.addressone),
                addresstwo: String(supplier.addresstwo),
                country: String(supplier.country),
                state: String(supplier.state),
                city: String(supplier.city),
                pincode: Number(supplier.pincode),
                email: String(supplier.email),
                gstn: String(supplier.gstn),
                phoneone: Number(supplier.phoneone),
                phonetwo: Number(supplier.phonetwo),
                phonethree: Number(supplier.phonethree),
                phonefour: Number(supplier.phonefour),
                landline: String(supplier.landline),
                whatsapp: Number(supplier.whatsapp),
                contactperson: String(supplier.contactperson),
                creditdays: Number(supplier.creditdays),
                dlnos: Number(supplier.dlnos),
            });
            setSupplier(req.data)
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            bactToPage('/contact/supplier/list');
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    const handleEditSubmit = (e) => {
        e.preventDefault();
        if(supplier.suppliername == ""){
            setShowAlert("Please enter supplier name!")
            handleClickOpen();
        }
        else if(supplier.phoneone == ""){
            setShowAlert("Please enter Phone No!")
            handleClickOpen();
        }
        else{
            updateSupplier();
        }
    }

    return (
        <Box>
            <Headtitle title={'Supplier Edit'} />
            <Typography sx={userStyle.HeaderText}>Edit Supplier</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleEditSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ justifyContent: 'center' }}>Supplier Details</Typography>
                        </Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Fields</Typography>
                        </Grid>
                        <Grid item md={8} sm={6} xs={12}></Grid>

                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>1.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Supplier Code</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <InputLabel htmlFor="component-outlined">Auto Generate</InputLabel>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><ContactPageOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={supplier.autogenerate}
                                        type="text"
                                        name="autogenerate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>2.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6' sx={{ display: "flex" }}>Supplier Name <b style={{ color: "red", marginLeft: "2px" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                <OutlinedInput
                                        id="component-outlined"
                                        value={supplier.suppliername}
                                        onChange={(e) => {setSupplier({ ...supplier, suppliername: e.target.value }); handleValidationName(e)}}
                                        type="text"
                                        name="suppliername"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>3.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Address1</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                    value={supplier.addressone}
                                    onChange={(e) => { setSupplier({ ...supplier, addressone: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>4.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Address2</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                    value={supplier.addresstwo}
                                    onChange={(e) => { setSupplier({ ...supplier, addresstwo: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>5.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Country</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LanguageOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                {/* <Select
                                        options={Country.getAllCountries()}
                                        getOptionLabel={(options) => {
                                            return options["name"];
                                        }}
                                        getOptionValue={(options) => {
                                            return options["name"];
                                        }}
                                        value={selectedCountry}
                                        styles={colourStyles}
                                        onChange={(item) => {
                                            setSelectedCountry(item);
                                        }}
                                    /> */}
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={supplier.country}
                                        onChange={(e) => { setSupplier({ ...supplier, country: e.target.value }); handleValidationCountry(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>6.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>State</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                {/* <Select
                                        options={State?.getStatesOfCountry(selectedCountry?.isoCode)}
                                        getOptionLabel={(options) => {
                                            return options["name"];
                                        }}
                                        getOptionValue={(options) => {
                                            return options["name"];
                                        }}
                                        value={selectedState}
                                        styles={colourStyles}
                                        onChange={(item) => {
                                            setSelectedState(item);
                                        }}
                                    /> */}
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={supplier.state}
                                        onChange={(e) => { setSupplier({ ...supplier, state: e.target.value }); handleValidationState(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>7.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>City</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                {/* <Select
                                        options={City.getCitiesOfState(
                                            selectedState?.countryCode,
                                            selectedState?.isoCode
                                        )}
                                        getOptionLabel={(options) => {
                                            return options["name"];
                                        }}
                                        getOptionValue={(options) => {
                                            return options["name"];
                                        }}
                                        value={selectedCity}
                                        styles={colourStyles}
                                        onChange={(item) => {
                                            setSelectedCity(item);
                                        }}
                                    /> */}
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={supplier.city}
                                        onChange={(e) => { setSupplier({ ...supplier, city: e.target.value }); handleValidationCity(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>8.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Pincode</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={supplier.pincode}
                                        onChange={(e) => { setSupplier({ ...supplier, pincode: e.target.value }); handlePincode(e.target.value) }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>9.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Email</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><EmailIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={supplier.email}
                                        onChange={(e) => { setSupplier({ ...supplier, email: e.target.value }) }}
                                        type="email"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>10.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>GSTN</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.gstn}
                                    onChange={(e) => { setSupplier({ ...supplier, gstn: e.target.value }); handleGstn(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>11.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6' sx={{ display: "flex" }}>Phone<b style={{ color: "red", marginLeft: "2px" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item md={6} sm={6} xs={12}>
                                    <InputLabel htmlFor="component-outlined">Phone1</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={supplier.phoneone}
                                            onChange={(e) => { setSupplier({ ...supplier, phoneone: e.target.value }); handlePhoneone(e.target.value) }}
                                            type="number"
                                        />
                                    </FormControl>
                                    {/* <Grid> <FormControlLabel control={<Checkbox />}  value={supplier.phonecheck}
                                    onChange={(e) => { setSupplier({ ...supplier, phonecheck: !supplier.phonecheck }) }} label="Same as whatsapp no" /></Grid> */}
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <InputLabel htmlFor="component-outlined">Phone2</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={supplier.phonetwo}
                                            onChange={(e) => { setSupplier({ ...supplier, phonetwo: e.target.value }); handlePhonetwo(e.target.value) }}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <InputLabel htmlFor="component-outlined">Phone3</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={supplier.phonethree}
                                            onChange={(e) => { setSupplier({ ...supplier, phonethree: e.target.value }); handlePhonethree(e.target.value) }}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <InputLabel htmlFor="component-outlined">Phone4</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <OutlinedInput
                                            sx={userStyle.input}
                                            id="component-outlined"
                                            value={supplier.phonefour}
                                            onChange={(e) => { setSupplier({ ...supplier, phonefour: e.target.value }); handlePhonefour(e.target.value) }}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>12.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Landline</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.landline}
                                    onChange={(e) => { setSupplier({ ...supplier, landline: e.target.value }); }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>13.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                        <Typography variant='h6' sx={{ display: "flex" }}>WhatsApp<b style={{ color: "red", marginLeft: "2px" }}>*</b></Typography>

                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={supplier.whatsapp}
                                    onChange={(e) => { setSupplier({ ...supplier, whatsapp: e.target.value }); handleWhatsapp(e.target.value) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>14.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Contact Person</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={supplier.contactperson}
                                    onChange={(e) => { setSupplier({ ...supplier, contactperson: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>15.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Credit Days</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={supplier.creditdays}
                                    onChange={(e) => { setSupplier({ ...supplier, creditdays: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>16.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>DL NOS</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={supplier.dlnos}
                                    onChange={(e) => { setSupplier({ ...supplier, dlnos: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Link to="/contact/supplier/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                                <Button sx={userStyle.buttonadd} type="submit">UPDATE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Supplieredit() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Suppliereditlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Supplieredit;