import React, { useEffect, useState, useContext } from 'react';
import { userStyle, colourStyles } from '../../PageStyle';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, TextField, Select, MenuItem, Paper, TableCell, Dialog,  DialogContent, DialogActions, TextareaAutosize, Typography, Button, Table, TableContainer, TableHead, TableBody, } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FaSearch } from "react-icons/fa";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { AiOutlineClose } from "react-icons/ai";
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Selects from "react-select";
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment'
import {SERVICE} from '../../../services/Baseservice'
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { AuthContext } from '../../../context/Appcontext';

const Purchasecreatelist = () => {

    const [purchases, setPurchases] = useState();
    const [supplier, setSupplier] = useState({});
    const [suppliers, setSuppliers] = useState([]);
    const [busilocations, setBusilocations] = useState([]);
    const [taxrates, setTaxrates] = useState();
    const [productsList, setProductsList] = useState([]);
    const [alpha, setAlpha] = useState("");
    const [isSupplierFetch] = useState();
    const [setngs, setSetngs] = useState();
    const [allTotalItems, setAlltotalItems] = useState([]);
    const [arraydata, getarraydata] = useState([]);
    const [getTaxValue , setTaxvalue] = useState("");
    const totalItem = { quantity: 0, freeitem: 0 };
    const { auth } = useContext(AuthContext);
    //supplier credit days
    const [duedate, setduedate] = useState("")
    //role access
    const {isUserRoleAccess} = useContext(UserRoleAccessContext);
    // purchase date
    const [purchaseDateTime, setPurchaseDateTime] = useState(dayjs());
    // paid date
    const [paidDateTime, setPaidDateTime] = useState(dayjs());
    let purchaseStatusPending = "Pending";
    let purchaseStatusReceived = "Received";
    let balanceamt=0;
    // produts list for add purchase data into db
    const productInputs = {
        prodname: "", sku: "", hsn: "", hsntax: 0, applicabletax:"",batch:"", expirydate:"", packingcharge:"", applicabletaxrate:"",purchasetabletax: "Exclusive", purchasetax:"", sellingpricetax: "", enteramt:"", margin: "", purchaseexcludetax: "",
        pruchaseincludetax:0, excalpha: "", incalpha: "", quantity: "", freeitem: "", purchasenetcost:0, distypemod: "None", disvaluemod: "", ratebydismod: "",subtax:"",invoicenumber:"",
        differenceamt: "", sellingpriceunitcost: "",
    }
    const [allProductsList, setAllProductsList] = useState([]);
    // purchase list og data only in add purchase
    const [purchaseAdd, setPurchaseAdd] = useState({
        supplier: "", referenceno: "", billamount:0, purchasedate: "", purchasestatus: "Pending", addressone: "",
        supplierid: "", supaddressone: "", addresstwo: "", city: "", cgst:0, gstn: 0, whatsapp: 0, contactperson: "", creditdays: 0, dlnos: "",
        balance:0, businesslocation: "", invoiceno:"",attachdoc: "", nettotal: 0, totalitem: 0,batch: "",packingcharge:0,expirydate:"",
        purchasetax: "", additionalnotes:"",paydue: 0.00,payamount:0.0, advancebalance:0, paidon:"", paymentmethod:"None",gdiscountvalue:"",
        discountamt:0,granddistype:"None",
    });

    // auto id for purchase number
    let newval = setngs ? setngs.purchasesku + "0001": "PU0001";

    // Error Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => {
        setIsErrorOpen(true);
    };
    const handleClose = () => {
        setIsErrorOpen(false);
    };
    
    let getTaxrateData = [];

    const gethsncode = async (e) => {
      
        let taxlist =await axios.get(SERVICE.TAXRATE,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
        let taxRateData = taxlist.data.taxrates.filter((data) => {
            if(data.taxname == e.applicabletax || e.hsncode) {
                    return data
                }
            });
            setTaxvalue(taxRateData)
            setTaxrates(taxRateData);
            //
            let isAlreadyAdded = false;
            let addedproductId =  allProductsList.map((item)=>{
                if(e.prodcode == item.sku){
                    isAlreadyAdded = true
                    setShowAlert("This product is already added!")
                    handleClickOpen();
                    return { ...item }
                }else {
                    return item
                    
                  }
            })

            if(isAlreadyAdded){
                setAllProductsList(addedproductId)
            }else{
            setAllProductsList((productslistdata) => {
                return [{ ...productInputs, batch:"", expirydate:"", packingcharge:"", supplier: purchaseAdd.supplier, date: purchaseDateTime, margin: setngs?.dprofitpercent,purchasetax:e.applicabletax, subtax:getTaxrateData, invoicenumber: purchaseAdd.invoiceno,
                        applicabletax: e.applicabletax, applicabletaxrate: e.applicabletax == "" ? 0 : taxRateData ? taxRateData[0].taxtotal : 0,category:e.category,molecule:e.molecules,schedule:e.schedule, manufacture:e.manufacture,hsntax: e.hsncode == "" ? 0 : taxRateData ? taxRateData[0].taxtotal : 0,
                        prodname: e.prodname, sku: e.prodcode, hsn: e.hsncode, sellingpricetax:e.selltaxtype }, ...productslistdata]
                });
            }
    }

    const fetchPurchases = async () => {
        try {
            let purchase = await axios.get(SERVICE.PURCHASE,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    }
            });
            setPurchases(purchase.data.purchases)
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Get supplier address Addressone
    const searchAdd = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.SUPPLIER_SINGLE}/${id}`,{              
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            setSupplier(res.data.ssupplier);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    
    let fiveDaysInTheFuture;
    let Duesdate;
    let Duedate;

    const getDueDate = (suppliercreditdays) => {
        fiveDaysInTheFuture = new Date(new Date().setDate(new Date(purchaseDateTime).getDate() + suppliercreditdays)) 
        Duesdate = moment(fiveDaysInTheFuture).utc().format('YYYY-MM-DD');
         Duedate = Duesdate.toString();
         if(suppliercreditdays == 0){
            setduedate ("")
         }else{
            setduedate(Duedate)
         }
    }
  
    // Suppliers
    const fetchSuppliers = async () => {
        try {
            let req = await axios.get(SERVICE.SUPPLIER,{              
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            setSuppliers(req?.data?.suppliers?.map((d) => ({
                ...d,
                label: d.suppliername,
                value: d.suppliername,
            })))
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Business Locations
    const fetchBusilocations = async () => {
   
        try {
            let productlist = await axios.get(SERVICE.BUSINESS_LOCATION,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            
            setBusilocations(
                productlist?.data?.busilocations?.map((d) => ({
                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Products
    const fetchProducts = async () => {
        try {
            let productlist = await axios.get(SERVICE.PRODUCT,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    }
                });
                setProductsList(
                    productlist?.data?.products?.map((d) => ({
                        ...d,
                        label: d.prodname,
                        value: d.prodname,
                    }))
                );
            } catch (err) {
                const messages = err.response.data.message;
                toast.error(messages);
            }
        };

    // get settings data
    const fetchSettings = async () => {
        try {
            var response = await axios.get(SERVICE.UPDATE_SETTINGS,{
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    }
            });
            setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Taxrates
    const fetchRates = async () => {
        try {
            let taxlist = await axios.get(SERVICE.TAXRATE,{
            headers: {
                'Authorization':`Bearer ${auth.APIToken}`
                }
            });
            setTaxrates(taxlist.data.taxrates);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    let cgst = 0;
    let gst = 0;
    let igst = 0;
    let CGST = 0;
    let GST = 0;
    let IGST = 0;

    allProductsList?.map((item) => {
           item?.subtax?.filter((data) =>{
            cgst += Number(data?.taxratecgst);
            gst += Number(data?.taxrategst);
            igst += Number(data?.taxrateigst);
           })
    })
  
        CGST = Number(totalNetCostCalcinclusive())*(cgst ? (cgst/100 ): 0).toFixed(2);
        GST  = Number(totalNetCostCalcinclusive())*(gst ? (gst/100) : 0).toFixed(2);
        IGST = Number(totalNetCostCalcinclusive())*(igst ?( igst/100 ): 0).toFixed(2);
      
    // Upload File 
    const handleFileChange = (e) => {
        let attachdoc = document.getElementById("attachdoc")
        var path = (window.URL || window.webkitURL).createObjectURL(attachdoc.files[0]);
        document.getElementById('attachdocdetail').innerText = ""
        document.getElementById('attachdocdetail').innerText = attachdoc.value.replace(/^.*[\\\/]/, '')
        toDataURL(path, function (dataUrl) {
            attachdoc.setAttribute('value', String(dataUrl));
            setPurchaseAdd({ ...purchaseAdd, attachdoc: String(dataUrl) })
            return dataUrl;
        })
    }

    function toDataURL(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    // alpha
    const fetchAlpha = async () => {
        let alpharate = await axios.get(SERVICE.ALPHARATE,{
            headers: {
            'Authorization':`Bearer ${auth.APIToken}`
            }
        });
        let getactivate = alpharate.data.alpharates.filter((data) => {
            return data.activate == true
        })
        setAlpha(getactivate);
    }

    // alpharate for exclude tax
    let gotalpha = "";
    const getAlphaRate = (inputValue) => {
        let getalpha = alpha.slice(0).filter((data) => {
            var num = inputValue;
            var digits = num.toString().split('');
            var realDigits = digits.map((item) => {
                if (item == '.') {
                    gotalpha += '.'
                }
                else {
                    gotalpha += data[item]
                }
            });
        });
        return gotalpha
    }

    //alpharate include tax
    const getAlphaRateInc = (taxValue) => {
        let alphaValue = ""
        let getalpha = alpha.slice(0).filter((data) => {
            var num = taxValue;
            var digits = num.toString().split('');
            var realDigits = digits.map((item) => {
                if (item == '.') {
                    alphaValue += '.'
                }
                else {
                    alphaValue += data[item]
                }
            })
        });
        return alphaValue;
    }


    useEffect(() => {
        fetchRates();
        fetchPurchases();
        fetchProducts();
        fetchSuppliers();
        fetchBusilocations();
        fetchSettings();
        fetchAlpha();
        totalNetCostCalc();
    }, [isSupplierFetch]);

    const backPage = useNavigate();

    // compare bill amount net total then only purchase status changed and update currentstock
    const purchaseStatusInput = (value) =>{
        if(value == "Received"){
            if(purchaseAdd.billamount == "" || purchaseAdd.billamount == 0){
                setPurchaseAdd({
                    ...purchaseAdd, purchasestatus: "Pending"
                });
                purchaseStatusReceived = "Pending";
                setShowAlert("Please enter bill amount");
                handleClickOpen();
            }else if(allProductsList.length == 0){
                setPurchaseAdd({
                    ...purchaseAdd, purchasestatus: "Pending"
                });
                purchaseStatusReceived = "Pending";
                setShowAlert("Please select any one of prodcut!");
                handleClickOpen();
            }else if(purchaseAdd.billamount != purchaseAdd.nettotal){
                setPurchaseAdd({
                    ...purchaseAdd, purchasestatus: "Pending"
                });
                purchaseStatusReceived = "Pending";
                setShowAlert("Bill Amount and Net total didn't match");
                handleClickOpen();
            }
            else   if(purchaseAdd.billamount == purchaseAdd.nettotal && allProductsList.length > 0){
                setPurchaseAdd({
                    ...purchaseAdd, purchasestatus: "Received"
                });
            }
            }
        }


    // purchase data add to database
    const addPurchase = async () => {
        // current stock when the user submit
        if(purchaseAdd.purchasestatus == "Received"){
            allProductsList.map((item, index) => {
                productsList.forEach((data, i) => {
                    if ((item.sku == data.prodcode)) {
                        axios.put(`${SERVICE.PRODUCT_SINGLE}/${data._id}`, {
                            currentstock: Number(data.currentstock) + Number(item.quantity) + Number(item.freeitem),
                            purchaseexcludetax: Number(item.purchaseexcludetax),
                            pruchaseincludetax: Number(item.pruchaseincludetax),
                            sellingpriceunitcost: Number(item.sellingpriceunitcost),
                            batch:String(item.batch),
                            expirydate:String(item.expirydate),
                        });
                    }
                })
            })
        }

        

        let allproductreverse = [...allProductsList.reverse()]

        try {
          
            let purchase = await axios.post(SERVICE.PURCHASE_CREATE, {
                    headers: {
                        'Authorization':`Bearer ${auth.APIToken}`
                        },
                supplier: String(purchaseAdd.supplier),
                referenceno: String(purchaseAdd.referenceno),
                purchasedate: String(purchaseDateTime),
                purchasestatus: String(purchaseAdd.purchasestatus),
                addressone: String(supplier.addressone + ' ' + supplier.addresstwo + ' ' + supplier.whatsapp),
                supplierid: String(supplier.autogenerate),
                supaddressone: String(supplier.addressone),
                addresstwo: String(supplier.addresstwo),
                city: String(supplier.city),
                gstn: String(supplier.gstn), 
                whatsapp: Number(supplier.whatsapp), 
                contactperson: String(supplier.contactperson),
                creditdays: Number(supplier.creditdays),
                dlnos: String(supplier.dlnos),
                duedate: duedate,
                businesslocation: String(purchaseAdd.businesslocation),
                billamount:Number(purchaseAdd.billamount),
                invoiceno: String(purchaseAdd.invoiceno),
                attachdoc: String(purchaseAdd.attachdoc),
                products: [...allproductreverse],
                additionalnotes: String(purchaseAdd.additionalnotes),
                totalcnt:Number(allProductsList.length),
                totalitem: Number(totalitem),
                nettotal: Number(purchaseAdd.nettotal),
                advancebalance: Number(purchaseAdd.advancebalance),
                payamount:Number(purchaseAdd.payamount),
                paidon:String(paidDateTime),
                paymentmethod:String(purchaseAdd.paymentmethod),
                paydue: Number(purchaseAdd.paydue),
                balance:Number(balanceClaculation()),
                beforediscounttotal:Number(totalNetCostCalc()),
                subcost: Number(totalNetCostCalcSub()),
                totaldiscount: Number(totalNetCostCalcDisc()),
                granddistype:String(purchaseAdd.granddistype),
                gdiscountvalue:Number(purchaseAdd.gdiscountvalue),
                discountamt:Number(purchaseAdd.discountamt),
                cgst:Number(CGST),
                igst:Number(IGST),
                gst:Number(GST),
                activate:Boolean(supplier.activate),
                userbyadd:String(isUserRoleAccess.first+' '+isUserRoleAccess.last),
            });
            setPurchaseAdd(purchase.data);
            backPage('/purchase/purchase/list');
            toast.success(purchase.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        catch (err) {
            const messages = err.response.data.message;
            setShowAlert(messages);
            handleClickOpen();
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(purchaseAdd.supplier == ""){
            setShowAlert("Please select supplier");
            handleClickOpen();
        } else if(purchaseAdd.invoiceno == ""){
            setShowAlert("Please enter invoice no");
            handleClickOpen();
         }else if(allProductsList.length == 0){
            setShowAlert("Please select any one of product details!");
            handleClickOpen();
         }else if(purchaseAdd.billamount == "" ){
            setShowAlert("Please enter bill amount");
            handleClickOpen();
        }else if((purchaseAdd.billamount < purchaseAdd.nettotal)){
            setShowAlert("Please enter a valid bill amount");
            handleClickOpen();
        }else{
            addPurchase();
        }
       
    }

    //pay due calculation
    function payDueCalc(){

        if(purchaseAdd.granddistype == "None"){
            setPurchaseAdd(
                {...purchaseAdd, paydue: totalNetCostCalc() == undefined ? 0 : Number(totalNetCostCalc()) - Number(purchaseAdd.payamount)}
            )  
        }else{
            setPurchaseAdd(
                {...purchaseAdd, paydue: purchaseAdd.nettotal == "" ||  purchaseAdd.nettotal == 0 ? 0 : Number(purchaseAdd.nettotal) - Number(purchaseAdd.payamount)}
            )  
        }
       
    }

    useEffect(
        ()=>{
            payDueCalc();
        },[purchaseAdd.payamount,purchaseAdd.nettotal,allProductsList]
    )

    // all tabel product tax calculation
    function productUserInput(indexInput, productInputName, inputValue, reference = "") {
        let userInputs = allProductsList.map((value, indexList) => {

            if (indexInput == indexList) {
                if (reference == "Exclusive") {
                       if (value.purchasetax) {
                        let tax = (Number(inputValue) * (getTaxValue[0]?.taxtotal) / 100);
                        let includeTaxValue = tax + Number(inputValue)
                        return { ...value, [productInputName]: inputValue, excalpha: getAlphaRate(inputValue), pruchaseincludetax: includeTaxValue.toFixed(0), incalpha: getAlphaRateInc(includeTaxValue) }
                    }
                    return { ...value, [productInputName]: inputValue, excalpha: getAlphaRate(inputValue)}
                }
                else if (reference == "Inclusive") {
                    let tax = value.purchaseexcludetax ? (Number(value.purchaseexcludetax) * (getTaxValue[0]?.taxtotal) / 100) : "";
                    let includeTaxValue = tax ? tax + Number(value.purchaseexcludetax) : "";
                    return { ...value, [productInputName]: inputValue, pruchaseincludetax: includeTaxValue, incalpha: getAlphaRateInc(includeTaxValue) }
                }
                else if(reference == "Batch"){
                    return {...value, [productInputName]: inputValue}
                }else if(reference == "Packcharge"){
                    return {...value, [productInputName]: inputValue}
                }else if(reference == "Expirydate"){
                    return {...value, [productInputName]: inputValue}
                }else if(reference == "Margin"){
                    return {...value, [productInputName]: inputValue}
                }
             
                else if (reference == "Enteramt") {
                    if (value.purchasetabletax == "Exclusive") {
                        if(value.sellingpricetax == "Exclusive"){
                            let purchaseExtAmt = Number(inputValue);
                            let purchaseextamtfixed= purchaseExtAmt.toFixed(0);
                            //selling price exclusive purchase price exclusive unit cost
                            let sellingvalue = (Number(purchaseextamtfixed) * (Number(value.margin) / 100));
                            let sellingExcludevalue = Number(purchaseextamtfixed) + sellingvalue;
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, purchaseexcludetax: purchaseextamtfixed, pruchaseincludetax: 0 , incalpha:"", excalpha: getAlphaRate(purchaseextamtfixed),sellingpriceunitcost: sellingExcludevalue.toFixed(0)};
                        }else if(value.sellingpricetax == "Inclusive"){
                            let purchaseExtAmt = Number(inputValue);
                            let purchaseextamtfixed= purchaseExtAmt.toFixed(0);
                            //selling price inclusive purchase price exclusive unit cost
                            let sellingvaluemargin= (Number(purchaseextamtfixed) * (Number(value.margin) / 100));
                            let sellingExmargin = Number(purchaseextamtfixed) + Number(sellingvaluemargin);
                            let sellingvaluetax = (Number(sellingExmargin) * ((value.applicabletaxrate ? Number(value.applicabletaxrate) : Number(value.hsntax)) / 100));
                            let sellingExcludevaluetax = Number(sellingExmargin) + Number(sellingvaluetax);
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, purchaseexcludetax: purchaseextamtfixed, pruchaseincludetax: 0 , incalpha:"", excalpha: getAlphaRate(purchaseextamtfixed),sellingpriceunitcost: sellingExcludevaluetax.toFixed(0)};
                        }
                    }
                    else if (value.purchasetabletax == "Inclusive" ) {
                        if(value.sellingpricetax == "Exclusive"){
                            let purchaseIncAmt = Number(inputValue);
                            let purchaseincamtfix=purchaseIncAmt.toFixed(0);
                            let purchaseExcamtBasedInc = purchaseIncAmt * (getTaxValue[0]?.taxtotal/100) - purchaseIncAmt;
                            let purchaseExcamtBasedIncfix = Math.abs(purchaseExcamtBasedInc.toFixed(0));
                            //selling price exclusive purchase price inclusive unit cost
                            let sellingvalue = (Number(purchaseincamtfix) * (Number(value.margin) / 100));
                            let sellingIncludevalue = Number(purchaseincamtfix) + sellingvalue;
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, pruchaseincludetax:purchaseincamtfix, purchaseexcludetax: purchaseExcamtBasedIncfix,excalpha: getAlphaRate(purchaseExcamtBasedIncfix),incalpha: getAlphaRateInc(purchaseincamtfix),sellingpriceunitcost: sellingIncludevalue.toFixed(0)};
                        }else if(value.sellingpricetax == "Inclusive"){
                            let purchaseIncAmt = Number(inputValue);
                            let purchaseincamtfix=purchaseIncAmt.toFixed(0);
                            let purchaseExcamtBasedInc = purchaseIncAmt * (getTaxValue[0]?.taxtotal/100) - purchaseIncAmt ;
                            let purchaseExcamtBasedIncfix = Math.abs(purchaseExcamtBasedInc.toFixed(0));
                            //selling price inclusive purchase price inclusive unit cost
                            let sellingvaluemargin= (Number(purchaseExcamtBasedIncfix) * (Number(value.margin) / 100));
                            let sellingExmargin = Number(purchaseExcamtBasedIncfix) + Number(sellingvaluemargin);
                            let sellingvaluetax = (Number(sellingExmargin) * ((value.applicabletaxrate ? Number(value.applicabletaxrate) : Number(value.hsntax)) / 100));
                            let sellingExcludevaluetax = Number(sellingExmargin) + Number(sellingvaluetax);
                            setAlltotalItems((alltotalitems) => {
                                let allItems = [...alltotalitems];
                                allItems[indexInput] = { ...totalItem, enteramt: inputValue };
                                return allItems;
                            });
                            return { ...value, [productInputName]: inputValue, pruchaseincludetax:purchaseincamtfix, purchaseexcludetax: purchaseExcamtBasedIncfix,excalpha: getAlphaRate(purchaseExcamtBasedIncfix),incalpha: getAlphaRateInc(purchaseincamtfix),sellingpriceunitcost: sellingExcludevaluetax.toFixed(0)};
                        }
                    }
                }
                else if (reference == "Quantity") {
               
                    if (value.purchasetabletax == "Exclusive" && value.purchaseexcludetax) {
                        let netCost = Number(value.purchaseexcludetax) * Number(inputValue)
                        setAlltotalItems((alltotalitems) => {
                            let allItems = [...alltotalitems];
                            allItems[indexInput] = { ...totalItem, quantity: inputValue };
                            return allItems;
                        });
                        return { ...value, [productInputName]: inputValue, purchasenetcost: netCost.toFixed(0),ratebydismod:netCost.toFixed(0)};
                    }
                    
                    else if (value.purchasetabletax == "Inclusive") {       
                        getTaxrateData = taxrates?.filter((item) => {
                            if(item.taxname == value.applicabletax ){
                                        return item                              
                                }
                            })                              
                        let netCost = Number(value.pruchaseincludetax) * Number(inputValue);
                        setAlltotalItems((alltotalitems) => {
                            let allItems = [...alltotalitems];
                            allItems[indexInput] = { ...totalItem, quantity: inputValue };
                            return allItems;
                        })
                    return { ...value, [productInputName]: inputValue, purchasenetcost: netCost.toFixed(0),ratebydismod:netCost.toFixed(0), 
                                     subtax:getTaxrateData };
                            }
                     
                    
                }
                else if (reference == "Discount") {
                    if(value.distypemod == "None") {
                        return { ...value, [productInputName]: inputValue, differenceamt: value.purchasenetcost,
                             }
                    }
                    else if((value.distypemod == "Fixed" || value.distypemod == "Amount") && value.purchasenetcost) {
                        let discountValue = Number(value.purchasenetcost) - Number(inputValue);
                        let differenceValue = Number(value.purchasenetcost) - discountValue;
                        return { ...value, [productInputName]: inputValue, ratebydismod: discountValue, differenceamt: differenceValue }
                    }
                    else if (value.distypemod == "Percentage" && value.purchasenetcost) {
                        let differenceValue = Number(value.purchasenetcost) * (Number(inputValue) / 100);
                        let discountValue = Number(value.purchasenetcost) - differenceValue;
                        return { ...value, [productInputName]: inputValue, ratebydismod: discountValue, differenceamt: differenceValue }
                    }
                }
                else if (reference == "Free") {
                    setAlltotalItems((alltotalitems) => {
                        let allItems = [...alltotalitems];
                        allItems[indexInput] = { ...totalItem, freeitem: inputValue };
                        return allItems;
                    });
                }
                return { ...value, [productInputName]: inputValue }
            }
            else {
                return value;
            }
        });
        setAllProductsList(userInputs);
    }
    
    function totalNetCostCalc() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                totalvalue += Number(value.ratebydismod)
            })
            return Number(totalvalue.toFixed(0));
        }
    }
    function totalNetCostCalcinclusive() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                if(value.purchasetabletax == "Inclusive")
                totalvalue += Number(value.ratebydismod)
            })
            return Number(totalvalue.toFixed(0));
        }
    }

    const balanceClaculation = () =>{
       if(purchaseAdd.granddistype == "None"){
        let balanceamtval =Number(purchaseAdd.billamount) ? Number(purchaseAdd.billamount)  : 0;
        balanceamt = (balanceamtval ? balanceamtval   : 0)- ( Number(totalNetCostCalc()) ? Number(totalNetCostCalc())  : 0)

        return balanceamt
       }else{
        let balanceamtval =Number(purchaseAdd.billamount) ? Number(purchaseAdd.billamount)  : 0;
        balanceamt = (balanceamtval ? balanceamtval   : 0)- ( Number(purchaseAdd.nettotal) ? Number(purchaseAdd.nettotal)  : 0)

        return balanceamt
       }

    }

    useEffect(
        ()=>{
            balanceClaculation()
        },[purchaseAdd.nettotal,allProductsList]
    )

    // total quantity and free item calculation
    let totalitem = 0.00;
    {
        allProductsList && (
            allProductsList.forEach(
                (item => {
                    totalitem += +item.quantity + +item.freeitem
                })
            ))
    }

    // Discount Amount
    function totalNetCostCalcDisc() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                totalvalue += +Number(value.differenceamt)
            })
            return totalvalue.toFixed(0);
        }
    }

    //subtotal
    function totalNetCostCalcSub() {
        let totalvalue = 0;
        if (allProductsList?.length > 0) {
            allProductsList?.forEach((value) => {
                totalvalue += +Number(value.purchasenetcost)
            })
            return totalvalue.toFixed(0);
        }
    }

    useEffect(()=>{
        setPurchaseAdd({...purchaseAdd,beforediscounttotal:Number(totalNetCostCalc()) })
    },[])


    // Delete Searched Product
    const deleteRow = (i, e) => {
        setAllProductsList(allProductsList.filter((v, item) => item !== i));
        getarraydata(arraydata.filter((v, item) => item !== i))
    }
  
    // grand total calculation
    const grandtotalCalc = (reference, inputvalue) => {
        if(reference == "discounttype"){
            if (inputvalue == "None" ) {                    
                setPurchaseAdd(
                    {
                        ...purchaseAdd, nettotal: totalNetCostCalc().toFixed(0), granddistype:inputvalue, gdiscountvalue:"", discountamt:""
                    });
            }else if (inputvalue == "Fixed" || inputvalue == "Amount" ) {                    
                setPurchaseAdd(
                    {
                        ...purchaseAdd, nettotal: ((Number(totalNetCostCalc()) - Number(purchaseAdd.gdiscountvalue)).toFixed(0)), discountamt:Number(purchaseAdd.gdiscountvalue).toFixed(0) , granddistype:inputvalue
                    });
            }
            else if (inputvalue == "Percentage") {                    
                let percentCalc = Number(totalNetCostCalc()) * (Number(purchaseAdd.gdiscountvalue) / 100);
            setPurchaseAdd(
                {
                    ...purchaseAdd,  nettotal: (Number(totalNetCostCalc()) - percentCalc).toFixed(0),discountamt:Math.abs((Number(totalNetCostCalc()) - percentCalc) -  Number(totalNetCostCalc())).toFixed(0), granddistype:inputvalue
                });
            }
        }else if(reference == "discountamt"){
            if (purchaseAdd.granddistype == "None" ) {                    
                setPurchaseAdd(
                    {
                        ...purchaseAdd, nettotal: totalNetCostCalc().toFixed(0), gdiscountvalue:Number(inputvalue), discountamt: ""
                    });
            }else if (purchaseAdd.granddistype == "Fixed" || purchaseAdd.granddistype == "Amount" ) {                    
                setPurchaseAdd(
                    {
                        ...purchaseAdd, nettotal: (Number(totalNetCostCalc()) - Number(inputvalue)), discountamt:Number(inputvalue) , gdiscountvalue:Number(inputvalue),
                    });
            }
            else if (purchaseAdd.granddistype == "Percentage") {                    
                let percentCalc = Number(totalNetCostCalc()) * (Number(inputvalue) / 100);
            setPurchaseAdd(
                {
                    ...purchaseAdd,  nettotal: (Number(totalNetCostCalc()) - percentCalc).toFixed(0),discountamt:Math.abs((Number(totalNetCostCalc()) - percentCalc) -  Number(totalNetCostCalc())).toFixed(0), gdiscountvalue:Number(inputvalue)
                });
            }
        }             
    }

    return (
        <Box>
            <Headtitle title={'Add Purchase'} />
            <form onSubmit={handleSubmit}>
                <Typography sx={userStyle.HeaderText}>Add Purchase</Typography>
                <Box sx={userStyle.container}>
                    <Grid container spacing={3} sx={userStyle.textInput}>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Supplier<b style={{color:'red'}}>*</b></InputLabel>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth sx={{ display: 'flex' }}>
                                    <Selects
                                        styles={colourStyles}
                                        options={suppliers}
                                        onChange={(e) => { setPurchaseAdd({ ...purchaseAdd, supplier:e.value,referenceno:newval, businesslocation:setngs ? setngs.businesslocation : "" }) ;searchAdd(e._id); getDueDate(e.creditdays)}}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            {purchases && (
                                purchases.map(
                                    () => {
                                        let strings = setngs ? setngs.purchasesku: "PU";
                                        let refNo = purchases[purchases.length - 1].referenceno;
                                        let digits = (purchases.length + 1).toString();
                                        const stringLength = refNo.length;
                                        let lastChar = refNo.charAt(stringLength - 1);
                                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                                        let lastBeforeChar = refNo.slice(-2);
                                        let lastThreeChar = refNo.slice(-3);
                                        let lastDigit = refNo.slice(-4);
                                        let refNOINC = parseInt(lastChar) + 1
                                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                                        let refLstThree = parseInt(lastThreeChar) + 1;
                                        let refLstDigit = parseInt(lastDigit) + 1;
                                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                            refNOINC = ("000" + refNOINC).substr(-4);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                            refNOINC = ("00" + refLstTwo).substr(-4);
                                            newval = strings + refNOINC;
                                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                                            refNOINC = ("0" + refLstThree).substr(-4);
                                            newval = strings + refNOINC;
                                        } else {
                                            refNOINC = (refLstDigit).substr(-4);
                                            newval = strings + refNOINC;
                                        }
                                    }))}
                                    <InputLabel id="demo-select-small">Reference No</InputLabel>
                            
                            <FormControl variant="outlined" size="small" fullWidth>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    value={newval}
                                    name="referenceno"
                                />
                            </FormControl>
                            <Typography variant='body2' sx={{ opacity: '0.9', marginTop: '5px' }}>Leave empty to autogenerate</Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Purchase Date</InputLabel>
                            <FormControl size="small" fullWidth >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField size="small" {...props} />}
                                    value={purchaseDateTime}
                                    onChange={(newValue) => {
                                    setPurchaseDateTime(newValue); 
                                    }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Bill Amount</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchaseAdd.billamount}
                                    type="Number"
                                    onChange={(e) => { setPurchaseAdd({ ...purchaseAdd, billamount: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Business Location</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Selects 
                                    options={busilocations}
                                    styles={colourStyles}
                                    placeholder={setngs ? setngs.businesslocation : ""}
                                    onChange={(e) => { setPurchaseAdd({ ...purchaseAdd, businesslocation: e.name});}} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Invoice No<b style={{color:'red'}}>*</b></InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchaseAdd.invoiceno}
                                    placeholder='Enter invoice number'
                                    type="text"
                                    onChange={(e) => { setPurchaseAdd({ ...purchaseAdd, invoiceno: e.target.value }) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <p id='attachdocdetail'></p>
                                <Button component="label" sx={userStyle.uploadBtn}>
                                    Upload File
                                    <input type='file' id="attachdoc" name='Pfile' hidden onChange={handleFileChange}
                                    />
                                </Button>
                                <br />
                                <Typography variant='body2'>Max File size: 5MB <br />Allowed File: .jpeg, .jpg, .png</Typography>
                            </FormControl>
                        </Grid>
                       
                        <Grid item md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Balance:-</InputLabel>
                            <Typography value={purchaseAdd.balance}
                             style={{color:'red', fontSize:'20px'}}
                            > {balanceClaculation()  == NaN ? 0 : balanceClaculation()}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='subtitle1'>
                                <b>Address:</b> <br /> 
                                {supplier.addressone}<br />
                                {supplier.phoneone}
                                {supplier.whatsapp ? 'Whatsapp: '+supplier.whatsapp:null}
                            </Typography>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Due date</InputLabel>
                        <FormControl size="small" fullWidth>
                            <TextField
                                id="date"
                                type="date"
                                size="small"
                                fullWidth
                                value={duedate}
                                onChange={(e) => { setduedate(e.target.value) }}
                            />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.filtercontent} style={{ minHeight: '300px', }}>
                    <Grid container style={{ justifyContent: "center", padding: '10px' }} sx={userStyle.textInput} >
                        <Grid md={8} sx={12} xs={12}>
                            <Grid sx={{ display: 'flex' }} >
                                <Grid sx={userStyle.spanIconTax}>< FaSearch /></Grid>
                                <FormControl size="small" fullWidth >
                                    <Selects
                                        sx={userStyle.textInput}
                                        styles={colourStyles}
                                        placeholder="Enter Product Name / SKU "
                                        options={productsList}
                                        onChange={(e) => 
                                            gethsncode(e)
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <br />
                    <TableContainer component={Paper} sx={userStyle.tablecontainer}>
                        <Table aria-label="customized table">
                            <TableHead >
                                <StyledTableRow >
                                    <TableCell sx={{fontSize:'15px', background:'#5CB85C !important',color:'black !important'}}>Product</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Batch Number</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Tax</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Unit Cost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Quantity</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Purchase Netcost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}>Selling Price Unit Cost</TableCell>
                                    <TableCell sx={userStyle.tableHead1}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {allProductsList.length > 0 &&
                                    allProductsList.map((data, i) => {
                                        return (
                                            <>
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Product Name</Typography>
                                                                <TextField 
                                                                    fullWidth
                                                                  size='small'
                                                                  sx={{minWidth:'230px'}}
                                                                    value={data?.prodname}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>SKU</Typography>
                                                                <TextField 
                                                                 fullWidth
                                                                size='small'
                                                                    value={data?.sku}
                                                                />
                                                            </Grid>
                                                            {data?.hsn ?
                                                                (
                                                                    <>
                                                                        <Grid item md={12}>
                                                                            <Typography variant='body2'>HSN</Typography>
                                                                            <TextField size='small'
                                                                             fullWidth
                                                                                value={data?.hsn}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Grid item md={12}>
                                                                            <Typography variant='body2'>TAX</Typography>
                                                                            <TextField size='small'
                                                                             fullWidth
                                                                                value={data?.applicabletax}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                                )
                                                            }
                                                            <Grid item md={12} sx={{ display: 'none' }}>
                                                                <Typography variant='body2'>TAX VALUE</Typography>
                                                                <TextField 
                                                                size='small'
                                                                fullWidth
                                                                    value={data?.applicabletaxrate}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12} sx={{ display: 'none' }}>
                                                                <Typography variant='body2'>HSN VALUE</Typography>
                                                                <TextField size='small'
                                                                 fullWidth
                                                                    value={data?.hsntax}
                                                                >
                                                                </TextField>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1} sx={{marginTop:'43px'}}>                                                        
                                                            <Grid item md={12}>
                                                            <Typography variant='body2'>Batch</Typography>
                                                                <TextField 
                                                                    size="small"
                                                                    fullWidth
                                                                    type="text"
                                                                    value={data?.batch}
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "batch", e.target.value, "Batch")
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                            <Typography variant='body2'>Packing charge</Typography>
                                                                <TextField 
                                                                    size="small"
                                                                    value={data?.packingcharge}                     
                                                                    fullWidth
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "packingcharge", e.target.value, "Packcharge")
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                            <Typography variant='body2'>Expiry date</Typography>
                                                                <TextField
                                                                    id="date"
                                                                    type="date"
                                                                    size="small"
                                                                    fullWidth
                                                                    value={data?.expirydate}
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "expirydate", e.target.value, "Expirydate")
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Purchase Tax</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <Select
                                                                        id="demo-select-small"
                                                                        value={data?.purchasetabletax}
                                                                        onChange={(e) => productUserInput(i, "purchasetabletax", e.target.value)}
                                                                        fullWidth
                                                                    >
                                                                        <MenuItem value="Exclusive" >Exclusive</MenuItem>
                                                                        <MenuItem value="Inclusive" >Inclusive</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Enter Amt</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField size='small'
                                                                        type="Number"
                                                                        value={data?.enteramt}
                                                                        onChange={(e) => {
                                                                            productUserInput(i, "enteramt", e.target.value, "Enteramt");
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Margin %</Typography>
                                                                <FormControl size="small" fullWidth>
                                                                    <TextField size='small'
                                                                        value= {data?.margin}
                                                                        type="number"
                                                                        onChange={(e) => {
                                                                            productUserInput(i, "margin", e.target.value, "Margin")
                                                                        }}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Exc Tax</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.purchaseexcludetax}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Alphaarate</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.excalpha}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Inc Tax</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.pruchaseincludetax}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Alphaarate</Typography>
                                                                <TextField size='small'
                                                                    type="text"
                                                                    value={data?.incalpha}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Quantity</Typography>
                                                                <TextField size='small'
                                                                    type="number"
                                                                    value={data?.quantity}
                                                                    onChange={(e) => {
                                                                        productUserInput(i, "quantity", e.target.value, "Quantity"); 
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12} style={{ width: "auto !important" }} >
                                                                <Typography variant='body2'>Free Item</Typography>
                                                                <TextField size='small'
                                                                    value={data?.freeitem}
                                                                    type="number"
                                                                    onChange={(e) => productUserInput(i, "freeitem", e.target.value, "Free")}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Netcost</Typography>
                                                                <TextField size='small'
                                                                    value={data?.purchasenetcost}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Discount type</Typography>
                                                                    <FormControl size="small" fullWidth>
                                                                        <Select
                                                                            id="demo-select-small"
                                                                            value={data?.distypemod}
                                                                            onChange={(e) => productUserInput(i, "distypemod", e.target.value)}
                                                                        >
                                                                            <MenuItem value="None">None</MenuItem>
                                                                            <MenuItem value="Fixed">Fixed</MenuItem>
                                                                            <MenuItem value="Amount" >Amount</MenuItem>
                                                                            <MenuItem value="Percentage">Percentage</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <Typography variant='body2'>Disc.Val</Typography>
                                                                <TextField size='small'
                                                                    type="number"
                                                                    value={data?.disvaluemod}
                                                                    onChange={(e) => productUserInput(i, "disvaluemod", e.target.value, "Discount")}
                                                                />
                                                            </Grid>
                                                            <Grid item md={6}>
                                                                <Typography variant='body2'>Disc.Amt</Typography>
                                                                <TextField size='small'
                                                                    type="number"
                                                                    value={data?.differenceamt}
                                                                />
                                                            </Grid>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Netcost (After Discount)</Typography>
                                                                <OutlinedInput
                                                                    size="small"
                                                                    id="component-outlined"
                                                                    value={data?.ratebydismod}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid item md={12}>
                                                            <Typography variant='body2'>Selling Price Tax</Typography>
                                                            <FormControl size="small" fullWidth >
                                                                <OutlinedInput
                                                                    id="demo-select-small"
                                                                    type="text"
                                                                    value={data?.sellingpricetax}
                                                                    fullWidth
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item md={12}>
                                                                <Typography variant='body2'>Selling price unit tax</Typography>
                                                                <FormControl size="small" >
                                                                    <TextField size='small'
                                                                        type="number"
                                                                        value={data?.sellingpriceunitcost}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell><AiOutlineClose style={{ color: 'red', fontWeight: '900', cursor: 'pointer', fontSize: 'large' }} onClick={(e) => deleteRow(i, e)}/></StyledTableCell>
                                                </StyledTableRow>
                                            </>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />                    
                </Box>
                <br />
                <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item md={1} sm={6} xs={12}>
                    <InputLabel id="demo-select-small">Total Item</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={Number(allProductsList.length)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={1} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Total Quantity</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={totalitem}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">Total Amount:</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={totalNetCostCalc()}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Type</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    id="demo-select-small"  
                                    value={purchaseAdd.granddistype}
                                    onChange={(e) => {grandtotalCalc('discounttype', e.target.value)}}
                                >
                                    <MenuItem value="None">None</MenuItem>
                                    <MenuItem value="Fixed">Fixed</MenuItem>
                                    <MenuItem value="Amount">Amount</MenuItem>
                                    <MenuItem value="Percentage">Percentage</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Value</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchaseAdd.gdiscountvalue}
                                    onChange={(e) => grandtotalCalc('discountamt', e.target.value)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Discount Amount</InputLabel>
                            <FormControl size="small" fullWidth>
                                <TextField
                                    size="small"
                                    value={purchaseAdd.discountamt}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">After Disc Total Amount:</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={purchaseAdd.granddistype == "None" ? totalNetCostCalc() : purchaseAdd.nettotal}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">CGST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={(CGST ? CGST : 0).toFixed(2)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">GST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={(GST ? GST : 0).toFixed(2)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} sm={6} xs={12}>
                        <InputLabel id="demo-select-small">IGST</InputLabel>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="demo-select-small"
                                    value={(IGST ? IGST : 0).toFixed(2)}
                                />
                            </FormControl>
                        </Grid>
                </Grid>
                </Box><br />  
                <Box sx={userStyle.container}>
                     {/* purchasestatus section start */}
                    <Grid container>
                        <Grid item md={3} sm={6} xs={12}>
                            <InputLabel id="demo-select-small">Purchase Status</InputLabel>
                            <FormControl size="small" fullWidth>
                                <Select
                                    id="demo-select-small"
                                    value={purchaseAdd.purchasestatus}
                                    onChange={(e) => {
                                        setPurchaseAdd({ ...purchaseAdd, purchasestatus: e.target.value });
                                        purchaseStatusInput(e.target.value);
                                    }}
                                >
                                    <MenuItem value={purchaseStatusPending}>{purchaseStatusPending}</MenuItem>
                                    <MenuItem value={purchaseStatusReceived}>{purchaseStatusReceived}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid><br />
                    {/* purchase status section end */}
                {purchaseAdd.purchasestatus == "Received" ? 
                    (
                        <>
                            <Typography variant="h6" >Add payment</Typography>
                            <Grid container spacing={3} sx={userStyle.textInput}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography variant="body2" ><b>Advance Balance:</b> ₹ {purchaseAdd.advancebalance}</Typography>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                <InputLabel htmlFor="component-outlined">Amount</InputLabel>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <Grid sx={userStyle.spanIconTax}><MoneyOutlinedIcon style={{ fontSize: 'large' }} /></Grid>
                                            <FormControl size="small" fullWidth >
                                            <OutlinedInput
                                                id="component-outlined"
                                                defaultValue={purchaseAdd}
                                                value={purchaseAdd.payamount}
                                                onChange={(e) => {
                                                    setPurchaseAdd({
                                                        ...purchaseAdd, payamount: e.target.value,
                                                        paydue: purchaseAdd.nettotal - +e.target.value
                                                    })
                                                }}
                                                type='number'
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12} >
                                <InputLabel htmlFor="component-outlined">Paid Date:</InputLabel>
                                    <Grid sx={{ display: 'flex' }}  >
                                        <FormControl size="small" fullWidth >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                renderInput={(props) => <TextField size="small" {...props} />}
                                                value={paidDateTime}
                                                onChange={(newValue) => {
                                                    setPaidDateTime(newValue);
                                                }}
                                            />
                                            </LocalizationProvider>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item md={4} sm={6} xs={12}>
                                <InputLabel id="demo-select-small">Payment Method</InputLabel>
                                    <FormControl size="small" fullWidth>
                                        <Select
                                            id="demo-select-small"
                                            value={purchaseAdd.paymentmethod}
                                            onChange={(e) => {
                                                setPurchaseAdd({ ...purchaseAdd, paymentmethod: e.target.value });
                                            }}
                                        >
                                            <MenuItem value="None">None</MenuItem>
                                            <MenuItem value="Cash">Cash</MenuItem>
                                            <MenuItem value="Credit Card">Credit Card</MenuItem>
                                            <MenuItem value="UPI">UPI</MenuItem>
                                            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                                            <MenuItem value="Net Banking">Net Banking</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <InputLabel id="demo-select-small" sx={{ m: 1 }}>Payment Note</InputLabel>
                                    <FormControl size="small" fullWidth >
                                        <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                            value={purchaseAdd.additionalnotes}
                                            onChange={(e) => { setPurchaseAdd({ ...purchaseAdd, additionalnotes: e.target.value }) }}
                                        />
                                    </FormControl><br /><br />
                                    <hr />
                                </Grid>
                                <Grid container style={{ justifyContent: "right", }} sx={userStyle.textInput}>
                                    <Grid>
                                        <Typography variant='subtitle1'
                                        ><b>Payment due:</b> ₹ {purchaseAdd.paydue}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    ): null
                }
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid sx={{ display: 'flex' }}>
                        <Link to="/purchase/purchase/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
                        <Button sx={userStyle.buttonadd} type="submit">SAVE</Button><br />
                    </Grid>
                </Grid>
                </Box>
            </form>
            {/* ALERT DIALOG */}
            <Dialog
                open={isErrorOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                <Typography variant="h6" >{showAlert}</Typography>
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

const Purchasecreate = () => {
    return (
        <>
            <Box>
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Purchasecreatelist /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Purchasecreate;