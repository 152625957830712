import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import axios from 'axios';
import $ from 'jquery';
import { toast } from 'react-toastify';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import { AuthContext } from '../../context/Appcontext'
function Supplierwise() {
  const [supplierwiseestimate, setSupplierWiseEstimate] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })
  let manArr = []
  let totArr = []
  let quantityArr = []
  let arrMixed = []

  let tableDatas =[]
  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  const fetchTableproducts = async (e) => {
    try {
      let response = await axios.get(SERVICE.PURCHASEPRODUCTS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setPurchases(response.data.purchases)
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  //estimate fetching
  const fetchPos = async () => {
    try {
      let request = await axios.get(SERVICE.ESTIMATE,
        {
          headers: {
            'Authorization': `Bearer ${auth.APIToken}`
          }
        });
      let estimatefetch = request.data.estimate.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })
     
      estimatefetch.map((estimate) => {  
        estimate.goods.map((value) => {       
          purchases.forEach((data) => {
            if (value.productid == data.sku && value.batch == data.batch && value.sellingpriceunitcost == data.sellingpriceunitcost) {        
              tableDatas.push({...value, supplier:data.supplier})              
            }
          })
        })
      })
   
 
        const result = [...tableDatas.reduce((r, o) => {
          const key = o.supplier;
          const items = r.get(key) || Object.assign({}, o, {
            quantity: 0,
            subtotal: 0
          });
          items.quantity += o.quantity
          items.subtotal += o.subtotal
          return r.set(key, items);
        }, new Map).values()];
        setSupplierWiseEstimate(result);
        $(document).ready(function () {
          $.fn.dataTable.ext.errMode = 'none';
          setTimeout(function () {
            $(tableRef.current).DataTable({
              language: { search: '', searchPlaceholder: "Search..." },
              lengthMenu: [
                [10, 1, 25, 50, 100, 200, 500, -1],
                [10, 1, 25, 50, 100, 200, 500, 'All'],
              ],
            });
          }, 1000);
        });
    
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }
  // Export Excel
  const fileName = 'Supplier Wise Estimate'
  //Excel data
  const [exceldata, setExceldata] = useState([]);
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = supplierwiseestimate.map(t => ({
      Supplier: t.suppler,
      "Total Quantity": t.totlquantity,
      "Total Amount": t.totalbillamt,
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas()
  }, [supplierwiseestimate])


  useEffect(()=>{
    fetchTableproducts()
  },[])

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#supplierwiseestimate' })
    doc.save('Supplier Wise Estimate.pdf')
  }
  
  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'SupplierwiseEstimate',
    pageStyle: 'print'
  });

  return (
    <Box>
      <Headtitle title={'Supplierwise Estimate'} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={userStyle.importheadtext}>Supplier Wise Estimate</Typography>
        </Grid>
      </Grid><br />
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={5} md={5}>
            <Grid container>
              <Grid item lg={3} md={3}>
                <Typography sx={{ marginLeft: "1.3em" }}> From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} md={5}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchPos} >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
        { /* ******************************************************EXPORT Buttons****************************************************** */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvsupplierwiseestimate && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelsupplierwiseestimate&& (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printsupplierwiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfsupplierwiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="supplierwiseestimate" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Supplier</StyledTableCell>
                  <StyledTableCell >Total Qunatity</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {supplierwiseestimate &&
                  (supplierwiseestimate.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell >{row.supplier}</StyledTableCell>
                        <StyledTableCell >{row.quantity}</StyledTableCell>
                        <StyledTableCell >{row.subtotal}</StyledTableCell>
                      </StyledTableRow>
                    )
                  }))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="supplierwiseestimate" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Supplier</StyledTableCell>
              <StyledTableCell >Total Qunatity</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {supplierwiseestimate &&
              (supplierwiseestimate.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell >{row.supplier}</StyledTableCell>
                    <StyledTableCell >{row.quantity}</StyledTableCell>
                    <StyledTableCell >{row.subtotal}</StyledTableCell>
                  </StyledTableRow>
                )
              }))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function Supplierwisesales() {
  return (
    <Box>
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Supplierwise /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Supplierwisesales;