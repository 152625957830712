import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Grid, Button, Table, TableBody, TableContainer, FormControlLabel, DialogContentText, TableHead, DialogTitle, Typography, Paper, Checkbox, Dialog, DialogContent, DialogActions } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../../PageStyle';
import $ from 'jquery';
import axios from 'axios';
import { Link } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { toast } from 'react-toastify';
import { ExportXL, ExportCSV } from '../../Export';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SERVICE } from '../../../services/Baseservice';
import jsPDF from "jspdf";
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

const Discountlisttable = () => {

    const [discounts, setDiscounts] = useState([]);
    const [isdiscount, setDiscount] = useState({})
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // PDF Data
    const [isPdfData, setIsPdfData] = useState({
        isDisclocation: false, isDiscId: false, isDiscname: false, isDiscproduct: false, isDisccategory: false,
         isPurchaseExtax: false, isPurchaseIntax: false, isSellinIntax: false, isSellingExtax: false, isDisctype: false,
        isDiscprice: false, isDiscAmount: false, isDiscValue: false, isStart: false, isEnd: false,
    })

    // User Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const handleClickOpen = () => { setIsDeleteOpen(true); };
    const handleClose = () => { setIsDeleteOpen(false); };

    // PDF
    const [openPdf, setOpenPdf] = useState(false);
    const handleOpenPdf = () => { setOpenPdf(true); };
    const handleClosePdf = () => { setOpenPdf(false); };

    //  Fetch Discount Data
    const fetchDiscount = async () => {
        try {
            let res = await axios.get(SERVICE.DISCOUNT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDiscounts(res.data.discounts);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //set function to get particular row
    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.DISCOUNT_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setDiscount(res.data.sdiscount);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //alert delete popup
    let discountid = isdiscount._id;

    const deleteDiscount = async (discountid) => {

        try {
          await axios.delete(`${SERVICE.DISCOUNT_SINGLE}/${discountid}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    //  Excel
    const fileName = 'Discounts';

    // // get perticular columns for export excel
    const getexcelDatas = async () => {
        var data = discounts.map(t => ({
            "Location": t.location, "Discount ID": t.discountid, "Name": t.name, "Product Name": t.products, "Category": t.category, "Schedule": t.schedule,
            "Molecule": t.molecule,"Manufacture": t.manufacture, "Purchase Exclude Tax": t.purchaseexcludetax, "Purchase Include Tax": t.purchaseincludetx, "Selling Exclude Tax": t.sellingvalue,
            "Discount Type": t.discounttype,"Discount Amount": t.discountamt,
            "Discount Value": t.discountvalue, "Starts At": t.startsat, "Ends At": t.endsat,
        }));
        setExceldata(data);
    }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'HIPOS | DISCOUNTS',
      pageStyle: 'print'
  });

    // Pdf
    const downloadPdf = () => {
        const newData = discounts.map(row => {
            delete row._id;
            delete row.prodid;
            delete row.createdAt;
            delete row.__v
            { !isPdfData.isDisclocation && delete row.location };
            { !isPdfData.isDiscId && delete row.discountid };
            { !isPdfData.isDiscname && delete row.name };
            { !isPdfData.isDiscproduct && delete row.products };
            { !isPdfData.isDisccategory && delete row.category };
            { !isPdfData.isPurchaseExtax && delete row.purchaseexcludetax };
            { !isPdfData.isPurchaseIntax && delete row.pruchaseincludetax };
            { !isPdfData.isSellingExtax && delete row.sellingvalue };
            { !isPdfData.isDisctype && delete row.discounttype };
            { !isPdfData.isDiscprice && delete row.selectdiscountprice };
            { !isPdfData.isDiscAmount && delete row.discountamt };
            { !isPdfData.isDiscValue && delete row.discountvalue };
            { !isPdfData.isStart && delete row.startsat };
            { !isPdfData.isEnd && delete row.endsat };
            setIsPdfData(row)
        })

        const doc = new jsPDF()

        doc.autoTable({
            theme: "grid",
            body: discounts,
        })
        doc.save('Discounts.pdf')
    }

    useEffect(
        () => {
            fetchDiscount();
            getexcelDatas();
        }, [deleteDiscount]
    );

    //  JQUERY
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)])

    return (
        <Box>
            <Headtitle title={'Discount List'} />
            <Typography sx={userStyle.HeaderText}>Discount</Typography>
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography sx={userStyle.importheadtext}>All your discounts</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].adiscount && (
                            <>
                                <Link to={'/product/discount/create'} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].csvdiscount && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].exceldiscount && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printdiscount && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfproduct && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => handleOpenPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid>
                <Box>
                    <TableContainer component={Paper} >
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="discounttable" ref={tableRef}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Actions</StyledTableCell>
                                    <StyledTableCell>Location </StyledTableCell>
                                    <StyledTableCell>Discount ID</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Product Name</StyledTableCell>
                                    <StyledTableCell>Category</StyledTableCell>
                                    <StyledTableCell>Schedule</StyledTableCell>
                                    <StyledTableCell>Molecule</StyledTableCell>
                                    <StyledTableCell>Manufacture</StyledTableCell>
                                    <StyledTableCell>Purchase Excluding Tax</StyledTableCell>
                                    <StyledTableCell>Purchase Including Tax</StyledTableCell>
                                    <StyledTableCell>Selling Tax</StyledTableCell>
                                    <StyledTableCell>Discount Type </StyledTableCell>
                                    <StyledTableCell>Discount Amount </StyledTableCell>
                                    <StyledTableCell>Discount Value </StyledTableCell>
                                    <StyledTableCell>Start At </StyledTableCell>
                                    <StyledTableCell>Ends At </StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {discounts.length > 0 &&
                                    (discounts.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell >
                                                <Grid sx={{ display: 'flex' }}>
                                                    {isUserRoleCompare[0].ediscount && (<Link to={`/product/discount/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                                                    {isUserRoleCompare[0].ddiscount && (<Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>)}
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">{row.location}</StyledTableCell>
                                            <StyledTableCell>{row.discountid}</StyledTableCell>
                                            <StyledTableCell>{row.name}</StyledTableCell>
                                            <StyledTableCell>{row.products}</StyledTableCell>
                                            <StyledTableCell>{row.category}</StyledTableCell>
                                            <StyledTableCell>{row.schedule}</StyledTableCell>
                                            <StyledTableCell>{row.molecule}</StyledTableCell>
                                            <StyledTableCell>{row.manufacture}</StyledTableCell>
                                            <StyledTableCell>{row.purchaseexcludetax}</StyledTableCell>
                                            <StyledTableCell>{row.purchaseincludetx}</StyledTableCell>
                                            <StyledTableCell>{row.sellingvalue}</StyledTableCell>
                                            <StyledTableCell>{row.discounttype}</StyledTableCell>
                                            <StyledTableCell>{row.discountamt}</StyledTableCell>
                                            <StyledTableCell>{row.discountvalue}</StyledTableCell>
                                            <StyledTableCell>{moment(row.startsat).format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell>{moment(row.endsat).format('DD-MM-YYYY')}</StyledTableCell>
                                        </StyledTableRow>
                                    )))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Delete */}
                <Dialog
                    open={isDeleteOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined">Cancel</Button>
                        <Button onClick={(e) => deleteDiscount(discountid)} autoFocus variant="contained" color='error'> OK </Button>
                    </DialogActions>
                </Dialog>

                { /* ****** Print ****** */}
                <Box sx={userStyle.printcls} >
                    <Box>
                        <Typography variant='h5' >Pos</Typography>
                    </Box>
                    <>
                        <Box  >
                            <TableContainer component={Paper} sx={userStyle.printcls}>
                                <Table aria-label="simple table" id="discounttablepdf" ref={componentRef}>
                                    <TableHead sx={{ fontWeight: "600" }} >
                                        <StyledTableRow >
                                            <StyledTableCell>Location </StyledTableCell>
                                            <StyledTableCell>Discount ID</StyledTableCell>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Product Name</StyledTableCell>
                                            <StyledTableCell>Category</StyledTableCell>
                                            <StyledTableCell>Schedule</StyledTableCell>
                                            <StyledTableCell>Molecule</StyledTableCell>
                                            <StyledTableCell>Manufacture</StyledTableCell>
                                            <StyledTableCell>Purchase Excluding Tax</StyledTableCell>
                                            <StyledTableCell>Purchase Including Tax</StyledTableCell>
                                            <StyledTableCell>Selling Tax</StyledTableCell>
                                            <StyledTableCell>Discount Type </StyledTableCell>
                                            <StyledTableCell>Discount Amount </StyledTableCell>
                                            <StyledTableCell>Discount Value </StyledTableCell>
                                            <StyledTableCell>Start At </StyledTableCell>
                                            <StyledTableCell>Ends At </StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {discounts.length > 0 &&
                                            (discounts.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell component="th" scope="row">{row.location}</StyledTableCell>
                                                    <StyledTableCell>{row.discountid}</StyledTableCell>
                                                    <StyledTableCell>{row.name}</StyledTableCell>
                                                    <StyledTableCell>{row.products}</StyledTableCell>
                                                    <StyledTableCell>{row.category}</StyledTableCell>
                                                    <StyledTableCell>{row.schedule}</StyledTableCell>
                                                    <StyledTableCell>{row.molecule}</StyledTableCell>
                                                    <StyledTableCell>{row.manufacture}</StyledTableCell>
                                                    <StyledTableCell>{row.purchaseexcludetax}</StyledTableCell>
                                                    <StyledTableCell>{row.purchaseincludetx}</StyledTableCell>
                                                    <StyledTableCell>{row.sellingvalue}</StyledTableCell>
                                                    <StyledTableCell>{row.discounttype}</StyledTableCell>
                                                    <StyledTableCell>{row.discountamt}</StyledTableCell>
                                                    <StyledTableCell>{row.discountvalue}</StyledTableCell>
                                                    <StyledTableCell>{moment(row.startsat).format('DD-MM-YYYY')}</StyledTableCell>
                                                    <StyledTableCell>{moment(row.endsat).format('DD-MM-YYYY')}</StyledTableCell>
                                                </StyledTableRow>
                                            )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                </Box>

                {/* PDF Modal */}
                <Box>
                    <Dialog
                        open={openPdf}
                        onClose={handleClosePdf}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Select Option to Print PDF
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="subtitle1">Choose any 6</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDisclocation} onChange={(e) => { setIsPdfData({ ...isPdfData, isDisclocation: !isPdfData.isDisclocation }) }} />} label="Location" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDiscId} onChange={(e) => setIsPdfData({ ...isPdfData, isDiscId: !isPdfData.isDiscId })} />} label="Discount Id" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDiscname} onChange={(e) => setIsPdfData({ ...isPdfData, isDiscname: !isPdfData.isDiscname })} />} label="Name" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDiscproduct} onChange={(e) => setIsPdfData({ ...isPdfData, isDiscproduct: !isPdfData.isDiscproduct })} />} label="Product Name" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDisccategory} onChange={(e) => setIsPdfData({ ...isPdfData, isDisccategory: !isPdfData.isDisccategory })} />} label="Category" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isPurchaseExtax} onChange={(e) => setIsPdfData({ ...isPdfData, isPurchaseExtax: !isPdfData.isPurchaseExtax })} />} label="Purcahase Exclude Tax" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isPurchaseIntax} onChange={(e) => setIsPdfData({ ...isPdfData, isPurchaseIntax: !isPdfData.isPurchaseIntax })} />} label="Purchase Include Tax" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isSellingExtax} onChange={(e) => setIsPdfData({ ...isPdfData, isSellingExtax: !isPdfData.isSellingExtax })} />} label="Sellig Tax" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDisctype} onChange={(e) => setIsPdfData({ ...isPdfData, isDisctype: !isPdfData.isDisctype })} />} label="Discount Type" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDiscprice} onChange={(e) => setIsPdfData({ ...isPdfData, isDiscprice: !isPdfData.isDiscprice })} />} label="Select Discount Price" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDiscAmount} onChange={(e) => setIsPdfData({ ...isPdfData, isDiscAmount: !isPdfData.isDiscAmount })} />} label="Discount Amount" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isDiscValue} onChange={(e) => setIsPdfData({ ...isPdfData, isDiscValue: !isPdfData.isDiscValue })} />} label="Discount Value" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isStart} onChange={(e) => setIsPdfData({ ...isPdfData, isStart: !isPdfData.isStart })} />} label="StartAt" />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox value={isPdfData.isEnd} onChange={(e) => setIsPdfData({ ...isPdfData, isEnd: !isPdfData.isEnd })} />} label="EndsAt" />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='primary' onClick={() => downloadPdf()} autoFocus>PDF</Button>
                            <Button variant='contained' color='error' onClick={handleClosePdf}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
        </Box>
    );
}
const Discountlist = () => {
    return (
        <>
            <Box>
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Discountlisttable /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}
export default Discountlist;