import React, {useState,useEffect} from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const Headtitle = ({ title }) => {

    const [setngs, setSetngs] = useState();

    // get settings data
    const fetchSettings = async () => {
        try {
          var response = await axios.get(`http://localhost:8003/api/settings`);
          setSetngs(response.data.settingid);
        } catch (err) {
            const messages = err.response.data.message;
        }
        }
        let bnname = setngs ? setngs.businessname: "";

        useEffect(
            () => {
                fetchSettings();
            }, [])

    return (
        <Helmet>
            <title>{`${title} - ${bnname}`}</title>
        </Helmet>
    )
}

export default Headtitle;