import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Grid, Typography, FormControl, InputLabel, OutlinedInput, TextareaAutosize, Dialog, DialogContent, DialogActions } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function Categoryeditlist() {

  const [isCategory, setIsCategory] = useState({});

  const id = useParams().id;

  const { auth } = useContext(AuthContext);

  // Error Popup model
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [showAlert, setShowAlert] = useState()
  const handleOpen = () => {
    setIsErrorOpen(true);
  };
  const handleClose = () => {
    setIsErrorOpen(false);
  };

  // fetch particular id value
  const fetchCategory = async () => {
    try {
      let req = await axios.get(`${SERVICE.CATEGORY}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      setIsCategory(req.data.scategory);
    } catch (err) {
      const messages = err.response.data.errorMessage;
      toast.error(messages);
    }
  }

  let backPage = useNavigate();

  // store edited data to particular id update request
  const sendRequest = async () => {

    try {
      let res = await axios.put(`${SERVICE.CATEGORY}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
        categoryname: String(isCategory.categoryname),
        categorycode: String(isCategory.categorycode),
        categorydescription: String(isCategory.categorydescription),

      });
      setIsCategory(res.data);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
      });
      backPage("/product/category/list");
    } catch (err) {
      const messages = err.response.data.message;
      setShowAlert(messages);
      handleOpen();
    }
  };

  useEffect(
    () => {
      fetchCategory();
    }, []
  )

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isCategory.categoryname == "") {
      setShowAlert("Please enter name!");
      handleOpen();
    } else if (isCategory.categorycode == "") {
      setShowAlert("Please enter code!");
      handleOpen();
    } else {
      sendRequest();
    }
  }

  return (
    <Box>
      <Headtitle title={'Edit Category'} />
      {/* Form Start */}
      <form onSubmit={handleSubmit}>
        <Typography sx={userStyle.HeaderText} variant='body2'>Edit Category</Typography>
        <Box sx={userStyle.container}>
          <Grid container spacing={3} sx={userStyle.textInput}>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel htmlFor="component-outlined">Category name<b style={{ color: "red" }}> *</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  id="component-outlined"
                  value={isCategory.categoryname}
                  onChange={(e) => { setIsCategory({ ...isCategory, categoryname: e.target.value }) }}
                  type="text"
                  name="categoryname"
                />
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel htmlFor="component-outlined">Category Code<b style={{ color: "red" }}> *</b></InputLabel>
              <FormControl size="small" fullWidth>
                <OutlinedInput
                  id="component-outlined"
                  value={isCategory.categorycode}
                  onChange={(e) => { setIsCategory({ ...isCategory, categorycode: e.target.value }) }}
                  type="text"
                  name="categorycode"
                />
              </FormControl>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel id="demo-select-small">Description</InputLabel>
              <FormControl size="small" fullWidth >
                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                  value={isCategory.categorydescription}
                  onChange={(e) => { setIsCategory({ ...isCategory, categorydescription: e.target.value }) }}
                  name="categorydescription"
                />
              </FormControl>
            </Grid>
          </Grid><br />
          <Grid container sx={{ marginTop: '20px', marginBottom: '20px', justifyContent: 'center' }}>
            <Button sx={userStyle.buttonadd} type="submit" onClick={handleSubmit} autoFocus>UPDATE</Button>
            <Link to="/product/category/list"><Button sx={userStyle.buttoncancel}>CANCEL</Button></Link>
          </Grid>
        </Box>
      </form>
      {/* Form End */}
      {/* ALERT DIALOG */}
      <Dialog
        open={isErrorOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h6" >{showAlert}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
const Categoryedit = () => {
  return (
    <>
      <Box>
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Categoryeditlist /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default Categoryedit;