import React, { useState, useEffect, useRef, useContext, createRef } from "react";
import { Box, Button, Typography,DialogContent, Paper, TableHead, Table, TableContainer, TableBody, Dialog, TableFooter, DialogActions, Grid} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import "rsuite/dist/rsuite.css";
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import Headtitle from '../../../components/header/Headtitle';
import moment from 'moment';
import axios from 'axios';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { toast } from 'react-toastify';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print"
import { ExportXL, ExportCSV } from '../../Export';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import autoTable from 'jspdf-autotable';
import jsPDF from "jspdf";

const Purchasetablelist = () => {
    const [purchases, setPurchases] = useState();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [deletepur, setDeletepur] = useState({});
    const {isUserRoleCompare} = useContext(UserRoleAccessContext);

    const { auth } = useContext(AuthContext);

    const [ exceldata , setExceldata]= useState([])

    //  get particular columns for export excel
    const getexcelDatas = async () => {
        let data = purchases.map(t => ({
            Supplier: t.supplier, 'Reference Number' : t.referenceno , 'Purchase Date': t.purchasedate, 'Bill Amount': t.billamount, 'Business Location': t.businesslocation, 'Invoice No': t.invoiceno,
            Balance: t.balance, Address: t.addressone, 'Due Date': t.duedate, 'Total Items': t.totalcnt, 'Total Quantity': t.totalitem, 'Total Amount': t.beforediscounttotal, 'Discount Type': t.granddistype,
            'Discount Value': t.gdiscountvalue, 'Discount Amount': t.discountamt, 'After Disc Total Amount': t.billamount, CGST:t.cgst, GST: t.gst, IGST: t.igst,'Pay Due': t.paydue, 'Purchase Status': t.purchasestatus, 'Sub Cost': t.subcost, 'Added By': t.userbyadd
        }));
        setExceldata(data);
    }

    // ***** Jquery Plugin ****//
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // table sort/filter search data
    useEffect (() => {
        $(document).ready(function() {
          $.fn.dataTable.ext.errMode = 'none';
          setTimeout(function () {
          $(tableRef.current).DataTable({
              language: { search: '', searchPlaceholder: "Search..." },
              lengthMenu: [
                    [10, 1, 25, 50, 100, 200, 500, -1],
                    [10, 1, 25, 50, 100, 200, 500, 'All'],
                ],
                    });
        }, 1000);
        } );
        },[])

    // Purchase 
    const fetchPurchase = async () => {
        try {
            let purchase = await axios.get(SERVICE.PURCHASE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPurchases(purchase.data.purchases);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };
    let fileName= "purchases"
    let total = 0;
    let sum = 0;
    let btnval;

     //set function to get particular row
     const rowDataDel = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.PURCHASE_SINGLE}/${id}`, {
                headers: {
                'Authorization': `Bearer ${auth.APIToken}`
                },
            });
            setDeletepur(res.data.spurchse);
        } catch (err) {
            const messages = err.response.data.message;
        toast.error(messages);
        }
    }
    // Alert delete popup
    let supid = deletepur._id;
   
    const deletePurchase = async () => {
        try {
            let res = await axios.delete(`${SERVICE.PURCHASE_SINGLE}/${supid}`,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch(err){
            const messages = err.response.data.message;
           toast.error(messages);
        }
    };

   //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#purchasetablepdf' })
        doc.save('Purchase.pdf')
    }
    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Purchase',
        pageStyle: 'print'
    });
    const ref = createRef();
    const options = {
        orientation: 'portrait',
        unit: 'in'
      };
     // Delete model
     const handleClickOpen = () => {
         setIsDeleteOpen(true);
     };
 
     const handleClose = () => {
         setIsDeleteOpen(false);
     };

    useEffect(
        () => {
            fetchPurchase();
        }, [deletePurchase]);

    useEffect(
        ()=>{
            getexcelDatas();
        },[purchases]
    )

    return (
        <>
            <Box>
                <Headtitle title={'Purchases'} />
                <Typography sx={userStyle.HeaderText}>Purchases</Typography>
            </Box>
            {/* //table----------------------------------------------- */}
            <Box sx={userStyle.container}>
                { /* Table header  */}
                <Grid container >
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <Typography sx={userStyle.importheadtext}>All your Purchases</Typography>
                    </Grid> 
                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{ textAlign: 'right !important' }}>
                        {isUserRoleCompare[0].apurchase && (
                            <>
                                <Link to="/purchase/purchase/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                            </>
                        )}
                    </Grid><br />
                    <Grid container sx={{ justifyContent: "center", }}>
                        {isUserRoleCompare[0].csvpurchase && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelpurchase && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printpurchase && (
                            <>
                        <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfpurchase && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid> 
                { /* Table start */}
                <Box>
                    <TableContainer component={Paper} >
                        <Table aria-label="customized table" id="purchasetable" ref={tableRef}>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell> Action </StyledTableCell>
                                    <StyledTableCell> Date</StyledTableCell>
                                    <StyledTableCell> Reference No</StyledTableCell>
                                    <StyledTableCell> Location </StyledTableCell>
                                    <StyledTableCell> Supplier </StyledTableCell>
                                    <StyledTableCell> Invoice No</StyledTableCell>
                                    <StyledTableCell> Purchase Status</StyledTableCell>
                                    <StyledTableCell> Payment status</StyledTableCell>
                                    <StyledTableCell> Grand Total</StyledTableCell>
                                    <StyledTableCell> Payment Due</StyledTableCell>
                                    <StyledTableCell> Added By</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {purchases && (
                                    purchases.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>
                                                <Grid sx={{ display: 'flex' }}>
                                                {isUserRoleCompare[0].vpurchase && (
                                                    <>
                                                        <Link to={`/purchase/purchase/view/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonview} style={{ minWidth: '0px' }}><VisibilityOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                    </>
                                                )} 
                                                {isUserRoleCompare[0].epurchase && (
                                                    <>
                                                        <Link to={`/purchase/purchase/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit} style={{ minWidth: '0px' }}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>
                                                    </>
                                                )} 
                                                {isUserRoleCompare[0].dpurchase && (
                                                    <>
                                                        <Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowDataDel(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                                                    </>
                                                )} 
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell>{moment(row.purchasedate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell>{row.referenceno}</StyledTableCell>
                                            <StyledTableCell>{row.businesslocation}</StyledTableCell>
                                            <StyledTableCell>{row.supplier}</StyledTableCell>
                                            <StyledTableCell>{row.invoiceno}</StyledTableCell>
                                            <StyledTableCell>{row.purchasestatus}</StyledTableCell>
                                            <StyledTableCell>{row.paymentmethod}</StyledTableCell>
                                            <StyledTableCell>₹ {row.billamount}</StyledTableCell>
                                            <StyledTableCell>₹ {row.paydue}</StyledTableCell>
                                            <StyledTableCell>{row.userbyadd}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                            <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
                                <StyledTableRow className="table2_total" >
                                    {purchases && (
                                        purchases.forEach(
                                            (item => {
                                                total += +item.paydue;
                                                sum += +item.billamount;
                                            })
                                        ))}
                                    <StyledTableCell colSpan={6} sx={userStyle.footerStyle}>Total</StyledTableCell>
                                    <StyledTableCell align="left">{btnval}</StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                    <StyledTableCell align="left" sx={userStyle.footerStyle}>₹ {+sum}</StyledTableCell>
                                    <StyledTableCell align="left" sx={userStyle.footerStyle}>Purchase Due - ₹ {+total} </StyledTableCell>
                                    <StyledTableCell align="left"></StyledTableCell>
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
                { /* Table end */}
            </Box>
            <Box sx={userStyle.printcls}>
                <TableContainer component={Paper} >
                    <Table aria-label="customized table" id="purchasetablepdf" ref={componentRef}>
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell> Date</StyledTableCell>
                                <StyledTableCell> Reference No</StyledTableCell>
                                <StyledTableCell> Location </StyledTableCell>
                                <StyledTableCell> Supplier </StyledTableCell>
                                <StyledTableCell> Invoice No</StyledTableCell>
                                <StyledTableCell> Purchase Status</StyledTableCell>
                                <StyledTableCell> Payment status</StyledTableCell>
                                <StyledTableCell> Grand Total</StyledTableCell>
                                <StyledTableCell> Payment Due</StyledTableCell>
                                <StyledTableCell> Added By</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {purchases && (
                                purchases.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{moment(row.purchasedate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                                        <StyledTableCell>{row.referenceno}</StyledTableCell>
                                        <StyledTableCell>{row.businesslocation}</StyledTableCell>
                                        <StyledTableCell>{row.supplier}</StyledTableCell>
                                        <StyledTableCell>{row.invoiceno}</StyledTableCell>
                                        <StyledTableCell>{row.purchasestatus}</StyledTableCell>
                                        <StyledTableCell>{row.paymentmethod}</StyledTableCell>
                                        <StyledTableCell>₹ {row.billamount}</StyledTableCell>
                                        <StyledTableCell>₹ {row.paydue}</StyledTableCell>
                                        <StyledTableCell>{row.userbyadd}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                        <TableFooter sx={{ backgroundColor: '#9591914f', height: '75px' }}>
                            <StyledTableRow className="table2_total" >
                                {purchases && (
                                    purchases.forEach(
                                        (item => {
                                            total += +item.paydue;
                                            sum += +item.billamount;
                                        })
                                    ))}
                                <StyledTableCell colSpan={6} sx={userStyle.footerStyle}>Total</StyledTableCell>
                                <StyledTableCell align="left">{btnval}</StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                                <StyledTableCell align="left" sx={userStyle.footerStyle}>₹ {+sum}</StyledTableCell>
                                <StyledTableCell align="left" sx={userStyle.footerStyle}>Purchase Due - ₹ {+total} </StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            { /* Table end */}
            <Box>
                {/* ALERT DIALOG */}
                <Dialog
                    open={isDeleteOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined">Cancel</Button>
                        <Button autoFocus variant="contained" color='error' onClick={(e) => deletePurchase(supid)}> OK </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}

function Purchaselists() {
    return (
        <>
            <Box>
                <Navbar />
                <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                    <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                        <Purchasetablelist /><br /><br /><br />
                        <Footer />
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Purchaselists;