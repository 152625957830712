import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import axios from 'axios';
import autoTable from 'jspdf-autotable';
import { useReactToPrint } from "react-to-print";
import { toast } from 'react-toastify';
import $ from 'jquery';
import { SERVICE } from "../../services/Baseservice";
import { AuthContext, UserRoleAccessContext } from '../../context/Appcontext';

function Nillstockllist() {

  //  Access
  const { auth } = useContext(AuthContext);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  const [isProducts, setIsProducts] = useState([]);
  const [exceldata, setExceldata] = useState([]);

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  //fetch all products
  const fetchProducts = async () => {
    try {
        let res_products = await axios.get(SERVICE.PRODUCT,{
          headers: {
              'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        let resdata = res_products.data.products.filter((data, index)=>{
            if(data.currentstock == 0){
              return data
            }
        })
        setIsProducts(resdata);
    } catch (err) {
        const messages = err.response.data.message;
        toast.error(messages);
    }
  };

  useEffect(
    ()=>{
      fetchProducts();
    },[]
  )

    // Export Excel
    const fileName = 'Nill Stock Report'

    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = isProducts.map(t=>({ "Item Code":t.prodcode,"Item Name": t.prodname, }));
        setExceldata(data);
    }

    useEffect(() => {
        getexcelDatas()
    }, [isProducts])

    // PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#nillstockreport' })
        doc.save('Nill Stock Report.pdf')
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | NILL STOCK REPORT',
        pageStyle: 'print'
    });

  return (

      <Box>
        <Headtitle title={'Nill Stock Report'} />
        <Typography sx={userStyle.HeaderText}>Nill Stock Report</Typography>
        {/* content start */}
        <Box sx={userStyle.container}>
          { /* ******************************************************EXPORT Buttons****************************************************** */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvminimumwisestockreport && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )} 
              {isUserRoleCompare[0].excelminimumwisestockreport && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
               )}
              {isUserRoleCompare[0].printminimumwisestockreport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfminimumwisestockreport && ( 
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
               )} 
            </Grid>
          </Grid><br />
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700 }} aria-label="customized table" id="nillstockreport" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell >Item Code</StyledTableCell>
                    <StyledTableCell >Item Name</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {isProducts.length > 0 ? (
                    isProducts.map((row, index)=>(
                      <>
                        <StyledTableRow key={index}>
                          <StyledTableCell>{row.prodcode}</StyledTableCell>
                          <StyledTableCell>{row.prodname}</StyledTableCell>
                        </StyledTableRow>
                      </>
                    ))
                  ) : (
                    <>
                      <StyledTableRow>
                        <StyledTableCell colSpan={2}>No data record found!</StyledTableCell>
                      </StyledTableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        {/* Print Layout */}
        <TableContainer component={Paper} sx={userStyle.printcls}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" id="nillstockreport" ref={componentRef}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell >Item Code</StyledTableCell>
                <StyledTableCell >Item Name</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody align="left">
              {isProducts.length > 0 ? (
                isProducts.map((row, index)=>(
                  <>
                    <StyledTableRow key={index}>
                      <StyledTableCell>{row.prodcode}</StyledTableCell>
                      <StyledTableCell>{row.prodname}</StyledTableCell>
                    </StyledTableRow>
                  </>
                ))
              ) : (
                <>
                  <StyledTableRow>
                    <StyledTableCell colSpan={2}>No data record found!</StyledTableCell>
                  </StyledTableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
  );
}

function Nillstock() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Nillstockllist /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Nillstock;