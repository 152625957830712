import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import moment from "moment";
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

function Retailfollowcustomer() {

    const [pos, setPos] = useState({});
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    //  Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    
    const [dateFilter, setDateFilter] = useState({
        startdate: today
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    const fetchPos = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            let result = req.data.pos1.filter((data) => {
                return data.activate == true
            })
            let getDatawithFilter = result.filter((data) => {
                if (dateFilter.startdate == "") {
                    return data
                }
                else {
                    return moment(data.date).format('YYYY-MM-DD') == dateFilter.startdate
                }
            })
            setPos(getDatawithFilter)

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    useEffect(() => {
        fetchPos();
    }, [])

    // Export Excel
    const fileName = 'Retail Follow Customer';
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = pos.map(t => ({
            "Name": t.customer, "Mobile No": t.contactnumber, "Area": t.address, "Diagnosis": t.diagnosis, "Follow Bill No": t.referenceno, "Bill Date": moment(t.date).format("DD-MM-YYYY")
        }));
        setExceldata(data);
    }

    useEffect(
        () => {
            getexcelDatas();
        }, [pos]
    )

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | RETAIL FOLLOW CUSTOMER',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#retailfollowcustomer' })
        doc.save('retailfollowcustomer.pdf')
    }

    return (
        <Box >
            <Headtitle title={'Retail Follow customer'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Retail Follow Customer List</Typography>
            <Box sx={userStyle.container}>
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={2} md={2}></Grid>
                    <Grid item lg={6} md={6}>
                        <Grid container >
                            <Grid item lg={1} md={1}>
                                <Typography sx={{ marginTop: '7px' }}>Date</Typography>
                            </Grid>
                            <Grid item lg={10} md={10} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.startdate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="startdate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={3}>
                        <Button variant='contained' color='secondary' onClick={fetchPos}>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].csvretailfollowupcustomer && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelretailfollowupcustomer && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printretailfollowupcustomer && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfretailfollowupcustomer && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="retailfollowcustomer" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Mobile No</StyledTableCell>
                                    <StyledTableCell>Area</StyledTableCell>
                                    <StyledTableCell>Diagnosis</StyledTableCell>
                                    <StyledTableCell>Follow Bill NO</StyledTableCell>
                                    <StyledTableCell>Bill Date</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {pos.length > 0 && (
                                    pos.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                            <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.address}</StyledTableCell >
                                            <StyledTableCell align="left">{row.diagnosis}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{moment(row.date).format("DD-MM-YYYY")}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>

            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Retail Followup Customer</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="retailfollowcustomer">
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Mobile No</StyledTableCell>
                                        <StyledTableCell>Area</StyledTableCell>
                                        <StyledTableCell>Diagnosis</StyledTableCell>
                                        <StyledTableCell>Follow Bill NO</StyledTableCell>
                                        <StyledTableCell>Bill Date</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {pos.length > 0 && (
                                        pos.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.customer}</StyledTableCell>
                                                <StyledTableCell align="left">{row.contactnumber}</StyledTableCell>
                                                <StyledTableCell align="left">{row.address}</StyledTableCell >
                                                <StyledTableCell align="left">{row.diagnosis}</StyledTableCell>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{moment(row.date).format("DD-MM-YYYY")}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}
function Retailfollowcustomerlist() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Retailfollowcustomer /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Retailfollowcustomerlist;