import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import Selects from "react-select";


function Referencewisepaymentreportlist() {

    const [customerpayments, setCustomerpayments] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const [customerRef, setCustomerRef] = useState();
    const { auth } = useContext(AuthContext);

    //  Datefield
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;

    const [dateFilter, setDateFilter] = useState({
        startdate: today, enddate: today, referenceno: ""
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Selects style.......
    const colourStyles = {
        menuList: styles => ({
            ...styles,
            background: 'white'
        }),
        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            // color:'black',
            color: isFocused
                ? 'rgb(255 255 255, 0.5)'
                : isSelected
                    ? 'white'
                    : 'black',
            background: isFocused
                ? 'rgb(25 118 210, 0.7)'
                : isSelected
                    ? 'rgb(25 118 210, 0.5)'
                    : undefined,
            zIndex: 1
        }),
        menu: base => ({
            ...base,
            zIndex: 100
        })
    }

    // Fetch remark for dropdown .............
    const fetchReference = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomerRef(
                req?.data?.customerpays?.map((d) => ({
                    ...d,
                    label: d.referenceno,
                    value: d.referenceno,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // customername handlechange
    const handleremarkNo = (newValue, actionMeta) => {
        setDateFilter({ ...dateFilter, referenceno: newValue.value })
    };
    const handleInputremarkNo = (inputValue, actionMeta) => {
    };

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()


    // customerpayments
    const fetchCustomerpayments = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_PAYMENT, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getDatawithFilter = req.data.customerpays.filter((data) => {
                if (dateFilter.startdate == "" && dateFilter.enddate == "" && dateFilter.referenceno == "") {
                    return data
                }
                else if (data.referenceno == dateFilter.referenceno && dateFilter.startdate == "" && dateFilter.enddate == "") {
                    return data
                }
                else if (dateFilter.startdate <= data.date && dateFilter.enddate + 1 >= data.date && dateFilter.referenceno == "") {
                    return data
                }
                else if (dateFilter.startdate <= data.date && dateFilter.enddate == "" && dateFilter.referenceno == "") {
                    return data
                }
                else if (dateFilter.startdate == "" && dateFilter.enddate >= data.date && dateFilter.referenceno == "") {
                    return data
                }
                else if (dateFilter.startdate <= data.date && dateFilter.enddate + 1 >= data.date && data.referenceno == dateFilter.referenceno) {
                    return data
                }

            })
            setCustomerpayments(getDatawithFilter);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Reference Wise Payments Report'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = customerpayments.map(t => ({
            referenceno: t.referenceno,
            paiddate: t.date,
            name: t.name,
            totalamount: t.totalamount,
            returnsdiscount: t.discounts,
            netpaidamount: t.amountpaid,
            pay: t.netpaidamount,
            paymode: t.paymode,
            remarks: t.remarks,
        }));
        setExceldata(data);
    }
    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'ReferenceWisePaymentsReport',
        pageStyle: 'print'
    });


    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#ReferenceWisePaymentsReport' })
        doc.save('ReferenceWisePaymentsReport.pdf')
    }

    useEffect(
        () => {
            fetchReference();
            fetchCustomerpayments()
        }, []
    )
    useEffect(
        () => {
            getexcelDatas();
        }, [customerpayments]
    )

    return (

        <Box >
            <Headtitle title={'Reference Wise Payments Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Reference Wise Payments Report</Typography>
            <Box sx={userStyle.filtercontent} >
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={3} md={3}>
                        <Grid container>
                            <Grid item lg={4} md={4} sx={{ marginTop: 1 }}>
                                <Typography >From</Typography>
                            </Grid>
                            <Grid item lg={8} md={8} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.startdate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="startdate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={3}>
                        <Grid container>
                            <Grid item lg={1} md={1}>
                                <Typography sx={{ marginTop: 1 }}>To</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <TextField
                                        id="component-outlined"
                                        value={dateFilter.enddate}
                                        onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                                        type="date"
                                        size="small"
                                        name="enddate"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4} sx={{ display: 'flex' }}>
                        <Grid item lg={3} md={3} sx={{ marginTop: 1 }}>
                            <Typography>Reference:</Typography>
                        </Grid>
                        <Grid item lg={8} md={8}>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    isClearable
                                    onChange={handleremarkNo}
                                    styles={colourStyles}
                                    onInputChange={handleInputremarkNo}
                                    options={customerRef}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item lg={1} md={1}>
                        <Button variant='contained' color='secondary' onClick={fetchCustomerpayments}>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br /><br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >

                        <Grid >
                            {isUserRoleCompare[0].csvreferencewisepaymentreport && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].excelreferencewisepaymentreport && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].printreferencewisepaymentreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].pdfreferencewisepaymentreport && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="ReferenceWisePaymentsReport" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Paid Date</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Total Amount</StyledTableCell>
                                    <StyledTableCell>Returns & Discount</StyledTableCell>
                                    <StyledTableCell>Net Paid Amount</StyledTableCell>
                                    <StyledTableCell>Pay</StyledTableCell>
                                    <StyledTableCell>Pay Mode</StyledTableCell>
                                    <StyledTableCell>Remarks</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customerpayments.length > 0 && (
                                    customerpayments.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.date}</StyledTableCell >
                                            <StyledTableCell align="left">{row.name}</StyledTableCell >
                                            <StyledTableCell align="left">{row.totalamount}</StyledTableCell>
                                            <StyledTableCell align="left">{row.discounts}</StyledTableCell>
                                            <StyledTableCell align="left">{row.amountpaid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.netpaidamount}</StyledTableCell>
                                            <StyledTableCell align="left">{row.paymode}</StyledTableCell>
                                            <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Reference wise payment report list</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} sx={userStyle.printcls}>
                            <Table aria-label="simple table" id="ReferenceWisePaymentsReport">
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Reference No</StyledTableCell>
                                        <StyledTableCell>Paid Date</StyledTableCell>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>Total Amount</StyledTableCell>
                                        <StyledTableCell>Returns & Discount</StyledTableCell>
                                        <StyledTableCell>Net Paid Amount</StyledTableCell>
                                        <StyledTableCell>Pay</StyledTableCell>
                                        <StyledTableCell>Pay Mode</StyledTableCell>
                                        <StyledTableCell>Remarks</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {customerpayments.length > 0 && (
                                        customerpayments.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left">{row.date}</StyledTableCell >
                                                <StyledTableCell align="left">{row.name}</StyledTableCell >
                                                <StyledTableCell align="left">{row.totalamount}</StyledTableCell>
                                                <StyledTableCell align="left">{row.discounts}</StyledTableCell>
                                                <StyledTableCell align="left">{row.amountpaid}</StyledTableCell>
                                                <StyledTableCell align="left">{row.netpaidamount}</StyledTableCell>
                                                <StyledTableCell align="left">{row.paymode}</StyledTableCell>
                                                <StyledTableCell align="left">{row.remarks}</StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function Referencewisepaymentreport() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Referencewisepaymentreportlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Referencewisepaymentreport;