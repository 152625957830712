import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import axios from 'axios';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../../Export';
import { toast } from 'react-toastify';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Headtitle from '../../../components/header/Headtitle';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from 'react-to-print';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';

function Unitlisttable() {

  const [units, setUnits] = useState();

  //  Excel data
  const [exceldata, setExceldata] = useState([]);

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //delete modal
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [unitdel, setUnitDel] = useState({});

  const handleOpen = () => {
    setIsDeleteOpen(true);
  };
  const handleClose = () => {
    setIsDeleteOpen(false);
  };

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  //  Fetch Unit Data
  const fetchUnits = async () => {
    try {
      let res = await axios.get(SERVICE.UNIT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setUnits(res.data.unit);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  
  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = 'none';
      setTimeout(function () {
        $(tableRef.current).DataTable({
          language: { search: '', searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 1, 25, 50, 100, 200, 500, -1],
            [10, 1, 25, 50, 100, 200, 500, 'All'],
          ],
        });
      }, 1000);
    });
  }, [(tableRef.current)])

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let res = await axios.get(SERVICE.UNIT, {
      headers: {
        'Authorization': `Bearer ${auth.APIToken}`
      }
    });
    var data = res.data.unit.map(t => ({
      unit: t.unit, shortname: t.shortname
    }));
    setExceldata(data);
  }

  //alert delete popup
  const rowData = async (id) => {
    try {
      let res = await axios.get(`${SERVICE.UNIT_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      setUnitDel(res.data.sunit);//set function to get particular row
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  //alert delete popup
  let unitid = unitdel._id;
  const deleteUnits = async (unitid) => {
    try {
      let res = await axios.delete(`${SERVICE.UNIT_SINGLE}/${unitid}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      handleClose();
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(
    () => {
      getexcelDatas();
      fetchUnits();
    }, [deleteUnits]
  );

  // Export Excel
  const fileName = 'Units'

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: ' HIPOS | UNITS',
    pageStyle: 'print'
  });

  const downloadPdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: '#unittable' })
    doc.save('Units.pdf')
  }

  return (
    <Box>
      <Headtitle title={'Units'} />
      {/* header text */}
      <Typography sx={userStyle.HeaderText} variant='body2'>Units<Typography sx={userStyle.SubHeaderText} component="span" variant='body2'>Manage your units</Typography></Typography>
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}><Typography sx={userStyle.importheadtext} variant='body2'>All Your Units</Typography></Grid>
          <Grid item xs={4}>
            {isUserRoleCompare[0].aunit && (
              <>
                <Link to="/product/unit/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd} >ADD</Button></Link>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvunit && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelunit && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printunit && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
              </>
            )}
            {isUserRoleCompare[0].pdfunit && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
              </>
            )}
          </Grid>
        </Grid><br />
        <Box>
          {/* Table Start */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Actions</StyledTableCell>
                  <StyledTableCell>Unit </StyledTableCell>
                  <StyledTableCell >Short Name</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {units &&
                  (units.map((item, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        {isUserRoleCompare[0].eunit && (
                          <>
                            <Link to={`/product/unit/edit/${item._id}`} style={{ textDecoration: 'none', color: '#fff' }}><Button sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: "large" }} /></Button></Link>

                          </>
                        )}
                        {isUserRoleCompare[0].dunit && (
                          <>
                            <Button sx={userStyle.buttondelete} onClick={(e) => { handleOpen(); rowData(item._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                          </>
                        )}

                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">{item.unit}</StyledTableCell>
                      <StyledTableCell>{item.shortname}</StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Table End */}
        </Box>
      </Box>
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" id="unittable" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Unit </StyledTableCell>
              <StyledTableCell >Short Name</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {units &&
              (units.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">{row.unit}</StyledTableCell>
                  <StyledTableCell>{row.shortname}</StyledTableCell>
                </StyledTableRow>
              ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
      { /* content end */}
      {/* ALERT DIALOG */}
      <Dialog
        open={isDeleteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
          <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
          <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
          <Button onClick={(e) => deleteUnits(unitid)} autoFocus variant="contained" color='error'> OK </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function Unitlist() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Unitlisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Unitlist;