import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, InputLabel, OutlinedInput, Select, MenuItem, TextField, FormControl, FormControlLabel, Grid, Checkbox, Paper, Table, TableBody, TableHead, TableContainer, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import moment from "moment";
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';

function Daybookprintlist() {

    const [customerbill,setCustomerBill]=useState([]);
    const [exceldata, setExceldata] = useState([]);
    
    const { auth, setAuth } = useContext(AuthContext);


    // Datefield
    const [dateFilter, setDateFilter] = useState({
        startdate:"", enddate:"",
    })

    // Customer bills
    const fetchCustomerbills = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_BILL , {
                headers: {
                    'Authorization':`Bearer ${auth.APIToken}`
                    }

            });
            let getDatawithFilter = req.data.customerbills.filter((data) => {
                let startdatefromsupplier = dateFilter.startdate;
                let enddatefromsupplier = dateFilter.enddate;
                if(dateFilter.startdate ==" " && dateFilter.enddate == " ") {
                     return data
                 } else {
                    return startdatefromsupplier <= data.billdate && enddatefromsupplier + 1 >= data.billdate

                 }
            })  
            setAuth({ ...auth, loginState:true });
            setCustomerBill(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRefCusbill.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 25, 50, 100, 200, 500, -1],
                            [10, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } 
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };


    // Excel
    const fileName = 'Daybookprint'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        let res = await axios.get('http://localhost:8003/api/customerbills');
        var data = res.data.customerbills.map(t => ({
            referenceno: t.referenceno, name: t.name , billno: t.billno, billdate: t.billdate , duedate: t.duedate , billamount: t.billamount,  narration: t.narration, }));
        setExceldata(data);
    }

    //  PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Daybookprint' })
        doc.save('Daybookprint.pdf')
    }

    useEffect(
        () => {
            getexcelDatas();
            fetchCustomerbills();      
        },[]
    )

    let total = 0;

    //jquery
  $.DataTable = require("datatables.net");
  const tableRefCusbill = useRef();
  useEffect(() => {
    $(document).ready(function () {
      $.fn.dataTable.ext.errMode = "none";
      setTimeout(function () {
        console.log(tableRefCusbill.current);
        $(tableRefCusbill.current).DataTable({
          language: { search: "", searchPlaceholder: "Search..." },
          lengthMenu: [
            [10, 25, 50, 100, 200, 500, -1],
            [10, 25, 50, 100, 200, 500, "All"],
          ],
        });
      }, 1000);
    });
  }, [tableRefCusbill.current]);

  
    return (
        <Box >
            <Headtitle title={'Day Book Printing'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Day Book Printing</Typography>
            <br />
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "end" }} >
                        <Grid>
                            <Typography sx={{ marginTop: 1 }}>Opening Balance on 11/01/2023 : 30000.00</Typography>
                         </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="Daybookprint" 
                        ref={tableRefCusbill}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Particulars</StyledTableCell>
                                    <StyledTableCell>Debit</StyledTableCell>
                                    <StyledTableCell>Credit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {customerbill.length > 0 && (
                                    customerbill?.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.date}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.particulars}</StyledTableCell >
                                            <StyledTableCell align="left">{row.debit}</StyledTableCell>
                                            <StyledTableCell align="left">{row.credit}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer><br></br>

                    { /* ****** Table End ****** */}
                    <Grid container sx={{ justifyContent: "end" }} >
                        <Grid>
                            <Typography sx={{ marginTop: 1 }}>Closing Balance on 11/01/2023 : 20000.00</Typography>
                         </Grid>
                    </Grid>
                </Box>
            </>

            
        </Box>
    );
}

function Daybookprint() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Daybookprintlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Daybookprint;