import React from "react";
import { Box, Paper, Typography, Table, TableBody, TableContainer, TableHead, } from "@mui/material";

import { StyledTableRow, StyledTableCell } from "../components/Table";

function Dashtopselling() {

  const getcmonth = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const valnmonth = monthNames[getcmonth.getMonth()];


  //  JQUERY
  // $(document).ready(function () {
  //     setTimeout(function () {
  //         $('#expcattable').DataTable({
  //             language: { search: '', searchPlaceholder: "Search..." },
  //             lengthMenu: [25, 50, 100, 200, 500, 1000],
  //         });
  //     }, 1000);
  // });

  return (
    <Box>
      <Box sx={{margin: '10px'}}>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 700 }}
            aria-label="customized table"
            id="expcattable"
          >
            <TableHead>
            <Typography variant='h6'>Top Selling Products ({valnmonth})</Typography>
              <StyledTableRow>
                <StyledTableCell align="left">Product</StyledTableCell>
                <StyledTableCell align="left">Quantity</StyledTableCell>
                <StyledTableCell align="left">Grand Total</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
                <StyledTableCell align="left">test</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}


export default Dashtopselling;
