import React, { useState, useEffect, useContext } from 'react';
import { Button, OutlinedInput, TextareaAutosize, Typography, Grid,  FormControl, Box, } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import {  useParams, Link } from 'react-router-dom';
import { userStyle,colourStyles } from '../../PageStyle';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { AuthContext } from '../../../context/Appcontext';
import { SERVICE } from '../../../services/Baseservice';
import moment from 'moment';

function Voucherviewlist() {

    const { auth } = useContext(AuthContext);

    //  Add Text Field
    const [voucher, setVoucher] = useState({
        voucherno: "", date: "", fromto: "", forwhat: "", amount: "", paymode: "", accountgroup: "", entry: "", remarks: "",
    });


    const id = useParams().id;

    // Fetch Voucher
    const fetchVoucher = async () => {
        try {
            let req = await axios.get(`${SERVICE.VOUCHER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setVoucher(req.data.svoucher);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchVoucher();
    }, [id])
   
    return (
        <Box>
            <Headtitle title={' View Voucher Entry'} />
            <Typography sx={userStyle.HeaderText}>View Voucher Entry</Typography>
            <Box sx={userStyle.container}>
                <form>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Voucher No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={voucher.voucherno}
                                        />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} lg={4} xs={12} sx={{ display: 'flex' }}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Date</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl variant="outlined" size="small" fullWidth>
                            <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={moment(voucher.date).utc().format('DD-MM-YYYY')}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>From / To <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={voucher.fromto}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>For what ? <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12} sx={{ display: 'flex' }}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={voucher.forwhat}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Amount <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={voucher.amount}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Pay mode</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={voucher.paymode}
                                >
                                </OutlinedInput>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Account Group <b style={{ color: "red" }}>*</b></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        value={voucher.accountgroup}
                                        styles={colourStyles}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={4} xs={12}>
                        </Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Entry</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={voucher.entry}
                                >
                                </OutlinedInput>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Remarks</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <TextareaAutosize aria-label="minimum height" minRows={3} style={{ border: '1px solid #b97df0' }}
                                    value={voucher.remarks}
                                    label="remarks"
                                    name='remarks'
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={12}></Grid>
                        <br />
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Link to="/account/voucher/list"><Button sx={userStyle.buttoncancel}>Back</Button></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </Box>
    );
}

function Voucherview() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Voucherviewlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Voucherview;