import React, { useState, useEffect, useRef, useContext } from "react";
import { userStyle, colourStyles } from "../../PageStyle";
import { Box, Grid, FormControl, OutlinedInput, Paper, TextField, Typography, Button, Table, TableContainer, TableHead, TableBody, DialogActions, DialogContent, Dialog, DialogContentText, DialogTitle } from "@mui/material";
import Selects from "react-select";
import axios from "axios";
import $ from "jquery";
import { toast } from 'react-toastify';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from "../../../services/Baseservice";
import { AuthContext } from '../../../context/Appcontext';
import { UserRoleAccessContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

const Schedule = () => {

    const [allDrugs, setAllDrugs] = useState([]);
    const [allCustomer, setAllCustomer] = useState();
    const [customer, setCustomer] = useState({});
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    const [customerDrug, setCustomerDrug] = useState({
        customerId: "", name: "", itemcode: "", iname: ""
    });

    const [setngs, setSetngs] = useState();

    // Autogenerate
    let newval = setngs ? setngs.customerdrugentrysku + "0001" : "CDE0001";

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    // Drud add model
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    // Popup model
    const [showAlert, setShowAlert] = useState()

    // Get all customer drugs
    const fetchDrug = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_DRUG, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAllDrugs(req.data.customerdrugs);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Fetch Customer id
    const fetchcustomername = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setAllCustomer(
                req?.data?.customers?.map((d) => ({
                    ...d,
                    label: d.name,
                    value: d.name,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Add function
    const addCustomerDrug = async () => {
        try {
            let req = await axios.post(SERVICE.CUSTOMER_DRUG_CREATE, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                cusid: String(newval),
                name: String(customerDrug.name),
                itemcode: String(customerDrug.itemcode),
                iname: String(customerDrug.iname),
            });
            setCustomerDrug(req.data)
            handleCloseModal();
            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Select particular customer id to get customer name
    const searchCus = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.CUSTOMER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomer(res.data.scustomer);
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);

        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (customerDrug.name == "") {
            setShowAlert("Please enter Item Customer name!")
           
        }else if (customerDrug.itemcode == "") {
            setShowAlert("Please enter Item code!")
           
        }else if (customerDrug.iname == "") {
            setShowAlert("Please enter Item name!")
           
        } else {
            setShowAlert("");
            addCustomerDrug();
        }
    }

    // Excel
    const fileName = 'Customer Drugs Entry'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = allDrugs.map((t) => ({
            "Customer ID": t.cusid,
            "Customer Name": t.name,
            "Item Code": t.itemcode,
            "Item Name": t.iname,
        }));
        setExceldata(data);
    };

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CUSTOMER DRUG LIST',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Customerbillsreport' })
        doc.save('Customerbillsreport.pdf')
    }

    useEffect(() => {
        fetchDrug();
    },[openModal])

    useEffect(() => {
        fetchcustomername();
    },[])

    useEffect(() => {
        getexcelDatas();
    },[allDrugs])

    // jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    useEffect(() => {
        $(document).ready(function () {
            $.fn.dataTable.ext.errMode = 'none';
            setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                        [10, 1, 25, 50, 100, 200, 500, -1],
                        [10, 1, 25, 50, 100, 200, 500, 'All'],
                    ],
                });
            }, 1000);
        });
    }, [(tableRef.current)]);

    const handleValidationName = (e) => {
        let val = e.target.value;
        var regex = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;
        if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomerDrug({ ...customerDrug, iname: value })
        }
    }

    return (
        <Box>
            <Typography sx={userStyle.HeaderText}>Customer Drug</Typography>
            <Box sx={userStyle.container}>
                <Headtitle title={'Customer Drug'} />
                { /* ****** Header Content ****** */}
                <Grid container spacing={2}>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].acusdrugentry && (
                            <>
                                <Button sx={userStyle.buttonadd} onClick={handleOpenModal}>ADD</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                { /* ****** Filter Start ****** */}
                { /* Header Buttons */}
                <Grid container sx={{ justifyContent: "center", }} >
                    <Grid >
                        {isUserRoleCompare[0].csvcusdrugentry && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelcusdrugentry && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printcusdrugentry && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfcusdrugentry && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                { /* Table Start */}
                <TableContainer component={Paper} >
                    <Table aria-label="simple table" id="customertable" ref={tableRef}>
                        <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
                            <StyledTableRow >
                                <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Customer ID</StyledTableCell>
                                <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Customer Name</StyledTableCell>
                                <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Item Code</StyledTableCell>
                                <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Item Name</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {allDrugs.length && (
                                allDrugs.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{row.cusid}</StyledTableCell>
                                        <StyledTableCell align="left">{row.name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.itemcode}</StyledTableCell>
                                        <StyledTableCell align="left">{row.iname}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {/* Table End */}
            {/* Add Model */}
            <Box>
                <Dialog
                    open={openModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                >
                    <form onSubmit={handleSubmit}>
                        <DialogTitle id="alert-dialog-title">
                            Add Drugs
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <p style={{color:'red'}}>{showAlert}</p>
                                <Grid container spacing={2}>
                                    <Grid item md={3} xs={12}>
                                        <Typography variant='h6' sx={{ display: "flex" }}>Name<Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <FormControl size="small" fullWidth>
                                            <Selects
                                                onChange={(e) => {
                                                    setCustomerDrug({ ...customerDrug, name: e.value, customerId:newval})
                                                    searchCus(e._id);
                                                }}
                                                styles={colourStyles}
                                                options={allCustomer}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12}></Grid>
                                    <Grid item md={3} xs={12}>
                                        <Typography variant='h6'>Customer ID</Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <FormControl size="small" fullWidth>
                                            {allDrugs && (
                                                allDrugs.map(
                                                    () => {
                                                        let strings = setngs ? setngs.customerdrugentrysku : 'CDE';
                                                        let refNo = allDrugs[allDrugs.length - 1].cusid;
                                                        let digits = (allDrugs.length + 1).toString();
                                                        const stringLength = refNo.length;
                                                        let lastChar = refNo.charAt(stringLength - 1);
                                                        let getlastBeforeChar = refNo.charAt(stringLength - 2);
                                                        let getlastThreeChar = refNo.charAt(stringLength - 3);
                                                        let lastBeforeChar = refNo.slice(-2);
                                                        let lastThreeChar = refNo.slice(-3);
                                                        let lastDigit = refNo.slice(-4);
                                                        let refNOINC = parseInt(lastChar) + 1
                                                        let refLstTwo = parseInt(lastBeforeChar) + 1;
                                                        let refLstThree = parseInt(lastThreeChar) + 1;
                                                        let refLstDigit = parseInt(lastDigit) + 1;
                                                        if (digits.length < 4 && getlastBeforeChar == 0 && getlastThreeChar == 0) {
                                                            refNOINC = ("000" + refNOINC);
                                                            newval = strings + refNOINC;
                                                        } else if (digits.length < 4 && getlastBeforeChar > 0 && getlastThreeChar == 0) {
                                                            refNOINC = ("00" + refLstTwo);
                                                            newval = strings + refNOINC;
                                                        } else if (digits.length < 4 && getlastThreeChar > 0) {
                                                            refNOINC = ("0" + refLstThree);
                                                            newval = strings + refNOINC;
                                                        } else {
                                                            refNOINC = (refLstDigit);
                                                            newval = strings + refNOINC;
                                                        }
                                                    }))}
                                            <TextField size="small" sx={{
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #B97DF0',
                                                },
                                            }}
                                                value={newval}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12}></Grid>
                                    <Grid item md={3} xs={12}>
                                        <Typography variant='h6'>Item Code<b style={{color:'red'}}>*</b></Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <FormControl size="small" fullWidth>
                                            <OutlinedInput
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #B97DF0',
                                                    },
                                                }}
                                                id="component-outlined"
                                                value={customerDrug.itemcode}
                                                onChange={(e) => { setCustomerDrug({ ...customerDrug, itemcode: e.target.value }) }}
                                                type="text"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12}></Grid>
                                    <Grid item md={3} xs={12}>
                                        <Typography variant='h6' >Iname<b style={{color:'red'}}>*</b></Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <FormControl size="small" fullWidth>
                                            <OutlinedInput
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: '1px solid #B97DF0',
                                                    },
                                                }}
                                                id="component-outlined"
                                                value={customerDrug.iname}
                                                onChange={(e) => { setCustomerDrug({ ...customerDrug, iname: e.target.value }); handleValidationName(e) }}
                                                type="text"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item md={3} xs={12}></Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='primary' type="submit" autoFocus>SAVE</Button>
                            <Button variant='contained' color='error' onClick={handleCloseModal}>Close</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Box>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} >
                <Box>
                    <Typography variant='h5' >Customer Drug Entry</Typography>
                </Box>
                <Box  >
                    <TableContainer component={Paper} sx={userStyle.printcls}>
                        <Table aria-label="simple table" id="Customerbillsreport" ref={componentRef}>
                            <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
                                <StyledTableRow >
                                    <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Customer ID</StyledTableCell>
                                    <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Customer Name</StyledTableCell>
                                    <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Item Code</StyledTableCell>
                                    <StyledTableCell sx={{ border: '1px solid #dddddd7d !important' }}>Item Name</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {allDrugs.length && (
                                    allDrugs.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.cusid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell align="left">{row.itemcode}</StyledTableCell>
                                            <StyledTableCell align="left">{row.iname}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    )
}
function Customerdrugentry() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Schedule /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Customerdrugentry;