import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { userStyle, colourStyles } from '../../PageStyle';
import axios from 'axios';
import { Box, Typography, FormControl, Grid, Paper, InputLabel, Table, TableBody, TableHead, TableContainer, Button, OutlinedInput, } from '@mui/material';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import {  Link, useParams } from 'react-router-dom';


function ViewSupplierProductlinkall() {

  const { auth } = useContext(AuthContext)
  const [tableData, setTableData] = useState([])
  const [addSuppPoduct, setAddSuppPoduct] = useState({})

  const id = useParams().id

  const fetchHandlerList = async () => {
    try {
      let response = await axios.get(`${SERVICE.SUPPLIER_PRODUCT_LINK_SINGLE}/${id}`, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      })
      setTableData(response.data.ssupplierproduct.supplierproductlinks);
      setAddSuppPoduct(response.data.ssupplierproduct);

    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  useEffect(() => {
    fetchHandlerList();
  }, [id])

  return (
    <Box>
              <Typography sx={userStyle.HeaderText}>View Supplier & Product Link <Typography sx={userStyle.SubHeaderText}></Typography></Typography>

      <Box sx={userStyle.container}>
        <Headtitle title={'View supplierproductslinks'} />
        <br /><br />
        <Grid container lg={12} md={12} sm={12} xs={12} sx={{ justifyContent: "center" }} spacing={1}>
          <Grid container lg={12} md={12} >
            <Grid item lg={2} md={2}>
              <Typography sx={{ marginTop: 1, marginLeft: "1em" }}><b>Supplier:</b><b style={{ color: "red" }}> *</b></Typography>
            </Grid>
            <Grid item lg={4} md={2} style={{ marginRight: "5em" }}>
              <FormControl size="small" fullWidth style={{ marginLeft: "0.8em" }} >
                <InputLabel htmlFor="component-outlined" ></InputLabel>
                <OutlinedInput
                  id="component-outlined"
                  styles={colourStyles}
                  value={addSuppPoduct.supplier}

                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={8}>

            </Grid>
          </Grid>
        </Grid><br /><br />
        <Grid container spacing={0.2} >
          <Grid item lg={2} md={4}>
            <Typography sx={{ marginTop: "1em", marginLeft: "0.6em" }}><b>Item Name:<b style={{ color: "red" }}> *</b></b></Typography>
          </Grid>
          <Grid item lg={4} md={4}>
            <FormControl size="small" fullWidth >
              <OutlinedInput
                styles={colourStyles}
                value={addSuppPoduct.itemname}
              />
            </FormControl>
          </Grid>
          <Grid item lg={3} md={4}>
          </Grid>
        </Grid><br /><br />
        <TableContainer component={Paper} >
          <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Item Code</StyledTableCell>
                <StyledTableCell >Item Name </StyledTableCell>
                <StyledTableCell >Category</StyledTableCell>
                <StyledTableCell >Manufacture</StyledTableCell>
                <StyledTableCell >Tax%</StyledTableCell>
                <StyledTableCell >Re-Order Level</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody align="left">
              {tableData.length > 0 &&
                tableData?.map((data, i) => {
                  return (
                    <>
                      <StyledTableRow key={i}>
                        <StyledTableCell >{data.productid}</StyledTableCell>
                        <StyledTableCell >{data.productname}</StyledTableCell>
                        <StyledTableCell >{data.category}</StyledTableCell>
                        <StyledTableCell >{data.manufacture}</StyledTableCell>
                        <StyledTableCell >{data.taxtareval}</StyledTableCell>
                        <StyledTableCell >{data.quantity}</StyledTableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer><br /><br />
        <Grid container>
          <Grid item md={3}></Grid>
          <Grid item md={3}>
            <Grid>
              <Box>
                <Link to="/purchase/SupplierProduct/ListSupplierProduct"><Button sx={userStyle.buttoncancel}>Back</Button></Link>
              </Box>
            </Grid>
          </Grid>
          <Grid item md={3}></Grid>
          <Grid item md={3}></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function SupplierProductView() {
  return (
    <>
      <Box>
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <ViewSupplierProductlinkall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SupplierProductView;