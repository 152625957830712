import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Grid, Box, Typography, Table, TableHead, TableContainer, FormControlLabel, TableBody, DialogContentText, DialogTitle, Checkbox, Paper, DialogContent, DialogActions, Dialog, FormGroup } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import $ from 'jquery';
import { userStyle } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { ExportXL, ExportCSV } from '../../Export';
import jsPDF from "jspdf";
import { useReactToPrint } from 'react-to-print';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext, UserRoleAccessContext } from '../../../context/Appcontext';

const Locationtable = () => {

    const [busilocations, setBusilocations] = useState();
    const [exceldata,setExceldata]= useState([]);

    const { auth } = useContext(AuthContext);

    // User Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    const [isPdfData, setIsPdfData] = useState({
        isBusiId: false, isBusiName: false, isBusiLandmark: false, isCountry: false,
        isState: false, isCity: false, isZipcode: false, ismobile: false, ismobileone: false, ismobiletwo: false,
        ismobilethree: false, isWhatsapp: false, isEmail: false, isWebsite: false
    })
    const [busilocationses, setBusilocationses] = useState({});

    const [isOpen, setIsOpen] = useState(false);
    const handlePdfOpen = () => { setIsOpen(true); };
    const handlePdfClose = () => { setIsOpen(false); };
    
    // get particular columns for export excel
    const getexcelDatas= async () => {
        var data = busilocations.map(t=>({Name: t.name, 'Location ID': t.locationid, Landmark: t.landmark, Country: t.country, State: t.state,City: t.city, Zipcode: t.zipcde,
             Mobile: t.phonenumber, 'Mobile 1': t.onephonenumber, 'Mobile 2': t.twophonenumber, 'Mobile 3': t.threephonenumber,
             'Landline Number': t.landlinenumber , 'WhatsApp No': t.whatsappno, Email: t.email, Website: t.website, Activate: t.activate}));
        setExceldata(data);
    }

    // jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // Business Locations
    const fetchLocation = async () => {
        try {
            let response = await axios.get(SERVICE.BUSINESS_LOCATION, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setBusilocations(response.data.busilocations);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const getrow = async (id) => {
        try {
            let response = await axios.get(`${SERVICE.BUSINESS_LOCATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            let getdata = response.data.sbusilocation.activate;
            await axios.put(`${SERVICE.BUSINESS_LOCATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                activate: !getdata,
            }).then(res => res.data);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'Business Locations';

    //delete model
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const handleClickOpen = (id) => {
        setIsDeleteOpen(true);
    };
    const handleClose = () => {
        setIsDeleteOpen(false);
    };
    
    // Alert popup
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()

    const handleClickOpener = () => { setIsErrorOpen(true); };
    const handleCloser = () => { setIsErrorOpen(false); };

    const rowData = async (id) => {
        try {
            let res = await axios.get(`${SERVICE.BUSINESS_LOCATION_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setBusilocationses(res.data.sbusilocation);//set function to get particular row
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    //alert delete popup
    let busiid = busilocationses._id;
    const deleteLocation = async (busiid) => {
        try {
            let res = await axios.delete(`${SERVICE.BUSINESS_LOCATION_SINGLE}/${busiid}`,{
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            handleClose();
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | BUSINESS LOCATIONS',
        pageStyle: 'print'
    });

    // PDF
    const downloadPdf = () => {
        const newData = busilocations.map(row => {
            delete row._id;
            delete row.createdAt;
            delete row.activate
            delete row.landlinenumber
            delete row.__v

            { !isPdfData.isBusiId && delete row.locationid };
            { !isPdfData.isBusiName && delete row.name };
            { !isPdfData.isBusiLandmark && delete row.landmark };
            { !isPdfData.isCountry && delete row.country };
            { !isPdfData.isState && delete row.state };
            { !isPdfData.isCity && delete row.city };
            { !isPdfData.isZipcode && delete row.zipcde };
            { !isPdfData.ismobile && delete row.phonenumber };
            { !isPdfData.ismobileone && delete row.onephonenumber };
            { !isPdfData.ismobiletwo && delete row.twophonenumber };
            { !isPdfData.ismobilethree && delete row.threephonenumber };
            { !isPdfData.isWhatsapp && delete row.whatsappno };
            { !isPdfData.isEmail && delete row.email };
            { !isPdfData.isWebsite && delete row.website };
            setIsPdfData(row)
        })

        const doc = new jsPDF()
        doc.autoTable({
            theme: "grid",
            body: busilocations
        })
        doc.save('Business Locations.pdf')
    }

    useEffect(
        ()=>{
            $(document).ready(function() {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                $(tableRef.current).DataTable({
                    language: { search: '', searchPlaceholder: "Search..." },
                    lengthMenu: [
                          [10, 1, 25, 50, 100, 200, 500, -1],
                          [10, 1, 25, 50, 100, 200, 500, 'All'],
                      ],
                          });
              }, 1000);
              } );
        },[tableRef.current]
    )

    useEffect(
        () => {
            fetchLocation();
            getexcelDatas();
        }, [deleteLocation]
    );

    return (
        <Box>
            <Headtitle title={'Business Locations'} />
            <Grid sx={{display: 'flex'}}>
                <Typography sx={userStyle.HeaderText}>Business Locations</Typography> &nbsp;
                <Typography sx={userStyle.SubHeaderText} style={{marginTop:"22px"}}>Manage your business locations</Typography>
            </Grid>
            <Box sx={userStyle.container}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography sx={userStyle.importheadtext}>All your business locations</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {isUserRoleCompare[0].abusinesslocation && (
                            <>
                                <Link to={'/settings/location/create'} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>
                            </>
                        )}
                    </Grid>
                </Grid>
                {/* EXPORT BUTTONS START */}
                <Grid container sx={userStyle.gridcontainer}>
                    <Grid >
                        {isUserRoleCompare[0].csvbusinesslocation && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelbusinesslocation && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printbusinesslocation && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfbusinesslocation && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => handlePdfOpen()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid>
                {/* EXPORT BUTTONS END */}
                {/* TABLE START */}
                <Box>
                    <TableContainer component={Paper} >
                        <Table aria-label="customized table" id="businessLocation" sx={{ minWidth:700 }} ref={tableRef}>
                            <TableHead>
                                <StyledTableRow>
                                <StyledTableCell align="left">Action</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align="left">Location ID</StyledTableCell>
                                    <StyledTableCell align="left">Landmark</StyledTableCell>
                                    <StyledTableCell align="left">Country</StyledTableCell>
                                    <StyledTableCell align="left">State</StyledTableCell>
                                    <StyledTableCell align="left">City</StyledTableCell>
                                    <StyledTableCell align="left">Zip Code</StyledTableCell>
                                    <StyledTableCell align="left">Mobile</StyledTableCell>
                                    <StyledTableCell align="left">Mobile 1</StyledTableCell>
                                    <StyledTableCell align="left">Mobile 2</StyledTableCell>
                                    <StyledTableCell align="left">Mobile 3</StyledTableCell>
                                    <StyledTableCell align="left">WhatsApp</StyledTableCell>
                                    <StyledTableCell align="left">Website</StyledTableCell>
                                    <StyledTableCell align="left">Email</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {busilocations &&
                                    (busilocations.map((row, index) => (
                                        <StyledTableRow key={index}>
                                             <StyledTableCell align="left">
                                                <Grid sx={{ display: 'flex' }}>
                                                    {isUserRoleCompare[0].ebusinesslocation && (
                                                        <>
                                                            <Link to={`/settings/location/edit/${row._id}`} style={{ textDecoration: 'none', color: '#fff', minWidth: '0px' }}><Button sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: "large" }} /></Button></Link>
                                                        </>
                                                    )}
                                                    {isUserRoleCompare[0].dbusinesslocation && (
                                                        <>
                                                            <Button sx={userStyle.buttondelete} onClick={(e) => { handleClickOpen(); rowData(row._id) }}><DeleteOutlineOutlinedIcon style={{ fontsize: 'large' }} /></Button>
                                                        </>
                                                    )}
                                                    {isUserRoleCompare[0].activatebusinesslocation && (
                                                        <>
                                                            <Button variant="contained" color={row.activate == true ? 'success' : 'warning'} sx={{ minWidth: '0px', padding: '0 7px' }} onClick={(e) => { handleClickOpener((setShowAlert(row.activate == true ? 'Do you want to Deactivate?' : 'Do you want to Activate?'))); rowData(row._id) }}><PowerSettingsNewOutlinedIcon style={{ fontSize: 'large' }} /></Button>
                                                        </>
                                                    )}
                                                </Grid>
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row"> {row.name} </StyledTableCell>
                                            <StyledTableCell align="left">{row.locationid}</StyledTableCell>
                                            <StyledTableCell align="left">{row.landmark}</StyledTableCell>
                                            <StyledTableCell align="left">{row.country}</StyledTableCell>
                                            <StyledTableCell align="left">{row.state}</StyledTableCell>
                                            <StyledTableCell align="left">{row.city}</StyledTableCell>
                                            <StyledTableCell align="left">{row.zipcde}</StyledTableCell>
                                            <StyledTableCell align="left">{row.phonenumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.onephonenumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.twophonenumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.threephonenumber}</StyledTableCell>
                                            <StyledTableCell align="left">{row.whatsappno}</StyledTableCell>
                                            <StyledTableCell align="left">{row.website}</StyledTableCell>
                                            <StyledTableCell align='left'>{row.email}</StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {/* Alert Dialog */}
                <Dialog
                    open={isDeleteOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h5" sx={{ color: 'red', textAlign: 'center' }}>Are you sure?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined">Cancel</Button>
                        <Button onClick={(e) => deleteLocation(busiid)} autoFocus variant="contained" color='error'> OK </Button>
                    </DialogActions>
                </Dialog>
                {/* PDF Model */}
                <Box>
                    <Dialog
                        open={isOpen}
                        onClose={handlePdfClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Select Option to Print PDF
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="subtitle1">Choose any 6</Typography>
                                <Grid container spacing={2}>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={isPdfData.isBusiName} onClick={(e) => { setIsPdfData({ ...isPdfData, isBusiName: !isPdfData.isBusiName }) }}/>} label="Name"  />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isBusiId} onClick={(e) => setIsPdfData({ ...isPdfData, isBusiId: !isPdfData.isBusiId })}/>} label="Location id"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isBusiLandmark} onClick={(e) => setIsPdfData({ ...isPdfData, isBusiLandmark: !isPdfData.isBusiLandmark })}/>} label="Landmark"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCountry} onClick={(e) => setIsPdfData({ ...isPdfData, isCountry: !isPdfData.isCountry })}/>} label="Country"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isState} onClick={(e) => setIsPdfData({ ...isPdfData, isState: !isPdfData.isState })}/>} label="State"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isCity} onClick={(e) => setIsPdfData({ ...isPdfData, isCity: !isPdfData.isCity })}/>} label="City"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isZipcode} onClick={(e) => setIsPdfData({ ...isPdfData, isZipcode: !isPdfData.isZipcode })}/>} label="Zipcode"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.ismobile} onClick={(e) => setIsPdfData({ ...isPdfData, ismobile: !isPdfData.ismobile })}/>} label="Mobile"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.ismobileone} onClick={(e) => setIsPdfData({ ...isPdfData, ismobileone: !isPdfData.ismobileone })}/>} label="Mobile 1"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.ismobiletwo} onClick={(e) => setIsPdfData({ ...isPdfData, ismobiletwo: !isPdfData.ismobiletwo })}/>} label="Mobile 2"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.ismobilethree} onClick={(e) => setIsPdfData({ ...isPdfData, ismobilethree: !isPdfData.ismobilethree })}/>} label="Mobile 3"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isWhatsapp} onClick={(e) => setIsPdfData({ ...isPdfData, isWhatsapp: !isPdfData.isWhatsapp })}/>} label="WhatsApp"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isEmail} onClick={(e) => setIsPdfData({ ...isPdfData, isEmail: !isPdfData.isEmail })}/>} label="Email"  />
                                    </Grid>
                                    <Grid item md={3} sm={6} xs={12}>
                                        <FormControlLabel control={<Checkbox checked={isPdfData.isWebsite} onClick={(e) => setIsPdfData({ ...isPdfData, isWebsite: !isPdfData.isWebsite })} />} label="Website" />
                                    </Grid>
                                </Grid>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant='contained' color='primary' onClick={() => downloadPdf()} autoFocus>PDF</Button>
                            <Button variant='contained' color='error' onClick={handlePdfClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={isErrorOpen}
                        onClose={handleCloser}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                            <Typography variant="h6">{showAlert}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloser} variant="outlined">Cancel</Button>
                            <Button variant="contained" color="error" onClick={(e) => { getrow(busiid); handleCloser() }}>ok</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </Box>
            {/* Print layout */}
            <TableContainer component={Paper} sx={userStyle.printcls}>
                <Table aria-label="customized table" id="businessLocation" sx={{ minWidth:700 }} ref={componentRef}>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="left">Location ID</StyledTableCell>
                            <StyledTableCell align="left">Landmark</StyledTableCell>
                            <StyledTableCell align="left">Country</StyledTableCell>
                            <StyledTableCell align="left">State</StyledTableCell>
                            <StyledTableCell align="left">City</StyledTableCell>
                            <StyledTableCell align="left">Zip Code</StyledTableCell>
                            <StyledTableCell align="left">Mobile</StyledTableCell>
                            <StyledTableCell align="left">Mobile 1</StyledTableCell>
                            <StyledTableCell align="left">Mobile 2</StyledTableCell>
                            <StyledTableCell align="left">Mobile 3</StyledTableCell>
                            <StyledTableCell align="left">WhatsApp</StyledTableCell>
                            <StyledTableCell align="left">Website</StyledTableCell>
                            <StyledTableCell align="left">Email</StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {busilocations &&
                            (busilocations.map((row, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell component="th" scope="row"> {row.name} </StyledTableCell>
                                    <StyledTableCell align="left">{row.locationid}</StyledTableCell>
                                    <StyledTableCell align="left">{row.landmark}</StyledTableCell>
                                    <StyledTableCell align="left">{row.country}</StyledTableCell>
                                    <StyledTableCell align="left">{row.state}</StyledTableCell>
                                    <StyledTableCell align="left">{row.city}</StyledTableCell>
                                    <StyledTableCell align="left">{row.zipcde}</StyledTableCell>
                                    <StyledTableCell align="left">{row.phonenumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.onephonenumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.twophonenumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.threephonenumber}</StyledTableCell>
                                    <StyledTableCell align="left">{row.whatsappno}</StyledTableCell>
                                    <StyledTableCell align="left">{row.website}</StyledTableCell>
                                    <StyledTableCell align='left'>{row.email}</StyledTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* printlayout ends */}
        </Box>
    );
}
function Locationlist() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Locationtable /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Locationlist;