
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { ExportXL, ExportCSV } from '../Export';
import Headtitle from '../../components/header/Headtitle';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import $ from 'jquery';
import { SERVICE } from '../../services/Baseservice';
import moment from "moment";
import { AuthContext } from '../../context/Appcontext';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";

function Categorywise() {

  const [categorywisesales, setCategorywisesales] = useState([]);
  const { auth, setAuth } = useContext(AuthContext);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const [exceldata, setExceldata] = useState([]);

  //jquery
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  let manArr = []
  let totArr = []
  let quantityArr = []
  let arrMixed = []

  //categorywisesales fetching

  const fetchcategorywisesales = async () => {
    try {
      let request = await axios.get(SERVICE.POS, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      setAuth({ ...auth, loginState: true });
      let categorywisesalesfetch = request.data.pos1.filter((data) => {
        let splittedMonth = data.date
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })

      categorywisesalesfetch.map(item => {
        item.goods.map(value => {
          manArr.push(value.category)
          totArr.push(value.subtotal)
          quantityArr.push(value.quantity)

        })
        arrMixed = manArr.map(function (data, i) {
          return { categories: data, totalbillamt: totArr[i], totlquantity: quantityArr[i], };

        });

        const result = [...arrMixed.reduce((r, o) => {
          const key = o.categories;
          const items = r.get(key) || Object.assign({}, o, {
            totalbillamt: 0,
            totlquantity: 0
          });
          items.totalbillamt += o.totalbillamt
          items.totlquantity += o.totlquantity

          return r.set(key, items);
        }, new Map).values()];

        setCategorywisesales(result);

      })

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 25, 50, 100, 200, 500, -1],
              [10, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });

    } catch (error) {
    }
  }
  // Export Excel
  const fileName = 'Category Wise Sales'

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = categorywisesales.map(t => ({
      "Category": t.categories,
      "Total Bills": t.totlquantity,
      "Total Amount": t.totalbillamt
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  }, [categorywisesales])

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#categorywisetable' })
    doc.save('categorywisesales.pdf')
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIschedulewisesales | Salesummary',
    pageStyle: 'print'
  });

  return (
    <Box>
      <Grid item xs={12}>
        <Typography sx={userStyle.importheadtext}>Category Wise Sales (Retail)</Typography>
      </Grid><br /><br />

      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}> From</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchcategorywisesales} >Generate</Button>
          </Grid>
        </Grid>
      </Box><br />
      <Headtitle title={'Categorywise sales'} />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
        </Grid>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
            {isUserRoleCompare[0].csvcategorywisesales && (<ExportCSV csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].excelcategorywisesales && (<ExportXL csvData={exceldata} fileName={fileName} />)}
            {isUserRoleCompare[0].printcategorywisesales && (<Button sx={userStyle.buttongrp} onClick={handleprint}><FaPrint />&ensp;Print&ensp;</Button>)}
            {isUserRoleCompare[0].pdfcategorywisesales && (<Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="categorywisetable" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell >Total Bills</StyledTableCell>
                  <StyledTableCell >Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {categorywisesales &&
                  (categorywisesales.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell >{row.categories}</StyledTableCell>
                        <StyledTableCell >{row.totlquantity}</StyledTableCell>
                        <StyledTableCell >{row.totalbillamt}</StyledTableCell>
                      </StyledTableRow>
                    )
                  }))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls} >
        <Table sx={{ minWidth: 700, }} aria-label="customized table" id="categorywisetable" ref={componentRef}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Category</StyledTableCell>
              <StyledTableCell >Total Bills</StyledTableCell>
              <StyledTableCell >Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {categorywisesales &&
              (categorywisesales.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell >{row.categories}</StyledTableCell>
                    <StyledTableCell >{row.totlquantity}</StyledTableCell>
                    <StyledTableCell >{row.totalbillamt}</StyledTableCell>
                  </StyledTableRow>
                )
              }))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Categorywisesales() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Categorywise /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Categorywisesales;