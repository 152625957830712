import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle, colourStyles } from '../../PageStyle';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import $ from 'jquery';
import Selects from 'react-select';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import { UserRoleAccessContext } from '../../../context/Appcontext';

function Customerbalanceregister() {

    const [pos, setPos] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const [customername, setCustomername] = useState();
    const [customerarea, setCustomerarea] = useState();
    const [cusNameFilter, setCusNameFilter] = useState({ customername: "", area: "" });
    const [newdata, setNewdata] = useState([]);
    const [newdata1, setNewdata1] = useState([]);
    const { auth } = useContext(AuthContext);

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    let areaArr = [];
    let billNoArr = []
    let allArray = [];
    let purchaseArr = [];
    let cusNameArr = [];
    let amountArr = [];

    // Fetch customer address 
    const fetchCustomeraddress = async () => {
        try {
            let res_group = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            let masters_data = res_group?.data?.customers?.map((d) => {
                return d.city
            });

            let masters_name = res_group?.data?.customers?.map((d) => {
                return d.name
            })
            setNewdata(masters_data);
            setNewdata1(masters_name);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    let newarray = [...new Set(newdata)];

    let newarray1 = [...new Set(newdata1)];

    // Fetch customer Area for dropdown......Area
    const fetchcustomerarea = async () => {
        try {
            setCustomerarea(
                newarray.map((d) => ({
                    ...d,
                    label: d,
                    value: d,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchcustomerarea();
    }, [newdata])

    // Fetch customer name for dropdown .............Name
    const fetchcustomernames = async () => {
        try {

            setCustomername(
                newarray1?.map((d) => ({
                    ...d,
                    label: d,
                    value: d,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchcustomernames();
    }, [newdata1])

    // Fetch area wise customer data from pos......Area
    const fetchCustomer = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });

            let result = req.data.pos1.filter((items) => {
                if (cusNameFilter.customername == "" && cusNameFilter.area == "") {
                    return items
                }
                else if (items.location == cusNameFilter.area && cusNameFilter.customername == "") {

                    return items
                }
                else if (items.customer == cusNameFilter.customername && cusNameFilter.area == "") {

                    return items
                }
                else if (items.customer == cusNameFilter.customername && items.location == cusNameFilter.area) {
                    return items
                }
            })

            //pushing into arrays to get values in table
            result.filter((value) => {
                billNoArr.push(value.contactid)
                amountArr.push(+value.dueamount)
                areaArr.push(value.location)
                cusNameArr.push(value.customer)
            })

            result.filter((value) => {
                if ((customername == value.customer && customerarea == value.location) || (customername == value.customer && customerarea == "") || (customername == "" && customerarea == value.location)) {
                    return value
                }
            })
            allArray = billNoArr.map(function (data, i) {
                return { billno: data, amountgains: amountArr[i], purchasevalue: purchaseArr[i], area: areaArr[i], customername: cusNameArr[i] };
            });

            const finalresult = [...allArray.reduce((r, o) => {
                const val = o.billno
                const items = r.get(val) || Object.assign({}, o, {
                    amountgains: 0,
                });
                items.amountgains += o.amountgains
                return r.set(val, items);
            }, new Map).values()];
            setPos(finalresult);

            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [10, 1, 25, 50, 100, 200, 500, -1],
                            [10, 1, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(
        () => {
            fetchCustomeraddress();
        }, []
    )

    // Excel
    const fileName = 'Customerbalanceregister'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        var data = pos.map(t => ({
            "Name": t.customername,
            "Area": t.area,
            "Net Balance": t.amountgains
        }));
        setExceldata(data);
    }
    useEffect(
        () => {
            getexcelDatas();
        }, [pos]
    )

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'HIPOS | CUSTOMER BALANCE REGISTER',
        pageStyle: 'print'
    });

    //  PDF
    const downloadpdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#Customerbalanceregister' })
        doc.save('Customerbalanceregister.pdf')
    }

    return (
        <Box>
            <Headtitle title={'Customer Balance Register'} />
            { /* ****** Header Content ****** */}
            <Typography component={'span'} variant={'body2'} sx={userStyle.HeaderText}>Customer Balance Register List</Typography>
            <Box sx={userStyle.filtercontent}>
                <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                    <Grid item lg={1} md={1}></Grid>
                    <Grid item lg={4} md={4}>
                        <Grid container>
                            <Grid item lg={2} md={2}>
                                <Typography component={'span'} variant={'body2'} sx={{ marginTop: 1 }}>Area</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" sx={{ width: "100%" }} >
                                    <Selects
                                        onChange={(e) => setCusNameFilter({ ...cusNameFilter, area: e.value })}
                                        styles={colourStyles}
                                        options={customerarea}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={4}>
                        <Grid container>
                            <Grid item lg={2} md={2}>
                                <Typography component={'span'} variant={'body2'} sx={{ marginTop: 1 }}> Name</Typography>
                            </Grid>
                            <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                <FormControl size="small" sx={{ width: "100%" }}>
                                    <Selects
                                        onChange={(e) => setCusNameFilter({ ...cusNameFilter, customername: e.value })}
                                        styles={colourStyles}
                                        options={customername}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={3}>
                        <Button variant='contained' color='secondary' onClick={fetchCustomer}>Generate</Button>
                    </Grid>
                </Grid>
            </Box><br />
            <Box sx={userStyle.container} >
                { /* ****** Header Buttons ****** */}
                <Grid container sx={{ justifyContent: "center" }} >
                    <Grid >
                        {isUserRoleCompare[0].csvcustomerbalanceregister && (
                            <>
                                <ExportCSV csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].excelcustomerbalanceregister && (
                            <>
                                <ExportXL csvData={exceldata} fileName={fileName} />
                            </>
                        )}
                        {isUserRoleCompare[0].printcustomerbalanceregister && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                            </>
                        )}
                        {isUserRoleCompare[0].pdfcustomerbalanceregister && (
                            <>
                                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                            </>
                        )}
                    </Grid>
                </Grid><br />
                {/* ****** Table Grid Container ****** */}
                { /* ****** Table start ****** */}
                <TableContainer component={Paper} >
                    <Table aria-label="simple table" id="Customerbalanceregister" ref={tableRef}>
                        <TableHead sx={{ fontWeight: "600" }} >
                            <StyledTableRow >
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Area</StyledTableCell>
                                <StyledTableCell>Net Balance</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {pos.length > 0 && (
                                pos.map((row, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="left">{row.customername}</StyledTableCell>
                                        <StyledTableCell align="left">{row.area}</StyledTableCell>
                                        <StyledTableCell align="left">{row.amountgains.toFixed()}</StyledTableCell >
                                    </StyledTableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                { /* ****** Table End ****** */}
                { /* ****** Print ****** */}
                <Box sx={userStyle.printcls} ref={componentRef}>
                    <Box>
                        <Typography variant='h5' >Customer Wise Payment Report Print</Typography>
                    </Box>
                    <>
                        <Box  >
                            <TableContainer component={Paper} sx={userStyle.printcls}>
                                <Table aria-label="simple table" id="Customerbalanceregister">
                                    <TableHead sx={{ fontWeight: "600" }} >
                                        <StyledTableRow >
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Area</StyledTableCell>
                                            <StyledTableCell>Net Balance</StyledTableCell>
                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pos.length > 0 && (
                                            pos.map((row, item) => (
                                                <StyledTableRow key={item}>
                                                    <StyledTableCell align="left">{row.customername}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.area}</StyledTableCell>
                                                    <StyledTableCell align="left">{row.amountgains.toFixed()}</StyledTableCell >
                                                </StyledTableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </>
                </Box>
            </Box>
        </Box>
    );
}
function Customerbalanceregisterlist() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Customerbalanceregister /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default Customerbalanceregisterlist;