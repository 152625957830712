import React, { useState, useEffect,useRef,useContext} from 'react';
import { Button, Grid, Typography, Box, Table, TableBody, TableContainer, TableHead, Paper } from '@mui/material';
import { StyledTableRow, StyledTableCell } from '../../../components/Table';
import { FaPrint, FaFilePdf } from "react-icons/fa";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ExportXL, ExportCSV } from '../../Export';
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';
import { userStyle } from '../../PageStyle';
import { Link } from 'react-router-dom';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import axios from 'axios';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../../context/Appcontext';


function Roleslisttable() {

  const [roles, setRoles] = useState();

  const {isUserRoleCompare} = useContext(UserRoleAccessContext);

  // Roles
  const fetchHandler = async () => {
    try {
      let role = await axios.get('http://192.168.85.6:7000/api/roles');
      setRoles(role.data.roles)
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  const deleteUser = async (id) => {
    try {
      let res = await axios.delete(`http://192.168.85.6:7000/api/role/${id}`);
      toast.success(res.data.message);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  useEffect(() => {
    fetchHandler();
  }, [deleteUser]);

  // Export Excel
  const fileName = 'Roles';

  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#roletable' })
    doc.save('Roles.pdf')
  }

  //jquery
 $.DataTable = require('datatables.net')
 const tableRef = useRef()
 useEffect(() => {
     $(document).ready(function () {
         $.fn.dataTable.ext.errMode = 'none';
         setTimeout(function () {
             console.log(tableRef.current)
             $(tableRef.current).DataTable({
                 language: { search: '', searchPlaceholder: "Search..." },
                 lengthMenu: [
                     [10, 25, 50, 100, 200, 500, -1],
                     [10, 25, 50, 100, 200, 500, 'All'],
                 ],
             });
         }, 1000);
     });
 }, [(tableRef.current)])

  return (
    <Box >
      <Typography sx={userStyle.HeaderText}>Roles <Typography sx={userStyle.SubHeaderText}>Manage Roles</Typography></Typography>
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography sx={userStyle.importheadtext}>All Roles</Typography>
          </Grid>
          <Grid item xs={4}>
          {isUserRoleCompare[0].arole && (<Link to="/user/role/create" style={{ textDecoration: 'none', color: 'white' }}><Button sx={userStyle.buttonadd}>ADD</Button></Link>)}
          </Grid>
        </Grid>
        <Grid container sx={userStyle.gridcontainer}>
          <Grid >
          {isUserRoleCompare[0].excelrole && (<ExportCSV csvData={roles} fileName={fileName} />)}
          {isUserRoleCompare[0].csvrole && (<ExportXL csvData={roles} fileName={fileName} />)}
            {isUserRoleCompare[0].printrole && ( <Link to={`/user/role/print`} style={{ textDecoration: 'none', color: 'white', '&:hover': { color: 'red', backgroundColor: 'yellow' } }}>
              <Button sx={userStyle.buttongrp} ><FaPrint />&ensp;Print&ensp;</Button>
            </Link>)}
            {isUserRoleCompare[0].pdfrole && ( <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>)}
          </Grid>
        </Grid><br />
        <Box>
          <TableContainer component={Paper} sx={userStyle.tablecontainer}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="roletable" ref={tableRef}
>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell sx={{ width: '600px !important' }}>Roles</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {roles && (
                  roles.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">{row.rolename}</StyledTableCell>
                      <StyledTableCell>
                        <Grid SX={{ display: 'flex' }}>
                        {isUserRoleCompare[0].erole &&  (  <Link to={`/user/role/edit/${row._id}`} style={{ textDecoration: 'none', color: 'white', }}><Button sx={userStyle.buttonedit}><EditOutlinedIcon style={{ fontSize: 'large' }} /></Button></Link>)}
                        {isUserRoleCompare[0].drole &&  (   <Button onClick={(e) => deleteUser(row._id)} sx={userStyle.buttondelete}><DeleteOutlineOutlinedIcon style={{ fontSize: 'large' }} /></Button>)}
                        </Grid>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
}

function Roleslist() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: '100%', overflowX: 'hidden' }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <Roleslisttable /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}

export default Roleslist;