import React, { useState, useEffect, useRef, useContext } from "react";
import { Box, Typography, TextField, FormControl, Grid, Paper, Table, TableBody, TableHead, TableContainer, Button } from '@mui/material';
import { userStyle } from '../PageStyle';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import axios from 'axios';
import jsPDF from "jspdf";
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { ExportXL, ExportCSV } from '../Export';
import autoTable from 'jspdf-autotable';
import { toast } from 'react-toastify';
import Headtitle from '../../components/header/Headtitle';
import $ from 'jquery';
import { UserRoleAccessContext } from '../../context/Appcontext';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { AuthContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import { useReactToPrint } from "react-to-print";
import moment from "moment";

function CashBookRegisterList() {

    const [cashbooks, setCashbooks] = useState([]);
    const [exceldata, setExceldata] = useState([]);
    const { auth } = useContext(AuthContext);

    // Datefield
    const [dateFilter, setDateFilter] = useState({
        startdate: "", enddate: "",
    })

    // Access
    const { isUserRoleCompare } = useContext(UserRoleAccessContext);

    //jquery
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    // CashBook
    const fetchCashBook = async () => {
        try {
            let req = await axios.get(SERVICE.CASHBOOK, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            let getDatawithFilter = req.data.cashbooks.filter((data) => {
                if (dateFilter.startdate == "" && dateFilter.enddate == "") {
                    return data
                } else {
                    return dateFilter.startdate <= moment(data.createdAt).utc().format('YYYY-MM-DD') && dateFilter.enddate + 1 >= moment(data.createdAt).utc().format('YYYY-MM-DD')
                }
            })
            setCashbooks(getDatawithFilter);
            $(document).ready(function () {
                $.fn.dataTable.ext.errMode = 'none';
                setTimeout(function () {
                    $(tableRef.current).DataTable({
                        language: { search: '', searchPlaceholder: "Search..." },
                        lengthMenu: [
                            [ 10, 25, 50, 100, 200, 500, -1],
                            [ 10, 25, 50, 100, 200, 500, 'All'],
                        ],
                    });
                }, 1000);
            });
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Excel
    const fileName = 'CashBookRegisterReport'
    // get particular columns for export excel
    const getexcelDatas = async () => {
        let res = await axios.get(SERVICE.CASHBOOK,{
            headers: {
                'Authorization': `Bearer ${auth.APIToken}`
            },
        });
        var data = res.data.cashbooks.map(t => ({
            date: t.date, referenceno: t.referenceno,
        }));
        setExceldata(data);
    }

    // Print
    const componentRef = useRef();
    const handleprint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Cash Book Register Report',
        pageStyle: 'print'
    });

    //  PDF
    const downloadPdf = () => {
        const doc = new jsPDF()
        autoTable(doc, { html: '#CashBookRegisterReport' })
        doc.save('CashBookRegisterReport.pdf')
    }

    useEffect(
        () => {
            fetchCashBook();
            getexcelDatas();
        }, []
    )

    return (
        <Box >
            <Headtitle title={'Cash Book Register Report'} />
            { /* ****** Header Content ****** */}
            <Typography sx={userStyle.HeaderText}>Cash Book Register Report</Typography>
            { /* ****** Table Start ****** */}
            <>
                <Box sx={userStyle.container} >
                    <Grid container sx={{ justifyContent: "center" }} spacing={1}>
                        <Grid item lg={4} md={4}>
                            <Grid container  >
                                <Grid item lg={2} md={2}>
                                    <Typography sx={{ marginTop: 1 }}>From</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <Grid sx={userStyle.spanIcons}><CalendarMonthOutlinedIcon /></Grid>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.startdate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, startdate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={4}>
                            <Grid container >
                                <Grid item lg={1} md={1}>
                                    <Typography sx={{ marginTop: 1 }}>To</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                                    <Grid sx={userStyle.spanIcons}><CalendarMonthOutlinedIcon /></Grid>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            id="component-outlined"
                                            value={dateFilter.enddate}
                                            type="date"
                                            size="small"
                                            onChange={(e) => setDateFilter({ ...dateFilter, enddate: e.target.value })}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={2} md={2}>
                            <Button variant='contained' color='secondary' onClick={fetchCashBook}>Generate</Button>
                        </Grid>
                    </Grid>
                </Box><br />
                <Box sx={userStyle.container} >
                    { /* ****** Header Buttons ****** */}
                    <Grid container sx={{ justifyContent: "center" }} >
                        <Grid >
                            {isUserRoleCompare[0].auser && (
                                <>
                                    <ExportCSV csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].auser && (
                                <>
                                    <ExportXL csvData={exceldata} fileName={fileName} />
                                </>
                            )}
                            {isUserRoleCompare[0].auser && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                                </>
                            )}
                            {isUserRoleCompare[0].auser && (
                                <>
                                    <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                                </>
                            )}
                        </Grid>
                    </Grid><br />
                    { /* ****** Table start ****** */}
                    <TableContainer component={Paper} >
                        <Table sx={{}} aria-label="simple table" id="CashBookRegisterReport" ref={tableRef}>
                            <TableHead sx={{ fontWeight: "600" }} >
                                <StyledTableRow >
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Reference No</StyledTableCell>
                                    <StyledTableCell>Particular</StyledTableCell>
                                    <StyledTableCell>Debit</StyledTableCell>
                                    <StyledTableCell>Credit</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {cashbooks.length > 0 && (
                                    cashbooks.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="left">{row.date}</StyledTableCell>
                                            <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell >
                                            <StyledTableCell align="left"></StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { /* ****** Table End ****** */}
                </Box>
            </>
            { /* ****** Print ****** */}
            <Box sx={userStyle.printcls} ref={componentRef}>
                <Box>
                    <Typography variant='h5' >Cash Book Register Report</Typography>
                </Box>
                <>
                    <Box  >
                        <TableContainer component={Paper} >
                            <Table sx={{}} aria-label="simple table" id="CashBookRegisterReport" >
                                <TableHead sx={{ fontWeight: "600" }} >
                                    <StyledTableRow >
                                        <StyledTableCell>Date</StyledTableCell>
                                        <StyledTableCell>Reference No</StyledTableCell>
                                        <StyledTableCell>Particular</StyledTableCell>
                                        <StyledTableCell>Debit</StyledTableCell>
                                        <StyledTableCell>Credit</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {cashbooks.length > 0 && (
                                        cashbooks.map((row, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="left">{row.date}</StyledTableCell>
                                                <StyledTableCell align="left">{row.referenceno}</StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell >
                                                <StyledTableCell align="left"></StyledTableCell>
                                                <StyledTableCell align="left"></StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            </Box>
        </Box>
    );
}

function CashbookRegisterreport() {
    return (
        <Box >
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <CashBookRegisterList /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
export default CashbookRegisterreport;