import React, { useState, useEffect, createRef, useRef, } from 'react';
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Typography } from '@mui/material';
import { userStyle } from '../../../PageStyle';
import { StyledTableRow, StyledTableCell } from '../../../../components/Table';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../../components/header/Headtitle';

const TaxgrpPrint = () => {

  const [taxRateGrp, setTaxRateGrp] = useState([]);

  // Get Datas
  const taxRateRequest = async () => {
    try {
      let response = await axios.get(`http://localhost:8003/api/taxrates`);
      let taxRateGrpData = response.data.taxrates.filter((data) => {
        return data.taxtype == "taxrategroup"
      })
      setTaxRateGrp(taxRateGrpData)
      setTimeout(() => {
        handleprint()
      }, 3000);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  // Printing
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | TAXRATE GROUP',
    pageStyle: 'print'
  });
  const ref = createRef();
  const options = {
    orientation: 'portrait',
    unit: 'in'
  };
  useEffect(() => {
    taxRateRequest()
  }, []);

  return (
    <Box>
      <Headtitle title={'Taxgroup Print'} />
      <Box>
        <Typography variant='h5'>Taxrate Group</Typography>
      </Box>
      <br />
      <Box>
        <TableContainer component={Paper} sx={userStyle.container}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" id="taxrategroup" ref={componentRef}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell align="left">Tax rate %</StyledTableCell>
                <StyledTableCell align="left">Sub taxes</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {taxRateGrp.length > 0 && (
                taxRateGrp.map((item, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row">{item.taxname}</StyledTableCell>
                    <StyledTableCell align="left">{item.taxrate}</StyledTableCell>
                    <StyledTableCell align="left">{item.subtax}</StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
export default TaxgrpPrint;