import React, { useState, useEffect, useRef, useContext } from 'react';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import { FaPrint, FaFilePdf } from 'react-icons/fa';
import { Box, Table, TableBody, TableContainer, TableHead, FormControl, TextField, Paper, Button, Grid, Typography } from '@mui/material';
import moment from "moment";
import $ from 'jquery';
import { userStyle } from '../PageStyle';
import axios from 'axios';
import jsPDF from "jspdf";
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { UserRoleAccessContext } from '../../context/Appcontext';
import { useReactToPrint } from "react-to-print";
import autoTable from 'jspdf-autotable';
import Headtitle from '../../components/header/Headtitle';
import { SERVICE } from '../../services/Baseservice';
import { AuthContext } from '../../context/Appcontext';

function Purchasereportall() {

  const [purchasesreport, setPurchasesreport] = useState();

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);

  //Excel data
  const [exceldata, setExceldata] = useState([]);

  // Datefield
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef();

  const fetchPurchases = async () => {
    try {
      let req = await axios.get(SERVICE.PURCHASE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });

      let getDatawithFilter = req.data.purchases.filter((data) => {
        let splittedMonth = data.purchasedate
        let dateTrim = moment(splittedMonth).utc().format('YYYY-MM-DD')
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        } else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      })
      setPurchasesreport(getDatawithFilter);

      $(document).ready(function () {
        $.fn.dataTable.ext.errMode = 'none';
        setTimeout(function () {
          $(tableRef.current).DataTable({
            language: { search: '', searchPlaceholder: "Search..." },
            lengthMenu: [
              [10, 1, 25, 50, 100, 200, 500, -1],
              [10, 1, 25, 50, 100, 200, 500, 'All'],
            ],
          });
        }, 1000);
      });
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  // Export Excel
  const fileName = 'Purchase Report';

  //  get particular columns for export excel
  const getexcelDatas = async () => {
    var data = purchasesreport?.map(t => ({
      'GRN No': t.invoiceno, 'GRN Date': t.purchasedate, 'Supplier Name': t.supplier, 'Bill No': t.referenceno, 'Bill Date': t.paidon, 'Total Items': t.totalcnt,
      'Total Quantity': t.totalitem, 'Sub Total': t.subcost, "Discount": t.totaldiscount, "Other Charges":t?.products?.map((value)=> { return value.packingcharge == undefined || value.packingcharge == 0 ? 0 : +value.packingcharge}).reduce((val , dat)=> val + dat), 'Grand Total': t.beforediscounttotal ? t.beforediscounttotal : 0,
      'Round Off': t.beforediscounttotal ? Number(t.beforediscounttotal).toFixed(2) : 0 , 'Net Total': t.nettotal, 'Pay Mode': t.paymentmethod
    }));
    setExceldata(data);
  }

  useEffect(() => {
    getexcelDatas();
  },[purchasesreport])

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | PURCHASE REPORT',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    autoTable(doc, { html: '#purchasetable' })
    doc.save('Purchase Report.pdf')
  }

  useEffect(() => {
    fetchPurchases()
  }, [])
  
  return (
    <Box >
      <Headtitle title={'Purchase Report '} />
      { /* ************************************************** Header Content ****************************************************** */}
      <Typography sx={userStyle.HeaderText}>Purchase Report</Typography>
      <Box sx={userStyle.container} >
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, startdate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: 'flex' }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => { setDateFilter({ ...dateFilter, enddate: e.target.value }) }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button variant='contained' color='secondary' onClick={fetchPurchases}>Generate</Button>
          </Grid>
        </Grid>
      </Box><br />

      { /* ******************************************************EXPORT Buttons****************************************************** */}
      <>
        <Box sx={userStyle.container} >
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvpurchasereport && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelpurchasereport && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printpurchasereport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfpurchasereport && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          { /*  *******************************************Table Start  *******************************************p*/}
          <Box>
            <TableContainer component={Paper} >
              <Table sx={{ minWidth: 700, }} aria-label="customized table" id="purchasetable" ref={tableRef}>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>GRN NO</StyledTableCell>
                    <StyledTableCell >GRN Date</StyledTableCell>
                    <StyledTableCell >Supplier Name</StyledTableCell>
                    <StyledTableCell >Bill No</StyledTableCell>
                    <StyledTableCell >Bill Date</StyledTableCell>
                    <StyledTableCell >Total Items</StyledTableCell>
                    <StyledTableCell >Total Quantity</StyledTableCell>
                    <StyledTableCell >Sub Total</StyledTableCell>
                    <StyledTableCell >Discount</StyledTableCell>
                    <StyledTableCell >Other Charges</StyledTableCell>
                    <StyledTableCell >Grand Total</StyledTableCell>
                    <StyledTableCell >Round OFF</StyledTableCell>
                    <StyledTableCell >Net Total</StyledTableCell>
                    <StyledTableCell >Pay Mode</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody align="left">
                  {purchasesreport &&
                    (purchasesreport.map((row, index) => (
                      <StyledTableRow >
                        <StyledTableCell>{row.invoiceno}</StyledTableCell>
                        <StyledTableCell >{moment(row.purchasedate).format('DD-MM-YYYY')}</StyledTableCell>
                        <StyledTableCell >{row.supplier}</StyledTableCell>
                        <StyledTableCell >{row.referenceno}</StyledTableCell>
                        <StyledTableCell >{moment(row.paidon).format('DD-MM-YYYY')}</StyledTableCell>
                        <StyledTableCell >{row.totalcnt}</StyledTableCell>
                        <StyledTableCell >{row.totalitem}</StyledTableCell>
                        <StyledTableCell >{row.subcost}</StyledTableCell>
                        <StyledTableCell >{row.totaldiscount}</StyledTableCell>
                        <StyledTableCell >{row?.products?.map((value)=> { return value.packingcharge == undefined || value.packingcharge == 0 ? 0 : +value.packingcharge}).reduce((val , dat)=> val + dat)}</StyledTableCell>
                        <StyledTableCell >{row.beforediscounttotal ? row.beforediscounttotal : 0}</StyledTableCell>
                        <StyledTableCell >{row.beforediscounttotal ? Number(row.beforediscounttotal).toFixed(2) : 0}</StyledTableCell>
                        <StyledTableCell >{row.nettotal}</StyledTableCell>
                        <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          { /* *********************************************print layout **********************************************/}
          <TableContainer component={Paper} sx={userStyle.printcls} >
            <Table sx={{ minWidth: 700, }} aria-label="customized table" id="usertable" ref={componentRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>GRN NO</StyledTableCell>
                  <StyledTableCell >GRN Date</StyledTableCell>
                  <StyledTableCell >Supplier Name</StyledTableCell>
                  <StyledTableCell >Bill No</StyledTableCell>
                  <StyledTableCell >Bill Date</StyledTableCell>
                  <StyledTableCell >Total Items</StyledTableCell>
                  <StyledTableCell >Total Quantity</StyledTableCell>
                  <StyledTableCell >Sub Total</StyledTableCell>
                  <StyledTableCell >Discount</StyledTableCell>
                  <StyledTableCell >Other Charges</StyledTableCell>
                  <StyledTableCell >Grand Total</StyledTableCell>
                  <StyledTableCell >Round OFF</StyledTableCell>
                  <StyledTableCell >Net Total</StyledTableCell>
                  <StyledTableCell >Pay Mode</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {purchasesreport &&
                  (purchasesreport.map((row, index) => (
                    <StyledTableRow >
                      <StyledTableCell>{row.invoiceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.purchasedate).utc().format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.supplier}</StyledTableCell>
                      <StyledTableCell >{row.referenceno}</StyledTableCell>
                      <StyledTableCell >{moment(row.paidon).utc().format('DD-MM-YYYY')}</StyledTableCell>
                      <StyledTableCell >{row.totalcnt}</StyledTableCell>
                      <StyledTableCell >{row.totalitem}</StyledTableCell>
                      <StyledTableCell >{row.subcost}</StyledTableCell>
                      <StyledTableCell >{row.totaldiscount}</StyledTableCell>
                      <StyledTableCell >{row?.products?.map((value)=> { return value.packingcharge == undefined || value.packingcharge == 0 ? 0 : +value.packingcharge}).reduce((val , dat)=> val + dat)}</StyledTableCell>
                      <StyledTableCell >{row.beforediscounttotal ? row.beforediscounttotal : 0}</StyledTableCell>
                      <StyledTableCell >{row.beforediscounttotal ? Number(row.beforediscounttotal).toFixed(2) : 0}</StyledTableCell>
                      <StyledTableCell >{row.nettotal}</StyledTableCell>
                      <StyledTableCell >{row.paymentmethod}</StyledTableCell>
                    </StyledTableRow>
                  ))
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </>

    </Box>
  );
}
function Purchasereport() {
  return (
    <>
      <Box >
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Purchasereportall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Purchasereport;