import React, { useState, useEffect, useContext, useRef } from "react";
import { Box, Table, TableBody, TableContainer, TableHead, Paper, Button, Grid, Typography, FormControl, TextField } from "@mui/material";
import { FaPrint, FaFilePdf } from "react-icons/fa";
import { userStyle } from "../PageStyle";
import Navbar from "../../components/header/Navbar";
import Footer from "../../components/footer/Footer";
import { StyledTableRow, StyledTableCell } from "../../components/Table";
import { ExportXL, ExportCSV } from "../Export";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import axios from "axios";
import Headtitle from "../../components/header/Headtitle";
import { UserRoleAccessContext } from "../../context/Appcontext";
import $ from "jquery";
import { toast } from 'react-toastify';
import { SERVICE } from "../../services/Baseservice";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { AuthContext } from '../../context/Appcontext';
function ManufacturewiseEstimate() {
  const [manufacturewiseestimate, setManufactureWiseEstimate] = useState([]);
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);
  const { auth } = useContext(AuthContext);
  //jquery
  $.DataTable = require("datatables.net");
  const tableRef = useRef();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const [dateFilter, setDateFilter] = useState({
    startdate: today, enddate: today,
  })
  //All  empty Arrays
  let manArr = [];
  let totArr = [];
  let quantityArr = [];
  let arrMixed = [];
  //estimate fetching
  const fetchEstimate = async () => {
    try {
      let request = await axios.get(SERVICE.ESTIMATE, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let estimatefetch = request.data.estimate.filter((data) => {
        let splittedMonth = data.date;
        let dateTrim = moment(splittedMonth).utc().format("YYYY-MM-DD");
        if (dateFilter.startdate == "" && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
        else if (dateFilter.startdate <= dateTrim && dateFilter.enddate == "") {
          return data
        }
        else if (dateFilter.startdate == "" && dateFilter.enddate + 1 >= dateTrim) {
          return data
        }
      });
      estimatefetch != "" ? estimatefetch.map((item) => {
        item.goods.map((value) => {
          manArr.push(value.manufacture);
          totArr.push(+value.subtotal);
          quantityArr.push(+value.quantity);
        });
        //all arrays
        arrMixed = manArr.map(function (data, i) {

          return {
            manufacture: data,
            totalbillamt: totArr[i],
            totlquantity: quantityArr[i],
          };
        });
        //calculation for total / quantity
        const result = [
          ...arrMixed
            .reduce((r, o) => {
              const key = o.manufacture;
              const items =
                r.get(key) ||
                Object.assign({}, o, {
                  totalbillamt: 0,
                  totlquantity: 0,
                });
              items.totalbillamt += o.totalbillamt;
              items.totlquantity += o.totlquantity;
              return r.set(key, items);
            }, new Map())
            .values(),
        ];
        setManufactureWiseEstimate(result);
        $(document).ready(function () {
          $.fn.dataTable.ext.errMode = "none";
          setTimeout(function () {
            $(tableRef.current).DataTable({
              language: { search: "", searchPlaceholder: "Search..." },
              lengthMenu: [
                [10, 1, 25, 50, 100, 200, 500, -1],
                [10, 1, 25, 50, 100, 200, 500, "All"],
              ],
            });
          }, 1000);
        });
      }) : setManufactureWiseEstimate([])
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };
  // Export Excel
  const fileName = "Manufacture_Wise_Estimate";
  //Excel data
  const [exceldata, setExceldata] = useState([]);
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    let data = manufacturewiseestimate.map(t => ({
      Manufacture: t.manufacture,
      "Total Quantity": t.totlquantity,
      "Total Amount": t.totalbillamt,
    }));
    setExceldata(data);
  }
  useEffect(() => {
    getexcelDatas()
  }, [manufacturewiseestimate])
  // PDF
  const downloadpdf = () => {
    const doc = new jsPDF();
    autoTable(doc, { html: "#manufacturewiseestimate" });
    doc.save("Manufacture Wise Estimate.pdf");
  };
  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Manufacture Wise Estimate",
    pageStyle: "print",
  });
  return (
    <Box>
      <Headtitle title={"Manufacturewise Estimate"} />
      <Typography sx={userStyle.HeaderText}>ManufactureWise Estimate</Typography>
      <Box sx={userStyle.container}>
        <Grid container sx={{ justifyContent: "center" }} spacing={1}>
          <Grid item lg={5} md={5}>
            <Grid container>
              <Grid item lg={2} md={2}>
                <Typography sx={{ marginTop: 1 }}>From</Typography>
              </Grid>
              <Grid item lg={9} md={10} sx={{ display: "flex" }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.startdate}
                    onChange={(e) => {
                      setDateFilter({
                        ...dateFilter,
                        startdate: e.target.value,
                      });
                    }}
                    type="date"
                    size="small"
                    name="startdate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} md={5}>
            <Grid container>
              <Grid item lg={1} md={1}>
                <Typography sx={{ marginTop: 1 }}>To</Typography>
              </Grid>
              <Grid item lg={9} md={9} sx={{ display: "flex" }}>
                <FormControl size="small" fullWidth>
                  <TextField
                    id="component-outlined"
                    value={dateFilter.enddate}
                    onChange={(e) => {
                      setDateFilter({ ...dateFilter, enddate: e.target.value });
                    }}
                    type="date"
                    size="small"
                    name="enddate"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={fetchEstimate}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      {/* header text */}
      {/* content start */}
      <Box sx={userStyle.container}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {/* <Typography sx={userStyle.importheadtext}>All your users</Typography> */}
          </Grid>
          <Grid item xs={4}>
            {isUserRoleCompare[0].auser && <></>}
          </Grid>
        </Grid>
        {/* ******************************************************EXPORT Buttons****************************************************** */}
        <Grid container sx={userStyle.gridcontainer}>
          <Grid>
            {isUserRoleCompare[0].csvmanufacturewiseestimate && (
              <>
                <ExportCSV csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].excelmanufacturewiseestimate && (
              <>
                <ExportXL csvData={exceldata} fileName={fileName} />
              </>
            )}
            {isUserRoleCompare[0].printmanufacturewiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={handleprint}>
                  &ensp;
                  <FaPrint />
                  &ensp;Print&ensp;
                </Button>
              </>
            )}
            {isUserRoleCompare[0].pdfmanufacturewiseestimate && (
              <>
                <Button sx={userStyle.buttongrp} onClick={() => downloadpdf()}>
                  <FaFilePdf />
                  &ensp;Export to PDF&ensp;
                </Button>
              </>
            )}
          </Grid>
        </Grid>
        <br />
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table" id="manufacturewiseestimate" ref={tableRef}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Manufacture</StyledTableCell>
                  <StyledTableCell>Total Quantity</StyledTableCell>
                  <StyledTableCell>Total Amount</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody align="left">
                {manufacturewiseestimate &&
                  (manufacturewiseestimate.map((row, index) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell>{row.manufacture}</StyledTableCell>
                        <StyledTableCell>{row.totlquantity}</StyledTableCell>
                        <StyledTableCell>{row.totalbillamt}</StyledTableCell>
                      </StyledTableRow>
                    );
                  }))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* content end */}
      {/* print layout */}
      <TableContainer component={Paper} sx={userStyle.printcls}>
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          id="manufacturewiseestimate"
          ref={componentRef}
        >
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Manufacture</StyledTableCell>
              <StyledTableCell>Total Quantity</StyledTableCell>
              <StyledTableCell>Total Amount</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody align="left">
            {manufacturewiseestimate &&
              (manufacturewiseestimate.map((row, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.manufacture}</StyledTableCell>
                    <StyledTableCell>{row.totlquantity}</StyledTableCell>
                    <StyledTableCell>{row.totalbillamt}</StyledTableCell>
                  </StyledTableRow>
                );
              }))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
function Manufacturewisesales() {
  return (
    <Box >
      <Navbar />
      <Box sx={{ width: "100%", overflowX: "hidden" }}>
        <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
          <ManufacturewiseEstimate /><br /><br /><br />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
export default Manufacturewisesales;