import React, { useState, useEffect, useContext } from 'react';
import { Button, Typography, Grid, FormControl, Box, OutlinedInput, Dialog, DialogContent, DialogActions } from '@mui/material';
import Navbar from '../../../components/header/Navbar';
import Footer from '../../../components/footer/Footer';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnIconOutlined from '@mui/icons-material/LocationOnOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { userStyle, colourStyles } from '../../PageStyle';
import Selects from "react-select";
import axios from 'axios';
import { toast } from 'react-toastify';
import Headtitle from '../../../components/header/Headtitle';
import { SERVICE } from '../../../services/Baseservice';
import { AuthContext } from '../../../context/Appcontext';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

function Customereditlist() {

    //  Add Text Field
    const [customerCategory, setCustomerCategory] = useState();
    const [customers, setCustomers] = useState({});
    const [pos, setPos] = useState({});
    const { auth } = useContext(AuthContext);

    const [customer, setCustomer] = useState({
        cusid: "", customergroup: "", name: "", address: "", area: "", mobileno: "", whatsapp: "", gstn: "", uin: "", aadhaar: "",
        panno: "", country: "", state: "", city: "", creditlimit: "", creditdays: "", dlnos: "", category: "Retail", activate: true,
    });

    // Popup model
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [showAlert, setShowAlert] = useState()
    const handleClickOpen = () => { setIsErrorOpen(true); };
    const handleClose = () => { setIsErrorOpen(false); };

    // Aadhar field
    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    // Customers
    const fetchCustomers = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setCustomers(req.data.customers);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    // Pos
    const fetchPos = async () => {
        try {
            let req = await axios.get(SERVICE.POS, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            });
            setPos(req.data.pos1);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    const id = useParams().id

    // Customers
    const fetchHandler = async () => {
        try {
            let response = await axios.get(`${SERVICE.CUSTOMER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }
            })
            setCustomer(response.data.scustomer);
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }

    // Category
    const fetchCategory = async () => {
        try {
            let req = await axios.get(SERVICE.CUSTOMER_GROUP, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                }

            });
            setCustomerCategory(
                req?.data?.cgroups?.map((d) => ({
                    ...d,
                    label: d.cusgroupname,
                    value: d.cusgroupname,
                }))
            );
        } catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    };

    useEffect(() => {
        fetchCustomers();
        fetchPos();
        fetchHandler();
        fetchCategory();
    }, [id])

    // Phone Number length
    const handlePhoneone = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setCustomer({ ...customer, mobileno: num })
        }
    }
    const handleWhatsapp = (e) => {
        if (e.length > 10) {
            setShowAlert("Phone number can't more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setCustomer({ ...customer, whatsapp: num })
        }
    }
    const handleAadhaar = (e) => {
        if (e.length > 12) {
            setShowAlert("AADHAAR number can't have more than 12 characters!")
            handleClickOpen();
            let num = e.slice(0, 12);
            setCustomer({ ...customer, aadhaar: num })
        }
    }
    const handlePan = (e) => {
        if (e.length > 10) {
            setShowAlert("Pan number can't have more than 10 characters!")
            handleClickOpen();
            let num = e.slice(0, 10);
            setCustomer({ ...customer, panno: num })
        }
    }
    const handleUin = (e) => {
        if (e.length > 15) {
            setShowAlert("UIN number can't have more than 15 characters!")
            handleClickOpen();
            let num = e.slice(0, 15);
            setCustomer({ ...customer, uin: num })
        }
    }
    const handleGstin = (e) => {
        if (e.length > 15) {
            setShowAlert("GSTIN number can't have more than 15 characters!")
            handleClickOpen();
            let num = e.slice(0, 15);
            setCustomer({ ...customer, gstn: num })
        }
    }

    const backPage = useNavigate();

    const updateCustomer = async () => {
        pos.map((item) => {
            if (item.contactid == customer.cusid) {
                axios.put(`${SERVICE.POS_SINGLE}/${item._id}`, {
                    contactid: String(customer.cusid),
                    customer: String(customer.name),
                    address: String(customer.address),
                    area: String(customer.area),
                    mobileno: Number(customer.whatsapp),
                    contactnumber: Number(customer.whatsapp),
                    location: String(customer.city),
                    creditlimit: Number(customer.creditlimit),
                    creditdays: Number(customer.creditdays),
                    activate: Boolean(customer.activate),
                });
            }
        })

        try {
            let req = await axios.put(`${SERVICE.CUSTOMER_SINGLE}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${auth.APIToken}`
                },
                cusid: String(customer.cusid),
                customergroup: String(customer.customergroup),
                name: String(customer.name),
                address: String(customer.address),
                area: String(customer.area),
                mobileno: Number(customer.mobileno),
                whatsapp: Number(customer.whatsapp),
                gstn: String(customer.gstn),
                uin: String(customer.uin),
                aadhaar: Number(customer.aadhaar),
                panno: String(customer.panno),
                country: String(customer.country),
                state: String(customer.state),
                city: String(customer.city),
                creditlimit: Number(customer.creditlimit),
                creditdays: Number(customer.creditdays),
                dlnos: String(customer.dlnos),
                category: String(customer.category),
                ledgerbalance: Number(customer.ledgerbalance),
                activate: Boolean(customer.activate),
            });
            setCustomer(req.data)

            toast.success(req.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            backPage('/contact/customer/list');
        }
        catch (err) {
            const messages = err.response.data.message;
            toast.error(messages);
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        updateCustomer();
    }

    const handleBack = () => {
        backPage('/contact/customer/list')
    }


    const handleValidationSName = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, name: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, name: value })
        }
    }

    const handleValidationCountry = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, country: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, country: value })
        }
    }

    const handleValidationState = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, State: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, State: value })
        }
    }

    const handleValidationCity = (e) => {
        let val = e.target.value;
        let numbers = new RegExp('[0-9]');
        var regex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (e.target.value.match(numbers)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, city: value })
        }
        else if (regex.test(e.target.value)) {
            setShowAlert("Please enter letter only! (a-z)")
            handleClickOpen();
            let num = val.length;
            let value = val.slice(0, num - 1)
            setCustomer({ ...customer, city: value })
        }
    }

    return (
        <Box>
            <Headtitle title={'Edit Customer'} />
            <Typography sx={userStyle.HeaderText}>Edit Customer</Typography>
            <Box sx={userStyle.container}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ justifyContent: 'center' }}>Customer Details</Typography>
                        </Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Fields</Typography>
                        </Grid>
                        <Grid item md={8} sm={6} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>1.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Customer ID</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><ContactPageOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        id="component-outlined"
                                        value={customer.cusid}
                                        type="text"
                                        name="customerID"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>2.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Category</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <Selects
                                    styles={colourStyles}
                                    options={customerCategory}
                                    placeholder={customer.customergroup == undefined || customer.customergroup == "" ? "Select" : customer.customergroup}
                                    onChange={(e) => {
                                        setCustomer({ ...customer, customergroup: e.value })
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>3.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: 'flex' }}>Name <Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><PersonOutlineOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.name}
                                        onChange={(e) => { setCustomer({ ...customer, name: e.target.value }); handleValidationSName(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>4.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Address</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.address}
                                    onChange={(e) => { setCustomer({ ...customer, address: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>5.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Area</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.area}
                                    onChange={(e) => { setCustomer({ ...customer, area: e.target.value }) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>6.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: 'flex' }}>Mobile No <Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LocalPhoneOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.mobileno}
                                        onChange={(e) => { setCustomer({ ...customer, mobileno: e.target.value }); handlePhoneone(e.target.value) }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>7.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6' sx={{ display: 'flex' }}>WhatsApp No <Typography style={{ color: "red", marginLeft: "2px" }}>*</Typography></Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><PhoneAndroidOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth >
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.whatsapp}
                                        onChange={(e) => { setCustomer({ ...customer, whatsapp: e.target.value }); handleWhatsapp(e.target.value) }}
                                        type="number"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>8.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>GSTIN No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.gstn}
                                    onChange={(e) => { setCustomer({ ...customer, gstn: e.target.value }); handleGstin(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>9.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>UIN No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth >
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.uin}
                                    onChange={(e) => { setCustomer({ ...customer, uin: e.target.value }); handleUin(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>10.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>AADHAR No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={customer.aadhaar}
                                    onChange={(e) => { setCustomer({ ...customer, aadhaar: e.target.value }); handleAadhaar(e.target.value) }}
                                    type="number"
                                    sx={userStyle.input}
                                    onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>11.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>PAN No</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    id="component-outlined"
                                    value={customer.panno}
                                    onChange={(e) => { setCustomer({ ...customer, panno: e.target.value }); handlePan(e.target.value) }}
                                    type="text"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>12.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Country</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LanguageOutlinedIcon /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.country}
                                        onChange={(e) => { setCustomer({ ...customer, country: e.target.value }); handleValidationCountry(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>13.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>State</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}>
                                <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.state}
                                        onChange={(e) => { setCustomer({ ...customer, state: e.target.value }); handleValidationState(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>14.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>City</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Grid sx={{ display: 'flex' }}  >
                                <Grid sx={userStyle.spanIcons}><LocationOnIconOutlined /></Grid>
                                <FormControl size="small" fullWidth>
                                    <OutlinedInput
                                        sx={userStyle.input}
                                        id="component-outlined"
                                        value={customer.city}
                                        onChange={(e) => { setCustomer({ ...customer, city: e.target.value }); handleValidationCity(e) }}
                                        type="text"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>15.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Credit Limit</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.creditlimit}
                                    onChange={(e) => { setCustomer({ ...customer, creditlimit: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>16.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>Credit Days</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.creditdays}
                                    onChange={(e) => { setCustomer({ ...customer, creditdays: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid item md={2} sm={3} xs={12}><Typography variant='h6' sx={{ marginLeft: '50px' }}>17.</Typography></Grid>
                        <Grid item md={2} sm={3} xs={12}>
                            <Typography variant='h6'>DL NOS</Typography>
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <FormControl size="small" fullWidth>
                                <OutlinedInput
                                    sx={userStyle.input}
                                    id="component-outlined"
                                    value={customer.dlnos}
                                    onChange={(e) => { setCustomer({ ...customer, dlnos: e.target.value }) }}
                                    type="number"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={2} xs={12}></Grid>
                        <Grid container sx={userStyle.gridcontainer}>
                            <Grid >
                                <Button sx={userStyle.buttoncancel} onClick={handleBack}>CANCEL</Button>
                                <Button sx={userStyle.buttonadd} type="submit" >UPDATE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Box>

            {/* ALERT DIALOG */}
            <Box>
                <Dialog
                    open={isErrorOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent sx={{ width: '350px', textAlign: 'center', alignItems: 'center' }}>
                        <ErrorOutlineOutlinedIcon sx={{ fontSize: "80px", color: 'orange' }} />
                        <Typography variant="h6" >{showAlert}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleClose}>ok</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}

function Customeredit() {
    return (
        <Box>
            <Navbar />
            <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                <Box component="main" sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
                    <Customereditlist /><br /><br /><br />
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}

export default Customeredit;