import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Button, Grid, FormGroup, FormControlLabel, Checkbox, Paper, Typography, TableContainer, Table, TableHead, TableBody, FormControl, } from '@mui/material';
import { FaPrint, FaFilePdf, } from 'react-icons/fa';
import Navbar from '../../components/header/Navbar';
import Footer from '../../components/footer/Footer';
import { StyledTableRow, StyledTableCell } from '../../components/Table';
import $ from 'jquery';
import { userStyle, colourStyles } from '../PageStyle';
import axios from 'axios';
import jsPDF from "jspdf";
import Selects from 'react-select';
import autoTable from 'jspdf-autotable';
import { ExportXL, ExportCSV } from '../Export';
import { toast } from 'react-toastify';
import { useReactToPrint } from "react-to-print";
import { UserRoleAccessContext } from '../../context/Appcontext';
import { SERVICE } from '../../services/Baseservice';
import Headtitle from '../../components/header/Headtitle';
import { AuthContext } from '../../context/Appcontext';

function Manufacturewiselistall() {

  const [products, setProducts] = useState([]);
  const [manufactures, setManufacture] = useState([]);
  const [checked, setChecked] = useState(true);
  const [selectFilter, setSelectFilter] = useState({ manufacture: "" })
  const [newdata, setNewdata] = useState([]);
  const [exceldata, setExceldata] = useState([]);
  const { auth } = useContext(AuthContext);

  //role access
  const { isUserRoleCompare } = useContext(UserRoleAccessContext);

  //DataTable
  $.DataTable = require('datatables.net')
  const tableRef = useRef()

  // get all products
  const fetchProduct = async () => {
    try {
      let res_product = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let mole_data = res_product?.data?.products?.map((d) => {
        return d.manufacture
      })
      setNewdata(mole_data);
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  }

  let newarray = [...new Set(newdata)];

  // Manufactures
  const fetchManufacture = async () => {
    try {
      setManufacture(
        newarray.map((d) => (
          {
            ...d,
            label: d,
            value: d,
          }
        ))
      );
    } catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const getCateName = (newValue) => {
    if (newValue) {
      setProducts([]) || setChecked(true)
    }
  }

  const searchLoc = async () => {
    try {
      let product1 = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        }
      });
      let prodDataLocation = product1.data.products.filter((data) => {
        if (data.manufacture == selectFilter.manufacture) {
          return data
        }
      });
      setProducts(prodDataLocation);
    }
    catch (err) {
      const messages = err.response.data.message;
      toast.error(messages);
    }
  };

  const fetchproductSelect = async (e) => {

    setChecked(s => !s)
    try {
      let response = await axios.get(SERVICE.PRODUCT, {
        headers: {
          'Authorization': `Bearer ${auth.APIToken}`
        },
      });
      if (checked === true) {
        setProducts(response.data.products);

      } else if (checked === false) {
        setProducts([])
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchManufacture();
  }, [manufactures])

  // Export Excel
  const fileName = 'Manufacture Wise Item List';
  //  get particular columns for export excel
  const getexcelDatas = async () => {
    var data = products.map(t => ({
      "Item Code": t.prodcode, "Item Name": t.prodname, "Molecule": t.molecules, "Category": t.category, "Schedule": t.schedule,
      "Manufacture": t.manufacture, "Location": t.businesslocation, "HSN Code": t.hsncode ? t.hsncode : t.applicabletax,
    }));
    setExceldata(data);
  }

  // Print
  const componentRef = useRef();
  const handleprint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'HIPOS | MANUFACTURE WISE ITEM LIST',
    pageStyle: 'print'
  });

  //PDF
  const downloadPdf = () => {
    const doc = new jsPDF()
    doc.autoTable({
      html: '#manufacturetable',
      margin: { top: 10 },
    })
    doc.save('Manufacture Wise Item List.pdf')
  }

  useEffect(() => {
    getexcelDatas();
    fetchProduct();
  }, [exceldata])

  return (
    <Box >
      <Headtitle title={'Manufacture Wise Item List'} />
      { /* ****** Header Content ****** */}
      <Typography variant='body2' sx={userStyle.HeaderText}>Manufacture<Typography variant='body2' sx={userStyle.SubHeaderText}>Manufacture Item List</Typography></Typography>
      <Box sx={userStyle.filtercontent}>
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <FormControl size="small" fullWidth>
              <Selects
                placeholder="Manufactures"
                options={manufactures}
                styles={colourStyles}
                onChange={(e)=>{
                  setSelectFilter({ ...selectFilter, manufacture: e.value })
                  getCateName(e.value);
                  setChecked(true)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12}>
            <FormGroup>
              <FormControlLabel control={<Checkbox onClick={(e) => { fetchproductSelect(e) }} checked={!checked} />} label="Select All" />
            </FormGroup>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Button variant='contained' color='secondary' onClick={searchLoc}>Generate&ensp;</Button>
          </Grid>
        </Grid>
      </Box>
      <br />
      { /* ****** Table Start ****** */}
      <>
        <Box sx={userStyle.container} >
          { /* Header Content */}

          { /* Header Buttons */}
          <Grid container sx={userStyle.gridcontainer}>
            <Grid >
              {isUserRoleCompare[0].csvmanufacturelist && (
                <>
                  <ExportCSV csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].excelmanufacturelist && (
                <>
                  <ExportXL csvData={exceldata} fileName={fileName} />
                </>
              )}
              {isUserRoleCompare[0].printmanufacturelist && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={handleprint}>&ensp;<FaPrint />&ensp;Print&ensp;</Button>
                </>
              )}
              {isUserRoleCompare[0].pdfmanufacturelist && (
                <>
                  <Button sx={userStyle.buttongrp} onClick={() => downloadPdf()}><FaFilePdf />&ensp;Export to PDF&ensp;</Button>
                </>
              )}
            </Grid>
          </Grid><br />
          { /* Table Start */}
          <TableContainer component={Paper} >
            <Table aria-label="simple table" id="manufacturetable" ref={tableRef}>
              <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
                <StyledTableRow >
                  <StyledTableCell >Item Code</StyledTableCell>
                  <StyledTableCell >Item Name</StyledTableCell>
                  <StyledTableCell >Molecule</StyledTableCell>
                  <StyledTableCell >Category</StyledTableCell>
                  <StyledTableCell >Schedule</StyledTableCell>
                  <StyledTableCell >Manufacture</StyledTableCell>
                  <StyledTableCell >Location</StyledTableCell>
                  <StyledTableCell >HSN Code</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {products.length > 0 ? (
                  products?.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">{row.prodcode}  </StyledTableCell>
                      <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                      <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                      <StyledTableCell align="left">{row.category}</StyledTableCell>
                      <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                      <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                      <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                      <StyledTableCell align="left">{row.hsncode ? row.hsncode : row.applicabletax}</StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : <StyledTableCell colSpan={8} align="center">No data available</StyledTableCell>}
              </TableBody>
            </Table>
          </TableContainer>
          { /* Table End */}
        </Box>

        {/* print layout */}
        <TableContainer component={Paper} sx={userStyle.printcls}>
          <Table aria-label="simple table" id="manufacturetable" ref={componentRef}>
            <TableHead sx={{ fontWeight: "600", fontSize: "14px" }} >
              <StyledTableRow >
                <StyledTableCell >Item Code</StyledTableCell>
                <StyledTableCell >Item Name</StyledTableCell>
                <StyledTableCell >Molecule</StyledTableCell>
                <StyledTableCell >Category</StyledTableCell>
                <StyledTableCell >Schedule</StyledTableCell>
                <StyledTableCell >Manufacture</StyledTableCell>
                <StyledTableCell >Location</StyledTableCell>
                <StyledTableCell >HSN Code</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {products.length > 0 ? (
                products.map((row, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{row.prodcode}  </StyledTableCell>
                    <StyledTableCell align="left">{row.prodname}</StyledTableCell>
                    <StyledTableCell align="left">{row.molecules}</StyledTableCell>
                    <StyledTableCell align="left">{row.category}</StyledTableCell>
                    <StyledTableCell align="left">{row.schedule}</StyledTableCell>
                    <StyledTableCell align="left">{row.manufacture}</StyledTableCell>
                    <StyledTableCell align="left">{row.businesslocation}</StyledTableCell>
                    <StyledTableCell align="left">{row.hsncode ? row.hsncode : row.applicabletax}</StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (<StyledTableCell colSpan={8}><Typography variant='body2'>No data available in table</Typography></StyledTableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Box>
  );
}

function Manufacturewiselist() {
  return (
    <>
      <Box >
        <Navbar />
        <Box sx={{ width: '100%', overflowX: 'hidden' }}>
          <Box component="main"sx={{ paddingRight: '60px',paddingLeft: '60px',paddingTop: '20px', }}>
            <Manufacturewiselistall /><br /><br /><br />
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Manufacturewiselist;